import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import config from '../../config'
import {get, post} from "../Api";
import * as cache from "../Cache";

const initialState = {
    authenticated: false,
    initialized: false,
    account: null,
    name: null,
    roleMapping: null,
    isOwner: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

export const login = createAsyncThunk(
    'account/login',
    async ({credentials}, {rejectWithValue}) => {
        const LOGIN_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/login`;
        const body = {
            email: credentials.email,
            password: credentials.password
        };
        try {
            const account = await post(false, LOGIN_ENDPOINT, body, {}, false);
            cache.set(config.constants.CACHE_KEY_TOKEN, account.token);
            cache.set(config.constants.CACHE_KEY_JWT, account.token);
            cache.set(config.constants.CACHE_KEY_API, account.token);
            cache.set(config.constants.CACHE_KEY_ACCOUNT, account );
            return account;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.body);
        }
    }
);

export const logout = createAsyncThunk(
    'account/logout',
    async () => {
        const LOGOUT_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/logout`;
        await get(true, LOGOUT_ENDPOINT, {}, false);
    }
);

const getRoleMapping = (account) => {
    // Admin Mappings
    if (account.roles.includes('ROOT')) return 'ADMIN';
    if (account.roles.includes('ADMINISTRATOR')) return 'ADMIN';
    // Organization Mappings
    if (account.roles.includes('OWNER')) return 'ORGANIZATION';
    if (account.roles.includes('DEVELOPER')) return 'ORGANIZATION';
    if (account.roles.includes('USER')) return 'ORGANIZATION';
    // Contractor Mappings
    if (account.roles.includes('CONTRACTOR')) return 'CONTRACTOR';
    return undefined;
}

const isOwner = (account) => {
    if (getRoleMapping(account) === 'ORGANIZATION') return account.roles.includes('OWNER');
    return false;
}

const getName = (account) => {
    let name = 'Rick Deckard';
    if (account.givenName && account.surName) name = `${account.givenName} ${account.surName}`;
    return name;
}

const accountSlice = createSlice({
    name: 'accountSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = '';

            return state;
        },
        getAccount: (state) => {
            const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);

            if (account) {
                state.authenticated = true;
                state.initialized = true;
                state.account = account;
                state.name = getName(account);
                state.roleMapping = getRoleMapping(account);
                state.isOwner = isOwner(account);
                state.isSuccess = true;

                return state;
            }
        }
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                const account = action.payload;

                state.authenticated = true;
                state.initialized = true;
                state.account = account;
                state.name = getName(account);
                state.roleMapping = getRoleMapping(account);
                state.isOwner = isOwner(account);
                state.isSuccess = true;
            }
        },
        [login.rejected]: (state, action) => {
            state.loading = false;
            state.isError = true;
            if (action?.payload) {
                let errObj = undefined;
                try {
                    errObj = action?.payload;
                } catch (e) {
                    // Don't need to do anything.
                }
                if (errObj) {
                    state.errorMessage = `${errObj.message || errObj.error.message}.`;
                } else {
                    state.errorMessage = action.error.message;
                }
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
        [logout.pending]: (state, action) => {
            state.loading = true;
        },
        [logout.fulfilled]: (state, action) => {
            state.loading = false;

            state.authenticated = false;
            state.initialized = false;
            state.account = null;
            state.name = null;
            state.roleMapping = null;
            state.isOwner = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = '';

            cache.del(config.constants.CACHE_KEY_TOKEN);
            cache.del(config.constants.CACHE_KEY_ACCOUNT);
            cache.del(config.constants.CACHE_KEY_API);
            cache.del(config.constants.CACHE_KEY_JWT);
        },
        [logout.rejected]: (state, action) => {
            state.loading = false;
            state.authenticated = false;
            state.initialized = false;
            state.account = null;
            state.name = null;
            state.roleMapping = null;
            state.isOwner = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = '';
            cache.del(config.constants.CACHE_KEY_TOKEN);
            cache.del(config.constants.CACHE_KEY_ACCOUNT);
            cache.del(config.constants.CACHE_KEY_API);
            cache.del(config.constants.CACHE_KEY_JWT);
        },
    }
})


export const {clearState, getAccount} = accountSlice.actions;
export default accountSlice.reducer;
