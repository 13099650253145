/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import React, {useState} from "react";
import styled from "styled-components";

import {APP_COLORS} from "../../common/Constants";
import {Button, Col, Row, Accordion} from "react-bootstrap";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";

const HeaderPanel = styled.div `
  background-color: ${APP_COLORS['gray-100']};
  padding: 0.5rem;

  border-width: 0.0125rem;
  border-style: solid;
  border-color: #e7eaf3;
`;

const SearchBarContainer = styled.div `
  margin: 8px 0 8px 15px;
`;

const SearchSubText = styled.div `
  display: flex;
  //margin-left: 1rem;
  font-size: 13px;
  font-style: italic;
  padding: 5px 15px;
`;

const SearchExpandCollapseToggleContainer = styled.div `
  display: flex;
  justify-content: end;
`;

const SearchSubTextLocation = styled.div `
  font-weight: 600;
  font-size: 14px;
`;

const ContractorDirectoryHeader = (props) => {
    const {
        searchBar,
        searchSubText,
        searchSubTextLocation,
        searchOptionsForm
    } = props;

    const [expanded, setExpanded] = useState(false);

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionToggle(eventKey, () => setExpanded(!expanded));
        return (
            <Button
                variant="link"
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    return (
        <HeaderPanel>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <SearchBarContainer>
                        {searchBar}
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                            <Accordion>
                                <Row>
                                    <Col xl={10} lg={10} md={10} sm={10}>
                                        <SearchSubText>{searchSubText}&nbsp;<SearchSubTextLocation>{searchSubTextLocation}</SearchSubTextLocation></SearchSubText>
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={2}>
                                        <SearchExpandCollapseToggleContainer>
                                            <CustomToggle eventKey="0">
                                                { expanded ? (
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                )}
                                            </CustomToggle>
                                        </SearchExpandCollapseToggleContainer>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            {searchOptionsForm}
                                        </Col>
                                    </Row>
                                </Accordion.Collapse>
                            </Accordion>
                            </Col>
                        </Row>
                    </SearchBarContainer>
                </Col>
            </Row>
        </HeaderPanel>
    );
}

export default ContractorDirectoryHeader;
