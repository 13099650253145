
import _ from 'lodash';
export const LIST_TYPES =
    {
        IMPORT_COMPANY: {
            id: "IMPORT_COMPANY",
            domain: "IMPORT",
            listTypeDomain: 'COMPANY',
            display: "Company Import",
            description: "An imported list of companies",
            recordType: null,
            recordId: null,
            cubeName: null,
            orderBy: null
        },
        IMPORT_CONTACT: {
            id: "IMPORT_CONTACT",
            domain: "IMPORT",
            listTypeDomain: 'CONTACT',
            display: "Contact Import",
            description: "An imported list of contacts",
            recordType: null,
            recordId: null,
            cubeName: null,
            orderBy: null
        },
        IMPORT_OVERLAY: {
            id: "IMPORT_OVERLAY",
            domain: "IMPORT",
            listTypeDomain: 'OVERLAY',
            display: "Overlay Import",
            description: "An imported list of overlays",
            recordType: null,
            recordId: null,
            cubeName: null,
            orderBy: null
        },
        MATCH_COMPANY: {
            id: 'MATCH_COMPANY',
            domain: 'MATCH',
            display: 'Company Match',
            description: 'A company Match',
            data: {
                defaultMatchType: 'COMPANY_MATCH'
            }
        },
        MATCH_CONTACT: {
            id: 'MATCH_CONTACT',
            domain: 'MATCH',
            display: 'Contact Match',
            description: 'A contact Match',
            data: {
                defaultMatchType: 'CONTACT_MATCH'
            }
        },
        EXPORT_COMPANY: {
            id: 'EXPORT_COMPANY',
            domain: 'EXPORT',
            display: 'Company Export',
            description: 'An exported list of companies',
            data: null
        },
        EXPORT_CONTACT: {
            id: 'EXPORT_CONTACT',
            domain: 'EXPORT',
            display: 'Contact Export',
            description: 'An exported list of companies and contacts',
            data: null
        }
    }

    export function getTypeById(id) {
        if (id) {
            return _.get(LIST_TYPES, id);
        }
    }
