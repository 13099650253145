import * as Api from '../Api';
import config from '../../config';
import * as CubeApi from "../CubeApi";

const apiUrl = `${config.services.ATLAS.apiUrl}/lists`;

export const getById = async (id) => {
    return Api.get(true, `${apiUrl}/${id}`);
};

export const update = async (id, list) => {
    const body = {
        options: null,
        data: list
    };
    return Api.post(true, `${apiUrl}/${id}/edit`, body);
};

export const download = async (listId, data) => {
    const body = {
        options: {
        },
        data: data
    };
    return Api.post(true, `${apiUrl}/${listId}/download`, body);
};

export const downloadListMatchReport = async (listId) => {
    return Api.get(true, `${apiUrl}/${listId}/get-match-report-download-url`);
};

export const getListsWithFilter = async(filter) => {
    return Api.get(true, `${apiUrl}`, filter);
};

export const getListImportOverlayRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportOverlayRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportOverlayRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }

    const payload = {
        dimensions: [
            'ListImportOverlayRecord.id',
            'ListImportOverlayRecord.list_id',
            'ListImportOverlayRecord.overlay_name',
            'ListImportOverlayRecord.overlay_website',
            'ListImportOverlayRecord.overlay_address',
            'ListImportOverlayRecord.overlay_address_delivery_line_1',
            'ListImportOverlayRecord.overlay_address_delivery_line_2',
            'ListImportOverlayRecord.overlay_address_city',
            'ListImportOverlayRecord.overlay_address_region',
            'ListImportOverlayRecord.overlay_address_postal_code',
            'ListImportOverlayRecord.overlay_address_postal_code_plus_4',
            'ListImportOverlayRecord.overlay_address_delivery_point_b',
            'ListImportOverlayRecord.overlay_address_latitude',
            'ListImportOverlayRecord.overlay_address_longitude',
            'ListImportOverlayRecord.overlay_address_precision',
            'ListImportOverlayRecord.overlay_address_county_fips',
            'ListImportOverlayRecord.overlay_address_county_name',
            'ListImportOverlayRecord.overlay_address_congressional_d',
            'ListImportOverlayRecord.overlay_address_rdi',
            'ListImportOverlayRecord.overlay_address_utc_offset',
            'ListImportOverlayRecord.overlay_phone',
            'ListImportOverlayRecord.overlay_email',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 25000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};

export const getListImportCompanyRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportCompanyRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportCompanyRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }
    const payload = {
        dimensions: [
            'ListImportCompanyRecord.id',
            'ListImportCompanyRecord.list_id',
            'ListImportCompanyRecord.company_name',
            'ListImportCompanyRecord.company_website',
            'ListImportCompanyRecord.company_address',
            'ListImportCompanyRecord.company_address_delivery_line_1',
            'ListImportCompanyRecord.company_address_delivery_line_2',
            'ListImportCompanyRecord.company_address_city',
            'ListImportCompanyRecord.company_address_region',
            'ListImportCompanyRecord.company_address_postal_code',
            'ListImportCompanyRecord.company_address_postal_code_plus_4',
            'ListImportCompanyRecord.company_address_delivery_point_b',
            'ListImportCompanyRecord.company_address_latitude',
            'ListImportCompanyRecord.company_address_longitude',
            'ListImportCompanyRecord.company_address_precision',
            'ListImportCompanyRecord.company_address_county_fips',
            'ListImportCompanyRecord.company_address_county_name',
            'ListImportCompanyRecord.company_address_congressional_d',
            'ListImportCompanyRecord.company_address_rdi',
            'ListImportCompanyRecord.company_address_utc_offset',
            'ListImportCompanyRecord.company_phone',
            'ListImportCompanyRecord.company_email',
            'ListImportCompanyMatchedRecord.id',
            'ListImportCompanyMatchedRecord.company_id',
            'ListImportCompanyMatchedRecord.list_import_record_id',
            'ListImportCompanyMatchedRecord.matching_score',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 25000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};


export const getListImportContactRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportContactRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportContactRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }
    const payload = {
        dimensions: [
            'ListImportContactRecord.id',
            'ListImportContactRecord.list_id',
            'ListImportContactRecord.company_name',
            'ListImportContactRecord.company_website',
            'ListImportContactRecord.company_address',
            'ListImportContactRecord.company_address_delivery_line_1',
            'ListImportContactRecord.company_address_delivery_line_2',
            'ListImportContactRecord.company_address_city',
            'ListImportContactRecord.company_address_region',
            'ListImportContactRecord.company_address_postal_code',
            'ListImportContactRecord.company_address_postal_code_plus_4',
            'ListImportContactRecord.company_address_delivery_point_b',
            'ListImportContactRecord.company_address_latitude',
            'ListImportContactRecord.company_address_longitude',
            'ListImportContactRecord.company_address_precision',
            'ListImportContactRecord.company_address_county_fips',
            'ListImportContactRecord.company_address_county_name',
            'ListImportContactRecord.company_address_congressional_d',
            'ListImportContactRecord.company_address_rdi',
            'ListImportContactRecord.company_address_utc_offset',
            'ListImportContactRecord.company_phone',
            'ListImportContactRecord.company_email',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 25000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};

export const removeCubeTableName = (results) => {
    if (!results || results.length === 0) {
        return [];
    }
    const removeTab = (rec) => {
        const res = {};
        Object.keys(rec).map((key) => {
            const keys = key.split('.');
            const newKey = keys.length > 1 ? keys[1] : keys[0]
            res[newKey] = rec[key];
        });
        return res;
    };

    if (results && results.length > 0) {
        return results.map((_rec) => removeTab(_rec));
    }
    return removeTab(results);
};

export const shouldDisplayActionDownload = (list, isFromOrganizationMenu = false) => {
    if (!list) {
        return false
    }

    if (isFromOrganizationMenu) {
        if (
            list.type === 'IMPORT_COMPANY' ||
            list.type === 'IMPORT_CONTACT' ||
            list.type === 'IMPORT_OVERLAY' ||
            list.type === 'MATCH_COMPANY' ||
            list.type === 'MATCH_CONTACT'||
            list.type === 'EXPORT_COMPANY' ||
            list.type === 'EXPORT_CONTACT'
        ) {
            if (list.status !== 'READY') return false;
            return true
        }

    } else if (
        list.type === 'IMPORT_COMPANY' ||
        list.type === 'IMPORT_CONTACT' ||
        list.type === 'IMPORT_OVERLAY' ||
        list.type === 'MATCH_COMPANY' ||
        list.type === 'MATCH_CONTACT'||
        list.type === 'EXPORT_COMPANY' ||
        list.type === 'EXPORT_CONTACT'
    ) {
        if (list.status !== 'READY') return false;
        return true
    }
    return false;
}

export const shouldDisplayActionMatch = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'IMPORT_COMPANY' &&
        list.type !== 'IMPORT_CONTACT'
    ) {
        return false;
    }
    if (list.status !== 'NORMALIZED' &&
        list.status !== 'READY'
    ) {
        return false;
    }
    return true;
}

export const shouldDisplayActionMatchCheck = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'MATCH_COMPANY' &&
        list.type !== 'MATCH_CONTACT'
    ) {
        return false;
    }
    if (list.status !== 'MATCHING') {
        return false;
    }
    return true;
}

export const shouldDisplayActionNormalize = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'IMPORT_COMPANY' &&
        list.type !== 'IMPORT_CONTACT' &&
        list.type !== 'IMPORT_OVERLAY'
    ) {
        return false;
    }
    if (list.status !== 'PARSED'
    ) {
        return false;
    }
    return true;
}


export const shouldDisplayActionParse = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'IMPORT_COMPANY' &&
        list.type !== 'IMPORT_CONTACT' &&
        list.type !== 'IMPORT_OVERLAY'
    ) {
        return false;
    }
    if (list.status !== 'CREATED'
    ) {
        return false;
    }
    return true;
}

export const shouldDisplayActionProcess = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'IMPORT_COMPANY' &&
        list.type !== 'IMPORT_CONTACT' &&
        list.type !== 'IMPORT_OVERLAY'
    ) {
        return false;
    }
    if (list.status !== 'CREATED'
    ) {
        return false;
    }
    return true;
}

export const shouldDisplayActionSync = (list) => {
    if (!list) {
        return false
    }
    if (list.type !== 'IMPORT_COMPANY' &&
        list.type !== 'IMPORT_CONTACT' &&
        list.type !== 'IMPORT_OVERLAY'
    ) {
        return false;
    }
    if (list.status !== 'NORMALIZED'
    ) {
        return false;
    }
    return true;
}

export const shouldDisplayActionReSync = (list) => {
    if (!list) {
        return false
    }
    if (list.type === 'IMPORT_COMPANY' ||
        list.type === 'IMPORT_CONTACT' ||
        list.type === 'IMPORT_OVERLAY'
    ) {
        return false;
    }
    if (list.status !== 'READY' &&
        list.status !== 'ERROR'
    ) {
        return false;
    }
    return true;
}
