/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {get} from 'lodash';
import React, {useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Image} from 'react-bootstrap';
import homeScreenImg from '../../common/assets/img/ToolBelt_home_screen.png';
import Container from "../../common/components/layout/Container";
import {useDispatch, useSelector } from "react-redux";

import {getVersion} from '../../common/Utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../../common/Constants";
import {faArrowUp, faArrowsV} from "@fortawesome/pro-solid-svg-icons";
import {getAtlasApiPing, getAtlasCorePing} from "./adminHomeSlice";

export default function AdminHome(props) {

    const adminHomeState = useSelector(state => state.adminHome);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAtlasCorePing());
        dispatch(getAtlasApiPing());
    }, []);

    const formatPingResponse = (resp) => {
        return `${resp.application} v${resp.version} (${resp.environment.toUpperCase()})`
    }

    return (
        <>
            <div style={{
                // wrap content to allow for background
                overflow: 'hidden',
                position: 'relative'
            }}>
                <Image
                    style={{// background style
                        opacity: '0.1',
                        position: 'absolute',
                        height: 'auto',
                        top: '200px',
                        left: '50%',
                        transform: 'translate(-50% , -50%)',
                        WebkitTransform: 'translate(-50% , -50%)'
                    }}
                    src={homeScreenImg}
                    rounded={true}
                    width={'400px'}
                />
                <div style={{
                    // main content
                    position: 'relative',
                    minHeight: '400px' // ensure we've always got our background
                }}>
                    <Container>
                        <Row>
                            <Col xl={4} lg={4} md={4} sm={4}>
                                <h4 className="text-center">Services</h4>
                                <div className="text-muted">
                                    <h6>Atlas API
                                        {
                                            adminHomeState.atlasApiPing && (
                                                <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.green}
                                                                               icon={faArrowUp}/></>
                                            )
                                        }
                                        {
                                            !adminHomeState.atlasApiPing && (
                                                <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.orange}
                                                                               icon={faArrowsV}/></>
                                            )
                                        }
                                    </h6>
                                    <ul style={{fontSize: '14px'}}>
                                        <li>Version: {adminHomeState.atlasApiPing ? formatPingResponse(adminHomeState.atlasApiPing) : (
                                            <em>[pending]</em>)} </li>
                                        <li>Up
                                            Since: {adminHomeState.atlasApiPing ? `${get(adminHomeState, 'atlasApiPing.status.started', 'error')} (${get(adminHomeState, 'atlasApiPing.status.uptime')} seconds)` : (
                                                <em>[pending]</em>)} </li>
                                        {/*<li>Uptime: {adminHomeState.atlasCorePing ?  : (<em>[pending]</em>)} </li>*/}
                                    </ul>
                                    <h6>Atlas Core
                                        {
                                            adminHomeState.atlasCorePing && (
                                                <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.green}
                                                                               icon={faArrowUp}/></>
                                            )
                                        }
                                        {
                                            !adminHomeState.atlasCorePing && (
                                                <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.orange}
                                                                               icon={faArrowsV}/></>
                                            )
                                        }
                                    </h6>
                                    <ul style={{fontSize: '14px'}}>
                                        <li>Version: {adminHomeState.atlasCorePing ? formatPingResponse(adminHomeState.atlasCorePing) : (
                                            <em>[pending]</em>)} </li>
                                        <li>Up
                                            Since: {adminHomeState.atlasCorePing ? `${get(adminHomeState, 'atlasCorePing.status.started', 'error')} (${get(adminHomeState, 'atlasCorePing.status.uptime')} seconds)` : (
                                                <em>[pending]</em>)} </li>
                                        {/*<li>Uptime: {adminHomeState.atlasCorePing ?  : (<em>[pending]</em>)} </li>*/}
                                    </ul>
                                    <h6>Atlas Cube
                                        <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.orange} icon={faArrowsV}/></>
                                    </h6>
                                    <ul style={{fontSize: '14px'}}>
                                        <li>Version: <em>[not implemented]</em></li>
                                        <li>Up Since: <em>[not implemented]</em></li>
                                    </ul>
                                    <h6>Atlas UX
                                        <>&nbsp;&nbsp;<FontAwesomeIcon color={APP_COLORS.green} icon={faArrowUp}/></>
                                    </h6>
                                    <ul style={{fontSize: '14px'}}>
                                        <li>Version: {getVersion(true)} </li>
                                        <li>Up Since: <em>[it's up, it doesnt work that way]</em></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} className="text-center">
                                <h4>Things To Help Manage the Platform</h4>
                                <span className="text-muted">Some helpful information and data for managing and monitoring the platform that I haven't had a chance to code yet</span>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
