/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
    map,
    set
} from 'lodash';

// NOTE: KEEP THIS IN ORDER OF ELEMENTS IN THE UI; IT HELPS QA

import {
    getContactTitle,
    getContactEmailStatus,
    getCompanyAddressRegion,
    getCompanyAddressLocMsa,
    getCompanyAddressRdi,
    getCompanyAddressLocCsa,
    getCompanyEmailStatus,
    getCompanySegments,
    getCompanyClassPrimaryTrade,
    getCompanyClassTrades,
    getCompanyClassNAICSDescriptions,
    getCompanyClassSICDescriptions,
    getCompanyServices,
    getCompanyFactBusinessStructure,
    getCompanyFactPublicPrivate,
    getCompanyFactOwnershipType,
    getCompanyStatEmployeesRange,
    getCompanyStatSalesRange,
    getCompanyAffinities,
    getCompanyAffiliations,
    getCompanyIndicatorWorkTypes,
    getCompanyIndicatorWorkLocations,
    getCompanyIndicatorWorkCredentials,
    getCompanyProfileTypes,
    getCompanyProfileAssociations,
    getCompanyProfileManufacturers,
    getCompanyProfileUtilities,
    getCompanyProfileOnlineProfiles,
    getCompanyLicenseTypes,
    getCompanyRegLocalRegistrationStatus,
    getCompanyRegLocalRegistrar,
    getCompanyRegLocalRegistrarRegion,

} from './metaDataServices';

// NOTE: KEEP THIS IN ORDER OF ELEMENTS IN THE UI; IT HELPS QA

const initialState = {
    contactTitle: [],
    contactEmailStatus: [],
    companyAddressRegion: [],
    companyAddressLocMsa: [],
    companyAddressRdi: [],
    companyAddressLocCsa: [],
    companyEmailStatus: [],
    companySegments: [],
    companyClassPrimaryTrade: [],
    companyClassTrades: [],
    companyClassNAICSDescriptions: [],
    companyClassSICDescriptions: [],
    companyServices: [],
    companyFactBusinessStructure: [],
    companyFactPublicPrivate: [],
    companyFactOwnershipType: [],
    companyStatEmployeesRange: [],
    companyStatSalesRange: [],
    companyAffinities: [],
    companyAffiliations: [],
    companyIndWorkTypes: [],
    companyIndWorkLocations: [],
    companyIndWorkCredentials: [],
    companyProfileTypes: [],
    companyProfileAssociations: [],
    companyProfileManufacturers: [],
    companyProfileOnlineProfiles: [],
    companyProfileUtilities: [],
    companyLicenseTypes: [],
    companyRegLocalRegistrationStatus: [],
    companyRegLocalRegistrar: [],
    companyRegLocalRegistrarRegion: [],


    companyLocPlaceDesc: [],
    searchTypes: [],
    status: {
        status: 'idle',
        error: undefined,
    }
};

const metaDataServices = [
    {
        id: 'contactTitle',
        service: () => getContactTitle()
    },
    {
        id: 'contactEmailStatus',
        service: () => getContactEmailStatus()
    },
    {
        id: 'companyAddressRegion',
        service: () => getCompanyAddressRegion()
    },
    {
        id: 'companyAddressLocMsa',
        service: () => getCompanyAddressLocMsa()
    },
    {
        id: 'companyAddressRdi',
        service: () => getCompanyAddressRdi()
    },
    {
        id: 'companyAddressLocCsa',
        service: () => getCompanyAddressLocCsa()
    },
    {
        id: 'companyEmailStatus',
        service: () => getCompanyEmailStatus()
    },
    {
        id: 'companySegments',
        service: () => getCompanySegments()
    },
    {
        id: 'companyClassPrimaryTrade',
        service: () => getCompanyClassPrimaryTrade()
    },
    {
        id: 'companyClassTrades',
        service: () => getCompanyClassTrades()
    },
    {
        id: 'companyClassNAICSDescriptions',
        service: () => getCompanyClassNAICSDescriptions()
    },
    {
        id: 'companyClassSICDescriptions',
        service: () => getCompanyClassSICDescriptions()
    },
    {
        id: 'companyServices',
        service: () => getCompanyServices()
    },
    {
        id: 'companyFactBusinessStructure',
        service: () => getCompanyFactBusinessStructure()
    },
    {
        id: 'companyFactPublicPrivate',
        service: () => getCompanyFactPublicPrivate()
    },
    {
        id: 'companyFactOwnershipType',
        service: () => getCompanyFactOwnershipType()
    },
    {
        id: 'companyStatEmployeesRange',
        service: () => getCompanyStatEmployeesRange()
    },
    {
        id: 'companyStatSalesRange',
        service: () => getCompanyStatSalesRange()
    },
    {
        id: 'companyAffinities',
        service: () => getCompanyAffinities()
    },
    {
        id: 'companyAffiliations',
        service: () => getCompanyAffiliations()
    },
    {
        id: 'companyIndWorkTypes',
        service: () => getCompanyIndicatorWorkTypes()
    },
    {
        id: 'companyIndWorkLocations',
        service: () => getCompanyIndicatorWorkLocations()
    },
    {
        id: 'companyIndWorkCredentials',
        service: () => getCompanyIndicatorWorkCredentials()
    },
    {
        id: 'companyProfileTypes',
        service: () => getCompanyProfileTypes()
    },
    {
        id: 'companyProfileAssociations',
        service: () => getCompanyProfileAssociations()
    },
    {
        id: 'companyProfileManufacturers',
        service: () => getCompanyProfileManufacturers()
    },
    {
        id: 'companyProfileOnlineProfiles',
        service: () => getCompanyProfileOnlineProfiles()
    },
    {
        id: 'companyProfileUtilities',
        service: () => getCompanyProfileUtilities()
    },
    {
        id: 'companyLicenseTypes',
        service: () => getCompanyLicenseTypes()
    },
    {
        id: 'companyRegLocalRegistrationStatus',
        service: () => getCompanyRegLocalRegistrationStatus()
    },
    {
        id: 'companyRegLocalRegistrar',
        service: () => getCompanyRegLocalRegistrar()
    },
    {
        id: 'companyRegLocalRegistrarRegion',
        service: () => getCompanyRegLocalRegistrarRegion()
    },
];

export const getMetaData = createAsyncThunk('toolbeltdata/getMetaData',
    async () => {
    const _metaData = await Promise.all(
        map(metaDataServices, async(metaData) => {
            const result = await metaData.service();
            return ({
                id: metaData.id,
                value: result
            })
        })
    );
    return _metaData;
    });

export const refreshMetaData = createAsyncThunk('toolbeltdata/refreshMetaData',
    async () => {
        const _metaData = await Promise.all(
            map(metaDataServices, async(metaData) => {
                const result = await metaData.service();
                return ({
                    id: metaData.id,
                    value: result
                })
            })
        );
        return _metaData;
    });


const metaDataSlice = createSlice({
    name: 'metaData', initialState,
    reducers: {
    },
    extraReducers: {
        [getMetaData.pending]: (state, action) => {
            state.status.status = 'loading';
        },
        [getMetaData.fulfilled]: (state, action) => {
            map(action.payload, (metaData) => {
                set(state, `${metaData.id}`, metaData.value);
            });
            state.status.status = 'succeeded';
        },
        [getMetaData.rejected]: (state, action) => {
            state.status.status = 'failed';
            state.status.error = action?.error?.message
        },
        [refreshMetaData.pending]: (state, action) => {
            state.status.status = 'loading';
        },
        [refreshMetaData.fulfilled]: (state, action) => {
            map(action.payload, (metaData) => {
                set(state, `${metaData.id}`, metaData.value);
            });
            state.status.status = 'succeeded';
        },
        [refreshMetaData.rejected]: (state, action) => {
            state.status.status = 'failed';
            state.status.error = action?.error?.message
        },

    }
});


export default metaDataSlice.reducer;
