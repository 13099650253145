import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import config from '../../config'
import * as Api from '../../common/Api';
import {LIST_STATUSES} from "../../common/metadata/ListStatuses";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    adminList: null,
};


const LIST_ACTION_URL_BASE = `${config.services.ATLAS.apiUrl}/managed-lists`;

export const getAdminListById = createAsyncThunk('adminLists/get-by-id', async (id) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-lists/${id}`;
    const adminList = await Api.get(true, URL);
    return adminList;
});

export const getAdminListWithFilter = createAsyncThunk('adminLists/get-by-id-with-filter', async ({id, filter}) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-lists/${id}`;
    const adminList = await Api.get(true, URL, filter);
    return adminList;
});


export const processList = createAsyncThunk('adminLists/process',
    async (id) => {
        const PROCESS_LIST = `${LIST_ACTION_URL_BASE}/${id}/process`;
        return await Api.post(true,
            PROCESS_LIST,
            {
                options: {
                    processToStatus: LIST_STATUSES.READY.id,
                }
            }
        );
    });

export const syncList = createAsyncThunk('adminLists/sync',
    async (id) => {
        const SYNC_LIST = `${LIST_ACTION_URL_BASE}/${id}/sync`;
        return await Api.post(true,
            SYNC_LIST,
            {
                options: {
                    processToStatus: LIST_STATUSES.READY.id,
                }
            }
        );
    });

export const reSyncList = createAsyncThunk('adminLists/re-sync',
    async (id) => {
        const SYNC_LIST = `${LIST_ACTION_URL_BASE}/${id}/re-sync`;
        return await Api.post(true,
            SYNC_LIST,
            {
                options: {
                    processToStatus: LIST_STATUSES.READY.id,
                }
            }
        );
    });

export const downloadList = createAsyncThunk('adminLists/download',
    async ({id, fileName}) => {
        const DOWNLOAD_LIST = `${LIST_ACTION_URL_BASE}/${id}/download`;
        return await Api.post(true,
            DOWNLOAD_LIST,
            {
                options: {
                },
                data: {
                    fileName: fileName
                }
            }
        );
    });
export const normalizeList = createAsyncThunk('adminLists/normalize',
    async (id) => {
        const NORMALIZE_LIST = `${LIST_ACTION_URL_BASE}/${id}/normalize`;
        return await Api.post(true,
            NORMALIZE_LIST,
            {
                options: {
                    processToStatus: LIST_STATUSES.NORMALIZED.id
                }
            }
        );
    });


export const parseList = createAsyncThunk('adminLists/parse',
    async (id) => {
        const NORMALIZE_LIST = `${LIST_ACTION_URL_BASE}/${id}/process`;
        return await Api.post(true,
            NORMALIZE_LIST,
            {
                options: {
                    processToStatus: LIST_STATUSES.PARSED.id
                }
            }
        );
    });

export const matchList = createAsyncThunk('adminLists/match',
    async ({id, matchTemplateId}) => {
        const URL = `${LIST_ACTION_URL_BASE}/${id}/match`;
        return await Api.post(true,
            URL,
            {
                options: null,
                data: {
                    matchTemplateId: matchTemplateId
                }
            }
        );
    });

const adminListSlice = createSlice({
    name: 'adminListSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.adminList = null;
            return state;
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getAdminListById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
            state.download = undefined;
        },
        [getAdminListById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.adminList = action.payload;
            }
        },
        [getAdminListById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve managed list';
        },
        [getAdminListWithFilter.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getAdminListWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.adminList = action.payload;
            }
        },
        [getAdminListWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve managed list';
        },

        [processList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [processList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully process list';
            state.loading = false;
        },
        [processList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to process list';
            state.successMessage = undefined;
        },


        [normalizeList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [normalizeList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully normalize list';
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
            }
        },
        [normalizeList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to normalize list';
            state.successMessage = undefined;
        },


        [syncList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [syncList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully sync list';
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
            }
        },
        [syncList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to sync list';
            state.successMessage = undefined;
        },


        [downloadList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.download = undefined;
        },
        [downloadList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.loading = false;
            if (action.payload) {
                state.download = action.payload;
            }
        },
        [downloadList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to download this list';
            state.successMessage = undefined;
            state.download = undefined;
        },






        [parseList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [parseList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully parse list';
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
            }
        },
        [parseList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to parse list';
            state.successMessage = undefined;
        },


        [matchList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [matchList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully matched list';
            state.loading = false;
        },
        [matchList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to match list';
            state.successMessage = undefined;
        },

        [reSyncList.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [reSyncList.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = 'Successfully re-sync list';
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
            }
        },
        [reSyncList.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to re-sync list';
            state.successMessage = undefined;
        },


    }
});

export const { clearState } = adminListSlice.actions;
export default adminListSlice.reducer;
