import React, {useEffect} from 'react';
import _ from "lodash";
import {getFieldMappings, updateMappingAttributes} from './uploadSlice';
import {
    Form,
    FormGroup,
    Row,
    Col,
} from 'react-bootstrap';

import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

function MappingListSelection(props) {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFieldMappings());
    }, [dispatch]);
    const uploadMetaState = useSelector(state => state.upload);
    const fields = uploadMetaState.fieldMappings;
    const handleSelectMyFileField = function (key, header, required) {
        dispatch(updateMappingAttributes({key: key, header: header, required: required}));
    }

    return (
        <Form>
            <FormGroup>
                <Container className={"mappingContainer"}>
                    <Row className={"mappingHeader"}>
                        <Col md={6}> Fields </Col>
                        <Col md={6}> My File Fields </Col>
                        {/*<Col md={3}> Map Status</Col>*/}
                    </Row>
                    <div className={"mappingRecordContainer"}>
                        {fields && fields.map((field, idx) => {
                            const selectedHeaderField = _.find(uploadMetaState.mappingAttributes, (o) => {return o.key === field.key}) ?  _.find(uploadMetaState.mappingAttributes, (o) => {return o.key === field.key}).header : '';
                            const alreadyUsedHeader = uploadMetaState.mappingAttributes ? uploadMetaState.mappingAttributes.map((o) => {return o.header}) : '';
                            const toDisplayHeader = _.concat(selectedHeaderField, _.difference(uploadMetaState.fileHeaders, alreadyUsedHeader)) ;
                            const options = toDisplayHeader && toDisplayHeader.map(header => {return {value: header, label: header}});

                            return (
                                <Row key={`mappingRecord-${idx}`} className={"mappingRecord"}>
                                    <Col md={6}>
                                        {field.display}
                                    </Col>
                                    <Col md={6}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="color"
                                            options={options}
                                            menuPortalTarget={document.body}
                                            menuPosition={'absolute'}
                                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                            onChange={(v) => handleSelectMyFileField(field.key, v ? v.value : undefined, field.required)}
                                            placeholder={'select a field'}
                                            value={{value: selectedHeaderField, label: selectedHeaderField}}
                                            isClearable={selectedHeaderField ? true : false}

                                        />
                                    </Col>
                                    {/*<Col md={3}>*/}
                                    {/*    { selectedHeaderField && 'Mapped'}*/}
                                    {/*    { !selectedHeaderField && field.required && (<span style={{color: '#c13727'}}> Required</span>)}*/}
                                    {/*    { !selectedHeaderField && !field.required && 'Unmapped'}*/}
                                    {/*</Col>*/}
                                </Row>
                            )
                        })}
                        {(fields && fields.length === 0) && (
                            <div className="mapping-spinner">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                            </div>
                        )}
                    </div>
                </Container>
            </FormGroup>
        </Form>
    );
}


export default MappingListSelection;
