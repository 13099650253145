/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {getManagedContacts, getManagedContactsCount, setCurrentPage, setOrder, setSelectedId} from "./managedContactsSlice";

import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import TableWithPaging from '../../common/components/TableWithPaging';
import CheckBox from "../../common/components/CheckBox";
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faExternalLink} from '@fortawesome/pro-solid-svg-icons';
import LinkButton from '../../common/components/LinkButton';
import {useHistory} from "react-router-dom";
import {Row, Col} from 'react-bootstrap';
import TextWrap from "../../common/components/TextWrap";

const ManagedContactsTable = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const managedContactsState = useSelector((state) => state.managedContacts);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getManagedContactsCount());
        dispatch(getManagedContacts());
    }, [props.lastReload, dispatch]);

    // Generate column header for table
    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'contactId',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <div>
                        <CheckBox
                            id={`check-${row.contactId}`}
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.contactId,
                                    companyId: row.companyId,
                                    name: row.name,
                                    type: row.type,
                                    status: row.status,
                                    isChecked: value
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'contactId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactId) {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row'}}>                              
                             <LinkButton style={{fontSize: '100%', fontWeight: 400,'padding':'0px'}} onClick={() => history.push(`/admin/d/companies/${row.companyId}/edit?selectedContactId=${row.contactId}`)}>
                            <TextHoverDisplay canShowCopyToClipboard={false}
                                    truncatedText={row.contactId.substring(0, 13)}
                                    fullText={row.contactId} minWidth={116}
                                />
                            </LinkButton>
                            <TextHoverDisplay canShowText={false}
                                    truncatedText={row.contactId.substring(0, 13)}
                                    fullText={row.contactId} minWidth={15}
                                /></div>
                        );
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Identity',
                property: 'contactIdentity',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactIdentity) {
                        return <TextHoverDisplay style={{'text-wrap': 'nowrap'}}
                            truncatedText={row.contactIdentity.substring(0, 13)}
                            fullText={row.contactIdentity}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Score',
                property: 'contactScore',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactScore) {
                          
                        return  <TextWrap propertyvalue={row.contactScore}></TextWrap>                    
                    }
                    return <span></span>
                }
            },

            {
                id: 5,
                name: 'Given Name',
                property: 'contactNameGivenName',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactNameGivenName) {
                          
                        return  <TextWrap propertyvalue={row.contactNameGivenName}></TextWrap>                    
                    }
                    return <span></span>                    
                }
            },
            {
                id: 6,
                name: 'Middle Name',
                property: 'contactNameMiddleName',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactNameMiddleName) {                          
                        return  <TextWrap propertyvalue={row.contactNameMiddleName}></TextWrap>                    
                    }
                    return <span></span>                   
                }
            },
            {
                id: 7,
                name: 'Surname',
                property: 'contactNameSurname',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactNameSurname) {                          
                        return  <TextWrap propertyvalue={row.contactNameSurname}></TextWrap>                    
                    }
                    return <span></span>                      
                }
            },
            {
                id: 8,
                name: 'Suffix',
                property: 'contactNameMaturitySuffix',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    return <span>{row.contactNameMaturitySuffix ?? ''}</span>
                }
            },
            {
                id: 9,
                name: 'Title',
                property: 'contactTitle',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactTitle) {                          
                        return  <TextWrap propertyvalue={row.contactTitle}></TextWrap>                    
                    }
                    return <span></span> 
                }
            },
            {
                id: 10,
                name: 'Phone',
                property: 'contactPhone',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactPhone) {                          
                        return  <TextWrap propertyvalue={row.contactPhone}></TextWrap>                    
                    }
                    return <span></span> 
                }
            },
            {
                id: 11,
                name: 'Email',
                property: 'contactEmail',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactEmail) {                          
                        return  <TextWrap propertyvalue={row.contactEmail}></TextWrap>                    
                    }
                    return <span></span>
                }
            },
            {
                id: 12,
                name: 'Is Primary',
                property: 'contactRoleIsPrimary',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.contactRoleIsPrimary) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 13,
                name: 'First Touch',
                property: 'contactSourceFirstTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.contactSourceFirstTouch) {
                         return <span style={{'text-wrap': 'nowrap'}}>{DateTime.fromISO(row.contactSourceFirstTouch).toLocaleString(DateTime.DATETIME_MED)}</span>
                    }
                    return <span></span>
                }
            },
            {
                id: 14,
                name: 'Last Touch',
                property: 'contactSourceLastTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.contactSourceLastTouch) {
                        return <span style={{'text-wrap': 'nowrap'}}>{DateTime.fromISO(row.contactSourceLastTouch).toLocaleString(DateTime.DATETIME_MED)}</span>;
                    }
                }
            },
            {
                id: 15,
                name: 'Company Id',
                property: 'companyId',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyId) {
                        return (                            
                                <div style={{display: 'flex', flexDirection: 'row'}}>                              
                                <LinkButton style={{fontSize: '100%', fontWeight: 400,'padding':'0px'}} onClick={() => history.push(`/admin/d/companies/${row.companyId}/edit`)}>
                                <TextHoverDisplay canShowCopyToClipboard={false}
                                        truncatedText={row.companyId.substring(0, 13)}
                                        fullText={row.companyId} minWidth={117}
                                    />
                                </LinkButton>
                                <TextHoverDisplay canShowText={false}
                                        truncatedText={row.companyId.substring(0, 13)}
                                        fullText={row.companyId} minWidth={15}
                                    /></div>
                        );
                    }
                    return <span></span>
                }
            },
            {
                id: 16,
                name: 'Company Identity',
                property: 'companyIdentity',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyIdentity) {
                        return <TextHoverDisplay style={{'text-wrap': 'nowrap'}}
                            truncatedText={row.companyIdentity.substring(0, 13)}
                            fullText={row.companyIdentity}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 17,
                name: 'Company Score',
                property: 'companyScore',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyScore) {                          
                        return  <TextWrap propertyvalue={row.companyScore}></TextWrap>                    
                    }
                    return <span></span>
                }
            },
            {
                id: 18,
                name: 'Company Name',
                property: 'companyName',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyName) {                          
                        return  <TextWrap propertyvalue={row.companyName}></TextWrap>                    
                    }
                    return <span></span>                   
                }
            },
            {
                id: 19,
                name: 'Company URL',
                property: 'companyUrl',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.url) {
                        return (
                            <a target='_blank'
                               href={`${row.url}`}
                               rel='noopener noreferrer'
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={faExternalLink}
                                    style={{marginRight: '5px', fontSize: '12px','text-wrap': 'nowrap'}}
                                />
                                <TextWrap propertyvalue={`${row.url.substring(0, 20)}...`}></TextWrap> 
                            </a>
                        )
                    }
                }
            },
            {
                id: 20,
                name: 'Company Delivery Line 1',
                property: 'companyAddressDeliveryLine1',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressDeliveryLine1) {                        
                        return  <TextWrap propertyvalue={row.companyAddressDeliveryLine1}></TextWrap> 
                    }
                    return <span></span>                    
                }
            },
            {
                id: 21,
                name: 'Company Delivery Line 2',
                property: 'companyAddressDeliveryLine2',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressDeliveryLine2) {                        
                        return  <TextWrap propertyvalue={row.companyAddressDeliveryLine2}></TextWrap> 
                    }
                    return <span></span>                     
                }
            },
            {
                id: 22,
                name: 'Company City',
                property: 'companyAddressCity',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressCity) {                        
                        return  <TextWrap propertyvalue={row.companyAddressCity}></TextWrap> 
                    }
                    return <span></span>                     
                }
            },
            {
                id: 23,
                name: 'Company Region',
                property: 'companyAddressRegion',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <span>{row.companyAddressRegion ?? ''}</span>
                }
            },
            {
                id: 24,
                name: 'Company Postal Code',
                property: 'companyAddressPostalCode',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <span>{row.companyAddressPostalCode ?? ''}</span>
                }
            },
            {
                id: 25,
                name: 'Company Phone',
                property: 'companyPhone',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyPhone) {                        
                        return  <TextWrap propertyvalue={row.companyPhone}></TextWrap>
                    }
                    return <span></span>                  
                }
            },
            {
                id: 26,
                name: 'Company Email',
                property: 'companyEmail',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyEmail) {
                        return  <TextWrap propertyvalue={row.companyEmail}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 27,
                name: 'Company Primary Trade',
                property: 'companyClassPrimaryTrade',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyClassPrimaryTrade) {
                        return  <TextWrap propertyvalue={row.companyClassPrimaryTrade}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 28,
                name: 'Company Trades',
                property: 'companyClassTrades',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyClassTrades) {
                        return  <TextWrap propertyvalue={row.companyClassTrades}></TextWrap> 
                    }
                    return <span></span>                    
                }
            },
            {
                id: 29,
                name: 'Company Contacts',
                property: 'companyStatContactCnt',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <span>{row.companyStatContactCnt ?? ''}</span>
                }
            },
            {
                id: 30,
                name: 'Company First Touch',
                property: 'companySourceFirstTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.companySourceFirstTouch) {
                        return <TextWrap propertyvalue={DateTime.fromISO(row.companySourceFirstTouch).toLocaleString(DateTime.DATETIME_MED)}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 31,
                name: 'Company Last Touch',
                property: 'companySourceLastTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.companySourceLastTouch) {
                        return <TextWrap propertyvalue={DateTime.fromISO(row.companySourceLastTouch).toLocaleString(DateTime.DATETIME_MED)}></TextWrap>
                    }
                    return <span></span>
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getManagedContacts());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div id='contact-table-container'>
            <TableWithPaging
                id={'source-table-with-paging'}
                headers={headers}
                rows={managedContactsState.contacts}
                countOfResults={managedContactsState.totalRecords}
                resultsPerPage={managedContactsState.pageSize}
                currentPage={managedContactsState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
};


ManagedContactsTable.propTypes = {
    managedContacts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

ManagedContactsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    managedContacts: []
};

export default ManagedContactsTable;
