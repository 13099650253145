import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";
import SectionCollapse from '../../SectionCollapse';
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import styled from "styled-components";
import { CheckBoxContainer, GridButton } from '../../StyledComponents';
import CheckBox from '../../../../../common/components/CheckBox';
import CompanyCoreAttributes from './CompanyCoreAttributes';
import ContactCoreAttributes from './ContactCoreAttributes';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from  "../../../../../common/Constants";

const attributeColumns = [
    {key: 'nonstandardProperty', name: 'Property'},
    {key: 'nonstandardValue', name: 'Value'},   
    {key: '', name: ''},
    {key: 'standardProperty', name: 'Property'},
    {key: 'standardValue', name: 'Value'},   
];

const getAttributeRows = (nonstandardAttributes, obj) => {
    const standardAttributes = [
        {standardProperty: 'verified', standardValue: obj.verified ? (<FontAwesomeIcon color={APP_COLORS['green']} icon={faCheck} />) :<></>,
        standardAction: (<GridButton disabled>VERIFY</GridButton>)},    
        {standardProperty: 'verifiedOn', standardValue: obj.verifiedOn, standardAction: ''},
        {standardProperty: 'invalidate', standardValue: obj.invalidate ? (<FontAwesomeIcon color={APP_COLORS['green']} icon={faCheck} />) :<></>,
        standardAction: (<GridButton disabled>INVALIDATE</GridButton>)},
        {standardProperty: 'invalidatedOn', standardValue: obj.invalidatedOn, standardAction: ''},
        {standardProperty: 'invalidatedReason', standardValue: obj.invalidatedReason, standardAction: ''},
        {standardProperty: 'origin', standardValue: obj.origin, standardAction: ''},
        {standardProperty: 'originId', standardValue: obj.originId, standardAction: ''},
        {standardProperty: 'created', standardValue: obj.created, standardAction: ''},
        {standardProperty: 'updated', standardValue: obj.updated, standardAction: ''}
    ]

    let tempRows = [];

    for (let i = 0; i < Math.max(standardAttributes.length, nonstandardAttributes.length); i++) {
        tempRows.push({
            ...({nonstandardProperty: (nonstandardAttributes[i] ? nonstandardAttributes[i].property : ''), 
                nonstandardValue: (nonstandardAttributes[i] ? nonstandardAttributes[i].value : ''), 
                nonstandardAction: (nonstandardAttributes[i] ? nonstandardAttributes[i].action : '')}),
            ...(standardAttributes[i] ?? {})
        })
    }

    for (let i = 0; i < tempRows.length; i++) {
        tempRows[i].nonstandardProperty = <b>{tempRows[i].nonstandardProperty}</b>
        tempRows[i].standardProperty = <b>{tempRows[i].standardProperty}</b>
    }

    return tempRows;
} 

const CoreAttributes = (props) => {
    const {
        isCallFromCompany=false
    } = props;
    console.log(isCallFromCompany);
    return (
        <SectionCollapse header={'CORE ATTRIBUTES'} isOpen={true} >
            <Row>
                <Col md={6}>
                    <CompanyCoreAttributes companyId={props.companyId} getAttributeRows={getAttributeRows} attributeColumns={attributeColumns} />
                </Col>
                <Col md={6}>
                    <ContactCoreAttributes contactId={props.contactId} getAttributeRows={getAttributeRows} attributeColumns={attributeColumns} isCallFromCompany={isCallFromCompany} />
                </Col>
            </Row>
        </SectionCollapse>
    );
}

export default CoreAttributes;