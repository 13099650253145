import React, {useEffect, useState} from 'react';

import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {resetUploadState, updateImporting, updateImportStatus} from "./uploadSlice";

import Modal from "../../../common/components/Modal";
import ListTypeSelection from "./ListTypeSelection";
import InputListSelection from "./InputListSelection";
import NameListSelection from "./NameListSelection";
import MappingListSelection from "./MappingSelection";
import Review from "./Review";

import './UploadListDialog.scss'
import config from "../../../config";
import _ from "lodash";
import * as Api from "../../../common/Api";
import Spinner from "react-bootstrap/Spinner";
import isEmail from 'validator/es/lib/isEmail';
import * as cache from "../../../common/Cache";

function UploadListDialog(props) {
    const {
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const clearStateAndClose = () => {
        dispatch(resetUploadState({listTypeId: "IMPORT_COMPANY", importRestricted: false}));
        handleClose();
    };

    useEffect(() => {
        const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
        const restrictedFeatures = _.get(account.organization, 'config.features');
        if (!restrictedFeatures || restrictedFeatures.length === 0) {
            dispatch(resetUploadState({listTypeId: "IMPORT_OVERLAY", importRestricted: true}));
        } else if (restrictedFeatures && restrictedFeatures.length > 0) {
            const featureEnrich = restrictedFeatures.find(service => service === 'enrich');
            if (!featureEnrich) {
                dispatch(resetUploadState({listTypeId: "IMPORT_OVERLAY", importRestricted: true}));
            }
        } else {
            // When open dialog, we clear state. This forces a rerender which is VERY confusing to troubleshoot
            dispatch(resetUploadState({listTypeId: "IMPORT_COMPANY", importRestricted: false}));
        }
    }, [dispatch]);
    const [uploadFile, setUploadFile] = useState(null);
    const handleUploadFile = (files) => {
        if (files) {
            if (files.length && files.length > 0) {
                setUploadFile(files[0]);
            }
        }
    };
    const [currentStep, setCurrentStep] = useState(0);
    const isValid = true;


    const uploadMetaState = useSelector(state => state.upload);

    const enableNextButton = () => {
        switch (currentStep) {
            case 0: {
                if (uploadMetaState.listType) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 1: {
                if (uploadMetaState.fileUrl || (uploadMetaState.rawTextInput && uploadMetaState.rawTextInput.length > 0)) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 2: {
                let NAME_VALIDATE_REX = /^[A-Za-z0-9-\\ \\_\\.\\-\\(\\)]+$/;
                if (uploadMetaState.listName && NAME_VALIDATE_REX.test(uploadMetaState.listName)) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 3: {
                // const onlyRequiredFieldMapping =  _.filter(uploadMetaState.fieldMappings, (o) => {
                //      return o.required === true;
                //  })
                //  const onlyRequiredMappingList = _.filter(uploadMetaState.mappingAttributes, (o) => {
                //      return o.required === true;
                //  })
                //
                //  if (onlyRequiredFieldMapping && onlyRequiredMappingList && onlyRequiredFieldMapping.length === onlyRequiredMappingList.length)
                //      return true;
                //  else return false;

                const mappingAttributes = _.filter(uploadMetaState.mappingAttributes, (o) => o.header !== '');
                return mappingAttributes.length > 0;
                break;
            }
        }
    }

    function enableImportButton() {
        // 1: Validate notifyByEmailOnSuccessOrFailure
        if (uploadMetaState.notifyByEmailOnSuccessOrFailure) {
            const emails = _.split(uploadMetaState.notifyByEmailOnSuccessOrFailure, ';');
           for ( const email of emails) {
               if (!isEmail(_.trim(email))) return false;
           }
        }
        return true;
    }

    const handleImport = async () => {
        // dispatch importing status
        dispatch(updateImporting(true));
        dispatch(updateImportStatus(undefined));
        // Build mapping payload
        const IMPORT_LIST_API = `${config.services.ATLAS.apiUrl}/lists/import`;
        const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
        const organizationId = account?.organization?.id;
        let mappingPayload = undefined;
        const mappingList = _.reduce(uploadMetaState.mappingAttributes, function (result, a) {
            const obj = {};
            obj[a.key] = a.header;
            return Object.assign(result, obj)
        }, {});

        const fileHeaderFields = _.reduce(uploadMetaState.fileHeaders, function (result = [], a, index){
            result.push(`${a}_${index}`);
            return result;
        }, []);

        const inputSource = uploadMetaState.inputSource;
        if ('TEXT_INPUT' === inputSource) {
            try {
                mappingPayload = {
                    options: null,
                    data: {
                        type: uploadMetaState.listType,
                        name: uploadMetaState.listName,
                        description: uploadMetaState.listDescription,
                        dataType: 'text',
                        mapping: {
                            listMappingId: uploadMetaState.fieldMappings && uploadMetaState.fieldMappings.length > 0 && uploadMetaState.fieldMappings[0].listMappingId,
                            attributes: mappingList,
                            fields: fileHeaderFields
                        },
                        text: uploadMetaState.textInput,
                        config: {notifyByEmailOnSuccessOrFailure: uploadMetaState.notifyByEmailOnSuccessOrFailure}
                    }
                }
                console.log(mappingPayload);
                await Api.post(true, IMPORT_LIST_API, mappingPayload);
                dispatch(updateImporting(false));
                dispatch(updateImportStatus('SUCCESS'));
            } catch (e) {
                console.log(e);
                dispatch(updateImporting(false));
                dispatch(updateImportStatus('FAIL'));
            }
        } else {
            const PRE_SIGN_URL = `${config.services.ATLAS.apiUrl}/lists/upload`;
            const content = {
                options: null,
                data: {
                    fileName: uploadMetaState.fileUrl,
                    organizationId: organizationId,
                }
            }
            try {
                const signedContent = await Api.post(true, PRE_SIGN_URL, content);
                const uploadResult = await fetch(signedContent.uploadUrl, {
                    method: 'PUT',
                    headers: {'Content-Type': 'text/plain'},
                    body: uploadFile
                })
                console.log(uploadResult);
                mappingPayload = {
                    options: null,
                    data: {
                        type: uploadMetaState.listType,
                        name: uploadMetaState.listName,
                        description: uploadMetaState.listDescription,
                        dataType: uploadMetaState.fileType,
                        dataFileName: uploadMetaState.fileUrl,
                        dataRef: signedContent.uploadRef,
                        mapping: {
                            listMappingId: uploadMetaState.fieldMappings && uploadMetaState.fieldMappings.length > 0 && uploadMetaState.fieldMappings[0].listMappingId,
                            attributes: mappingList,
                            fields: fileHeaderFields
                        },
                        config: {notifyByEmailOnSuccessOrFailure: uploadMetaState.notifyByEmailOnSuccessOrFailure}
                    }
                }
                await Api.post(true, `${IMPORT_LIST_API}`, mappingPayload);
                dispatch(updateImporting(false));
                dispatch(updateImportStatus('SUCCESS'));
            } catch (e) {
                console.log(e);
                dispatch(updateImporting(false));
                dispatch(updateImportStatus('FAIL'));
            }
        }
    }

    const steps = [
        {
            title: 'List Type',
            container: <ListTypeSelection/>
        },
        {
            title: 'Input List',
            container: <InputListSelection handleUploadFile={handleUploadFile}/>
        },
        {
            title: 'Name List',
            container: <NameListSelection/>
        },
        {
            title: 'Mapping List',
            container: <MappingListSelection/>
        },
        {
            title: 'Review',
            container: <Review/>
        }

    ];

    return (
        <Modal
            dialogClassName={'upload-dialog'}
            showClose={true}
            title={'Import List'}
            show={true}
            handleClose={clearStateAndClose}
            footer={<Footer
                backDisabled={(currentStep <= 0) || !isValid}
                nextDisabled={(currentStep >= (steps.length - 1)) || !enableNextButton()}
                importDisabled = {(!enableImportButton())}
                handleClose={clearStateAndClose}
                handleBack={() => {
                    if (currentStep >= 1) {
                        setCurrentStep(currentStep - 1)
                    }

                }}
                handleNext={() => {
                    if (currentStep < steps.length) {
                        setCurrentStep(currentStep + 1)
                    }
                }}
                showImportButton={currentStep === 4 && uploadMetaState.importStatus === undefined}
                showDoneButton={currentStep === 4 && (uploadMetaState.importing === false && (uploadMetaState.importStatus === 'SUCCESS' || uploadMetaState.importStatus === 'FAIL'))}
                handleImport={handleImport}
            />}
        >
            <MultiSteps
                steps={steps}
                currentStep={currentStep}
            />
        </Modal>
    );
}

function Footer(props) {
    const {
        backDisabled,
        nextDisabled,
        handleClose,
        handleBack,
        handleNext,
        handleImport,
        showImportButton,
        showDoneButton,
        importDisabled,
    } = props;

    const uploadMetaState = useSelector(state => state.upload);
    return (
        <div className={'footer'}>
            <div>
                <Button
                    disabled={backDisabled}
                    onClick={handleBack}
                    variant='outline-secondary'
                    style={{marginLeft: '10px'}}
                >
                    Back
                </Button>
            </div>
            <div>
                {showDoneButton && (<Button
                    onClick={handleClose}
                    variant='info'
                    style={{marginLeft: '10px'}}
                >
                    DONE
                </Button>)}
                {showImportButton && (
                    <Button
                        disabled={importDisabled}
                        onClick={handleImport}
                        variant='info'
                        style={{marginLeft: '10px'}}
                    >
                        {uploadMetaState.importing && (
                            <Spinner
                                style={{marginRight: '5px', verticalAlign: 'baseline'}}
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )} Import
                    </Button>
                )}
                {!showDoneButton && !showImportButton && (
                    <Button
                        disabled={nextDisabled}
                        onClick={handleNext}
                        variant={'info'}
                    >
                        Next
                    </Button>
                )}
                <Button
                    onClick={handleClose}
                    variant='outline-secondary'
                    style={{marginLeft: '10px'}}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );

}

function MultiSteps(props) {
    const {
        steps,
        currentStep
    } = props;

    const getStepStyles = (indx, currentStep, length) => {
        if (indx === currentStep) {
            return '-active';
        }
        return '';
    };

    const renderSteps = () => {
        return steps.map((s, i) => (
            <li
                className={`step${getStepStyles(i, currentStep, steps.length)}`}
                key={i}
                value={i}
            >
                <span>{i + 1} | {s.title}</span>
            </li>
        ))

    };
    return (
        <div className={'multiSteps'}>

            <ol className={'navigator'}
            >
                {renderSteps()}
            </ol>


            <div className={'container'}>
                {steps[currentStep].container}
            </div>

        </div>

    );

}


export default UploadListDialog;
