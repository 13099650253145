/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';

import assign from 'lodash/assign';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {APP_COLORS} from '../Constants';

import './AppliedFilter.scss';
import ReactTooltip from "react-tooltip";

const AppliedFilter = props => {
    const [detail, setDetail] = useState('');
    const [moreDetail, setMoreDetail] = useState(false);

    useEffect(() => {
        if (props.detail.length > 45) {
            setDetail(props.detail.substring(0, 45));
            setMoreDetail(true);
        } else {
            setDetail(props.detail);
            setMoreDetail(false);
        }
    }, [props.detail]);

    return (
        <div style={assign({}, props.style, {
            backgroundColor: props.color,
            border: '1px solid',
            borderColor: APP_COLORS['gray-600'],
            borderRadius: '.25rem',
            marginBottom: 10,
            maxHeight: 45,
            maxWidth: 350
        })}>
            <div className="AppliedFilterContainer">
                <span className="filter-name">{props.name}</span>
                <div className="filter-detail">
                    {detail}
                    {moreDetail && (
                        <span>
                            <a data-tip data-for='clickme'
                               data-event='click'
                               className="detail-button"> ... </a>

                            <ReactTooltip
                                id='clickme'
                                className="detail-tooltip"
                                place='bottom'
                                effect='solid'
                                clickable={true}
                                globalEventOff="click"
                                overridePosition={ (
                                    { left, top },
                                    currentEvent, currentTarget, node) => {
                                    const d = document.documentElement;
                                    left = Math.min(d.clientWidth - node.clientWidth, left);
                                    top = Math.min(d.clientHeight - node.clientHeight, top);
                                    left = Math.max(0, left) - 120;
                                    top = Math.max(0, top) - 30;
                                    return { top, left }
                                } }
                            >
                                <div className="detail-popup">
                                    {props.detail}
                                </div>
                            </ReactTooltip>
                        </span>
                    )}
                </div>
                <Button
                    variant="Link"
                    className="text-muted filter-action"
                    onClick={() => props.handleRemove(props.id)}
                >
                    <FontAwesomeIcon color={APP_COLORS['gray-600']} icon={faTimes}/>
                </Button>
            </div>
        </div>
    )
};

AppliedFilter.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
    handleRemove: PropTypes.func,
};

AppliedFilter.defaultProps = {
    color: '#f4f0e2',
    name: '',
    detail: '',
    handleRemove: (e) => e,
};

export default AppliedFilter;
