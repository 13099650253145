/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";
import {APP_COLORS} from "../../Constants";

const FormContainer = styled.div `
  color: ${APP_COLORS['blue']};
  textarea {
    color: ${APP_COLORS['blue']};
  }

  .row {
    padding-bottom: 10px;
    align-items: baseline;
  }

  .panel {
    align-items: baseline;
  }

  .buttons {
    text-align: left;
  }
`;

export default FormContainer;
