/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import {useHistory} from "react-router-dom";

import {
    clearSelected,
    setSearchCriteria,
    getSourcesCount, getSources
} from "./sourcesSlice";

import SourcesTable from "./SourcesTable";
import FormTextInput from "../../common/components/FormTextInput";
import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

export default class Sources extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showImportDialog: false,
            lastReload: Date.now()
        };
    }

    render() {
        return (
            <SourcesComponent/>
        )
    }
}

function SourcesComponent(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Sources',
        href: '',
        isActive: true
    }];

    const sourcesState = useSelector(state => state.sources);
    const dispatch = useDispatch();

    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (sourcesState.alertMessage) {
            setAlertMessage(sourcesState.alertMessage);
            setShowAlert(true);
        }
    }, [sourcesState.alertMessage]);

    const isReload = sourcesState.reload === true;

    useEffect(() => {
        dispatch(getSourcesCount());
        dispatch(getSources());
    }, [isReload, dispatch]);

    const getActionItems = () => {
        const actionItems = [
            {
                label: 'EDIT',
                href: '',
                isActive: sourcesState?.selectedId.length === 1,
             onClickHandler: () => history.push(`/admin/sources/${sourcesState.selectedId[0]}/edit-source`)
            }
        ];
        return actionItems;
    };



    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Sources (${sourcesState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/sources/create-source')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by name, label or description...'}
                        value={sourcesState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {sourcesState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <SourcesTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
}

