/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import FormValidator from '../FormValidator';

import {useDispatch, useSelector} from "react-redux";
import {login, clearState} from "../account/accountSlice";

import logo from '../assets/img/ToolBelt_login_logo.png'

export default function Login() {
    const {isSuccess, isError, errorMessage} = useSelector(state => state.account);
    const dispatch = useDispatch();
    const history = useHistory();

    const [formValidated, setFormValidated] = useState(false);
    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');
    const [formErrors, setFormErrors] = useState(null);

    const [loading, setLoading] = useState(null);
    const [showAlert, setShowAlert] = useState(null);
    const [alertVariant, setAlertVariant] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [fromConfirmationEmailMessage, setFromConfirmationEmailMessage] = useState(null);
    const [showConfirmationEmailMessage, setShowConfirmationEmailMessage] = useState(null);
    const [invalidTokenMessage, setInvalidTokenMessage] = useState(null);
    const [showInvalidTokenMessage, setShowInvalidTokenMessage] = useState(null);
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const isFromConfirmationEmail = params.get('confirmed');
    const isInvalidToken = params.get('invalid');


    useEffect(() => {
        setShowConfirmationEmailMessage(isFromConfirmationEmail === 'true');
        setShowInvalidTokenMessage(isInvalidToken === 'true');
        if (isError) {
            // Note: the errorMessage are different on firefox and chrome.
            let _message = null;
            try {
                const error = JSON.parse(errorMessage);
                _message = error?.error?.message;
            } catch (err) {
                _message = errorMessage;
            }
            setFormValidated(false);
            setShowAlert(true);
            setAlertVariant('danger');
            setFromConfirmationEmailMessage(undefined);
            setShowConfirmationEmailMessage(false);
            setInvalidTokenMessage(undefined);
            setShowInvalidTokenMessage(false);
            if (_message === 'Unauthorized' || _message === 'login failed' || _message === 'login failed.') {
                setAlertMessage('Sign in failed. Please check your credentials and try again.');
            } else {
                setAlertMessage(`Sign in failed. There was a problem - ${_message}`);
            }

            setLoading(false);
        }

        if (isSuccess) {
            dispatch(clearState());
            history.push('/')
        }

        if (!isError && isFromConfirmationEmail === 'true') {
            setFromConfirmationEmailMessage(`Thanks for confirming your email. If this is your first time logging in please select forgot password and fill out the form to set your credentials`);
            setLoading(false);
        }

        if (!isError && isInvalidToken === 'true') {
            setInvalidTokenMessage(`This link has expired. If you've already confirmed your email trying resetting your password to login or contact support for additional help.`);
            setLoading(false);
        }

    }, [isError, isSuccess, errorMessage, dispatch, history]);

    function handleOnClick(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = document.getElementsByName("formLogin")[0];
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            setFormErrors(errors);
        } else {
            dispatch(clearState());

            setShowAlert(false);
            setFormValidated(true);
            setFormErrors(null);
            setLoading(true);

            const credentials = {
                email: formEmail,
                password: formPassword
            }
            dispatch(login({credentials}));
        }
    }

    const hasError = (formName, inputName, method) => formErrors
        && formErrors[inputName]
        && formErrors[inputName][method];

    return (
        <Container fluid style={{overflow: 'hidden'}}>
            <Row className="min-vh-100">
                <Col xl={6} lg={6} md={6} sm={6} className="bg-primary" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Image
                        src={logo}
                        width="350"
                        alt="ToolBeltData Logo"
                        fluid
                    />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Col xl={8} lg={8} md={12} sm={12}>
                        <Form noValidate validated={formValidated} name="formLogin">
                            <Alert
                                variant={'primary'}
                                show={showConfirmationEmailMessage}
                                onClose={() => setShowAlert(false)}
                                transition={false}
                            >
                                {fromConfirmationEmailMessage}
                            </Alert>
                            <Alert
                                variant={'danger'}
                                show={showInvalidTokenMessage}
                                onClose={() => setShowAlert(false)}
                                transition={false}
                            >
                                {invalidTokenMessage}
                            </Alert>
                            <div className="text-center">
                                <h2>Sign In</h2>
                            </div>
                            <Form.Group controlId="formLoginEmail">
                                <Form.Label>Your email</Form.Label>
                                <Form.Control
                                    size="md"
                                    type="email"
                                    name="email"
                                    placeholder="rick.deckard@example.com"
                                    isInvalid={
                                        hasError('formLogin', 'email', 'required')
                                        || hasError('formLogin', 'email', 'email')
                                    }
                                    onChange={(e) => setFormEmail(e.target.value)}
                                    data-validate='["required", "email"]'
                                    value={formEmail}
                                />
                                {hasError('formLogin', 'email', 'required') && (
                                    <Form.Control.Feedback type="invalid">
                                        Email is required.
                                    </Form.Control.Feedback>
                                )}
                                {!hasError('formLogin', 'email', 'required')
                                    && hasError('formLogin', 'email', 'email')
                                    && (
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    )}
                            </Form.Group>

                            <Form.Group controlId="formLoginPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    size="md"
                                    type="password"
                                    name="password"
                                    placeholder="8+ Characters Required"
                                    isInvalid={
                                        hasError('formLogin', 'password', 'required')
                                        || hasError('formLogin', 'password', 'minlen')
                                    }
                                    onChange={(e) => setFormPassword(e.target.value)}
                                    data-validate='["required", "minlen"]'
                                    data-param="8"
                                    value={formPassword}
                                />
                                {hasError('formLogin', 'password', 'required') && (
                                    <Form.Control.Feedback type="invalid">
                                        Password is required.
                                    </Form.Control.Feedback>
                                )}
                                {
                                    !hasError('formLogin', 'password', 'required')
                                    && hasError('formLogin', 'password', 'minlen')
                                    && (
                                        <Form.Control.Feedback type="invalid">
                                            Password must be at least 8 characters in length.
                                        </Form.Control.Feedback>
                                    )}
                            </Form.Group>
                            <Alert
                                variant={alertVariant}
                                show={showAlert}
                                onClose={() => setShowAlert(false)}
                                dismissible
                                transition={false}
                            >
                                {alertMessage}
                            </Alert>
                            <Button
                                variant="info"
                                type="submit"
                                size="md"
                                block
                                className="pt-3 pb-3"
                                disabled={loading}
                                onClick={(e) => handleOnClick(e)}
                            >
                                {!loading && <>SIGN IN</>}
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </Button>
                            <Form.Text className="text-center mt-2">
                                <Link to="recover" className="text-muted">Forgot Password?</Link>
                            </Form.Text>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}
