/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import CardContainer from "./CardContainer";
import IconBox from "./IconBox";
import CompanyBox from "./CompanyBox";
import ChannelsBox from "./ChannelsBox";
import ClassificationBox from "./ClassificationBox";
import ContactBox from "./ContactBox";
import ContactInfoBox from "./ContactInfoBox";
import AttributesBox from "./AttributesBox";
import CardActionBox from "./CardActionBox";

export default function CompanyCard(props) {
    const {
        mode,
        item
    } = props;

    return (
        <CardContainer>
            <IconBox mode={mode} />
            <CompanyBox item={item} />
            <ChannelsBox item={item} />
            <ClassificationBox item={item} />
            <ContactBox item={item} label={'Primary Contact'} />
            <ContactInfoBox item={item} />
            <AttributesBox item={item} />
            <CardActionBox disabled />
        </CardContainer>
    )
}
