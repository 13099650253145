/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import { useHistory } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Image} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import homeScreenImg from '../../common/assets/img/ToolBelt_home_screen.png'

export default function Home() {
    const history = useHistory();

    return (
        <Row noGutters>
            <Col xl={12} lg={12} md={12} sm={12} className="text-center text-muted">
                <Image
                    src={homeScreenImg}
                    rounded={true}
                    width={'400px'}
                /><br/>
                <p className="display-5 home font-weight-bold">Welcome, nice to see you!</p>
                <p className="display-5 home font-weight-light">You've made a great choice. Now let's get going.</p>
                <p>  <Button
                    data-testid="homeToExploreBtn"
                    className="btn-min-width-120 btn-height-35"
                    variant="info"
                    onClick={() => history.push('/org/explore')}
                >
                    START EXPLORING
                </Button></p>
            </Col>
        </Row>
    )
}
