/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

export function buildGeoJson(cubeQueryResult, cube) {
    // TODO This is wired up for 'Postal Code', make it more flexible
    // TODO Where does the company / contact intelligence live
    const geoJsonFeatures = cubeQueryResult.map((r) => {
        const count_companies = parseInt(r[`${cube}.count_companies`], 10);
        const count_contacts = !r[`${cube}.count_contacts`] ? 0 : parseInt(r[`${cube}.count_contacts`], 10);
        const company_address_city_region_formatted = `${r[`${cube}.company_address_city`]}, ${r[`${cube}.company_address_region`]}`
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [r[`${cube}.company_address_loc_city_longitude`], r[`${cube}.company_address_loc_city_latitude`]],
            },
            properties: {
                id: company_address_city_region_formatted,
                count_companies: count_companies,
                count_contacts: count_contacts,
                company_address_city: r[`${cube}.company_address_city`],
                company_address_region: r[`${cube}.company_address_region`],
                company_address_city_region_formatted: company_address_city_region_formatted,
                lat: r[`${cube}.company_address_loc_city_latitude`],
                long: r[`${cube}.company_address_loc_city_longitude`],
            }
        };
    });
    return {
        type: 'FeatureCollection',
        features: geoJsonFeatures
    };
}

export function buildPointDetailGeoJson(cubeQueryResult, cube) {
    const geoJsonFeatures = cubeQueryResult.map((r) => {
        const company_address_city_region_formatted = `${r[`${cube}.company_address_city`]}, ${r[`${cube}.company_address_region`]}`
        {
            const count_contacts = !r[`${cube}.count_contacts`] ? 0 : parseInt(r[`${cube}.count_contacts`], 10);
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [r[`${cube}.company_address_longitude`], r[`${cube}.company_address_latitude`]],
                },
                properties: {
                    id: r[`${cube}.company_identity`],
                    company_score: parseFloat(r[`${cube}.company_score`]),
                    count_companies: 1,
                    count_contacts: count_contacts,
                    company_address_city: r[`${cube}.company_address_city`],
                    company_address_region: r[`${cube}.company_address_region`],
                    company_address_city_region_formatted: company_address_city_region_formatted,
                    lat: r[`${cube}.company_address_latitude`],
                    long: r[`${cube}.company_address_longitude`],
                }
            };
        }
    })
    return {
        type: 'FeatureCollection',
        features: geoJsonFeatures
    };
}

// TODO rename this method
export function buildGeoJsonForOverlayOverlay(locationRecords, cube) {
    const geoJsonFeatures = locationRecords.map((r) => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [r[`${cube}.overlay_address_longitude`], r[`${cube}.overlay_address_latitude`]],
            },
            properties: {
                id: r[`${cube}.id`],
                list_id: r[`${cube}.list_id`],
                locationId: r[`${cube}.location_id`], // TODO check this field.
            }
        };
    })
    return {
        type: 'FeatureCollection',
        features: geoJsonFeatures
    };
}

export function buildGeoJsonForCompanyOverlay(companyRecords, cube) {
    const geoJsonFeatures = companyRecords.map((r) => {
        const matchedToCube = cube.replace('Record', 'MatchedRecord');
        const feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [r[`${cube}.company_address_longitude`], r[`${cube}.company_address_latitude`]],
            },
            properties: {
                id: r[`${cube}.id`],
                list_id: r[`${cube}.list_id`],
                name: r[`${cube}.company_name`],
                companyId: r[`${matchedToCube}.company_identity`],
                // This condition likely wont happen and needs to be revisited
                contactId: r[`${matchedToCube}.company_contact_id`],
            }
        }
        return feature;
    })
    return {
        type: 'FeatureCollection',
        features: geoJsonFeatures
    };
}

export function buildGeoJsonForSupplierOverlay(supplierRecords) {
    const geoJsonFeatures = supplierRecords.map((r) => {
        const feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [r['companyAddressLongitude'], r['companyAddressLatitude']],
            },
            properties: {
                id: r['companyRecordId'],
                sourceId: r['sourceId'],
                companyName: r['companyName'],
                companyRecordId: r['companyRecordId'],
            }
        }
        return feature;
    });
    return {
        type: 'FeatureCollection',
        features: geoJsonFeatures
    };
}
