/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {createAccount} from "./managedAccountSlice";
import CreateManageAccount from "../components/accounts/CreateManageAccount";

export default function CreateAccountContainer() {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Accounts',
        href: '/admin/accounts',
        isActive: false
    }, {label: 'Create Account', href: '', isActive: true}];

    const dispatch = useDispatch();
    const managedAccountState = useSelector(state => state.managedAccount);

    const history = useHistory();

    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [account, setAccount] = useState({
        organization: [],
        givenName: '',
        surName: '',
        email: '',
        role: { value: 'USER', label: 'User' },
        sendEmail: false
    });

    const reset = () => {
        resetAccount();
        setAlertMessage('');
        setSuccessMessage('');
    }

    const resetAccount = () => {
        setAccount({
            organization: [],
            givenName: '',
            surName: '',
            email: '',
            role: { value: 'USER', label: 'User' },
            sendEmail: false
        });
    }

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedAccountState.successMessage) {
            setSuccessMessage(managedAccountState.successMessage);
            resetAccount();
        }
    }, [managedAccountState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedAccountState.alertMessage) {
            setAlertMessage(managedAccountState.alertMessage);
        }
    }, [managedAccountState.alertMessage]);

    const handleOnCreate = async (data) => {
        dispatch(createAccount(data));
    };

    const handleOnCancel = async () => {
        reset();
        history.push('/admin/accounts')
    };

    return (
        <CreateManageAccount
            breadcrumbItems={breadcrumbItems}
            account={account}
            handleOnCreate={handleOnCreate}
            handleOnCancel={handleOnCancel}
            searchOrganization
            loading={managedAccountState.loading}
            alertMessage={alertMessage}
            successMessage={successMessage}
        />
    )
}
