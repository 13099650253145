import React from 'react';
import {PopupComponent} from 'ag-grid-community';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';

class AddressCellEditor extends PopupComponent {
  eInput;
  cancelBeforeStart;

  handleSubmit(event) {
    alert('handleSubmit has been called');
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  // gets called once before the renderer is used
  init(params) {
    // create the cell
    //this.eInput = document.createElement('input');
    this.eInput = document.createElement('div');
    // this.eInput.innerText = "Hello! This is some text!!!";
    // this.eInput.innerText='<span className="my-css-class"><button className="btn-simple">Push Me</button></span>';
    const deliveryLine1 = params.data['COMPANY_ADDRESS_DELIVERY_LINE_1'] ? params.data['COMPANY_ADDRESS_DELIVERY_LINE_1'] : '';
    const deliveryLine2 = params.data['COMPANY_ADDRESS_DELIVERY_LINE_2'] ? params.data['COMPANY_ADDRESS_DELIVERY_LINE_2'] : '';
    const city = params.data['COMPANY_ADDRESS_CITY'] ? params.data['COMPANY_ADDRESS_CITY']: '';
    const region = params.data['COMPANY_ADDRESS_REGION'] ? params.data['COMPANY_ADDRESS_REGION']: '';
    const postalCode = params.data['COMPANY_ADDRESS_POSTAL_CODE'] ? params.data['COMPANY_ADDRESS_POSTAL_CODE']: '';
    const validationReason = params.data['COMPANY_ADDRESS_VALIDATION_REASON'] ? params.data['COMPANY_ADDRESS_VALIDATION_REASON']: '';
    const invalidatedReason = params.data['COMPANY_ADDRESS_INVALIDATED_REASON']
        ? params.data['COMPANY_ADDRESS_INVALIDATED_REASON']: '';

    this.eInput.innerHTML = '<form onSubmit={this.handleSubmit}>' +
        `<div class="addressFormInput"><label for="addrLine1">Address Line 1:</label><input type="text" id="addrLine1" name="addrLine1" value=${deliveryLine1}></div>` +
        `<div class="addressFormInput"><label for="addrLine2">Address Line 2:</label><input type="text" id="addrLine2" name="addrLine2" value=${deliveryLine2}></div>` +
        `<div class="addressFormInput"><label for="city">City:</label><input type="text" id="city" name="city" value=${city}></div>` +
        `<div class="addressFormInput"><label for="region">Region:</label><input type="text" id="region" name="region" value=${region}></div>` +
        `<div class="addressFormInput"><label for="postalCode">Postal Code:</label><input type="text" id="postalCode" name="postalCode" value=${postalCode}></div>` +
        `<div class="addressFormInput"><label for="validationReason">Validation Reason:</label><input type="text"" id="validationReason" name="validationReason" value=${validationReason}></div>` +
        `<div class="addressFormInput"><label for="invalidatedReason">Invalidated Reason:</label><input type="text" id="validationReason" name="validationReason" value=${invalidatedReason}></div>` +
        '<input type="submit" value="Submit">' +
        '</form>';
    this.eInput.className = 'address-editor';

    const eventKey = params.eventKey;

    if (eventKey === KEY_BACKSPACE) {
      this.eInput.value = '';
    } else if (this.isCharNumeric(eventKey)) {
      this.eInput.value = eventKey;
    } else {
      if (params.value !== undefined && params.value !== null) {
        this.eInput.value = params.value;
      }
    }

    this.eInput.addEventListener('keydown', (event) => {
      if (!event.key || event.key.length !== 1) {
        return;
      }


     /* if (!this.isNumericKey(event)) {
        this.eInput.focus();
        if (event.preventDefault) event.preventDefault();
      } else if (this.isNavigationKey(event) || this.isBackspace(event)) {
        event.stopPropagation();
      }*/
    });

    // only start edit if key pressed is a number, not a letter
   /* const isCharacter = eventKey && eventKey.length === 1;
    var isNotANumber = isCharacter && '1234567890'.indexOf(eventKey) < 0;
    this.cancelBeforeStart = !!isNotANumber; */
  }

  isNumericKey(event) {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  isBackspace(event) {
    return event.key === KEY_BACKSPACE;
  }

  isNavigationKey(event) {
    return event.key === 'ArrowLeft' || event.key === 'ArrowRight';
  }

  isCharNumeric(charStr) {
    return charStr && !!/^\d+$/.test(charStr);
  }

  // gets called once when grid ready to insert the element
  getGui() {
    console.log('In AddressCellEditor getGui()');
    return this.eInput;
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached() {
    this.eInput.focus();
  }

  // returns the new value after editing
  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // example - will reject the number if it contains the value 007
  // - not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
  }

  // returns the new value after editing
  getValue() {
    console.log("Hello in AddressCellEditor getValue()");
    return this.eInput.value;
  }

  // any cleanup we need to be done here
  destroy() {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
  }
}

export default AddressCellEditor;