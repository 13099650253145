/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';

function SideBarContent(props) {

    return (
        <section className={props.expanded ? "main-content main-content--expanded" : "main-content"}>
            {props.children}
        </section>
    )
}

SideBarContent.propTypes = {
    expanded: PropTypes.bool,
};

export default SideBarContent;
