import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedAccounts: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    accounts: [],
};

export const clearAccounts = createAsyncThunk('accounts/clear-accounts', async (arg, {getState, requestId}) => {
   getState().managedAccounts.accounts = undefined;
});

export const getAccounts = createAsyncThunk('accounts/get-all', async (arg, {getState, requestId}) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-account`;
    const accounts = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, buildQuery(getState().managedAccounts.currentPage, getState().managedAccounts.pageSize,
        getState().managedAccounts.order.by, getState().managedAccounts.order.direction, getState().managedAccounts.searchCriteria));
    return accounts;
});

export const getAccountsWithFilter = createAsyncThunk('accounts/get-all-with-filter', async (filter) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-account`;
    const accounts = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return accounts;
});

export const getAccountsCount = createAsyncThunk('accounts/getCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        const isGuid = regexExp.test(getState().managedAccounts.searchCriteria);
        const where = {filter:
                        {where: isGuid ? {
                            or: [
                                {
                                    id: {
                                        eq: `${getState().managedAccounts.searchCriteria ? getState().managedAccounts.searchCriteria : ''}`
                                    }
                                },
                                {
                                    organizationId:{
                                        eq: `${getState().managedAccounts.searchCriteria ? getState().managedAccounts.searchCriteria : ''}`
                                    }
                                }
                            ]

                        }
                        :{  email: {
                            ilike: `%${getState().managedAccounts.searchCriteria ? getState().managedAccounts.searchCriteria : ''}%`
                        }

                }
            }
        };

        const SOURCE_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-account/count`;
        const accountsCount = await Api.get(true, SOURCE_COUNT_URL, where);
        return accountsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {

    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isGuid = regexExp.test(searchCriteria);
    const query = {
        filter: {
            where: isGuid ? {
                    or: [
                    {
                        id: {
                            eq: `${searchCriteria ? searchCriteria : ''}`
                        }
                    },
                    {
                        organizationId:{
                            eq: `${searchCriteria ? searchCriteria : ''}`
                        }
                    }
                ]
            }:{
                email: {
                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                }},
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

const accountsSlice = createSlice({
    name: 'accountsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        clearSelectedAccounts: {
            reducer(state, action) {
                state.selectedAccounts = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedAccounts.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedAccounts.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getAccounts.pending]: (state, action) => {
            state.loading = true;
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.accounts = action.payload;
            }
        },
        [getAccounts.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAccountsCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getAccountsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getAccountsCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAccountsWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getAccountsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.accounts = action.payload;
            }
        },
        [getAccountsWithFilter.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});


export const {setCurrentPage, setOrder, setSelectedId, clearSelected, clearSelectedAccounts, setSearchCriteria, resetCreateOrganizationState} = accountsSlice.actions;
export default accountsSlice.reducer;
