
function Degrees_to_DMM(deg,type,spacer,minutemark) {
	if (!deg.toString().match(/[0-9]/)) { return ''; }
	if (!spacer) { spacer = ''; }
	if (!minutemark) { minutemark = ''; }
	if (type === 'lat') {
		if (parseFloat(deg) < 0) { var dir = 'S'; } else { var dir = 'N'; }
	} else {
		if (parseFloat(deg) < 0) { var dir = 'W'; } else { var dir = 'E'; }
	}
	var d = Math.floor(Math.abs(parseFloat(deg)));
	var m = 60 * (Math.abs(parseFloat(deg)) - parseFloat(d))
	m = Math.round(1000000 * m) / 1000000;
	if (type === 'lon') {
		if (d < 10) { d = '00'+d; } else if (d < 100) { d = '0'+d; }
	} else {
		if (d < 10) { d = '0'+d; }
	}
	if (parseFloat(m) === Math.floor(parseFloat(m))) { m = m + '.0'; }
	return dir + d + String.fromCharCode(176) + spacer + comma2point(m) + minutemark;
}

function Degrees_to_DMS(deg,type,spacer) {
	if (!deg.toString().match(/[0-9]/)) { return ''; }
	if (!spacer) { spacer = ''; }
	if (type === 'lat') {
		if (parseFloat(deg) < 0) { var dir = 'S'; } else { var dir = 'N'; }
	} else {
		if (parseFloat(deg) < 0) { var dir = 'W'; } else { var dir = 'E'; }
	}
	var d = Math.floor(Math.abs(parseFloat(deg)));
	var mmm = 60 * (Math.abs(parseFloat(deg)) - parseFloat(d))
	mmm = Math.round(1000000 * mmm) / 1000000;
	var m = Math.floor(parseFloat(mmm));
	var s = 60 * (parseFloat(mmm) - parseFloat(m))
	s = Math.round(1000 * s) / 1000;
	return dir + d + String.fromCharCode(176) + spacer + m + '\'' + spacer + comma2point(s) + '"';
}

function deg2rad (deg) {
	return (parseFloat(comma2point(deg)) * 3.14159265358979/180);
}
function rad2deg (radians) {
	return (Math.round(10000000 * parseFloat(radians) * 180/3.14159265358979) / 10000000);
}
function comma2point (number) {
	number = number+''; // force number into a string context
	return (number.replace(/,/g,'.'));
}

function parseCoordinate(coordinate,type,format,spaced) {
	coordinate = coordinate.toString();
	coordinate = coordinate.replace(/(^\s+|\s+$)/g,''); // remove white space
	var neg = 0; if (coordinate.match(/(^-|[WS])/i)) { neg = 1; }
	if (coordinate.match(/[EW]/i) && !type) { type = 'lon'; }
	if (coordinate.match(/[NS]/i) && !type) { type = 'lat'; }
	coordinate = coordinate.replace(/[NESW\-]/gi,' ');
	if (!coordinate.match(/[0-9]/i)) {
		return '';
	}
	var parts = coordinate.match(/([0-9\.\-]+)[^0-9\.]*([0-9\.]+)?[^0-9\.]*([0-9\.]+)?/);
	if (!parts || parts[1] === null) {
		return '';
	} else {
		var n = parseFloat(parts[1]);
		if (parts[2]) { n = n + parseFloat(parts[2])/60; }
		if (parts[3]) { n = n + parseFloat(parts[3])/3600; }
		if (neg && n >= 0) { n = 0 - n; }
		if (format === 'dmm') {
			if (spaced) {
				n = Degrees_to_DMM(n,type,' ');
			} else {
				n = Degrees_to_DMM(n,type);
			}
		} else if (format === 'dms') {
			if (spaced) {
				n = Degrees_to_DMS(n,type,' ');
			} else {
				n = Degrees_to_DMS(n,type,'');
			}
		} else {
			n = Math.round(100000000000 * n) / 100000000000;
			if (n === Math.floor(n)) { n = n + '.0'; }
		}
		return comma2point(n);
	}
}
var parseDistance_units = '';
function parseDistance(distance_text) { // returns meters
	var meters = parseFloat(distance_text.replace(/^.*?([0-9]+\.?[0-9]*|\.[0-9]+).*$/,"$1"));
	if (distance_text.match(/mi/i)) { meters *= 1609.344; parseDistance_units = 'us'; }
	else if (distance_text.match(/(\b|\d)(m\b|meter)/i)) { meters *= 1; }
	else if (distance_text.match(/(\b|\d)(naut|n\.?m|kn)/i)) { meters *= 1852; parseDistance_units = 'nautical'; }
	else if (distance_text.match(/((\b|\d)fe*t|')/i)) { meters *= 0.3048; parseDistance_units = 'us'; }
	else if (distance_text.match(/(\b|\d)(yd|yard)/i)) { meters *= 0.9144; parseDistance_units = 'us'; }
	else { meters *= 1000; } // default is kilometers
	return (meters);
}
function parseBearing(bearing_text) { // returns degrees
	var degrees;
	if (bearing_text.toUpperCase().match(/[NS].*[0-9].*[EW]/i)) {
		var parts = bearing_text.toUpperCase().match(/([NS])(.*[0-9].*)([EW])/);
		degrees = parts[2];
		if (parts[1] === 'N' && parts[3] === 'E') { degrees = 0 + parseFloat(parseCoordinate(degrees)); }
		else if (parts[1] === 'N' && parts[3] === 'W') { degrees = 360 - parseFloat(parseCoordinate(degrees)); }
		else if (parts[1] === 'S' && parts[3] === 'E') { degrees = 180 - parseFloat(parseCoordinate(degrees)); }
		else if (parts[1] === 'S' && parts[3] === 'W') { degrees = 180 + parseFloat(parseCoordinate(degrees)); }
	} else {
		degrees = parseFloat(parseCoordinate(bearing_text.replace(/[NSEW]/gi,' ')));
	}
	return degrees;
}

export function Point_At_Distance_And_Bearing2(start_lat,start_lon,distance_text,bearing) { // input is in degrees, km, degrees
	// http://www.movable-type.co.uk/scripts/latlong.html
	var earth_radius = 6371000; // "average" radius
	var distance = parseDistance(distance_text);
	bearing = deg2rad(parseBearing(bearing));
	var start_lat_rad = deg2rad(parseCoordinate(start_lat));
	var start_lon_rad = deg2rad(parseCoordinate(start_lon));
	var ending_point = []; // output
	var arc = distance/earth_radius;
	var end_lat_rad = Math.asin( Math.sin(start_lat_rad)*Math.cos(arc) + Math.cos(start_lat_rad)*Math.sin(arc)*Math.cos(bearing) );
	var end_lon_rad = start_lon_rad + Math.atan2( Math.sin(bearing)*Math.sin(arc)*Math.cos(start_lat_rad),Math.cos(arc)-Math.sin(start_lat_rad)*Math.sin(end_lat_rad));
	end_lon_rad = (end_lon_rad+Math.PI)%(2*Math.PI) - Math.PI; // normalise to -180...+180
	ending_point[0] = rad2deg(end_lat_rad); ending_point[1] = rad2deg(end_lon_rad);

	return (ending_point);
}

