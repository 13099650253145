/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import AppRouter from './AppRouter';

import './styles/bootstrap.scss';
import './App.scss';

class App extends Component {
    render() {
        return (
            <Router basename={"/"}>
                <Route path="/" component={AppRouter} />
            </Router>
        );
    }
}

export default App;
