/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useState, useEffect} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {filter, map} from 'lodash';
import {filterUpsert, removeFilter} from "./contactSlice";
import { FilterOptions } from './FilterOptions';
import SectionTogglePanel from "./components/SectionTogglePanel";
import TogglePanel from "../../common/components/TogglePanel";
import MoreLessPanel from '../../common/components/MoreLessPanel';
import FilterComponentBuilder from '../../common/components/FilterComponentBuilder';
import {setExploreMode} from "./exploreSlice";
import ListFilter from './ListFilter';
import {LIST_STATUSES} from "../../common/metadata/ListStatuses";
import {getListsWithFilter} from "../../common/services/ListService";
import {LIST_TYPES} from "../../common/metadata/ListTypes";
import styled from "styled-components";
import {APP_COLORS} from "../../common/Constants";

const ExportText = styled.span `
  color: ${APP_COLORS['orange']};
  font-weight: 500;
`;

const MatchText = styled.span `
  color: ${APP_COLORS['green']};
  font-weight: 500;
`;

export const ContactFilter = () => {
    const dispatch = useDispatch();
    const exploreDataState = useSelector(state => state.explore);
    const contactDataState = useSelector(state => state.contact);
    const metaDataState = useSelector(state => state.metaData);
    const [filterState, setFilterState] = useState({});
    const isOpenSection = (id) => {
        return exploreDataState.exploreMode.contact.sections[id];
    };

    const handleToggleSection = (id, value) => {
        dispatch(setExploreMode(
            {
                ...exploreDataState.exploreMode,
                contact: {
                    ...exploreDataState.exploreMode.contact,
                    sections: {
                        ...exploreDataState.exploreMode.contact.sections,
                        [id]: value
                    }
                }
            }
        ));
    };

    useEffect(() => {
        const currentFilter = contactDataState.filters.reduce((add, cur) => {
            add[cur.id] = cur.value;
            return add;
        }, {});
        setFilterState(currentFilter);
    }, [contactDataState, metaDataState?.status?.status]);

    /**
     * Import Metadata (to populate filters if needed) - not all filters need metadata
     *
     * 1) Ensure metaDataServices returns your metadata, metaDataSlice needs to be updated in tandem
     * 2) Add metadata Here
     * 3) Update FilterOptions.js with your section / sub section / filter
     * 4) Add that below (in section titled ADD FILTER HERE)
     * 5) Update HTML with new filter section/sub section if needed
     */
    const metaData = {
        isLoading: metaDataState?.status?.status === 'loading',
        CONTACT_TITLE: metaDataState?.contactTitle,
        CONTACT_EMAIL_STATUS: metaDataState?.companyEmailStatus,
        // COMPANY
        COMPANY_ADDRESS_REGION: metaDataState?.companyAddressRegion,
        COMPANY_ADDRESS_LOC_MSA: metaDataState?.companyAddressLocMsa,
        COMPANY_ADDRESS_RESIDENTIAL_DELIVERY_INDICATOR: metaDataState?.companyAddressRdi,
        COMPANY_ADDRESS_LOC_CSA: metaDataState?.companyAddressLocCsa,
        COMPANY_EMAIL_STATUS: metaDataState?.companyEmailStatus,
        // FACTS
        COMPANY_FACT_BUSINESS_STRUCTURE: metaDataState?.companyFactBusinessStructure,
        COMPANY_FACT_PUBLIC_PRIVATE: metaDataState?.companyFactPublicPrivate,
        COMPANY_FACT_OWNERSHIP_TYPE: metaDataState?.companyFactOwnershipType,
        // STATISTICS
        COMPANY_STAT_EMPLOYEES_RANGE: metaDataState?.companyStatEmployeesRange,
        COMPANY_STAT_SALES_RANGE: metaDataState?.companyStatSalesRange,
        // TAXONOMY
        COMPANY_SEGMENTS: metaDataState?.companySegments,
        COMPANY_CLASS_TRADE: metaDataState?.companyClassTrade,
        COMPANY_CLASS_TRADES: metaDataState?.companyClassTrades,
        COMPANY_CLASS_NAICS_DESCRIPTIONS: metaDataState?.companyClassNAICSDescriptions,
        COMPANY_CLASS_SIC_DESCRIPTIONS: metaDataState?.companyClassSICDescriptions,
        COMPANY_SERVICES: metaDataState?.companyServices,
        // INSIGHTS
        COMPANY_AFFINITIES: metaDataState?.companyAffinities,
        COMPANY_AFFILIATIONS: metaDataState?.companyAffiliations,
        COMPANY_INDICATOR_WORK_TYPES: metaDataState?.companyIndWorkTypes,
        COMPANY_INDICATOR_WORK_LOCATIONS: metaDataState?.companyIndWorkLocations,
        COMPANY_INDICATOR_WORK_CREDENTIALS: metaDataState?.companyIndWorkCredentials,
        // PROFILES
        COMPANY_PROFILE_TYPES: metaDataState?.companyProfileTypes,
        COMPANY_PROFILE_ASSOCIATIONS: metaDataState?.companyProfileAssociations,
        COMPANY_PROFILE_MANUFACTURERS: metaDataState?.companyProfileManufacturers,
        COMPANY_PROFILE_ONLINE_PROFILES: metaDataState?.companyProfileOnlineProfiles,
        // LICENSES
        COMPANY_LICENSE_TYPES: metaDataState?.companyLicenseTypes,
        // PERMITS
        // POLICIES
        // REGISTRATIONS
        COMPANY_REG_LOCAL_REGISTRATION_STATUS: metaDataState?.companyRegLocalRegistrationStatus,
        COMPANY_REG_LOCAL_REGISTRAR: metaDataState?.companyRegLocalRegistrar,
        COMPANY_REG_LOCAL_REGISTRAR_REGION: metaDataState?.companyRegLocalRegistrarRegion,
        // SOURCES

    };

    const handleKeyValueChange = (key, value) => {
        setFilterState({
            ...filterState,
            [key]: value,
        }
        );
    };

    const handleRemoveFilter = (id) => {
        dispatch(removeFilter(id));
    };
    const handleSubmit = (_filter) => {
        dispatch(filterUpsert(_filter));
    };

    const getFilters = (filterSubSections) => {

        return [FilterComponentBuilder(
            filter(Object.keys(filterSubSections).map((key) => filterSubSections[key]), subSection => !subSection.hide)
            , filterState, handleKeyValueChange, metaData, handleSubmit, handleRemoveFilter),
            FilterComponentBuilder(
                filter(Object.keys(filterSubSections).map((key) => filterSubSections[key]), subSection => subSection.hide)
                , filterState, handleKeyValueChange, metaData, handleSubmit, handleRemoveFilter)
        ];
    };

    const [listOptions, setListOptions] = useState([]);
    const getListOptions = () => {
        if (!listOptions || listOptions.length === 0) {
            return [];
        }
        const listMatchValues = filterState['CONTACT_LIST_MATCH'] && filterState['CONTACT_LIST_MATCH'].length > 0 ? filterState['CONTACT_LIST_MATCH'].map(v => v.id) : [];
        const listNotMatchValues = filterState['CONTACT_LIST_NOT_MATCH'] && filterState['CONTACT_LIST_NOT_MATCH'].length > 0 ? filterState['CONTACT_LIST_NOT_MATCH'].map(v => v.id) : [];
        return listOptions.filter(option => (!listMatchValues.includes(option.id) && !listNotMatchValues.includes(option.id)));
    };

    /***
     *
     * ADD FILTER HERE
     *
     */

    // NOTE: KEEP THIS IN ORDER OF ELEMENTS IN THE UI; IT HELPS QA

    // CONTACT
    const [contactNameFilter, hideContactNameFilter] = getFilters(FilterOptions.CONTACT.NAME);
    const [contactTitleFilter, hideContactTitleFilter] = getFilters(FilterOptions.CONTACT.TITLE);
    const [contactPhoneFilter, hideContactPhoneFilter] = getFilters(FilterOptions.CONTACT.PHONE);
    const [contactEmailFilter, hideContactEmailFilter] = getFilters(FilterOptions.CONTACT.EMAIL);
    // COMPANY
    const [companyNameFilters, hideCompanyNameFilters] = getFilters(FilterOptions.COMPANY.NAME);
    const [companyUrlFilters, hideCompanyUrlFilters] = getFilters(FilterOptions.COMPANY.URL);
    const [companyLocationFilters, hideCompanyLocationFilters] = getFilters(FilterOptions.COMPANY.LOCATION);
    const [companyPhoneFilters, hideCompanyPhoneFilters] = getFilters(FilterOptions.COMPANY.PHONE);
    const [companyEmailFilters, hideCompanyEmailFilters] = getFilters(FilterOptions.COMPANY.EMAIL);
    // FACTS
    const [factsStickyFilters, hideFactsStickyFilters] = getFilters(FilterOptions.FACTS.STICKY_FILTER);
    // STATISTICS
    const [statisticsStickyFilters, hideStatisticsStickyFilters] = getFilters(FilterOptions.STATISTICS.STICKY_FILTER);
    // TAXONOMY
    const [taxonomySegmentsFilters, hideTaxonomySegmentsFilters] = getFilters(FilterOptions.TAXONOMY.SEGMENTS);
    const [taxonomyClassificationFilters, hideTaxonomyClassificationFilters] = getFilters(FilterOptions.TAXONOMY.CLASSIFICATION);
    const [taxonomyServicesFilters, hideTaxonomyServicesFilters] = getFilters(FilterOptions.TAXONOMY.SERVICES);
    // INSIGHTS
    const [insightsAffinitiesAffiliationsFilters, hideInsightsAffinitiesAffiliationsFilters] = getFilters(FilterOptions.INSIGHTS.AFFINITIES_AFFILIATIONS);
    const [insightsIndicatorsFilters, hideInsightsIndicatorsFilters] = getFilters(FilterOptions.INSIGHTS.INDICATORS);
    // TODO: Disabled, re-enable once we've got meaningful content
    // const [insightsScoresFilters, hideInsightsScoresFilters] = getFilters(FilterOptions.INSIGHTS.SCORES);
    // PROFILES
    const [profilesStickyFilters, hideProfilesStickyFilters] = getFilters(FilterOptions.PROFILES.STICKY_FILTER);
    const [profilesAssociationsFilters, hideProfilesAssociationsFilters] = getFilters(FilterOptions.PROFILES.ASSOCIATIONS);
    const [profilesManufacturersFilters, hideProfilesManufacturersFilters] = getFilters(FilterOptions.PROFILES.MANUFACTURERS);
    const [profilesOnlineFilters, hideProfilesOnlineFilters] = getFilters(FilterOptions.PROFILES.ONLINE);
    // LICENSES
    const [licensesStickyFilters, hideLicensesStickyFilters] = getFilters(FilterOptions.LICENSES.STICKY_FILTER);
    // PERMITS
    const [permitsStickyFilters, hidePermitsStickyFilters] = getFilters(FilterOptions.PERMITS.STICKY_FILTER);
    const [permitsFrequencyFilters, hidePermitsFrequencyFilters] = getFilters(FilterOptions.PERMITS.FREQUENCY);
    const [permitsValuationFilters, hidePermitsValuationFilters] = getFilters(FilterOptions.PERMITS.VALUATION);
    // POLICIES
    const [policiesStickyFilters, hidePoliciesStickyFilters] = getFilters(FilterOptions.POLICIES.STICKY_FILTER);
    // REGISTRATIONS
    const [registrationsStickyFilters, hideRegistrationsStickyFilters] = getFilters(FilterOptions.REGISTRATIONS.STICKY_FILTER);
    // SOURCES
    const [sourcesStickyFilters, hideSourcesStickyFilters] = getFilters(FilterOptions.SOURCES.STICKY_FILTER);

    if (exploreDataState.exploreMode.mode === 'COMPANY') {
        return (
            <>
            </>
        );
    }

    const showMatchAndExport = (type) => {
        if (type.includes('MATCH')) {
            return <MatchText>(M)</MatchText>
        }

        if (type.includes('EXPORT')) {
            return <ExportText>(E)</ExportText>
        }
    };


    const onListSearch = async (term, offset = 0) => {
        const where = term ? {
                and: [
                    {
                        or: [
                            {status: LIST_STATUSES.READY.id}
                        ]
                    },
                    {type: {
                            inq: [
                                LIST_TYPES.MATCH_CONTACT.id,
                                LIST_TYPES.EXPORT_CONTACT.id,
                            ]
                        }
                    },
                    {
                        or: [
                            {name: {
                                    ilike: `%${term}%`
                                }},
                            {description: {
                                    ilike: `%${term}%`
                                }
                            },
                        ]
                    }
                ]

            }
            :
            {
                and: [
                    {
                        or: [
                            {status: LIST_STATUSES.READY.id},
                        ]
                    },
                    {type: {
                            inq: [
                                LIST_TYPES.MATCH_CONTACT.id,
                                LIST_TYPES.EXPORT_CONTACT.id,
                            ]
                        }
                    },
                ]
            };

        try {
            const _list = await getListsWithFilter({filter: {where: where, skip: offset, order: ['created DESC']}});
            if (_list && _list.length > 0) {
                setListOptions(map(_list, (list) => {
                        const _option = {
                            id: list.id,
                            display: list.name,
                            updated: list.updated,
                            type: list.type
                        };
                        return _option;
                    })

                );
            }
        } catch (err) {
        }

    };
    return (
        <div>
            <SectionTogglePanel
                title={'Contact'}
                isExpand={isOpenSection('CONTACT')}
                handleOnClick={(value) => handleToggleSection('CONTACT', value)}
            >
                <TogglePanel
                    title={'Name'}
                    isExpand={isOpenSection('CONTACT_NAME')}
                    handleOnClick={(value) => handleToggleSection('CONTACT_NAME', value)}
                    hideChildren={hideContactNameFilter}
                >
                    {contactNameFilter}
                </TogglePanel>
                <TogglePanel
                    title={'Title'}
                    isExpand={isOpenSection('CONTACT_TITLE')}
                    handleOnClick={(value) => handleToggleSection('CONTACT_TITLE', value)}
                    hideChildren={hideContactTitleFilter}
                >
                    {contactTitleFilter}
                </TogglePanel>
                <TogglePanel
                    title={'PHONE'}
                    isExpand={isOpenSection('CONTACT_PHONE')}
                    handleOnClick={(value) => handleToggleSection('CONTACT_PHONE', value)}
                    hideChildren={hideContactPhoneFilter}
                >
                    {contactPhoneFilter}
                </TogglePanel>
                <TogglePanel
                    title={'EMAIL'}
                    isExpand={isOpenSection('CONTACT_EMAIL')}
                    handleOnClick={(value) => handleToggleSection('CONTACT_EMAIL', value)}
                    hideChildren={hideContactEmailFilter}
                >
                    {contactEmailFilter}
                </TogglePanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'COMPANY'}
                isExpand={isOpenSection('COMPANY')}
                handleOnClick={(value) => handleToggleSection('COMPANY', value)}
            >
                <TogglePanel
                    title={'NAME'}
                    isExpand={isOpenSection('COMPANY_NAME')}
                    handleOnClick={(value) => handleToggleSection('COMPANY_NAME', value)}
                    hideChildren={hideCompanyNameFilters}
                >
                    {companyNameFilters}
                </TogglePanel>
                <TogglePanel
                    title={'URL'}
                    isExpand={isOpenSection('COMPANY_URL')}
                    handleOnClick={(value) => handleToggleSection('COMPANY_URL', value)}
                    hideChildren={hideCompanyUrlFilters}
                >
                    {companyUrlFilters}
                </TogglePanel>
                <TogglePanel
                    title={'LOCATION'}
                    isExpand={isOpenSection('COMPANY_LOCATION')}
                    handleOnClick={(value) => handleToggleSection('COMPANY_LOCATION', value)}
                    hideChildren={hideCompanyLocationFilters}
                >
                    {companyLocationFilters}
                </TogglePanel>
                <TogglePanel
                    title={'PHONE'}
                    isExpand={isOpenSection('COMPANY_PHONE')}
                    handleOnClick={(value) => handleToggleSection('COMPANY_PHONE', value)}
                    hideChildren={hideCompanyPhoneFilters}
                >
                    {companyPhoneFilters}
                </TogglePanel>
                <TogglePanel
                    title={'EMAIL'}
                    isExpand={isOpenSection('COMPANY_EMAIL')}
                    handleOnClick={(value) => handleToggleSection('COMPANY_EMAIL', value)}
                    hideChildren={hideCompanyEmailFilters}
                >
                    {companyEmailFilters}
                </TogglePanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'FACTS'}
                isExpand={isOpenSection('FACTS')}
                handleOnClick={(value) => handleToggleSection('FACTS', value)}
            >
                <MoreLessPanel
                    hideChildren={hideFactsStickyFilters}
                >
                    {factsStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'STATISTICS'}
                isExpand={isOpenSection('STATISTICS')}
                handleOnClick={(value) => handleToggleSection('STATISTICS', value)}
            >
                <MoreLessPanel
                    hideChildren={hideStatisticsStickyFilters}
                >
                    {statisticsStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'TAXONOMY'}
                isExpand={isOpenSection('TAXONOMY')}
                handleOnClick={(value) => handleToggleSection('TAXONOMY', value)}
            >
                <TogglePanel
                    title={'SEGMENTS'}
                    isExpand={isOpenSection('TAXONOMY_SEGMENTS')}
                    handleOnClick={(value) => handleToggleSection('TAXONOMY_SEGMENTS', value)}
                    hideChildren={hideTaxonomySegmentsFilters}
                >
                    {taxonomySegmentsFilters}
                </TogglePanel>
                <TogglePanel
                    title={'CLASSIFICATION'}
                    isExpand={isOpenSection('TAXONOMY_CLASSIFICATION')}
                    handleOnClick={(value) => handleToggleSection('TAXONOMY_CLASSIFICATION', value)}
                    hideChildren={hideTaxonomyClassificationFilters}
                >
                    {taxonomyClassificationFilters}
                </TogglePanel>
                <TogglePanel
                    title={'SERVICES'}
                    isExpand={isOpenSection('TAXONOMY_SERVICES')}
                    handleOnClick={(value) => handleToggleSection('TAXONOMY_SERVICES', value)}
                    hideChildren={hideTaxonomyServicesFilters}
                >
                    {taxonomyServicesFilters}
                </TogglePanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'INSIGHTS'}
                isExpand={isOpenSection('INSIGHTS')}
                handleOnClick={(value) => handleToggleSection('INSIGHTS', value)}
            >
                <TogglePanel
                    title={'AFFINITIES & AFFILIATIONS'}
                    isExpand={isOpenSection('INSIGHTS_AFFINITIES_AFFILIATIONS')}
                    handleOnClick={(value) => handleToggleSection('INSIGHTS_AFFINITIES_AFFILIATIONS', value)}
                    hideChildren={hideInsightsAffinitiesAffiliationsFilters}
                >
                    {insightsAffinitiesAffiliationsFilters}
                </TogglePanel>
                <TogglePanel
                    title={'INDICATORS'}
                    isExpand={isOpenSection('INSIGHTS_INDICATORS')}
                    handleOnClick={(value) => handleToggleSection('INSIGHTS_INDICATORS', value)}
                    hideChildren={hideInsightsIndicatorsFilters}
                >
                    {insightsIndicatorsFilters}
                </TogglePanel>
                {/*<TogglePanel*/}
                {/*    title={'SCORES'}*/}
                {/*    isExpand={isOpenSection('INSIGHTS_SCORES')}*/}
                {/*    handleOnClick={(value) => handleToggleSection('INSIGHTS_SCORES', value)}*/}
                {/*    hideChildren={hideInsightsScoresFilters}*/}
                {/*>*/}
                {/*    {insightsScoresFilters}*/}
                {/*</TogglePanel>*/}
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'PROFILES'}
                isExpand={isOpenSection('PROFILES')}
                handleOnClick={(value) => handleToggleSection('PROFILES', value)}
            >
                <MoreLessPanel
                    hideChildren={hideProfilesStickyFilters}
                >
                    {profilesStickyFilters}
                </MoreLessPanel>
                <TogglePanel
                    title={'ASSOCIATIONS'}
                    isExpand={isOpenSection('PROFILES_ASSOCIATIONS')}
                    handleOnClick={(value) => handleToggleSection('PROFILES_ASSOCIATIONS', value)}
                    hideChildren={hideProfilesAssociationsFilters}
                >
                    {profilesAssociationsFilters}
                </TogglePanel>
                <TogglePanel
                    title={'MANUFACTURERS'}
                    isExpand={isOpenSection('PROFILES_MANUFACTURERS')}
                    handleOnClick={(value) => handleToggleSection('PROFILES_MANUFACTURERS', value)}
                    hideChildren={hideProfilesManufacturersFilters}
                >
                    {profilesManufacturersFilters}
                </TogglePanel>
                <TogglePanel
                    title={'ONLINE'}
                    isExpand={isOpenSection('PROFILES_ONLINE')}
                    handleOnClick={(value) => handleToggleSection('PROFILES_ONLINE', value)}
                    hideChildren={hideProfilesOnlineFilters}
                >
                    {profilesOnlineFilters}
                </TogglePanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'LICENSES'}
                isExpand={isOpenSection('LICENSES')}
                handleOnClick={(value) => handleToggleSection('LICENSES', value)}
            >
                <MoreLessPanel
                    hideChildren={hideLicensesStickyFilters}
                >
                    {licensesStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'PERMITS'}
                isExpand={isOpenSection('PERMITS')}
                handleOnClick={(value) => handleToggleSection('PERMITS', value)}
            >
                <MoreLessPanel
                    hideChildren={hidePermitsStickyFilters}
                >
                    {permitsStickyFilters}
                </MoreLessPanel>
                <TogglePanel
                    title={'FREQUENCY'}
                    isExpand={isOpenSection('PERMITS_FREQUENCY')}
                    handleOnClick={(value) => handleToggleSection('PERMITS_FREQUENCY', value)}
                    hideChildren={hidePermitsFrequencyFilters}
                >
                    {permitsFrequencyFilters}
                </TogglePanel>
                <TogglePanel
                    title={'VALUATION'}
                    isExpand={isOpenSection('PERMITS_VALUATION')}
                    handleOnClick={(value) => handleToggleSection('PERMITS_VALUATION', value)}
                    hideChildren={hidePermitsValuationFilters}
                >
                    {permitsValuationFilters}
                </TogglePanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'POLICIES'}
                isExpand={isOpenSection('POLICIES')}
                handleOnClick={(value) => handleToggleSection('POLICIES', value)}
            >
                <MoreLessPanel
                    hideChildren={hidePoliciesStickyFilters}
                >
                    {policiesStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'REGISTRATIONS'}
                isExpand={isOpenSection('REGISTRATIONS')}
                handleOnClick={(value) => handleToggleSection('REGISTRATIONS', value)}
            >
                <MoreLessPanel
                    hideChildren={hideRegistrationsStickyFilters}
                >
                    {registrationsStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'SOURCES'}
                isExpand={isOpenSection('SOURCES')}
                handleOnClick={(value) => handleToggleSection('SOURCES', value)}
            >
                <MoreLessPanel
                    hideChildren={hideSourcesStickyFilters}
                >
                    {sourcesStickyFilters}
                </MoreLessPanel>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'LISTS'}
                isExpand={isOpenSection('LISTS')}
                handleOnClick={(value) => handleToggleSection('LISTS', value)}
                contentHeight={getListOptions().length > 0 ? 350 : null}
            >
                <ListFilter
                    id={'CONTACT_LIST_MATCH'}
                    multiple
                    label={'Contained In'}
                    placeholder={'Please select...'}
                    pageSize={6}
                    renderMenuItemChildren={option => (
                        <div key={option.id} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>{showMatchAndExport(option.type)}&nbsp;{option.display}&nbsp;</span>
                        </div>
                    )}
                    selected={filterState && filterState['CONTACT_LIST_MATCH'] && filterState['CONTACT_LIST_MATCH'].length > 0 ? filterState['CONTACT_LIST_MATCH'] : []}
                    listOptions={getListOptions()}
                    onSearch={onListSearch}
                    onChange={(v) => {
                        handleKeyValueChange('CONTACT_LIST_MATCH', v);

                        if (!v || v.length === 0) {
                            // REMOVE
                            return handleRemoveFilter({id: 'CONTACT_LIST_MATCH'});
                        }
                        handleSubmit(
                            {
                                id: 'CONTACT_LIST_MATCH',
                                display: 'Contained In',
                                field: 'ListMatchContactXref.list_id',
                                operatorDisplay: 'in',
                                operatorId: 'equals',
                                component: 'combobox',
                                fieldDisplay: 'Contained In',
                                metaDataId: 'CONTACT_LIST_MATCH',
                                multiple: true,
                                value: v,

                            }
                        )
                    }}
                    paginationText={'More'}
                />
                <div style={{marginTop: '5px'}}></div>
                {/*TODO: DISABLING FOR NOW AS THE RESULT SET IS NOT EXPECTED*/}
                <ListFilter
                    id={'CONTACT_LIST_NOT_MATCH'}
                    multiple
                    label={'Does Not Match To'}
                    placeholder={'Please select...'}
                    pageSize={6}
                    renderMenuItemChildren={option => (
                        <div key={option.id} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>{showMatchAndExport(option.type)}&nbsp;{option.display}&nbsp;</span>
                        </div>
                    )}
                    selected={filterState && filterState['CONTACT_LIST_NOT_MATCH'] && filterState['CONTACT_LIST_NOT_MATCH'].length > 0 ?
                        filterState['CONTACT_LIST_NOT_MATCH'] : []}
                    listOptions={getListOptions()}
                    onSearch={onListSearch}
                    onChange={(v) => {
                        handleKeyValueChange('CONTACT_LIST_NOT_MATCH', v);

                        if (!v || v.length === 0) {
                            // REMOVE
                            return handleRemoveFilter({id: 'CONTACT_LIST_NOT_MATCH'});
                        }
                        handleSubmit(
                            {
                                id: 'CONTACT_LIST_NOT_MATCH',
                                display: 'Does Not Match To',
                                field: 'ListMatchContactXref.list_id',
                                operatorDisplay: 'not in',
                                operatorId: 'notEquals',
                                component: 'combobox',
                                fieldDisplay: 'Does Not Match To',
                                metaDataId: 'CONTACT_LIST_NOT_MATCH',
                                multiple: true,
                                value: v,

                            }
                        )
                    }}
                    paginationText={'More'}
                />
            </SectionTogglePanel>
        </div>
    )
}
