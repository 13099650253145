/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useState} from 'react';
import {Row, Col, Button, Form, FormControl} from 'react-bootstrap';

import './CreateInputContainer.scss';

import {
    createInput, getMappings, getS3Files, getS3ObjectHeader, updateMappedToAttributes, resetCreateInputState, updateAllMappedToAttributes, changeInputChannel, setAlert
} from "./createInputSlice";

import Alert from 'react-bootstrap/Alert';
import {filter, values, find, concat, difference, sortBy, intersectionWith} from 'lodash';
import {TYPES} from './InputTypes';
import {INDICATOR_TYPES, getIndicatorTypeById} from '../../common/metadata/Indicators';
import config from '../../config';
import * as Api from '../../common/Api';
import {CSVReader} from 'react-papaparse';
import Image from 'react-bootstrap/Image';
import BrowserWindowImage from '../../common/assets/img/ToolbeltIcons_Artboard_17.png';
import Container from "../../common/components/layout/Container";
import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";
import Panel from "../../common/components/layout/Panel";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import FormContainer from "../../common/components/layout/FormContainer";
import FormSelect from "../../common/components/form/FormSelect";
import FormAsyncSelect from "../../common/components/form/FormAsyncSelect";
import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import CheckBox from "../../common/components/CheckBox";
import styled from "styled-components";
import FormLabel from "../../common/components/form/FormLabel";

import FileTreeBrowser from "./FileTreeBrowser";

const SourceDrop = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr;

  label {
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;
  }
`;

const MappingContainer = styled.div`
  border: 1px dashed #CCC;
  padding: 20px;
`;

const MappingHeader = styled.div`
  font-weight: bold;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 15px;
`;

const MappingRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 15px;
`;

const uploadRef = React.createRef();

export default class CreateInputContainer extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <CreateInput/>
        )
    }
}

function CreateInput(props) {
    const createInputState = useSelector(state => state.createInput);
    const dispatch = useDispatch();
    const history = useHistory();

    const productOptions = [
        {value: 'b2b', label: 'b2b'},
        {value: 'tcu', label: 'tcu'}
    ];

    const dataTypes = [
        {value: 'csv', label: 'csv'},
        {value: 'avro', label: 'avro'}
    ];

    const [mapping, setMapping] = useState(createInputState.mapping);
    const mappingAttributes = mapping && mapping.attributes.length > 0 ? sortBy(mapping.attributes, ['ordinalPosition']) : [];
    const mappedToAttributes = createInputState.mappedToAttributes;

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // Form
    const [sourcesSearch, setSourcesSearch] = useState('');
    const [formValid, setFormValid] = useState(true);
    const [source, setSource] = useState('');
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [qualityScore, setQualityScore] = useState('');
    const [authoritative, setAuthoritative] = useState(false);
    const [isPublic, setIsPublic] = useState(true);
    const [products, setProducts] = useState([productOptions[0]]);
    const [indicators, setIndicators] = useState(null);
    const [dataType, setDataType] = useState(dataTypes[0]);
    const [fileSelected, setFileSelected] = useState(false);
    const [uploadFile, setUploadFile] = useState('');
    const [inputChannel, setInputChannel] = useState("upload");
    const [uploadFileHeaders, setUploadFileHeaders] = useState('');

    const loadSourceOptions = async () => {
        const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-sources`;
        const query = {
            filter: {
                fields: [
                    'id', 'name', 'label', 'description', 'qualityScore',
                    'authoritative', 'public', 'products', 'indicators', 'archived'
                ],
                where: {
                    and: [
                        {
                            or: [{
                                name: {
                                    ilike: `%${sourcesSearch ? sourcesSearch : ''}%`
                                }
                            },
                                {
                                    description: {
                                        ilike: `%${sourcesSearch ? sourcesSearch : ''}%`
                                    }
                                },
                                {
                                    label: {
                                        ilike: `%${sourcesSearch ? sourcesSearch : ''}%`
                                    }
                                }
                            ]
                        }
                    ]
                },
                order: [`created DESC`],
            }
        }
        if (!sourcesSearch) query.filter.where = undefined;
        const sources = await Api.get(true, GET_SOURCE_URL, query);
        return sources.filter((source) => !source.archived);
    }

    const handleSourceInputChange = (newValue) => {
        if (newValue.length >= 3) setSourcesSearch(newValue);
    };

    const handleTypeOnChange = async (v) => {
        setType(v);
        if (uploadFileHeaders && uploadFileHeaders.data.length > 0) {
            dispatch(getMappings(v.value));
        }
    }

    const handleSourceOnChange = (s) => {
        if (!s) return reset();
        setSource(s);
        setLabel(s.label);
        setQualityScore(s.qualityScore);
        setAuthoritative(s.authoritative);
        setIsPublic(s.public);
        setProducts(() => {
            if (!s.products || s.products.length === 0) return setProducts([productOptions[0]]);
            return s.products.map((p) => (find(productOptions, {value: p})))
        });
        setIndicators(() => {
            if (!s.indicators || s.indicators.length === 0) return setIndicators(null);
            return s.indicators.map((i) => {
                const indicator = getIndicatorTypeById(i);
                if (indicator) {
                    return {
                        value: indicator.id,
                        label: indicator.display
                    }
                }
            })
        });
        setSource(s);
    };

    const handleOnFileDrop = (data, meta) => {
        const input = uploadRef.current;
        // console.log('data', data);
        // console.log('meta', meta);
        // Perform the upload (async)
        const sizeLimit = config.constants.LIMIT_FILE_UPLOAD;
        if (meta && meta.size > (sizeLimit * 1000000)) {
            input.state.files = null;
            setShowAlert(true);
            setAlertMessage('File size is not support.');
            // setTimeout(() => setShowAlert(false), 5000);

            return;
        }

        if (input && input.state && input.state.files) {
            // Why are we doing this and not getting it from meta?
            setUploadFile(input.state.files[0]);
            setFileSelected(true);
        }

        // Set Headers (for mapping)
        if (data && data.length > 0) {
            setUploadFileHeaders(data[0]);
            if (type) dispatch(getMappings(type.value));
        }

    }

    const handleOnFileError = (err, file, inputElem, reason) => {
        console.error(err);
    }

    const handleOnRemoveFile = (data) => {
        setUploadFile('');
    }

    const handleOnMappingSelect = (attribute, header, required) => {
        dispatch(updateMappedToAttributes({attribute: attribute, header: header, required: required}));
    }

    const handleMapByName = () => {
        if (!mappingAttributes) console.warn('there are no mapping attributes to auto fill with');
        if (!uploadFileHeaders.data) console.warn('there are no file headers map to');

        const mapped = intersectionWith(mappingAttributes, uploadFileHeaders.data, (arrVal, othVal) => {
            if (arrVal.attribute === othVal) return true;
        });
        const mappedToAttributes = mapped.map((m) => ({attribute: m.attribute, header: m.attribute, required: m.required}));
        dispatch(updateAllMappedToAttributes(mappedToAttributes));
    }

    const isValid = () => {
        if (!source) return false;
        if (!type.value) return false;
        if (!name) return false;
        if (!label) return false;
        if (qualityScore) {
            const _qualityScore = parseFloat(qualityScore);
            if (_qualityScore < 0 || _qualityScore > 1) return false;
        }
        if ((inputChannel === "upload" && !uploadFile)||
            (inputChannel === "S3" && !fileSelected))
            return false; // Check type and data here.
        if (!mappedToAttributes)
            return false;
        return true;
    };

    const reset = () => {
        setSource('');
        setType('');
        setName('');
        setLabel('');
        setDescription('');
        setQualityScore('');
        setAuthoritative(false);
        setIsPublic(true);
        setProducts([productOptions[0]]);
        setIndicators([]);
        setUploadFile('');
        setUploadFileHeaders('');
        setFileSelected(false);
        dispatch(resetCreateInputState());
    }

    /* Working here */
    const handleChangeInputChannel = (inputChannel) => {
        setInputChannel(inputChannel);
        if (inputChannel === "S3") {
            dispatch(getS3Files());
        }

        dispatch(changeInputChannel(inputChannel));
    }

    const onFileSelected = (fileKey) => {
        dispatch(getS3ObjectHeader(fileKey));
        setFileSelected(true);
        //Check back here on whether this is correct for setting the defRef
        let localUploadFile = {name:fileKey};
        setUploadFile(localUploadFile);
    }

    const handleCreateInput = async () => {
        if (!isValid()) {
            setFormValid(false);
            console.warn('Form is not valid. source, type, name, label, upload file and mapping are required');
            // TODO: The alert does not reset if dismissed (or more specifically useEffect is not popping (probablY)
            // dispatch(setAlert('Form is not valid. source, type, name, label, upload file and mapping are required'));
        } else {
            setFormValid(true);
            const data = {
                source: source,
                uploadedFile: uploadFile,
                mapping: mapping,
                mappedToAttributes: mappedToAttributes,
                input: {
                    sourceId: source.id,
                    type: type.value,
                    name: name.trim(),
                    label: label ? label.trim() : undefined,
                    description: description ? description.trim() : undefined,
                    url: url ? url.trim() : undefined,
                    qualityScore: !qualityScore ? undefined : parseFloat(qualityScore),
                    authoritative: authoritative,
                    public: isPublic,
                    products: products.map((p) => (p.value)),
                    indicators: indicators ? indicators.map((i) => (i.value)) : null,
                    dataType: dataType.value,
                    inputChannel: inputChannel
                }
            }
            dispatch(createInput(data));
        }
    };

    useEffect(() => {
        // When open dialog, we clear state.
        if (createInputState.alertMessage) {
            setAlertMessage(createInputState.alertMessage);
            setShowAlert(true);
        }
    }, [createInputState.alertMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (createInputState.successMessage) {
            setSuccessMessage(createInputState.successMessage);
            setShowSuccess(true);
        }
    }, [createInputState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (createInputState.reset) {
            reset()
        }
    }, [createInputState.reset]);

    useEffect( () => {
        if(createInputState.headerList){
            // const headerArray = [];
           // headerArray.push({data:createInputState.headerList}); //Match the format of the file uploader.
            setUploadFileHeaders({data:createInputState.headerList});
            if(type) {
                dispatch(getMappings(type.value));
            }
        }
    }, [createInputState.headerList]);

    useEffect( () => {
        if(createInputState.mapping){
            // const headerArray = [];
            // headerArray.push({data:createInputState.headerList}); //Match the format of the file uploader.
            setMapping(createInputState.mapping);
        }
    }, [createInputState.mapping]);

    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Inputs',
        href: '/admin/inputs',
        isActive: false
    }, {label: 'Create Input', href: '', isActive: true}];

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Create a new Input'} subHeader={'Define your new input, upload your data and map your input to create a new input'} />
            <Panel>
                {createInputState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createInput">
                            <Row>
                                <Col md={6}>
                                    <FormAsyncSelect
                                        label={'Source'}
                                        required
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadSourceOptions}
                                        getOptionLabel={e => `${e.name} (${e.id})`}
                                        getOptionValue={e => e.id}
                                        onChange={handleSourceOnChange}
                                        onInputChange={handleSourceInputChange}
                                        isInvalid={!formValid && !source}
                                        value={source}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Type'}
                                        options={filter(values(TYPES).map((t) => ({value: t.id, label: t.display})), (v) => {
                                            if (
                                                v.value === "PERMIT"
                                                || v.value === "LICENSE"
                                                || v.value === "PROFILE"
                                                || v.value === "POLICY"
                                                || v.value === "REGISTRATION"
                                            ) return true;
                                        })}
                                        onChange={handleTypeOnChange}
                                        isInvalid={!formValid && !type}
                                        value={{value: type.value, label: type.label}}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextInput
                                        label={'Name'}
                                        value={name}
                                        onChange={value => setName(value)}
                                        isInvalid={!formValid && !name}
                                        size={'sm'}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextInput
                                        label={'Label'}
                                        value={label}
                                        onChange={value => setLabel(value)}
                                        isInvalid={!formValid && !label}
                                        size={'sm'}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextArea
                                        label={'Description'}
                                        onChange={value => setDescription(value)}
                                        value={description}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextInput
                                        label={'URL'}
                                        value={url}
                                        onChange={value => setUrl(value)}
                                        size={'sm'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextInput
                                        label={'Quality Score'}
                                        value={qualityScore}
                                        onChange={value => setQualityScore(value)}
                                        size={'sm'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <CheckBox
                                        label={'Authoritative'}
                                        checked={authoritative}
                                        onChange={value => setAuthoritative(value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <CheckBox
                                        label={'Public'}
                                        checked={isPublic}
                                        onChange={value => setIsPublic(value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Products'}
                                        options={productOptions}
                                        onChange={(v) => setProducts(v)}
                                        value={products}
                                        isMulti
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Indicators'}
                                        options={filter(values(INDICATOR_TYPES), (i) => {
                                            return !!i.display}).map((t) => ({value: t.id, label: t.display})
                                        )}
                                        onChange={(v) => {
                                            v && v.length >0 ? setIndicators(v): setIndicators(null);
                                        }}
                                        value={indicators}
                                        isMulti
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Data Type'}
                                        options={dataTypes}
                                        onChange={(v) => setDataType(v)}
                                        value={dataType}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="button-group">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        onClick={() => handleChangeInputChannel("upload")}
                                        disabled={dataType.value !== 'csv'}
                                    >Upload
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        onClick={() => handleChangeInputChannel("S3")}
                                        disabled={dataType.value !== 'avro'}
                                    >S3(Avro)
                                    </Button>
                                </Col>
                            </Row>
                            {createInputState.inputChannel === 'upload' && (
                                <Row>

                                    <Col md={6}>
                                        {/*<FormLabel>Upload</FormLabel>  */}

                                        <CSVReader
                                            ref={uploadRef}
                                            onDrop={handleOnFileDrop}
                                            onError={handleOnFileError}
                                            onRemoveFile={handleOnRemoveFile}
                                            addRemoveButton
                                            config={{preview: 10}}
                                            style={{
                                                dropArea: {
                                                    border: 'dashed',
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    marginBottom: 10,
                                                },
                                                dropAreaActive: {
                                                    borderColor: 'red',
                                                },
                                                dropFile: {
                                                    width: 100,
                                                    height: 120,
                                                    background: '#fff',
                                                },
                                                fileSizeInfo: {
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                    borderRadius: 0,
                                                    lineHeight: 1,
                                                    marginBottom: '0.5em',
                                                    padding: '0 0.4em',
                                                },
                                                fileNameInfo: {
                                                    color: '#fff',
                                                    backgroundColor: '#eee',
                                                    borderRadius: 3,
                                                    fontSize: 14,
                                                    lineHeight: 1,
                                                    padding: '0 0.4em',
                                                },
                                                removeButton: {
                                                    color: 'blue',
                                                },
                                                progressBar: {
                                                    backgroundColor: 'pink',
                                                },
                                            }}
                                        >
                                            <SourceDrop>
                                                <Image
                                                    src={BrowserWindowImage}
                                                    width="110"
                                                    alt="Browser Window Image"
                                                    fluid
                                                />
                                                <FormLabel>
                                                    <b>Drag n' drop your file here or click to select your file</b>
                                                </FormLabel>
                                            </SourceDrop>
                                        </CSVReader>
                                    </Col>
                                </Row>
                            )}
                            {createInputState.inputChannel === 'S3' && (
                                <Row>
                                    <Col>
                                        <FileTreeBrowser onFileSelected={onFileSelected}/>
                                    </Col>
                                </Row>
                            )
                            }
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Mapping</FormLabel>
                                    <MappingContainer>
                                        {(!type || !fileSelected || !uploadFileHeaders) && (
                                            <div style={{marginTop: 5}}>
                                                <FormLabel>
                                                    Please select an input type AND file to upload
                                                </FormLabel>
                                            </div>
                                        )}
                                        {type && fileSelected && uploadFileHeaders && (
                                            <>
                                                <MappingHeader>
                                                    <div>{`Map To (${type.label}) Fields`}</div>
                                                    <div>Selected Fields from File</div>
                                                </MappingHeader>
                                                {mappingAttributes && mappingAttributes.map((ma, idx) => {
                                                    const selectedFileHeader = find(mappedToAttributes, (o) => {return o.attribute === ma.attribute}) && find(mappedToAttributes, (o) => {return o.attribute === ma.attribute}).header;
                                                    const alreadyUsedHeader = mappedToAttributes && mappedToAttributes.map((o) => {return o.header});
                                                    const headersToDisplay = selectedFileHeader ? concat(selectedFileHeader, difference(uploadFileHeaders.data, alreadyUsedHeader)) : difference(uploadFileHeaders.data, alreadyUsedHeader);
                                                    const options = headersToDisplay && headersToDisplay.map(header => {return {value: header, label: header}});
                                                    return (
                                                        <MappingRow key={`mappingRecord-${idx}`}>
                                                            <FormControl
                                                                value={ma.display}
                                                                disabled
                                                                type={"text"}
                                                            />
                                                            <FormSelect
                                                                options={options}
                                                                onChange={(v) => handleOnMappingSelect(ma.attribute, v ? v.value : null, ma.required)}
                                                                value={{value: selectedFileHeader, label: selectedFileHeader}}
                                                                isClearable={!!selectedFileHeader}
                                                                placeholder={'Select a field'}
                                                            />
                                                        </MappingRow>
                                                    )
                                                })}
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                                        <Button
                                                            className="btn-min-width-120 btn-height-35"
                                                            variant="outline-secondary"
                                                            onClick={handleMapByName}
                                                        >MAP BY NAME
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </MappingContainer>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateInput()}
                                    >CREATE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => history.push('/admin/inputs')}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
            {/*<div className="create-input-gutter">*/}
            {/*    <div className="create-input-container">*/}
            {/*        <div className="create-input-form-container">*/}
            {/*            <Form noValidate>*/}
            {/*                <Form.Group controlId="createInput">*/}
            {/*                    <Row>*/}
            {/*                        <Col md={6}>*/}
            {/*                            <h3>Define</h3>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Source</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <AsyncSelect*/}
            {/*                                        isClearable*/}
            {/*                                        cacheOptions*/}
            {/*                                        defaultOptions*/}
            {/*                                        loadOptions={loadSourceOptions}*/}
            {/*                                        getOptionLabel={e => `${e.name} (${e.id})`}*/}
            {/*                                        getOptionValue={e => e.id}*/}
            {/*                                        onChange={handleSourceOnChange}*/}
            {/*                                        onInputChange={handleSourceInputChange}*/}
            {/*                                        isInvalid={!formValid && !source}*/}
            {/*                                        value={source}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Type</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Select*/}
            {/*                                        options={filter(values(TYPES).map((t) => ({value: t.id, label: t.display})), (v) => {*/}
            {/*                                            if (*/}
            {/*                                                v.value === "PERMIT"*/}
            {/*                                                || v.value === "LICENSE"*/}
            {/*                                                || v.value === "PROFILE"*/}
            {/*                                                || v.value === "POLICY"*/}
            {/*                                                || v.value === "REGISTRATION"*/}
            {/*                                            ) return true;*/}
            {/*                                        })}*/}
            {/*                                        onChange={handleTypeOnChange}*/}
            {/*                                        isInvalid={!formValid && !type}*/}
            {/*                                        value={{value: type.value, label: type.label}}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Name</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Control*/}
            {/*                                        as="input"*/}
            {/*                                        size="sm"*/}
            {/*                                        isInvalid={!formValid && !name}*/}
            {/*                                        onChange={(e) => setName(e.target.value)}*/}
            {/*                                        value={name}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Label</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Control*/}
            {/*                                        as="input"*/}
            {/*                                        size="sm"*/}
            {/*                                        isInvalid={!formValid && !label}*/}
            {/*                                        onChange={(e) => setLabel(e.target.value)}*/}
            {/*                                        value={label}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Description</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Control*/}
            {/*                                        as="textarea"*/}
            {/*                                        rows={3}*/}
            {/*                                        size="sm"*/}
            {/*                                        onChange={(e) => setDescription(e.target.value)}*/}
            {/*                                        value={description}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>URL</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Control*/}
            {/*                                        as="input"*/}
            {/*                                        size="sm"*/}
            {/*                                        onChange={(e) => setUrl(e.target.value)}*/}
            {/*                                        value={url}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Quality Score</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={3}>*/}
            {/*                                    <Form.Control*/}
            {/*                                        as="input"*/}
            {/*                                        type="number"*/}
            {/*                                        size="sm"*/}
            {/*                                        onChange={(e) => setQualityScore(e.target.value)}*/}
            {/*                                        value={qualityScore}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                                <Col md={6} />*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3}/>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Check*/}
            {/*                                        id="create-input-authoritative-checkbox"*/}
            {/*                                        type="checkbox"*/}
            {/*                                        label="Authoritative"*/}
            {/*                                        onChange={(e) => setAuthoritative(e.target.checked)}*/}
            {/*                                        checked={authoritative}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3}/>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Form.Check*/}
            {/*                                        id="create-input-is-public-checkbox"*/}
            {/*                                        type="checkbox"*/}
            {/*                                        label="Public"*/}
            {/*                                        onChange={(e) => setIsPublic(e.target.checked)}*/}
            {/*                                        checked={isPublic}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Products</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Select*/}
            {/*                                        isMulti*/}
            {/*                                        options={productOptions}*/}
            {/*                                        onChange={(v) => setProducts(v)}*/}
            {/*                                        value={products}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Indicators</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Select*/}
            {/*                                        isMulti*/}
            {/*                                        options={filter(values(INDICATOR_TYPES), (i) => {return !!i.display}).map((t) => ({value: t.id, label: t.display}))}*/}
            {/*                                        onChange={(v) => setIndicators(v)}*/}
            {/*                                        value={indicators}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            <h4>Upload</h4>*/}
            {/*                            <Row>*/}
            {/*                                <Col md={3} className="create-input-form-label">*/}
            {/*                                    <Form.Label>Data Type</Form.Label>*/}
            {/*                                </Col>*/}
            {/*                                <Col md={9}>*/}
            {/*                                    <Select*/}
            {/*                                        isClearable*/}
            {/*                                        options={dataTypes}*/}
            {/*                                        onChange={(v) => setDataType(v)}*/}
            {/*                                        value={dataType}*/}
            {/*                                    />*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}
            {/*                            {!dataType && (*/}
            {/*                                <Row>*/}
            {/*                                    <Col md={12} className="text-center mt-5">*/}
            {/*                                        <h5>Please select a type</h5>*/}
            {/*                                    </Col>*/}
            {/*                                </Row>*/}
            {/*                            )}*/}
            {/*                            {dataType && (*/}
            {/*                                <Row>*/}
            {/*                                    <Col md={12} className="text-center mt-5">*/}
            {/*                                        <div className="upload-container">*/}
            {/*                                            {(*/}
            {/*                                                <CSVReader*/}
            {/*                                                    ref={uploadRef}*/}
            {/*                                                    onDrop={handleOnFileDrop}*/}
            {/*                                                    onError={handleOnFileError}*/}
            {/*                                                    onRemoveFile={handleOnRemoveFile}*/}
            {/*                                                    addRemoveButton*/}
            {/*                                                    config={{preview: 10}}*/}
            {/*                                                    style={{*/}
            {/*                                                        dropArea: {*/}
            {/*                                                            border: 'dashed'*/}
            {/*                                                        },*/}
            {/*                                                        removeButton: {*/}
            {/*                                                            color: 'blue',*/}
            {/*                                                        },*/}
            {/*                                                        progressBar: {*/}
            {/*                                                            backgroundColor: APP_COLORS.orange,*/}
            {/*                                                        },*/}
            {/*                                                    }}*/}
            {/*                                                >*/}
            {/*                                                    <div className="source-image">*/}
            {/*                                                        <Image*/}
            {/*                                                            src={BrowserWindowImage}*/}
            {/*                                                            width="110"*/}
            {/*                                                            alt="Browser Window Image"*/}
            {/*                                                            fluid*/}
            {/*                                                        />*/}
            {/*                                                        <FormLabel>*/}
            {/*                                                            <b>Drag n' drop your file here or click to select your file</b>*/}
            {/*                                                        </FormLabel>*/}
            {/*                                                    </div>*/}
            {/*                                                </CSVReader>*/}
            {/*                                            )}*/}
            {/*                                        </div>*/}
            {/*                                    </Col>*/}
            {/*                                </Row>*/}
            {/*                            )}*/}
            {/*                        </Col>*/}
            {/*                        /!*Mapping*!/*/}
            {/*                        <Col md={6}>*/}
            {/*                            <h4>Map</h4>*/}
            {/*                            {(!type || !uploadFile || !uploadFileHeaders) && (*/}
            {/*                                <Row>*/}
            {/*                                    <Col md={12} className="text-center mt-5">*/}
            {/*                                        <h5>Please select an input type AND file to upload</h5>*/}
            {/*                                    </Col>*/}
            {/*                                </Row>*/}
            {/*                            )}*/}
            {/*                            {type && uploadFile && uploadFileHeaders && (*/}
            {/*                                <div>*/}
            {/*                                    <Row>*/}
            {/*                                        <Col md={9}>*/}
            {/*                                            <small>{`mapping: ${uploadFile.name}`}</small><br/>*/}
            {/*                                            <small>{`file size: ${uploadFile.size} (bytes)`}</small><br/>*/}
            {/*                                            <small>{`number of headers: ${uploadFileHeaders.data.length}`}</small><br/>*/}
            {/*                                        </Col>*/}
            {/*                                        <Col md={3} >*/}
            {/*                                            <div className="mapping-auto-fill-button">*/}
            {/*                                                <Button*/}
            {/*                                                    className="btn-min-width-120 btn-height-35"*/}
            {/*                                                    variant="outline-info"*/}
            {/*                                                    onClick={handleMapByName}*/}
            {/*                                                >MAP BY NAME*/}
            {/*                                                </Button>*/}
            {/*                                            </div>*/}
            {/*                                        </Col>*/}
            {/*                                    </Row>*/}
            {/*                                    <Row className="mapping-container">*/}
            {/*                                        <Col md={12}>*/}
            {/*                                            <Row className={"mapping-header"}>*/}
            {/*                                                <Col md={5}> {`Map To (${type.label}) Fields`} </Col>*/}
            {/*                                                <Col md={5}> Selected Fields from File </Col>*/}
            {/*                                                <Col md={2}> Status </Col>*/}
            {/*                                            </Row>*/}
            {/*                                            <div className={"mapping-record-container"}>*/}
            {/*                                                {mappingAttributes && mappingAttributes.map((ma) => {*/}
            {/*                                                    const selectedFileHeader = find(mappedToAttributes, (o) => {return o.attribute === ma.attribute}) && find(mappedToAttributes, (o) => {return o.attribute === ma.attribute}).header;*/}
            {/*                                                    const alreadyUsedHeader = mappedToAttributes && mappedToAttributes.map((o) => {return o.header});*/}
            {/*                                                    const headersToDisplay = selectedFileHeader ? concat(selectedFileHeader, difference(uploadFileHeaders.data, alreadyUsedHeader)) : difference(uploadFileHeaders.data, alreadyUsedHeader);*/}
            {/*                                                    const options = headersToDisplay && headersToDisplay.map(header => {return {value: header, label: header}});*/}
            {/*                                                    return (*/}
            {/*                                                        <Row className="mapping-record">*/}
            {/*                                                            <Col md={4} className="mapping-record-display">*/}
            {/*                                                                {ma.display}*/}
            {/*                                                            </Col>*/}
            {/*                                                            <Col md={5}>*/}
            {/*                                                                <Select*/}
            {/*                                                                    className="basic-single"*/}
            {/*                                                                    classNamePrefix="select"*/}
            {/*                                                                    options={options}*/}
            {/*                                                                    onChange={(v) => handleOnMappingSelect(ma.attribute, v ? v.value : null, ma.required)}*/}
            {/*                                                                    placeholder={'select a field'}*/}
            {/*                                                                    value={{value: selectedFileHeader, label: selectedFileHeader}}*/}
            {/*                                                                    isClearable={!!selectedFileHeader}*/}
            {/*                                                                />*/}
            {/*                                                            </Col>*/}
            {/*                                                            <Col md={2} className="mapping-record-status">*/}
            {/*                                                                { selectedFileHeader && (<span style={{color: APP_COLORS.green}}>mapped</span>)}*/}
            {/*                                                                { !selectedFileHeader && ma.required && (<span style={{color: APP_COLORS.red}}>required</span>)}*/}
            {/*                                                                { !selectedFileHeader && !ma.required && 'unmapped'}*/}
            {/*                                                            </Col>*/}
            {/*                                                        </Row>*/}
            {/*                                                    )*/}
            {/*                                                })}*/}
            {/*                                            </div>*/}
            {/*                                        </Col>*/}
            {/*                                    </Row>*/}
            {/*                                </div>*/}
            {/*                            )}*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                    <Row>*/}
            {/*                        <Col xl={12} lg={12} md={12} sm={12} className="buttons">*/}
            {/*                            <Button*/}
            {/*                                className="btn-min-width-120 btn-height-35"*/}
            {/*                                variant="info"*/}
            {/*                                onClick={() => handleCreateInput()}*/}
            {/*                            >CREATE*/}
            {/*                            </Button>*/}
            {/*                            <Button*/}
            {/*                                className="btn-min-width-120 btn-height-35"*/}
            {/*                                style={{marginLeft: "10px"}}*/}
            {/*                                variant="outline-secondary"*/}
            {/*                                onClick={() => history.push('/admin/inputs')}*/}
            {/*                            >CANCEL*/}
            {/*                            </Button>*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </Form.Group>*/}
            {/*            </Form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Container>
    );
}
