/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, FormControl, Row} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import {CSVReader, readString} from "react-papaparse";
import isEmail from 'validator/es/lib/isEmail';

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";

import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";

import _, {isEmpty} from "lodash";
import config from "../../config";

import * as Api from "../../common/Api";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../../common/Constants";
import {faBuilding, faMapMarkerAlt, faUser} from "@fortawesome/free-solid-svg-icons";

import FormSearchOrganizationsTypeahead from "../components/organizations/FormSearchOrganizationsTypeahead";
import FormSelect from "../../common/components/form/FormSelect";
import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import FormRadioGroup from "../../common/components/form/FormRadioGroup";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import FormLabel from "../../common/components/form/FormLabel";

import BrowserWindowImage from "../../common/assets/img/ToolbeltIcons_Artboard_17.png";
import FileImage from "../../common/assets/img/ToolbeltIcons_Artboard_17.png";
import {getFieldMappings} from "./adminListImportSlice";
import { LIST_STATUSES } from '../../common/metadata/ListStatuses';
import {getTypeById as ListgetTypeById} from '../../common/metadata/ListTypes';
import CheckBox from "../../common/components/CheckBox";

export default function AdminImportListContainer(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Lists',
        href: '/admin/lists',
        isActive: false
    }, {label: 'Import List', href: '', isActive: true}];

    const history = useHistory();
    const [importProcessing, setImportProcessing] = useState(false);
    const [message, setMessage] = useState(null);

    const handleOnImport = async (importData, processToStatus) => {
        setImportProcessing(true);
        // Build mapping payload
        const LIST_IMPORT_API = `${config.services.ATLAS.apiUrl}/managed-lists/import`;
        let mappingPayload = undefined;
        const mappingList = _.reduce(importData.mappingAttributes, function (result, a) {
            const obj = {};
            obj[a.key] = a.header;
            return Object.assign(result, obj)
        }, {});
        const fileHeaderFields = _.reduce(importData.fileHeaders, function (result = [], a, index) {
            result.push(`${a}_${index}`);
            return result;
        }, []);

        const inputSource = importData.inputSource;
        if ('TEXT_INPUT' === inputSource) {
            try {
                mappingPayload = {
                    options: {processToStatus},
                    data: {
                        organizationId: importData.organizationId,
                        type: importData.listType,
                        name: importData.listName,
                        description: importData.listDescription,
                        dataType: 'text',
                        mapping: {
                            listMappingId: importData.fieldMappings && importData.fieldMappings.length > 0 && importData.fieldMappings[0].listMappingId,
                            attributes: mappingList,
                            fields: fileHeaderFields
                        },
                        text: importData.textInput,
                        config: {notifyByEmailOnSuccessOrFailure: importData.notifyByEmailOnSuccessOrFailure}
                    }
                }
                await Api.post(true, LIST_IMPORT_API, mappingPayload);
                setImportProcessing(false);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setMessage({
                    variant: 'success',
                    message: 'Successfully import list'
                });
            } catch (e) {
                console.log(e);
                setImportProcessing(false);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setMessage({
                    variant: 'danger',
                    message: 'Unable to import list'
                });
            }
        } else {
            const PRE_SIGN_URL = `${config.services.ATLAS.apiUrl}/managed-lists/upload`;
            const content = {
                options: null,
                data: {
                    organizationId: importData.organizationId,
                    fileName: importData.fileUrl
                }
            }
            try {
                const signedContent = await Api.post(true, PRE_SIGN_URL, content);
                await fetch(signedContent.uploadUrl, {
                    method: 'PUT',
                    headers: {'Content-Type': 'text/plain'},
                    body: importData.uploadFile
                });

                mappingPayload = {
                    options: {processToStatus},
                    data: {
                        organizationId: importData.organizationId,
                        type: importData.listType,
                        name: importData.listName,
                        description: importData.listDescription,
                        dataType: importData.fileType,
                        dataFileName: importData.fileUrl,
                        dataRef: signedContent.uploadRef,
                        mapping: {
                            listMappingId: importData.fieldMappings && importData.fieldMappings.length > 0 && importData.fieldMappings[0].listMappingId,
                            attributes: mappingList,
                            fields: fileHeaderFields
                        },
                        config: {notifyByEmailOnSuccessOrFailure: importData.notifyByEmailOnSuccessOrFailure}
                    }
                }
                await Api.post(true, LIST_IMPORT_API, mappingPayload);
                setImportProcessing(false);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setMessage({
                    variant: 'success',
                    message: 'Successfully import list'
                });
            } catch (e) {
                console.log(e);
                setImportProcessing(false);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setMessage({
                    variant: 'danger',
                    message: 'Unable to import list'
                });
            }
        }
    }

    const handleOnCancel = async () => {
        history.push('/admin/lists')
    };

    const listTypes = [
        {
            value: "IMPORT_COMPANY", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faBuilding}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Companies</span>
                </div>
        },
        {
            value: "IMPORT_CONTACT", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faUser}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Contacts</span>
                </div>
        },
        {
            value: "IMPORT_OVERLAY", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faMapMarkerAlt}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Overlays</span>
                </div>
        },
    ];

    const listSource = [
        {value: "FILE_UPLOAD", label: <>Upload</>},
        {value: "TEXT_INPUT", label: <>Text Input</>}
    ];

    const fileTypes = [{value: 'csv', label: 'Comma Separated Value (CSV)'}];

    return (
        <AdminImportList
            breadcrumbItems={breadcrumbItems}
            handleOnImport={(importData, processToStatus) => handleOnImport(importData, processToStatus)}
            handleOnCancel={handleOnCancel}
            listTypes={listTypes}
            listSource={listSource}
            fileTypes={fileTypes}
            importProcessing={importProcessing}
            message={message}
            onCloseMessageAlert={() => setMessage(null)}
        />
    )
}

const uploadRef = React.createRef();

function AdminImportList(props) {
    const {
        breadcrumbItems,
        handleOnImport,
        handleOnCancel,
        listTypes,
        listSource,
        fileTypes,
        importProcessing,
        message,
        onCloseMessageAlert,
    } = props;

    const dispatch = useDispatch();
    const adminListImportState = useSelector(state => state.adminListImport);
    const {account} = useSelector(state => state.account);


    const [formValid, setFormValid] = useState(true);

    const [organization, setOrganization] = useState([]);
    const [processStatus, setProcessStatus] = useState({value: 'READY', label: 'Ready', processToStatus: 'READY'});
    const [listType, setListType] = useState(listTypes[0]);
    const [inputSource, setInputSource] = useState(listSource[0].value);
    const [fileUrl, setFileUrl] = useState('');
    // const [fileSize, setFileSize] = useState(0);
    const [fileType, setFileType] = useState(fileTypes[0]);
    const [textInput, setTextInput] = useState('');
    const [rawTextInput, setRawTextInput] = useState('');
    const [listName, setListName] = useState('');
    const [listDescription, setListDescription] = useState('');
    const [fieldMappings, setFieldMappings] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [mappingAttributes, setMappingAttributes] = useState([]);
    const [notifyByEmailOnSuccessOrFailure, setNotifyByEmailOnSuccessOrFailure] = useState('');
    const [notifyByEmailCheckbox, setNotifyByEmailCheckbox] = useState(true);
    // const [totalRecords, setTotalRecords] = useState(0);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setNotifyByEmailOnSuccessOrFailure(account.email);
    }, []);

    const [uploadFile, setUploadFile] = useState(null);

    const NAME_VALIDATE_REX = /^[A-Za-z0-9-\\ \\_\\.\\-\\(\\)]+$/;

    const validateNameList = () => {
        if (listName && listName.length > 0) {
            return NAME_VALIDATE_REX.test(listName);
        }

        return true;
    }

    const isNotificationEmailValid = () => {
        if (notifyByEmailCheckbox === true && isEmpty(notifyByEmailOnSuccessOrFailure)) {
            return false;
        } else if (notifyByEmailCheckbox === true && !isEmpty(notifyByEmailOnSuccessOrFailure)) {
            let isValidEmail = true;
            const emails = _.split(notifyByEmailOnSuccessOrFailure, ';');
            if (emails) {
                emails.map(async (email) => {
                    const _isValidEmail = isEmail(_.trim(email));
                    if (!_isValidEmail) {
                        isValidEmail = false;
                    }
                });
                return isValidEmail;
            }
        } else {
            return true;
        }
    };

    const handleOnDrop = (data, meta) => {
        const input = uploadRef.current;

        const sizeLimit = config.constants.LIMIT_FILE_UPLOAD;
        if (meta && meta.size > (sizeLimit * 1000000)) {
            input.state.files = null;
            setShowAlert(true);
            setAlertMessage('File size is not support.');
            // setTimeout(() => setShowAlert(false), 5000);

            return;
        }

        if (input && input.state && input.state.files) {
            handleUploadFile(input.state.files);
        }

        if (data && data.length > 0 && data[0].data && data[0].data.length > 0) {
            setFileHeaders(data[0].data);
            setInputSource(listSource[0].value);
        }

        if (meta) {
            setFileUrl(meta.name);
            // setFileSize(meta.size);
            // setFileType(meta.type);
        }

        if (input && _.isEmpty(listName)) {
            const fileName = input.state.files[0] && input.state.files[0].name;
            setListName(fileName.substring(0, fileName.indexOf('.')));
        }
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        if (data && data.length > 0) {
            setFileHeaders([]);
        }
    }

    const handleOnTextInputUpdate = (value) => {
        setRawTextInput(value);

        const result = readString(_.trim(value));
        const data = result && result.data;
        const headers = result && result.data && result.data[0];

        if (data && headers) {
            const dataWithNoHeader = _.tail(data);
            if (dataWithNoHeader && dataWithNoHeader.length > 0) {
                const finalText = dataWithNoHeader.map((each) => {
                    return each.reduce(function (accum, currentValue, i) {
                        accum[headers[i]] = currentValue;
                        return accum;
                    }, {});
                });

                setTextInput(finalText);
                setFileHeaders(headers);
                // setTotalRecords(dataWithNoHeader.length);
            }
        }

        setInputSource('TEXT_INPUT');
    }

    const handleUploadFile = (files) => {
        if (files) {
            if (files.length && files.length > 0) {
                setUploadFile(files[0]);
            }
        }
    };

    const handleSelectMyFileField = function (key, header, required) {
        const attr = {
            key: key,
            header: header,
            required: required
        };
        _.remove(mappingAttributes, (o) => {
            return o.key === key
        });

        const _mappingAttributes = mappingAttributes;
        if (attr.header) {
            _mappingAttributes.push(attr)
        }
        setMappingAttributes([..._mappingAttributes]);
    }

    const handleMapByName = function () {
        const _mappingAttributes = [];
        fieldMappings.forEach(field => {
            const headerValue = fileHeaders.find(header => header.includes(field.key));

            if (headerValue) {
                const attr = {
                    key: field.key,
                    header: headerValue,
                    required: field.required
                };
                _mappingAttributes.push(attr);
            }
        });

        setMappingAttributes([..._mappingAttributes]);
    }

    const isInvalid = () => {
        if (!organization || organization.length === 0) {
            return true;
        }

        return listName === '';
    }

    const enableImportButton = () => {
        if (!mappingAttributes || mappingAttributes.length === 0) {
            return false;
        }

        // 1: Validate notifyByEmailOnSuccessOrFailure
        if (!isNotificationEmailValid()) {
            return false;
        }

        return true;
    }

    const onImport = () => {
        if (isInvalid()) {
            setFormValid(false);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            const importData = {
                organizationId: organization[0].id,
                listType: listType.value,
                listName: listName,
                listDescription: listDescription,
                inputSource: inputSource,
                fileUrl: fileUrl,
                fileType: fileType.value,
                uploadFile: uploadFile,
                fieldMappings: fieldMappings,
                mappingAttributes: mappingAttributes,
                textInput: textInput,
                fileHeaders: fileHeaders,
                notifyByEmailOnSuccessOrFailure: notifyByEmailOnSuccessOrFailure
            };
            const processToStatus = processStatus ? processStatus.processToStatus : undefined;
            handleOnImport(importData, processToStatus);
        }
    }

    const handleUpdateNotifyByEmailOnSuccessOrFailure = function(notifyByEmailOnSuccessOrFailure) {
        setNotifyByEmailOnSuccessOrFailure(notifyByEmailOnSuccessOrFailure);
    }

    const resetImportData = () => {
        setOrganization([]);
        setProcessStatus({value: 'READY', label: 'Ready', processToStatus: 'READY'});
        setListType(listTypes[0]);
        setInputSource(listSource[0].value);
        setFileUrl('');
        setFileType(fileTypes[0]);
        setTextInput('');
        setRawTextInput('');
        setListName('');
        setListDescription('');
        setFieldMappings([]);
        setFileHeaders([]);
        setMappingAttributes([]);
        setFormValid(true);
        setNotifyByEmailOnSuccessOrFailure('');
    }

    useEffect(() => {
        if (message && message.variant === 'success') {
            resetImportData();
        }
    }, [message]);

    useEffect(() => {
        dispatch(getFieldMappings(listType.value));
    }, [listType]);

    useEffect(() => {
        setFieldMappings([...adminListImportState.fieldMappings]);
    }, [adminListImportState.fieldMappings]);

    useEffect(() => {
        if (inputSource === 'FILE_UPLOAD') {
            setRawTextInput('');
            setTextInput('');
        } else {
            setFileUrl('');
        }
    }, [inputSource]);

    const getProcessStatuses = () => {
        const _listTypeObj = ListgetTypeById(listType.value);
        const processStatuses = _.filter(_.map(LIST_STATUSES, status => status), status => (status.processTo && status.listTypes.includes(_listTypeObj.domain)));
        return _.map(processStatuses, status => ({
           value: status.id,
            label:  status.display,
            processToStatus: status.id
        }));
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Import List'} subHeader={'Import (create) a new list'}/>
            <Panel>
                {importProcessing && (
                    <SpinnerOverlay/>
                )}
                {message && (
                    <Alert
                        variant={message.variant}
                        show={!isEmpty(message.message)}
                        onClose={() => onCloseMessageAlert()}
                        dismissible
                        transition={false}
                    >
                        {message.message}
                    </Alert>
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="adminImportList">
                            <Row>
                                <Col md={6}>
                                    <FormSearchOrganizationsTypeahead
                                        label={'Organization Id'}
                                        onChange={setOrganization}
                                        organization={organization}
                                        isInvalid={!formValid && organization.length === 0}
                                        editing
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Type'}
                                        options={listTypes}
                                        onChange={(v) => setListType(v)}
                                        value={listType}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextInput
                                        label={'Name'}
                                        value={listName}
                                        onChange={value => setListName(value)}
                                        isInvalid={!formValid && !validateNameList()}
                                        size={'sm'}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormTextArea
                                        label={'Description'}
                                        onChange={value => setListDescription(value)}
                                        value={listDescription}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Process to Status'}
                                        options={getProcessStatuses()}
                                        onChange={(v) => setProcessStatus(v)}
                                        value={processStatus}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormRadioGroup
                                        id={'ListSourceSelection'}
                                        name={'ListSourceSelection'}
                                        label={'Source'}
                                        value={inputSource}
                                        items={listSource}
                                        onChange={selectedValue => setInputSource(selectedValue)}
                                        radioButton
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    {inputSource === 'FILE_UPLOAD'
                                    && fileUrl.length === 0 && (
                                        <CSVReader
                                            ref={uploadRef}
                                            onDrop={handleOnDrop}
                                            onError={handleOnError}
                                            addRemoveButton
                                            onRemoveFile={handleOnRemoveFile}
                                            config={{preview: 10}}
                                            style={{
                                                dropArea: {
                                                    border: 'dashed',
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    marginBottom: 10,
                                                },
                                                dropAreaActive: {
                                                    borderColor: 'red',
                                                },
                                                dropFile: {
                                                    width: 100,
                                                    height: 120,
                                                    background: '#fff',
                                                },
                                                fileSizeInfo: {
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                    borderRadius: 0,
                                                    lineHeight: 1,
                                                    marginBottom: '0.5em',
                                                    padding: '0 0.4em',
                                                },
                                                fileNameInfo: {
                                                    color: '#fff',
                                                    backgroundColor: '#eee',
                                                    borderRadius: 3,
                                                    fontSize: 14,
                                                    lineHeight: 1,
                                                    padding: '0 0.4em',
                                                },
                                                removeButton: {
                                                    color: 'blue',
                                                },
                                                progressBar: {
                                                    backgroundColor: 'pink',
                                                },
                                            }}
                                        >
                                            <SourceDrop>
                                                <Image
                                                    src={BrowserWindowImage}
                                                    width="110"
                                                    alt="Browser Window Image"
                                                    fluid
                                                />
                                                <FormLabel>
                                                    <b>Drag n' drop your file here or click to select your file</b>
                                                </FormLabel>
                                            </SourceDrop>
                                        </CSVReader>
                                    )
                                    }
                                    {inputSource === 'FILE_UPLOAD'
                                    && fileUrl.length !== 0 && (
                                        <SourceFile>
                                            <Image
                                                src={FileImage}
                                                width="110"
                                                alt="File Image"
                                                fluid
                                            />
                                            <FormLabel>
                                                <span className="file-name">{fileUrl}</span> selected for upload
                                            </FormLabel>
                                        </SourceFile>
                                    )
                                    }
                                    {inputSource === 'TEXT_INPUT' && (
                                        <div className="source-text-input">
                                            <Row>
                                                <Col>
                                                    <FormTextArea
                                                        label={'Type or Copy / Paste your list below.'}
                                                        onChange={value => handleOnTextInputUpdate(value)}
                                                        value={rawTextInput}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormSelect
                                        label={'Data Type'}
                                        options={fileTypes}
                                        onChange={(v) => setFileType(v)}
                                        value={fileType}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Mapping</FormLabel>
                                    <MappingContainer>
                                        <MappingHeader>
                                            <div>Fields (Map To)</div>
                                            <div>My File Fields (Map From)</div>
                                        </MappingHeader>
                                        {fieldMappings && fieldMappings.map((field, idx) => {
                                            const selectedHeaderField = _.find(mappingAttributes, (o) => {
                                                return o.key === field.key
                                            }) ? _.find(mappingAttributes, (o) => {
                                                return o.key === field.key
                                            }).header : '';
                                            const alreadyUsedHeader = mappingAttributes ? mappingAttributes.map((o) => {
                                                return o.header
                                            }) : '';
                                            const toDisplayHeader = _.concat(selectedHeaderField, _.difference(fileHeaders, alreadyUsedHeader));
                                            const options = toDisplayHeader && toDisplayHeader.map(header => {
                                                return {value: header, label: header}
                                            });

                                            return (
                                                <MappingRow key={`mappingRecord-${idx}`}>
                                                    <FormControl
                                                        value={field.display}
                                                        disabled
                                                        type={"text"}
                                                    />
                                                    <FormSelect
                                                        options={options}
                                                        onChange={(v) => handleSelectMyFileField(field.key, v ? v.value : undefined, field.required)}
                                                        value={{value: selectedHeaderField, label: selectedHeaderField}}
                                                        isClearable={!!selectedHeaderField}
                                                        placeholder={'Select a field'}
                                                        disabled={!fileHeaders || fileHeaders.length === 0}
                                                    />
                                                </MappingRow>
                                            )
                                        })}
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                                <Button
                                                    className="btn-min-width-120 btn-height-35"
                                                    variant="outline-secondary"
                                                    onClick={() => handleMapByName()}
                                                    disabled={!fileHeaders || fileHeaders.length === 0}
                                                >MAP BY NAME
                                                </Button>
                                                <Button
                                                    className="btn-min-width-120 btn-height-35"
                                                    style={{marginLeft: "10px"}}
                                                    variant="outline-secondary"
                                                    onClick={() => setMappingAttributes([])}
                                                    disabled={!fileHeaders || fileHeaders.length === 0}
                                                >RESET
                                                </Button>
                                            </Col>
                                        </Row>
                                    </MappingContainer>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormLabel>Options</FormLabel>
                                    <CheckBox
                                        label={'Notify me when my import completes'}
                                        checked={notifyByEmailCheckbox}
                                        onChange={(e) => {setNotifyByEmailCheckbox(e); setNotifyByEmailOnSuccessOrFailure('')}}
                                    />
                                    <FormTextInput
                                        placeholder={'Multiple emails can be provided, separate with semi-colon'}
                                        className={'input'}
                                        size={'sm'}
                                        value={notifyByEmailOnSuccessOrFailure}
                                        onChange={(e) => {handleUpdateNotifyByEmailOnSuccessOrFailure(e)}}
                                        disabled={!notifyByEmailCheckbox}
                                        isInvalid={!isNotificationEmailValid()}
                                        validation={{
                                            state: !isNotificationEmailValid() === true ? 'error' : undefined,
                                            message: 'Invalid Email syntax. If you try to put multiple emails, please use ; to separate your inputs.'
                                        }}
                                    >
                                    </FormTextInput>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => onImport()}
                                        disabled={!enableImportButton()}
                                    >IMPORT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => handleOnCancel()}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    )
}

const SourceDrop = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr;

  label {
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;
  }
`;

const SourceFile = styled.div`
  display: grid;
  justify-items: start;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr;

  .file-name {
    color: ${APP_COLORS['orange']};
    font-weight: bold;
  }

  .source-file-type {
    width: 100%;
    margin-top: 20px;

    font-size: 15px;

    .col-form-label {
      padding-left: 0;
      text-align: left;

      label {
        margin-bottom: 0;
      }
    }
  }
`;

const MappingContainer = styled.div`
  border: 1px dashed #CCC;
  padding: 20px;
`;

const MappingHeader = styled.div`
  font-weight: bold;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 15px;
`;

const MappingRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-bottom: 15px;
`;
