import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    companyName: null
};

export const getCompanyNameById = createAsyncThunk('companies/getCompanyNameById', async (id) => {
    const GET_COMPANY_NAME_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${id}/name`;
    const companyName = await Api.get(true, GET_COMPANY_NAME_BY_ID_URL);
    return companyName;
});

export const changeCompanyNameById = createAsyncThunk('companies/changeCompanyNameById', async (data) => {
    const CHANGE_COMPANY_NAME_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/name/change`;
    const response = await Api.post(true, CHANGE_COMPANY_NAME_BY_ID_URL, data.body);
    return response;
});

const companyNameSlice = createSlice({
    name: 'companyNameSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.companyName = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyNameById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyNameById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyName = action.payload;
            }
        },
        [getCompanyNameById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
        [changeCompanyNameById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeCompanyNameById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyName = action.payload;
            }
        },
        [changeCompanyNameById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
    }
});

export const { clearState } = companyNameSlice.actions;
export default companyNameSlice.reducer;
