/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import React from "react";
import styled from "styled-components";
import CardLabel from "../../../../organization/explore/components/cards/CardLabel";
import {APP_COLORS} from "../../../../common/Constants";

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px, max-content);
  padding: 0 10px 0 0;
`;

const PillRow = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
`;

const PillContainer = styled.div `
  border-radius: 5px;
  border: 1px solid ${APP_COLORS['gray-card']};
  margin: 3px;
  background-color: ${APP_COLORS['gray-100']};
`;

const PillText = styled.span `
  font-size: 14px;
  padding: 5px;
  // padding-left: 5px;
  // padding-right: 5px;
  color: ${APP_COLORS['tech-blue']};
  font-weight: 600;
`;

export default function CompanyProductsBox(props) {
    const {
        item
    } = props;

    return (
        <BoxContainer>
            <CardLabel>Products</CardLabel>
            <PillRow>
                <PillContainer key={0}>
                    <PillText>Company Universe</PillText>
                </PillContainer>
                {item.companyProductTcu === true && 
                    <PillContainer key={1}>
                        <PillText>Contractor Universe</PillText>
                    </PillContainer>
                }
            </PillRow>
        </BoxContainer>
    )
}
