import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import { isGuid } from "../../common/utils/Utils";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedLists: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    adminLists: [],
    download: undefined,
};


// export const createAdminList = createAsyncThunk('adminLists/create-adminList',
// async (input) => {
//     const CREATE_LIST = `${config.services.ATLAS.apiUrl}/managed-lists/onboard`;
//     console.log('Creating Input');
//     return await Api.post(true,
//         CREATE_LIST,
//         {
//             options: null,
//             data: input
//         }
//     );
// });

export const getAdminLists = createAsyncThunk('adminLists/get-all', async (arg, {getState, requestId}) => {
    const GET_MANAGED_LISTS_URL = `${config.services.ATLAS.apiUrl}/managed-lists`;
    const adminLists = await Api.get(true, GET_MANAGED_LISTS_URL, buildQuery(getState().adminLists.currentPage, getState().adminLists.pageSize,
        getState().adminLists.order.by, getState().adminLists.order.direction, getState().adminLists.searchCriteria));
    return adminLists;
});

export const getAdminListsWithFilter = createAsyncThunk('adminLists/get-all-with-filter', async (filter) => {
    const GET_MANAGED_LISTS_URL = `${config.services.ATLAS.apiUrl}/managed-lists`;
    const adminLists = await Api.get(true, GET_MANAGED_LISTS_URL, filter);
    return adminLists;
});

export const getAdminListsCount = createAsyncThunk('adminLists/getCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const searchCriteria = getState().adminLists.searchCriteria ? getState().adminLists.searchCriteria : '';
        const where = isGuid(searchCriteria) ? {
            where: {
                or: [
                    {
                        id: {
                            eq: searchCriteria
                        }
                    },
                    {
                        organizationId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        name: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        description: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        type: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        status: {
                            ilike: `%${searchCriteria}%`
                        }
                    }
                ]
            }
        } : {
            where: {
                or: [
                    {
                        name: {
                            ilike: `%${getState().adminLists.searchCriteria ? getState().adminLists.searchCriteria : ''}%`
                        }
                    },
                    {
                        description: {
                            ilike: `%${getState().adminLists.searchCriteria ? getState().adminLists.searchCriteria : ''}%`
                        }
                    },
                    {
                        type: {
                            ilike: `%${getState().adminLists.searchCriteria ? getState().adminLists.searchCriteria : ''}%`
                        }
                    },
                    {
                        status: {
                            ilike: `%${getState().adminLists.searchCriteria ? getState().adminLists.searchCriteria : ''}%`
                        }
                    }
                ]
            }
        }
        const SOURCE_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-lists/count`;
        const adminListsCount = await Api.get(true, SOURCE_COUNT_URL, {filter: where});
        return adminListsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = isGuid(searchCriteria) ? {
        filter: {
            // fields: ['id', 'typeId', 'name', 'abbrv', 'statusId',
            //     'ownerId', 'created'],
            where: {
                and: [
                    {
                        or: [
                            {
                                id: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                organizationId: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                name: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                type: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                status: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                name: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                type: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                status: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        }
    };
    return query;
};


const adminListsSlice = createSlice({
    name: 'adminListsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedLists = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedLists.splice(state.selectedLists.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedLists.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateAdminListState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getAdminLists.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminLists.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminLists.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.adminLists = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getAdminListsWithFilter.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        // [createAdminList.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [createAdminList.fulfilled]: (state, action) => {
        //     state.loading = false;
        //     state.successMessage = `Successfully onboarded a new adminList [${action.payload.name} - ${action.payload.id}]`
        //     state.reset = true;
        // },
        // [createAdminList.rejected]: (state, action) => {
        //     state.loading = false;
        //     if (action.error && action.error.message) {
        //         const errObj = JSON.parse(action.error.message);
        //         if (errObj) {
        //             state.alertMessage = `${errObj.error.message}. ${errObj.error.details}`;
        //         } else {
        //             state.alertMessage = action.error.message;
        //         }
        //     } else {
        //         console.error(`There was an unhandled rejection`, action);
        //     }
        // }
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria,
    resetCreateAdminListState
} = adminListsSlice.actions;
export default adminListsSlice.reducer;
