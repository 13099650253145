/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import {useDispatch, useSelector} from "react-redux";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Image, Button,
} from 'react-bootstrap';
import {
    map,
    findIndex,
    cloneDeep,
    uniq,
    uniqBy
} from 'lodash';

import ExploreMapFlyoutDetailLoadingSpinner from './ExploreMapFlyoutDetailLoadingSpinner';
import config from '../../../../config';
import * as CubeApi from '../../../../common/CubeApi';
import {parseCubeFilter} from '../../CubeFilterBuilder';
import {
    countCompanyAndContactByCriteria, replaceFilter as replaceCompanyFilter,
    searchCompanyByCriteria
} from "../../companySlice";
import {
    countCompanyAndContactByCriteria as countContactAndCompanyByCriteria, replaceFilter as replaceContactFilter,
    searchContactByCriteria
} from "../../contactSlice";
import {FilterOptions} from "../../FilterOptions";

import ExploreMapFlyoutDetailSelectedProduct from './ExploreMapFlyoutDetailSelectedProduct';
import mapMarkerIcon from "../../../../common/assets/img/ToolbeltIcons-15.png";
import ExploreMapFlyoutDetailLocation from "./ExploreMapFlyoutDetailLocation";


export default function ExploreMapFlyoutDetailSelectedProductClustered(props) {
    const dispatch = useDispatch();
    const exploreDataState = useSelector(state => state.explore);
    const [loading, setLoading] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const productDataType =  '';
        useEffect(() => {
        console.log('ExploreMapFlyoutDetailSelectedProductCluster  - Product Cluster');
        setLoading(true);
        _getRecord(props, productDataType)
            .then((response) => {
                setLoading(false);
                setSelectedRecord(response);
            });
    }, [props.selectedPoint, productDataType]);


    const handleAddToFilter = () => {
        const {
            currentFilters,
            exploreMode,
        } = props;

        const {
            selectedLocations,
        } = props.selectedPoint;
        const filters = cloneDeep(currentFilters);
        const limitedLocations = selectedLocations;
        const filterId = productDataType === 'RECORD' ? FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_ID.id
            : FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_ADDRESS_CITY_REGION_FORMATTED.id;

        const locationFilter = productDataType === 'RECORD' ?
            ({
                ...FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_IDENTITY,
                value: map(limitedLocations, (loc) => ({id: loc.id}))
            }) :
            (
                {
                    ...FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_ADDRESS_CITY_REGION_FORMATTED,
                    value: map(uniqBy(limitedLocations, 'company_address_city_region_formatted'), (loc) => ({id: loc.company_address_city_region_formatted}))
                }
            )
        ;
        const index = findIndex(currentFilters, (ft) => ft.id === filterId);
        if (index >= 0) {
            filters.splice(index, 1, locationFilter);
        } else {
            filters.push(locationFilter);
        }

        if (exploreMode === 'COMPANY') {

            dispatch(replaceCompanyFilter(filters));
            dispatch(searchCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(countCompanyAndContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            return;
        }
        dispatch(replaceContactFilter(filters));
        dispatch(searchContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
        dispatch(countContactAndCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
    };

    const isDisabledAddToFilter = () => {
        return false;
    };

    if (loading) return (<ExploreMapFlyoutDetailLoadingSpinner/>);

    if (selectedRecord) {
        const {
            selectedLocations,
            countCompanies,
            countContacts,
            tradeTypes,
            bestLocation,
        } = selectedRecord;

        const _companyLocations = map(selectedLocations, (location) => location.company_address_city_region_formatted);
        const companyLocations = uniq(_companyLocations);

        return (
            <Row>
                <Col>
                    <div className="explore-map-flyout-detail">
                        {tradeTypes.length > 0 ?
                            <div>
                                <div>
                                    <ExploreMapFlyoutDetailLocation
                                        bestLocation={bestLocation}
                                        companyLocations={companyLocations}
                                        countCompanies={countCompanies}
                                        countContacts={countContacts}
                                        isClustered
                                    />
                                </div>
                                <div>
                                    <ExploreMapFlyoutDetailSelectedProduct
                                        tradeTypes={tradeTypes}
                                        countCompanies={countCompanies}
                                        countContacts={countContacts}
                                        countPlaces={companyLocations.length}
                                        bestLocationName={bestLocation.company_address_city_region_formatted}
                                    />
                                </div>
                                <div className="action-container">
                                    <Button
                                        style={{
                                            margin: '2px'
                                        }}
                                        className="action-button btn-height-35 btn-min-width-120"
                                        variant="outline-secondary"
                                        size="sm"
                                        disabled={isDisabledAddToFilter()}
                                        onClick={() => handleAddToFilter()}
                                    >ADD To FILTER</Button>
                                </div>
                            </div>
                            :
                            <div className={'selected-map-point'}>
                                <Image
                                    className="icon"
                                    src={mapMarkerIcon}
                                    alt="ToolBeltData Company Icon"
                                    fluid
                                />
                                <div>Select a point on the map to get started!</div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
}

ExploreMapFlyoutDetailSelectedProductClustered.propTypes = {
    selectedPoint: PropTypes.object,
};

async function _getRecord(props, productDataType) {
    const {
        currentFilters,
    } = props;
    const {
        selectedLocations,
        countCompanies,
        countContacts,
        bestLocation,
    } = props.selectedPoint;

    if (!bestLocation) {
        return null;
    }
    // const tab = exploreMode === 'COMPANY' ? 'Company' : 'Contact';
    const tab = 'CompanyContractorUniverse';
    // TODO async call here for trade Types.
    // There are 2 cases here.
    // 1 Clustered from LOC_PLACE mode. We don' t know individual record.
    // 2 Clustered from RECORD mode. We know this individual record.

    // TODO will be talk in case of huge cluster selected.
    // TODO: Need work here to correct loc_city_desc_derived
    // const limitedLocations = slice(selectedLocations, 0, 500);
    const limitedLocations = [bestLocation];
    const _filters =  currentFilters && currentFilters.length > 0 ? parseCubeFilter(currentFilters) : [];
    if (productDataType === 'RECORD') {
        _filters.push({
            dimension: `${tab}.company_identity`,
            operator: 'equals',
            values: map(limitedLocations, loc => loc.id)
        });
    } else {
        _filters.push({
            dimension: `${tab}.company_address_city`,
            operator: 'equals',
            values: map(limitedLocations, loc => loc.company_address_city)
        });
        _filters.push({
            dimension: `${tab}.company_address_region`,
            operator: 'equals',
            values: map(limitedLocations, loc => loc.company_address_region)
        });
    }


    const countTradeTypesPayload = {
        measures: ['CompanyContractorUniverse.count_companies'],
        dimensions: [
            `CompanyContractorUniverse.company_class_primary_trade`,
        ],
        timeDimensions: [],
        filters: _filters,
    };
    try {
        const countResult = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
            countTradeTypesPayload
        );
        const tradeTypes = map(countResult, row => ({
            tradeType: row[`CompanyContractorUniverse.company_class_primary_trade`],
            count: parseInt(row[`CompanyContractorUniverse.count_companies`], 10)
        }));

        const result = {
            selectedLocations,
            countCompanies,
            countContacts,
            tradeTypes,
            bestLocation
        };
        return result;
    } catch {
        return (
            {
                selectedLocations,
                countCompanies,
                countContacts,
                bestLocation,
                tradeTypes: []
            }
        )
    }
}
