/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import BreadCrumb from '../../common/components/BreadCrumb';
import {Row, Col, Button, Form} from 'react-bootstrap';

import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import './AdminActionListContainer.scss';

import {
    getManagedListMatchesWithFilter, checkMatchStatus
} from "../list-matches/managedListMatchesSlice";


import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import PanelHeader from "../../common/components/layout/PanelHeader";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";

const AdminMatchCheckContainer = (props) => {

    const managedListMatchesState = useSelector(state => state.managedListMatches);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [managedList, setManagedList] = useState(null);
    const [managedListMatchesStatus, setManagedListMatchesStatus] = useState(null);

    useEffect(() => {
        setManagedListMatchesStatus(undefined);
        const query = {
            filter: {
                where: {
                    listId: props.match.params.id
                },
                // include: ['list'],
            },
        };
        dispatch(getManagedListMatchesWithFilter(query));
    }, [props.match.params.id]);

    useEffect(() => {
        if ( managedListMatchesState.managedListMatches && managedListMatchesState.managedListMatches[0] && managedListMatchesState.managedListMatches[0].listId === props.match.params.id) {
            setManagedList(managedListMatchesState.managedListMatches[0]);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Lists',
                        href: '/admin/lists',
                        isActive: false
                    },
                    {
                        label: `${props.match.params.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'Check List Match',
                        href: '',
                        isActive: true
                    }
                 ]
            )
        }
        }
        , [managedListMatchesState.managedListMatches]);

    useEffect(() => {
            if ( managedListMatchesState.managedListMatchesStatus) {
                setManagedListMatchesStatus(managedListMatchesState.managedListMatchesStatus);
            }
        }
        , [managedListMatchesState.managedListMatchesStatus]);



    useEffect(() => {
        setAlertMessage(managedListMatchesState.alertMessage);
        if (managedListMatchesState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedListMatchesState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(managedListMatchesState.successMessage);
        if (managedListMatchesState.successMessage) {
            setShowSuccess(true);
        }else {
            setShowSuccess(false);
        }
    }, [managedListMatchesState.successMessage]);

    const handleAdminMatchCheck = async (data) => {
        dispatch(checkMatchStatus(data.listMatchId));
    };

    const handleCancel = async () => {
        history.push('/admin/lists')
    };

    return (
        <div>
            {managedListMatchesState.loading && (
                <div className="spinner-overlay">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <AdminMatch
                id={managedList?.listId}
                listMatchId={managedList?.id}
                name={managedList?.list?.name}
                handleAdminMatchCheck={(data) => handleAdminMatchCheck(data)}
                handleCancel={() => handleCancel()}
                status={managedListMatchesStatus?.disposition}
                type={managedList?.list?.type}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function AdminMatch(props) {
    const {
        breadcrumbItems,
        id,
        listMatchId,
        name,
        status,
        type,
        handleAdminMatchCheck,
        handleCancel,
    } = props;

    const reset = () => {

    };

    const handleAdminMatchClick = async () => {
        handleAdminMatchCheck({listMatchId});
    };

    return (
        <div className="action-list-gutter">
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`Check List Match - ${name ? name : ''}`} subHeader={'Check the match job for the list'} />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="matchList">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={9} className="action-list-form-label">
                                            <Form.Label>List ID</Form.Label>
                                            {id &&
                                                <TextHoverDisplay
                                                    fullText={id}
                                                    truncatedText={id}
                                                    displayTruncated={false}
                                                />
                                            }

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9} className="create-input-form-label">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                as="input"
                                                size="sm"
                                                disabled
                                                value={name}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={9} className="create-input-form-label">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control
                                                as="input"
                                                size="sm"
                                                disabled
                                                value={type}
                                            />
                                        </Col>
                                    </Row>
                                    {/*{ status &&*/}
                                    {/*    <Row>*/}
                                    {/*        <Col md={9} className="create-input-form-label">*/}
                                    {/*            <Form.Label>Status</Form.Label>*/}
                                    {/*            <Form.Control*/}
                                    {/*                as="input"*/}
                                    {/*                size="sm"*/}
                                    {/*                disabled*/}
                                    {/*                value={status}*/}
                                    {/*            />*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>*/}
                                    {/*}*/}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleAdminMatchClick()}
                                    >CHECK
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </div>
    );
}

export default AdminMatchCheckContainer;

