/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import FormValidator from '../FormValidator';
import {requestResetPassword} from '../account/AccountService';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../assets/img/ToolBelt_login_logo.png'

export default class Recover extends Component {
    static propTypes = {
        location: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formRecover: {
                validated: false,
                email: '',
            },
            showAlert: false,
            alertVariant: null,
            alertMessage: null,
        };
    }

    /**
     * Validate input using onChange event
     * @param  {String} event
     * @return {Function} a function used for the event
     */

    validateOnChange = (event) => {

        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;
        const result = null;

        // TODO USE THIS WHEN YOU WANT VALUES TO BE VALIDATED AS THE USER TYPES
        // const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                }
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    validated: true,
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                requestResetPassword(this.state.formRecover.email)
                    .then((resp) => {
                        this.setState({
                            loading: false,
                            showAlert: true,
                            alertVariant: 'success',
                            alertMessage: `Thank You! We received your request. If we find a matching account we will send an email with further instructions on how to complete password recovery.`});
                    })
                    .catch(error => { // eslint-disable-line
                        this.setState({loading: false}, () => {                            
                            this.setState({
                                showAlert: true,
                                alertVariant: 'danger',
                                alertMessage: `Something went wrong, please check back in a bit or contact your account manager.`
                            });
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        return (
            <Container fluid style={{overflow: 'hidden'}}>
                <Row className="min-vh-100">
                    <Col className="bg-primary" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Image
                            src={logo}
                            width="350"
                            alt="ToolBeltData Logo"
                            fluid
                        />
                    </Col>
                    <Col style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Col xl={8} lg={8} md={12} sm={12}>
                            <Form noValidate validated={this.state.formRecover.validated} name="formRecover" onSubmit={this.onSubmit}>
                                <div className="text-center">
                                    <h2>Recover Your Password</h2>
                                </div>
                                <Form.Text className="text-center mt-2 mb-2">
                                    <Link to="login" className="text-muted">Please provide your email to receive instructions on how to recover your password.</Link>
                                </Form.Text>
                                <Form.Group controlId="formRecoverEmail">
                                    <Form.Label>Your email</Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="email"
                                        name="email"
                                        placeholder="rick.deckard@example.com"
                                        isInvalid={
                                            this.hasError('formRecover', 'email', 'required')
                                            || this.hasError('formRecover', 'email', 'email')
                                        }
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formRecover.email}
                                    />
                                    {this.hasError('formRecover', 'email', 'required') && (
                                        <Form.Control.Feedback type="invalid">
                                            Email is required.
                                        </Form.Control.Feedback>
                                    )}
                                    {!this.hasError('formRecover', 'email', 'required')
                                    && this.hasError('formRecover', 'email', 'email')
                                    && (
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <Alert
                                    variant={this.state.alertVariant}
                                    show={this.state.showAlert}
                                    onClose={() => this.setState({showAlert: false})}
                                    dismissible
                                    transition={false}
                                >
                                    {this.state.alertMessage}
                                </Alert>
                                <Button
                                    variant="info"
                                    type="submit"
                                    size="md"
                                    block
                                    className="pt-3 pb-3"
                                    disabled={this.state.loading}
                                >
                                    {!this.state.loading && <>RECOVER</>}
                                    {this.state.loading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Button>
                                <Form.Text className="text-center mt-2">
                                    <Link to="login" className="text-muted">Back to Login</Link>
                                </Form.Text>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
        )
    }
}

