

import React from 'react';
import {
    Form,
    FormGroup,
    FormLabel,
    Row,
    Col,
} from 'react-bootstrap';

import FormTextInput from "../../../common/components/FormTextInput";
import {useDispatch, useSelector} from "react-redux";
import {updateListName, updateListDescription} from "./uploadSlice";


function NameListSelection(props) {
    const dispatch = useDispatch();
    const uploadMetaState = useSelector(state => state.upload);
    const NAME_VALIDATE_REX = /^[A-Za-z0-9-\\ \\_\\.\\-\\(\\)]+$/;

    const validateNameList = () => {
        if (uploadMetaState.listName && uploadMetaState.listName.length > 0) {
            return NAME_VALIDATE_REX.test(uploadMetaState.listName);
        }

        return true;
    }

    return (
        <Form className="nameListSelectionContent">
            <FormGroup>
                <Row>
                    <Col md={4} className="col-form-label">
                        <FormLabel>
                            Name Your List:
                        </FormLabel>
                    </Col>
                    <Col md={8}>
                        <FormTextInput
                            onChange={(value) => {dispatch(updateListName(value));}}
                            value={uploadMetaState.listName}
                            isInvalid={!validateNameList()}
                            validation={{
                                state: !validateNameList() === false ? 'error' : undefined,
                                message: 'Invalid'
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="col-form-label">
                        <FormLabel>
                            Description:
                        </FormLabel>
                    </Col>
                    <Col md={8}>
                        <Form.Control as="textarea" rows={7}
                            onChange={(e) => {dispatch(updateListDescription(e.target.value));}}
                            value={uploadMetaState.listDescription}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    );
}


export default NameListSelection;
