import { configureStore } from '@reduxjs/toolkit'

// Common
import accountSlice from "./common/account/accountSlice";
import mataDataSlice from './common/metadata/metaDataSlice';
// Organization
import exploreSlice from "./organization/explore/exploreSlice";
import companySlice from "./organization/explore/companySlice";
import contactSlice from "./organization/explore/contactSlice";
import uploadSlice from "./organization/explore/upload/uploadSlice";
import searchDataSlice from "./organization/explore/searchDataSlice";
import listSlice from "./organization/lists/listSlice";
import teamSlice from "./organization/manage/team/teamSlice";
// Admin
import adminHomeSlice from "./admin/home/adminHomeSlice";
import sourcesSlice from "./admin/sources/sourcesSlice";
import sourceSlice from "./admin/sources/sourceSlice";
import inputsSlice from "./admin/inputs/inputsSlice";
import createInputSlice from "./admin/inputs/createInputSlice";
import organizationsSlice from './admin/organizations/organizationsSlice';
import managedAccountsSlice from './admin/accounts/managedAccountsSlice';
import managedAccount from './admin/accounts/managedAccountSlice';
import managedOrganizationSlice from './admin/organizations/managedOrganizationSlice';
import adminListsSlice from "./admin/lists/adminListsSlice";
import adminListSlice from "./admin/lists/adminListSlice";
import adminListImportSlice from "./admin/lists/adminListImportSlice";
import managedListErrorsSlice from "./admin/list-errors/managedListErrorsSlice";
import managedListMatches from "./admin/list-matches/managedListMatchesSlice";
import managedListMatchLogs from "./admin/list-match-logs/managedListMatchLogsSlice";
import managedMatchTemplatesSlice from "./admin/match-templates/managedMatchTemplatesSlice";
import managedMatchTemplateSlice from "./admin/match-templates/managedMatchTemplateSlice";
import managedMatchTypesSlice from "./admin/match-types/managedMatchTypesSlice";
import entityVerificationsSlice from "./admin/entity-verifications/entityVerificationsSlice";
import contractorDirectorySlice from "./admin/contractor-directory/contractorDirectorySlice";
import managedIntegrationsSlice from "./admin/integrations/managedIntegrationsSlice";
import managedIntegrationSlice from "./admin/integrations/managedIntegrationSlice";
import managedCompaniesSlice from './admin/companies/managedCompaniesSlice';
import managedContactsSlice from './admin/contacts/managedContactsSlice';
import managedCompanySlice from './admin/companies/managedCompanySlice';
import companyNameSlice from './admin/companies/components/collapse-sections/core-attributes/slices/companyNameSlice';
import companyURLSlice from './admin/companies/components/collapse-sections/core-attributes/slices/companyURLSlice';
import contactNameSlice from './admin/companies/components/collapse-sections/core-attributes/slices/contactNameSlice';
import companyAddressSlice from './admin/companies/components/collapse-sections/core-attributes/slices/companyAddressSlice';
import companyPhoneSlice from './admin/companies/components/collapse-sections/core-attributes/slices/companyPhoneSlice';
import companyEmailSlice from './admin/companies/components/collapse-sections/core-attributes/slices/companyEmailSlice';
import contactTitleSlice from './admin/companies/components/collapse-sections/core-attributes/slices/contactTitleSlice';
import contactPhoneSlice from './admin/companies/components/collapse-sections/core-attributes/slices/contactPhoneSlice';
import contactEmailSlice from './admin/companies/components/collapse-sections/core-attributes/slices/contactEmailSlice';
import adminListMatchReportSlice from "./admin/lists/adminListMatchReportSlice";
import suppliersSlice from "./admin/suppliers/supplierSlice";
import inputSlice from './admin/inputs/inputSlice';

export default configureStore({
  reducer: {
    explore: exploreSlice,
    company: companySlice,
    contact: contactSlice,
    upload: uploadSlice,
    metaData: mataDataSlice,
    searchData: searchDataSlice,
    list: listSlice,
    team: teamSlice,
    account: accountSlice,
    sources: sourcesSlice,
    source: sourceSlice,
    inputs: inputsSlice,
    input: inputSlice,
    createInput: createInputSlice,
    organizations: organizationsSlice,
    managedAccounts: managedAccountsSlice,
    managedAccount: managedAccount,
    adminLists: adminListsSlice,
    adminList: adminListSlice,
    adminListMatchReport: adminListMatchReportSlice,
    adminListImport: adminListImportSlice,
    managedListErrors: managedListErrorsSlice,
    managedListMatches: managedListMatches,
    managedListMatchLogs: managedListMatchLogs,
    managedOrganization: managedOrganizationSlice,
    managedMatchTemplates: managedMatchTemplatesSlice,
    managedMatchTemplate: managedMatchTemplateSlice,
    managedMatchTypes: managedMatchTypesSlice,
    entityVerifications: entityVerificationsSlice,
    contractorDirectory: contractorDirectorySlice,
    adminHome: adminHomeSlice,
    managedIntegrations: managedIntegrationsSlice,
    managedIntegration: managedIntegrationSlice,
    managedCompanies: managedCompaniesSlice,
    managedCompany: managedCompanySlice,
    companyName: companyNameSlice,
    companyURL: companyURLSlice,
    companyAddress: companyAddressSlice,
    companyPhone: companyPhoneSlice,
    companyEmail: companyEmailSlice,
    contactName: contactNameSlice,
    contactTitle: contactTitleSlice,
    contactPhone: contactPhoneSlice,
    contactEmail: contactEmailSlice,
    managedContacts: managedContactsSlice,
    suppliers: suppliersSlice
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
      }),
})
