/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Redirect,
    Route, Switch,
} from 'react-router-dom';
import InputsContainer from './InputsContainer';
import CreateInputContainer from './CreateInputContainer';
import EditInPutContainer from './EditInputContainer'

export default class InputsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/inputs/create-input" exact component={CreateInputContainer}/>
                <Route path="/admin/inputs/:id/edit-input" exact component={EditInPutContainer}/>
                <Route path="/admin/inputs" component={InputsContainer}/>
            </Switch>
        );
    }
}
