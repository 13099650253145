/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    sortBy,
    filter
} from 'lodash';

import TeamTable from "./TeamTable";
import FormTextInput from "../../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getTeam
} from "./teamSlice";

import BreadCrumb from "../../../common/components/BreadCrumb";
import DropdownAction from "../../../common/components/DropdownAction";
import PanelHeader from "../../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../../common/components/SpinnerOverlay";

import PanelTable from "../../../common/components/layout/PanelTable";
import Container from "../../../common/components/layout/Container";

import AddTeamMemberDialog from "./AddTeamMemberDialog";
import ActivateTeamMemberDialog from "./ActivateTeamMemberDialog";
import DisableTeamMemberDialog from "./DisableTeamMemberDialog";


const Team = (props) => {
    return (
        <TeamComponent
        />
    );
};

const TeamComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/org/home', isActive: false}, {
        label: 'Manage Team',
        href: '',
        isActive: true
    }];
    const teamState = useSelector(state => state.team);
    const dispatch = useDispatch();
    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAddTeamMemberDialog, setShowAddTeamMemberDialog] = useState(false);
    const [showActivateTeamMemberDialog, setShowActivateTeamMemberDialog] = useState(false);
    const [showDisableTeamMemberDialog, setShowDisableTeamMemberDialog] = useState(false);


    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (teamState.alertMessage) {
            setAlertMessage(teamState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [teamState.alertMessage]);

    const isReload = teamState.reload === true;

    useEffect(() => {
        dispatch(getTeam());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const handleAddTeamMember = () => {
        setShowAddTeamMemberDialog(true);
    };

    const shouldDisplayActivate = (member) => {
        if (!member) return false;
        const selectedTeamMember = filter(teamState.team, {id: member.id})[0];
        if (!selectedTeamMember) return false;
        return selectedTeamMember.statusId === 'DISABLED';
    }

    const shouldDisplayDisable = (member) => {
        if (!member) return false;
        const selectedTeamMember = filter(teamState.team, {id: member.id})[0];
        if (!selectedTeamMember) return false;
        return selectedTeamMember.statusId === 'ACTIVE' || selectedTeamMember.statusId === 'CREATED';
    }

    const getActionItems = () => {
        const actionItems = [];
        actionItems.push(
            {
                label: 'ACTIVATE',
                href: '',
                onClickHandler: () => setShowActivateTeamMemberDialog(true),
                isActive: teamState.selectedTeam && teamState.selectedTeam.length === 1 && shouldDisplayActivate(teamState.selectedTeam[0])
            },
            {
                label: 'DISABLE',
                disabled: true,
                href: '',
                onClickHandler: () => setShowDisableTeamMemberDialog(true),
                isActive: teamState.selectedTeam && teamState.selectedTeam.length === 1 && shouldDisplayDisable(teamState.selectedTeam[0])
            }
        );
        return sortBy(actionItems, 'label');
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Team (${teamState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => handleAddTeamMember()}
                        >
                            ADD
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search is disabled'}
                        value={teamState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        disabled
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {teamState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                {showAddTeamMemberDialog && (
                    <AddTeamMemberDialog
                        handleClose={() => setShowAddTeamMemberDialog(false)}
                    />
                )}
                {showActivateTeamMemberDialog && (
                    <ActivateTeamMemberDialog
                        teamMember={filter(teamState.team, {id: teamState.selectedTeam[0].id})[0]}
                        handleClose={() => setShowActivateTeamMemberDialog(false)}
                    />
                )}
                {showDisableTeamMemberDialog && (
                    <DisableTeamMemberDialog
                        teamMember={filter(teamState.team, {id: teamState.selectedTeam[0].id})[0]}
                        handleClose={() => setShowDisableTeamMemberDialog(false)}
                    />
                )}
                <TeamTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default Team;
