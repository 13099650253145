/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";

import styled from "styled-components";
import {get, isEmpty} from "lodash";

import {useDispatch} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";
import config from "../../config";

import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";

import * as Api from "../../common/Api";
import * as cache from "../../common/Cache";

import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import FormRadioGroup from "../../common/components/form/FormRadioGroup";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import {getById, update} from "../../common/services/AdminListService";
import {FormColorPalette} from "../../common/components/form/FormColorPalette";
import {getTypeById, LIST_TYPES} from "../../common/metadata/ListTypes";
import SpriteIconPicker from "../../common/components/SvgSpritesIconPicker";
import UploadIcon from "../../common/components/UploadIcon";

import {icons} from "../../common/assets/img/fa-icons/FaIcons";

import spritesFile from "../../common/assets/img/fa-icons/original-solid.svg";

const OverlayOptionsContainer = styled.div `
  border: 1px dashed #CCC;
  padding: 20px;
`;

export default function AdminEditListContainer(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Lists',
        href: '/admin/lists',
        isActive: false
    }, {label: 'Edit List', href: '', isActive: true}];

    const history = useHistory();

    const handleOnCancel = async () => {
        history.push('/admin/lists')
    };

    return (
        <AdminEditList
            breadcrumbItems={breadcrumbItems}
            handleOnCancel={handleOnCancel}
            id={props.match.params.id}
        />
    )
}

function AdminEditList(props) {
    const {
        breadcrumbItems,
        handleOnCancel,
        id,
    } = props;

    const dispatch = useDispatch();

    const [message, setMessage] = useState(null);
    const [formValid, setFormValid] = useState(true);
    const [selectedList, setSelectedList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [configColor, setConfigColor] = useState('#0171fe');
    const [configTcuMatchedColor, setConfigTcuMatchedColor] = useState('#0171fe');
    const [configSprite, setConfigSprite] = useState(null);
    const [listType, setListType] = useState('');
    const [imgSecureUrl, setImgSecureUrl] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [imageMode, setImageMode] = useState('upload');

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const list = await getById(id);
            setSelectedList(list);
            setConfigColor(get(list, 'config.map.overlay.color', '#0171fe'));
            setConfigTcuMatchedColor(get(list, 'config.map.overlay.tcuMatchedColor', '#0171fe'));
            setConfigSprite(get(list, 'config.map.overlay.img', 'map-marker-alt'));
            const _imgUrl = get(list, 'config.map.overlay.imgUrl', null);
            setImgUrl(_imgUrl);
            setImgSecureUrl(get(list, 'config.map.overlay.imgSecureUrl', null));
            if (_imgUrl) {
                setImageMode('upload');
            } else {
                setImageMode('sprite');
            }
            const typeObj = getTypeById(list.type);
            setListType(typeObj ? typeObj.display : list.type);
            setLoading(false);
        };
        init();
    }, [id]);

    const updateList = async () => {
        if (!isValid) {
            setFormValid(true);
        } else {
            setLoading(true);
            try {
                const data = {
                    name: selectedList.name,
                    description: selectedList.description,
                    config: {
                        map: {
                            overlay: {
                                color: configColor,
                                tcuMatchedColor: configTcuMatchedColor,
                                img: selectedList && selectedList.type === LIST_TYPES.IMPORT_OVERLAY.id && imageMode === 'sprite' ?  configSprite : undefined,
                                imgUrl: imageMode === 'upload' && imgUrl ? imgUrl : undefined,
                                imgSecureUrl: imageMode === 'upload' && imgSecureUrl ? imgSecureUrl : undefined
                            }
                        }
                    }
                };
                await update(id, data);
                setMessage({
                    variant: 'success',
                    message: 'Successfully update list'
                });
                setLoading(false);
            } catch(error) {
                setMessage({
                    variant: 'danger',
                    message: 'Unable to update list'
                });
                setLoading(false);
            }
        }
    };

    const isValid = () => {
        if (!selectedList || !selectedList.name) {
            return false;
        }
        return true;
    };

    const listImageMode = [
        {value: "sprite", label: <>Sprite</>},
        {value: "upload", label: <>Upload</>}
    ];

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`Edit List`} subHeader={`Edit list type (${listType})`} />
            <Panel>
                {loading && (
                    <SpinnerOverlay />
                )}
                {message && (
                    <Alert
                        variant={message.variant}
                        show={!isEmpty(message.message)}
                        onClose={() => setMessage(null)}
                        dismissible
                        transition={false}
                    >
                        {message.message}
                    </Alert>
                )}
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="adminImportList">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={9}>
                                            <FormTextInput
                                                label={'Name'}
                                                value={selectedList?.name}
                                                onChange={value => setSelectedList(
                                                    {
                                                        ...selectedList,
                                                        name: value
                                                    }
                                                )}
                                                isInvalid={!formValid && !selectedList?.name}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <FormTextArea
                                                label={'Description'}
                                                onChange={value => setSelectedList(
                                                    {
                                                        ...selectedList,
                                                        description: value
                                                    }
                                                )}
                                                value={selectedList?.description}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                    (
                                        selectedList?.type === 'IMPORT_COMPANY' ||
                                        selectedList?.type === 'IMPORT_CONTACT' ||
                                        selectedList?.type === 'MATCH_CONTACT' ||
                                        selectedList?.type === 'MATCH_COMPANY' ||
                                        selectedList?.type === 'EXPORT_COMPANY' ||
                                        selectedList?.type === 'EXPORT_CONTACT') && (
                                        <Row>
                                            <Col md={9}>
                                                <FormLabel>Overlay Options</FormLabel>
                                                <OverlayOptionsContainer>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormColorPalette
                                                                    label={'Color'}
                                                                    value={configColor}
                                                                    handleChange={v =>
                                                                        setConfigColor(v)
                                                                    }
                                                                    editing
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <FormColorPalette
                                                                    label={'Matched Color'}
                                                                    value={configTcuMatchedColor}
                                                                    handleChange={v =>
                                                                        setConfigTcuMatchedColor(v)
                                                                    }
                                                                    editing
                                                                />
                                                            </Col>
                                                        </Row>
                                                </OverlayOptionsContainer>
                                            </Col>
                                        </Row>
                                    )
                                    }
                                    {selectedList?.type === 'IMPORT_OVERLAY' && (
                                        <Row>
                                            <Col md={9}>
                                                <FormLabel>Overlay Options</FormLabel>
                                                <OverlayOptionsContainer>
                                                    {imageMode !== 'upload' && (
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormColorPalette
                                                                    label={'Color'}
                                                                    value={configColor}
                                                                    handleChange={v =>
                                                                        setConfigColor(v)
                                                                    }
                                                                    editing
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormRadioGroup
                                                                id={'ListImageModeSelection'}
                                                                name={'ListImageModeSelection'}
                                                                label={'Select Mode'}
                                                                value={imageMode}
                                                                items={listImageMode}
                                                                onChange={selectedValue => setImageMode(selectedValue)}
                                                                radioButton
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormLabel>Image</FormLabel>
                                                            {imageMode === 'sprite' && (
                                                                <SpriteIconPicker
                                                                    url={spritesFile}
                                                                    value={configSprite}
                                                                    options={icons}
                                                                    onSelectIcon={(name) => setConfigSprite(name)}
                                                                    color={configColor}
                                                                />
                                                            )}
                                                            {imageMode === 'upload' && (
                                                                <UploadIcon
                                                                    imgSecureUrl={imgSecureUrl}
                                                                    onUpload={async (photo) => {
                                                                        try {
                                                                            setLoading(true);
                                                                            const result = await Api.upload(true, `${config.services.ATLAS.apiUrl}/managed-lists/${id}/upload-img`, photo);

                                                                            const originalSecureUrl = get(result, 'secure_url', null);
                                                                            const originalUrl = get(result, 'url', null);
                                                                            const idxSecure = originalSecureUrl.indexOf('/image/upload');
                                                                            const resizeSecureUrl = `${originalSecureUrl.substring(0, idxSecure)}/image/upload/c_scale,w_24${originalSecureUrl.substring(idxSecure + 13)}`;

                                                                            const idx = originalUrl.indexOf('/image/upload');
                                                                            const resizeUrl = `${originalUrl.substring(0, idx)}/image/upload/c_scale,w_24${originalUrl.substring(idx + 13)}`;

                                                                            setImgSecureUrl(resizeSecureUrl);
                                                                            setImgUrl(resizeUrl);
                                                                            setLoading(false);
                                                                        } catch (err) {
                                                                            setLoading(false);
                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {imageMode === 'upload'
                                                    && imgSecureUrl && (
                                                        <Row>
                                                            <Col md={3}>
                                                                <FormLabel>Preview</FormLabel>
                                                            </Col>
                                                            <Col md={9}>
                                                                <img
                                                                    src={imgSecureUrl}
                                                                    alt={'upload'}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </OverlayOptionsContainer>
                                            </Col>
                                        </Row>
                                    )
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => updateList()}
                                        disabled={loading || !isValid()}
                                    >SAVE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => handleOnCancel()}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    )
}
