/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import {Doughnut} from "react-chartjs-2";
import styled from "styled-components";

const ChartLayoutContainer = styled.div `
  margin-bottom: 10px;
`;

const FlyoutChartContainer = styled.div `
  width: 320px;
  height: auto;
`;

const SelectedChartContainer = styled.div `
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  height: 100px;
`;

const LegendContainer = styled.div `
  padding: 10px 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 5px;
`;

const FlyoutChart = (props) => {
    const {
        chartData,
        legend = false,
    } = props;

    return (
        <ChartLayoutContainer>
            <FlyoutChartContainer>
                <SelectedChartContainer>
                    <Doughnut
                        data={chartData}
                        options={{
                            legend: {display: false},
                            maintainAspectRatio: false
                        }}
                    />
                </SelectedChartContainer>
                { legend && (
                    <LegendContainer>
                        {chartData.datasets[0].data.map((data, i) => (
                            <>
                                {/*{(i < 4) && (*/}
                                    <Legend
                                        borderColor={chartData.datasets[0].borderColor[i]}
                                        backgroundColor={chartData.datasets[0].backgroundColor[i]}
                                        data={data}
                                        label={chartData.labels[i]}
                                    />
                                {/*)}*/}
                            </>
                        ))}
                    </LegendContainer>
                )}
            </FlyoutChartContainer>
        </ChartLayoutContainer>
    );
}

const LegendComponent = styled.div `
  display: grid;
  grid-template-columns: 25px 1fr;

  align-items: center;
`;

const Legend = (props) => {
    const {
        backgroundColor,
        borderColor,
        label
    } = props;

    return (
        <LegendComponent>
            <div style={{
                border: `2px solid ${borderColor}`,
                height: 15,
                width: 15,
                backgroundColor: backgroundColor
            }}/>
            <div style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 11
            }}>{label}</div>
        </LegendComponent>
    );
}

export default FlyoutChart;
