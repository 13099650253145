import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    successMessage: undefined,
    alertMessage: undefined,
    sources: []
};

export const getSources = createAsyncThunk('sources/get-all', async (arg, {getState, requestId}) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-sources`;
    const sources = await Api.get(true, GET_SOURCE_URL, buildQuery(getState().sources.currentPage, getState().sources.pageSize,
        getState().sources.order.by, getState().sources.order.direction, getState().sources.searchCriteria));
    return sources;
});

export const getSourcesWithFilter = createAsyncThunk('sources/get-all-with-filter', async (filter) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-sources`;
    const sources = await Api.get(true, GET_SOURCE_URL, filter);
    return sources;
});

export const getSourcesCount = createAsyncThunk('sources/getSourceCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const SOURCE_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-sources/count`;
        const sourcesCount = await Api.get(true, SOURCE_COUNT_URL,  buildQuery(getState().sources.currentPage, getState().sources.pageSize,
        getState().sources.order.by, getState().sources.order.direction, getState().sources.searchCriteria));
        return sourcesCount?.count;
    } catch (err) {
        console.error(err);
    }
});

export const createSource = createAsyncThunk('sources/create-source',
    async (source) => {
        const CREATE_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-sources`;
        return await Api.post(true,
            CREATE_SOURCE_URL,
            {
                options: {
                    test: false
                },
                data: source
            }
        );
    });

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isGuid = regexExp.test(searchCriteria);
    const query = isGuid ? {
            filter: {
                fields: ['id', 'type', 'name', 'label', 'description',
                    'url', 'region', 'county', 'city', 'postalCode',
                    'qualityScore', 'authoritative', 'public', 'products', 'indicators',
                    'created', 'isTest', 'archived'],
                where: {
                    id: {
                        eq: `${searchCriteria ? searchCriteria : ''}`
                    }
                },
                order: [`${orderBy} ${orderDirection}`],
                limit: pageSize,
                skip: (currentPage - 1) * pageSize
            },
        } : {
        filter: {
            fields: ['id', 'type', 'name', 'label', 'description',
                'url', 'region', 'county', 'city', 'postalCode',
                'qualityScore', 'authoritative', 'public', 'products', 'indicators',
                'created', 'isTest', 'archived'],
            where: {
                and: [
                    {
                        or: [
                            {
                                name: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                             },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                label: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
}


const sourcesSlice = createSlice({
    name: 'sourcesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        }
    },
    extraReducers: {
        [getSources.pending]: (state, action) => {
            state.loading = true;
        },
        [getSources.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.sources = action.payload;
            }
        },
        [getSources.rejected]: (state, action) => {
            state.loading = false;
        },
        [getSourcesCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getSourcesCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getSourcesCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getSourcesWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getSourcesWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.sources = action.payload;
            }
        },
        [getSourcesWithFilter.rejected]: (state, action) => {
            state.loading = false;
        },
        [createSource.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [createSource.fulfilled]: (state, action) => {
            state.loading = false;
            state.reload = true;
            state.lastReload = new Date();
            state.successMessage = `Successfully create a new source [${action.payload.name} - ${action.payload.id}]`;
        },
        [createSource.rejected]: (state, action) => {
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
    }
})

export const {setCurrentPage, setOrder, setSelectedId, clearSelected, setSearchCriteria} = sourcesSlice.actions;
export default sourcesSlice.reducer;
