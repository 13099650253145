import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import * as Api from '../../common/Api';
import {parseCubeFilter, parseDimension} from "./CubeFilterBuilder";
import config from "../../config";

const initialState = {
    status: {
        status: 'idle',
        error: undefined,
    },
    searchCriteria: [],
};

export const saveSearchCriteria = createAsyncThunk('toolbeltdata/saved-search-criteria',
    async ({name, type, description, filters}) => {
    if (!type || !name) {
        throw new Error('required field.');
    }
        const _filters =  filters && filters.length > 0 ? parseCubeFilter(filters) : [];
        const _dimensions = parseDimension(type);
        const requestUrl = `${config.services.ATLAS.apiUrl}/saved-searches`;
        const data = {
            data: {
                name: name,
                description: description || '',
                type: type,
                query: {
                    type: type,
                    requestUrl: requestUrl,
                    requestQuery: {
                        dimensions: _dimensions,
                        filters: _filters,
                        ungrouped: true,
                    },
                    filters: {
                        value: filters,
                        version: 0.1,
                    },
                }
            }
        };
        const resultSet = await Api.post(true,
            requestUrl,
            data
        );
        return resultSet;

});

export const findSearchCriteria = createAsyncThunk('toolbeltdata/get-search-criteria',
    async ({where, offset, limit}) => {
        const filter = {
            filter: {
                where: where,
                order: ['updated DESC'],
                limit,
                skip: offset
            }
        };
        const requestUrl = `${config.services.ATLAS.apiUrl}/saved-searches`;
        const resultSet = await Api.get(true,
            requestUrl,
            filter
        );
        return resultSet;

    });


const searchDataSlice = createSlice({
    name: 'searchData', initialState,
    reducers: {
        reset: {
            reducer(state, action) {
                state.status.status = 'idle';
                state.status.error = '';
            }
        }
    },
    extraReducers: {
        [saveSearchCriteria.pending]: (state, action) => {
            state.status.status = 'loading';
        },
        [saveSearchCriteria.fulfilled]: (state, action) => {
            state.status.status = 'succeeded';
        },
        [saveSearchCriteria.rejected]: (state, action) => {
            state.status.status = 'failed';
            state.status.error = action?.error?.message
        },

        [findSearchCriteria.pending]: (state, action) => {
            state.status.status = 'loading';
        },
        [findSearchCriteria.fulfilled]: (state, action) => {
            state.searchCriteria = action.payload;
            state.status.status = 'succeeded';
        },
        [findSearchCriteria.rejected]: (state, action) => {
            state.status.status = 'failed';
            state.status.error = action?.error?.message
        },

    }
});


export const {reset, } = searchDataSlice.actions;
export default searchDataSlice.reducer;
