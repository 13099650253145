/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */


import config from '../config';
import {encode, decode} from './Utils';

// TODO: for now lets assume its just objects were going to be caching ...
export function set(key, obj) {
    if (config.env === 'development') {
        localStorage.setItem(`${config.storageNamespace}.${key}`, JSON.stringify(obj));
    } else {
        localStorage.setItem(`${config.storageNamespace}.${key}`, encode(JSON.stringify(obj)));
    }
}

// TODO: for now lets assume its just objects were going to be caching ...
export function get(key) {
    if (config.env === 'development') return JSON.parse(localStorage.getItem(`${config.storageNamespace}.${key}`));
    const item = localStorage.getItem(`${config.storageNamespace}.${key}`);
    if (item) return JSON.parse(decode(item));
    return null;
}

export function del(key) {
    localStorage.removeItem(`${config.storageNamespace}.${key}`);
}
