import _ from "lodash";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as Api from "../../../common/Api";
import config from "../../../config";
import {readString} from "react-papaparse";

const initialState = {
    listType: 'IMPORT_COMPANY',
    importRestricted: false,
    inputSource: 'FILE_UPLOAD', // [TEXT_INPUT, FILE_UPLOAD]
    fileUrl: '',
    fileSize: 0,
    fileType: 'csv',
    textInput: '',
    rawTextInput: '',
    listName: '',
    listDescription: '',
    fieldMappings: [],
    fileHeaders: [],
    mappingAttributes: [],
    totalRecords: 0,
    loading: false,
    importing: undefined,
    importStatus: undefined,
    notifyByEmailOnSuccessOrFailure: undefined
}

export const getFieldMappings = createAsyncThunk('lists/mapping-types', async (arg, {getState, requestId}) => {
    const MAPPING_ATTR_URL = `${config.services.ATLAS.apiUrl}/lists/mapping-types`;
    const attr = await Api.get(true, MAPPING_ATTR_URL);
    if (attr) {
        if (_.isArray(attr)) {
            const mapping = _.find(attr, function (o) {
                return o.listType === getState().upload.listType;
            });
            if (mapping && mapping.attributes)
                return _.sortBy(mapping.attributes, ['ordinalPosition']);
        }
    }
    return;
});

const uploadSlice = createSlice({
    name: 'uploadSlice', initialState,
    reducers: {
        resetUploadState: {
            reducer(state, action) {
                state.listType = action.payload.listTypeId;
                state.importRestricted = action.payload.importRestricted;
                state.inputSource = 'FILE_UPLOAD'; // [TEXT_INPUT, FILE_UPLOAD]
                state.fileUrl = '';
                state.fileSize = 0;
                state.fileType = 'csv';
                state.textInput = '';
                state.listName = '';
                state.listDescription = '';
                state.fieldMappings = [];
                state.fileHeaders = [];
                state.mappingAttributes = [];
                state.totalRecords = 0;
                state.loading = false;
                state.importing = undefined;
                state.importStatus = undefined;
                state.rawTextInput = undefined;
                state.notifyByEmailOnSuccessOrFailure = undefined;
            }
        },
        updateListType: {
            reducer(state, action) {
                state.listType = action.payload;
            }
        },
        updateImportRestricted: {
            reducer(state, action) {
                state.importRestricted = action.payload;
            }
        },
        updateInputSource: {
            reducer(state, action) {
                state.inputSource = action.payload;
                if (state.inputSource === 'FILE_UPLOAD') {
                    state.rawTextInput = '';
                    state.textInput = '';
                } else {
                    state.fileUrl = '';
                }
            }
        },
        updateListName: {
            reducer(state, action) {
                state.listName = action.payload;
            }
        },
        updateListDescription: {
            reducer(state, action) {
                state.listDescription = action.payload;
            }
        },
        updateFileUrl: {
            reducer(state, action) {
                state.fileUrl = action.payload;
            }
        },
        updateFileSize: {
            reducer(state, action) {
                state.fileSize = action.payload;
            }
        },
        updateFileType: {
            reducer(state, action) {
                state.fileType = action.payload;
            }
        },
        updateImporting: {
            reducer(state, action) {
                state.importing = action.payload;
            }
        },
        updateImportStatus: {
            reducer(state, action) {
                state.importStatus = action.payload;
            }
        },
        updateNotifyByEmailOnSuccessOrFailure: {
            reducer(state, action) {
                state.notifyByEmailOnSuccessOrFailure = action.payload;
            }
        },
        updateTextInput: {
            reducer(state, action) {
                state.rawTextInput = action.payload;

                const result = readString(_.trim(action.payload));
                const data = result && result.data;
                const headers = result && result.data && result.data[0];
                if (data && headers) {
                    const dataWithNoHeader = _.tail(data);
                    if (dataWithNoHeader && dataWithNoHeader.length > 0) {
                        const finalText = dataWithNoHeader.map((each) => {
                            return each.reduce(function (accum, currentValue, i) {
                                accum[headers[i]] = currentValue;
                                return accum;
                            }, {});
                        });
                        state.textInput = finalText;
                        state.fileHeaders = headers;
                        state.totalRecords = dataWithNoHeader.length;
                    }
                }
            }
        },
        updateFileHeaders: {
            reducer(state, action) {
                if (action && action.payload)
                    state.fileHeaders = action.payload.data;
            }
        },
        resetFileHeaders: {
            reducer(state, action) {
                state.fileHeaders = [];
            }
        },
        updateMappingAttributes: {
            reducer(state, action) {
                const attr = action.payload;
                _.remove(state.mappingAttributes, (o) => {
                    return o.key === attr.key
                });
                if (attr.header) {
                    state.mappingAttributes.push(attr);
                }
            }
        },
        updateTotalRecords: {
            reducer(state, action) {
                if (action && action.payload)
                    state.totalRecords = action.payload.data;
            }
        },
    },
    extraReducers: {
        [getFieldMappings().pending]: (state, action) => {
            state.loading = true;
        },
        [getFieldMappings.fulfilled]: (state, action) => {
            state.loading = false;
            state.fieldMappings = action.payload;
        },
        [getFieldMappings.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})

export const {
    updateListType,
    updateFileHeaders,
    resetFileHeaders,
    updateFileUrl,
    updateFileSize,
    updateFileType,
    updateListDescription,
    updateListName,
    updateInputSource,
    updateTextInput,
    updateNotifyByEmailOnSuccessOrFailure,
    updateMappingAttributes,
    updateTotalRecords,
    updateImporting,
    updateImportStatus,
    resetUploadState
} = uploadSlice.actions;
export default uploadSlice.reducer;
