
import _ from 'lodash';
export const INDICATOR_TYPES = {
    company_ind_residential: {
        id: 'company_ind_residential',
        value: true,
        display: 'Residential',
        description: 'Indicates if the company does residential work'
    },
    company_ind_commercial: {
        id: 'company_ind_commercial',
        value: true,
        display: 'Commercial',
        description: 'Indicates if the company does commercial work'
    },
    company_ind_industrial: {
        id: 'company_ind_industrial',
        value: true,
        display: 'Industrial',
        description: 'Indicates if the company does industrial work'
    },
    company_ind_aerospace: {
        id: 'company_ind_aerospace',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the aerospace Industry'
    },
    company_ind_agriculture: {
        id: 'company_ind_agriculture',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the agriculture Industry'
    },
    company_ind_education: {
        id: 'company_ind_education',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the education Industry'
    },
    company_ind_energy: {
        id: 'company_ind_energy',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the energy Industry'
    },
    company_ind_government: {
        id: 'company_ind_government',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the government Industry'
    },
    company_ind_healthcare: {
        id: 'company_ind_healthcare',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the healthcare Industry'
    },
    company_ind_hospitality: {
        id: 'company_ind_hospitality',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the hospitality Industry'
    },
    company_ind_infrastructure: {
        id: 'company_ind_infrastructure',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the infrastructure Industry'
    },
    company_ind_manufacturing: {
        id: 'company_ind_manufacturing',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the manufacturing Industry'
    },
    company_ind_retail: {
        id: 'company_ind_retail',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the retail Industry'
    },
    company_ind_restaurants: {
        id: 'company_ind_restaurants',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the restaurant Industry'
    },
    company_ind_sports: {
        id: 'company_ind_sports',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the sport Industry. Including event complexes (eg Sports Arenas)'
    },
    company_ind_waterworks: {
        id: 'company_ind_waterworks',
        value: true,
        display: null,
        description: 'Indicates if the company does work within the waterworks Industry'
    },
    company_ind_interior: {
        id: 'company_ind_interior',
        value: true,
        display: 'Interior',
        description: 'Indicates if the company does interior work'
    },
    company_ind_exterior: {
        id: 'company_ind_exterior',
        value: true,
        display: 'Exterior',
        description: 'Indicates if the company does exterior work'
    },
    company_ind_structural: {
        id: 'company_ind_structural',
        value: true,
        display: null,
        description: 'Indicates if the company does structural work including foundations'
    },
    company_ind_roofing: {
        id: 'company_ind_roofing',
        value: true,
        display: null,
        description: 'Indicates if the company does roofing work'
    },
    company_ind_siding: {
        id: 'company_ind_siding',
        value: true,
        display: null,
        description: 'Indicates if the company does siding work'
    },
    company_ind_windows: {
        id: 'company_ind_windows',
        value: true,
        display: null,
        description: 'Indicates if the company does window work (installation, service and/or repair)'
    },
    company_ind_gutters: {
        id: 'company_ind_gutters',
        value: true,
        display: null,
        description: 'Indicates if the company does gutter work (installation, service and/or repair)'
    },
    company_ind_doors: {
        id: 'company_ind_doors',
        value: true,
        display: null,
        description: 'Indicates if the company installs, services or repairs doors. Interior and/or Exterior'
    },
    company_ind_masonry: {
        id: 'company_ind_masonry',
        value: true,
        display: null,
        description: 'Indicates if the company does masonry work'
    },
    company_ind_chimney: {
        id: 'company_ind_chimney',
        value: true,
        display: null,
        description: 'Indicates if the company does chimney work (eg Chimney Sweep)'
    },
    company_ind_decking: {
        id: 'company_ind_decking',
        value: true,
        display: null,
        description: 'Indicates if the company does installs, service or repairs decks'
    },
    company_ind_pools: {
        id: 'company_ind_pools',
        value: true,
        display: null,
        description: 'Indicates if the company installs, services, cleans or repairs swimming pools'
    },
    company_ind_additions: {
        id: 'company_ind_additions',
        value: true,
        display: null,
        description: 'Indicates if the company constructs residential additions'
    },
    company_ind_adus: {
        id: 'company_ind_adus',
        value: true,
        display: null,
        description: 'Indicates if the company constructs Accessory Dwelling Units (ADUs)'
    },
    company_ind_garages: {
        id: 'company_ind_garages',
        value: true,
        display: null,
        description: 'Indicates if the company constructs Garages'
    },
    company_ind_landscape: {
        id: 'company_ind_landscape',
        value: true,
        display: null,
        description: 'Indicates if the company performs services related to landscaping'
    },
    company_ind_lawn_care: {
        id: 'company_ind_lawn_care',
        value: true,
        display: null,
        description: 'Indicates if the company performs services related to lawn care and maintenance'
    },
    company_ind_hardscape: {
        id: 'company_ind_hardscape',
        value: true,
        display: null,
        description: 'Indicates if the company performs services related to hardscaping'
    },
    company_ind_fencing: {
        id: 'company_ind_fencing',
        value: true,
        display: null,
        description: 'Indicates if the company performs services related to fencing (all types)'
    },
    company_ind_soffits: {
        id: 'company_ind_soffits',
        value: true,
        display: null,
        description: 'Indicates if the company does soffit work'
    },
    company_ind_septic: {
        id: 'company_ind_septic',
        value: true,
        display: null,
        description: 'Indicates if the company does septic work (installation, service and/or repair)'
    },
    company_ind_solar: {
        id: 'company_ind_solar',
        value: true,
        display: null,
        description: 'Indicates if the company does solar work (installation, service and/or repair)'
    },
    company_ind_carpentry: {
        id: 'company_ind_carpentry',
        value: true,
        display: null,
        description: 'Indicates if the company does carpentry work'
    },
    company_ind_electrical: {
        id: 'company_ind_electrical',
        value: true,
        display: null,
        description: 'Indicates if the company does electrical work'
    },
    company_ind_plumbing: {
        id: 'company_ind_plumbing',
        value: true,
        display: null,
        description: 'Indicates if the company does plumbing work'
    },
    company_ind_hvac: {
        id: 'company_ind_hvac',
        value: true,
        display: null,
        description: 'Indicates if the company does HVAC work (installation, service and/or repair)'
    },
    company_ind_boiler: {
        id: 'company_ind_boiler',
        value: true,
        display: null,
        description: 'Indicates if the company does Boiler work (installation, service and/or repair)'
    },
    company_ind_water_heater: {
        id: 'company_ind_water_heater',
        value: true,
        display: null,
        description: 'Indicates if the company does Water Heater work (installation, service and/or repair)'
    },
    company_ind_duct_work: {
        id: 'company_ind_duct_work',
        value: true,
        display: null,
        description: 'Indicates if the company does duct work'
    },
    company_ind_geothermal: {
        id: 'company_ind_geothermal',
        value: true,
        display: null,
        description: 'Indicates if the company works on geothermal systems (installation, service and/or repair)'
    },
    company_ind_flooring: {
        id: 'company_ind_flooring',
        value: true,
        display: null,
        description: 'Indicates if the company works with flooring (Carpeting, Wood, Stone/Tile, etc)'
    },
    company_ind_carpets: {
        id: 'company_ind_carpets',
        value: true,
        display: null,
        description: 'Indicates if the company works with carpeting'
    },
    company_ind_wood_flooring: {
        id: 'company_ind_wood_flooring',
        value: true,
        display: null,
        description: 'Indicates if the company works with wood flooring (hardwood and/or composite)'
    },
    company_ind_stone_and_tile: {
        id: 'company_ind_stone_and_tile',
        value: true,
        display: null,
        description: 'Indicates if the company works with stone and/or tile'
    },
    company_ind_countertop: {
        id: 'company_ind_countertop',
        value: true,
        display: null,
        description: 'Indicates if the company works with countertops (installation, service and/or repair - stone, composite, etc)'
    },
    company_ind_painting: {
        id: 'company_ind_painting',
        value: true,
        display: null,
        description: 'Indicates if the company does painting work'
    },
    company_ind_interior_painting: {
        id: 'company_ind_interior_painting',
        value: true,
        display: null,
        description: 'Indicates if the company does interior painting work'
    },
    company_ind_exterior_painting: {
        id: 'company_ind_exterior_painting',
        value: true,
        display: null,
        description: 'Indicates if the company does exterior painting work'
    },
    company_ind_drywall: {
        id: 'company_ind_drywall',
        value: true,
        display: null,
        description: 'Indicates if the company does drywall work'
    },
    company_ind_wall_coverings: {
        id: 'company_ind_wall_coverings',
        value: true,
        display: null,
        description: 'Indicates if the company works with wall coverings, wall paper or other hangings (installation, service and/or repair)'
    },
    company_ind_cabinets: {
        id: 'company_ind_cabinets',
        value: true,
        display: null,
        description: 'Indicates if the company does cabinetry work (manufacture, installation, servicing and/or repair)'
    },
    company_ind_insulation: {
        id: 'company_ind_insulation',
        value: true,
        display: null,
        description: 'Indicates if the company does insulation work (installation, service and/or repair - blown, sprayed or solid)'
    },
    company_ind_damage_restoration: {
        id: 'company_ind_damage_restoration',
        value: true,
        display: null,
        description: 'Indicates if the company does damage restoration work (typically associated with a natural or manmade disaster)'
    },
    company_ind_fire_damage_resotoration: {
        id: 'company_ind_fire_damage_resotoration',
        value: true,
        display: null,
        description: 'Indicates if the company does fire damage restoration work'
    },
    company_ind_water_damage_resotoration: {
        id: 'company_ind_water_damage_resotoration',
        value: true,
        display: null,
        description: 'Indicates if the company does water damage restoration work'
    },
    company_ind_design: {
        id: 'company_ind_design',
        value: true,
        display: null,
        description: 'Indicates if the company does design work'
    },
    company_ind_design_furnishings: {
        id: 'company_ind_design_furnishings',
        value: true,
        display: null,
        description: 'Indicates if the company does interior design work related to furnishing selection'
    },
    company_ind_design_spaces: {
        id: 'company_ind_design_spaces',
        value: true,
        display: null,
        description: 'Indicates if the company does design work related to interior spaces (Kitchen, Bath, Bedroom, etc)'
    },
    company_ind_design_kitchen: {
        id: 'company_ind_design_kitchen',
        value: true,
        display: null,
        description: 'Indicates if the company does design work related to kitchens'
    },
    company_ind_design_bath: {
        id: 'company_ind_design_bath',
        value: true,
        display: null,
        description: 'Indicates if the company does design work related to bathrooms'
    },
    company_ind_design_basement: {
        id: 'company_ind_design_basement',
        value: true,
        display: null,
        description: 'Indicates if the company does design work related to basements'
    },
    company_ind_design_build: {
        id: 'company_ind_design_build',
        value: true,
        display: null,
        description: 'Indicates if the company does whole home design / build work'
    },
    company_ind_design_landscape: {
        id: 'company_ind_design_landscape',
        value: true,
        display: null,
        description: 'Indicates if the company does landscape design work'
    },
    company_ind_design_lighting: {
        id: 'company_ind_design_lighting',
        value: true,
        display: null,
        description: 'Indicates if the company does lighting design work'
    },
    company_ind_design_landscape_lighting: {
        id: 'company_ind_design_landscape_lighting',
        value: true,
        display: null,
        description: 'Indicates if the company does landscape lighting design work'
    },
    company_ind_design_interior_lighting: {
        id: 'company_ind_design_interior_lighting',
        value: true,
        display: null,
        description: 'Indicates if the company does interior design lighting work'
    },
    company_ind_green_builder: {
        id: 'company_ind_green_builder',
        value: true,
        display: null,
        description: 'Indicates if the company promotes green building techniques'
    },
    // Licenses
    company_ind_licensed: {
        id: 'company_ind_licensed',
        value: true,
        display: 'Licensed',
        description: 'Indicates if the company is licensed'
    },
    // Certifications
    company_ind_certified_nate: {
        id: 'company_ind_certified_nate',
        value: true,
        display: null,
        description: 'Indicates if the company is NATE certified'
    },
    // Not Related to 'Work'
    company_ind_veteran_owned: {
        id: 'company_ind_veteran_owned',
        value: true,
        display: null,
        description: 'Indicates if the company is veteran owned'
    },
    // company_ind_chain
    company_ind_franchise: {
        id: 'company_ind_franchise',
        value: true,
        display: null,
        description: 'Indicates if the company is part of a franchise'
    },
    // company_ind_government
    company_ind_home_office: {
        id: 'company_ind_home_office',
        value: true,
        display: null,
        description: 'Indicates if the company is a home office'
    },
    company_ind_minority: {
        id: 'company_ind_minority',
        value: true,
        display: null,
        description: 'Indicates if the company is minority owned'
    },
    // company_ind_small
    company_ind_woman: {
        id: 'company_ind_woman',
        value: true,
        display: null,
        description: 'Indicates if the company is woman owned and / or operated'
    },
    company_ind_lgbtqi_owned: {
        id: 'company_ind_lgbtqi_owned',
        value: true,
        display: null,
        description: 'Indicates if the company is LGBTQI owned and / or operated'
    },
    company_ind_public: {
        id: 'company_ind_public',
        value: true,
        display: null,
        description: 'Indicates if the company is a public company'
    },
    company_ind_private: {
        id: 'company_ind_private',
        value: true,
        display: null,
        description: 'Indicates if the company is a private company'
    },
    company_ind_erisa: {
        id: 'company_ind_erisa',
        value: true,
        display: null,
        description: 'Indicates if the company participates in ERISA'
    },
    // Language
    company_ind_bilingual: {
        id: 'company_ind_bilingual',
        value: true,
        display: null,
        description: 'Indicates if the company has bilingual speakers'
    },
    company_ind_language_english: {
        id: 'company_ind_language_english',
        value: true,
        display: null,
        description: 'Indicates if the company has english speakers'
    },
    company_ind_language_spanish: {
        id: 'company_ind_language_spanish',
        value: true,
        display: null,
        description: 'Indicates if the company has spanish speakers'
    },
    // Policy
    company_ind_bonded: {
        id: 'company_ind_bonded',
        value: true,
        display: 'Bonded',
        description: 'Indicates if the company is bonded'
    },
    company_ind_insured: {
        id: 'company_ind_insured',
        value: true,
        display: 'Insured',
        description: 'Indicates if the company is insured'
    }
}

export function getIndicatorTypeById(id) {
    if (id) {
        return _.get(INDICATOR_TYPES, id);
    }
}
