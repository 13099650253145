/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import CardLabel from "./CardLabel";
import {APP_COLORS} from "../../../../common/Constants";

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px,max-content);
  padding: 0px 10px, 0px, 0px;
`;

const LeadText = styled.div `
  font-size: 15px;
  font-weight: bold;
  color: ${APP_COLORS.blue};
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function ContactBox(props) {
    const {
        label,
        item
    } = props;

    return (
        <BoxContainer>
            <CardLabel>{label}</CardLabel>
            {item.contactId && (
                <>
                    <LeadText>
                        <>{item.contactName && item.contactName.length > 40 ?
                            `${item.contactName.substring(0, 40)}...` : item.contactName
                        }</>
                    </LeadText>
                    <SubText>
                        {item.contactTitle && (
                            <>{item.contactTitle && item.contactTitle.length > 75 ?
                                `${item.contactTitle.substring(0, 75)}...` : item.contactTitle
                            }</>
                        )}
                    </SubText>
                </>
            )}
        </BoxContainer>
    )
}
