/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import ManagedIntegrationsContainer from "./ManagedIntegrationsContainer";
import CreateManagedIntegrationContainer from "./CreateManagedIntegrationContainer";
import ManagedIntegrationViewAsJSON from "./ManagedIntegrationViewAsJSON";

export default class ManagedIntegrationsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/integrations/create" exact component={CreateManagedIntegrationContainer}/>
                <Route path="/admin/integrations/:id/view-as-json" component={ManagedIntegrationViewAsJSON}/>
                <Route path="/admin/integrations" component={ManagedIntegrationsContainer} />
            </Switch>
        );
    }
}
