/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';

import config from '../config';


/**
 * Get Copyright
 * @returns {object}
 */
export function getCopyright() {
    const year = new Date().getFullYear();
    return (
        <span>&copy; {year} - ToolBelt Data</span>
    );
}

/**
 * Get Version
 * @param long boolean if true, returns a 'long' version (name + version + env)
 * @returns {object}
 */
export function getVersion(long = false) {
    let _env;
    if (config.env === 'development') _env = 'DEV';
    if (config.env === 'test') _env = 'TEST';
    if (config.env === 'production') _env = 'PROD';
    if (long) return `${config.name} v${config.version} (${_env})`;
    return `${config.version}`;
}

/**
 * Determines if the current browser supports HTML5 local storage
 * This should be updated / replaced with Modernizr - https://modernizr.com/ | https://stackoverflow.com/questions/11214404/how-to-detect-if-browser-supports-html5-local-storage
 * @returns {boolean}
 */
export function supportsLocalStorage() {
    return typeof (Storage) !== 'undefined';
}

// TODO: Specify an encoding type, today we presume base64
export function encode(string) {
    return btoa(string);
}

// TODO: Specify an encoding type, today we presume base64
export function decode(encodedString) {
    return atob(encodedString);
}

export function numberFormatter(num, digits) {
    const si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'k' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'G' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) { // eslint-disable-line no-plusplus
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function isEven(n) {
    return n % 2 == 0; // eslint-disable-line eqeqeq
}

export function isOdd(n) {
    return Math.abs(n % 2) == 1; // eslint-disable-line eqeqeq
}

// TODO: Make Deep optional?
export function deleteEmptyKeysFromObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object') {
            newObj[key] = deleteEmptyKeysFromObject(obj[key]); // recurse
        } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
            newObj[key] = obj[key]; // copy value
        }
    });

    // // Remove empty objects from newObj
    // Object.keys(newObj).forEach((key) => {
    //     if (newObj[key] && typeof newObj[key] === 'object' && _.isEmpty(newObj[key])) { // TODO: This might not catch nested empties
    //         console.log('deleteing', newObj[key]);
    //         delete newObj[key];
    //     }
    // });

    return newObj;
}

export function formatId(id) {
    return `${id.split('-')[0]}...`;
}

export async function delay(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
