
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from 'react-bootstrap/Button';

import {APP_COLORS} from "../Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

import styled from "styled-components";

const TextHoverDisplay = (props) => {

  const {
      truncatedText,
      fullText,
      toolTipTextColor,
      toolTipBackgroundColor,
      displayTruncated,
      canShowText=true,
      canShowCopyToClipboard=true,
      minWidth=125,
  } = props;

  const [copied, setCopied] = useState(false);

  return (
      <div style={{minWidth: minWidth}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          {canShowText && (
              <div data-for='hoverText' data-tip={fullText}>
                  {displayTruncated ?
                      <span>{`${truncatedText} ...`}&nbsp;</span>
                      :
                      <span>{`${fullText}`}&nbsp;</span>
                  }
              </div>)}
              {canShowCopyToClipboard &&(
              <div>
                  <CopyToClipboard
                      text={fullText}
                      onCopy={(e) => {
                          setCopied(true);
                          window.setTimeout(() => setCopied(false), 2000);
                      }}
                  >
                      <div data-for='copiedText' data-tip={'copied'}>
                        <WrapperFontAwesomeIcon icon={faCopy} />
                      </div>
                  </CopyToClipboard>
              </div>)}
              { copied && (
                  <TagCopiedLayout>
                      <TagCopied>Copied!</TagCopied>
                  </TagCopiedLayout>
              )}
              
          </div>
          <ReactTooltip
              id='hoverText'
              getContent={(dataTip) => `${dataTip}`}
              textColor={toolTipTextColor ? toolTipTextColor: undefined}
              backgroundColor={toolTipBackgroundColor ? toolTipBackgroundColor : undefined}
          />

          {/*<ReactTooltip*/}
              {/*id='copiedText'*/}
              {/*getContent={(dataTip) => copied ? `${dataTip}` : undefined}*/}
              {/*textColor={toolTipTextColor ? toolTipTextColor : undefined}*/}
              {/*backgroundColor={toolTipBackgroundColor ? toolTipBackgroundColor : undefined}*/}
          {/*/>*/}

      </div>
  )
};

TextHoverDisplay.defaultProps = {
    displayTruncated: true,
};

TextHoverDisplay.propTypes = {
    displayTruncated: PropTypes.bool,
    truncatedText: PropTypes.string,
    fullText: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    toolTipTextColor: PropTypes.string,
    toolTipBackgroundColor: PropTypes.string,
};

const WrapperFontAwesomeIcon = styled(FontAwesomeIcon) `
  color: ${APP_COLORS.blue};
  cursor: pointer;
  
  &:hover {
    color: ${APP_COLORS.orange};
  }
  
  &:active {
    color: ${APP_COLORS.cyan};
  }
`;

const TagCopiedLayout = styled.div `
  position: relative;
`;

const TagCopied = styled.div `
  padding: 3px 15px;
  background-color: ${APP_COLORS.orange};
  border-radius: 5px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  margin-left: 15px;
  box-shadow: rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
  position: absolute;
  top: -2px;
`;

export default TextHoverDisplay;
