import React, {useState, useEffect} from "react";
import {editInput, getInputById, clearState} from "./inputSlice"
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";
import {Row, Col, Button, Form} from 'react-bootstrap';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import {TYPES} from "./InputTypes";
import FormSelect from "../../common/components/form/FormSelect";
import FormNumericInput from "../../common/components/FormNumericInput";
import CheckBox from "../../common/components/CheckBox";
import {INDICATOR_TYPES} from "../../common/metadata/Indicators";
import {values, filter} from "lodash";
import {Alert} from "react-bootstrap";
import {isEmpty} from "lodash";


const EditInputContainer = (props) => {
    const managedInputState = useSelector(state => state.input);
    const dispatch = useDispatch();
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (props.match.params.id) {
            dispatch(getInputById(props.match.params.id));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (managedInputState.managedInput) {
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Inputs',
                        href: '/admin/inputs',
                        isActive: false
                    },
                    {
                        label: `${managedInputState?.managedInput.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: `Edit Input`,
                        href: '',
                        isActive: true
                    }
                ]
            )
        }
    }, [managedInputState.managedInput]);

    useEffect(() => {
        setAlertMessage(managedInputState.alertMessage);
        if (managedInputState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedInputState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(managedInputState.successMessage);
        if (managedInputState.successMessage) {
            setShowSuccess(true);
        } else {
            setShowSuccess(false);
        }
    }, [managedInputState.successMessage]);

    const handleEditInput = async (data) => {
        dispatch(editInput({id: props.match.params.id, data: data}));
    };

    const handleCancel = async () => {
        dispatch(clearState());
        history.push('/admin/input')
    };
    return (
        <div>
            {managedInputState.loading && (
                <SpinnerOverlay/>
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            {
                managedInputState.managedInput && (
                    <EditInput
                        id={managedInputState.managedInput?.id}
                        handleEditInput={(data) => handleEditInput(data)}
                        handleCancel={() => handleCancel()}
                        breadcrumbItems={breadcrumbItems}
                        input={managedInputState.managedInput}
                    />
                )
            }
        </div>
    );
};

function EditInput(props) {
    const {
        breadcrumbItems,
        handleEditInput,
        handleCancel,
        input,
    } = props;

    const [formValid, setFormValid] = useState(true);
    const [formSelectedTypeOption, setFormSelectedTypeOption] = useState(null);
    const [formName, setFormName] = useState(input.name);
    const [formLabel, setFormLabel] = useState(input.label);
    const [formDescription, setFormDescription] = useState(input.description || '');
    const [formUrl, setFormUrl] = useState(input.url || '');
    const [formQualityScore, setFormQualityScore] = useState(input.qualityScore);
    const [formIsAuthoritative, setFormIsAuthoritative] = useState(input.authoritative);
    const [formIsPublic, setFormIsPublic] = useState(input.public);
    const [formSelectedProductOptions, setFormSelectedProductOptions] = useState([]);
    const [formSelectedIndicatorOptions, setFormSelectedIndicatorOptions] = useState([]);

    const typeOptions = values(TYPES).map((typ) => {
        return {value: typ.id, label: typ.display};
    });

    useEffect(() => {
        setFormSelectedTypeOption(typeOptions.find(a => a.value === input.type));
    }, [input.type]);

    const productOptions = [
        {value: 'b2b', label: 'b2b'},
        {value: 'tcu', label: 'tcu'}
    ];

    useEffect(() => {
        if (input.products && input.products.length > 0) {
            const _selectedProductOptions = [];
            input.products.forEach(element => {
                _selectedProductOptions.push(productOptions.find(a => a.value === element));
            });
            setFormSelectedProductOptions(_selectedProductOptions);
        }
    }, [input.products]);

    useEffect(() => {
        if (input.indicators && input.indicators.length > 0) {
            const _selectedIndicatorOptions = [];
            input.indicators.forEach(element => {
                const ind = values(INDICATOR_TYPES).find(a => a.id === element);
                _selectedIndicatorOptions.push({
                        value: ind.id,
                        label: ind.display
                    }
                )
            })
            setFormSelectedIndicatorOptions(_selectedIndicatorOptions);
        }
    }, [input.indicators]);



    const isValid = () => {
        if (isEmpty(formName)) return false;
        if (isEmpty(formLabel)) return false;
        if (isEmpty(formSelectedProductOptions)) return false;
        return true;
    };

    const handleEditInputClick = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            setFormValid(true);
            const data = {
                type: formSelectedTypeOption.value,
                name: formName,
                label: formLabel,
                description: isEmpty(formDescription) ? null : formDescription,
                url: isEmpty(formUrl) ? null : formUrl,
                qualityScore: parseFloat(formQualityScore),
                authoritative: formIsAuthoritative,
                public: formIsPublic,
                products: formSelectedProductOptions.map((i) => i.value),
                indicators: formSelectedIndicatorOptions.length > 0 ? formSelectedIndicatorOptions.map((i) => i.value) : null,
            };
            handleEditInput(data);
        }
    };

    const onInputValidate = (value, name) => {
        setError((prev) => ({
            ...prev,
            [name]: {...prev[name], errorMsg: value},
        }));
    };

    const [error, setError] = useState({
        inputName: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
        inputLabel: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
        }
    });


    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Edit Input'} subHeader={'Edit (change) the Input'}/>
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="editInput">
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={9}>
                                            <FormLabel>INPUT ID</FormLabel>
                                            {input.id &&
                                                <TextHoverDisplay
                                                    fullText={input.id}
                                                    truncatedText={input.id}
                                                    displayTruncated={false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Type'}
                                                options={filter(values(TYPES).map((t) => ({
                                                    value: t.id,
                                                    label: t.display
                                                })), (v) => {
                                                    if (
                                                        v.value === "PERMIT"
                                                        || v.value === "LICENSE"
                                                        || v.value === "PROFILE"
                                                        || v.value === "POLICY"
                                                        || v.value === "REGISTRATION"
                                                    ) return true;
                                                })}
                                                onChange={(v) => setFormSelectedTypeOption(v)}
                                                value={formSelectedTypeOption}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'Name'}
                                                label={'Name'}
                                                name={'inputName'}
                                                value={formName}
                                                onChange={value => setFormName(value)}
                                                isInvalid={!formValid && !formName}
                                                size={'sm'}
                                                min={4}
                                                required
                                                {...error.inputName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'Label'}
                                                label={'Label'}
                                                name={'inputLabel'}
                                                value={formLabel}
                                                onChange={value => setFormLabel(value)}
                                                isInvalid={!formValid && !formLabel}
                                                reqType={'TITLE'}
                                                min={4}
                                                size={'sm'}
                                                required
                                                {...error.inputLabel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextArea
                                                label={'Description'}
                                                onChange={value => setFormDescription(value)}
                                                value={formDescription}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'URL'}
                                                label={'URL'}
                                                name={'inputUrl'}
                                                value={formUrl}
                                                onChange={value => setFormUrl(value)}
                                                size={'sm'}
                                                {...error.inputUrl}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormNumericInput
                                                label={'Quality Score'}
                                                value={formQualityScore}
                                                onChange={value => setFormQualityScore(value)}
                                                isInvalid={!formValid && !formQualityScore}
                                                size={'sm'}
                                                step={props.step || 0.05}
                                                min={0.01}
                                                max={0.99}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Authoritative'}
                                                checked={formIsAuthoritative}
                                                onChange={value => setFormIsAuthoritative(value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Public'}
                                                checked={formIsPublic}
                                                onChange={value => setFormIsPublic(value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Products'}
                                                options={productOptions}
                                                onChange={value => setFormSelectedProductOptions(value)}
                                                value={formSelectedProductOptions}
                                                isMulti
                                                required
                                            />

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Indicators'}
                                                options={filter(values(INDICATOR_TYPES), (i) => {
                                                    return !!i.display
                                                }).map((t) => ({value: t.id, label: t.display})
                                                )}
                                                onChange={(v) => setFormSelectedIndicatorOptions(v)}
                                                value={formSelectedIndicatorOptions}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEditInputClick()}
                                    >EDIT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default EditInputContainer;
