/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    getManagedMatchTemplateById,
    editManagedMatchTemplate,
    clearState,
} from "./managedMatchTemplateSlice";

import {Row, Col, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import BreadCrumb from '../../common/components/BreadCrumb';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import PanelHeader from "../../common/components/layout/PanelHeader";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";
import FormTextArea from "../../common/components/form/FormTextArea";
import {APP_COLORS} from "../../common/Constants";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EditManagedMatchTemplateContainer = (props) => {
    const managedMatchTemplateState = useSelector(state => state.managedMatchTemplate);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    // Form
    const [managedMatchTemplate, setManagedMatchTemplate] = useState({
        matchTemplate: '',
    });
    const [matchTemplate, setMatchTemplate] = useState('');

    useEffect( () => {
        if (props.match.params.id){
            dispatch(getManagedMatchTemplateById(props.match.params.id));
        }

    }, [props.match.params.id]);

    useEffect( () => {
            if (managedMatchTemplateState.managedMatchTemplate) {
                setManagedMatchTemplate(managedMatchTemplateState.managedMatchTemplate);
                setMatchTemplate(managedMatchTemplateState.managedMatchTemplate);
                setBreadcrumbItems(
                    [
                        {label: 'Home', href: '/home', isActive: false},
                        {
                            label: 'Match Templates',
                            href: '/admin/match-templates',
                            isActive: false
                        },
                        {
                            label: `${managedMatchTemplateState?.managedMatchTemplate.id}`,
                            href: '',
                            isActive: true
                        },
                        {label: 'Edit Match Template' , href: '', isActive: true}]
                )
            }
        }
        , [managedMatchTemplateState.managedMatchTemplate]);

    useEffect(() => {
        setAlertMessage(managedMatchTemplateState.alertMessage);
        if (managedMatchTemplateState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedMatchTemplateState.alertMessage]);

    useEffect(() => {

        setSuccessMessage(managedMatchTemplateState.successMessage);
        if (managedMatchTemplateState.successMessage) {
            setShowSuccess(true);
        }else {
            setShowSuccess(false);
        }
    }, [managedMatchTemplateState.successMessage]);

    const handleEditManagedMatchTemplate = async (data) => {
        dispatch(editManagedMatchTemplate({id:props.match.params.id, data:data}));
    };

    const handleCancel = async () => {
        dispatch(clearState());
        history.push('/admin/match-templates')
    };

    return (
        <div>
            {managedMatchTemplateState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <EditMatchTemplate
                id={managedMatchTemplate?.id}
                handleEditManagedMatchTemplate={(data) => handleEditManagedMatchTemplate(data)}
                handleCancel={() => handleCancel()}
                matchTemplate={matchTemplate}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function EditMatchTemplate(props) {

    const {
        breadcrumbItems,
        matchTemplate,
        handleCancel,
        id,
    } = props;

    const [formValid, setFormValid] = useState(true);
    const [matchTemplateState, setMatchTemplateState] = useState('');

    const reset = () => {
        setMatchTemplateState('');
    };

    const isValid = () => {
        if (!matchTemplateState) return false;
        try {
            JSON.parse(matchTemplateState);
            return true;
        } catch (error) {
            console.warn(`Match Template contains errors - NOT VALID JSON [${error.message}]`);
            return false;
        }
    };

    const handleEditManagedMatchTemplate = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            const data = {
                matchTemplate: JSON.parse(matchTemplateState)
            };
            props.handleEditManagedMatchTemplate(data);
        }
    };

    useEffect(() => {
        setMatchTemplateState(JSON.stringify(matchTemplate, null, '\t'));
    }, [matchTemplate]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={'Edit Match Template'}
                subHeader={'Change match template properties'}
            />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="editMatchTemplate">
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <Col md={9}>
                                            <FormLabel>Match Template ID</FormLabel>
                                            {id &&
                                                <TextHoverDisplay
                                                    fullText={id}
                                                    truncatedText={id}
                                                    displayTruncated={false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormTextArea
                                                label={'Template'}
                                                value={matchTemplateState}
                                                onChange={value => setMatchTemplateState(value)}
                                                isInvalid={formValid}
                                                rows={20}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col md={12}>
                                            <h6 style={{fontWeight: "600"}}>Help</h6>
                                            <p style={{color: APP_COLORS.red}}><FontAwesomeIcon color={APP_COLORS.red} icon={faExclamationCircle} /> While you can change all attributes only changes to name, description and config will be saved. Check the console if your having issues, errors are likely logged there.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEditManagedMatchTemplate()}
                                    >EDIT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default EditManagedMatchTemplateContainer;

