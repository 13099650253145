/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState} from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SectionTogglePanel.scss';

function SectionTogglePanel(props) {
    const {
        overflowX = true
    } = props;
    const [expand, setExpand] = useState(props.isExpand ? props.isExpand : false);

    const handleOnClick = (value) => {
        setExpand(value);
        props.handleOnClick(value);
    };

    return (
        <div className="accordionWrapper">
            <div
                className={classNames({
                    "sectionAccordionTitle": true,
                    "open": expand,
                })}
            >
                <div
                    className="accordionHeaderContent"
                    onClick={() => handleOnClick(!expand)}
                >
                    <div className="title">{props.title}</div>
                </div>
                <div
                    className="expandIcon"
                    onClick={() => handleOnClick(!expand)}
                >
                    { expand ?
                        <FontAwesomeIcon icon={faMinus} />
                        :
                        <FontAwesomeIcon icon={faPlus} />
                    }
                </div>
            </div>
            <div className="sectionHorizontalDivider"/>
            <div
                className={classNames({
                    "accordionItem": true,
                    "collapsed": !expand,
                })}
            >
                <div
                    className="accordionContent"
                    style={{
                        maxHeight: props.contentHeight ? props.contentHeight : 'auto',
                        height: props.contentHeight ? props.contentHeight : 'auto',
                        overflowX: overflowX ? 'auto' : 'hidden'
                    }}
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
}

SectionTogglePanel.propTypes = {
    contentHeight: PropTypes.number,
    isExpand: PropTypes.bool,
    handleOnClick: PropTypes.func
};

SectionTogglePanel.defaultProps = {
    handleOnClick: (e) => e,
};

export default SectionTogglePanel;
