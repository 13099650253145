/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {APP_COLORS} from '../Constants';

function Sidebar(props) {
    return (
        <div className={props.expanded ? "sidebar sidebar--expanded" : "sidebar"}>
            <div className="toggle_box" onClick={props.toggleSidebar} >
                { props.expanded ?
                    <FontAwesomeIcon color={APP_COLORS['tech-blue']} icon={faCaretLeft} />
                    :
                    <FontAwesomeIcon color={APP_COLORS['tech-blue']} icon={faCaretRight} />
                }
            </div>
            <div className="sidebar-content">
                {props.children}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    toggleSidebar: PropTypes.func,
    expanded: PropTypes.bool,
};

export default Sidebar;
