import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    contactPhone: null
};

export const getContactPhoneById = createAsyncThunk('contacts/getContactPhoneById', async (id) => {
    const GET_CONTACT_PHONE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${id}/phone`;
    const contactPhone = await Api.get(true, GET_CONTACT_PHONE_BY_ID_URL);
    return contactPhone;
});

export const changeContactPhoneById = createAsyncThunk('contacts/changeCompanyPhoneById', async (data) => {
    const CHANGE_CONTACT_PHONE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${data.id}/phone/change`;
    const response = await Api.post(true, CHANGE_CONTACT_PHONE_BY_ID_URL, data.body);
    return response;
});

const contactPhoneSlice = createSlice({
    name: 'contactPhoneSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.contactPhone = null;
            return state;
        }
    },
    extraReducers: {
        [getContactPhoneById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getContactPhoneById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactPhone = action.payload;
            }
        },
        [getContactPhoneById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve contact information';
        },
        [changeContactPhoneById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeContactPhoneById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactPhone = action.payload;
            }
        },
        [changeContactPhoneById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set contact information';
        },
    }
});

export const { clearState } = contactPhoneSlice.actions;
export default contactPhoneSlice.reducer;
