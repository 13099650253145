/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import ManagedMatchTemplatesContainer from "./ManagedMatchTemplatesContainer";
import CreateManagedMatchTemplateContainer from "./CreateManagedMatchTemplateContainer";
import EditManagedMatchTemplateContainer from "./EditManagedMatchTemplateContainer";

export default class ManagedMatchTemplatesRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/match-templates/create" exact component={CreateManagedMatchTemplateContainer}/>
                <Route path="/admin/match-templates/:id/edit" exact component={EditManagedMatchTemplateContainer}/>
                <Route path="/admin/match-templates" component={ManagedMatchTemplatesContainer} />
            </Switch>
        );
    }
}
