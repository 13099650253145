import React, {Component} from "react";
import PropTypes from 'prop-types';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { setChonkyDefaults, FullFileBrowser, ChonkyActions, FileBrowserHandle } from 'chonky';
import {useSelector} from "react-redux";
let selectedFile = '';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

export default class FileTreeBrowser extends Component {
    static propTypes = {onFileSelected: PropTypes.func};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
        this.onFileSelected = props.onFileSelected;
        selectedFile = props.selectedFile;
    }

   handleFileAction =  (actionData) =>{
        if (actionData.id === ChonkyActions.ChangeSelection.id) {
            const selectedFiles = actionData.state.selectedFiles;
            if(selectedFiles.length > 0) { //Avoid making changes if someone clicks away from the Chonky window.
                const selectedFileNames = selectedFiles.map((f) => f.name);
                this.props.onFileSelected(selectedFileNames[0]);
                this.selectedFile = selectedFileNames[0];
            }
        }
    }

    render() {
        return (
            <FileTreeBrowserRender onFileAction={this.handleFileAction}/>
        )
    }
}

function getFileList(directoryList){

    let files = [];
    if(directoryList != null && directoryList.length > 0) { // != null && fileTreeState.objects.Contents.length > 0) {
        // let fileContents = directoryList;
        for (let contentKey in directoryList) {
            let file = directoryList[contentKey];
            files.push(
                {
                    id: file.Prefix,
                    name: file.Prefix,
                    // size: file.Size,
                    // modDate: file.LastModified,
                    isDir: true
                }
            )
        }
    }
    return files;
}

const folderChain = [{ id: 'xcv', name: 'Inputs', isDir: true }];

const fileActions = [
    ChonkyActions.ChangeSelection
];

function FileTreeBrowserRender(props) {
    const handleFileAction = props.onFileAction;
    let createInputState = useSelector(state => state.createInput); //Can this be passed in?
    let fileTreeState = createInputState.fileTreeState;
   // return "This is the File Tree Browser";
    let fileList = getFileList(fileTreeState);
    const fileBrowserRef = React.useRef('testValue');
    if(selectedFile != '') {
        let testCurrent = fileBrowserRef.current;
    }

    return (
            <div style={{ height: 400 }}>
                <FullFileBrowser
                    instanceId='testInstance'
                    ref={fileBrowserRef}
                    files={fileList}
                    folderChain={folderChain}
                    fileActions={fileActions}
                    onFileAction={handleFileAction}
                    defaultFileViewActionId={ChonkyActions.EnableListView.id}
                >
                </FullFileBrowser>
            </div>
    )
}
