/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import companyIcon from "../../../../common/assets/img/ToolbeltIcons_Artboard_19.png";
import contactIcon from "../../../../common/assets/img/ToolbeltIcons-16.png";
import {APP_COLORS} from "../../../../common/Constants";

const IconBoxContainer = styled.div `
  justify-self: center;
  align-self: center;
`;

const IconContainer = styled.div `
  height: 65px;
  width: 65px;
  padding: 6px;
  border: 1px solid ${APP_COLORS['gray-400']};
`;

const IconContactContainer = styled.div `
  height: 65px;
  width: 65px;
  padding: 6px;
  border: 1px solid ${APP_COLORS['gray-400']};
`;

const Icon = styled(Image) `
  height: 50px;
`;

const IconContact = styled(Image) `
  height: 50px;
`;

export default function IconBox(props) {
    const {
        mode
    } = props;

    return (
        <IconBoxContainer>
            { mode === 'COMPANY' ? (
                <IconContainer>
                    <Icon
                        src={companyIcon}
                        alt="ToolBeltData Company Icon"
                        fluid
                    />
                </IconContainer>
            ) : (
                <IconContactContainer>
                    <IconContact
                        src={contactIcon}
                        alt="ToolBeltData Contract Icon"
                        fluid
                    />
                </IconContactContainer>
            )}
        </IconBoxContainer>
    )
}
