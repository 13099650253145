/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React, {useState} from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import {
    Form, Row, Col, Button,
} from 'react-bootstrap';
import FormContainer from "../../common/components/layout/FormContainer";
import CheckBox from "../../common/components/CheckBox";
import styled from "styled-components";

import {
    setSearchLocationOption, setGeoCoderLocationData, postContractorsFtsSearch
} from "./contractorDirectorySlice";


import './ContractorDirectorySearchOptionsForm.scss';
import {useDispatch, useSelector} from "react-redux";

const SearchOptionsFormContainer = styled.div `
  margin: 8px 0 8px 15px;
`;

const HeaderDivider = styled.div `
    height: 1px;
    border-top: 1px solid #2d5d7e;
    margin-right: 10px;
`;

const SearchOptionsPreLikeText = styled.div `
  margin-top: 5px;
  display: block;
  white-space: pre-wrap;
  unicode-bidi: embed;
  font-family: monospace;
  color: #212529;
  font-size: 87.5%;
`;

export default function ContractorDirectorySearchOptionsForm(props) {
    // const {} = props;

    const contractorDirectoryState = useSelector(state => state.contractorDirectory);
    const dispatch = useDispatch();
    const [activeSearchLocationOption, setActiveSearchLocationOption] = useState(contractorDirectoryState.searchLocationOption);
    const [selectedGeocoderLocationData, setSelectedGeocoderLocationData] = useState(contractorDirectoryState.geocoderLocationData);

    const geocoderSelect = (selected, lat, lng, text) => {
        // console.log(selected, lat, lng, text);
        setSelectedGeocoderLocationData({
            location: selected,
            latitude: lat,
            longitude: lng
        })
    }

    const handleUpdate = () => {
        if (contractorDirectoryState.searchLocationOption !== activeSearchLocationOption) {
            if (activeSearchLocationOption === 'USE_IP') dispatch(setSearchLocationOption(activeSearchLocationOption));
            if (activeSearchLocationOption === 'USE_LOCATION' && selectedGeocoderLocationData) dispatch(setSearchLocationOption(activeSearchLocationOption));
        }
        if (selectedGeocoderLocationData && !contractorDirectoryState.geocoderLocationData) {
            dispatch(setGeoCoderLocationData(selectedGeocoderLocationData));
            dispatch(postContractorsFtsSearch());
        } else if (selectedGeocoderLocationData && selectedGeocoderLocationData.location !== contractorDirectoryState.geocoderLocationData.location) {
            dispatch(setGeoCoderLocationData(selectedGeocoderLocationData));
            dispatch(postContractorsFtsSearch());
        }
    };

    return (
        <SearchOptionsFormContainer>
            <FormContainer>
                <Form noValidate>
                    <Form.Group controlId="createOrganization">
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <h5>Search Options</h5>
                                <HeaderDivider />
                                <SearchOptionsPreLikeText>
                                    Search is constrained by geography. By default we use the browsers IP address to geo-locate the user and search
                                    within a {contractorDirectoryState.searchWithinMiles} mile radius of that location. This behaviour can be overridden below.
                                </SearchOptionsPreLikeText>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <Row>
                                    <Col xl={3} lg={3} md={3} sm={3}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <div style={{fontWeight: '600'}}>
                                                    <CheckBox
                                                        label={'Use Browsers IP (Default)'}
                                                        checked={activeSearchLocationOption === 'USE_IP'}
                                                        onChange={value => setActiveSearchLocationOption('USE_IP')}
                                                    />
                                                </div>
                                            </Col>
                                            {
                                                contractorDirectoryState.ipLocationData && (
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <SearchOptionsPreLikeText>
                                                            IP: {contractorDirectoryState.ipLocationData.ip}<br/>
                                                            City: {contractorDirectoryState.ipLocationData.city}<br/>
                                                            Region: {contractorDirectoryState.ipLocationData.region}<br/>
                                                            Latitude: {contractorDirectoryState.ipLocationData.latitude}<br/>
                                                            Longitude: {contractorDirectoryState.ipLocationData.longitude}<br/>
                                                        </SearchOptionsPreLikeText>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Col>
                                    <Col xl={3} lg={3} md={3} sm={3}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <div style={{fontWeight: '600'}}>
                                                    <CheckBox
                                                        label={'Select the Location'}
                                                        checked={activeSearchLocationOption === 'USE_LOCATION'}
                                                        onChange={value => setActiveSearchLocationOption('USE_LOCATION')}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <MapboxAutocomplete
                                                    publicKey='pk.eyJ1Ijoib3BzLWF0LWdldHRvb2xiZWx0LWRvdC1jb20iLCJhIjoiY2tqYWVlazdqN2FoajJ2cGRyaDM0b2w5dSJ9.0ghFxBToWVY2tiKhPDrs6w'
                                                    inputClass='form-control'
                                                    onSuggestionSelect={geocoderSelect}
                                                    country='us'
                                                    resetSearch={false}
                                                />
                                            </Col>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                {
                                                    selectedGeocoderLocationData && (
                                                        <Col xl={12} lg={12} md={12} sm={12}>
                                                            <SearchOptionsPreLikeText>
                                                                Location: {selectedGeocoderLocationData.location}<br/>
                                                                Latitude: {selectedGeocoderLocationData.latitude}<br/>
                                                                Longitude: {selectedGeocoderLocationData.longitude}<br/>
                                                            </SearchOptionsPreLikeText>
                                                        </Col>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={10} lg={10} md={10} sm={10} className="buttons">
                                <Button
                                    className="btn-min-width-100 btn-height-35"
                                    variant="info"
                                    onClick={() => handleUpdate()}
                                >UPDATE
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </FormContainer>
        </SearchOptionsFormContainer>
    );
};
