import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import { isGuid } from '../../common/utils/Utils';

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedMatchTypes: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedListMatches: [],
    managedListMatch: null,
    download: undefined,
    managedListMatchesStatus: undefined
};

export const getManagedListMatches = createAsyncThunk('managedListMatches/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-matches`;
    const managedListMatches = await Api.get(true, GET_URL, buildQuery(getState().managedListMatches.currentPage, getState().managedListMatches.pageSize,
        getState().managedListMatches.order.by, getState().managedListMatches.order.direction, getState().managedListMatches.searchCriteria));
    return managedListMatches;
});

export const getManagedListMatchesWithFilter = createAsyncThunk('managedListMatches/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-matches`;
    const managedListMatches = await Api.get(true, GET_URL, filter);
    if (managedListMatches && managedListMatches.length > 0) {
        const uniqListIds = _.uniq(_.map(managedListMatches, listMatch => listMatch.listId));
        const lists = await Api.get(true, `${config.services.ATLAS.apiUrl}/managed-lists`, {filter: {where: {id: {inq: uniqListIds}}}});
        return _.map(managedListMatches, listMatch => {
            const list = lists.find(li => li.id === listMatch.listId);
            return ({
                ...listMatch,
                list: {
                    ...list
                }
            })
        });
    }
    return managedListMatches;
});

export const checkMatchStatus = createAsyncThunk('managedListMatches/checkp-match-status', async (matchId) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-matches/${matchId}/check`;
    const matchingStatus = await Api.post(true, GET_URL);
    return matchingStatus;
});

export const getManagedListMatchesCount = createAsyncThunk('managedListMatches/getCount', async (arg, {getState, requestId}) => {
    try {
        const searchCriteria = getState().managedListMatches.searchCriteria ? getState().managedListMatches.searchCriteria : '';
        const where = isGuid(searchCriteria) ?
            {
            where: {
                or: [
                    {
                        id: {
                            eq: searchCriteria
                        }
                    },
                    {
                        organizationId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        listId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        originListId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        originListType: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        matchType: {
                            ilike: `%${searchCriteria}%`
                        }
                    }
                ]
            }
        } : {
                where: {
                    or: [
                        {
                            originListType: {
                                ilike: `%${searchCriteria}%`
                            }
                        },
                        {
                            matchType: {
                                ilike: `%${searchCriteria}%`
                            }
                        }
                    ]
                }
            };
        const COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-list-matches/count`;
        // const managedListMatchesCount = await Api.get(true, COUNT_URL, where);
        const managedListMatchesCount = await Api.get(true, COUNT_URL, {filter: where});
        return managedListMatchesCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = isGuid(searchCriteria) ?
        {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                id: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                organizationId: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                listId: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                originListId: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                originListType: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                matchType: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
            filter: {
                where: {
                    and: [
                        {
                            or: [
                                {
                                    originListType: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                },
                                {
                                    matchType: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                }
                            ]
                        }
                    ]
                },
                order: [`${orderBy} ${orderDirection}`],
                limit: pageSize,
                skip: (currentPage - 1) * pageSize
            }
        };
    return query;
};

const managedListMatchesSlice = createSlice({
    name: 'managedListMatchesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedMatchTypes.splice(state.selectedMatchTypes.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedMatchTypes.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedListMatchState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        }
    },
    extraReducers: {
        [getManagedListMatches.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatches.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListMatches = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatches.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListMatches = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchesWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [checkMatchStatus.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [checkMatchStatus.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListMatchesStatus = action.payload;
            }
            state.alertMessage = undefined;
            state.successMessage = `Current status of the matching process is ${state.managedListMatchesStatus?.disposition}`;
        },
        [checkMatchStatus.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage =  action.error.message;;
            state.successMessage = undefined;
        }
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = managedListMatchesSlice.actions;
export default managedListMatchesSlice.reducer;
