/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from 'luxon';

import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import {getTypeById} from './AccountStatuses';
import {getAccounts, getAccountsCount, setCurrentPage, setOrder, setSelectedId} from "./managedAccountsSlice";

import TableWithPaging from '../../common/components/TableWithPaging';
import TextHoverDisplay from '../../common/components/TextHoverDisplay';

import {APP_COLORS} from "../../common/Constants";
import CheckBox from "../../common/components/CheckBox";
import {getOrganizationsWithFilter} from "../organizations/organizationsSlice";

const AccountsTable = (props) => {
    const dispatch = useDispatch();
    const accountsState = useSelector((state) => state.managedAccounts);
    const organizationState = useSelector((state)  => state.organizations);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getOrganizationsWithFilter({filter:{limit: 50000}}));
        dispatch(getAccountsCount());
        dispatch(getAccounts());
    }, [props.lastReload, dispatch]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // let image = tbdBrainLightningIcon;
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.id,
                                    isChecked: value,
                                    statusId: row.statusId
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>

                }
            },
            {
                id: 3,
                name: 'OrgId',
                property: 'organizationId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.organizationId) {
                        return <TextHoverDisplay
                            truncatedText={row.organizationId.substring(0, 13)}
                            fullText={row.organizationId}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Organization',
                property: 'organization',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.organization) {
                        return <span>{`${row.organization.name}`}</span>
                    }
                    return <span></span>
                }
            },
            {
                id: 5,
                name: 'Owner',
                property: '',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.organization && row.organization.owner && row.organization.owner.id === row.id) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 6,
                name: 'Given Name',
                property: '',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.givenName) {
                        return <span>{`${row.givenName}`}</span>
                    }
                    return <span></span>
                }
            },
            {
                id: 7,
                name: 'Surname',
                property: '',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.surName) {
                        return <span>{`${row.surName}`}</span>
                    }
                    return <span></span>
                }
            },
            {
                id: 8,
                name: 'Email',
                property: 'email',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.emailVerified) {
                        return <span>{row.email} <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /> </span></span>
                    }
                    return <span>{row.email}</span>
                }
            },
            {
                id: 9,
                name: 'Status',
                property: 'statusId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    return <span>{getTypeById(row.statusId)}</span>
                }
            },
            {
                id: 10,
                name: 'Created',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const getOrganization = (organizationId) => {
      return  _.find(organizationState.organizations, (org) => {return (org.id === organizationId)});
    }
    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getAccounts());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    const adjust = (accounts, organizations) => {
        const newAccounts = [];
        _.forEach(accounts, (account) => {
             const ac = {...account};
             ac.organization = getOrganization(account.organizationId);
             newAccounts.push(ac);
        });
        return newAccounts;
    };

    return (
        <div>
            { accountsState.accounts.length > 0 && organizationState.organizations.length  > 0  && ( <TableWithPaging
                            id={'source-table-with-paging'}
                            headers={headers}
                            rows={adjust(accountsState.accounts, organizationState.organizations)}
                            countOfResults={accountsState.totalRecords}
                            resultsPerPage={accountsState.pageSize}
                            currentPage={accountsState.currentPage}
                            onSelectPage={(page) => onSelectPage(page)}
                        />
                )}
        </div>
    )
};


AccountsTable.propTypes = {
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

AccountsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    accounts: []
};
export default AccountsTable;
