/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import {Form} from "react-bootstrap";

import FormLabel from "./FormLabel";

const FormTextArea = (props) => {
    const {
        label,
        rows = 5,
        onChange,
        value
    } = props;

    return (
        <>
            <FormLabel>{label}</FormLabel>
            <Form.Control
                as="textarea"
                rows={rows}
                onChange={(e) => onChange(e.target.value)}
                value={value}
            />
        </>
    )
}

export default FormTextArea;
