/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react'
import {Breadcrumb} from "react-bootstrap";

import PropTypes from 'prop-types';

import './BreadCrumb.scss';

const BreadCrumb = (props) => {
    const {
        items,
    } = props;
    return (
        <Breadcrumb>
            {items.map((item, index) => {
                return (
                    <Breadcrumb.Item href={`${item.href}`} active={item.isActive} key={`bread-crumb-${index}`}>
                        {`${item.label}`}
                    </Breadcrumb.Item >
                );
            })}
        </Breadcrumb>
    );
};

BreadCrumb.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isActive: PropTypes.bool
    })).isRequired,
};

export default BreadCrumb;
