/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import Menu from '../components/Menu';

const Base = props => {
    return (
        <div>
            {props.showMenu && (
                <Container fluid>
                    <Menu menu={props.menu} />
                    {/*<Footer />*/}
                </Container>
            )}
            <Container fluid>
                { props.children }
            </Container>
        </div>

    )
};


Base.propTypes = {
    children: PropTypes.element.isRequired,
    menu: PropTypes.array.isRequired
};

export default Base;
