/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";

import CardContainer from "./CardContainer";
import IconBox from "./IconBox";
import CompanyBox from "./CompanyBox";
import ContactBox from "./ContactBox";
import AttributesBox from "./AttributesBox";
import styled from "styled-components";
import CardActionBox from "./CardActionBox";
import ContactInfoBox from "./ContactInfoBox";
import ChannelsBox from "./ChannelsBox";
import ClassificationBox from "./ClassificationBox";

const ContactCardContainer = styled(CardContainer) `
  grid-template-columns: 100px minmax(20px,250px) minmax(20px,3000px) minmax(100px,400px) minmax(20px,300px) minmax(20px,300px) minmax(160px,400px) 1fr;
`;

export default function ContactCard(props) {
    const {
        mode,
        item
    } = props;

    return (
        <ContactCardContainer>
            <IconBox mode={mode} />
            <ContactBox item={item} label={'Contact'} />
            <ContactInfoBox item={item} />
            <CompanyBox item={item} />
            <ChannelsBox item={item} />
            <ClassificationBox item={item} />
            <AttributesBox item={item} />
            <CardActionBox disabled />
        </ContactCardContainer>
    )
}
