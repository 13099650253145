/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

const OrganizationMenu = [
    {
        name: 'HOME',
        path: '/org/home',
    },
    {
        name: 'EXPLORE',
        path: '/org/explore'
    },
    {
        name: 'LISTS',
        path: '/org/lists'
    },
];
export default OrganizationMenu;
