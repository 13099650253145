/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Row, Col, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import {getManagedIntegrationByIdWithFilter} from "./managedIntegrationSlice";

import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import PanelHeader from "../../common/components/layout/PanelHeader";
import BreadCrumb from '../../common/components/BreadCrumb';

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormJsonInput from "../../common/components/form/FormJsonInput";


const ManagedIntegrationViewAsJSON = (props) => {
    const managedIntegrationState = useSelector(state => state.managedIntegration);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [managedIntegration, setManagedIntegration] = useState(null);

    useEffect( () => {
        if (props.match.params.id){
            dispatch(getManagedIntegrationByIdWithFilter({id: props.match.params.id}));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (managedIntegrationState.managedIntegration) {
            setManagedIntegration(managedIntegrationState.managedIntegration);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Integrations',
                        href: '/admin/integrations',
                        isActive: false
                    },
                    {
                        label: `${managedIntegrationState?.managedIntegration.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'View as JSON',
                        href: '',
                        isActive: true
                    }
                 ]
            )
        }
        }
        , [managedIntegrationState.managedIntegration]);

    useEffect(() => {
        setAlertMessage(managedIntegrationState.alertMessage);
        if (managedIntegrationState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedIntegrationState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(managedIntegrationState.successMessage);
        if (managedIntegrationState.successMessage) {
            setShowSuccess(true);
            history.push('/admin/integrations');
        }else {
            setShowSuccess(false);
        }
    }, [managedIntegrationState.successMessage]);

    const handleCancel = async () => {
        history.push('/admin/integrations')
    };

    return (
        <div>
            {managedIntegrationState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <ViewAsJSON
                id={managedIntegration?.id}
                name={managedIntegration?.name}
                object={managedIntegration}
                handleCancel={() => handleCancel()}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function ViewAsJSON(props) {

    const {
        breadcrumbItems,
        name,
        object,
        handleCancel,
    } = props;

    // Form
    const [json, setJson] = useState('');

    const reset = () => {

    };

    useEffect(() => {
        setJson(JSON.stringify(object, null, '\t'));
    }, [object]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`View as JSON - ${name}`} subHeader={'View integration as JSON'} />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="viewAsJSON">
                            <Row>
                                <Col md={9}>
                                    <FormJsonInput
                                        id={'view-as-json'}
                                        label={'JSON'}
                                        placeholder={object}
                                        onChange={(v) => setJson(v)}
                                        height={'500px'}
                                        width={'100%'}
                                        viewOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >DONE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default ManagedIntegrationViewAsJSON;

