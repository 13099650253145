/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormControl,
} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../../../common/Constants";
import styled from "styled-components";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ClearRelative = styled.div `
  position: relative;
`;

const ClearAbsolute = styled.div `
  position: absolute;
  right: 15px;
  top: -32px;
  cursor: pointer;
  &:hover {
    color: ${APP_COLORS['red']} !important;
  }
`;

const FormTextInput = (props) => {
    const {
        clearable,
        clearableHandle
    } = props;

    return (
        <FormGroup
            style={props.style}
            controlId={props.controlId}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
        >
            <FormControl
                id={props.id}
                value={props.value}               
                size={"md"}
                onChange={(e) => props.onChange(e.target.value)}
                disabled={props.disabled}
                placeholder={props.placeholder}
                type={"text"}
                isInvalid={props.isInvalid}
            />
            {clearable && clearableHandle && (!props.disabled) && (
                <ClearRelative>
                    <ClearAbsolute onClick={() => clearableHandle()}>
                        <FontAwesomeIcon
                            color={APP_COLORS['gray-500']}
                            icon={faTimes}
                            style={{fontSize: 25}}
                        />
                    </ClearAbsolute>
                </ClearRelative>
            )}
        </FormGroup>
    );
};


FormTextInput.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    isInvalid: PropTypes.bool,
    validation: PropTypes.shape({
        state: PropTypes.oneOf(['error', 'warning']),
        message: PropTypes.string,
    }),
    clearable: PropTypes.bool,
    clearableHandle: PropTypes.func,
};

FormTextInput.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    onChange: e => e,
    onBlur: e => e,
    onKeyDown: e => e,
    isInvalid: false,
    validation: {},
    value: '',
    size: 'sm',
    clearable: false,
    clearableHandle: e => e
};

export default FormTextInput;
