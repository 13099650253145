import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedInput: null
};

export const getInputById = createAsyncThunk('inputs/getInputById', async (id) => {
    const GET_MANAGED_INPUT_BY_ID_URL =`${config.services.ATLAS.apiUrl}/managed-inputs/${id}`
    const input = await Api.get(true, GET_MANAGED_INPUT_BY_ID_URL);
    return input;
});

export const editInput = createAsyncThunk('inputs/editInput', async ({id, data}) => {
    const EDIT_MANAGED_INPUT_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/${id}/edit`;
    const input = await Api.post(true, EDIT_MANAGED_INPUT_BY_ID_URL, {data});
    return input;
});

const inputSlice = createSlice({
    name: 'inputSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedInput = null;
            return state;
        }
    },
    extraReducers: {
        [getInputById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getInputById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedInput = action.payload;
            }
        },
        [getInputById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve an input information';
        },

        [editInput.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [editInput.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedInput = action.payload;
            }
            state.successMessage = 'Successfully edit input';
        },
        [editInput.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to edit an input';
        },
    }
});


export const { clearState } = inputSlice.actions;
export default inputSlice.reducer;
