
import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {
    Container,
    Form,
    Row,
    Col,
    Spinner,
    Button,
    Alert,

} from 'react-bootstrap';

import {useDispatch, useSelector} from "react-redux";
import { exportListCriteria, resetExport } from "../lists/listSlice";
import FormTextInput from "../../common/components/FormTextInput";
import FormLabel from "../../common/components/form/FormLabel";
import CheckBox from "../../common/components/CheckBox";

import Modal from '../../common/components/Modal';
import styles from './SaveModal.scss';

const EXPORT_RECORD_LIMIT = 50000;
const ExportModal = (props) => {
    const {
        handleClose,
        title,
        currentFilters,
        appliedFilters,
        exploreMode,
    } = props;
    const dispatch = useDispatch();
    const listDataState = useSelector(state => state.list);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const countResult = exploreMode.mode === 'COMPANY' ? appliedFilters.totalCompanies: appliedFilters.totalContacts;

    const [notifyByEmailOnSuccessOrFailure, setNotifyByEmailOnSuccessOrFailure] = useState('');
    const [notifyByEmailCheckbox, setNotifyByEmailCheckbox] = useState(false);

    useEffect(() => {
    // When open dialog, we clear state
        return () => {
            dispatch(resetExport());
        };
    }, [dispatch]);

    const isValid = () => {
        if (!name) {
            return false;
        }
        if (countResult > EXPORT_RECORD_LIMIT) {
            return false;
        }
        if (notifyByEmailCheckbox) {
            if (!notifyByEmailOnSuccessOrFailure) {
                return false;
            }
        }
        return true;
    };

    const isBusy = () => {
        if (listDataState.loading) {
            return true;
        }
        return false;
    };

    const isError = () => {
        if (listDataState && listDataState.status && listDataState.status.status === 'error') {
            return true;
        }
        return false;
    };

    const onClose = () => {
        handleClose();
    };

    if (listDataState.status.status === 'succeeded') {
        handleClose();
        return (
            <div></div>
        );
    }

    const doExport = async (name, description) => {
        const filters = currentFilters;
        const type = exploreMode.mode === 'COMPANY' ? 'EXPORT_COMPANY': 'EXPORT_CONTACT';
        dispatch(exportListCriteria({
            name,
            type,
            description,
            filters,
            cubeQuery: appliedFilters.cubeQuery,
            notifyByEmailOnSuccessOrFailure: notifyByEmailOnSuccessOrFailure,
        }));
    };

    const handleUpdateNotifyByEmailOnSuccessOrFailure = function(notifyByEmailOnSuccessOrFailure) {
        setNotifyByEmailOnSuccessOrFailure(notifyByEmailOnSuccessOrFailure);
    };

    return (
        <Modal
            showClose
            size={'lg'}
            title={title}
            handleClose={() => onClose()}
            showHeaderLine
            footer={
                (
                    <div className={styles.footer}>

                        <Button
                            variant='info'
                            disabled={isBusy() || !isValid()}
                            onClick={() => doExport(name, description)}
                        >
                            {!isBusy() && <>Create</>}
                            {isBusy() && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                        <Button
                            disabled={isBusy()}
                            variant='outline-secondary'
                            onClick={() => onClose()}
                            style={{marginLeft: '10px'}}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            <Container className={styles.container}>
                <Form noValidate>
                    <Form.Group id="exportList">
                        <Row>
                            <Col xs={12}>
                                {countResult > EXPORT_RECORD_LIMIT &&
                                    <Alert
                                        variant={'danger'}
                                        transition={true}
                                    >
                                        There is a 50,000 record limit on Export. Please add additional filters to reduce the count or contact your Account Representative.
                                    </Alert>
                                }
                                {isError() &&
                                    <Alert
                                        variant={'danger'}
                                        transition={true}
                                    >
                                        {
                                            listDataState.status.error ?
                                                `Unable to export list, there was an error [${listDataState.status.error}]. Please contact your administrator.`
                                                : 'Unable to export list, there was an error. Please contact your administrator.'
                                        }


                                    </Alert>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col/>
                            <Col xs={2}>
                                <Form.Label
                                    id={'form-control-name-label-id'}
                                >
                                    Name
                                </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control
                                    id={'form-control-name-input-id'}
                                    size="md"
                                    type="input"
                                    name="name"
                                    placeholder="Please enter name."
                                    isInvalid={
                                        !name
                                    }
                                    onChange={(e) => setName(e.target.value)}
                                    data-validate='["required"]'
                                    value={name}
                                />
                                {!name && (
                                    <Form.Control.Feedback
                                        id={'form-control-feed-back-id'}
                                        type="invalid"
                                    >
                                        Name is required.
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col/>
                        </Row>
                        <Row>
                            <Col/>
                            <Col xs={2}>
                                <FormLabel>Description</FormLabel>
                            </Col>
                            <Col xs={8}>
                                <Form.Control
                                    id={'form-control-desc-text-area-id'}
                                    as={'textarea'}
                                    rows={5}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Col>
                            <Col/>
                        </Row>
                        <Row>
                            <Col/>
                            <Col xs={2}>
                                <FormLabel>Options</FormLabel>
                            </Col>
                            <Col xs={8}>
                                <CheckBox
                                    label={'Notify me when my export completes'}
                                    checked={notifyByEmailCheckbox}
                                    onChange={(e) => {setNotifyByEmailCheckbox(e); setNotifyByEmailOnSuccessOrFailure('')}}
                                />
                                <FormTextInput
                                    placeholder={'Multiple emails can be provided, separate with semi-colon'}
                                    className={'input'}
                                    size={'sm'}
                                    value={notifyByEmailOnSuccessOrFailure}
                                    onChange={(e) => {handleUpdateNotifyByEmailOnSuccessOrFailure(e)}}
                                    disabled={!notifyByEmailCheckbox}
                                >
                                </FormTextInput>
                            </Col>
                            <Col/>
                        </Row>
                    </Form.Group>
                </Form>
            </Container>
        </Modal>
    );
};

ExportModal.propTypes = {
    title: PropTypes.string,
    exploreMode: PropTypes.object.isRequired,
    currentFilters: PropTypes.array.isRequired,
    handleClose: PropTypes.func,
};

ExportModal.defaultProps = {
    handleClose: (e) => e
};

export default ExportModal;
