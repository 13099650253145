/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormLabel,
} from 'react-bootstrap';

import NumericInput from 'react-numeric-input';
import FormRequiredText from "./form/FormRequiredText";

const FormNumericInput = (props) => {
    return (
        <FormGroup
            style={props.style}
            controlId={props.controlId}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
        >
            {props.label &&
                <FormLabel className={"formTextLabel"}>
                    {props.label}
                    {props.required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            }

            <NumericInput
                className="form-control"
                id={props.id}
                value={props.value}
                min={props.min}
                max={props.max}
                onChange={(v) => props.onChange(v)}
                disabled={props.disabled}
                placeholder={props.placeholder}
                step={props.step || 0.1}
                isInvalid={props.isInvalid}
            />
        </FormGroup>
    );
};


FormNumericInput.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    isInvalid: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    validation: PropTypes.shape({
        state: PropTypes.oneOf(['error', 'warning']),
        message: PropTypes.string,
    }),
};

FormNumericInput.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    onChange: e => e,
    onBlur: e => e,
    onKeyDown: e => e,
    isInvalid: false,
    validation: {},
    value: '',
    size: 'sm',
    min: 0,
    max: 1
};

export default FormNumericInput;
