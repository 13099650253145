import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import config from '../../config'
import * as Api from '../../common/Api';

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedIntegration: null,
};

export const getManagedIntegrationById = createAsyncThunk('integrations/getIntegrationById', async (id) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-integrations/${id}`;
    return await Api.get(true, URL);
});

export const getManagedIntegrationByIdWithFilter = createAsyncThunk('integrations/getIntegrationByIdWithFilter', async ({id, filter}) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-integrations/${id}`;
    return await Api.get(true, URL, filter);
});

const managedIntegrationSlice = createSlice({
    name: 'managedIntegrationSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedIntegration = null;
            return state;
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedIntegrationById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getManagedIntegrationById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedIntegration = action.payload;
            }
        },
        [getManagedIntegrationById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve integration information';
        },
        [getManagedIntegrationByIdWithFilter.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getManagedIntegrationByIdWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedIntegration = action.payload;
            }
        },
        [getManagedIntegrationByIdWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve integration information';
        }
    }
});

export const { clearState } = managedIntegrationSlice.actions;
export default managedIntegrationSlice.reducer;
