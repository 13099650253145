import React, { useState} from 'react';
import '../ManagedCompanies.scss'
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import {APP_COLORS} from "../../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const ColumnCollapse = (props) => {
    const { 
        isOpen=false,        
    } = props;
    const [open, setOpen] = useState(isOpen);

    return (
        <div style={{width: '100%', padding: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between',backgroundColor: '#f8f9fa',fontWeight:'bold'}}>
                <span style={{width: '100%', paddingTop: '6px'}}>{props.header}</span>               
                    <Button 
                        variant={'link'}
                        onClick={() => setOpen(!open)}
                    >
                        {open ?
                            <FontAwesomeIcon
                                style={{marginLeft: '10px'}}
                                size="xs"
                                color={APP_COLORS.primary}
                                icon={faMinus}
                            />
                            :
                            <FontAwesomeIcon
                                style={{marginLeft: '10px'}}
                                size="xs"
                                color={APP_COLORS.primary}
                                icon={faPlus}
                            />
                        }
                    </Button>                
            </div>
            <Collapse in={open}>
                <div>
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
}

export default ColumnCollapse;