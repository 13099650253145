/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Integrations extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    render() {
        return (
            <Row noGutters>
                <Col xl={12} lg={12} md={12} sm={12} className="text-center text-muted">
                    <h2 className="mt-5">Integrations</h2><br/>
                    <p><em>This page is currently under construction. More updates coming soon!</em></p>
                </Col>
            </Row>
        )
    }
}
