/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import {clearState, enableAccount} from "./managedAccountSlice";
import {getAccountById} from "./managedAccountSlice";

import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import FormTextInput from "../../common/components/FormTextInput";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";

const EnableAccountContainer = (props) => {
    return (
        <EnableAccount id={props.match.params.id}/>
    )
}

function EnableAccount(props) {
    const {id} = props;
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Accounts',
        href: '/admin/accounts',
        isActive: false
    }, {label: `${id}`, href: '', isActive: true}, {label: 'Enable Account', href: '', isActive: true}];

    const accountState = useSelector(state => state.managedAccount);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getAccountById(id));
        }
    }, [id]);
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // When open dialog, we clear state.
        if (accountState.successMessage) {
            setSuccessMessage(accountState.successMessage);
            setShowSuccess(true);
        }
    }, [accountState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (accountState.alertMessage) {
            setAlertMessage(accountState.alertMessage);
            setShowAlert(true);
        }
    }, [accountState.alertMessage]);

    const handleEnableAccount = async () => {
        dispatch(enableAccount(id));
    };

    const reset = () => {
        dispatch(clearState());
    }

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`Enable Account ${id}`} subHeader={'Enable the account'} />
            <Panel>
                {accountState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="enableAccount">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={9}>
                                            <FormLabel>Account ID</FormLabel>
                                            <TextHoverDisplay
                                                truncatedText={id}
                                                fullText={id}
                                                displayTruncated={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <FormTextInput
                                                label={'Email'}
                                                placeholder="Enter email"
                                                value={accountState?.managedAccount?.email}
                                                size={'sm'}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9}>
                                            <FormTextInput
                                                label={'Status'}
                                                value={accountState?.managedAccount?.statusId}
                                                size={'sm'}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEnableAccount()}
                                        disabled={accountState?.managedAccount?.statusId === 'ACTIVE'}
                                    >
                                        ENABLE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            history.push('/admin/accounts')
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}
export default EnableAccountContainer;
