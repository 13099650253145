


export const icons = [
    {icon: 'address-book'},
    {icon: 'ambulance'},
    {icon: 'location-arrow'},
    {icon: 'thumbtack'},
    {icon: 'map-marker'},
    {icon: 'map-marker-alt'},
    {icon: 'map-pin'},
    {icon: 'exclamation'},
    {icon: 'hammer'},
    {icon: 'hard-hat'},
    {icon: 'paint-roller'},
    {icon: 'toolbox'},
];
