/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars

import Organizations from './Organizations';

const OrganizationsContainer = (props) => {

    return(
        <Organizations />
    )
};

export default OrganizationsContainer;
