import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedOrganization: null,
};


export const getOrganizationById = createAsyncThunk('organizations/getOrganizationById', async (id) => {
    const GET_MANAGED_ORGANIZATION_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-organizations/${id}`;
    const organization = await Api.get(true, GET_MANAGED_ORGANIZATION_BY_ID_URL);
    return organization;
});


export const editOrganization = createAsyncThunk('organizations/editOrganization', async ({id, data}) => {
    const GET_MANAGED_ORGANIZATION_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-organizations/${id}/edit`;
    const organization = await Api.post(true, GET_MANAGED_ORGANIZATION_BY_ID_URL, {data});
    return organization;
});



const managedOrganizationSlice = createSlice({
    name: 'managedOrganizationSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedOrganization = null;
            return state;
        }
    },
    extraReducers: {
        [getOrganizationById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getOrganizationById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedOrganization = action.payload;
            }
        },
        [getOrganizationById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve an organization information';
        },

        [editOrganization.pending]: (state, action) => {
            state.loading = true;
        },
        [editOrganization.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedOrganization = action.payload;
            }
            state.successMessage = 'Successfully edit an organization';
        },
        [editOrganization.rejected]: (state, action) => {
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                state.alertMessage = 'Unable to edit an organization';
            }

        },
    }
});

export const { clearState } = managedOrganizationSlice.actions;
export default managedOrganizationSlice.reducer;
