/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import _, {find} from 'lodash';

import {Row, Col, Button, Form} from 'react-bootstrap';

import BreadCrumb from '../../common/components/BreadCrumb';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import {
    getAccountById,
    editAccount,
    clearState,
} from "./managedAccountSlice";

import Alert from 'react-bootstrap/Alert';

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";

import PanelHeader from "../../common/components/layout/PanelHeader";
import FormTextInput from "../../common/components/FormTextInput";
import FormSelect from "../../common/components/form/FormSelect";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

const EditAccountContainer = (props) => {
    const managedAccountState = useSelector(state => state.managedAccount);
    const organizationState = useSelector((state)  => state.organizations);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    // Form
    const [managedAccount, setManagedAccount] = useState(null);

    useEffect(() => {
        if (props.match.params.id){
            dispatch(getAccountById(props.match.params.id));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (managedAccountState.managedAccount) {
            setManagedAccount(managedAccountState.managedAccount);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Accounts',
                        href: '/admin/accounts',
                        isActive: false
                    },
                    {
                        label: `${managedAccountState?.managedAccount.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: `Edit Account`,
                        href: '',
                        isActive: true
                    }
                 ]
            )
        }
    }, [managedAccountState.managedAccount]);

    useEffect(() => {
        setAlertMessage(managedAccountState.alertMessage);
        if (managedAccountState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedAccountState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(managedAccountState.successMessage);
        if (managedAccountState.successMessage) {
            setShowSuccess(true);
        }else {
            setShowSuccess(false);
        }
    }, [managedAccountState.successMessage]);

    const handleEditAccount = async (data) => {
        dispatch(editAccount({id:props.match.params.id, data:data}));
    };

    const handleCancel = async () => {
        dispatch(clearState());
        history.push('/admin/accounts')
    };

    const getOrganizationTypeId = (organizationId) => {
        if (!organizationId) return;
        const org =  _.find(organizationState.organizations, (org) => {return (org.id === organizationId)});
        return org.typeId;
    }

    return (
        <div>
            {managedAccountState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <EditAccount
                id={managedAccount?.id}
                handleEditAccount={(data) => handleEditAccount(data)}
                handleCancel={() => handleCancel()}
                givenName={managedAccount?.givenName}
                surName={managedAccount?.surName}
                email={managedAccount?.email}
                roles={managedAccount?.roles}
                breadcrumbItems={breadcrumbItems}
                orgTypeId={getOrganizationTypeId(managedAccount?.organizationId)}
            />
        </div>
    );
};


function EditAccount(props) {

    const {
        breadcrumbItems,
        givenName,
        id,
        email,
        surName,
        roles,
        handleEditAccount,
        handleCancel,
        orgTypeId,
    } = props;

    const ROLES = [
        {value: 'OWNER', label: 'Owner'},
        {value: 'ADMINISTRATOR', label: 'Administrator'},
        {value: 'DEVELOPER', label: 'Developer'},
        {value: 'USER', label: 'User'},
    ];

    const INTERNAL_ROLES = [
        {value: 'ADMINISTRATOR', label: 'Administrator'},
    ];

    const [formValid, setFormValid] = useState(true);
    const [givenNameState, setGivenNameState] = useState('');
    const [surNameState, setSurNameState] = useState('');
    const [emailState, setEmailState] = useState('');
    const [roleState, setRoleState] = useState(null);

    const reset = () => {
        setGivenNameState('');
        setSurNameState('');
        setEmailState('');
        setRoleState(null);

    };

    const isValid = () => {
        return !(!givenNameState ||
            givenNameState.length < 1 ||
            !surNameState ||
            surNameState.length < 1);
    };

    const handleEditAccountClick = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            const data = {
                givenName: givenNameState,
                surName: surNameState,
                roles: [roleState ? roleState.value : undefined],
            };
            handleEditAccount(data);
        }
    };

    useEffect(() => {
        setGivenNameState(givenName);
        setSurNameState(surName);
        setEmailState(email);
        if (roles && roles.length > 0) {
            const existingRole = find(orgTypeId && orgTypeId === 'INTERNAL' ? INTERNAL_ROLES : ROLES,
                    r => r.value === roles[0]);
            setRoleState(existingRole);
        }

    }, [id, roles]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Edit Account'} subHeader={'Edit (change) the account'} />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="editAccount">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={9}>
                                            <FormLabel>Account ID</FormLabel>
                                            {id &&
                                                <TextHoverDisplay
                                                    fullText={id}
                                                    truncatedText={id}
                                                    displayTruncated={false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Given Name'}
                                                value={givenNameState}
                                                onChange={value => setGivenNameState(value)}
                                                isInvalid={!formValid && !givenNameState}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Surname'}
                                                value={surNameState}
                                                onChange={value => setSurNameState(value)}
                                                isInvalid={!formValid && !surNameState}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormTextInput
                                                label={'Email'}
                                                placeholder="Enter email"
                                                value={emailState}
                                                size={'sm'}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormSelect
                                                label={'Role'}
                                                options={orgTypeId && orgTypeId === 'INTERNAL' ? INTERNAL_ROLES : ROLES}
                                                onChange={(v) => setRoleState(v)}
                                                value={roleState}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEditAccountClick()}
                                    >EDIT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default EditAccountContainer;

