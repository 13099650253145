/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';

import './RadioGroup.scss';
import {APP_COLORS} from "../Constants";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/pro-solid-svg-icons";

const RadioGroup = (props) => {
    const {
        id,
        name,
        onChange,
        value,
        items,
        radioButton,
        showLockIcon,
    } = props;

    return (
        <div className={ radioButton ? "button-group" : "radio-group"}>
            { items.map(item => {
                return (
                    <label key={item.value}>
                        <input
                            id={`${id}-${value}`}
                            type="radio"
                            checked={value === item.value}
                            disabled={item.disabled}
                            value={item.value}
                            name={name}
                            onChange={(e) => onChange(e.target.value)}
                        />
                        <span>
                            {item.label}
                            {(showLockIcon && item.disabled) && (
                                <div className={"icon-lock"}>
                                    <FontAwesomeIcon
                                        color={APP_COLORS['yellow']}
                                        icon={faLock}
                                        style={{marginRight: '10px'}}
                                    />
                                </div>
                            )}
                        </span>
                    </label>
                );
            })}
        </div>
    );
}

RadioGroup.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.object.isRequired,
        checked: PropTypes.bool,
        disabled: PropTypes.bool
    })).isRequired,
    onChange: PropTypes.func,
    radioButton: PropTypes.bool
};

export default RadioGroup;
