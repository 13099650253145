import React, {useEffect, useState} from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

import {useDispatch, useSelector} from "react-redux";
import {APP_COLORS} from "../../../common/Constants";
import {updateNotifyByEmailOnSuccessOrFailure} from "./uploadSlice";

import Alert from "react-bootstrap/Alert";
import CheckBox from "../../../common/components/CheckBox";
import FormTextInput from "../../../common/components/FormTextInput";
import isEmail from "validator/es/lib/isEmail";
import _, {isEmpty} from "lodash";

function Review(props) {
    const dispatch = useDispatch();
    const uploadMetaState = useSelector(state => state.upload);
    const [notifyByEmailCheckbox, setNotifyByEmailCheckbox] = useState(true);
    const {account} = useSelector(state => state.account);
    useEffect(() => {
        dispatch(updateNotifyByEmailOnSuccessOrFailure(account.email));
    }, []);

    const isNotificationEmailValid = () => {
        if (notifyByEmailCheckbox === true && isEmpty(uploadMetaState.notifyByEmailOnSuccessOrFailure)) {
            return false;
        } else if (notifyByEmailCheckbox === true && !isEmpty(uploadMetaState.notifyByEmailOnSuccessOrFailure)) {
            let isValidEmail = true;
            const emails = _.split(uploadMetaState.notifyByEmailOnSuccessOrFailure, ';');
            if (emails) {
                emails.map(async (email) => {
                    const _isValidEmail = isEmail(_.trim(email));
                    if (!_isValidEmail) {
                        isValidEmail = false;
                    }
                });
                return isValidEmail;
            }
        } else {
            return true;
        }
    };


    return (
        <div className={'upload-review-container'}>
            {!uploadMetaState.importing && !uploadMetaState.importStatus && (
                <div className={'upload-review'}>
                    {/*<div style={{justifySelf: 'center'}}>*/}
                    {/*    Review the details of your new list below. If everything looks correct select*/}
                    {/*    <span style={{color: APP_COLORS.info}}> Import</span>*/}
                    {/*</div>*/}
                    <div>
                        Import a new list of <span style={{color: APP_COLORS.info, fontWeight: "bold"}}>
                        {uploadMetaState.listType === 'IMPORT_COMPANY' && ' Companies'}
                        {uploadMetaState.listType === 'IMPORT_OVERLAY' && ' Overlays'}
                        {uploadMetaState.listType === 'IMPORT_CONTACT' && ' Contacts'} </span> called:
                    </div>
                    <div style={{justifySelf: 'center'}}>
                        {uploadMetaState.listName}
                    </div>
                    <div>
                        <div>
                            Options
                        </div>
                        <CheckBox
                            label={'Notify me when my import completes'}
                            checked={notifyByEmailCheckbox}
                            onChange={(e) => {
                                setNotifyByEmailCheckbox(e);
                                dispatch(updateNotifyByEmailOnSuccessOrFailure(''))
                            }}
                        />
                        <FormTextInput
                            placeholder={'Multiple emails can be provided, separate with semi-colon'}
                            className={'input'}
                            size={'sm'}
                            value={uploadMetaState.notifyByEmailOnSuccessOrFailure}
                            onChange={(e) => {
                                dispatch(updateNotifyByEmailOnSuccessOrFailure(e));
                            }}
                            disabled={!notifyByEmailCheckbox}
                            isInvalid={!isNotificationEmailValid()}
                            validation={{
                                state: !isNotificationEmailValid() === true ? 'error' : undefined,
                                message: 'Invalid Email syntax. If you try to put multiple emails, please use ; to separate your inputs.'
                            }}>
                        </FormTextInput>
                    </div>
                </div>
            )}
            {(uploadMetaState.importing || uploadMetaState.importStatus === 'SUCCESS' || uploadMetaState.importStatus === 'FAIL') && (
                <>
                    <Row>
                        {uploadMetaState.inputSource === 'FILE_UPLOAD' &&
                            <Col sm={"auto"} md={"auto"}> Uploading File: <span
                                style={{color: APP_COLORS.info}}>{uploadMetaState.fileUrl}</span></Col>
                        }
                    </Row><br/>
                    <Row>
                        <Col sm={"auto"} md={"auto"}> Creating List: <span
                            style={{color: APP_COLORS.info}}>{uploadMetaState.listName} </span></Col>
                    </Row>
                </>
            )}
            {!uploadMetaState.importing && uploadMetaState.importStatus &&
                <Alert
                    variant={uploadMetaState.importStatus === 'SUCCESS' ? 'success' : 'danger'}
                    show={true}
                    dismissible
                    transition={false}
                >
                    {uploadMetaState.importStatus === 'SUCCESS' ? `Your list - ${uploadMetaState.listName} - was successfully created` : `Encountered problem to create your list - ${uploadMetaState.listName}.`}
                </Alert>
            }
        </div>
    );
}

export default Review;
