/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";

import React from "react";

import {Button} from "react-bootstrap";

const CardActionContainer = styled.div `
  justify-self: end;
  align-self: center;
  padding-right: 20px;
`;

export default function ContractorDirectorySearchResultActionBox(props) {
    const {
        item,
        handleOnContact
    } = props;

    return (
        <CardActionContainer>
            <Button
                className="btn-min-width-100 btn-height-35"
                variant="info"
                onClick={() => handleOnContact(item)}
            >CONTACT
            </Button>
        </CardActionContainer>
    )
}

