/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import AdminListsContainer from "./AdminListsContainer";
import AdminImportListContainer from "./AdminImportListContainer";
import AdminListProcessContainer from "./AdminListProcessContainer";
import AdminListParseContainer from "./AdminListParseContainer";
import AdminListNormalizeContainer from "./AdminListNormalizeContainer";
import AdminListMatchContainer from "./AdminListMatchContainer";
import AdminListSyncContainer from "./AdminListSyncContainer";
import AdminListReSyncContainer from './AdminListReSyncContainer'
import AdminEditListContainer from "./AdminEditListContainer";
import AdminDownloadListContainer from './AdminDownloadListContainer';
import AdminViewAsJSONContainer from "./AdminViewAsJSONContainer";
import AdminCreateListMatchReportContainer from "./AdminCreateListMatchReportContainer";
import AdminListMatchCheckContainer from "./AdminListMatchCheckContainer";

export default class AdminListsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/lists/import" exact component={AdminImportListContainer} />
                <Route path="/admin/lists/:id/process" exact component={AdminListProcessContainer}/>
                <Route path="/admin/lists/:id/parse" exact component={AdminListParseContainer}/>
                <Route path="/admin/lists/:id/normalize" exact component={AdminListNormalizeContainer}/>
                <Route path="/admin/lists/:id/re-sync" exact component={AdminListReSyncContainer}/>
                <Route path="/admin/lists/:id/sync" exact component={AdminListSyncContainer}/>
                <Route path="/admin/lists/:id/edit" exact component={AdminEditListContainer}/>
                <Route path="/admin/lists/:id/download" component={AdminDownloadListContainer}/>
                <Route path="/admin/lists/:id/match" component={AdminListMatchContainer}/>
                <Route path="/admin/lists/:id/match-check" component={AdminListMatchCheckContainer}/>
                <Route path="/admin/lists/:id/view-as-json" component={AdminViewAsJSONContainer}/>
                <Route path="/admin/lists/:id/create-list-match-report" component={AdminCreateListMatchReportContainer}/>
                <Route path="/admin/lists" component={AdminListsContainer} />
            </Switch>
        );
    }
}
