import React, { useEffect, useState} from 'react';
import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import './ManagedCompanies.scss'
import Panel from '../../common/components/layout/Panel';

import { getCompanyById } from './managedCompanySlice';
import { getManagedContactsWithFilter,setCurrentPage,setSearchCriteria,getManagedContactsCount,setPageSize } from '../contacts/managedContactsSlice'
import SpinnerOverlay from '../../common/components/SpinnerOverlay';
import FormContainer from '../../common/components/layout/FormContainer';
import { Row, Col, Form } from 'react-bootstrap';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/pro-solid-svg-icons';

import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import CheckBox from '../../common/components/CheckBox';
import AttributesBox from '../../organization/explore/components/cards/AttributesBox';
import CompanyChannelsBox from './components/entity-view-boxes/CompanyChannelsBox';
import ContactChannelsBox from './components/entity-view-boxes/ContactChannelsBox';
import CompanyProductsBox from './components/entity-view-boxes/CompanyProductsBox';
import CardLabel from '../../organization/explore/components/cards/CardLabel';
import CompanyManufacturersBox from './components/entity-view-boxes/CompanyManufacturersBox';
import CompanyAffiliationsBox from './components/entity-view-boxes/CompanyAffiliationsBox';
import CompanyOnlineProfilesBox from './components/entity-view-boxes/CompanyOnlineProfilesBox';
import CompanyClassificationBox from './components/entity-view-boxes/CompanyClassificationsBox';
import CoreAttributes from './components/collapse-sections/core-attributes/CoreAttributes';
import SectionCollapse from './components/SectionCollapse';
import { LeadText, SubText, CheckBoxContainer } from './components/StyledComponents';
import styled from "styled-components";
const LoadMoreRowsClassname = styled.div `
  width:300px!important;
  inline-size: 180px;
  padding-block: 8px;
  padding-inline: 16px;
  position: absolute;
  inset-block-end: 8px;
  inset-inline-end: 8px;
  color: white;
  line-height: 35px;
  background: rgb(0 0 0 / 0.6);
`;
const EditCompany = (props) => {
    return <EditCompanyComponent id={props.id} selectedContactId={props.selectedContactId} />
}
// Component for company or contact headers and the score
const SectionHeaderWithScore = (props) => {
    const {
        label,
        score,
        id,
        identity
    } = props;

    return (
        <div id={`${label}-section-header-with-score`}>
            <LeadText style={{fontSize: '115%', marginBottom: '5px'}}>{label} <span id={`score`} style={{fontSize: '75%', fontWeight:200, fontStyle: 'italic'}}>{score}</span></LeadText>
            {id && identity && <div style={{ marginLeft: '15px' }}>
                <SubText>
                    <Row>
                        Id:
                        <div id='id-container' style={{ marginLeft: '5px', marginRight: '5px' }}>
                            <TextHoverDisplay
                                truncatedText={id.substring(0, 13)}
                                fullText={id}
                            />
                        </div>
                        / Identity:
                        <div id='identity-container' style={{ marginLeft: '5px', marginRight: '5px' }}>
                            <TextHoverDisplay
                                truncatedText={identity.substring(0, 13)}
                                fullText={identity}
                            />
                        </div>
                    </Row>
                </SubText>
            </div>}
        </div>
    );
}

const EditCompanyComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Companies',
        href: '/admin/d/companies',
        isActive: false
    }, {
        label: props.id,
        href: '',
        isActive: true
    }, {
        label: 'Edit Company',
        href: '',
        isActive: true
    }];

    const managedCompanyState = useSelector(state => state.managedCompany);
    const managedContactsState = useSelector(state => state.managedContacts);

    const dispatch = useDispatch();
    const history = useHistory();
    
    const [managedCompany, setManagedCompany] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    
    
    // Contact table vars
    
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowToast, setIsShowToast] = useState(false);
    const colWidth = 100;
    const columns = [
        { key: 'selectContact', name: '' },
        { key: 'contactId', name: 'Id', width: colWidth, resizable: true },
        { key: 'contactIdentity', name: 'Identity', width: colWidth, resizable: true },
        { key: 'contactScore', name: 'Score', width: colWidth },
        { key: 'contactNameGivenName', name: 'Given Name', width: colWidth, resizable: true },
        { key: 'contactNameSurname', name: 'Surname', width: colWidth, resizable: true },
        { key: 'contactNameMaturitySuffix', name: 'Suffix', width: colWidth, resizable: true },
        { key: 'contactTitle', name: 'Title', width: colWidth, resizable: true },
        { key: 'contactPhone', name: 'Phone', width: colWidth, resizable: true },
        { key: 'contactSourceFirstTouch', name: 'First Touch', width: colWidth, resizable: true },
        { key: 'contactSourceLastTouch', name: 'Last Touch', width: colWidth, resizable: true },
        { key: 'contactEmail', name: 'Email', width: colWidth, resizable: true }
    ];
    function rowKeyGetter(row) {
        return row.id;
      }
      function isAtBottom({ currentTarget }){
        return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
      }
      
      async function handleScroll(event) {
    
        if (!isAtBottom(event)) return;
        if(managedContactsState.pageSize == managedContactsState.totalRecords || managedContactsState.pageSize>=100)
        {
            return;
        }
        debugger;
    
        const getContacts = () => {
            if (managedCompany == null) {
                return;
            }
            if(managedContactsState.pageSize >= managedContactsState.totalRecords)
            {
                return;
            }else{
                dispatch(setCurrentPage((managedContactsState.pageSize /20) +1));
            }
            setIsShowToast(true);
            const filter = {
                filter: {
                    where: {
                        companyId: {
                            eq: `${managedCompany.companyId}`
                        }
                    },
                    limit: managedContactsState.pageSize,
                    skip: (managedContactsState.currentPage - 1) * managedContactsState.pageSize
                }
            };
            dispatch(setCurrentPage(managedContactsState.currentPage +1));
            dispatch(setPageSize(((managedContactsState.pageSize /20) +1) * 20))

            dispatch(getManagedContactsWithFilter(filter));
        }
        
        if (managedCompanyState.managedCompany) {            
            setManagedCompany(managedCompanyState.managedCompany);
            getContacts();        
        }
        
        setRows([...rows]);
        setTimeout(() => {
            setIsShowToast(false);
          }, 1000);
        
      }
    
    // Get company data on page load
    useEffect(() => {
        if (props.id) {
            dispatch(getCompanyById(props.id));
        }
    }, [dispatch, props.id]);

  

    // Get contacts by company id 
    useEffect(() => {
        const getContacts = () => {
            if (managedCompany == null) {
                return;
            }
    
            const filter = {
                filter: {
                    where: {
                        companyId: {
                            eq: `${managedCompany.companyId}`
                        }
                    },
                    limit: managedContactsState.pageSize,
                    skip: (managedContactsState.currentPage - 1) * managedContactsState.pageSize
                }
            };
    
            dispatch(getManagedContactsWithFilter(filter));
        }
        const getContactsCount=()=>{
            if (managedCompanyState == null) {
                return;
            }
            dispatch(setSearchCriteria(`${managedCompanyState.managedCompany.companyId}`))
        };
        
        if (managedCompanyState.managedCompany) {            
            setManagedCompany(managedCompanyState.managedCompany);
            getContacts();

            getContactsCount();
            dispatch(getManagedContactsCount());       

        }
    }, [dispatch, managedCompany, managedCompanyState.managedCompany, managedContactsState.currentPage, managedContactsState.pageSize]);

    // Set contact table rows
    const refreshRows = () => {
        let tempRows = [];
        (contacts ?? []).forEach(contact => {
            tempRows.push({
                selectContact: (<CheckBoxContainer><CheckBox 
                    id={contact.contactId}
                    checked={selectedContact === contact} 
                    onChange={() => setSelectedContact(contact)}
                    /></CheckBoxContainer>),
                contactId: contact.contactId, 
                contactIdentity: contact.contactIdentity,
                contactScore: contact.contactScore,
                contactNameGivenName: contact.contactNameGivenName,
                contactNameSurname: contact.contactNameSurname,
                contactNameMaturitySuffix: contact.contactNameMaturitySuffix,
                contactTitle: contact.contactTitle,
                contactPhone: contact.contactPhone,
                contactSourceFirstTouch: contact.contactSourceFirstTouch,
                contactSourceLastTouch: contact.contactSourceLastTouch,
                contactEmail: contact.contactEmail
            });
        });
        setRows(tempRows);
    }   

    // Set contacts and selected contacts state vars when contact data is fetched from store
    useEffect(() => {
        if (managedContactsState.contacts) {
            setContacts(managedContactsState.contacts);
        }
        const contactElement = document.getElementById("3-Contacts");
        if(contactElement)
        {
            contactElement.classList.remove('active');
        }
        if (props.selectedContactId) {
           
            (contacts ?? []).forEach(contact => {
                if (contact.contactId === props.selectedContactId) {
                    setSelectedContact(contact);
                }
            });
        } else {
           
            // If no contact was provided, selected primary contact
            (contacts ?? []).forEach(contact => {
                if (contact.contactRoleIsPrimary) {
                    setSelectedContact(contact);
                }
            });
        }

        refreshRows();
    }, [contacts, managedContactsState.contacts, props.selectedContactId]);

    // Refresh rows when a new contact is selected
    useEffect(() => {
        refreshRows();
    }, [selectedContact]); 
 
    return (
        <div className='companies-container'>
            {managedCompanyState.loading && (
                <SpinnerOverlay />
            )}
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            {managedCompany &&
            <div>
            <div id='panel-header'>
                <PanelHeader
                    header={`Edit Company - ${managedCompany.companyName}`}
                    subHeader={`Edit (change) the company`}
                />
            </div>
            <Panel>
                <FormContainer>
                    <Form>
                        <Form.Group controlId='editCompany'>
                            <Row>
                                <Col md={5}>
                                    <div style={{ padding: '15px', height: '100%', width: '100%' }}>
                                        <SectionHeaderWithScore 
                                            label={"Company"} 
                                            score={managedCompany.companyScore} 
                                            id={managedCompany.companyId} 
                                            identity={managedCompany.companyIdentity} 
                                        />
                                        <Row>
                                            <Col md={6}>
                                                <LeadText id='company-name'>{managedCompany.companyName}</LeadText>
                                                <div>
                                                    {managedCompany.companyURL && <LeadText style={{fontWeight: 500}}><i><a target='_blank'
                                                        href={`${managedCompany.companyURL}`}
                                                        rel='noopener noreferrer'
                                                        >
                                                            <div style={{ marginLeft: '15px' }}>
                                                                <Row>
                                                                    <div id='company-url' data-for='hoverText' data-tip={managedCompany.companyURL}>
                                                                        {managedCompany.companyURL.length > 25 ?
                                                                            <span>{`${managedCompany.companyURL.substring(0, 25)} ...`}&nbsp;</span>
                                                                            :
                                                                            <span>{`${managedCompany.companyURL}`}&nbsp;</span>
                                                                        }
                                                                    </div>
                                                                    <FontAwesomeIcon
                                                                        color={APP_COLORS['blue']}
                                                                        icon={faExternalLink}
                                                                        style={{marginRight: '5px', fontSize: '12px'}}
                                                                    />
                                                                </Row>
                                                            </div>
                                                    </a></i></LeadText>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <CompanyChannelsBox item={managedCompany} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <CompanyClassificationBox item={managedCompany} />
                                            </Col>
                                            <Col md={6}>
                                                <CompanyProductsBox item={managedCompany} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <CompanyOnlineProfilesBox item={managedCompany} />
                                            </Col>
                                            <Col md={6}>
                                                <AttributesBox item={managedCompany} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <CompanyManufacturersBox item={managedCompany} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <CompanyAffiliationsBox item={managedCompany} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={6}>
                                    <div style={{ padding: '15px', height: '100%', width: '100%' }}>
                                        <SectionHeaderWithScore 
                                            label={"Contact"} 
                                            score={(selectedContact ? selectedContact.contactScore : '')} 
                                            id={(selectedContact ? selectedContact.contactId : '')}
                                            identity={(selectedContact ? selectedContact.contactIdentity : '')}
                                        />
                                        {selectedContact && <div>
                                            <Row>
                                                <Col>
                                                    <LeadText id='contact-name'>{selectedContact.contactNameGivenName} {selectedContact.contactNameSurname}</LeadText>
                                                    <SubText id='contact-title'>{selectedContact.contactTitle}</SubText>
                                                    <div style={{ marginLeft: '15px' }}>
                                                        <Row>
                                                            <CheckBoxContainer><CheckBox id={'contact-is-primary'} checked={selectedContact.contactRoleIsPrimary ?? false} /></CheckBoxContainer>
                                                            <p>Is Primary</p>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ContactChannelsBox item={selectedContact} />
                                                </Col>
                                            </Row>
                                        </div>}
                                        <CardLabel style={{fontSize: '105%'}}>Contacts ({managedContactsState.totalRecords})</CardLabel>
                                        {contacts.length > 0 ? 
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <DataGrid autoHeight columns={columns} rows={rows} rowKeyGetter={rowKeyGetter} onRowsChange={setRows} rowHeight={30} onScroll={handleScroll} />
                                                {isShowToast && (<LoadMoreRowsClassname>Loading more rows...</LoadMoreRowsClassname>)}
                                            </div>
                                        : <p><i>No contacts for this company</i></p>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <CoreAttributes companyId={managedCompany.companyId} contactId={selectedContact ? selectedContact.contactId : ''} isCallFromCompany={props.selectedContactId ? true : false}  />
                                <SectionCollapse header={'TAXONOMY'}></SectionCollapse>
                                <SectionCollapse header={'METRICS (INDICATORS, FACTS, AND STATISTICS'}></SectionCollapse>
                                <SectionCollapse header={'PROFILES'}></SectionCollapse>
                                <SectionCollapse header={'LICENSES'}></SectionCollapse>
                                <SectionCollapse header={'PERMITS'}></SectionCollapse>
                                <SectionCollapse header={'REGISTRATIONS'}></SectionCollapse>
                                <SectionCollapse header={'PRODUCTS'}></SectionCollapse>
                                <SectionCollapse header={'RECORDS'}></SectionCollapse>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
            </div>}
        </div>
    );
}

export default EditCompany;