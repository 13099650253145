/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    createManagedMatchTemplate,
    resetCreateManagedMatchTemplateState
} from "./managedMatchTemplatesSlice";

import {Row, Col, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import Container from "../../common/components/layout/Container";
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";

import sampleMatchTemplate from "./sampleMatchTemplate.json"
import FormJsonInput from "../../common/components/form/FormJsonInput";
import styled from "styled-components";

export default class CreateManagedMatchTemplateContainer extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <CreateManagedMatchTemplate/>
        )
    }
}

const PreLikeText = styled.div `
  margin-top: 5px;
  display: block;
  white-space: pre-wrap;
  unicode-bidi: embed;
  font-family: monospace;
  color: #212529;
  font-size: 87.5%;
`;

function CreateManagedMatchTemplate(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Match Templates',
        href: '/admin/match-templates',
        isActive: false
    }, {label: 'Create a Match Template', href: '', isActive: true}];
    const managedMatchTemplateState = useSelector(state => state.managedMatchTemplates);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // Form
    const [formValid, setFormValid] = useState(true);
    const [matchTemplateState, setMatchTemplateState] = useState(null);

    const reset = () => {
        setAlertMessage(undefined);
        setShowAlert(false);
        setShowSuccess(false);
        setSuccessMessage(undefined);
        setMatchTemplateState(null);
        dispatch(resetCreateManagedMatchTemplateState());
    }

    const isValid = () => {
        if (!matchTemplateState) return false;
        try {
            // TODO: We should interface with the form component
            return true;
        } catch (error) {
            console.warn(`Match Template contains errors - NOT VALID JSON [${error.message}]`);
            return false;
        }
    };

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedMatchTemplateState.reset) {
            reset()
        }
    }, [managedMatchTemplateState.reset]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedMatchTemplateState.successMessage) {
            setSuccessMessage(managedMatchTemplateState.successMessage);
            setShowSuccess(true);
        }
    }, [managedMatchTemplateState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedMatchTemplateState.alertMessage) {
            setAlertMessage(managedMatchTemplateState.alertMessage);
            setShowAlert(true);
        }
    }, [managedMatchTemplateState.alertMessage]);

    useEffect(() => {
        setMatchTemplateState(sampleMatchTemplate);
    }, []);

    const handleCreateManagedMatchTemplate = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            const data = {
                matchTemplate: matchTemplateState
            };
            dispatch(createManagedMatchTemplate(data));
        }
    };
    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={'Create Match Template'}
                subHeader={'Create a new match template'}
            />
            <Panel>
                {managedMatchTemplateState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createManagedMatchTemplate">
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormJsonInput
                                                id={'create-config-organization'}
                                                label={'Config'}
                                                placeholder={matchTemplateState}
                                                onChange={(v) => setMatchTemplateState(v)}
                                                height={'500px'}
                                                width={'100%'}
                                                viewOnly={false}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col md={12}>
                                            <h6 style={{fontWeight: "600"}}>Help</h6>
                                            <PreLikeText style={{color: APP_COLORS.red}}><FontAwesomeIcon color={APP_COLORS.red} icon={faExclamationCircle} />
                                                &nbsp;This is only a sample, make sure you know what your doing here! Check the console if your having issues, errors are likely logged there.
                                            </PreLikeText>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateManagedMatchTemplate()}
                                    >CREATE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            history.push('/admin/match-templates')
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}
