/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';

import {
    setSearchCriteria, getIpLocationData, postContractorsFtsSearch
} from "./contractorDirectorySlice";

import Container from "../../common/components/layout/Container";
import BreadCrumb from "../../common/components/BreadCrumb";
import ContractorDirectoryHeader from "./ContractorDirectoryHeader";
import ContractorDirectorySearchInput from "./ContractorDirectorySearchInput";
import ContractorDirectorySearchOptionsForm from "./ContractorDirectorySearchOptionsForm";

import Panel from "../../common/components/layout/Panel";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import ContractorDirectorySearchResults from "./ContractorDirectorySearchResults";

const ContractorDirectory = (props) => {
    return (
        <ContractorDirectoryComponent
        />
    );
};

const ContractorDirectoryComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Contractor Directory',
        href: '',
        isActive: true
    }];
    const contractorDirectoryState = useSelector(state => state.contractorDirectory);
    const dispatch = useDispatch();
    const [lastReload, setLastReload] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        dispatch(getIpLocationData());
    }, []);

    useEffect(() => {
        // on first load get contractor directory results based on ip location data
        if (contractorDirectoryState.ipLocationData) {
            dispatch(postContractorsFtsSearch());
        }
    }, [contractorDirectoryState.ipLocationData]);

    useEffect(() => {
        if (contractorDirectoryState.alertMessage) {
            setAlertMessage(contractorDirectoryState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [contractorDirectoryState.alertMessage]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleOnContact = (item) => {
        console.log('contacting', item)
    }

    let searchSubTextLocation = '';
    if (contractorDirectoryState.searchLocationOption === 'USE_IP' && contractorDirectoryState.ipLocationData) {
        const {
            ip,
            city,
            region,
            latitude,
            longitude
        } = contractorDirectoryState.ipLocationData;
        searchSubTextLocation = `Searching within [${contractorDirectoryState.searchWithinMiles}] miles of ${city}, ${region} (${latitude}, ${longitude})`;
    }
    if (contractorDirectoryState.searchLocationOption === 'USE_LOCATION' && contractorDirectoryState.geocoderLocationData) {
        const {
            location,
            latitude,
            longitude
        } = contractorDirectoryState.geocoderLocationData;
        searchSubTextLocation = `Searching within [${contractorDirectoryState.searchWithinMiles}] miles of ${location}, (${latitude}, ${longitude})`;
    }

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <ContractorDirectoryHeader
                searchBar={
                    <ContractorDirectorySearchInput
                        placeholder={'Enter you search here'}
                        value={contractorDirectoryState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </ContractorDirectorySearchInput>
                }
                searchSubText={`Search the Contractor Directory!`}
                searchSubTextLocation={searchSubTextLocation}
                searchOptionsForm={
                    <ContractorDirectorySearchOptionsForm/>
                }
            />
            <Panel>
                {contractorDirectoryState.loading && (
                    <SpinnerOverlay />
                )}
                    <Alert
                        variant={"danger"}
                        show={showAlert}
                        onClose={() => setShowAlert(!showAlert)}
                        dismissible
                        transition={false}
                    >
                        {alertMessage}
                    </Alert>
                    <ContractorDirectorySearchResults lastReload={lastReload} handleOnContact={handleOnContact}/>
            </Panel>
        </Container>
    );
};

export default ContractorDirectory;
