

import React, { useState, useRef, useCallback} from 'react'; // eslint-disable-line no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    map,
    filter,
} from 'lodash';
import moment from 'moment';
import {findSearchCriteria} from './searchDataSlice';

import {APP_COLORS} from '../../common/Constants';

const SearchFilterTypeAhead = (props) => {
    const {
        id,
        style,
        onChange,
        clearButton,
        mode,
    } = props;
    const dispatch = useDispatch();
    const searchCriteriaState = useSelector(state => state.searchData);
    const searchType = mode === 'COMPANY' ? 'SEARCH_COMPANY' : 'SEARCH_CONTACT';
    const result = searchCriteriaState.searchCriteria;
    const options = !result || result.length === 0 ? [] :
        map(filter(result, res => res.type === searchType), (search) => ({id: search.id, display: search.name, updated: search.updated}));
    const [offset, setOffset] = useState(0);
    const resultPerPage = 6;
    const [term, setTerm] = useState('');
    const [selectedSearch, setSelectedSearch] = useState([]);
    const inputEl = useRef(null);

    const makeAndHandleRequest = (term, searchType, offset = 0) => {
        const where = {
            and: [
                {type: searchType},
                {
                    or: [
                        {name: {
                            ilike: `%${term}%`
                        }},
                        {description: {
                                ilike: `%${term}%`
                            }
                            },
                    ]
                }
            ]

        };
        dispatch(findSearchCriteria({where, skip: 0, limit: (offset + resultPerPage)}));
    };

    const handleInputChange = (value) => {
        setTerm(value);
    };

    const handlePaginate =  useCallback((_term, searchType, offset) => {
        return makeAndHandleRequest(_term, searchType, offset);
    }, []);

    const handleSearch = useCallback((query) => {
        makeAndHandleRequest(query, searchType, 0);
    }, [searchType]);

    const onAppliedSearch = (searchFilter) => {
        onChange(searchFilter);
        setSelectedSearch([]);
        inputEl.current.blur();
    };

    return (
        <div>
            <AsyncTypeahead
                ref={inputEl}
                style={style}
                id={`combo-box-${id}`}
                labelKey={'display'}
                maxResults={resultPerPage - 1}
                minLength={0}
                onInputChange={handleInputChange}
                onPaginate={() => {
                    handlePaginate(term, searchType, (offset + (resultPerPage - 1)));
                    setOffset((offset + (resultPerPage - 1)));
                }}
                onFocus={() => handleSearch('')}
                onSearch={handleSearch}
                paginate
                placeholder={'My Searches'}
                renderMenuItemChildren={option => (
                    <div key={option.id} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span>{option.display}&nbsp;</span>
                        <span style={{paddingRight: '10px', fontStyle: 'italic', color: APP_COLORS['gray-500']}}>
                            ({moment(option.updated).fromNow()})
                        </span>
                    </div>
                )}
                useCache={false}
                options={options}
                isLoading={searchCriteriaState.status.status === 'loading'}
                onChange={(values) => {
                        onAppliedSearch(values[0]);
                }}
                selected={selectedSearch}
                ignoreDiacritics
                clearButton={clearButton}
                query={term}
                paginationText={'More Searches'}
            >
            </AsyncTypeahead>
        </div>
            );
};

export default SearchFilterTypeAhead;
