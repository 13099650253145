/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import EntityVerificationsContainer from "./EntityVerificationsContainer";

export default class EntityVerificationsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/entity-verifications" component={EntityVerificationsContainer} />
            </Switch>
        );
    }
}
