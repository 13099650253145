/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import ManagedListErrors from "./ManagedListErrors"; // eslint-disable-line no-unused-vars

const ManagedListErrorsContainer = (props) => {
    return(
       <ManagedListErrors/>
    )
};

export default ManagedListErrorsContainer;
