/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import find from 'lodash/find';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'

import {getVersion} from '../Utils';
import {APP_COLORS} from '../Constants';
import logoIcon from '../assets/img/ToolBelt_Icon.png'
import {useDispatch, useSelector} from "react-redux";
import {clearAllFilter as clearAllCompanyFilter} from "../../organization/explore/companySlice";
import {clearAllFilter as clearAllContactFilter} from "../../organization/explore/contactSlice";
import {setCompanyMode} from "../../organization/explore/exploreSlice";
import {clearState, logout} from "../account/accountSlice";

// TODO: This appears to be re-rendering on every page change

export default function Menu(props) {
    const { menu } = props;
    const { account } = useSelector(state => state.account);
    const dispatch = useDispatch();

    const handleSignOut = () => {
        dispatch(clearAllCompanyFilter());
        dispatch(clearAllContactFilter());
        dispatch(setCompanyMode());

        dispatch(clearState());
        dispatch(logout());
    };

    const { givenName, surName , email } = account;
    const homeMenuItem = find(menu, {name: 'HOME'});

    return (
        <Row noGutters>
            <Col>
                <Navbar className="navbar-bordered" expand="lg">
                    <LinkContainer to={homeMenuItem.path}>
                        <Navbar.Brand>
                            <img
                                src={logoIcon}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="ToolBeltData Logo"
                            />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {<MenuItems menu={menu}/>}
                        </Nav>
                        <Nav className="ml-auto">
                            <NavDropdown title={<FontAwesomeIcon icon={faUser} />} id="basic-nav-dropdown" alignRight>
                                <NavDropdown.Item disabled style={{textAlign: 'center'}}><FontAwesomeIcon color={APP_COLORS.info} icon={faUser} /></NavDropdown.Item>
                                <NavDropdown.Item disabled style={{textAlign: 'center', fontSize: '1rem'}}>{`${givenName} ${surName}`}</NavDropdown.Item>
                                <NavDropdown.Item disabled style={{textAlign: 'center', fontSize: '.9rem'}}>{email}</NavDropdown.Item>
                                <NavDropdown.Item
                                    disabled
                                    style={{fontSize: '.75rem', textAlign: 'center'}}
                                >
                                    {getVersion(false)}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={() => handleSignOut()}
                                    style={{fontSize: '.9rem', textAlign: 'center'}}
                                >
                                    Sign Out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col>
        </Row>
    )
}

const MenuItems = props => {
    if (props.menu && Array.isArray(props.menu)) {
        return props.menu.map((m, i) => {
            // SubMenu
            if (m.subMenu && m.subMenu.length > 0) {
                const navDropDownItems = m.subMenu.map(sm => {
                    if (sm.path) {
                        return (
                            <LinkContainer key={`${i}-${sm.path}`} to={`${sm.path}`}>
                                {sm.divider ? (
                                    <NavDropdown.Divider />
                                ) : (
                                    <NavDropdown.Item>{sm.name}</NavDropdown.Item>
                                )}
                            </LinkContainer>
                        )
                    } else {
                        return (
                            <NavDropdown.Item key={`${i}-${sm.name}`}>{sm.name}</NavDropdown.Item>
                        )
                    }
                })
                return (
                    <NavDropdown key={i} title={m.name} id={`menu-${m.name.lowerCase}-nav-dropdown`} className="font-weight-bold">
                        {navDropDownItems}
                    </NavDropdown>
                )
            }
            // Mega Menu
            if (m.megaMenu) {
                const getNavDropDownItems = (c) => {
                    if (c.path) {
                        return (
                            <LinkContainer key={`${i}-${c.path}`} to={`${c.path}`}>
                                {c.divider ? (
                                    <NavDropdown.Divider />
                                ) : (
                                    <NavDropdown.Item id={`${i}-${c.name}`}>{c.name}</NavDropdown.Item>
                                )}
                            </LinkContainer>
                        )
                    } else {
                        return (
                            <NavDropdown.Item id={`${i}-${c.name}`} key={`${i}-${c.name}`}>{c.name}</NavDropdown.Item>
                        )
                    }
                }

                // TODO This could be made much more interesting
                const administerDropDownItems = m.megaMenu.administer.map(c => getNavDropDownItems(c));
                //const dataDropDownItems = m.megaMenu.data.map(c => getNavDropDownItems(c));
                const configureDropDownItems = m.megaMenu.configure.map(c => getNavDropDownItems(c));
                const toolsDropDownItems = m.megaMenu.tools.map(c => getNavDropDownItems(c));
                return (
                    <NavDropdown key={i} title={m.name} id={`menu-${m.name.lowerCase}-nav-dropdown`} className="font-weight-bold">
                        <Row style={{minWidth: '400px'}}>
                            <Col xl={6} lg={6} md={6} sm={6} style={{minWidth: '200px'}}>
                                <NavDropdown.Item style={{fontWeight: 'bold', color: APP_COLORS["gray-600"]}}>Administer</NavDropdown.Item>
                                {administerDropDownItems}
                                <NavDropdown.Item style={{fontWeight: 'bold', color: APP_COLORS["gray-600"]}}>Configure</NavDropdown.Item>
                                {configureDropDownItems}
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} style={{minWidth: '200px'}}>
                                {/* <NavDropdown.Item style={{fontWeight: 'bold', color: APP_COLORS["gray-600"]}}>Data</NavDropdown.Item>
                                {dataDropDownItems} */}
                                <NavDropdown.Item style={{fontWeight: 'bold', color: APP_COLORS["gray-600"]}}>Tools</NavDropdown.Item>
                                {toolsDropDownItems}
                            </Col>
                        </Row>
                    </NavDropdown>
                )
            }
            return (
                <LinkContainer key={i} to={`${m.path}`}>
                    <Nav.Link className="font-weight-bold">{m.name}</Nav.Link>
                </LinkContainer>
            )
        })
    } else {
        return null;
    }
};
