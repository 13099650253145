/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import ManagedListMatchLogs from "./ManagedListMatchLogs"; // eslint-disable-line no-unused-vars

const ManagedListMatchLogsContainer = (props) => {
    return(
       <ManagedListMatchLogs/>
    )
};

export default ManagedListMatchLogsContainer;
