/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Row, Col, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import {getAdminListWithFilter} from "./adminListSlice";

import {APP_COLORS} from "../../common/Constants";

import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import PanelHeader from "../../common/components/layout/PanelHeader";
import BreadCrumb from '../../common/components/BreadCrumb';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormJsonInput from "../../common/components/form/FormJsonInput";

import './AdminActionListContainer.scss';

const AdminViewAsJSONContainer = (props) => {
    const adminListState = useSelector(state => state.adminList);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [managedList, setManagedList] = useState(null);

    useEffect( () => {
        if (props.match.params.id){
            dispatch(getAdminListWithFilter({id: props.match.params.id, filter: {filter: {
                include: ['match']
            }}}));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (adminListState.adminList) {
            setManagedList(adminListState.adminList);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Lists',
                        href: '/admin/lists',
                        isActive: false
                    },
                    {
                        label: `${adminListState?.adminList.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'View as JSON',
                        href: '',
                        isActive: true
                    }
                 ]
            )
        }
        }
        , [adminListState.adminList]);

    useEffect(() => {
        setAlertMessage(adminListState.alertMessage);
        if (adminListState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [adminListState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(adminListState.successMessage);
        if (adminListState.successMessage) {
            setShowSuccess(true);
            history.push('/admin/lists');
        }else {
            setShowSuccess(false);
        }
    }, [adminListState.successMessage]);

    const handleCancel = async () => {
        history.push('/admin/lists')
    };

    return (
        <div>
            {adminListState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <AdminMatch
                id={managedList?.id}
                name={managedList?.name}
                managedList={managedList}
                handleCancel={() => handleCancel()}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function AdminMatch(props) {

    const {
        breadcrumbItems,
        id,
        name,
        managedList,
        handleCancel,
    } = props;

    // Form
    const [managedListState, setManagedList] = useState('');

    const reset = () => {

    };

    useEffect(() => {
        setManagedList(JSON.stringify(managedList, null, '\t'));
    }, [managedList]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`View as JSON - ${name}`} subHeader={'View list as JSON'} />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="viewListAsJSON">
                            <Row>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormJsonInput
                                                id={'view-list-as-json'}
                                                label={'Config'}
                                                placeholder={managedList}
                                                onChange={(v) => setManagedList(v)}
                                                height={'500px'}
                                                width={'100%'}
                                                viewOnly={true}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col md={12}>
                                            <h6 style={{fontWeight: "600"}}>Info</h6>
                                            {
                                                managedList && managedList.match && (
                                                    <div className="action-list-form-label">
                                                        <Form.Label>For <span style={{color: APP_COLORS.orange, fontWeight: "bold"}}>matching</span>, copy the listMatchId:</Form.Label>
                                                        {managedList.match.id &&
                                                            <TextHoverDisplay
                                                                fullText={managedList.match.id}
                                                                truncatedText={managedList.match.id}
                                                                displayTruncated={false}
                                                            />
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >DONE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default AdminViewAsJSONContainer;

