import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../../config'
import * as Api from "../../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedTeam: [],
    currentPage: 1,
    pageSize: 50,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    team: [],
    teamMember: null,
};

/***

TODO: Search is currently disabled. The backend does not expose a searchable endpoint ATM. ToDo so correctly
 would require exposing a new endpoint /organization-members and re-thinking how we 'build' organizations and accounts

 */

export const getTeam = createAsyncThunk('team/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/organization/team`;
    const team = await Api.get(true, GET_URL,
        buildQuery(
            getState().team.currentPage,
            getState().team.pageSize,
            getState().team.order.by,
            getState().team.order.direction,
            getState().team.searchCriteria
        ));
    return team;
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = {
        filter: {
            where: {
                // and: [
                //     {
                //         or: [
                //             {
                //                 givenName: {
                //                     ilike: `%${searchCriteria ? searchCriteria : ''}%`
                //                 }
                //             },
                //             {
                //                 surname: {
                //                     ilike: `%${searchCriteria ? searchCriteria : ''}%`
                //                 }
                //             },
                //             {
                //                 email: {
                //                     ilike: `%${searchCriteria ? searchCriteria : ''}%`
                //                 }
                //             },
                //         ]
                //     }
                // ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

export const addTeamMember = createAsyncThunk('team/organization/add-team-member',
    async ({givenName, surname, email}) => {
        const ADD_ORGANIZATION_MEMBER_URL = `${config.services.ATLAS.apiUrl}/organization/team`;
        const data = {
            givenName: givenName,
            surName: surname,
            credentials: {
                email: email
            }
        };

        return await Api.post(true,
            ADD_ORGANIZATION_MEMBER_URL,
            data
        );
    });

export const activateTeamMember = createAsyncThunk('team/organization/activate-team-member',
    async ({memberId}) => {
        const ACTIVATE_ORGANIZATION_MEMBER_URL = `${config.services.ATLAS.apiUrl}/organization/team/${memberId}/activate`;
        return await Api.post(true, ACTIVATE_ORGANIZATION_MEMBER_URL, {});
    });

export const disableTeamMember = createAsyncThunk('team/organization/disable-team-member',
    async ({memberId}) => {
        const DISABLE_ORGANIZATION_MEMBER_URL = `${config.services.ATLAS.apiUrl}/organization/team/${memberId}/disable`;
        return await Api.post(true, DISABLE_ORGANIZATION_MEMBER_URL, {});
    });

const teamSlice = createSlice({
    name: 'teamSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedTeam = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedTeam.splice(state.selectedTeam.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedTeam.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedMatchTypeState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getTeam.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getTeam.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.team = action.payload;
                state.totalRecords = action.payload.length;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getTeam.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [addTeamMember.pending]: (state, action) => {
            state.loading = true;
        },
        [addTeamMember.fulfilled]: (state, action) => {
            state.loading = false;
            state.reload = true;
            state.lastReload = new Date();
        },
        [addTeamMember.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = `There was an error while trying to add the team member. Failed with message - [${action.error.message}]. Please contact support.`;
        },
        [activateTeamMember.pending]: (state, action) => {
            state.loading = true;
        },
        [activateTeamMember.fulfilled]: (state, action) => {
            state.loading = false;
            state.reload = true;
            state.lastReload = new Date();
        },
        [activateTeamMember.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = `There was an error while trying to active the team member. Failed with message - [${action.error.message}]. Please contact support.`;
        },
        [disableTeamMember.pending]: (state, action) => {
            state.loading = true;
        },
        [disableTeamMember.fulfilled]: (state, action) => {
            state.loading = false;
            state.reload = true;
            state.lastReload = new Date();
        },
        [disableTeamMember.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = `There was an error while trying to disable the team member. Failed with message - [${action.error.message}]. Please contact support.`;
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = teamSlice.actions;
export default teamSlice.reducer;
