/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";

import React from "react";

import ContractorDirectorySearchResultIconBox from "./ContractorDirectorySearchResultIconBox";
import ContractorDirectorySearchResultContractorBox from "./ContractorDirectorySearchResultContractorBox";
import ContractorDirectorySearchResultDetailsBox from "./ContractorDirectorySearchResultDetailsBox";
import ContractorDirectorySearchResultActionBox from "./ContractorDirectorySearchResultActionBox";
import ContractorDirectorySearchResultCredentialsBox from "./ContractorDirectorySearchResultCredentialsBox";

const SearchResultContainer = styled.div `
  margin-top: 5px;
  display: grid;
  grid-template-columns: minmax(100px,100px) minmax(200px,400px) minmax(20px,400px) minmax(20px,500px) 1fr;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  min-height: 150px;
  width: 100%;
  min-width: 1600px;
`;

export default function CompanyCard(props) {
    const {
        item,
        handleOnContact
    } = props;

    return (
        <SearchResultContainer>
            <ContractorDirectorySearchResultIconBox btVerified={true} />
            <ContractorDirectorySearchResultContractorBox item={item}/>
            <ContractorDirectorySearchResultDetailsBox item={item} />
            <ContractorDirectorySearchResultCredentialsBox item={item} />
            <ContractorDirectorySearchResultActionBox item={item} handleOnContact={handleOnContact} />
        </SearchResultContainer>
    )
}
