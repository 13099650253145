import React, { Component } from 'react';
import {denormCompanyUpdate} from "./entityVerificationsSlice";

export class CompanyUrlCellEditor extends Component {
    constructor(props) {
        super(props);
        this.rowData = props.data;
        this.url = React.createRef();
        this.validationReason = React.createRef();
        this.invalidationReason = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getValue() {
        alert('CompanyAddressCellEditor getValue called!');
        alert(`current value = ${this.url.current.value}`);
        return this.url.current.value;
    };

    afterGuiAttached() {
        alert(`CompanyAddressCellEditor afterGuiAttached called!`);
        alert(`this equals ${JSON.stringify(this)}`);
        if (this.url) this.url.current.focus();
    };

    handleSubmit() {
        alert(`CompanyAddressCellEditor handleSubmit called!`);

        const {dispatch} = this.props;
        alert(`this.props.dispatch is defined =  ${this.props.dispatch !== undefined}`);
        alert(`dispatch is defined = ${dispatch !== undefined}`);
        this.props.dispatch(denormCompanyUpdate({companyId: this.rowData["COMPANY_ID"], column: `something`, value: `some value`, rowIndex: 0}))
        if (this.url) this.url.current.focus();
    };

    render() {
        return (
            // <input onKeyPress={this.onKeyPress} ref={this.textInput} defaultValue={this.props.value}/>
            <form style={{backgroundColor: "white"}} onSubmit={this.handleSubmit}>
                <label>URL:<input type="text" ref={this.url} defaultValue={this.props.value}/></label><br/>
                <label>Validation Reason:<input type="text" ref={this.validationReason} defaultValue="not validated"/></label><br/>
                <label>Invalidated Reason:<input type="text" ref={this.invalidationReason} defaultValue="not invalidated"/></label><br/>
                <button>Submit</button>
            </form>
        );
    }

    connect
}