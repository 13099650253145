/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    createManagedIntegration,
    resetCreateManagedIntegrationState
} from "./managedIntegrationsSlice";

import {Row, Col, Button, Form, Table} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import Container from "../../common/components/layout/Container";
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";

import styled from "styled-components";
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import CreateManagedIntegrationForm from './CreateManagedIntegrationForm';
import LinkButton from '../../common/components/LinkButton';

export default class CreateManagedIntegrationContainer extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <CreateManagedIntegration/>
        )
    }
}

const PreLikeText = styled.div `
  margin-top: 5px;
  display: block;
  white-space: pre-wrap;
  unicode-bidi: embed;
  font-family: monospace;
  color: #212529;
  font-size: 87.5%;
`;

function CreateManagedIntegration(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Integrations',
        href: '/admin/integrations',
        isActive: false
    }, {label: 'Create an Integration', href: '', isActive: true}];

    const managedIntegrationState = useSelector(state => state.managedIntegrations);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    // Form
    const [formValid, setFormValid] = useState(true);
    const [organizationId, setOrganizationId] = useState([]);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [accountId, setAccountId] = useState([]);
    const [integrationConfig, setIntegrationConfig] = useState(
        { "organizationId": organizationId,
            "type": type,
            "name": name,
            "description": description,
            "options": {
            "accountId": accountId
            } });
    const [editAsJSON, setEditAsJSON] = useState(false);
    const [editBtnTxt, setEditBtnTxt] = useState('View JSON');
    const [accountDisabled, setAccountDisabled] = useState(true);

    const reset = () => {
        setAlertMessage(undefined);
        setShowAlert(false);
        setShowSuccess(false);
        setSuccessMessage(undefined);
        dispatch(resetCreateManagedIntegrationState());
    }

    const isValid = () => {
        // TODO add checks (fields are marked as required so not sure what to check for)
        // keeping this function here in case someone wants to implement this later
        return true;
    };

    const getJSON = () => {
        // return form field values as a JSON object
        return ({ "organizationId": ((organizationId[0] ?? {id: ''}).id ?? ''),
            "type": ((type ?? { value: '' }).value ?? ''),
            "name": name,
            "description": description,
            "options": {
                "accountId": ((accountId[0] ?? {id: ''}).id ?? '')
            } });
    }

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedIntegrationState.reset) {
            reset()
        }
    }, [managedIntegrationState.reset]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedIntegrationState.successMessage) {
            setSuccessMessage(managedIntegrationState.successMessage);
            setShowSuccess(true);
        }
    }, [managedIntegrationState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedIntegrationState.alertMessage) {
            setAlertMessage(managedIntegrationState.alertMessage);
            setShowAlert(true);
        }
    }, [managedIntegrationState.alertMessage]);
    
    useEffect(() => {
        // When account fields update, set integrationConfig to new JSON
        setIntegrationConfig(getJSON());
    }, [accountId, description, name, organizationId, type]);

    useEffect(() => {
        // When organizationId is changed, check if it has been cleared
        if (((organizationId[0] ?? {id: ''}).id ?? '') === '') {
            // If it has been cleared, clear accountId and disabled account id field
            setAccountId([]);
            setAccountDisabled(true);
        } else {
            // Enable account id field if an organization id has been entered
            setAccountDisabled(false);
        }
    }, [organizationId]);

    const handleCreateManagedIntegration = async () => {
        // Check if form is valid
        if (!isValid()) {
            setFormValid(false);
        } else {
            // Convert form values to JSON string and submit form
            const integrationToCreate = getJSON();
            dispatch(createManagedIntegration(integrationToCreate));
        }
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={'Create Integration'}
                subHeader={'Create a new integration'}
            />
            <Panel>
                {managedIntegrationState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createManagedIntegration">
                            <Row>
                                <Col md={7}>
                                    <CreateManagedIntegrationForm
                                        editAsJSON={editAsJSON}
                                        integrationConfig={integrationConfig}
                                        setIntegrationConfig={setIntegrationConfig}
                                        organizationId={organizationId}
                                        setOrganizationId={setOrganizationId}
                                        type={type}
                                        setType={setType}
                                        name={name}
                                        setName={setName}
                                        description={description}
                                        setDescription={setDescription}
                                        accountId={accountId}
                                        setAccountId={setAccountId}
                                        accountDisabled={accountDisabled}
                                        formValid={formValid}
                                    />
                                </Col>
                                <Col md={2}>
                                    <div id='editAsJSONBtn-container'>
                                    <LinkButton id='editAsJSONBtn' onClick={() => {
                                        if (!editAsJSON) {
                                            setEditBtnTxt('Edit Form');
                                        } else {
                                            setEditBtnTxt('View JSON');
                                        }
                                        setEditAsJSON(!editAsJSON);
                                        }}>
                                        {editBtnTxt}
                                    </LinkButton>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div>
                                            <h6 style={{fontWeight: "600"}}>Help</h6>
                                            <PreLikeText>
                                                <Table size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>Property</th>
                                                        <th>Description</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>organizationId</td>
                                                        <td style={{padding: '5px'}}>By default is populated with your Account's organization id. Update as needed</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>type</td>
                                                        <td style={{padding: '2px'}}>'API' is the only currently supported value</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>name</td>
                                                        <td style={{padding: '2px', color: APP_COLORS.red}}>UPDATE REQUIRED</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>description</td>
                                                        <td style={{padding: '2px', color: APP_COLORS.red}}>UPDATE REQUIRED</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>options.accountId</td>
                                                        <td style={{padding: '2px'}}>By default is populated with your Account's id. Update as needed</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </PreLikeText>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateManagedIntegration()}
                                    >CREATE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            history.push('/admin/integrations')
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}
