/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useState} from 'react';
import BreadCrumb from '../../common/components/BreadCrumb';
import {Row, Col, Button, Form} from 'react-bootstrap';

import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import './AdminActionListContainer.scss';

import {
    getAdminListById,
    syncList
} from "./adminListSlice";


import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import {APP_COLORS} from "../../common/Constants";
import PanelHeader from "../../common/components/layout/PanelHeader";

const AdminSyncContainer = (props) => {

    const adminListState = useSelector(state => state.adminList);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    const [managedList, setManagedList] = useState(null);

    useEffect(() => {
        dispatch(getAdminListById(props.match.params.id));

    }, [props.match.params.id]);

    useEffect(() => {
        if (adminListState.adminList && adminListState.adminList.id === props.match.params.id) {
            setManagedList(adminListState.adminList);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Lists',
                        href: '/admin/lists',
                        isActive: false
                    },
                    {
                        label: `${props.match.params.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'Sync List',
                        href: '',
                        isActive: true
                    }
                 ]
            )
        }
        }
        , [adminListState.adminList]);

    useEffect(() => {
        setAlertMessage(adminListState.alertMessage);
        if (adminListState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [adminListState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(adminListState.successMessage);
        if (adminListState.successMessage) {
            setShowSuccess(true);
            history.push('/admin/lists');
        }else {
            setShowSuccess(false);
        }
    }, [adminListState.successMessage]);

    const handleAdminSync = async () => {
        dispatch(syncList(props.match.params.id));
    };

    const handleCancel = async () => {
        history.push('/admin/lists')
    };

    return (
        <div>
            {adminListState.loading && (
                <div className="spinner-overlay">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <AdminSync
                id={managedList?.id}
                name={managedList?.name}
                handleAdminSync={(data) => handleAdminSync(data)}
                handleCancel={() => handleCancel()}
                status={managedList?.status}
                type={managedList?.type}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function AdminSync(props) {

    const {
        breadcrumbItems,
        id,
        name,
        status,
        type,
        handleAdminSync,
        handleCancel,
    } = props;

    const reset = () => {

    };

    const isValid = () => {
    };

    const handleAdminSyncClick = async () => {
        handleAdminSync({id});
    };

    return (
        <div className="action-list-gutter">
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={`Sync List - ${name ? name : ''}`} subHeader={'Sync the list'} />
            <div className="action-list-container">
                <div className="action-list-form-container">
                    <Form noValidate>
                        <Form.Group controlId="processList">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={9} className="action-list-form-label">
                                            <Form.Label>List Id</Form.Label>
                                            {id &&
                                                <TextHoverDisplay
                                                    fullText={id}
                                                    truncatedText={id}
                                                    displayTruncated={false}
                                                />
                                            }

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9} className="create-input-form-label">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                as="input"
                                                size="sm"
                                                disabled
                                                value={name}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={9} className="create-input-form-label">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control
                                                as="input"
                                                size="sm"
                                                disabled
                                                value={type}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={9} className="create-input-form-label">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                as="input"
                                                size="sm"
                                                disabled
                                                value={status}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        disabled={status !== 'NORMALIZED'}
                                        onClick={() => handleAdminSyncClick()}
                                    >SYNC
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default AdminSyncContainer;

