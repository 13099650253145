/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState} from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import LinkButton from "./LinkButton";
import {APP_COLORS} from "../Constants";

function MoreLessPanel(props) {
    const [more, setMore] = useState(false);
    const visibleChildren = props.children;
    const hideChildren = props.hideChildren;
    return (
        <div className="accordionWrapper">
            <div
                className={classNames({
                    "accordionItem": true,
                })}
            >
                <div
                    className="accordionContent"
                    style={{
                        maxHeight: props.contentHeight ? props.contentHeight : 'auto',
                    }}
                >
                    {visibleChildren}
                    {more &&
                        <div>
                            {hideChildren}
                        </div>
                    }
                    {hideChildren &&
                        <div className="accordionShowMoreContent">
                            <LinkButton
                                onClick={() => setMore(!more)}
                            >
                                {more ?
                                    <span style={{color: APP_COLORS.info}}>LESS</span>
                                    :
                                    <span style={{color: APP_COLORS.info}}>MORE</span>
                                }
                            </LinkButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

MoreLessPanel.propTypes = {
    contentHeight: PropTypes.number,
    isExpand: PropTypes.bool,
    handleOnClick: PropTypes.func,
    hideChildren: PropTypes.object
};

MoreLessPanel.defaultProps = {
    handleOnClick: (e) => e,
};

export default MoreLessPanel;
