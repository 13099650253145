import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    contactTitle: null
};

export const getContactTitleById = createAsyncThunk('contacts/getContactTitleById', async (id) => {
    const GET_CONTACT_TITLE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${id}/title`;
    const contactTitle = await Api.get(true, GET_CONTACT_TITLE_BY_ID_URL);
    return contactTitle;
});

export const changeContactTitleById = createAsyncThunk('contacts/changeCompanyTitleById', async (data) => {
    const CHANGE_CONTACT_TITLE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${data.id}/title/change`;
    const response = await Api.post(true, CHANGE_CONTACT_TITLE_BY_ID_URL, data.body);
    return response;
});

const contactTitleSlice = createSlice({
    name: 'contactTitleSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.contactTitle = null;
            return state;
        }
    },
    extraReducers: {
        [getContactTitleById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getContactTitleById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactTitle = action.payload;
            }
        },
        [getContactTitleById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve contact information';
        },
        [changeContactTitleById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeContactTitleById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactTitle = action.payload;
            }
        },
        [changeContactTitleById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set contact information';
        },
    }
});

export const { clearState } = contactTitleSlice.actions;
export default contactTitleSlice.reducer;
