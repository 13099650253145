/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import config from '../../config';
import * as cache from '../Cache';
import {get, post} from '../Api';

const LOGIN_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/login`;
const LOGOUT_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/logout`;
const ACCOUNT_ENDPOINT = `${config.services.ATLAS.apiUrl}/account`;
const REQUEST_PASSWORD_RESET_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/request-reset-password`;
const RESET_PASSWORD_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/change-password`;
const CONFIRM_ACCOUNT_AND_RESET_PASSWORD_ENDPOINT = `${config.services.ATLAS.apiUrl}/account/confirm`;

/**
 * Check to see if a session exists
 * @returns {boolean}
 */
export function isLoggedIn() {
    if (cache.get(config.constants.CACHE_KEY_TOKEN) && cache.get(config.constants.CACHE_KEY_TOKEN)) return true;
    return false;
}

/**
 * Attempts to log the account holder in
 */
export async function login(credentials) {
    const body = {
        email: credentials.email,
        password: credentials.password
    };
    const token = await post(false, LOGIN_ENDPOINT, body, {}, false);
    cache.set(config.constants.CACHE_KEY_TOKEN, token);
    const account = await get(true, ACCOUNT_ENDPOINT);
    cache.set(config.constants.CACHE_KEY_ACCOUNT, account);
    cache.set(config.constants.CACHE_KEY_API, account.apiKey);
    cache.set(config.constants.CACHE_KEY_JWT, account.jwt);
    return account;
}

export async function logout() {
    try {
        await post(true, LOGOUT_ENDPOINT, null, {}, false);
        cache.del(config.constants.CACHE_KEY_TOKEN);
        cache.del(config.constants.CACHE_KEY_ACCOUNT);
        cache.del(config.constants.CACHE_KEY_API);
        cache.del(config.constants.CACHE_KEY_JWT);
    } catch (error) {
        console.log(error);
        cache.del(config.constants.CACHE_KEY_TOKEN);
        cache.del(config.constants.CACHE_KEY_ACCOUNT);
        cache.del(config.constants.CACHE_KEY_API);
        cache.del(config.constants.CACHE_KEY_JWT);
    }
}

export function getAccount() {
    return cache.get(config.constants.CACHE_KEY_ACCOUNT);
}

export async function requestResetPassword(email) {
    const body = {
        email: email
    };
    await post(false, REQUEST_PASSWORD_RESET_ENDPOINT, body, {}, false);
    return Promise.resolve();
}

export async function resetPassword(password, token) {
    const body = {
        password: password
    };
    await post(false, RESET_PASSWORD_ENDPOINT, body, {'x-api-jwt': token}, false);
    return Promise.resolve();
}


export async function confirmAccountAndSetPassword(password, token) {
    const body = {
        password: password
    };
    await post(false, CONFIRM_ACCOUNT_AND_RESET_PASSWORD_ENDPOINT, body, {'x-api-jwt': token}, false);
    return Promise.resolve();
}

export function getRoleMapping(account) {
    // Admin Mappings
    if (account.roles.includes('ROOT')) return 'ADMIN';
    if (account.roles.includes('ADMIN')) return 'ADMIN';
    // Developer Mappings
    if (account.roles.includes('OWNER')) return 'ORGANIZATION';
    if (account.roles.includes('DEVELOPER')) return 'ORGANIZATION';
    return undefined;
}

export function isOwner(_account) {
    const account = _account ? _account : getAccount();
    if (!account || !account.roles) {
        return false;
    }
    if (getRoleMapping(account) === 'ORGANIZATION')
        return account.roles.includes('OWNER');
        // return account.roles.includes('OWNER') || account.roles.includes('DEVELOPER');

    return false;
}

export function getName(profile) {
    let name = 'Rick Deckard';
    if (profile.givenName && profile.surName) name = `${profile.givenName} ${profile.surName}`;
    return name;
}

export const isAdmin = (_account) => {
    const account = _account ? _account : getAccount();
    if (!account) {
        return false;
    }
    return [
        'ROOT',
        'ADMINISTRATOR'
    ].includes(account.roles);
};
