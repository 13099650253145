import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    contactEmail: null
};

export const getContactEmailById = createAsyncThunk('contacts/getContactEmailById', async (id) => {
    const GET_CONTACT_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${id}/email`;
    const contactEmail = await Api.get(true, GET_CONTACT_EMAIL_BY_ID_URL);
    return contactEmail;
});

export const changeContactEmailById = createAsyncThunk('contacts/changeCompanyEmailById', async (data) => {
    const CHANGE_CONTACT_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${data.id}/email/change`;
    const response = await Api.post(true, CHANGE_CONTACT_EMAIL_BY_ID_URL, data.body);
    return response;
});
export const createContactEmailById = createAsyncThunk('contacts/createeContactEmailById', async (data) => {
    const CREATE_CONTACT_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${data.id}/email`;
    const response = await Api.post(true, CREATE_CONTACT_EMAIL_BY_ID_URL, data.body);
    return response;
});

const contactEmailSlice = createSlice({
    name: 'contactEmailSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.contactEmail = null;
            return state;
        }
    },
    extraReducers: {
        [getContactEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getContactEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactEmail = action.payload;
            }
        },
        [getContactEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve contact information';
        },
        [changeContactEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeContactEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactEmail = action.payload;
            }
        },
        [changeContactEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set contact information';
        },
        [createContactEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
            },
            [createContactEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactEmail = action.payload;
            }
            },
            [createContactEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set contact information';
            }
    }
});

export const { clearState } = contactEmailSlice.actions;
export default contactEmailSlice.reducer;
