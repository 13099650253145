import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    atlasApiPing: undefined,
    atlasCorePing: undefined,
    atlasCubePing: undefined,
};

export const getAtlasCorePing = createAsyncThunk('adminHome/get-atlas-core-ping', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.baseUrl}/ping`;
    return Api.get(true, GET_URL);
});

export const getAtlasApiPing = createAsyncThunk('adminHome/get-atlas-api-ping', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/ping`;
    return Api.get(true, GET_URL);
});

const adminHomeSlice = createSlice({
    name: 'adminHomeSlice', initialState,
    reducers: {
        resetAtlasCorePing: {
            reducer(state, action) {
                state.atlasCorePing = undefined;
            }
        },
        resetAtlasDataPing: {
            reducer(state, action) {
                state.atlasCorePing = undefined;
            }
        },
        resetAtlasCubePing: {
            reducer(state, action) {
                state.atlasCorePing = undefined;
            }
        },
    },
    extraReducers: {
        [getAtlasCorePing.pending]: (state, action) => {
            state.loading = true;
        },
        [getAtlasCorePing.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.atlasCorePing = action.payload;
            }
        },
        [getAtlasCorePing.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAtlasApiPing.pending]: (state, action) => {
            state.loading = true;
        },
        [getAtlasApiPing.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.atlasApiPing = action.payload;
            }
        },
        [getAtlasApiPing.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});


export const {
    resetAtlasCorePing,
    resetAtlasApiPing,
    resetAtlasCubePing,
} = adminHomeSlice.actions;
export default adminHomeSlice.reducer;
