/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {map} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {removeFilter as removeCompanyFilter} from "./companySlice";
import {removeFilter as removeContactFilter} from "./contactSlice";
import AppliedFilter from "../../common/components/AppliedFilter";
import LockedFilter from "../../common/components/LockedFilter";
import LockedFilterWithOr from "../../common/components/LockedFilterWithOr";
import classNames from "classnames";
import LinkButton from "../../common/components/LinkButton";
import * as cache from "../../common/Cache";
import config from "../../config";

export const ExploreAppliedFilters = (props) => {
    const currentMode = props.exploreMode.mode;
    const dispatch = useDispatch();

    const handleRemoveCompanyFilter = (id) =>  {
            return dispatch(removeCompanyFilter({id}));
    };

    const handleRemoveContactFilter = (id) =>  {
        return dispatch(removeContactFilter({id}));
    };

    if (currentMode === 'COMPANY') {
        return (
            <CompanyAppliedFilters
                handleRemove={handleRemoveCompanyFilter}
            />
        )
    }
    return (
        <ContactAppliedFilters
            handleRemove={handleRemoveContactFilter}
        />
    )
};

const CompanyAppliedFilters = (props) => {
    const exploreDataState = useSelector(state => state.company);
    const metaDataState = useSelector(state => state.metaData);

    // Probably a better way to do this, inject locked filters at render
    // This has no bearing on what is sent to cube
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    // console.log('config', account.organization.config);

    const _filters = Object.assign([], exploreDataState.filters);
    // Score
    const score = exploreDataState.accuracyData ? exploreDataState.accuracyData.minScore : account.organization.config.scores.companyScore;
    _filters.push({
        id: 'LOCKED_COMPANY_SCORE',
        display: 'Company Score',
        field: 'CompanyContractorUniverse.company_score',
        operatorDisplay: 'between',
        operatorId: 'between',
        component: 'rangeslider',
        value: {
            min: score,
            max: 1
        },
        step: 0.01,
        fieldDisplay: '[company_score]'
    });

    const _filtersForOrganization = buildOrganizationAppliedFilters(_filters, account.organization.config, metaDataState);
    const renderFilter = createAppliedFilters(_filtersForOrganization, props.handleRemove);
    return (
        <FilterBox>
            {renderFilter}
        </FilterBox>
    )
};

const ContactAppliedFilters = (props) => {
    const exploreDataState = useSelector(state => state.contact);
    const metaDataState = useSelector(state => state.metaData);
    // Probably a better way to do this, inject locked filters at render
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    const score = exploreDataState.accuracyData ? exploreDataState.accuracyData.minScore : account.organization.config.scores.contactScore;
    const _filters = Object.assign([], exploreDataState.filters);
    _filters.push({
        id: 'LOCKED_CONTACT_SCORE',
        display: 'Contact Score',
        field: 'ContactContractorUniverse.contact_score',
        operatorDisplay: 'between',
        operatorId: 'between',
        component: 'rangeslider',
        value: {
            min: score,
            max: 1
        },
        step: 0.01,
        fieldDisplay: '[contact_score]'
    });
    const _filtersForOrganization = buildOrganizationAppliedFilters(_filters, account.organization.config, metaDataState);
    const renderFilter = createAppliedFilters(_filtersForOrganization, props.handleRemove);
    return (
        <FilterBox>
            {renderFilter}
        </FilterBox>
    )
};

const FilterBox = (props) => {
    const {
        children
    } = props;

    const filterRef = React.createRef();

    const [showLessMoreAction, setShowLessMoreAction] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [moreFilters, setMoreFilters] = useState(0);

    const handleSelect = () => setShowMore(!showMore);

    useEffect(() => {
        let moreNumber = 0;

        for (const el of filterRef.current.childNodes) {
            if (el.className !== 'show-action' && el.offsetTop > 10) {
                moreNumber++;
            }
        }

        setShowLessMoreAction(moreNumber > 0);
        showMore && setShowMore(moreNumber > 0);
        setMoreFilters(moreNumber);
    }, [children.length]);

    return (
        <div className='filters-applied-container'>
            <div
                ref={filterRef}
                className={classNames({
                    "filters-applied": true,
                    "filters-applied-more": showMore
                })}
            >
                {showLessMoreAction &&
                    <div className='show-action' onClick={handleSelect}>
                        <LinkButton size={'md'}>
                            {!showMore ? `more (${moreFilters})` : 'less'}
                        </LinkButton>
                    </div>
                }
                {children}
            </div>
        </div>
    );
};

const createAppliedFilters = (filters, handleRemove) => {
    // TODO: Here - Need to splice the filters if hidden filters are supplied ...
    return map(filters, (filter, idx) => {
        // Locked Filters (THIS NEEDS TO BE FIRST)
        if (filter.id.slice(0, 6) === 'LOCKED') {
            if (filter.operatorId === 'between') {
                return (
                    <LockedFilter
                        key={idx}
                        style={{marginLeft: '10px'}}
                        id={filter.id}
                        name={filter.fieldDisplay || filter.field}
                        detail={`${filter.operatorDisplay} [${filter.value?.min}, ${filter.value?.max}]`}
                        handleRemove={handleRemove}
                        lockOpen={true}
                    />
                );
            }
            if (filter.operatorId === 'contains' && Array.isArray(filter.value)) {
                const operands = map(filter.value, v => v.display).join(', ');
                if (filter.orHasAnyValue) {
                    return (
                        <LockedFilterWithOr
                            key={idx}
                            style={{marginLeft: '10px'}}
                            id={filter.id}
                            name={filter.fieldDisplay || filter.field}
                            detail={`${filter.operatorDisplay} [${operands}]`}
                            handleRemove={handleRemove}
                            lockOpen={false}
                        />
                    );
                }
                return (
                    <LockedFilter
                        key={idx}
                        style={{marginLeft: '10px'}}
                        id={filter.id}
                        name={filter.fieldDisplay || filter.field}
                        detail={`${filter.operatorDisplay} [${operands}]`}
                        handleRemove={handleRemove}
                        lockOpen={false}
                    />
                );
            }
            if (filter.operatorId === 'equals') {
                const operands = map(filter.value, v => v.display || v.id).join(', ');
                return (
                    <LockedFilter
                        key={idx}
                        style={{marginLeft: '10px'}}
                        id={filter.id}
                        name={filter.fieldDisplay || filter.field}
                        detail={`${filter.operatorDisplay} [${operands}]`}
                        handleRemove={handleRemove}
                        lockOpen={false}
                    />
                );
            }
            if (filter.operatorId === 'notEquals') {
                const operands = map(filter.value, v => v.display || v.id).join(', ');
                return (
                    <LockedFilter
                        key={idx}
                        style={{marginLeft: '10px'}}
                        id={filter.id}
                        name={filter.fieldDisplay || filter.field}
                        detail={`${filter.operatorDisplay} [${operands}]`}
                        handleRemove={handleRemove}
                        lockOpen={false}
                    />
                );
            }
        }
        // Unlocked Filters
        if (filter.operatorId === 'equals') {
            const operands = map(filter.value, v => v.display || v.id).join(', ');
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${operands}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'notEquals') {
            const operands = map(filter.value, v => v.display || v.id).join(', ');
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${operands}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'between') {
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${filter.value?.min}, ${filter.value?.max}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'set') {
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay}`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'multiple_boolean') {
            const multiValues = map(filter.value, v => v.display).join(', ');
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${multiValues}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'lat_long_radius') {
            const {
                latitude,
                longitude,
                radius
            } = filter.value;
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} ${radius} mi from ${latitude}, ${longitude}`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'contains' && Array.isArray(filter.value)) {
            const operands = map(filter.value, v => v.display).join(', ');
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${operands}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        if (filter.operatorId === 'notContains' && Array.isArray(filter.value)) {
            const operands = map(filter.value, v => v.display).join(', ');
            return (
                <AppliedFilter
                    key={idx}
                    style={{marginLeft: '10px'}}
                    id={filter.id}
                    name={filter.fieldDisplay || filter.field}
                    detail={`${filter.operatorDisplay} [${operands}]`}
                    handleRemove={handleRemove}
                />
            );
        }
        return (
            <AppliedFilter
                key={idx}
                style={{marginLeft: '10px'}}
                id={filter.id}
                name={filter.fieldDisplay || filter.field}
                detail={`${filter.operatorDisplay} ${filter.value}`}
                handleRemove={handleRemove}
            />
        );

    });
}

/***
 * Enforced by the backend (but we should test that) these filters are display only and are not added to the local
 * state
 */
const buildOrganizationAppliedFilters = (filters = [], organizationConfig, metaDataState) => {
    const _filters = Object.assign([], filters);

    // Geography
    const geographies = organizationConfig.subscription.geographies;
    if (geographies) {
        if (geographies.unrestricted) {
            // do nothing
        }

        // US Filters
        const usGeographies = geographies.countries.us;
        if (usGeographies.msas) {
            _filters.push({
                id: 'LOCKED_COMPANY_ADDRESS_LOC_MSA',
                display: 'MSA',
                field: 'CompanyContractorUniverse.company_address_loc_msa',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_address_loc_msa]',
                value: _.filter(metaDataState?.companyAddressLocMsa, (o) => _.includes(usGeographies.msas, o.id)),
                orHasAnyValue: false
            });
        }

        if (usGeographies.cities) {
            _filters.push({
                id: 'LOCKED_COMPANY_ADDRESS_CITY',
                display: 'City',
                field: 'CompanyContractorUniverse.company_address_city',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_address_city]',
                value: usGeographies.cities.map(i => ({id: i, display: i})),
                orHasAnyValue: false
            });
        }

        if (usGeographies.regions) {
            _filters.push({
                id: 'LOCKED_COMPANY_ADDRESS_REGION',
                display: 'City',
                field: 'CompanyContractorUniverse.company_address_region',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_address_region]',
                value: _.filter(metaDataState?.companyAddressRegion, (o) => _.includes(usGeographies.regions, o.id)),
                orHasAnyValue: false
            });
        }
    }

    // Taxonomy
    // TODO: Remember this needs to be kept in sync with the backend (cube)
    const taxonomy = organizationConfig.subscription.products.taxonomy;
    if (taxonomy) {
        if (taxonomy.unrestricted) {
            // do nothing
        }

        // Filter on Trades
        if (taxonomy.classifications && taxonomy.classifications.filterOn === 'trades') {
            _filters.push({
                id: 'LOCKED_COMPANY_CLASS_TRADES',
                display: 'All Trades',
                field: 'CompanyContractorUniverse.company_class_trades',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'LOCKED_COMPANY_CLASS_TRADES',
                multiple: true,
                fieldDisplay: '[company_class_trades]',
                value: _.filter(metaDataState?.companyClassTrades, (o) => _.includes(taxonomy.classifications.allowed, o.id)),
                orHasAnyValue: taxonomy.classifications.allowUnclassified
            });
        }

        if (taxonomy.classifications && taxonomy.classifications.filterOn === 'naics') {
            _filters.push({
                id: 'LOCKED_COMPANY_CLASS_NAICS_CODES',
                display: 'All Trades',
                field: 'CompanyContractorUniverse.company_class_naics_codes',
                operatorDisplay: 'in',
                operatorId: 'contains',
                component: 'combobox',
                metaDataId: 'LOCKED_COMPANY_CLASS_NAICS_CODES',
                multiple: true,
                fieldDisplay: '[company_class_naics_codes]',
                value: taxonomy.classifications.allowed.map(i => ({id: i, display: i})), // TODO: Update Once We have metadata map
                orHasAnyValue: taxonomy.classifications.allowUnclassified
            });
        }

        // TODO: Add Support for Segments and Services
    }
    return _filters;
}
