/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';

import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {Tabs, Tab} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faMapMarkerAlt,
    faListUl,
    faTrashAlt,
    faBuilding,
    faAddressBook,
    faLock
} from '@fortawesome/free-solid-svg-icons'
import {APP_COLORS} from '../../common/Constants';
import ExploreMapContainer from './ExploreMapContainer';
import {CompanyFilter} from "./CompanyFilter";
import {ContactFilter} from './ContactFilter';
import {ExploreAppliedFilters} from "./ExploreAppliedFilters";
import LinkButton from '../../common/components/LinkButton';
import Sidebar from "../../common/components/Sidebar";
import SideBarContent from "../../common/components/SidebarContent";
import UploadListDialog from "./upload/UploadListDialog";
import SaveModal from './SaveModal'
import Spinner from "react-bootstrap/Spinner";
import SearchFilterTypeAhead from './SearchFilterTypeAhead';
import moment from "moment/moment";
import Modal from "../../common/components/Modal";
import ExploreCards from "./components/cards/ExploreCards";
import ExploreAccuracyConfig from "./ExploreAccuracyConfig";
import ExportModal from "./ExportModal";

export default class Explore extends Component {
    static propTypes = {
        handleClearAllFilter: PropTypes.func,
        handleToggleFilter: PropTypes.func,
        handleSearch: PropTypes.func,
        handleOnSelectSearchCriteria: PropTypes.func,
        exploreMode: PropTypes.object,
        currentFilters: PropTypes.array,
        hasFilter: PropTypes.bool,
        handleSettingAccuracy: PropTypes.func,
        accuracyData: PropTypes.object,
        canExport: PropTypes.bool,
    };

    static defaultProps = {
        exploreMode: {
            mode: 'COMPANY',
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: this.props.loading,
            toggleMap: this.props.exploreMode.mode === 'COMPANY' && this.props.exploreMode.company.toggleMap,
            expanded: true,
            showApplySearchConfirmModal: {
                show: false,
                selectedSearch: null
            }
        };
    }

    toggleSidebar() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    render() {
        const {
            totalContacts,
            totalCompanies,
            currentFilters,
            exploreMode,
            loading,
            hasFilter,
            canExport,
            accuracyData,
            appliedFilters
        } = this.props;
        return (
            <main>
                <Sidebar toggleSidebar={this.toggleSidebar.bind(this)} expanded={this.state.expanded}>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <div>
                                <Tabs defaultActiveKey={this.props.exploreMode.mode}
                                      transition={false}
                                      onSelect={(eventKey) => this.props.handleToggleFilter({
                                          ...this.props.exploreMode,
                                          mode: eventKey
                                      })}
                                >
                                    <Tab eventKey="COMPANY" title={
                                        <div style={{color: APP_COLORS['tech-blue']}}>
                                            <FontAwesomeIcon
                                                color={APP_COLORS['tech-blue']}
                                                icon={faBuilding}
                                                style={{marginRight: '10px'}}
                                            />
                                            Company
                                        </div>
                                    }>
                                        <CompanyFilter/>
                                    </Tab>
                                    <Tab eventKey="CONTACT" title={
                                        <div style={{color: APP_COLORS['tech-blue']}}>
                                            <FontAwesomeIcon
                                                color={APP_COLORS['tech-blue']}
                                                icon={faAddressBook}
                                                style={{marginRight: '10px'}}
                                            />
                                            Contact
                                        </div>
                                    }>
                                        <ContactFilter/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Sidebar>
                <SideBarContent expanded={this.state.expanded}>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <div className="panel" style={{backgroundColor: APP_COLORS['gray-100']}}>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <div className="filters-panel">
                                                    {/* TODO: STYLE CUSTOM LINK OR BUTTON FOR CLICKABLE CLEAR ALL TRASH CAN ICON - faTrashCanAlt - looks really squished*/}
                                                    <div style={{padding: '7px 0 0 5px'}}>
                                                        <span className="md"
                                                              style={{color: APP_COLORS['tech-blue']}}>Filters </span>
                                                        <LinkButton
                                                            size={'md'}
                                                            onClick={() => this.props.handleClearAllFilter()}
                                                            style={{paddingTop: 0}}
                                                        >
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    size="xs"
                                                                    color={APP_COLORS.info}
                                                                    icon={faTrashAlt}
                                                                />
                                                                <span className="small"
                                                                      style={{color: APP_COLORS.info}}> CLEAR ALL</span>
                                                            </div>
                                                        </LinkButton>
                                                    </div>
                                                    <ExploreAppliedFilters
                                                        exploreMode={this.props.exploreMode}
                                                    />
                                                    {loading && (
                                                        <Button className="btn-min-width-120 btn-height-35"
                                                                style={{marginLeft: 'auto'}}
                                                                variant="info"
                                                                disabled>
                                                            <Spinner
                                                                style={{marginRight: '5px', verticalAlign: 'baseline'}}
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            Loading..
                                                        </Button>)}
                                                    {!loading && (
                                                        <Button
                                                            className="btn-min-width-120 btn-height-35"
                                                            style={{marginLeft: 'auto'}}
                                                            variant="info"
                                                            onClick={() => this.props.handleSearch()}
                                                        >
                                                            APPLY FILTER
                                                        </Button>)
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '10px'}}>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <div>
                                                    <span className="small"
                                                          style={{color: APP_COLORS['tech-blue']}}><span
                                                        style={{fontWeight: '600', color: APP_COLORS['orange']}}>{totalCompanies ? totalCompanies.replace(/(.)(?=(\d{3})+$)/g,'$1,') : 0}</span> Companies | <span
                                                        style={{fontWeight: '600', color: APP_COLORS['orange']}}>{totalContacts ? totalContacts.replace(/(.)(?=(\d{3})+$)/g,'$1,') : 0}</span> Contacts</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                <div className="horizontal-divider" style={{backgroundColor: 'pink'}}/>
                                            </Col>
                                        </Row>
                                        <Row className="exploreToolBar">
                                            <Col xl={12} lg={12} md={12} sm={12} className="exploreTool">
                                                <div className="exploreToolAction">
                                                    <Button
                                                        className="btn-min-width-120 btn-height-35"
                                                        variant="info"
                                                        onClick={() => {
                                                            this.setState({showExportDialog: true});
                                                        }}
                                                        disabled={!canExport || !appliedFilters}
                                                    >
                                                        EXPORT
                                                        {!canExport && (
                                                            <>&nbsp;&nbsp;<FontAwesomeIcon
                                                                icon={faLock}
                                                                // style={{fontSize: "12px"}}
                                                            /></>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        className="btn-min-width-120 btn-height-35"
                                                        variant="outline-secondary"
                                                        onClick={() => this.setState({showImportDialog: true})}
                                                    >
                                                        IMPORT
                                                    </Button>
                                                    <Button
                                                        className="btn-min-width-120 btn-height-35"
                                                        variant="outline-secondary"
                                                        disabled={!currentFilters || currentFilters.length === 0}
                                                        onClick={() => this.setState({showSaveSearchDialog: true})}
                                                    >
                                                        SAVE
                                                    </Button>
                                                    <SearchFilterTypeAhead
                                                        style={{marginLeft: '10px', width: '300px'}}
                                                        mode={this.props.exploreMode.mode}
                                                        onChange={(selectedSearch) => {
                                                            if (hasFilter) {
                                                                this.setState(
                                                                    {
                                                                        showApplySearchConfirmModal: {
                                                                            show: true,
                                                                            selectedSearch: selectedSearch
                                                                        }
                                                                    }
                                                                );
                                                                return;
                                                            }
                                                            this.props.handleOnSelectSearchCriteria(selectedSearch);
                                                        }}
                                                    />
                                                    <ExploreAccuracyConfig
                                                        accuracyData={accuracyData}
                                                        onChange={(v) => this.props.handleSettingAccuracy(v)}
                                                    />
                                                </div>
                                                {this.props.exploreMode.mode === 'COMPANY' &&
                                                <div className="exploreToolSwitch">
                                                    <ButtonGroup toggle>
                                                        <ToggleButton
                                                            type="radio"
                                                            variant="light"
                                                            name="radio"
                                                            value="toggle-map"
                                                            checked={this.state.toggleMap}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    toggleMap: !this.state.toggleMap,
                                                                }, () => this.props.handleToggleFilter({
                                                                        ...this.props.exploreMode,
                                                                        company: {
                                                                            ...this.props.exploreMode.company,
                                                                            toggleMap: this.state.toggleMap
                                                                        }
                                                                    }
                                                                ))
                                                            }}
                                                            className="btn-toggle"
                                                        >
                                                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            type="radio"
                                                            variant="light"
                                                            name="radio"
                                                            value="toggle-list"
                                                            checked={!this.state.toggleMap}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    toggleMap: !this.state.toggleMap,
                                                                }, () => this.props.handleToggleFilter({
                                                                        ...this.props.exploreMode,
                                                                        company: {
                                                                            ...this.props.exploreMode.company,
                                                                            toggleMap: this.state.toggleMap
                                                                        }
                                                                    }
                                                                ))
                                                            }}
                                                            className="btn-toggle"
                                                        >
                                                            <FontAwesomeIcon icon={faListUl}/>
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                </div>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {this.state.showImportDialog &&
                                <div>
                                    <UploadListDialog
                                        show={this.state.showImportDialog}
                                        handleClose={() => this.setState({showImportDialog: false})}
                                    />
                                </div>
                            }

                            {this.state.showSaveSearchDialog &&
                                <div>
                                    <SaveModal
                                        title={'Save Search'}
                                        show={this.state.showSaveSearchDialog}
                                        handleClose={() => this.setState({showSaveSearchDialog: false})}
                                        currentFilters={currentFilters}
                                        exploreMode={exploreMode}
                                    />
                                </div>
                            }
                            {this.state.showExportDialog &&
                            <div>
                                <ExportModal
                                    title={'Export'}
                                    show={this.state.showExportDialog}
                                    handleClose={() => this.setState({showExportDialog: false})}
                                    currentFilters={currentFilters}
                                    appliedFilters={appliedFilters}
                                    exploreMode={exploreMode}
                                />
                            </div>
                            }
                            {this.state.showApplySearchConfirmModal && this.state.showApplySearchConfirmModal.show &&
                                <ApplySearchConfirmModel
                                    selectedSearch={this.state.showApplySearchConfirmModal.selectedSearch}
                                    onCancel={() => this.setState(
                                        {
                                            showApplySearchConfirmModal: {
                                                show: false
                                            }
                                        }
                                        )}
                                    onSubmit={() => this.setState(
                                        {
                                            showApplySearchConfirmModal: {
                                                    ...this.state.showApplySearchConfirmModal,
                                                    show: false
                                                }
                                        }, () => this.props.handleOnSelectSearchCriteria(this.state.showApplySearchConfirmModal.selectedSearch))}

                                />
                            }

                            <div>
                                {/*VIEW MODE = MAP*/}
                                {this.state.toggleMap && this.props.exploreMode.mode === 'COMPANY' &&
                                    <Row className="mt-3">
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <div className="panel shadow-sm" style={{padding: '0px'}}>
                                                <ExploreMapContainer
                                                    exploreMode={this.props.exploreMode.mode}
                                                    currentFilters={currentFilters}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {/*VIEW MODE = LIST*/}
                                {!this.state.toggleMap && this.props.exploreMode.mode === 'COMPANY' &&
                                    <ExploreCards
                                        loading={loading}
                                        mode={'COMPANY'}
                                        handleSearch={this.props.handleSearch}
                                        expanded={this.state.expanded}
                                    />
                                }
                                {/*VIEW MODE = LIST*/}
                                {this.props.exploreMode.mode === 'CONTACT' &&
                                    <ExploreCards
                                        loading={loading}
                                        mode={'CONTACT'}
                                        handleSearch={this.props.handleSearch}
                                        expanded={this.state.expanded}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </SideBarContent>
            </main>
        )
    }
}

const ApplySearchConfirmModel = (props) => {
    const {
        selectedSearch,
        loading,
        onCancel,
        onSubmit,
    } = props;
    return (
        <Modal
            title={'Load Search'}
            footer={
                (
                    <div className={'footer'}>
                        <Button
                            variant='primary'
                            onClick={
                                () => {
                                    onSubmit()
                                }
                            }
                        >
                            {
                                loading ?
                                    <div>
                                        <Spinner
                                            style={{marginRight: '5px', verticalAlign: 'baseline'}}
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading..
                                    </div>
                                    :
                                    <span>
                                        Load
                                    </span>
                            }
                        </Button>
                        <Button
                            variant='secondary'
                            style={{marginLeft: '10px'}}
                            onClick={() => {
                                onCancel();
                            }
                            }>
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            <div className="modal-body">
                <div style={{padding: '50px', color: APP_COLORS.blue}}>
                    <p>
                        Are you sure you want to load:
                        <br/>
                        <span>{selectedSearch?.display}
                            <span style={{fontStyle: 'italic', paddingLeft: '10px', color: APP_COLORS['gray-500']}}>
                                    ({moment(selectedSearch?.updated).fromNow()})
                                </span>
                            </span>
                        <br/>
                        <br/>
                        <span style={{color: APP_COLORS.yellow}}>
                                Warning Loading your previous search will undo your active changes. This cannot be undone
                        </span>
                    </p>
                </div>
            </div>
        </Modal>

    );
};
