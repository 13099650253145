import React, { useState} from 'react';
import '../ManagedCompanies.scss'
import { Row, Col, Collapse, Button } from 'react-bootstrap';
import {APP_COLORS} from "../../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import CardLabel from '../../../organization/explore/components/cards/CardLabel';
import { LeadText } from './StyledComponents';
import {faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
const SectionCollapse = (props) => {
    const { 
        isOpen=false
    } = props;

    const [open, setOpen] = useState(isOpen);

    return(
        <div style={{width: '100%', padding: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <LeadText style={{fontSize: '14px'}}>{props.header}</LeadText>
                <Button
                    variant={'link'}
                    onClick={() => setOpen(!open)}
                >
                   { open ? (
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                )}
                </Button>
            </div>
                <Collapse in={open}>
                <div>
                    {props.children}
                </div>
            </Collapse>
        </div>
    )
}

export default SectionCollapse;