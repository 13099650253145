/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {InputGroup} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-solid-svg-icons";

import {useDispatch, useSelector} from "react-redux";

import {getOrganizationByIdWithFilter} from "../../organizations/organizationsSlice";
import {clearAccounts, getAccountsWithFilter} from "../../accounts/managedAccountsSlice";
import TextHoverDisplay from "../../../common/components/TextHoverDisplay";

import {isArray} from "lodash";
import FormLabel from "../../../common/components/form/FormLabel";
import FormRequiredText from "../../../common/components/form/FormRequiredText";

const FormSearchAccountsTypeahead = (props) => {
    const {
        label,
        organization,
        account,
        onChange,
        isInvalid,
        editing,
        required,
        disabled
    } = props;

    const dispatch = useDispatch();
    const organizationState = useSelector(state => state.organizations);
    const accountState = useSelector(state => state.managedAccounts);

    const searchAccount = (search) => {
        dispatch(clearAccounts());
        const membersQuery = {
            filter: {
                include: ['members']
            }
        };

        dispatch(getOrganizationByIdWithFilter({id: organization, filter: membersQuery}));

        const query = {
            filter: {
                where: {
                    and: [
                        {
                            organizationId: organization
                        },
                        {
                            givenName: {
                                ilike: `%${search ? search : ''}%`
                            }
                        }
                    ]
                }
            },
        };

        dispatch(getAccountsWithFilter(query));
    };

    return (
        <>
            {label && (
                <FormLabel>
                    {label}
                    {required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            )}
            { editing ? (
                <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSearch} style={{fontSize: '16px'}} />
                    </InputGroup.Text>
                    <AsyncTypeahead
                        id="search-account-typeahead"
                        isLoading={accountState.loading}
                        labelKey="username"
                        minLength={1}
                        onSearch={searchAccount}
                        options={accountState.accounts}
                        placeholder="Search accounts..."
                        renderMenuItemChildren={(option) => (
                            <div key={option.id}>
                                <span>{option.username}</span>
                            </div>
                        )}
                        onChange={onChange}
                        selected={account}
                        clearButton
                        isInvalid={isInvalid}
                        disabled={disabled}
                        useCache={false}
                    />
                </InputGroup>
            ) : (
                <TextHoverDisplay
                    fullText={isArray(account) ? account[0]?.id : account?.id }
                    truncatedText={isArray(account) ? account[0]?.id : account?.id}
                    displayTruncated={false}
                />
            )}
        </>
    )
}

export default FormSearchAccountsTypeahead;
