import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    companyAddress: null,
    normalizedCompanyAddress: null
};

export const getCompanyAddressById = createAsyncThunk('companies/getCompanyAddressById', async (id) => {
    const GET_COMPANY_ADDRESS_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${id}/address`;
    const companyAddress = await Api.get(true, GET_COMPANY_ADDRESS_BY_ID_URL);
    return companyAddress;
});

export const changeCompanyAddressById = createAsyncThunk('companies/changeCompanyAddressById', async (data) => {
    const CHANGE_COMPANY_ADDRESS_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/address/change`;
    const response = await Api.post(true, CHANGE_COMPANY_ADDRESS_BY_ID_URL, data.body);
    return response;
});

export const normalizeCompanyAddress = createAsyncThunk('companies/normalizeCompanyAddress', async (body) => {
    const GET_COMPANY_ADDRESS_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/hygiene/address/normalize`;
    const normalizedCompanyAddress = await Api.post(true, GET_COMPANY_ADDRESS_BY_ID_URL, body);
    return normalizedCompanyAddress;
});

const companyAddressSlice = createSlice({
    name: 'companyAddressSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.companyAddress = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyAddressById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyAddressById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyAddress = action.payload;
            }
        },
        [getCompanyAddressById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
        [changeCompanyAddressById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeCompanyAddressById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyAddress = action.payload;
            }
        },
        [changeCompanyAddressById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
        [normalizeCompanyAddress.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [normalizeCompanyAddress.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.normalizedCompanyAddress = action.payload;
            }
        },
        [normalizeCompanyAddress.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
    }
});

export const { clearState } = companyAddressSlice.actions;
export default companyAddressSlice.reducer;
