/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {find, findIndex, concat, maxBy, get as _get} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import Spinner from 'react-bootstrap/Spinner';

import mapboxgl from '!mapbox-gl'; //eslint-disable-line
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {APP_COLORS} from '../../common/Constants';
import {buildGeoJsonForOverlayOverlay, buildGeoJsonForCompanyOverlay, buildGeoJsonForSupplierOverlay} from './GeoJsonBuilder';
import './ExploreMap.scss';

import {setOverlaySelect} from "./exploreSlice";
import ReactResizeDetector from 'react-resize-detector';

import ExploreMapFlyout from './ExploreMapFlyout';

import svgIcons from '../../common/assets/img/fa-icons/solid.svg';
import {getListImportOverlayRecord, getListImportCompanyRecord, getListImportContactRecord} from "../../common/services/ListService";
import {getSupplierRecordBySourceId} from "../../common/services/SupplierService";
import {TYPES as SourceTypes} from '../../admin/sources/SourceTypes';

mapboxgl.accessToken = 'pk.eyJ1Ijoib3BzLWF0LWdldHRvb2xiZWx0LWRvdC1jb20iLCJhIjoiY2tqYWVlazdqN2FoajJ2cGRyaDM0b2w5dSJ9.0ghFxBToWVY2tiKhPDrs6w';

const initialCoordinates = [-98.2883, 41.1289];
const initialZoom = 3.75;

export default function ExploreMap(props) {
    const dispatch = useDispatch();

    // Redux State
    const exploreDataState = useSelector(state => state.explore);
    const companyDataState = useSelector(state => state.company);
    const contactDataState = useSelector(state => state.contact);

    // Loading Spinner
    const [loading, setLoading] = useState(true);

    // For Setting Current Map lat, lng and zoom
    const [lng, setLng] = useState(initialCoordinates[0]);
    const [lat, setLat] = useState(initialCoordinates[1]);
    const [zoom, setZoom] = useState(initialZoom);

    // One of CITY, MSA or ADDRESS
    // MSA is not currently supported
    // WHEN count_companies <= 10k mapMode = ADDRESS
    const [exploreMapMode, setExploreMapMode] = useState('CITY');

    // Overlays
    // Track what has / has not been rendered - for event listener handling
    const [registeredListeners, setRegisteredListeners] = useState([]);
    const [renderedOverlays, setRenderedOverlays] = useState([]);
    const [renderedProducts, setRenderedProducts] = useState([]);

    // One of NONE, CLUSTERED, UNCLUSTERED, LOCATION
    // TODO: Rename to 'exploreMapFlyoutDetailMode'
    const [exploreMapFlyoutMode, setExploreMapFlyoutMode] = useState('CLUSTERED');

    const [exploreMapFlyoutDetailMode, setExploreMapFlyoutDetailMode] = useState('NONE');

    // For displaying the flyout Overlays, Location and Point Detail
    const [showFlyout, setShowFlyout] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState(null);

    const mapData = exploreDataState.exploreMode.mode === 'COMPANY' ? companyDataState.companiesGeo : contactDataState.contactsGeo;

    // Map References
    const [map, setMap] = useState(exploreDataState.map);
    const mapContainer = useRef(null);

    // Explore Overlay Typeahead

    // Helpers
    const handleDataPointMouseEnter = (e) => {
        e.target.getCanvas().style.cursor = 'pointer';
    }

    const handleDataPointMouseLeave = (e) => {
        e.target.getCanvas().style.cursor = '';
    }

    // Initial Map Load
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v10",
            center: [lng, lat],
            zoom: zoom
        });

        // Controls
        map.addControl(new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'City, State, Zip ...',
            // set marker to false to not drop one
            marker: false,
            // marker: {
            //     color: APP_COLORS['blue'],
            // },
            countries: 'us',
            // types: 'place'
        }), 'top-left');

        const nav = new mapboxgl.NavigationControl({visualizePitch: true});
        map.addControl(nav, 'top-left');

        map.on('load', () => {
            if (map && mapData && mapData.features.length > 0) {
                // If we have load the map data we could load it here, but were just initializing the map
                buildDefaultProductMap(map, true);
            } else {
                setMap(map);
                // dispatch(setExploreMap(map));
                setLoading(false);
            }
        });

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

        // TODO: What's the best event for the loading cycle
        //  https://docs.mapbox.com/mapbox-gl-js/api/map/#map#loaded
        //  it appears the predominant check ... whats causing the lag
        //  is the useEffects comparison (deps[])
        // map.on('idle', () => {
        //     console.log('A idle event occurred.');
        // });

        // eslint-disable-next-line
    }, []); // Important! we want to initialize the map on component mount

    useEffect(() => {
        // This effect runs post-component mounting, and should only run during updates
        if (map) {
            if (!map.getSource('data')) {
                buildDefaultProductMap(map);
            } else {
                map.getSource('data').setData(mapData);
            }
        }
        // TODO: Create a hash that can be compared rather than the mapData ... too big?
    }, [mapData && mapData.features.length]); // Important! We only run this effect when the map changes!

    useEffect(() => {
        if (map && exploreDataState.overlaySelect) {
            handleOverlaySelection('ADD', exploreDataState.overlaySelect);
        }
    }, [map]);

    /*


    CLUSTERED MAP


     */

    const buildDefaultProductMap = (map, disableLoading = false) => {
        // TODO: Revisit when the right time for this is
        // setLoading(true);
        // TODO: Conditional Checks on unclustered data points
        // Remove unclustered data points
        if (map.getLayer('unclustered-data-point')) map.removeLayer('unclustered-data-point');
        // To remove click events or other listeners (resets everything)
        if (map.getSource('unclustered-data')) map.removeSource('unclustered-data');
        // Close Detail if open
        setShowFlyout(false);

        // Remove Layers and Create new ones on change
        const layers = ['clusters', 'cluster-count', 'unclustered-point', 'unclustered-count', 'unclustered-record-point'];
        layers.forEach((l) => {
            if (map.getLayer(l)) map.removeLayer(l);
        });

        if (map.getSource('data')) map.removeSource('data');
        map.addSource('data', {
            type: 'geojson',
            data: mapData,
            cluster: true,
            clusterMaxZoom: 5, // Max zoom to cluster points on, match to unclustered points, the behvaiour here is not what you think. read the docs
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            clusterMinPoints: 2,
            clusterProperties: {
                // TODO This would need to change based on view company / contact
                count: ['+', ['get', 'count_companies']],
                countContacts: ['+', ['get', 'count_contacts']],
            }
        });

        map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'data',
            layout: {
                'visibility': 'visible'
            },
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': APP_COLORS.orange,
                'circle-radius': [
                    'step',
                    ['get', 'count'],
                    30, 1000,
                    40, 10000,
                    50
                ]
            }
        });

        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'data',
            filter: ['has', 'point_count'],
            layout: {
                // TODO: We'll need to use an expression to abbreviate and format the cont total
                'text-field': '{count}',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'visibility': 'visible'
            },
            paint: {
                'text-color': '#fff'
            }
        });

        map.addLayer({
            id: 'cluster-location_place_point',
            type: 'circle',
            source: 'data',
            layout: {
                'visibility': 'visible'
            },
            filter:  ['all', ['>=', 'count_companies',1], ['!has', 'point_count'], ['!has', 'company_score']],
            paint: {
                'circle-radius': 20,
                'circle-color': APP_COLORS.orange
            },
        });
        map.addLayer({
            id: 'cluster-location_place_count',
            type: 'symbol',
            source: 'data',
            filter:  ['all', ['>=', 'count_companies',1], ['!has', 'point_count'], ['!has', 'company_score']],
            layout: {
                // TODO: We'll need to use an expression to abbreviate and format the cont total
                'text-field': '{count_companies}',
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'visibility': 'visible'
            },
            paint: {
                'text-color': '#fff'
            }
        });

        map.addLayer({
            id: 'unclustered-record-point',
            type: 'circle',
            source: 'data',
            layout: {
                'visibility': 'visible'
            },
            filter: ['all', ['==', 'count_companies', 1], ['!has', 'point_count'], ['has', 'company_score']],
            paint: {
                'circle-radius': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    6, 2,
                    10, 4,
                    15, 8,
                    20, 90
                ],
                // 'circle-color': APP_COLORS.orange
                // Based on the confidence score of the record make higher
                // confidence records standout
                'circle-color': [
                    'case',
                    ['>=', ['get', 'company_score'], 0.90], 'rgba(238, 90, 56, 1)',
                    ['>=', ['get', 'company_score'], 0.80], 'rgba(238, 90, 56, .7)',
                    ['>=', ['get', 'company_score'], 0.70], 'rgba(238, 90, 56, .3)',
                    ['>=', ['get', 'company_score'], 0.60], 'rgba(238, 90, 56, .2)',
                    /* other */ 'rgba(238, 90, 56, .1)'
                ]
            },

        });

        // Check out this to see why we register these now
        // https://github.com/mapbox/mapbox-gl-js/issues/5145

        // inspect a cluster on click
        map.on('click', 'clusters', handleClusteredPointClick);
        // map.on('click', 'unclustered-point', handleUnclusteredPointClick);
        map.on('click', 'cluster-location_place_point', handleUnclusterLocationPlaceClusterPointClick);
        map.on('click', 'unclustered-record-point', handleUnclusteredRecordDataPointClick);

        map.on('mouseenter', 'clusters', handleDataPointMouseEnter);
        map.on('mouseleave', 'clusters', handleDataPointMouseLeave);
        // map.on('mouseenter', 'unclustered-point', handleDataPointMouseEnter);
        // map.on('mouseleave', 'unclustered-point', handleDataPointMouseLeave);
        map.on('mouseenter', 'cluster-location_place_point', handleDataPointMouseEnter);
        map.on('mouseleave', 'cluster-location_place_point', handleDataPointMouseLeave);

        map.on('mouseenter', 'unclustered-record-point', handleDataPointMouseEnter);
        map.on('mouseleave', 'unclustered-record-point', handleDataPointMouseLeave);

        setMap(map);
        // dispatch(setExploreMap(map));
        // setShowActionBarAndLegend(true);
        if (disableLoading) setLoading(false);
    };

    const handleClusteredPointClick = (e) => {
        // james' notes, this method is called when selecting a product (tcu) cluster
        //  the cluster is 'clustered' as in its not the last node in the cluster
        // console.log('handleClusteredPointClick');
        // Use this method to get data from the cluster
        const map = e.target;
        let clusterFeatures = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });
        setShowFlyout(false);
        if (clusterFeatures && clusterFeatures.length > 0) {
            let clusterId = clusterFeatures[0].properties.cluster_id;
            var pointCount = clusterFeatures[0].properties.point_count;
            var clusterSource = map.getSource('data');

            clusterSource.getClusterLeaves(clusterId, pointCount, 0, function (error, features) {
                // Print cluster leaves in the console
                if (features && features.length > 0) {
                    //console.log('Cluster leaves:', error, features);
                    const bestMatchLocation = maxBy(features, (feature) => feature.properties.count_companies);
                    setExploreMapFlyoutDetailMode('NONE');
                    setSelectedPoint({
                        type: 'PRODUCT',
                        geometry: clusterFeatures[0].geometry,
                        countCompanies: clusterFeatures[0].properties.count,
                        countContacts: clusterFeatures[0].properties.countContacts,
                        selectedLocations: features.map((feature) => feature.properties),
                        bestLocation: bestMatchLocation.properties
                    });
                    setExploreMapFlyoutDetailMode('PRODUCT_DATA_CLUSTERED');
                    setShowFlyout(true);
                }
            });
        }
    };

    const handleUnclusterLocationPlaceClusterPointClick = (e) => {
        // james's notes this is invoked as the least common denominator in the clustered map
        // console.log('handleUnclusterLocationPlaceClusterPointClick');
        setExploreMapFlyoutDetailMode('NONE');
        setSelectedPoint({
            type: 'PRODUCT',
            geometry: e.features[0].geometry,
            countCompanies: e.features[0].properties.count_companies,
            countContacts: e.features[0].properties.count_contacts,
            selectedLocations: [e.features[0].properties],
            bestLocation: e.features[0].properties
        });
        setExploreMapFlyoutDetailMode('PRODUCT_DATA_CLUSTERED');
        setShowFlyout(true);
    };

    // This needs to be outside the callback in order to 'turn it off'
    //https://gis.stackexchange.com/questions/251921/removing-events-map-onclick-from-mapbox-gl
    const handleUnclusteredRecordDataPointClick = (e) => {
        // james's notes this is the new method invoked when an individual map point is clicked
        // console.log('handleUnclusteredRecordDataPointClick');
        setShowFlyout(false);
        setExploreMapFlyoutDetailMode('NONE');
        setSelectedPoint({
            type: 'PRODUCT',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties
        });
        setExploreMapFlyoutMode('PRODUCT_DATA_RECORD_POINT');
        setExploreMapFlyoutDetailMode('PRODUCT_DATA_RECORD_POINT');
        setShowFlyout(true);
    };

    /* END CLUSTERED MAP */

    /*


        OVERLAYS


     */

    const handleOverlaySelection = (action, selectedOption) => {
        const sourceId = `loc-${selectedOption.value}-data`;
        const layerId = `loc-${selectedOption.value}-point`;
        const imgId = `loc-${selectedOption.value}-img`;

        if (action === 'REMOVE') {
            if (map.getLayer(layerId)) {
                map.off('mouseenter', layerId, handleDataPointMouseEnter);
                map.off('mouseleave', layerId, handleDataPointMouseLeave);
                map.off('click', layerId, handleLocationPointClick);
                map.off('click', layerId, handleOverlayImportCompanyPointClick);
                map.off('click', layerId, handleOverlayListContactPointClick);
                map.removeLayer(layerId);
            }
            if (map.getSource(sourceId)) map.removeSource(sourceId);
            if (map.hasImage(imgId)) map.removeImage(imgId);
            const _renderedOverlays = renderedOverlays;
            const _selectedOptionIdx = findIndex(_renderedOverlays, selectedOption);
            _renderedOverlays.splice(_selectedOptionIdx, 1);
            setRenderedOverlays(_renderedOverlays);
            setExploreMapFlyoutDetailMode('NONE');
            dispatch(setOverlaySelect(null));
        }

        if (action === 'ADD') {
            setLoading(true);
            _getOverlayRecords(selectedOption.value, selectedOption.type)
                .then((response) => {
                    if (selectedOption.type === 'IMPORT_OVERLAY') {
                        const geoJson = buildGeoJsonForOverlayOverlay(response, 'ListImportOverlayRecord');
                        const iconUrl = _get(selectedOption, 'config.map.overlay.imgSecureUrl', null);
                        if (iconUrl) {
                            map.loadImage(iconUrl, (error, image) => {
                                    if (error) throw error;
                                    map.addImage(imgId, image);
                                    map.addSource(sourceId, {
                                        'type': 'geojson',
                                        'data': geoJson
                                    });
                                    map.addLayer({
                                        'id': layerId,
                                        'type': 'symbol',
                                        'source': sourceId,
                                        'layout': {
                                            'icon-image': imgId,
                                            'icon-size': 1,
                                            // 'icon-allow-overlap': true,
                                            'visibility': 'visible'
                                        }
                                    });
                                }
                            );

                        } else {
                            const _img = new Image(24, 24);
                            const iconSrc = _get(selectedOption, 'config.map.overlay.img', 'map-marker-alt');
                            const iconColor = _get(selectedOption, 'config.map.overlay.color', '#f8f9fa');
                            const tcuMatchedColor = _get(selectedOption, 'config.map.overlay.tcuMatchedcolor', '#0171fe');
                            _img.onload = () => {
                                map.addImage(imgId, _img, {
                                    "sdf": "true"
                                });
                                map.addSource(sourceId, {
                                    'type': 'geojson',
                                    'data': geoJson
                                });
                                map.addLayer({
                                    'id': layerId,
                                    'type': 'symbol',
                                    'source': sourceId,
                                    'layout': {
                                        'icon-image': imgId,
                                        'icon-size': 1,
                                        // 'icon-allow-overlap': true,
                                        'visibility': 'visible'
                                    },
                                    'paint': {
                                        //'icon-color': `${iconColor}`,
                                        'icon-halo-color': '#fff',
                                        'icon-halo-width': 2,
                                        'icon-color': [
                                            'case',
                                            ['to-boolean',['get', 'locationId']],
                                            tcuMatchedColor,
                                            iconColor
                                        ]
                                    }
                                });
                            };
                            const ensureSVGWidthHeight = (svgElem) => {
                                if(!svgElem.getAttribute('width') || !svgElem.getAttribute('height')) {
                                    var viewBox = svgElem.getAttribute('viewBox');
                                    if(viewBox) {
                                        svgElem.setAttribute('width', 24);
                                        svgElem.setAttribute('height', 24);
                                    }
                                }
                                return svgElem;
                            };

                            const req = new XMLHttpRequest;
                            req.open('get', `${svgIcons}#${iconSrc}`);
                            req.onload = () => {
                                const childElement = req.responseXML.getElementById(iconSrc);
                                var svgDoc = ensureSVGWidthHeight(childElement.parentNode);
                                var svgData = new XMLSerializer().serializeToString(svgDoc);
                                _img.src = "data:image/svg+xml;base64," + btoa(svgData)
                            };
                            req.send();
                        }

                        // Only register if we've never registered before
                        if (!find(registeredListeners, {value: selectedOption.value})) {
                            map.on('mouseenter', layerId, handleDataPointMouseEnter);
                            map.on('mouseleave', layerId, handleDataPointMouseLeave);
                            map.on('click', layerId, handleLocationPointClick);
                            setRegisteredListeners(concat(registeredListeners, [selectedOption]));
                        }
                        setLoading(false);
                        setRenderedOverlays(concat(renderedOverlays, [selectedOption]));
                        dispatch(setOverlaySelect(selectedOption));
                    }

                    if (selectedOption.type === 'IMPORT_COMPANY') {
                        const geoJson = buildGeoJsonForCompanyOverlay(response, 'ListImportCompanyRecord');
                        const tcuMatchedColor = selectedOption?.config?.map?.overlay?.tcuMatchedColor || '#0171fe';
                        map.addSource(sourceId, {
                            type: 'geojson',
                            data: geoJson
                        });

                        map.addLayer({
                            id: layerId,
                            type: 'circle',
                            source: sourceId,
                            'paint': {
                                'circle-radius': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    6, 2,
                                    10, 4,
                                    15, 8,
                                    20, 90
                                ],
                                'circle-color': [
                                    'case',
                                    ['to-boolean', ['get', 'companyId']],
                                    tcuMatchedColor,
                                    selectedOption.config.map.overlay.color
                                ],
                            },
                            // add the layer after the unclustered data layer
                        }, map.getLayer('clusters') ? 'clusters' : undefined);

                        // Only register if we've never registered before
                        if (!find(renderedOverlays, {value: selectedOption.value})) {
                            map.on('mouseenter', layerId, handleDataPointMouseEnter);
                            map.on('mouseleave', layerId, handleDataPointMouseLeave);
                            map.on('click', layerId, handleOverlayImportCompanyPointClick);
                            setRenderedOverlays(concat(renderedOverlays, [selectedOption]));
                        }
                        setLoading(false);
                        // setShowActionBarAndLegend(true);
                    }

                    if (selectedOption.type === 'IMPORT_CONTACT') {
                        const geoJson = buildGeoJsonForCompanyOverlay(response, 'ListImportContactRecord');
                        const color = _get(selectedOption, 'config.map.overlay.color', '#f8f9fa');
                        const tcuMatchedColor = _get(selectedOption, 'config.map.overlay.tcuMatchedcolor', '#0171fe');
                        map.addSource(sourceId, {
                            type: 'geojson',
                            data: geoJson
                        });

                        map.addLayer({
                            id: layerId,
                            type: 'circle',
                            source: sourceId,
                            'paint': {
                                'circle-radius': [
                                    'interpolate',
                                    ['exponential', 2],
                                    ['zoom'],
                                    6, 2,
                                    10, 4,
                                    15, 8,
                                    20, 90
                                ],
                                'circle-color': [
                                    'case',
                                    ['to-boolean', ['get', 'contactId']],
                                    tcuMatchedColor,
                                    color
                                ],
                            },
                            // add the layer after the unclustered data layer
                        }, map.getLayer('clusters') ? 'clusters' : undefined);

                        // Only register if we've never registered before
                        if (!find(renderedOverlays, {value: selectedOption.value})) {
                            map.on('mouseenter', layerId, handleDataPointMouseEnter);
                            map.on('mouseleave', layerId, handleDataPointMouseLeave);
                            map.on('click', layerId, handleOverlayListContactPointClick);
                            setRenderedOverlays(concat(renderedOverlays, [selectedOption]));
                        }
                        setLoading(false);
                        // setShowActionBarAndLegend(true);
                    }

                    if (selectedOption.type === SourceTypes.SUPPLIER.id) {
                        const geoJson = buildGeoJsonForSupplierOverlay(response);

                        const _img = new Image(24, 24);
                        const iconSrc = _get(selectedOption, 'config.map.overlay.img', 'map-marker-alt');
                        const iconColor = _get(selectedOption, 'config.map.overlay.color', '#f8f9fa');
                        const tcuMatchedColor = _get(selectedOption, 'config.map.overlay.tcuMatchedcolor', '#0171fe');
                        _img.onload = () => {
                            map.addImage(imgId, _img, {
                                "sdf": "true"
                            });
                            map.addSource(sourceId, {
                                'type': 'geojson',
                                'data': geoJson
                            });
                            map.addLayer({
                                'id': layerId,
                                'type': 'symbol',
                                'source': sourceId,
                                'layout': {
                                    'icon-image': imgId,
                                    'icon-size': 1,
                                    // 'icon-allow-overlap': true,
                                    'visibility': 'visible'
                                },
                                'paint': {
                                    //'icon-color': `${iconColor}`,
                                    'icon-halo-color': '#fff',
                                    'icon-halo-width': 2,
                                    'icon-color': [
                                        'case',
                                        ['to-boolean',['get', 'locationId']],
                                        tcuMatchedColor,
                                        iconColor
                                    ]
                                }
                            });
                        };
                        const ensureSVGWidthHeight = (svgElem) => {
                            if(!svgElem.getAttribute('width') || !svgElem.getAttribute('height')) {
                                var viewBox = svgElem.getAttribute('viewBox');
                                if(viewBox) {
                                    svgElem.setAttribute('width', 24);
                                    svgElem.setAttribute('height', 24);
                                }
                            }
                            return svgElem;
                        };

                        const req = new XMLHttpRequest;
                        req.open('get', `${svgIcons}#${iconSrc}`);
                        req.onload = () => {
                            const childElement = req.responseXML.getElementById(iconSrc);
                            var svgDoc = ensureSVGWidthHeight(childElement.parentNode);
                            var svgData = new XMLSerializer().serializeToString(svgDoc);
                            _img.src = "data:image/svg+xml;base64," + btoa(svgData)
                        };
                        req.send();

                        // Only register if we've never registered before
                        if (!find(renderedOverlays, {value: selectedOption.value})) {
                            map.on('mouseenter', layerId, handleDataPointMouseEnter);
                            map.on('mouseleave', layerId, handleDataPointMouseLeave);
                            map.on('click', layerId, handleOverlaySupplierPointClick);
                            setRenderedOverlays(concat(renderedOverlays, [selectedOption]));
                        }
                        setLoading(false);
                        // setShowActionBarAndLegend(true);
                    }
                })
        }
    }

    const handleLocationPointClick = (e) => {
        // console.log('clicked on location', e.features[0].properties);
        setShowFlyout(true);
        setExploreMapFlyoutDetailMode('NONE');
        setSelectedPoint({
            type: 'OVERLAY',
            geometry: e.features[0].geometry,
            properties: e.features[0].properties,
        });
        setExploreMapFlyoutDetailMode('OVERLAY');
    };

    const handleOverlayImportCompanyPointClick = (e) => {
        // console.log('clicked overlay data point (overlay)', e.features[0].properties);
        if (e.features[0] && e.features[0].properties && e.features[0].properties.companyId) {
            setExploreMapFlyoutDetailMode('NONE');
            setSelectedPoint({
                type: 'PRODUCT',
                geometry: e.features[0].geometry,
                properties: {
                    companyId: e.features[0].properties.companyId
                }
            });
            setExploreMapFlyoutMode('PRODUCT_DATA_RECORD_POINT');
            setExploreMapFlyoutDetailMode('PRODUCT_DATA_RECORD_POINT');
            setShowFlyout(true);
        } else {
            setExploreMapFlyoutDetailMode('NONE');
            setSelectedPoint({
                type: 'OVERLAY',
                geometry: e.features[0].geometry,
                properties: {
                    ...e.features[0].properties,
                    listType: 'IMPORT_COMPANY'
                },
            });
            // TODO THIS IS JUST FOR THE TIME BEING, IT SHOULD BE DRIVEN BY WHATEVER THE MAP CLUSTER / UNCLUSTERED IS DOING
            setExploreMapMode('ADDRESS');
            setExploreMapFlyoutDetailMode('UNCLUSTERED');
            setShowFlyout(true);
        }
    };

    const handleOverlayListContactPointClick = (e) => {
        // console.log('clicked overlay data point (overlay)', e.features[0].properties);
        setExploreMapFlyoutDetailMode('NONE');
        setSelectedPoint({
            type: 'OVERLAY',
            geometry: e.features[0].geometry,
            properties: {
                ...e.features[0].properties,
                listType: 'IMPORT_CONTACT'

            },
        });
        // TODO THIS IS JUST FOR THE TIME BEING, IT SHOULD BE DRIVEN BY WHATEVER THE MAP CLUSTER / UNCLUSTERED IS DOING
        setExploreMapMode('ADDRESS');
        setExploreMapFlyoutDetailMode('UNCLUSTERED');
        setShowFlyout(true);
    };

    const handleOverlaySupplierPointClick = (e) => {
        setExploreMapFlyoutDetailMode('NONE');
        setSelectedPoint({
            type: 'OVERLAY',
            geometry: e.features[0].geometry,
            properties: {
                ...e.features[0].properties,
                listType: 'SUPPLIER'

            },
        });
        // TODO THIS IS JUST FOR THE TIME BEING, IT SHOULD BE DRIVEN BY WHATEVER THE MAP CLUSTER / UNCLUSTERED IS DOING
        setExploreMapMode('ADDRESS');
        setExploreMapFlyoutDetailMode('UNCLUSTERED');
        setShowFlyout(true);
    };

    // Map Functionality (in Flyout more/less)

    const handleShowHideProductMap = () => {
        const layers = ['clusters', 'cluster-count', 'unclustered-point', 'unclustered-count', 'cluster-location_place_point', 'cluster-location_place_count',
            'unclustered-data-point', 'unclustered-record-point'];
        layers.forEach((l) => {
            const visibility = map.getLayoutProperty(l, 'visibility');
            if (visibility === 'visible') {
                map.setLayoutProperty(l, 'visibility', 'none');
                setRenderedProducts([]);
            }
            if (visibility === 'none') {
                map.setLayoutProperty(l, 'visibility', 'visible');
                setRenderedProducts(['TCU']);
            }
        });
    };

    return (
        <div>
            {(
                loading ||
                companyDataState.loading ||
                contactDataState.loading ||
                companyDataState.mapStatus === 'loading' ||
                contactDataState.mapStatus === 'loading'
            ) && (
                <div className="explore-map-spinner-overlay">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            )}
            { !showFlyout && (
                <div className='explore-map-flyout-hide' onClick={(e) => setShowFlyout(!showFlyout)}>
                    <FontAwesomeIcon
                        color={APP_COLORS['tech-blue']}
                        icon={faLayerGroup}
                    />
                </div>
            )}

            { showFlyout && (
                <ExploreMapFlyout
                    setShowFlyout={setShowFlyout}
                    currentFilters={props.currentFilters}
                    exploreMode={props.exploreMode}
                    exploreMapMode={exploreMapMode}
                    exploreMapFlyoutDetailMode={exploreMapFlyoutDetailMode}
                    renderedOverlays={renderedOverlays}
                    handleOverlaySelection={handleOverlaySelection}
                    renderedProducts={renderedProducts}
                    handleShowHideProductMap={handleShowHideProductMap}
                    selectedPoint={selectedPoint}
                />
            )}
            {/* FOR DEBUGGING */}
            {/*<div className='sidebarStyle'>*/}
            {/*    <div>Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}</div>*/}
            {/*</div>*/}
            <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => {
                    map?.resize();
                }}
                targetRef={mapContainer} // Fixed findDOMNode issue.
            >
                <div ref={(el) => (mapContainer.current = el)} className="mapContainer" id="mapContainerID" />
            </ReactResizeDetector>
        </div>
    );
}

async function _getOverlayRecords(listId, type) {
    if (type === 'IMPORT_OVERLAY') {
        return getListImportOverlayRecord(listId);
    }
    if (type === 'IMPORT_COMPANY') {
        return getListImportCompanyRecord(listId);
    }
    if (type === 'IMPORT_CONTACT') {
        return getListImportContactRecord(listId);
    }
    if (type === SourceTypes.SUPPLIER.id) {
        return getSupplierRecordBySourceId(listId);
    }
}
