/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import AccountsContainer from './AccountsContainer';
import CreateAccountContainer from "./CreateAccountContainer";
import EditAccountContainer from "./EditAccountContainer";
import DisableAccountContainer from "./DisableAccountContainer";
import EnableAccountContainer from "./EnableAccountContainer";

export default class AccountsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/accounts/create-account" exact component={CreateAccountContainer}/>
                <Route path="/admin/accounts/:id/managed-accounts" exact component={EditAccountContainer}/>
                <Route path="/admin/accounts/:id/disable-account" exact component={DisableAccountContainer}/>
                <Route path="/admin/accounts/:id/enable-account" exact component={EnableAccountContainer}/>
                <Route path="/admin/accounts" component={AccountsContainer} />
            </Switch>
        );
    }
}
