/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';
import TableWithPaging from '../../common/components/TableWithPaging';
import {useDispatch, useSelector} from "react-redux";
import {getLists, getListsCount, setCurrentPage, setOrder, setSelectedId} from "./listSlice";
import Image from "react-bootstrap/Image";
import ContactIcon from './assets/ToolbeltIcons-Contacts.png';
import CompanyIcon from './assets/ToolbeltIcons_Company.png';
import LocationIcon from './assets/ToolbeltIcons-Location.png';
import {getTypeById} from '../../common/metadata/ListTypes';
import {LIST_STATUSES, getListStatusById} from '../../common/metadata/ListStatuses';
import {APP_COLORS} from "../../common/Constants";

import './List.scss';
import styled from "styled-components";
import CheckBox from "../../common/components/CheckBox";
import TextHoverDisplay from "../../common/components/TextHoverDisplay";

const ListSelectColumn = styled.div `
  display: grid;
  grid-template-columns: 25px 1fr;
  align-items: center;
  min-width: 60px;  
`;

function ListTable(props) {

    // This gonna change to listen from store.
    const dispatch = useDispatch();
    const listReduxState = useSelector((state) => state.list);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getListsCount());
        dispatch(getLists());
    }, [props.lastReload, dispatch]);

    const createHeader = () => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const typeDef = getTypeById(row.type)
                    let image = '';
                    if (typeDef) {
                        if (typeDef.id === 'IMPORT_COMPANY' ||
                            typeDef.id === 'MATCH_COMPANY' ||
                            typeDef.id === 'EXPORT_COMPANY')
                        {
                            image = CompanyIcon;
                        } else if (
                            typeDef.id === 'IMPORT_CONTACT' ||
                            typeDef.id === 'MATCH_CONTACT' ||
                            typeDef.id === 'EXPORT_CONTACT')
                        {
                            image = ContactIcon;
                        }
                        else {
                            image = LocationIcon;
                        }
                    }
                    else {
                        image = LocationIcon;
                    }

                    return (
                        <ListSelectColumn>
                            <CheckBox
                                innerState
                                onChange={value => dispatch(setSelectedId({id: row.id, isChecked: value, list: row}))}
                            />
                            <Image
                                className="table-icon"
                                src={image}
                                fluid
                            />
                        </ListSelectColumn>
                    )
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 6)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Name',
                property: 'name',
                sortable: true,
                formatted: false,
            },
            {
                id: 4,
                name: 'Description',
                property: 'description',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return (<div className={'description'}> {row.description} </div>);
                }
            },
            {
                id: 5,
                name: 'Type',
                property: 'type',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    const _type = getTypeById(row.type);
                   return _type ? _type.display : row.type;
                }
            },
            {
                id: 6,
                name: 'Status ',
                property: 'status',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                   const listStatusDef =  getListStatusById(row.status);
                    if (listStatusDef.id === LIST_STATUSES.READY.id || listStatusDef.id === LIST_STATUSES.EXPORTED.id) {
                        return (<span style={{color: '#6da06f'}}>{listStatusDef.display}</span>);
                    } else  if (listStatusDef.id === LIST_STATUSES.CREATED.id || listStatusDef.id === LIST_STATUSES.CREATED.id) {
                        return (<span>{listStatusDef.display}</span>);
                    } else if (listStatusDef.id === LIST_STATUSES.ERROR.id) {
                        return (<span style={{color: '#c13727'}}>{listStatusDef.display}</span>)
                    } else {
                        return (<span style={{color: '#fdba43'}}>{listStatusDef.display}</span>);
                    }
                }
            },
            {
                id: 7,
                name: 'Count ',
                property: 'dataCntRecords',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.dataCntRecords) {
                        return (
                            <div className={'count'}>
                                <span style={{color: APP_COLORS['orange']}}>{row.dataCntRecords}</span> <span style={{color: APP_COLORS['tech-blue']}}>Records</span>
                            </div>
                        )
                    } else {
                        return (<div className={'count'}><span style={{color: APP_COLORS['orange']}}>N/A</span></div>)
                    }
                }
            },
            {
                id: 8,
                name: 'Created ',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getLists());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'list-table-with-paging'}
                headers={headers}
                rows={listReduxState.lists}
                countOfResults={listReduxState.totalRecords}
                resultsPerPage={listReduxState.pageSize}
                currentPage={listReduxState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
}


ListTable.propTypes = {
    lists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

ListTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    lists: []
};
export default ListTable;
