import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";
import ColumnCollapse from "../../ColumnCollapse";
import CollapsePanel from "../../../../../common/components/CollapsePanel";
import '../../../../../common/components/Modal.scss';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { GridButton, DataGridContainer } from '../../StyledComponents';
import { getCompanyNameById, changeCompanyNameById } from './slices/companyNameSlice';
import { getCompanyURLById, changeCompanyURLById } from './slices/companyURLSlice';
import { changeCompanyAddressById, getCompanyAddressById, normalizeCompanyAddress } from './slices/companyAddressSlice';
import { getCompanyPhoneById, changeCompanyPhoneById, createCompanyPhoneById } from './slices/companyPhoneSlice';
import { getCompanyEmailById, changeCompanyEmailById, createCompanyEmailById } from './slices/companyEmailSlice';
import { Button, Modal, Card, Form, Row, Col } from 'react-bootstrap';
import FormTextInput from "../../../../../common/components/FormTextInput";
import FormContainer from "../../../../../common/components/layout/FormContainer";

const CompanyNameComponent = (props) => {
    const { 
        companyId, 
        getAttributeRows, 
        attributeColumns,
        handleOnCreate 
    } = props;

    const companyNameState = useSelector(state => state.companyName);

    const dispatch = useDispatch();

    const [companyName, setCompanyName] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [nameInput, setNameInput] = useState('');
    const [isShowVerifyForCompany,setShowVerifyForCompany]=useState(true);

    const changeOnClick = () => {
        if (nameInput !== companyName.name) {
            dispatch(changeCompanyNameById({id: companyId, body: {data: {name: nameInput}}}));
        }
    }

    useEffect(() => {
        dispatch(getCompanyNameById(companyId));
    }, [companyId]);

    useEffect(() => {
        if (companyNameState.companyName) {
            setCompanyName(companyNameState.companyName);
        }
    }, [companyNameState.companyName]);

    useEffect(() => {
        if (companyName) {
            setNameInput(companyName.name);
        }
    }, [companyName]);

    useEffect(() => {
        if (companyName) {
            const nonstandardAttributes = [
                {property: 'id', value: companyName.id},
                {property: 'companyId', value: companyName.companyId},
                {property: 'name', value: <input style={{border: 'none', width: '100%', outline: 'none'}} type='text' defaultValue={companyName.name} onChange={(e) => setNameInput(e.target.value)} />, action: <GridButton onClick={changeOnClick} type='button'>CHANGE</GridButton>},
                {property: 'designator', value: companyName.designator},
                {property: '_name', value: companyName._name}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, companyName));
        }
    }, [companyName, nameInput]);

    return (
        <ColumnCollapse header={'Company Name'} isOpen={true}>
            <CollapsePanel header={'Attribute'} isOpen={true} isShowActionButtons={true} showVerifyForCompany={isShowVerifyForCompany}>
                                    
                <DataGridContainer>                
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    );
}

const CompanyURLComponent = (props) => {
    const { 
        companyId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const companyURLState = useSelector(state => state.companyURL);

    const dispatch = useDispatch();

    const [companyURL, setCompanyURL] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [urlInput, setUrlInput] = useState(urlInput);
    const [isAddNewUrl, setAddNewUrl]=useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isShowGrid, setShowGrid]=useState(false);
    const [isSaveUrl, setSaveUrl]=useState(false);
    const [isShowActionButton, setShowActionButton]=useState(false);
    const [isEnableAddValueButton, setEnableAddValueButton]=useState(false);
    const [isShowVerifyForCompany,setShowVerifyForCompany]=useState(true);
    const [isUrlValid, setUrlValid] =useState(false)

    const changeOnClick = () => {
         if (urlInput !== companyURL.url && (companyId && companyId!=='')) {
            dispatch(changeCompanyURLById({id: companyId, body: {data: {url: urlInput}}}));
        }
    }

    const isInvalid = () => {       
        return isUrlValid;
    };
    useEffect(() => {
        if(companyId && companyId!=='')
        {
            dispatch(getCompanyURLById(companyId));
        }
    }, [companyId]);
    useEffect(() => {
        if(!companyURLState)
        {
            setEnableAddValueButton(true);
            setCompanyURL('');
        }
    })
    useEffect(() => {
        if (companyURLState.companyURL) {
            setCompanyURL(companyURLState.companyURL);
            setEnableAddValueButton(false);
            setShowGrid(true);
            setShowActionButton(true);
        }else{
            setEnableAddValueButton(true);
        }
    }, [companyURLState.companyURL]);

    useEffect(() => {
        if (companyURL) {
            setUrlInput(companyURL.url);
        }
    }, [companyURL]);

    useEffect(() => {
        if (companyURL) {
            const nonstandardAttributes = [
                {property: 'id', value: companyURL.id},
                {property: 'companyId', value: companyURL.companyId},
                {property: 'url', value: companyURL.url},
                {property: 'domain', value: companyURL.domain},
                {property: 'tld', value: companyURL.tld},
                {property: 'subdomain', value: companyURL.subdomain},
                {property: 'publicSuffix', value: companyURL.publicSuffix ? 'true' : 'false'},
                {property: 'supportsHttp', value: companyURL.supportsHttp ? 'true' : 'false'},
                {property: 'supportsHttps', value: companyURL.supportsHttps ? 'true' : 'false'},
                {property: '_url', value: companyURL._url}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, companyURL));
        }
    }, [companyURL, urlInput]);

    const onChange = (e) => {
        e.preventDefault();
        setAddNewUrl(true);
        setShowGrid(false);
        setSaveUrl(true);
        setShowActionButton(false);        
        return false;
    }

    const saveNewUrlOnClick = (e) => {
        e.preventDefault();        
        if  (isInvalid ()) {
            setFormValid(false);
            return; 
        } 
      if(companyURL)
      {
        if (urlInput !== companyURL.url) {
            dispatch(changeCompanyURLById({id: companyId, body: {data: {url: urlInput}}}));
        }
        setShowActionButton(true);
      }
        setSaveUrl(false);
        setAddNewUrl(false);
        setShowGrid(true)
    }
    const onInputValidate = (value, name) => {     
        if(value)
        {
            setUrlValid(true);            
        }else{
            setUrlValid(false);
        }
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };
     
      const [error, setError] = useState({        
        companyUrl: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      });  



    return (
        <ColumnCollapse header={'COMPANY URL'}>
            <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={isShowActionButton} showVerifyForCompany={isShowVerifyForCompany}
            isSaveValueButton={isSaveUrl} onSaveValue={saveNewUrlOnClick}
            onChange={onChange}>
                {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
                :<></>}
                {isAddNewUrl && (<>
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput">
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput
                                            style={{marginTop:'30px'}}
                                            title={'Url'}
                                            label={'Url'}
                                            name={'companyUrl'}
                                            placeholder="Enter Url"
                                            value={urlInput}
                                            onChange={value => setUrlInput(value)}
                                            isInvalid={!formValid && !urlInput }
                                            size={'sm'}
                                            reqType={'URL'}
                                            required
                                            {...error.companyUrl}
                                        />
                                    </Col>
                                </Row>
                                </Form.Group>
                    </Form>
                </FormContainer>                
                </> )}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    );
}

const CompanyAddressComponent = (props) => {
    const { 
        companyId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const companyAddressState = useSelector(state => state.companyAddress);

    const dispatch = useDispatch();

    const [companyAddress, setCompanyAddress] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [show, setShow] = useState(false);
    const [deliveryLineInput, setDeliveryLineInput] = useState('');
    const [cityInput, setCityInput] = useState('');
    const [regionInput, setRegionInput] = useState('');
    const [postalCodeInput, setPostalCodeInput] = useState('');
    const [normalizedCompanyAddress, setNormalizedCompanyAddress] = useState('');
    const [isShowVerifyForCompany,setShowVerifyForCompany]=useState(true);

    const handleClose = () => setShow(false);

    const handleSave = () => {
        if (document.getElementById('radio-original').checked) {
            dispatch(changeCompanyAddressById({id: companyId, body: {options: {test: false}, data: {
                deliveryLine1: deliveryLineInput,
                city: cityInput,
                region: regionInput,
                postalCode: postalCodeInput
            }}}));
        } else {
            dispatch(changeCompanyAddressById({id: companyId, body: {options: {test: false}, data: {
                deliveryLine1: normalizedCompanyAddress.deliveryLine1,
                city: normalizedCompanyAddress.city,
                region: normalizedCompanyAddress.region,
                postalCode: normalizedCompanyAddress.postalCode
            }}}));
        }
        
        setShow(false);
    }

    const handleShow = () => {
        if (deliveryLineInput === companyAddress.deliveryLine1 && cityInput === companyAddress.city 
            && regionInput === companyAddress.region && postalCodeInput === companyAddress.postalCode) {
            return;
        }

        const body = {
            options: {
                test: false
            }, data: {
                deliveryLine1: deliveryLineInput,
                city: cityInput,
                region: regionInput,
                postalCode: postalCodeInput
            }
        };

        dispatch(normalizeCompanyAddress(body));
        setShow(true);
    }

    useEffect(() => {
        setNormalizedCompanyAddress(companyAddressState.normalizedCompanyAddress);
    }, [companyAddressState.normalizedCompanyAddress]);

    useEffect(() => {
        dispatch(getCompanyAddressById(companyId));
    }, [companyId]);

    useEffect(() => {
        if (companyAddressState.companyAddress) {
            setCompanyAddress(companyAddressState.companyAddress);
        }
    }, [companyAddressState.companyAddress]);

    useEffect(() => {
        if (companyAddress) {
            setDeliveryLineInput(companyAddress.deliveryLine1);
            setCityInput(companyAddress.city);
            setRegionInput(companyAddress.region);
            setPostalCodeInput(companyAddress.postalCode);
        }
    }, [companyAddress]);

    useEffect(() => {
        if (companyAddress) {
            const nonstandardAttributes = [
                {property: 'id', value: companyAddress.id},
                {property: 'companyId', value: companyAddress.companyId},
                {property: 'country', value: companyAddress.country},
                {property: 'deliveryLine1', value: <input style={{border: 'none', width: '100%', outline: 'none'}} type='text' defaultValue={companyAddress.deliveryLine1} onChange={(e) => setDeliveryLineInput(e.target.value)} />, action: <GridButton onClick={handleShow} type='button'>CHANGE</GridButton>},
                {property: 'deliveryLine2', value: companyAddress.deliveryLine2},
                {property: 'lastLine', value: companyAddress.lastLine},
                {property: 'city', value: <input style={{border: 'none', width: '100%', outline: 'none'}} type='text' defaultValue={companyAddress.city} onChange={(e) => setCityInput(e.target.value)} />, action: <GridButton onClick={handleShow} type='button'>CHANGE</GridButton>},
                {property: 'region', value: <input style={{border: 'none', width: '100%', outline: 'none'}} type='text' defaultValue={companyAddress.region} onChange={(e) => setRegionInput(e.target.value)} />, action: <GridButton onClick={handleShow} type='button'>CHANGE</GridButton>},
                {property: 'postalCode', value: <input style={{border: 'none', width: '100%', outline: 'none'}} type='text' defaultValue={companyAddress.postalCode} onChange={(e) => setPostalCodeInput(e.target.value)} />, action: <GridButton onClick={handleShow} type='button'>CHANGE</GridButton>},
                {property: 'postalCodePlus4', value: companyAddress.postalCodePlus4},
                {property: 'deliveryPointBarcode', value: companyAddress.deliveryPointBarcode},
                {property: 'latitude', value: companyAddress.latitude},
                {property: 'longitude', value: companyAddress.longitude},
                {property: 'percision', value: companyAddress.percision},
                {property: 'recordType', value: companyAddress.recordType},
                {property: 'postalCodeType', value: companyAddress.postalCodeType},
                {property: 'countyFips', value: companyAddress.countyFips},
                {property: 'ewsMatch', value: companyAddress.ewsMatch},
                {property: 'carrierRoute', value: companyAddress.carrierRoute},
                {property: 'congressionalDistrict', value: companyAddress.congressionalDistrict},
                {property: 'buildingDefaultIndicator', value: companyAddress.buildingDefaultIndicator},
                {property: 'rdi', value: companyAddress.rdi},
                {property: 'elotSequence', value: companyAddress.elotSequence},
                {property: 'elotSort', value: companyAddress.elotSort},
                {property: 'utcOffset', value: companyAddress.utcOffset},
                {property: 'dst', value: companyAddress.dst},
                {property: 'dpvMatchCode', value: companyAddress.dpvMatchCode},
                {property: 'dpvFootnotes', value: companyAddress.dpvFootnotes},
                {property: 'dpvCmra', value: companyAddress.dpvCmra},
                {property: 'dpvVacant', value: companyAddress.dpvVacant},
                {property: 'active', value: companyAddress.active},
                {property: 'lacslinkCode', value: companyAddress.lacslinkCode},
                {property: 'lacslinkIndicator', value: companyAddress.lacslinkIndicator},
                {property: 'suitelinkMatch', value: companyAddress.suitelinkMatch},
                {property: 'enhancedMatch', value: companyAddress.enhancedMatch},
                {property: '_country', value: companyAddress._country},
                {property: '_address', value: companyAddress._address},
                {property: '_deliveryLine1', value: companyAddress._deliveryLine1},
                {property: '_deliveryLine2', value: companyAddress._deliveryLine2},
                {property: '_lastLine', value: companyAddress._lastLine},
                {property: '_city', value: companyAddress._city},
                {property: '_region', value: companyAddress._region},
                {property: '_postalCode', value: companyAddress._postalCode},
                {property: 'parseStrategies', value: companyAddress.parseStrategies},
                {property: 'parseStrategy', value: companyAddress.parseStrategy}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, companyAddress));
        }
    }, [companyAddress, deliveryLineInput, cityInput, regionInput, postalCodeInput]);

    return (
        <ColumnCollapse header={'COMPANY ADDRESS'}>
            <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={true} showVerifyForCompany={isShowVerifyForCompany} >
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>

                {companyAddress && <Modal show={show} keyboard={false} backdrop='static' onHide={handleClose}>
                    <Modal.Body>
                        <div style={{padding: '15px'}}>
                        <Form>
                            <Form.Group>
                                <Row>
                                    <Col md={6}>
                                        <Card>
                                            <div style={{padding: '15px'}}>
                                            <Card.Title>Original</Card.Title>
                                            <Card.Body>
                                                <Form.Check 
                                                    name='addressModal'
                                                    type={'radio'} 
                                                    id={'radio-original'} 
                                                    label={<div>
                                                        <Card.Text>{deliveryLineInput}</Card.Text>
                                                        <Card.Text>{cityInput}, {regionInput} {postalCodeInput}</Card.Text>
                                                        </div>}
                                                />
                                            </Card.Body>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card>
                                            <div style={{padding: '15px'}}>
                                            <Card.Title>Recommended</Card.Title>
                                            <Card.Body>
                                                <Form.Check 
                                                    name='addressModal'
                                                    defaultChecked
                                                    type={'radio'} 
                                                    id={'radio-recomended'} 
                                                    label={normalizedCompanyAddress && <div>
                                                        <Card.Text>{normalizedCompanyAddress.deliveryLine1}</Card.Text>
                                                        <Card.Text>{normalizedCompanyAddress.city}, {normalizedCompanyAddress.region} {normalizedCompanyAddress.postalCode}</Card.Text>
                                                        </div>}
                                                />
                                            </Card.Body>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='footer'>
                            <Button variant='secondary' onClick={handleClose}>Close</Button>
                            <Button variant='primary' onClick={handleSave}>Save</Button>
                        </div>
                    </Modal.Footer>
                </Modal>}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    );
}

const CompanyPhoneComponent = (props) => {
    const { 
        companyId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const companyPhoneState = useSelector(state => state.companyPhone);

    const dispatch = useDispatch();

    const [companyPhone, setCompanyPhone] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [phoneInput, setPhoneInput] = useState(phoneInput);
    const [isAddNewPhone, setAddNewPhone]=useState(false);
    const [formValid, setFormValid] = useState(true); 
    const [isSavePhone, setSavePhone]=useState(false);  
    const [isEnableAddValueButton, setEnableAddValueButton]=useState(false); 
    const [isShowGrid, setShowGrid]=useState(false); 
    const [isShowActionButton, setShowActionButton]=useState(false);
    const [isShowVerifyForCompany,setShowVerifyForCompany]=useState(true);
    const [isPhoneValid, setPhoneValid] = useState(false);

    const changeOnClick = () => {
        if (phoneInput !== companyPhone.phone) {
            dispatch(changeCompanyPhoneById({id: companyId, body: {data: {phone: phoneInput}}}));
        }
    }

    const isInvalid = () => {       
        return isPhoneValid;
    };

    useEffect(() => {
        dispatch(getCompanyPhoneById(companyId));
    }, [companyId]);

    useEffect(() => {
    if(!companyPhoneState)
        {
            setEnableAddValueButton(true);
            setCompanyPhone('');
        }                
        if (companyPhoneState.companyPhone) {
            setCompanyPhone(companyPhoneState.companyPhone);
            setEnableAddValueButton(false);
            setShowGrid(true);
            setShowActionButton(true);
        }
        else{
            setEnableAddValueButton(true);
        }
    }, [companyPhoneState.companyPhone]);

    useEffect(() => {
        if (companyPhone) {
            setPhoneInput(companyPhone.phone);
        }
    }, [companyPhone]);
    useEffect(() => {
        if (companyPhone) {
            const nonstandardAttributes = [
                {property: 'id', value: companyPhone.id},
                {property: 'companyId', value: companyPhone.companyId},
                {property: 'phone', value: companyPhone.phone},
                {property: 'e164Format', value: companyPhone.e164Form},
                {property: 'internationalFormat', value: companyPhone.internationalFormat},
                {property: 'nationalFormat', value: companyPhone.nationalFormat},
                {property: 'countryCode', value: companyPhone.countryCode},
                {property: 'countryPrefix', value: companyPhone.countryPrefix},
                {property: 'countryName', value: companyPhone.countryName},
                {property: 'carrier', value: companyPhone.carrier},
                {property: 'lineType', value: companyPhone.lineType},
                {property: 'public', value: companyPhone.public ? 'true' : 'false'},
               {property: '_phone', value: companyPhone._phone}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, companyPhone));
        }
    }, [companyPhone, phoneInput]);
    const addValueOnClick = (e) => {
        e.preventDefault();
        setAddNewPhone(true);
        setSavePhone(true);
        setEnableAddValueButton(false);
        setShowActionButton(false);       
        return false;
    }

    const onChange = (e) => {
        e.preventDefault();
        setAddNewPhone(true);
        setShowGrid(false);
        setSavePhone(true);
        setShowActionButton(false);        
        return false;
    }
   
    const saveNewPhoneOnClick = (e) => {        
        e.preventDefault();          

        if (isInvalid()) { 
            setFormValid(false);
            return
        }
        if(companyPhone)
      {        
        if (phoneInput !== companyPhone.phone) {
            dispatch(changeCompanyPhoneById({id: companyId, body: {data: {phone: phoneInput}}}));
        }                
      }
      else if(phoneInput) {
            dispatch(createCompanyPhoneById({id: companyId, body: {data: {phone: phoneInput}}}));            
        }        
        setSavePhone(false);
        setAddNewPhone(false);
        setShowActionButton(true);
        setShowGrid(true)
    }
    const onInputValidate = (value, name) => {
        if(value)     
        {
            setPhoneValid(true);
        }else{
            setPhoneValid(false);
        }
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };

      const [error, setError] = useState({
          companyPhone: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      });

    return (
        <ColumnCollapse header={'COMPANY PHONE'}>
            <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={isShowActionButton} isShowAddValueButton={isEnableAddValueButton} showVerifyForCompany={isShowVerifyForCompany}
             onAddValue={addValueOnClick}  isSaveValueButton={isSavePhone} onSaveValue={saveNewPhoneOnClick} onChange={onChange}
            >
                {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
                :<> </> }

                {isAddNewPhone && (<> 
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput">                           
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput
                                            style={{marginTop:'30px'}}
                                            title={'phone'}
                                            label={'Phone'}
                                            name={'companyPhone'}
                                            placeholder="Enter Phone"
                                            value={phoneInput}
                                            onChange={value => setPhoneInput(value)}
                                            isInvalid={!formValid && !phoneInput}
                                            size={'sm'}
                                            reqType={'PHONE'}
                                            required

                                            {...error.companyPhone}      
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                    </Form>
                </FormContainer>               
                </> )}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    );
}

const CompanyEmailComponent = (props) => {
    const { 
        companyId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const companyEmailState = useSelector(state => state.companyEmail);

    const dispatch = useDispatch();

    const [companyEmail, setCompanyEmail] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [emailInput, setEmailInput] = useState(emailInput);
    const [isAddNewEmail, setAddNewEmail]=useState(false);   
    const [formValid, setFormValid] = useState(true);
    const [isSaveEmail, setSaveEmail]=useState(false);  
    const [isEnableAddValueButton, setEnableAddValueButton]=useState(false);  
    const [isShowGrid, setShowGrid]=useState(false); 
    const [isShowActionButton, setShowActionButton]=useState(false); 
    const [isShowVerifyForCompany,setShowVerifyForCompany]=useState(true);
    const [isEmailValid, setEmailValid]= useState(false);
   
    const changeOnClick = () => {
        if (emailInput !== companyEmail.email) {
            dispatch(changeCompanyEmailById({id: companyId, body: {data: {email: emailInput}}}));
        }
    }

    const isInvalid = () => {       
        return isEmailValid;
    }; 

    useEffect(() => {
        dispatch(getCompanyEmailById(companyId));
    }, [companyId]);

    useEffect(() => {
        if(!companyEmailState)
        {
            setEnableAddValueButton(true);
            setCompanyEmail('');
        }       
        if (companyEmailState.companyEmail) {
            setCompanyEmail(companyEmailState.companyEmail);
            setEnableAddValueButton(false);
            setShowGrid(true);
            setShowActionButton(true);
        }else{
            setEnableAddValueButton(true);
        }
    }, [companyEmailState.companyEmail]);

    useEffect(() => {
        if (companyEmail) {
            setEmailInput(companyEmail.email);
        }
    }, [companyEmail]);

      
    useEffect(() => {
        if (companyEmail) {
            const nonstandardAttributes = [
                {property: 'id', value: companyEmail.id},
                {property: 'companyId', value: companyEmail.companyId},
                {property: 'email', value: companyEmail.email},
                {property: 'localPart', value: companyEmail.localPart},
                {property: 'domain', value: companyEmail.domain},
                {property: 'pattern', value: companyEmail.pattern},
                {property: 'formatValid', value: companyEmail.formatValid ? 'true' : 'false'},
                {property: 'mxFound', value: companyEmail.mxFound ? 'true' : 'false'},
                {property: 'smtpFound', value: companyEmail.smtpFound ? 'true' : 'false'},
                {property: 'catchAll', value: companyEmail.catchAll ? 'true' : 'false'},
                {property: 'disposable', value: companyEmail.disposable ? 'true' : 'false'},
                {property: 'free', value: companyEmail.free ? 'true' : 'false'},
                {property: 'role', value: companyEmail.role ? 'true' : 'false'},
                {property: 'public', value: companyEmail.public ? 'true' : 'false'},
                {property: '_email', value: companyEmail._email},
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, companyEmail));
        }
    }, [companyEmail, emailInput]);
    const addValueOnClick = (e) => {
        e.preventDefault();
        setAddNewEmail(true);
        setSaveEmail(true);
        setEnableAddValueButton(false);
        setShowActionButton(false);               
        return false;
    }
    const onChange = (e) => {
        e.preventDefault();
        setAddNewEmail(true);
        setShowGrid(false);
        setSaveEmail(true);
        setShowActionButton(false);               
        return false;
    }
    const saveNewEmailOnClick = (e) => {
        e.preventDefault();    
        
      if (isInvalid ()) {
            setFormValid(false);
            return; 
        } 
      if(companyEmail)
      {
        if (emailInput !== companyEmail.email) {
            dispatch(changeCompanyEmailById({id: companyId, body: {data: {email: emailInput}}}));
        }
        setShowActionButton(true);        
      }else if(emailInput) {
            dispatch(createCompanyEmailById({id: companyId, body: {data: {email: emailInput}}}));           
        }        
        setSaveEmail(false);
        setAddNewEmail(false);
        setShowGrid(true)
    }
    const onInputValidate = (value, name) => {     
        if(value)
        {
            setEmailValid(true);            
        }else{
            setEmailValid(false);
        }
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };
     
      const [error, setError] = useState({        
          companyEmail: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      });  
    
    return (
        <ColumnCollapse header={'COMPANY EMAIL'}>
            <CollapsePanel header={'ATTRIBUTE'} showVerifyForCompany={isShowVerifyForCompany} isShowActionButtons={isShowActionButton} isShowAddValueButton={isEnableAddValueButton}
             onAddValue={addValueOnClick} isSaveValueButton={isSaveEmail} onSaveValue={saveNewEmailOnClick}
             onChange={onChange} isOpen={true}>
           {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>              
               :<> </> }

                {isAddNewEmail && (<> 
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput">                           
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput
                                            style={{ marginTop: '30px'}}
                                            title={'Email'}
                                            label={'Email'}
                                            name={'companyEmail'}
                                            placeholder="Enter email"
                                            value={emailInput}
                                            onChange={value => setEmailInput(value)}
                                            isInvalid={!formValid && !emailInput}
                                            size={'sm'} 
                                            reqType={'EMAIL'}
                                            required  
                                            {...error.companyEmail}                  
                                        />
                                    </Col>
                                </Row>                                
                             </Form.Group>
                         </Form>
                   </FormContainer>                  
                </> )}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    );
}

const CompanyCoreAttributes = (props) => {
    return (<>
        <CompanyNameComponent {...props} />    
        <CompanyURLComponent {...props} />
        <CompanyAddressComponent {...props} />
        <CompanyPhoneComponent {...props} />
        <CompanyEmailComponent {...props} />
    </>);
}

export default CompanyCoreAttributes;