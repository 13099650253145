/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import CardLabel from "./CardLabel";
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faPhone,
    faCheck,
    faQuestion,
} from '@fortawesome/free-solid-svg-icons';

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px,max-content) 1fr;
  padding: 0px 10px, 0px, 0px;
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function ContactInfoBox(props) {
    const {
        item
    } = props;

    return (
        <BoxContainer>
            <CardLabel>Contact Info</CardLabel>
                {item.contactPhone && (
                    <SubText>
                        <FontAwesomeIcon
                            color={APP_COLORS['orange']}
                            icon={faPhone}
                        />&nbsp;&nbsp;
                        <a target='_blank' rel="noreferrer" href={`tel:${item.contactPhone}`}>
                            {item.contactPhone}
                        </a>
                    </SubText>
                )}
                {item.contactEmail && (
                    <SubText>
                        <FontAwesomeIcon
                            color={APP_COLORS['orange']}
                            icon={faEnvelope}
                        />&nbsp;&nbsp;
                        <a target='_blank' rel="noreferrer" href={`mailto:${item.contactEmail}`}>
                            <>{item.contactEmail && item.contactEmail.length > 10 ?
                                `${item.contactEmail.substring(0, 10)}...` : item.contactEmail
                            }</>
                        </a>&nbsp;&nbsp;
                        {item.contactEmailStatus && item.contactEmailStatus === "Verified" && (
                            <FontAwesomeIcon
                                color={APP_COLORS['green']}
                                icon={faCheck}
                            />
                        )}
                        {item.contactEmailStatus && item.contactEmailStatus !== "Verified" && (
                            <FontAwesomeIcon
                                color={APP_COLORS['yellow']}
                                icon={faQuestion}
                            />
                        )}
                    </SubText>
                )}
        </BoxContainer>
    )
}
