/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import CardLabel from "./CardLabel";
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faPhone,
    faCheck,
    faQuestion,
} from '@fortawesome/free-solid-svg-icons';

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px,max-content) 1fr;
  padding: 0px 10px, 0px, 0px;
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function ChannelsBox(props) {
    const {
        item
    } = props;

    return (
        <BoxContainer>
            <CardLabel>Channels</CardLabel>
            {item.companyPhone && (
                <SubText>
                    <FontAwesomeIcon
                        color={APP_COLORS['orange']}
                        icon={faPhone}
                    />&nbsp;&nbsp;
                    <a target='_blank' rel="noreferrer" href={`tel:${item.companyPhone}`}>
                        {item.companyPhone}
                    </a>
                </SubText>
            )}
            {item.companyEmail && (
                <SubText>
                    <FontAwesomeIcon
                        color={APP_COLORS['orange']}
                        icon={faEnvelope}
                    />&nbsp;&nbsp;
                    <a target='_blank' rel="noreferrer" href={`mailto:${item.companyEmail}`}>
                        <>{item.companyEmail && item.companyEmail.length > 10 ?
                            `${item.companyEmail.substring(0, 10)}...` : item.companyEmail
                        }</>
                    </a>&nbsp;&nbsp;
                    {item.companyEmailStatus && item.companyEmailStatus === "Verified" && (
                        <FontAwesomeIcon
                            color={APP_COLORS['green']}
                            icon={faCheck}
                        />
                    )}
                    {item.companyEmailStatus && item.companyEmailStatus !== "Verified" && (
                        <FontAwesomeIcon
                            color={APP_COLORS['yellow']}
                            icon={faQuestion}
                        />
                    )}
                </SubText>
            )}
        </BoxContainer>
    )
}
