/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';

import {getInputs, getInputsCount, setCurrentPage, setOrder, setSelectedId} from "./inputsSlice";

import {getInputStatusById, INPUT_STATUSES} from "../../common/metadata/InputStatuses";
import {getListStatusById, LIST_STATUSES} from "../../common/metadata/ListStatuses";
import {getTypeById} from './InputTypes';

import TableWithPaging from '../../common/components/TableWithPaging';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import styled from "styled-components";

import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faExternalLink} from '@fortawesome/pro-solid-svg-icons';
import CheckBox from "../../common/components/CheckBox";

const RecordColumn = styled.div `
  width: 200px;
`;

const RecordCount = styled.span `
  color: ${APP_COLORS['orange']};
`;

const RecordText = styled.span `
  width: ${APP_COLORS['tech-blue']};
`;

    const InputsTable = (props) => {

    const dispatch = useDispatch();
    const inputsState = useSelector((state) => state.inputs);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getInputsCount());
        dispatch(getInputs());
    }, [props.lastReload]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                id: row.id,
                                isChecked: value,
                                statusId: row.statusId
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'ID',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Source Id',
                property: 'sourceId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.sourceId) {
                        return <TextHoverDisplay
                            truncatedText={row.sourceId.substring(0, 13)}
                            fullText={row.sourceId}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Type',
                property: 'type',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return getTypeById(row.type).display;
                }
            },
            {
                id: 5,
                name: 'Name',
                property: 'name',
                sortable: true,
                formatted: false,
            },
            {
                id: 6,
                name: 'Label',
                property: 'label',
                sortable: true,
                formatted: false,
            },
            // {
            //     id: 7,
            //     name: 'Description',
            //     property: 'description',
            //     sortable: false,
            //     formatted: true,
            //     formatBy: (row, header) => {
            //         return (<div className={'description'}> {row.description} </div>);
            //     }
            // },
            {
                id: 7,
                name: 'Status',
                property: 'status',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    const listStatusDef =  getInputStatusById(row.status);
                    if (listStatusDef && (listStatusDef.id === INPUT_STATUSES.PROCESSED_WITH_ATTRIBUTION.id || listStatusDef.id === INPUT_STATUSES.MIGRATED_WITH_ATTRIBUTION.id)) {
                        return (<span style={{color: '#6da06f'}}>{listStatusDef.display}</span>)
                    } else  if (listStatusDef && (listStatusDef.id === INPUT_STATUSES.CREATED.id || listStatusDef.id === INPUT_STATUSES.PARSING.id || listStatusDef.id === INPUT_STATUSES.NORMALIZING.id)) {
                        return (<span style={{color: '#fdba43'}}>{listStatusDef.display}</span>)
                    } else  if (listStatusDef && (listStatusDef.id === INPUT_STATUSES.NORMALIZED.id)) {
                        return (<span style={{color: '#2c354f'}}>{listStatusDef.display}</span>)
                    } else  if (listStatusDef && (listStatusDef.id === INPUT_STATUSES.ABORTED.id || listStatusDef.id === INPUT_STATUSES.INVALIDATED.id)) {
                        return (<span style={{color: '#c13727'}}>{listStatusDef.display}</span>)
                    } else {
                        return (<span>{listStatusDef? listStatusDef.display : ''}</span>)
                    }
                }
            },
            {
                id: 8,
                name: 'Records',
                property: 'dataCntRecords',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.dataCntRecords) {
                        return (
                            <RecordColumn>
                                <RecordCount>{row.dataCntRecords.toLocaleString('en-US')}</RecordCount> <RecordText>Records</RecordText>
                            </RecordColumn>
                        )
                    } else {
                        return (<RecordColumn><RecordCount>N/A</RecordCount></RecordColumn>)
                    }
                }
            },
            {
                id: 9,
                name: 'URL',
                property: 'url',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.url) {
                        return (
                            <a target='_blank' href={`${row.url}`}>
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={faExternalLink}
                                    style={{marginRight: '5px', fontSize: '12px'}}
                                />
                                {`${row.url.substring(0, 20)}...`}
                            </a>
                        )
                    }
                }
            },
            {
                id: 10,
                name: 'Region',
                property: 'region',
                sortable: true,
                formatted: false,
            },
            // {
            //     id: 10,
            //     name: 'County',
            //     property: 'county',
            //     sortable: true,
            //     formatted: false,
            // },
            {
                id: 11,
                name: 'City',
                property: 'city',
                sortable: true,
                formatted: false,
            },
            {
                id: 12,
                name: 'Authoritative',
                property: 'authoritative',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.authoritative) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 13,
                name: 'Public',
                property: 'public',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.public) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 14,
                name: 'Products',
                property: 'products',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return row.products ? row.products.join(', ') : null;
                }
            },
            {
                id: 15,
                name: 'Indicators',
                property: 'indicators',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return row.indicators ? row.indicators.join(', ') : null;
                }
            },
            {
                id: 16,
                name: 'Created ',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getInputs());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'input-table-with-paging'}
                headers={headers}
                rows={inputsState.inputs}
                countOfResults={inputsState.totalRecords}
                resultsPerPage={inputsState.pageSize}
                currentPage={inputsState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
}


InputsTable.propTypes = {
    inputs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

InputsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    inputs: []
};
export default InputsTable;
