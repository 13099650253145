/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import mapMarkerIcon from '../../../../common/assets/img/ToolbeltIcons-15.png';

const ExploreMapFlyoutDetailNoSelection = (props) => {
    return (
        <Row>
            <Col>
                <div className="explore-map-flyout-detail">
                    <div className="no-selected-point">
                        <div className="icon-container">
                            <Image
                                className="icon"
                                style={{marginTop: '10px'}}
                                src={mapMarkerIcon}
                                alt="ToolBeltData Map Marker Icon"
                                fluid
                            />
                        </div>
                        <div className="detail-text-container">
                            <span className="detail-text">Select a point on the map to get started!</span>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
};

export default ExploreMapFlyoutDetailNoSelection;
