/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";

import styled from "styled-components";
import {APP_COLORS} from "../../../../common/Constants";

const Label = styled.div `
  font-size: 13px;
  font-weight: 600;
`;

const Count = styled.div `
  font-size: 30px;
  color: ${APP_COLORS['orange']};
`;

const MainContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
`;


export default function ExploreMapFlyoutDetailSelectedCountCompaniesContactsClustered(props) {
    const {
        countCompanies,
        countContacts
    } = props;

    return (
        <MainContainer>
            <Label>Companies / Contacts</Label>
            <Count>{countCompanies.toLocaleString()} / {countContacts.toLocaleString()}</Count>
        </MainContainer>
    );
}
