/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import {
    Row,
    Col,
} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

export default function ExploreMapDetailFlyoutDetailLoadingSpinner(props) {
    return (
        <Row>
            <Col>
                <div className="explore-map-flyout-detail">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Spinner
                            style={{marginTop: '30px'}}
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}