/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import ManagedListMatchLogsContainer from "./ManagedListMatchLogsContainer";

export default class ManagedListMatchLogsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/list-match-logs" component={ManagedListMatchLogsContainer} />
            </Switch>
        );
    }
}
