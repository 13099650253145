/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import ManagedListMatches from "./ManagedListMatches"; // eslint-disable-line no-unused-vars

const ManagedListMatchesContainer = (props) => {
    return(
       <ManagedListMatches/>
    )
};

export default ManagedListMatchesContainer;
