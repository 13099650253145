import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {reduce, remove, isArray, find, assign} from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reset: false,
    alertMessage: undefined,
    successMessage: undefined,
    inputChannel: 'upload',
    mapping: '',
    mappedToAttributes: []
};

export const getMappings = createAsyncThunk('managed-lists/mappings',
    async (type) => {
    const MAPPING_ATTR_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/mappings`;
    const mappings = await Api.get(true, MAPPING_ATTR_URL);
    if (mappings && type) {
        if (isArray(mappings)) {
            return find(mappings, {inputType: type});
        }
    }
    return null;
});

export const getS3Files = createAsyncThunk('managed-lists/get-s3-inputs',
async () => {
    const S3_INPUTS_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/retrieve-s3-inputs`;
    const response = await Api.get(true, S3_INPUTS_URL);
    return response;
});

export const getS3ObjectHeader = createAsyncThunk('managed-lists/get-s3-object-header',
    async (objectKey) => {
        const S3_INPUTS_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/retrieve-s3-object-header`;
        const response = await Api.get(true, S3_INPUTS_URL, {key: objectKey});
        return response;
    });

export const createInput = createAsyncThunk('managed-inputs/create-input',
    async (data) => {
        //data.uploadedFile = undefined;
        const input = assign({}, data.input, {
            dataFileName: data.uploadedFile.name,
            dataMapping: {
                inputMappingId: data.mapping.id,
                attributes: reduce(data.mappedToAttributes, (result, a) => {
                    const obj = {};
                    obj[a.attribute] = a.header;
                    return Object.assign(result, obj);
                }, {})
            }
        })

        try {
            // Get PreSigned URL (dataRef)
            if (data.input.dataType != 'avro') {
                const GET_PRESIGNED_UPLOAD_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/upload`; // skip if S3
                const preSignedUploadBody = {
                    options: null,
                    data: {
                        fileName: data.uploadedFile.name
                    }
                }
                const preSignedUploadUrl = await Api.post(true, GET_PRESIGNED_UPLOAD_URL, preSignedUploadBody);
                input.dataRef = preSignedUploadUrl.uploadRef;
                console.log('Retrieved Pre Signed URL for Upload');
                // console.log(preSignedUploadUrl);
                // Upload to S3
                const uploadResult = await fetch(preSignedUploadUrl.uploadUrl, {
                    method: 'PUT',
                    headers: {'Content-Type': 'text/plain'},
                    body: data.uploadedFile
                })
                console.log('Uploaded File');
            }
            else{
                input.dataRef = data.uploadedFile.name;
            }// End skip if S3
            // console.log(input);
            // Create Input
            const CREATE_INPUT_URL = `${config.services.ATLAS.apiUrl}/managed-inputs`;
            console.log('Creating Input');
            return await Api.post(true,
                CREATE_INPUT_URL,
                {
                    options: {
                        test: false,
                        processToStatus: 'NORMALIZED'
                    },
                    data: input
                }
            );
        } catch(err) {
            console.error(err);
        }
    });


const createInputSlice = createSlice({
    name: 'createInputSlice', initialState,
    reducers: {
        resetCreateInputState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
                state.mapping = '';
                state.mappedToAttributes = [];
            }
        },
        updateMappedToAttributes: {
            reducer(state, action) {
                const attr = action.payload;
                remove(state.mappedToAttributes, (o) => {
                    return o.attribute === attr.attribute
                });
                state.mappedToAttributes.push(attr);
            }
        },
        updateAllMappedToAttributes: {
            reducer(state, action) {
                state.mappedToAttributes = action.payload;
            }
        },
        setAlert: {
            reducer(state, action) {
                state.alertMessage = action.payload;
            }
        },
        changeInputChannel: {
            reducer(state, action){
                state.inputChannel = action.payload;
            }
        }
    },
    extraReducers: {
        [createInput.pending]: (state, action) => {
            state.loading = true;
        },
        [createInput.fulfilled]: (state, action) => {
            state.loading = false;
            state.successMessage = `Successfully create a new input [${action.payload.name} - ${action.payload.id}]`
            state.reset = true;
        },
        [createInput.rejected]: (state, action) => {
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
        [getMappings.pending]: (state, action) => {
            state.loading = true;
        },
        [getMappings.fulfilled]: (state, action) => {
            state.loading = false;
            state.mapping = action.payload;
        },
        [getMappings.rejected]: (state, action) => {
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
        [getS3Files.pending]: (state, action) => {
            console.log("In getS3Inputs.pending")
            // state.inputChannel = action.payload;
            state.loading = true;
        },
        [getS3Files.fulfilled]: (state, action) => {
            console.log("In getS3Inputs.fulfilled")
            state.loading = false;
            state.fileTreeState = action.payload;
        },
        [getS3Files.rejected]: (state, action) => {
            console.log("In getS3Inputs.rejected")
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
        [getS3ObjectHeader.pending]: (state, action) => {
            console.log("In getS3Inputs.pending")
            // state.inputChannel = action.payload;
            state.loading = true;
        },
        [getS3ObjectHeader.fulfilled]: (state, action) => {
            console.log("In getS3Inputs.fulfilled")
            state.loading = false;
            state.headerList = action.payload;
        },
        [getS3ObjectHeader.rejected]: (state, action) => {
            console.log("In getS3Inputs.rejected")
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        }
    }
})

export const {
    resetCreateInputState,
    updateMappedToAttributes,
    updateAllMappedToAttributes,
    setAlert,
    changeInputChannel
} = createInputSlice.actions;
export default createInputSlice.reducer;
