import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedMatchTypes: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedMatchTypes: [],
    managedMatchType: null,
};

export const getManagedMatchTypes = createAsyncThunk('managedMatchTypes/get-all', async (arg, { getState, requestId }) => {
    const GET_MANAGED_MATCH_TYPES_URL = `${config.services.ATLAS.apiUrl}/managed-match-types`;
    const managedMatchTypes = await Api.get(true, GET_MANAGED_MATCH_TYPES_URL, buildQuery(getState().managedMatchTypes.currentPage, getState().managedMatchTypes.pageSize,
        getState().managedMatchTypes.order.by, getState().managedMatchTypes.order.direction, getState().managedMatchTypes.searchCriteria));
    return managedMatchTypes;
});

export const getManagedMatchTypesWithFilter = createAsyncThunk('managedMatchTypes/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-match-types`;
    const managedMatchTypes = await Api.get(true, GET_URL, filter);
    return managedMatchTypes;
});

export const getManagedMatchTypesCount = createAsyncThunk('managedMatchTypes/getCount', async (arg, { getState, requestId }) => {
    try {
        const GET_MANAGED_TYPES_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-match-types/count`;
        const managedMatchTypesCount = await Api.get(true, GET_MANAGED_TYPES_COUNT_URL, buildQuery(getState().managedMatchTypes.currentPage, getState().managedMatchTypes.pageSize,
            getState().managedMatchTypes.order.by, getState().managedMatchTypes.order.direction, getState().managedMatchTypes.searchCriteria));
        return managedMatchTypesCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const isGuid = regexExp.test(searchCriteria);
    const query = isGuid ? {
        filter: {
            where: {
                or: [{
                    id: {
                        eq: `${searchCriteria ? searchCriteria : ''}`
                    }
                },
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: 5,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                key: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

const managedMatchTypesSlice = createSlice({
    name: 'managedMatchTypesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedMatchTypes.splice(state.selectedMatchTypes.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedMatchTypes.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedMatchTypeState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedMatchTypes.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypes.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTypes = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypes.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTypes = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTypesWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = managedMatchTypesSlice.actions;
export default managedMatchTypesSlice.reducer;
