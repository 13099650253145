import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    contactName: null
};

export const getContactNameById = createAsyncThunk('contacts/getContactNameById', async (id) => {
    const GET_CONTACT_NAME_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${id}/name`;
    const contactName = await Api.get(true, GET_CONTACT_NAME_BY_ID_URL);
    return contactName;
});

export const changeContactNameById = createAsyncThunk('contacts/changeCompanyNameById', async (data) => {
    const CHANGE_CONTACT_NAME_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/contacts/${data.id}/name/change`;
    const response = await Api.post(true, CHANGE_CONTACT_NAME_BY_ID_URL, data.body);
    return response;
});

const contactNameSlice = createSlice({
    name: 'contactNameSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.contactName = null;
            return state;
        }
    },
    extraReducers: {
        [getContactNameById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getContactNameById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactName = action.payload;
            }
        },
        [getContactNameById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve contact information';
        },
        [changeContactNameById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeContactNameById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contactName = action.payload;
            }
        },
        [changeContactNameById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set contact information';
        },
    }
});

export const { clearState } = contactNameSlice.actions;
export default contactNameSlice.reducer;
