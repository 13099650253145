/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import CardLabel from "./CardLabel";
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px, max-content) 1fr;
  padding: 0 10px 0 0;
`;

const LeadText = styled.div `
  font-size: 15px;
  font-weight: bold;
  color: ${APP_COLORS.blue};
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function CompanyBox(props) {
    const {
        item
    } = props;

    return (
        <BoxContainer>
            <CardLabel>Company <span style={{fontSize: '12px',  fontWeight:200, fontStyle: 'italic'}}>{item.companyScore}</span></CardLabel>
            <LeadText>
                <>{item.companyName && item.companyName.length > 40 ?
                    `${item.companyName.substring(0, 40)}...` : item.companyName
                }</>
                &nbsp;&nbsp;
                {item.companyURLStatus === 'Active' && item.companyURL && (
                    <a target='_blank' href={`https://${item.companyURL}`}>
                        <FontAwesomeIcon
                            color={APP_COLORS['orange']}
                            icon={faArrowUpRightFromSquare}
                            style={{fontSize: '18px'}}
                        />
                    </a>
                )}
            </LeadText>
            <SubText>
                <>{item.companyAddress && item.companyName.length > 75 ?
                    `${item.companyAddress.substring(0, 75)}...` : item.companyAddress
                }</>
            </SubText>
        </BoxContainer>
    )
}
