import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import {isGuid} from "../../common/utils/Utils";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedListErrors: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedListErrors: [],
    managedListError: null,
};

export const getManagedListErrors = createAsyncThunk('managedListErrors/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-errors`;
    const managedListErrors = await Api.get(true, GET_URL, buildQuery(getState().managedListErrors.currentPage, getState().managedListErrors.pageSize,
        getState().managedListErrors.order.by, getState().managedListErrors.order.direction, getState().managedListErrors.searchCriteria));
    return managedListErrors;
});

export const getManagedListErrorsWithFilter = createAsyncThunk('managedListErrors/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-errors`;
    const managedListErrors = await Api.get(true, GET_URL, filter);
    return managedListErrors;
});

export const getManagedListErrorsCount = createAsyncThunk('managedListErrors/getCount', async (arg, {getState, requestId}) => {
    try {
        const searchCriteria = getState().managedListErrors.searchCriteria ? getState().managedListErrors.searchCriteria : '';
        const where = isGuid(searchCriteria) ? {
            where: {
                or: [
                    {
                        id: {
                            eq: searchCriteria
                        }
                    },
                    {
                        listId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        action: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                ]
            }
        } : {
            where: {
                or: [
                    {
                        action: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                ]
            }
        };
        const COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-list-errors/count`;
        const managedListErrorsCount = await Api.get(true, COUNT_URL, {filter: where});
        return managedListErrorsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = isGuid(searchCriteria) ? {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                id: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                listId: {
                                    eq: searchCriteria
                                }
                            },
                            {
                                action: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                action: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

const managedListErrorsSlice = createSlice({
    name: 'managedListErrorsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedMatchTypes.splice(state.selectedMatchTypes.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedMatchTypes.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedListErrorState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedListErrors.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrors.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListErrors = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrors.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListErrors = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListErrorsWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = managedListErrorsSlice.actions;
export default managedListErrorsSlice.reducer;
