/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter, useHistory, useLocation
} from 'react-router-dom';

import Base from './common/layout/Base';
import BasePage from './common/layout/BasePage';

import LoginContainer from './common/login/LoginContainer.jsx';
import RecoverContainer from './common/recover/RecoverContainer.jsx';
import ResetContainer from './common/reset/ResetContainer.jsx';

import AdminRouter from './admin/AdminRouter.jsx';
import AdminMenu from './admin/AdminMenu';
import OrganizationRouter from './organization/OrganizationRouter.jsx';
import OrganizationMenu from './organization/OrganizationMenu';

import {useDispatch, useSelector} from "react-redux";
import {getAccount} from "./common/account/accountSlice";
import * as cache from "./common/Cache";
import config from "./config";
import {isOwner} from "./common/account/AccountService";
import AccountConfirmContainer from "./common/account-confirm/AccountConfirmContainer";

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const publicPages = [
    '/login',
    '/recover',
    '/reset',
    '/confirm',
];

// Pertains to animation / transition groups
// const timeout = {enter: 500, exit: 500};
// Animations supported
//      'rag-fadeIn'
//      'rag-fadeInRight'
//      'rag-fadeInLeft'
// const animationName = 'rag-fadeIn';

const AppRouter = (props) => {
    const { location } = props;

    const accountDataState = useSelector(state => state.account);
    const dispatch = useDispatch();
    const history = useHistory();

    const search = useLocation().search;
    const currentPathname =search ? window.location.pathname+search : window.location.pathname;

    const [organizationMenu, setOrganizationMenu] = useState([]);

    useEffect(() => {
        const orgMenu = [...OrganizationMenu];

        if (isOwner()) {
            orgMenu.push({
                name: 'MANAGE',
                path: '/org/manage',
                subMenu: [
                    // {
                    //     name: 'Integrations',
                    //     path: '/org/manage/integrations'
                    // },
                    {
                        name: 'Team',
                        path: '/org/manage/team'
                    }
                ]
            });
        }

        setOrganizationMenu([...orgMenu]);
    }, [accountDataState.account?.id]);

    useEffect(() => {
        dispatch(getAccount());
        const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);

        if (currentPathname !== '/' && account) {
            history.push(currentPathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //const currentKey = location.pathname.split('/')[1] || '/';

    // If Authenticated and I'm a member of an organization
    if (accountDataState.authenticated && accountDataState.roleMapping === 'ORGANIZATION') {
        const showMenu = currentPathname.includes('/org/com/');
        return (
            <Base menu={organizationMenu} showMenu={!showMenu}>
                <div>
                    <Route path="/" component={OrganizationRouter} />
                </div>
                {/*http://reactcommunity.org/react-transition-group/transition-group*/}
                {/*<TransitionGroup>*/}
                {/*    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>*/}
                {/*        <div>*/}
                {/*            <Route path="/" component={OrganizationRouter} />*/}
                {/*        </div>*/}
                {/*    </CSSTransition>*/}
                {/*</TransitionGroup>*/}
            </Base>
        );
    }

    // If Authenticated and I'm an administrator
    if (accountDataState.authenticated && accountDataState.roleMapping === 'ADMIN') {
        const showMenu = currentPathname.includes('/admin/');
        return (
            <Base menu={AdminMenu} showMenu={showMenu}>
                <div>
                    <Route path="/" component={AdminRouter} />
                </div>
            </Base>
        );
    }

    // Unauthenticated 'Public' routes
    if (!accountDataState.authenticated && publicPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Switch location={location}>
                    <Route exact path="/login" component={LoginContainer}/>
                    <Route path="/recover" component={RecoverContainer}/>
                    <Route path="/reset" component={ResetContainer}/>
                    <Route path="/confirm" component={AccountConfirmContainer}/>
                </Switch>
            </BasePage>
        );
    }

    return (
        <Redirect to={{pathname: '/login'}} />
    );
}

export default withRouter(AppRouter);
