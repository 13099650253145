import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    searchCriteriaPlaceholder: `Enter your search here, for instance 'Licensed Roofer'`,
    searchFields: [
        'companyId',
        'companyIdentity',
        'companyScore',
        'companyName',
        'companyURL',
        'companyURLStatus',
        'companyAddressDeliveryLine1',
        'companyAddressCity',
        'companyAddressRegion',
        'companyAddressPostalCode',
        'companyPhone',
        'companyEmail',
        'companyEmailStatus',
        'companyClassTrade',
        'companyClassTrades',
        'companyIndResidential',
        'companyIndIndustrial',
        'companyIndExterior',
        'companyIndInterior',
        'companyIndLicensed',
        'companyIndBonded',
        'companyIndInsured',
        'companyProfileCnt',
        'companyProfileTypes',
        'companyProfileOnlineScoreNormalizedAvg',
        'companyManufacturerCnt',
        'companyManufacturers',
        'companyAssociationCnt',
        'companyAssociations',
        'companyUtilityCnt',
        'companyUtilities',
        'companyLicenseCnt',
        'companyPermitCnt',
        'companyPolicyCnt',
        'companyRegLocalRegistrationStatus',
        'companyStatEmployeesRange',
        'companyStatSalesRange',
        'companyFactFounded',
        'companyStatContactCnt',
        'companySourceCnt',
        'companySourceLabels',
        'contactId',
        'contactIdentity',
        'contactNameGivenName',
        'contactNameSurname',
        'contactTitle',
        'contactPhone',
        'contactEmail',
        'contactEmailStatus',
        'btVerified',
    ],
    searchResults: [],
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    contractorDirectory: [],
    searchLocationOption: 'USE_IP', // one of USE_IP or USE_LOCATION
    ipLocationData: null, // response from search option
    geocoderLocationData: null, // response from search option
    searchLocationData: null, // the active search option (lat lon)
    searchWithinMiles: 100,
    searchResultsMap: {
        map: null,
        data: {
            type: 'FeatureCollection',
            features: []
        },
    }
};

export const postContractorsFtsSearch = createAsyncThunk('contractorDirectory/get-contractor-fts-search', async (arg, {getState}) => {
    const POST_CONTRACTOR_FTS_SEARCH_URL = `${config.services.ATLAS.apiUrl}/contractors/search`;

    const ftsQuery = getState().contractorDirectory.searchCriteria ?
        getState().contractorDirectory.searchCriteria :
        getState().contractorDirectory.searchCriteriaPlaceholder.replace(`Enter your search here, for instance 'Licensed Roofer'`, 'Licensed Roofer');
    const requestBody = {
        options: {},
        data: {
            requestor: {
                type: 'bt_builder'
            },
            query: ftsQuery,
            requester: {
                type: "bt_builder"
            },
            latitude: parseFloat(getState().contractorDirectory.searchLocationData.latitude),
            longitude: parseFloat(getState().contractorDirectory.searchLocationData.longitude),
            searchWithinMiles: getState().contractorDirectory.searchWithinMiles
        }
    };
    const query = {filter: {fields: getState().contractorDirectory.searchFields}};
    return await Api.post(true, POST_CONTRACTOR_FTS_SEARCH_URL, requestBody, query);
});

export const getIpLocationData = createAsyncThunk('contractorDirectory/get-ip-location-data', async (arg) => {
    // https://route360.dev/post/get-ip-react/
    // Connect ipapi.co with fetch()
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json();
    // Set the IP address to the constant `ip`
    return Promise.resolve(data);
});

const contractorDirectorySlice = createSlice({
    name: 'contractorDirectorySlice', initialState,
    reducers: {
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
            }
        },
        setSearchLocationOption: {
            reducer(state, action) {
                state.searchLocationOption = action.payload;
                if (action.payload === 'USE_IP') {
                    state.searchLocationData = {
                        latitude: state.ipLocationData.latitude,
                        longitude: state.ipLocationData.longitude
                    };
                }
            }
        },
        setGeoCoderLocationData: {
            reducer(state, action) {
                state.geocoderLocationData = action.payload;
                state.searchLocationData = {
                    latitude: action.payload.latitude,
                    longitude: action.payload.longitude
                };
            }
        },
        setSearchLocationData: {
            reducer(state, action) {
                state.searchLocationData = action.payload;
            }
        },
    },
    extraReducers: {
        [postContractorsFtsSearch.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [postContractorsFtsSearch.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                console.log(action.payload);
                // Randomly assign btVerified; TODO: remove once overlay
                // state.searchResults = action.payload.map(i => _.assign({}, i, {
                //     // randomly select a value from array
                //     btVerified: [true, true, false, true, false][_.random(0, 4)]
                // }));
                state.searchResults = action.payload;

                // Build GEO JSON
                state.searchResultsMap.data = {
                    type: 'FeatureCollection',
                    features: action.payload.map(i => (
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [i.companyFtsLocationLongitude, i.companyFtsLocationLatitude],
                            },
                            properties: {
                                id: i.companyId
                            }
                        }
                    ))
                }
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [postContractorsFtsSearch.rejected]: (state, action) => {
            state.loading = false;
            if (action.error) {
                state.alertMessage = action.error.message;
            }
            state.successMessage = undefined;
        },
        [getIpLocationData.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getIpLocationData.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.ipLocationData = action.payload;
                state.searchLocationData = {
                    latitude: action.payload.latitude,
                    longitude: action.payload.longitude
                }
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getIpLocationData.rejected]: (state, action) => {
            state.loading = false;
            if (action.error) {
                state.alertMessage = action.error.message;
            }
            state.successMessage = undefined;
        },
    }
});


export const {
    setSearchCriteria,
    setSearchLocationOption,
    setGeoCoderLocationData,
    setSearchLocationData,
} = contractorDirectorySlice.actions;
export default contractorDirectorySlice.reducer;
