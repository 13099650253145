import React, { useEffect, useState} from 'react';
import { Button } from "react-bootstrap";
import BreadCrumb from "../../common/components/BreadCrumb";
import DataCollectionPanelHeader from "../../common/components/DataCollectionPanelHeader";
import DropdownAction from "../../common/components/DropdownAction";
import PanelTable from "../../common/components/layout/PanelTable";
import SearchContactInput from "../components/form/FormInputTextSearch";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import './ManagedContacts.scss';
import ManagedContactsTable from './ManagedContactsTable';
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import {
    clearSelected,
    setSearchCriteria,
    setTcuFilter,
    getManagedContactsCount,
    getManagedContacts,
    clearSelectedContacts
} from "./managedContactsSlice";

const ManagedContacts = (props) => {
    return (
        <ManagedContactsComponent />
    );
}

const ManagedContactsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Contacts',
        href: '',
        isActive: true
    }];

    const managedContactsState = useSelector(state => state.managedContacts);
    const dispatch = useDispatch();
    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [searchSubTextFilter,SetsearchSubTextFilter] = useState('No Additional Filters Applied');
    const [searchSubText,SetSearchSubText] = useState('Search Contacts!');

    // Options state vars
    const [products, setProducts] = useState([]);
    const [fts, setFts] = useState(false);
    const [randomReviewset, setRandomReviewSet] = useState(false);

    useEffect(() => {
        if (products.map(a => a.value).includes('Contractor Universe (TCU)')) {
            dispatch(setTcuFilter(true));
        } else {
            dispatch(setTcuFilter(false));
        }
        if(products.length > 0)
        {
            SetsearchSubTextFilter('Selected Filters: '+products.map(a => a.value).join(','));
        }else{
            SetsearchSubTextFilter('No Additional Filters Applied');
        }
       // setLastReload(Date.now());
    }, [dispatch, products]);

    useEffect(() => {
        dispatch(clearSelectedContacts());
    }, []);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedContactsState.alertMessage) {
            setAlertMessage(managedContactsState.alertMessage);
            setShowAlert(true);
        }
    }, [managedContactsState.alertMessage]);

    const isReload = managedContactsState.reload === true;

    useEffect(() => {
        dispatch(getManagedContactsCount());
        dispatch(getManagedContacts());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }

    const actionItems = [
        {
            label: 'EDIT',
            href: '',
            isActive: true,
            onClickHandler: () => history.push(`/admin/d/companies/${managedContactsState.selectedContacts[0].companyId}/edit?selectedContactId=${managedContactsState.selectedContacts[0].id}`)
        }, 
       
    ];

    return (
        <div className="contacts-container">
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <DataCollectionPanelHeader
                header={`Contacts (${managedContactsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH</Button>
                        <DropdownAction items={actionItems}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled
                            onClick={() => {}}
                        >CREATE</Button>
                    </div>
                }                
                searchBar={
                    <SearchContactInput
                        placeholder={'Search by Id or Identity...'}
                        className={'contactsSearchText'}
                        value={managedContactsState.searchCriteria}
                        onChange={(value) => {dispatch(setSearchCriteria(value))}}
                        onKeyDown={handleOnKeyDown}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </SearchContactInput>
                }
                searchSubText={searchSubText}
                searchSubTextFilter={searchSubTextFilter}
                products={products}
                setProducts={setProducts}
                fts={fts}
                setFts={setFts}
                randomReviewset={randomReviewset}
                setRandomReviewSet={setRandomReviewSet}
            />
            <PanelTable>
            {managedContactsState.loading && (
                    <SpinnerOverlay />
                )}
                <ManagedContactsTable lastReload={lastReload} />
            </PanelTable>
        </div>
    );
}

export default ManagedContacts;