/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars

import Accounts from './Accounts';

const AccountsContainer = (props) => {

    return(
        <Accounts
        />
    )
};

export default AccountsContainer;
