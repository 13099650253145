/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faEnvelope,
    faPhone,
    faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import mapMarkerIcon from '../../../../common/assets/img/ToolbeltIcons-15.png';
import {APP_COLORS} from '../../../../common/Constants';
import ExploreMapFlyoutDetailLoadingSpinner from './ExploreMapFlyoutDetailLoadingSpinner';
import {FilterOptions} from "../../FilterOptions";
import {
    countCompanyAndContactByCriteria, replaceFilter as replaceCompanyFilter,
    searchCompanyByCriteria
} from "../../companySlice";
import {
    countCompanyAndContactByCriteria as countContactAndCompanyByCriteria,
    replaceFilter as replaceContactFilter,
    searchContactByCriteria
} from "../../contactSlice";
import {setExploreMode} from "../../exploreSlice";
import {
    getListImportOverlayRecord,
    removeCubeTableName
} from '../../../../common/services/ListService'
import {
    faCalculator, faIdBadge, faSatelliteDish,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {
    faFileCertificate
} from '@fortawesome/pro-solid-svg-icons';
import {cloneDeep, concat} from "lodash";
import {ExploreMapFlyoutSelectedDetailSection} from './ExploreMapFlyoutSelectedDetailSection';
import {SelectStyled} from "../../../../common/components/form/FormSelect";

const options = [
    { value: 5, label: '5 Miles' },
    { value: 10, label: '10 Miles' },
    { value: 20, label: '20 Miles' },
    { value: 50, label: '50 Miles' },
    { value: 100, label: '100 Miles' },
    { value: 200, label: '200 Miles' },
]

export default function ExploreMapFlyoutDetailSelectedImportListLocation(props) {
    const dispatch = useDispatch();

    const exploreDataState = useSelector(state => state.explore);
    const [loading, setLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [selectedDistanceFrom, setSelectedDistanceFrom] = useState(null);
    const {
        currentFilters,
    } = props;
    const _filters = cloneDeep(currentFilters);
    useEffect(() => {
        console.log('ExploreMapFlyoutDetailSelectedImportListLocation - List Location');
        setLoading(true);
        _getSelectedLocation(props.selectedPoint)
            .then((response) => {
                setLoading(false);
                setSelectedLocation(response);
            });
    }, [props.selectedPoint]);

    if (loading) return (<ExploreMapFlyoutDetailLoadingSpinner/>);

    const handleSearch = async (filters_) => {
        let filters = concat(_filters, filters_);
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            dispatch(replaceCompanyFilter(filters));
            dispatch(searchCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(countCompanyAndContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(setExploreMode({
                ...exploreDataState.exploreMode,
                mode: 'COMPANY',
                company: {
                    ...exploreDataState.exploreMode.company,
                    sections: {
                        ...exploreDataState.exploreMode.company.sections,
                    }
                }
            }));
            return;
        }
        dispatch(replaceContactFilter(filters));
        dispatch(searchContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
        dispatch(countContactAndCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
        dispatch(setExploreMode({
            ...exploreDataState.exploreMode,
            mode: 'CONTACT',
            contact: {
                ...exploreDataState.exploreMode.contact,
                sections: {
                    ...exploreDataState.exploreMode.contact.sections,
                }
            }
        }));
    };


    if (selectedLocation) {
        const {
            overlay_name,
            overlay_website,
            overlay_address_delivery_line_1,
            overlay_address_city,
            overlay_address_region,
            overlay_address_postal_code,
            overlay_address_longitude,
            overlay_address_latitude,

            company_stat_contact_cnt,
            company_profile_cnt,
            company_license_cnt,
            company_permit_cnt,
            company_policy_cnt,
        } = selectedLocation;

        const overlay_address_last_line = `${overlay_address_city}, ${overlay_address_region} ${overlay_address_postal_code}`;

        const densityIcons = [
            {attr: 'overlay_website', icon: faGlobe},
            {attr: 'company_email', icon: faEnvelope},
            {attr: 'company_phone', icon: faPhone},
            {attr: 'company_address_delivery_line_1', icon: faMapMarkerAlt},
        ];
        const densityIconsJsx = [];
        densityIcons.forEach((i, idx) => {
            if (selectedLocation[i.attr]) {
                let marginLeft = '10px';
                if (densityIconsJsx.length === 0) marginLeft = '0px';
                // TODO: Garbage data ....
                if (i.attr === 'overlay_website' && selectedLocation[i.attr] !== 'null') {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <a
                                href={`${overlay_website}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['orange']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </a>
                        </div>
                    )
                } else {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            />
                        </div>
                    )
                }
            }
        });

        const badgesIcons = [
            {attr: 'company_stat_contact_cnt', value: company_stat_contact_cnt, icon: faUser},
            {attr: 'company_profile_cnt', value: company_profile_cnt, icon: faSatelliteDish},
            {attr: 'company_license_cnt', value: company_license_cnt, icon: faIdBadge},
            {attr: 'company_permit_cnt', value: company_permit_cnt, icon: faCalculator},
            {attr: 'company_policy_cnt', value: company_policy_cnt, icon: faFileCertificate}
        ];

        const badgesIconsJsx = [];
        badgesIcons.forEach((i) => {
            if (parseInt(i.value, 10) > 0) {
                badgesIconsJsx.push(
                    <div key={badgesIconsJsx.length + 1} className="selected-point-detail-record-badges-icons-item">
                        <span>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            /><span style={{fontSize: '14px', fontWeight: '600'}}> {i.value}</span>
                        </span>
                    </div>
                )
            }
        });

        return (
            <Row>
                <Col>
                    <div className="explore-map-flyout-detail">
                         <ExploreMapFlyoutSelectedDetailSection
                            name={overlay_name}
                            address1={overlay_address_delivery_line_1}
                            address2={overlay_address_last_line}
                            densityIconsJsx={densityIconsJsx}
                            detailIconJsx={
                                <Image
                                    className="icon"
                                    src={mapMarkerIcon}
                                    alt="ToolBeltData Map Marker Icon"
                                    fluid
                                />}
                         />
                        <div className="flyout-map-tools-section">
                            <div className="label-text">SEARCH WITHIN</div>
                            <SelectStyled
                                id="selected-location-point-geospatial-search-typeahead"
                                options={options}
                                onChange={(e) => {
                                    setSelectedDistanceFrom(e);
                                    if (e) {
                                        return handleSearch([{
                                            ...FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_ADDRESS_GEO_RADIUS,
                                            value: {
                                                latitude: overlay_address_latitude,
                                                longitude: overlay_address_longitude,
                                                radius: e.value,
                                            }
                                        }]);
                                    }
                                }}
                                value={selectedDistanceFrom}
                                placeholder={'Select a distance ...'}
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
}

ExploreMapFlyoutDetailSelectedImportListLocation.propTypes = {
    selectedPoint: PropTypes.object,
};

async function _getSelectedLocation(selectedPoint) {
    const {
        id,
        list_id
    } = selectedPoint.properties;

   const results = await getListImportOverlayRecord(list_id, id);
   if (results && results.length > 0) {
       const data =  removeCubeTableName(results[0]);
       return ({
           ...data
       });
   }
   return null;
}
