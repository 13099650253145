/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import OrganizationsTable from "./OrganizationsTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getOrganizationsCount,
    getOrganizations
} from "./organizationsSlice";

import PanelTable from "../../common/components/layout/PanelTable";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import './Organizations.scss';

const Organizations = (props) => {
    return (
        <OrganizationsComponent
        />
    );
};

const OrganizationsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Organizations',
        href: '',
        isActive: true
    }];

    const organizationsState = useSelector(state => state.organizations);
    const dispatch = useDispatch();
    const history = useHistory();


    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (organizationsState.alertMessage) {
            setAlertMessage(organizationsState.alertMessage);
            setShowAlert(true);
        } else {
            setAlertMessage('');
            setShowAlert(false);
        }
    }, [organizationsState.alertMessage]);

    const isReload = organizationsState.reload === true;

    useEffect(() => {
        dispatch(getOrganizationsCount());
        dispatch(getOrganizations());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
      if (target.code === 'Enter') {
          setLastReload(Date.now())
      }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [
            {
                label: 'EDIT',
                href: '',
                isActive: organizationsState?.selectedId?.length === 1,
                onClickHandler: () => history.push(`/admin/organizations/${organizationsState.selectedId[0]}/managed-organizations`)
            },
            {
                label: 'ADD TEAM MEMBER',
                href: '',
                isActive: organizationsState?.selectedId?.length === 1,
                onClickHandler: () => history.push(`/admin/organizations/${organizationsState.selectedId[0]}/organization-add-team-member`)
            }
        ];

        return actionItems;
    };

    return (
        <div className="organizations-container">
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Organizations (${organizationsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/organizations/create-organization')}
                        >
                            ONBOARD
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id or name...'}
                        className={'organizationSearchText'}
                        value={organizationsState.searchCriteria}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        onKeyDown={handleOnKeyDown}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {organizationsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <OrganizationsTable lastReload={lastReload}/>
            </PanelTable>
        </div>
    );
};


export default Organizations;
