import React, { useState, forwardRef, Component } from 'react';
import {useDispatch} from 'react-redux';
import {denormCompanyUpdate} from "./entityVerificationsSlice";

export const AddressAttributeEditor = forwardRef((props, ref) => {

    const uiAttributeName = props.colDef.headerName;
    const attributeType = props.attributeType;
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const entityIdColumn = props.entityIdColumn;

    const [rowData, setRowData] = useState( props.data);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const [attributeId, setAttributeId] = useState(rowData[attributeDenormPrefix+'Id']);
    const [deliveryLine1, setDeliveryLine1] = useState(props.data[attributeDenormPrefix+'DeliveryLine1']);
    const [city, setCity] = useState(props.data[attributeDenormPrefix+'City']);
    const [region, setRegion] = useState(props.data[attributeDenormPrefix+'Region']);
    const [country, setCountry] = useState(props.data[attributeDenormPrefix+'Country']);
    const [postalCode, setPostalCode] = useState(props.data[attributeDenormPrefix+'PostalCode']);

    const [verifiedBy, setVerifiedBy] = useState(setVerifiedByBeginState(rowData[attributeDenormPrefix+'VerifiedBy']));
    const [invalidatedReason, setInvalidatedReason] = useState(setInvalidatedReasonBeginState(rowData[attributeDenormPrefix+'InvalidatedReason']));
    const dispatch = useDispatch();

/*
    getValue() {
        alert('CompanyAddressCellEditor getValue called!');
        alert(`current value = ${this.url.current.value}`);
        return this.url.current.value;
    };

    afterGuiAttached() {
        alert(`CompanyAddressCellEditor afterGuiAttached called!`);
        alert(`this equals ${JSON.stringify(this)}`);
        if (this.url) this.url.current.focus();
    }; */

    function setVerifiedByBeginState(beginValue){

        const validVerifieds = ['---'
            ,'[Entity Verification] - URL Verified - CHANGE'
            ,'[Entity Verification] - URL Verified - VERIFY (NO CHANGE)'
            ,'[Entity Verification] - URL Verified - OTHER'
            ,'[Entity Verification] - Phone Verified'
            ,'[Entity Verification] - Other'];

        if(beginValue === null){
            return '---';
        }
        else if(validVerifieds.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    function setInvalidatedReasonBeginState(beginValue){

        const validReasons = ['---'
            ,'[Entity Verification] - URL Verified - INVALIDATE'
            ,'[Entity Verification] - Phone Verified - INVALIDATE'
            ,'[Entity Verification] - Other'];

        if(beginValue === null){
            return '---';
        }
        else if(validReasons.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault(); //prevent refresh on submit
        try {
            dispatch(denormCompanyUpdate({
                entityVerificationId: rowData["id"],
                companyId: rowData["companyId"],
                entityId: rowData[entityIdColumn],
                attributeType: attributeType,
                attributeDenormPrefix: attributeDenormPrefix,

                attributeId: rowData[attributeDenormPrefix+'Id'],
                columns: {  deliveryLine1: deliveryLine1,
                            city: city,
                            region: region,
                            country: country,
                            postalCode: postalCode,
                            verifiedBy: verifiedBy === '---' ? null : verifiedBy,
                            invalidatedReason: invalidatedReason === '---' ? null: invalidatedReason},
                rowIndex: rowIndex
            }));

            // if (url) url.current.focus();
        }
        catch (err){
            console.log(`Had an exception and err = ${JSON.stringify(err)}`);
        }
    };

    const changeVerified = (event) => {
        setVerifiedBy(event.target.value);
        if(event.target.value === '---' && (invalidatedReason === '---')){
            setSubmitEnabled(false);
        }
        else if( event.target.value !== '---' && invalidatedReason !== '---'){ //Can't have both values set.
            setSubmitEnabled(false);
        }
        else {
            setSubmitEnabled(true);
        }
    }

    const changeInvalidated = (event) => {
        setInvalidatedReason(event.target.value);
        if(event.target.value === '---' && verifiedBy === '---' ){
            setSubmitEnabled(false);
        }
        else if(event.target.value !== '---' && verifiedBy !== '---' ){
            setSubmitEnabled(false);
        }
        else{
            setSubmitEnabled(true);
        }
    }



    return (
            // <input onKeyPress={this.onKeyPress} ref={this.textInput} defaultValue={this.props.value}/>
            <form style={{backgroundColor: "white", width:"300px"}} onSubmit={handleSubmit}>
                <div style={{marginLeft: "10px"}}>
                <label>Delivery Line 1:<br/><input style={{width: "280px"}} type="text" value={deliveryLine1} onChange={(e) => setDeliveryLine1(e.target.value)}/></label><br/>
                <label>City:<br/><input type="text" value={city} onChange={(e) => setCity(e.target.value)}/></label><br/>
                <label>Region:<br/><input type="text" value={region} onChange={(e) => setRegion(e.target.value)}/></label><br/>
                <label>Postal Code:<br/><input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/></label><br/>
                <label>Verified By:<br/><select value={verifiedBy} onChange={changeVerified}>
                    <option value='---'>---</option>
                    <option value='[Entity Verification] - URL Verified - CHANGE'>URL Verified - CHANGE</option>
                    <option value='[Entity Verification] - URL Verified - VERIFY (NO CHANGE)'>URL Verified - VERIFY (NO CHANGE)</option>
                    <option value='[Entity Verification] - URL Verified - OTHER'>URL Verified - OTHER</option>
                    <option value='[Entity Verification] - Phone Verified'>Phone Verified</option>
                    <option value='[Entity Verification] - Other'>Other</option>
                </select></label><br/>
                <label>Invalidated Reason:<br/><select value={invalidatedReason} onChange={changeInvalidated}>
                    <option value='---'>---</option>
                    <option value='[Entity Verification] - URL Verified - INVALIDATE'>URL Verified - INVALIDATE</option>
                    <option value='[Entity Verification] - Phone Verified - INVALIDATE'>Phone Verified - INVALIDATE</option>
                    <option value='[Entity Verification] - Other'>Other</option>
                </select></label><br/>
                <button disabled={!submitEnabled}>Submit</button><br/><br/><br/>

                <label>Attribute Id (edits not applied): <br/><input style={{width: "280px"}} value={attributeId}/></label>
                </div>
            </form>
    );
});