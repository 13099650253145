import React, {useRef, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getLists} from './listSlice';
import Modal from '../../common/components/Modal';
import {
    Container,
    Form,
    Row,
    Col,
    Spinner,
    Button,
    FormCheck,

} from 'react-bootstrap';

import {
    get,
} from 'lodash';
import {TwitterPicker} from 'react-color';
import {getById, update} from '../../common/services/ListService';
import Styles from'./EditListDialog.scss';
import SpriteIconPicker from '../../common/components/SvgSpritesIconPicker';
import { useOutsiderClick } from '../../common/components/useOutsiderClick';
import { LIST_TYPES, getTypeById } from '../../common/metadata/ListTypes';
import { icons } from '../../common/assets/img/fa-icons/FaIcons';
import spritesFile from '../../common/assets/img/fa-icons/original-solid.svg';
import * as Api from '../../common/Api';
import config from '../../config';
import UploadIcon from '../../common/components/UploadIcon';
import * as cache from "../../common/Cache";
import FormTextInput from "../../common/components/FormTextInput";
import FormContainer from "../../common/components/layout/FormContainer";
import FormTextArea from "../../common/components/form/FormTextArea";

function EditListDialog(props) {
    const dispatch = useDispatch();
    const {
        handleClose,
        id,
    } = props;

    const [selectedList, setSelectedList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [configColor, setConfigColor] = useState('#0171fe');
    const [configTcuMatchedColor, setConfigTcuMatchedColor] = useState('#0171fe');
    const [configSprite, setConfigSprite] = useState(null);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayTcuMatchedColorPicker, setDisplayTcuMatchedColorPicker] = useState(false);
    const [listType, setListType] = useState('');
    const [imgSecureUrl, setImgSecureUrl] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [imageMode, setImageMode] = useState('upload');

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const list = await getById(id);
            setSelectedList(list);
            setConfigColor(get(list, 'config.map.overlay.color', '#0171fe'));
            setConfigTcuMatchedColor(get(list, 'config.map.overlay.tcuMatchedColor', '#0171fe'));
            setConfigSprite(get(list, 'config.map.overlay.img', 'map-marker-alt'));
            const _imgUrl = get(list, 'config.map.overlay.imgUrl', null);
            setImgUrl(_imgUrl);
            setImgSecureUrl(get(list, 'config.map.overlay.imgSecureUrl', null));
            if (_imgUrl) {
                setImageMode('upload');
            } else {
                setImageMode('sprite');
            }
            const typeObj = getTypeById(list.type);
            setListType(typeObj ? typeObj.display : list.type);
            setLoading(false);
        };
        init();
    }, [id]);

    const updateList = async () => {
        setLoading(true);
        try {
            const overlay = {
                    color: configColor,
                    tcuMatchedColor: selectedList && selectedList.type === LIST_TYPES.IMPORT_OVERLAY.id ? undefined : configTcuMatchedColor,
                    img: selectedList && selectedList.type === LIST_TYPES.IMPORT_OVERLAY.id && imageMode === 'sprite' ?  configSprite : undefined,
                    imgUrl: imageMode === 'upload' && imgUrl ? imgUrl : undefined,
                    imgSecureUrl: imageMode === 'upload' && imgSecureUrl ? imgSecureUrl : undefined

                };

            const data = {
                name: selectedList.name,
                description: selectedList.description,
                config: {
                    map: {
                        overlay: overlay
                    }
                }
            };
            await update(id, data);
            dispatch(getLists());
            setLoading(false);
            handleClose();
        } catch(error) {
            setLoading(false);
        }
    };
    const isValid = () => {
        if (!selectedList || !selectedList.name) {
            return false;
        }
        return true;
    };

    const colorRef = useRef(null);
    useOutsiderClick(colorRef, () => setDisplayColorPicker(false));

    const tcuMatchedColorRef = useRef(null);
    useOutsiderClick(tcuMatchedColorRef, () => setDisplayTcuMatchedColorPicker(false));

    const showOverlayOptions = () => {
        if (selectedList.type === 'IMPORT_OVERLAY') return true;
        return false;
    };

    const showMatchAndExportOptions = () => {
        if (
            selectedList.type === 'MATCH_COMPANY' ||
            selectedList.type === 'MATCH_CONTACT' ||
            selectedList.type === 'EXPORT_COMPANY' ||
            selectedList.type === 'EXPORT_CONTACT'
        ) return true;
        return false;
    };

    return (
        <Modal
            dialogClassName={'dialog'}
            showClose={true}
            show={true}
            handleClose={handleClose}
            title={`Edit List`}
            footer={
                (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>

                        <Button
                            variant='info'
                            disabled={loading || !isValid()}
                            onClick={() => updateList()}
                        >
                            {!loading && <>Save</>}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            variant='outline-secondary'
                            onClick={() => handleClose()}
                            style={{marginLeft: '10px'}}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            {loading && !selectedList?
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        style={{marginTop: '30px'}}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
                :
                <Container className={Styles.container}>
                    <FormContainer>
                        <Form noValidate>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Name'}
                                        value={selectedList?.name}
                                        onChange={(value) => setSelectedList({
                                            ...selectedList,
                                            name: value})}
                                        // isInvalid={}
                                        size={'sm'}
                                        placeholder="Please enter a name for the list"
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextArea
                                        label={'Description'}
                                        // isInvalid={}
                                        size={'sm'}
                                        rows={3}
                                        value={selectedList?.description}
                                        onChange={(value) => setSelectedList({
                                            ...selectedList,
                                            description: value})}
                                    />
                                </Col>
                            </Row>
                            <div>
                                {/*KEEPING THIS FOR A MINUTE, EVERYTHING GOING FORWARD IS "MATCHED" NOT SURE WE NEED TO DIFFERENTIATE*/}
                                {/*{*/}
                                {/*    (*/}
                                {/*        selectedList.type === 'MATCH_COMPANY' ||*/}
                                {/*        selectedList.type === 'MATCH_CONTACT' ||*/}
                                {/*        selectedList.type === 'EXPORT_COMPANY' ||*/}
                                {/*        selectedList.type === 'EXPORT_CONTACT'*/}
                                {/*    ) &&*/}
                                {/*    <Row>*/}
                                {/*        <Col>*/}
                                {/*            <Row>*/}
                                {/*                <Col/>*/}
                                {/*                <Col>*/}
                                {/*                    <Form.Label*/}
                                {/*                        id={'form-control-tcu-matched-color-label-id'}*/}
                                {/*                    >*/}
                                {/*                        Matched Color:*/}
                                {/*                    </Form.Label>*/}
                                {/*                </Col>*/}
                                {/*                <Col xs={6}>*/}
                                {/*                    {configTcuMatchedColor &&*/}
                                {/*                        <div>*/}
                                {/*                            <div*/}
                                {/*                                className={Styles.swatch}*/}
                                {/*                                onClick={() => setDisplayTcuMatchedColorPicker(true)}*/}
                                {/*                            >*/}
                                {/*                                <div style={{*/}
                                {/*                                    width: '50px',*/}
                                {/*                                    height: '30px',*/}
                                {/*                                    borderRadius: '2px',*/}
                                {/*                                    backgroundColor: `${configTcuMatchedColor}`,*/}
                                {/*                                }}/>*/}
                                {/*                            </div>*/}
                                {/*                            {displayTcuMatchedColorPicker ?*/}
                                {/*                                <div ref={tcuMatchedColorRef} className={Styles.popover}>*/}
                                {/*                                    <div className={Styles.cover}*/}
                                {/*                                         onClick={() => setDisplayTcuMatchedColorPicker(false)}/>*/}
                                {/*                                    <TwitterPicker*/}
                                {/*                                        color={configTcuMatchedColor}*/}
                                {/*                                        onChangeComplete={(color) => {*/}
                                {/*                                            setConfigTcuMatchedColor(color.hex);*/}
                                {/*                                            setDisplayTcuMatchedColorPicker(false);*/}
                                {/*                                        }}*/}
                                {/*                                    />*/}
                                {/*                                </div> : null}*/}
                                {/*                        </div>*/}
                                {/*                    }*/}
                                {/*                </Col>*/}
                                {/*                <Col/>*/}
                                {/*            </Row>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*}*/}
                                {
                                    showOverlayOptions() && (
                                        <>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                <Col md={12}>
                                                    <Form.Label
                                                        id={'form-control-overlay-label-id'}
                                                        className={"formTextLabel"}
                                                    >
                                                        Explore Options
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                <Col md={12}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }}>
                                                        <Form.Label
                                                            id={'form-control-overlay-label-id'}
                                                            className={"formTextLabel"}
                                                        >
                                                            Display As
                                                        </Form.Label>
                                                        <div style={{
                                                            marginLeft: '20px'
                                                        }}>
                                                            <FormCheck
                                                                inline
                                                                label="Sprite"
                                                                name="group1"
                                                                type={'radio'}
                                                                id='inline-radio-sprite'
                                                                onChange={() => setImageMode('sprite')}
                                                                checked={imageMode === 'sprite'}
                                                            />
                                                            <FormCheck
                                                                inline
                                                                label="Upload my own Image"
                                                                name="group1"
                                                                type={'radio'}
                                                                id='inline-radio-upload'
                                                                onChange={() => setImageMode('upload')}
                                                                checked={imageMode === 'upload'}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                {imageMode !== 'upload' && (
                                                    <Col md={12}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}>
                                                            <Form.Label
                                                                id={'form-control-overlay-label-id'}
                                                                className={"formTextLabel"}
                                                            >
                                                                Color
                                                            </Form.Label>
                                                            {configColor &&
                                                                <div style={{
                                                                    marginLeft: '60px'
                                                                }}>
                                                                    <div
                                                                        className={Styles.swatch}
                                                                        onClick={() => setDisplayColorPicker(true)}
                                                                    >
                                                                        <div style={{
                                                                            width: '150px',
                                                                            height: '30px',
                                                                            borderRadius: '2px',
                                                                            backgroundColor: `${configColor}`,
                                                                        }}/>
                                                                    </div>
                                                                    {displayColorPicker ?
                                                                        <div ref={colorRef} className={Styles.popover}>
                                                                            <div className={Styles.cover}
                                                                                 onClick={() => setDisplayColorPicker(false)}/>
                                                                            <TwitterPicker
                                                                                color={configColor}
                                                                                onChangeComplete={(color) => {
                                                                                    setConfigColor(color.hex);
                                                                                    setDisplayColorPicker(false);
                                                                                }}
                                                                            />
                                                                        </div> : null}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                <Col md={12}>
                                                    <Form.Label
                                                        id={'form-control-overlay-label-id'}
                                                        className={"formTextLabel"}
                                                    >
                                                        Selected for Display
                                                    </Form.Label>
                                                    { imageMode === 'sprite' &&
                                                        <SpriteIconPicker
                                                            url={spritesFile}
                                                            value={configSprite}
                                                            options={icons}
                                                            onSelectIcon={(name) => setConfigSprite(name)}
                                                            color={configColor}
                                                        />
                                                    }
                                                    {imageMode === 'upload' &&
                                                        <UploadIcon
                                                            imgSecureUrl={imgSecureUrl}
                                                            onUpload={async (photo) => {
                                                                try {
                                                                    setLoading(true);
                                                                    const result = await Api.upload(true, `${config.services.ATLAS.apiUrl}/lists/${id}/upload-img`, photo);

                                                                    const originalSecureUrl = get(result, 'secure_url', null);
                                                                    const originalUrl = get(result, 'url', null);
                                                                    const idxSecure = originalSecureUrl.indexOf('/image/upload');
                                                                    const resizeSecureUrl = `${originalSecureUrl.substring(0,idxSecure)}/image/upload/c_scale,w_24${originalSecureUrl.substring(idxSecure+13)}`;

                                                                    const idx = originalUrl.indexOf('/image/upload');
                                                                    const resizeUrl = `${originalUrl.substring(0,idx)}/image/upload/c_scale,w_24${originalUrl.substring(idx+13)}`;

                                                                    setImgSecureUrl(resizeSecureUrl);
                                                                    setImgUrl(resizeUrl);
                                                                    setLoading(false);
                                                                } catch (err) {
                                                                    setLoading(false);
                                                                }
                                                            }
                                                            }
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                            { imageMode === 'upload' && imgSecureUrl && (
                                                <Row style={{
                                                    marginTop: '10px',
                                                    paddingBottom: '0px'
                                                }}>
                                                    <Col md={12}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}>
                                                            <Form.Label
                                                                id={'form-control-overlay-label-id'}
                                                                className={"formTextLabel"}
                                                            >
                                                                Preview
                                                            </Form.Label>
                                                            <div style={{
                                                                marginLeft: '20px'
                                                            }}>
                                                                <img
                                                                    src={imgSecureUrl}
                                                                    alt={'upload'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                )}
                                            <Row>
                                                <Col/>
                                            </Row>
                                        </>
                                    )
                                }
                                {/* MATCH AND EXPORT */}
                                {
                                    showMatchAndExportOptions() && (
                                        <>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                <Col md={12}>
                                                    <Form.Label
                                                        id={'form-control-overlay-label-id'}
                                                        className={"formTextLabel"}
                                                    >
                                                        Explore Options
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row style={{
                                                marginTop: '10px',
                                                paddingBottom: '0px'
                                            }}>
                                                {imageMode !== 'upload' && (
                                                    <Col md={12}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row'
                                                        }}>
                                                            <Form.Label
                                                                id={'form-control-overlay-label-id'}
                                                                className={"formTextLabel"}
                                                            >
                                                                Color
                                                            </Form.Label>
                                                            {configColor &&
                                                                <div style={{
                                                                    marginLeft: '60px'
                                                                }}>
                                                                    <div
                                                                        className={Styles.swatch}
                                                                        onClick={() => setDisplayColorPicker(true)}
                                                                    >
                                                                        <div style={{
                                                                            width: '150px',
                                                                            height: '30px',
                                                                            borderRadius: '2px',
                                                                            backgroundColor: `${configColor}`,
                                                                        }}/>
                                                                    </div>
                                                                    {displayColorPicker ?
                                                                        <div ref={colorRef} className={Styles.popover}>
                                                                            <div className={Styles.cover}
                                                                                 onClick={() => setDisplayColorPicker(false)}/>
                                                                            <TwitterPicker
                                                                                color={configColor}
                                                                                onChangeComplete={(color) => {
                                                                                    setConfigColor(color.hex);
                                                                                    setDisplayColorPicker(false);
                                                                                }}
                                                                            />
                                                                        </div> : null}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                                <Col/>
                                            </Row>
                                        </>
                                    )
                                }
                            </div>
                        </Form>
                    </FormContainer>
                </Container>
            }
        </Modal>
    );
}

export default EditListDialog;
