/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import moment from 'moment';

import FormTextInput from './FormTextInput';
import ComboBox from './ComboBox';
import CheckBox from './CheckBox';
import RangeSlider from './RangeSlider';
import FormDatePicker from './FormDatePicker';
import FormNumberInput from "./FormNumberInput";

/**
 * A Factory function to create Filter components
 * @param filterOptions
 * @param stateObj (filterOptionId, value) property.
 * @param handleKeyValueChange
 * @param metaDataObj (filterOptionId, value) object use for list data such as combobox.
 * @param handleSubmitFilter
 * @param handleRemoveFilter
 * @return {*}
 * @constructor
 */

function FilterComponentBuilder(filterOptions, stateObj, handleKeyValueChange, metaDataObj, handleSubmitFilter, handleRemoveFilter) {
    if (!filterOptions || filterOptions.length === 0) {
        return null;
    }

    const filterComponents = filterOptions.map(ftOption => {
        return createComponentFilter({ftOption, stateObj, handleKeyValueChange, metaDataObj, handleSubmitFilter, handleRemoveFilter});
    });
    return (
        <div>
            {filterComponents}
        </div>
    );
}

function createComponentFilter(props) {
    const {
        ftOption,
        stateObj,
        handleKeyValueChange,
        metaDataObj,
        handleSubmitFilter,
        handleRemoveFilter
    } = props;
    switch (ftOption.component) {
        case 'combobox':
            return createComboBox({ftOption, stateObj, handleKeyValueChange, metaDataObj, handleSubmitFilter, handleRemoveFilter});
        case 'checkbox':
            return createCheckbox({ftOption, stateObj, handleKeyValueChange, handleSubmitFilter, handleRemoveFilter});
        case 'rangeslider':
            return createRangeSlider({ftOption, stateObj, handleKeyValueChange, handleSubmitFilter, handleRemoveFilter});
        case 'datepicker':
            return createDatePicker({ftOption, stateObj, handleKeyValueChange, handleSubmitFilter, handleRemoveFilter});
        case 'numberInput':
            return createNumberInput({ftOption, stateObj, handleKeyValueChange, handleSubmitFilter});
            case 'textbox':
        default:
            return createTextInput({ftOption, stateObj, handleKeyValueChange, handleSubmitFilter});
    };

}

function createTextInput(props) {
    const {
        id,
        display,
        placeholder,
    } = props.ftOption;

    const {
        stateObj,
        handleKeyValueChange,
        handleSubmitFilter
    } = props;

    const onSubmit = () => {
        if (stateObj[id]) {
            handleSubmitFilter({
                ...props.ftOption,
                value: stateObj[id]
            })
        }
    };
    return (
        <div
            key={id}
        >
                <FormTextInput
                id={`${id}-text-input`}
                label={display}
                placeholder={placeholder}
                value={stateObj &&  stateObj[id] ? stateObj[id] : ''}
                onChange={(value) => handleKeyValueChange(id, value)}
                onBlur={() => onSubmit()}
                onKeyDown={(e) => {
                    if(e.key === 'Enter') {
                        onSubmit();
                    }
                }}
            />
        </div>

            );
}


function createComboBox(props) {
    const {
        id,
        display,
        metaDataId,
        disabled,
        multiple
    } = props.ftOption;

    const {
        stateObj,
        metaDataObj,
        handleKeyValueChange,
        handleSubmitFilter,
        handleRemoveFilter
    } = props;
    const _metaDataId = metaDataId ? metaDataId : id;
    const options = metaDataObj[_metaDataId] || [];
    const isLoading = metaDataObj.isLoading ? true : false;
    return (
        <div
            key={id}
        >
            <ComboBox
                id={id}
                disabled={!!disabled}
                multiple={multiple}
                label={display}
                placeholder={'Please select...'}
                isLoading={isLoading}
                options={options}
                selected={stateObj && stateObj[id] && stateObj[id].length > 0 ? stateObj[id] : []}
                clearButton
                onChange={(v) => {
                    handleKeyValueChange(id, v);

                    if (!v || v.length === 0) {
                        // REMOVE
                        return handleRemoveFilter({
                            ...props.ftOption,
                            }
                        );
                    }
                    handleSubmitFilter({
                        ...props.ftOption,
                        value: v
                    }
                    )
                }}
            />
        </div>
    )
}


function createCheckbox(props) {
    const {
        id,
        display,
        operand
    } = props.ftOption;

    const {
        stateObj,
        handleSubmitFilter,
        handleRemoveFilter
    } = props;
    return (
        <div
            key={id}
        >
            <CheckBox
                id={`${id}-check-box`}
                label={display}
                checked={stateObj[id] || false}
                onChange={(value) => {
                    if (value) {
                        return handleSubmitFilter({
                            ...props.ftOption,
                            value: operand ? operand : value
                        });
                    }
                    handleRemoveFilter({id});
                }}
            />
        </div>

    );
}

function createRangeSlider(props) {
    const {
        id,
        display,
    } = props.ftOption;

    const min = props.ftOption.min && !Number.isNaN(props.ftOption.min) ? props.ftOption.min : 0;
    const max = props.ftOption.max && !Number.isNaN(props.ftOption.max) ? props.ftOption.max : 100;
    const step = props.ftOption.step || 1;

    const {
        stateObj,
        handleSubmitFilter,
    } = props;

    return (
        <div
            key={id}
        >
        <RangeSlider
            key={id}
            id={id}
            name={display}
            min={min}
            max={max}
            onChangeComplete={(value) => handleSubmitFilter({
                ...props.ftOption,
                value: value
            })}
            value={!stateObj[id] ? {min: 0, max: max} : stateObj[id]}
            step={step}
        />
        </div>
    );
}


function createDatePicker(props) {
    const {
        id,
        display,
    } = props.ftOption;

    const {
        stateObj,
        handleSubmitFilter,
    } = props;

    return (
        <div
        key={id}
        >
            <FormDatePicker
                id={id}
                label={display}
                value={stateObj[id]}
                format={'YYYY-MM-DD'}
                onChange={(dateObj) => {
                    if (dateObj instanceof Date) {
                        handleSubmitFilter({
                            ...props.ftOption,
                            value: moment(dateObj).format('YYYY-MM-DD')
                        });
                    }
                }}
            />
        </div>
    );
}

function createNumberInput(props) {
    const {
        id,
        display,
        placeholder,
    } = props.ftOption;

    const {
        stateObj,
        handleKeyValueChange,
        handleSubmitFilter
    } = props;

    const onSubmit = () => {
        if (stateObj[id]) {
            handleSubmitFilter({
                ...props.ftOption,
                value: stateObj[id]
            })
        }
    };
    return (
        <div
            key={id}
        >
            <FormNumberInput
                id={`${id}-number-input`}
                label={display}
                placeholder={placeholder}
                value={stateObj &&  stateObj[id] ? stateObj[id] : ''}
                onChange={(value) => handleKeyValueChange(id, value)}
                onBlur={() => onSubmit()}
                onKeyDown={(e) => {
                    if(e.key === 'Enter') {
                        onSubmit();
                    }
                }}
            />
        </div>

    );
}

export default FilterComponentBuilder;
