import styled from "styled-components";
import Panel from "./Panel";

const PanelTable = styled(Panel) `
  height: calc(100vh - 280px);

  .table-responsive {
    height: calc(100vh - 360px);
  }
`;

export default PanelTable;
