/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';
import SourcesContainer from './SourcesContainer';
import CreateSourceContainer from "./CreateSourceContainer";
import EditSourceContainer from './EditSourceContainer';

export default class SourcesRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/sources/create-source" exact component={CreateSourceContainer} />
                 <Route path="/admin/sources/:id/edit-source" exact component={EditSourceContainer} />                  
                <Route path="/admin/sources" component={SourcesContainer} /> 
            </Switch>
        );
    }
}
