/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {includes, map, xor, get, union} from 'lodash';
import Fuse from 'fuse.js';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import {Button, Col, Collapse, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import './ExploreMapFlyout.scss';
import {APP_COLORS} from '../../common/Constants';
import CheckBox from '../../common/components/CheckBox';
import ExploreMapFlyoutDetail from './components/flyout/ExploreMapFlyoutDetail';
import {LIST_STATUSES} from '../../common/metadata/ListStatuses';
import {LIST_TYPES} from '../../common/metadata/ListTypes';


import moment from "moment/moment";
import {getListsWithFilter} from '../../common/services/ListService';
import {getSuppliers} from '../../common/services/SupplierService';
import config from "../../config";
import * as cache from "../../common/Cache";
import styled from "styled-components";
import {TYPES as SourceTypes} from '../../admin/sources/SourceTypes';

export default function ExploreMapFlyout(props) {
    const [overlaySelections, setOverlaySelections] = useState([]);
    const [showFlyoutOptions, setShowFlyoutOptions] = useState(false);

    // This is temporary and not scalable
    const [showTCUProductMap, setShowTCUProductMap] = useState(true);

    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(false);

    const [supplierList, setSupplierList] = useState(null);

    const overlayListOptions = !lists || lists.length === 0 ? [] :
        map(lists, (list) => {
            const getConfig = (_list) => {
                if (_list.type === 'IMPORT_OVERLAY') {
                    if (get(_list, 'config.map.overlay', null)) {
                        return _list.config;
                    }

                    return (
                        {
                            map: {
                                overlay: {
                                    color: '#0171fe',
                                    img: 'map-marker-alt'
                                }
                            }
                        }
                    );
                } else {
                    return (
                        {
                            map: {
                                overlay: {
                                    color: `${APP_COLORS['tech-blue']}`,
                                    img: 'map-marker-alt'
                                }
                            }
                        }
                    )
                }
            };
            const _option = {
                id: list.id,
                type: list.type,
                value: list.id,
                key: list.name,
                display: list.name,
                updated: list.updated,
                created: list.created,
                config: getConfig(list)
            };
            return _option;
        });
    const [offset, setOffset] = useState(0);
    const resultPerPage = 6;
    const [term, setTerm] = useState('');
    const inputEl = useRef(null);

    const makeAndHandleRequest = async (term, offset = 0) => {
        const where = term ? {
                and: [
                    {status: LIST_STATUSES.READY.id},
                    {type: {
                            inq: [
                                LIST_TYPES.IMPORT_OVERLAY.id,
                            ]
                        }
                    },
                    {
                        or: [
                            {name: {
                                    ilike: `%${term}%`
                                }},
                            {description: {
                                    ilike: `%${term}%`
                                }
                            },
                        ]
                    }
                ]

            }
            :
            {
                and: [
                    {status: LIST_STATUSES.READY.id},
                    {type: {
                            inq: [
                                LIST_TYPES.IMPORT_OVERLAY.id,
                            ]
                        }
                    },
                ]
            };

        try {
            setLoading(true);
            const _suppliers = await _getSuppliers();
            const _list = await getListsWithFilter({filter: {where: where, skip: offset, order: ['created DESC']}});
            const unionList = union(_suppliers, _list);
            if (term) {
            const fuseOptions = {
                // isCaseSensitive: false,
                // includeScore: false,
                // shouldSort: true,
                // includeMatches: false,
                // findAllMatches: false,
                // minMatchCharLength: 1,
                // location: 0,
                // threshold: 0.6,
                // distance: 100,
                // useExtendedSearch: false,
                // ignoreLocation: false,
                // ignoreFieldNorm: false,
                // fieldNormWeight: 1,
                keys: [
                    "name",
                ]
            };

            const fuse = new Fuse(unionList, fuseOptions);
            const fusedList = fuse.search(term);
            setLists(map(fusedList, l => l.item));
            } else {
                setLists(unionList);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }

    };

    const handleInputChange = (value) => {
        setTerm(value);
    };

    const handlePaginate =  useCallback((_term, offset) => {
        return makeAndHandleRequest(_term, offset);
    }, [supplierList]);

    const handleSearch = useCallback((query) => {
        makeAndHandleRequest(query, 0);
    }, [supplierList]);

    const onChangeOverlaySelection = (e) => {
        // Diff with state,
        let _xor = xor(overlaySelections, e);
        const selectedOption = _xor[0];
        // Remove
        if (includes(overlaySelections, selectedOption)) {
            setOverlaySelections(e);
            props.handleOverlaySelection('REMOVE', selectedOption);
        } else {
            // Add
            setOverlaySelections(e);
            props.handleOverlaySelection('ADD', selectedOption);
        }
    };

    useEffect(() => {
        if (props.renderedOverlays.length > 0) {
            setOverlaySelections(props.renderedOverlays);
        }

        if (includes(props.renderedProducts, 'TCU')) {
            setShowTCUProductMap(true);
        }

        _getSuppliers();
    }, []);

        const _getSuppliers = async () => {
        if (supplierList) {
            return supplierList;
        }

            const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
            const _supplierListId = get(account, 'organization.config.suppliers', []);
            if (_supplierListId && _supplierListId.length > 0) {
                const filter = {
                filter: {
                    where: {
                        and: [
                            {
                        id: {
                            inq: _supplierListId
                                }
                            }
                        ]

                        }
                    }
                };
                const _supplierList = await getSuppliers(filter);
                const __supplierList = map(_supplierList, l => ({...l, type: SourceTypes.SUPPLIER.id}));
                setSupplierList(__supplierList);
            return __supplierList;
            }
         setSupplierList([]);
        return [];
        };

    const onChangeShowTCUProductMap = (e) => {
        setShowTCUProductMap(!showTCUProductMap);
        props.handleShowHideProductMap();
    };

    const showListOrSupplier = (type) => {
        if (type.includes('OVERLAY')) {
            return <ListText>(L)</ListText>
        } else {
            return <SupplierText>(S)</SupplierText>
        }
    };


    return (
        <div className='explore-map-flyout'>
            <div className='close-box' onClick={(e) => props.setShowFlyout(false)}>
                <FontAwesomeIcon
                    color={APP_COLORS['tech-blue']}
                    icon={faTimes}
                    style={{marginLeft: 5, marginTop: 7}}
                />
            </div>
            <div>
                <div className="flyout-header">
                    <Row>
                        <Col>
                            <div className="label-text">OVERLAYS</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="overlay-typeahead">
                                <AsyncTypeahead
                                    ref={inputEl}
                                    id={'explore-map-location-typeahead'}
                                    labelKey={'display'}
                                    maxResults={resultPerPage - 1}
                                    minLength={0}
                                    onInputChange={handleInputChange}
                                    onPaginate={() => {
                                        handlePaginate(term, (offset + (resultPerPage - 1)));
                                        setOffset((offset + (resultPerPage - 1)));
                                    }}
                                    onFocus={() => handleSearch('')}
                                    onSearch={handleSearch}
                                    paginate
                                    placeholder={'Choose a list to overlay'}
                                    renderMenuItemChildren={option => (
                                        <div key={option.id} style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span>{showListOrSupplier(option.type)}&nbsp;{option.display}</span>
                                            {option.type.includes('OVERLAY') &&
                                            <span style={{
                                                paddingRight: '10px',
                                                fontStyle: 'italic',
                                                color: APP_COLORS['gray-500']
                                            }}>
                                                ({moment(option.created).fromNow()})
                                            </span>
                                            }
                                        </div>

                                    )}
                                    useCache={false}
                                    options={overlayListOptions}
                                    isLoading={loading}
                                    onChange={(e) =>  {
                                        onChangeOverlaySelection(e)
                                    }
                                    }
                                    selected={overlaySelections}
                                    ignoreDiacritics
                                    multiple
                                    clearButton
                                    query={term}
                                    paginationText={'More Searches'}
                                >
                                </AsyncTypeahead>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="accordion-toggle-container">
                                <Button
                                    className="accordion-toggle-button"
                                    variant="link"
                                    size="sm"
                                    onClick={() => setShowFlyoutOptions(!showFlyoutOptions)}
                                >
                                    {`${showFlyoutOptions ? 'LESS' : 'MORE'}`}
                                </Button>
                            </div>
                            <Collapse in={showFlyoutOptions}>
                                    <div className="accordion-body-container">
                                        {/*<div className="label-text">ACTIONS</div>*/}
                                        <div className="label-text">PRODUCTS</div>
                                        <div style={{
                                            fontSize: '14px'
                                        }}>
                                            <CheckBox
                                                label="TCU - ToolBelt Contractor Universe"
                                                checked={showTCUProductMap}
                                                onChange={onChangeShowTCUProductMap}
                                            />
                                        </div>
                                    </div>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
                <ExploreMapFlyoutDetail
                    currentFilters={props.currentFilters}
                    exploreMode={props.exploreMode}
                    exploreMapMode={props.exploreMapMode}
                    exploreMapFlyoutDetailMode={props.exploreMapFlyoutDetailMode}
                    selectedPoint={props.selectedPoint}
                />
            </div>
        </div>
    );
}

ExploreMapFlyout.propTypes = {
    setShowFlyout: PropTypes.func,
    currentFilters: PropTypes.array,
    exploreMode: PropTypes.string,
    exploreMapMode: PropTypes.oneOf(['CITY', 'MSA', 'ADDRESS']),
    exploreMapFlyoutDetailMode: PropTypes.oneOf(['NONE', 'OVERLAY', 'CLUSTERED', 'UNCLUSTERED', 'PRODUCT_DATA_RECORD_POINT', 'PRODUCT_DATA_CLUSTERED']),
    handleOverlaySelection: PropTypes.func,
    renderedOverlays: PropTypes.array,
    handleShowHideProductMap: PropTypes.func,
    renderedProducts: PropTypes.array,
    selectedPoint: PropTypes.object,
};

const SupplierText = styled.span `
  color: ${APP_COLORS['green']};
  font-weight: 500;
`;

const ListText = styled.span `
  color: ${APP_COLORS['orange']};
  font-weight: 500;
`;
