import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    selectedRecordValidations: undefined,
    successMessage: undefined,
    recordValidations: [],
};

const hygieneEndpointMap = new Map([
    ['COMPANY_NAME', {validate: true, endpoint: `/managed-hygiene/business-name/normalize`, dtoDataName: `name`}],
    ['COMPANY_NAME_VERIFIED', {validate: false}],
    ['COMPANY_NAME_VERIFIED_REASON', {validate: false}],
    ['COMPANY_NAME_INVALIDATED_REASON', {validate: false}],
    ['COMPANY_URL', {validate: true, endpoint: `/managed-hygiene/url/normalize`, dtoDataName: `url`}],
    ['COMPANY_URL_VERIFIED_REASON', {validate: false}],
    ['COMPANY_URL_INVALIDATED_REASON', {validate: false}]
]);

export const getDenormCompanies = createAsyncThunk('entityVerifications/get-all', async (pageSelector, { getState, rejectWithValue }) => {
    try {
        const {pageNumber, pageSize} = pageSelector;
        const companiesResult = await Api.get(true,
            `${config.services.ATLAS.apiUrl}/managed-entity-verifications?pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return companiesResult;
    }
    catch (err){
        console.log(`Error in getDenormCompanies and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});

export const getDenormByCompanyId = createAsyncThunk('entityVerifications/get-by-company-id', async (id, { getState, rejectWithValue }) => {
    try {
        const companiesResult = await Api.get(true,
            `${config.services.ATLAS.apiUrl}/managed-entity-verifications/company/${id}`);
        return companiesResult;
    }
    catch (err){
        console.log(`Error in getDenormCompanyById and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});

export const getEntityVerificationsWithFilter = createAsyncThunk('entityVerifications/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-entity-verifications`;
    const entityVerifications = await Api.get(true, GET_URL, filter);
    return entityVerifications ;
});

export const denormCompanyUpdate = createAsyncThunk('entityVerifications/update-record-validation', async (cellUpdate, {rejectWithValue}) => {

    try {
            const POST_URL = `${config.services.ATLAS.apiUrl}/managed-entity-verifications`;
            const entityVerfications = await Api.post(true, POST_URL, {options: {}, data: cellUpdate},);
            return entityVerfications;
       // }
    }
    catch (err){
        console.log(`Error in denormCompanyEdit and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});

export const getEntityVerificationsCount = createAsyncThunk('entityVerifications/getCount', async (arg, { getState, requestId }) => {
   return 25;
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const isGuid = regexExp.test(searchCriteria);
    const query = isGuid ? {
        filter: {
            where: {
                or: [{
                    id: {
                        eq: `${searchCriteria ? searchCriteria : ''}`
                    }
                },
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: 5,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            where: {
                and: [
                    {
                        or: [
                            {
                                key: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

const entityVerificationsSlice = createSlice({
    name: 'entityVerificationSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedRecordValidations.splice(state.selectedRecordValidations.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedRecordValidations.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateRecordValidationsState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getDenormCompanies.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getDenormCompanies.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.recordValidations = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getDenormCompanies.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getDenormByCompanyId.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = action.payload;
        },
        [getDenormByCompanyId.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getDenormByCompanyId.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.recordValidations = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [denormCompanyUpdate.pending]: (state, action) => {
            state.loading = true;
        },
        [denormCompanyUpdate.fulfilled]: (state, action) => {
            state.loading = false;
            if(action.payload) {
                state.recordValidations[action.meta.arg.rowIndex] = action.payload;
            }
        },
        [denormCompanyUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = action.payload;
        },
        [getEntityVerificationsCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getEntityVerificationsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getEntityVerificationsCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getEntityVerificationsWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getEntityVerificationsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTypes = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getEntityVerificationsWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = entityVerificationsSlice.actions;
export default entityVerificationsSlice.reducer;
