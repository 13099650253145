import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedCompany: null
};

export const getCompanyById = createAsyncThunk('companies/getCompanyById', async (id) => {
    const GET_COMPANY_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-companies/${id}`;
    const company = await Api.get(true, GET_COMPANY_BY_ID_URL);
    return company;
});

const managedCompanySlice = createSlice({
    name: 'managedCompanySlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedCompany = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedCompany = action.payload;
            }
        },
        [getCompanyById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
    }
});


export const { clearState } = managedCompanySlice.actions;
export default managedCompanySlice.reducer;
