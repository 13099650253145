import _ from "lodash";

export const INPUT_STATUSES = {
    CREATED: {
        id: 'CREATED',
        display: 'Created',
        description: null
    },
    PARSING: {
        id: 'PARSING',
        display: 'Parsing',
        description: null
    },
    PARSED: {
        id: 'PARSED',
        display: 'Parsed',
        description: null
    },
    NORMALIZING: {
        id: 'NORMALIZING',
        display: 'Normalizing',
        description: null
    },
    NORMALIZED: {
        id: 'NORMALIZED',
        display: 'Normalized',
        description: null
    },
    RESOLVING: {
        id: 'RESOLVING',
        display: 'Resolving',
        description: null
    },
    RESOLVED: {
        id: 'RESOLVED',
        display: 'Resolved',
        description: null
    },
    INVALIDATING: {
        id: 'INVALIDATING',
        display: 'Invalidating',
        description: null
    },
    INVALIDATED: {
        id: 'INVALIDATED',
        display: 'Invalidated',
        description: null
    },
    INCORPORATING: {
        id: 'INCORPORATING',
        display: 'Incorporating',
        description: null
    },
    FULLY_INCORPORATED: {
        id: 'FULLY_INCORPORATED',
        display: 'Fully Incorporated',
        description: null
    },
    INCORPORATED: {
        id: 'INCORPORATED',
        display: 'Incorporated',
        description: null
    },
    PARTIALLY_INCORPORATED: {
        id: 'PARTIALLY_INCORPORATED',
        display: 'Partially Incorporated',
        description: null
    },
    MINIMALLY_INCORPORATED: {
        id: 'MINIMALLY_INCORPORATED',
        display: 'Minimally Incorporated',
        description: null
    },
    NOT_INCORPORATED: {
        id: 'NOT_INCORPORATED',
        display: 'Not Incorporated',
        description: null
    },
    ERROR: {
        id: 'ERROR',
        display: 'Error',
        description: null
    },
    // TODO: DEPRECATED
    PROCESSING: {
        id: 'PROCESSING',
        display: 'Processing',
        description: null
    },
    PROCESSED_WITH_ATTRIBUTION: {
        id: 'PROCESSED_WITH_ATTRIBUTION',
        display: 'Processed With Attribution',
        description: null
    },
    PROCESSED_WITHOUT_ATTRIBUTION: {
        id: 'PROCESSED_WITHOUT_ATTRIBUTION',
        display: 'Processed Without Attribution',
        description: null
    },
    MIGRATED_WITH_ATTRIBUTION: {
        id: 'MIGRATED_WITH_ATTRIBUTION',
        display: 'Migrated and attributed',
        description: null
    },
    MIGRATED_WITHOUT_ATTRIBUTION: {
        id: 'MIGRATED_WITHOUT_ATTRIBUTION',
        display: 'Migrated and not attributed',
        description: null
    },
    ABORTED: {
        id: 'ABORTED',
        display: 'Aborted',
        description: 'Manual error state, input was processing but manually aborted for some reason'
    },
};
export function getInputStatusById(id) {
    if (id) {
        return _.get(INPUT_STATUSES, id);
    }
}
