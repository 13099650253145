/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';

import {DateTime} from 'luxon';
import {findIndex, assign} from 'lodash';
import {useDispatch, useSelector} from "react-redux";

import PropTypes from 'prop-types';

import {getSources, getSourcesCount, setCurrentPage, setOrder, setSelectedId} from "./sourcesSlice";

import {getTypeById} from './SourceTypes';

import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faExternalLink} from '@fortawesome/pro-solid-svg-icons';

import TableWithPaging from '../../common/components/TableWithPaging';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";

import DescriptionColumn from "../../common/components/layout/DescriptionColumn";
import CheckBox from "../../common/components/CheckBox";

    const SourcesTable = (props) => {

    const dispatch = useDispatch();
    const sourcesState = useSelector((state) => state.sources);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getSourcesCount());
        dispatch(getSources());
    }, [props.lastReload, dispatch]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // let image = tbdBrainLightningIcon;
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value => dispatch(setSelectedId({
                                id: row.id,
                                isChecked: value,
                                statusId: row.statusId
                            }))}
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'id',
                property: 'id',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Type',
                property: 'type',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return getTypeById(row.type).display;
                }
            },
            {
                id: 4,
                name: 'Name',
                property: 'name',
                sortable: true,
                formatted: false,
            },
            {
                id: 5,
                name: 'Label',
                property: 'label',
                sortable: true,
                formatted: false,
            },
            {
                id: 6,
                name: 'Description',
                property: 'description',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return (<DescriptionColumn> {row.description} </DescriptionColumn>);
                }
            },
            {
                id: 7,
                name: 'URL',
                property: 'url',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.url) {
                        return (
                            <a target='_blank'
                               href={`${row.url}`}
                               rel='noopener noreferrer'
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={faExternalLink}
                                    style={{marginRight: '5px', fontSize: '12px'}}
                                />
                                {`${row.url.substring(0, 20)}...`}
                            </a>
                        )
                    }
                }
            },
            {
                id: 8,
                name: 'Region',
                property: 'region',
                sortable: true,
                formatted: false,
            },
            // {
            //     id: 9,
            //     name: 'County',
            //     property: 'county',
            //     sortable: true,
            //     formatted: false,
            // },
            {
                id: 9,
                name: 'City',
                property: 'city',
                sortable: true,
                formatted: false,
            },
            {
                id: 10,
                name: 'Authoritative',
                property: 'authoritative',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.authoritative) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 11,
                name: 'Public',
                property: 'public',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.public) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 12,
                name: 'Test',
                property: 'isTest',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.isTest) {
                        return <span><FontAwesomeIcon color={APP_COLORS.green} icon={faCheck} /></span>
                    }
                    return <span></span>
                }
            },
            {
                id: 13,
                name: 'Products',
                property: 'products',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return row.products ? row.products.join(', ') : null;
                }
            },
            {
                id: 14,
                name: 'Indicators',
                property: 'indicators',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    return row.indicators ? row.indicators.join(', ') : null;
                }
            },
            {
                id: 15,
                name: 'Created ',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getSources());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'source-table-with-paging'}
                headers={headers}
                rows={sourcesState.sources}
                countOfResults={sourcesState.totalRecords}
                resultsPerPage={sourcesState.pageSize}
                currentPage={sourcesState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
}

SourcesTable.propTypes = {
    sources: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

SourcesTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    sources: []
};
export default SourcesTable;
