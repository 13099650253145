/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import React from "react";
import styled from "styled-components";

import {APP_COLORS} from "../../Constants";
import {Col, Form, Row} from "react-bootstrap";

const HeaderPanel = styled.div `
  background-color: ${APP_COLORS['gray-100']};
  padding: 0.5rem;

  border-width: 0.0125rem;
  border-style: solid;
  border-color: #e7eaf3;
`;

const HeaderDivider = styled.div `
    height: 1px;
    border-top: 1px solid #2d5d7e;
    margin-left: 10px;
    margin-right: 10px;
`;

const HeaderText = styled.div `
  margin: 8px 0 8px 15px;
  font-size: 30px;
`;

const HeaderSubHeaderText = styled.div `
  margin: 0 0 8px 15px;
  font-size: 13px;
`;

const SubHeaderText = styled.div `
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-style: italic;
`;

const SearchBarContainer = styled.div `
  margin: 8px 0 8px 15px;
`;

const ToolActionContainer = styled.div `
  margin: 8px 15px 8px 15px;
  
  > div {
    display: grid;
    grid-template-columns: 125px 125px 125px;
    grid-gap: 10px;
  }
`;

const ToolActionRow = styled.div `
  display: grid;
  grid-template-columns: ${props => props.customTemplateColumns};
  grid-gap: 10px;
`;

const PanelHeader = (props) => {
    const {
        header,
        headerSubHeader,
        subHeader,
        toolAction,
        searchBar,
        customTemplateColumns = '1fr 425px'
    } = props;

    return (
        <HeaderPanel>
            {toolAction ?
                <ToolActionRow customTemplateColumns={customTemplateColumns}>
                    <div>
                        <HeaderText>{header}</HeaderText>
                        <HeaderSubHeaderText>{headerSubHeader}</HeaderSubHeaderText>
                    </div>
                    <ToolActionContainer>{toolAction}</ToolActionContainer>
                </ToolActionRow>
                :
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <HeaderText>{header}</HeaderText>
                    </Col>
                </Row>
            }
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <HeaderDivider />
                </Col>
            </Row>
            <Row>
                {searchBar ?
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <SearchBarContainer>{searchBar}</SearchBarContainer>
                    </Col>
                    :
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <SubHeaderText>{subHeader}</SubHeaderText>
                    </Col>
                }
            </Row>
        </HeaderPanel>
    );
}

export default PanelHeader;
