/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import EntityVerifications from "./EntityVerifications"; // eslint-disable-line no-unused-vars

const EntityVerificationsContainer = (props) => {
    return(
       <EntityVerifications/>
    )
};

export default EntityVerificationsContainer;
