/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";

const Panel = styled.div `
  box-shadow: rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
  padding: 1rem;
  margin-top: 1.5rem;
`;

export default Panel;
