/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useRef, useState } from 'react';
import {useSelector} from 'react-redux';

import mapboxgl from '!mapbox-gl'; //eslint-disable-line
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import {APP_COLORS} from '../../common/Constants';
import './ContractorDirectorySearchResultsMap.scss';

mapboxgl.accessToken = 'pk.eyJ1Ijoib3BzLWF0LWdldHRvb2xiZWx0LWRvdC1jb20iLCJhIjoiY2tqYWVlazdqN2FoajJ2cGRyaDM0b2w5dSJ9.0ghFxBToWVY2tiKhPDrs6w';

const initialCoordinates = [-98.2883, 41.1289];
const initialZoom = 9;

export default function ExploreMap(props) {

    // Redux State
    const contractorDirectoryState = useSelector(state => state.contractorDirectory);

    const mapContainer = useRef(null);
    const map = useRef(null);
    // For Setting Current Map lat, lng and zoom
    const [lng, setLng] = useState(initialCoordinates[0]);
    const [lat, setLat] = useState(initialCoordinates[1]);
    const [zoom, setZoom] = useState(initialZoom);

    const mapData = contractorDirectoryState.searchResultsMap.data;

    // Helpers
    const handleDataPointMouseEnter = () => {
        // map.getCanvas().style.cursor = 'pointer';
        map.current.getCanvas().style.cursor = 'pointer';
    }

    const handleDataPointMouseLeave = () => {
        // map.getCanvas().style.cursor = '';
        map.current.getCanvas().style.cursor = '';
    }

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        if (contractorDirectoryState.searchLocationData) {
            setLat(contractorDirectoryState.searchLocationData.latitude);
            setLng(contractorDirectoryState.searchLocationData.longitude);
            map.current.setCenter([contractorDirectoryState.searchLocationData.longitude, contractorDirectoryState.searchLocationData.latitude]);
        }
    }, [contractorDirectoryState.searchLocationData, map.current]);


    // Initial Map Load
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        // Controls
        // map.current.addControl(new MapboxGeocoder({
        //     accessToken: mapboxgl.accessToken,
        //     mapboxgl: mapboxgl,
        //     placeholder: 'City, State, Zip ...',
        //     // set marker to false in order to not drop one
        //     marker: false,
        //     countries: 'us',
        // }), 'top-left');

        const nav = new mapboxgl.NavigationControl({visualizePitch: true});
        map.current.addControl(nav, 'top-left');

        map.current.on('load', () => {
            if (1 === 1) { // TODO Conditional Data Check when Available
                buildDefaultMap(map);
            }
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    useEffect(() => {
        // This effect runs post-component mounting, and should only run during updates
        if (!map.current) return; // wait for map to initialize
        if (!map.current.getSource('data')) return; // wait for data to initialize
        map.current.getSource('data').setData(mapData);
    }, [mapData]); // Important! We only run this effect when the map changes!

    /*


    MAP


     */

    const buildDefaultMap = (map) => {
        console.log('buildDefaultMap');

        map.current.addSource('data', {
            type: 'geojson',
            data: mapData,
        });

        // map.current.addSource('data', {
        //     type: 'vector',
        //     url: 'mapbox://mapbox.2opop9hr'
        // });
        map.current.addLayer({
            'id': 'search-results',
            'type': 'circle',
            'source': 'data',
            'layout': {
                // Make the layer visible by default.
                'visibility': 'visible'
            },
            'paint': {
                // 'circle-radius': 8,
                'circle-radius': [
                    'interpolate',
                    ['exponential', 2],
                    ['zoom'],
                    6, 5,
                    10, 8,
                    15, 10,
                    20, 90
                ],
                'circle-color': APP_COLORS.orange,
        //         // Based on the confidence score of the record make higher
        //         // confidence records standout
        //         'circle-color': [
        //             'case',
        //             ['>=', ['get', 'company_score'], 0.90], 'rgba(238, 90, 56, 1)',
        //             ['>=', ['get', 'company_score'], 0.80], 'rgba(238, 90, 56, .7)',
        //             ['>=', ['get', 'company_score'], 0.70], 'rgba(238, 90, 56, .3)',
        //             ['>=', ['get', 'company_score'], 0.60], 'rgba(238, 90, 56, .2)',
        //             /* other */ 'rgba(238, 90, 56, .1)'
        //         ]
            },
            // Probably something to do with the data
            // 'source-layer': 'museum-cusco'
        });

        map.current.on('click', 'search-results', (e) => console.log('clicked', e.features));
        map.current.on('mouseenter', 'search-results', handleDataPointMouseEnter);
        map.current.on('mouseleave', 'search-results', handleDataPointMouseLeave);

    };

    return (
        <div>
            <div ref={mapContainer} className="search-results-map-container" />
        </div>
    );
}
