import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import { isGuid } from "../../common/utils/Utils";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedMatchTypes: [],
    currentPage: 1,
    pageSize: 50,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedListMatchLogs: [],
    managedListMatch: null,
};

export const getManagedListMatchLogs = createAsyncThunk('managedListMatchLogs/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-match-logs`;
    const managedListMatchLogs = await Api.get(true, GET_URL, buildQuery(getState().managedListMatchLogs.currentPage, getState().managedListMatchLogs.pageSize,
        getState().managedListMatchLogs.order.by, getState().managedListMatchLogs.order.direction, getState().managedListMatchLogs.searchCriteria));
    return managedListMatchLogs;
});

export const getManagedListMatchLogsWithFilter = createAsyncThunk('managedListMatchLogs/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-match-logs`;
    const managedListMatchLogs = await Api.get(true, GET_URL, filter);
    return managedListMatchLogs;
});

export const getManagedListMatchLogsCount = createAsyncThunk('managedListMatchLogs/getCount', async (arg, {getState, requestId}) => {
    try {
        const searchCriteria = getState().managedListMatchLogs.searchCriteria ? getState().managedListMatchLogs.searchCriteria : '';
        const where = isGuid(searchCriteria) ? {
            where: {
                or: [
                        {
                            id: {
                                eq: searchCriteria
                            }
                        },
                        {
                            organizationId: {
                                eq: searchCriteria
                            }
                        },
                        {
                            listId: {
                                eq: searchCriteria
                            }
                        },
                        {
                            listMatchId: {
                                eq: searchCriteria
                            }
                        },
                    {
                        level: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        message: {
                            ilike: `%${searchCriteria}%`
                        }
                    }
                ]
            }
        } : {
            where: {
                or: [
                    {
                        level: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        message: {
                            ilike: `%${searchCriteria}%`
                        }
                    }
                ]
            }
        }
        const COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-list-match-logs/count`;
        // const managedListMatchLogsCount = await Api.get(true, COUNT_URL, where);
        const managedListMatchLogsCount = await Api.get(true, COUNT_URL, {filter: where});
        return managedListMatchLogsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = isGuid(searchCriteria) ?
        {
        filter: {
            where: {
                and: [
                    {
                        or: [
                                {
                                    id: {
                                        eq: searchCriteria
                                    }
                                },
                                {
                                    organizationId: {
                                        eq: searchCriteria
                                    }
                                },
                                {
                                    listId: {
                                        eq: searchCriteria
                                    }
                                },
                                {
                                    listMatchId: {
                                        eq: `${searchCriteria}`
                                    }
                                },
                            {
                                level: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                message: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
            filter: {
                where: {
                    and: [
                        {
                            or: [
                                {
                                    level: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                },
                                {
                                    message: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                }
                            ]
                        }
                    ]
                },
                order: [`${orderBy} ${orderDirection}`],
                limit: pageSize,
                skip: (currentPage - 1) * pageSize
            }
        };
    return query;
};

const managedListMatchLogsSlice = createSlice({
    name: 'managedListMatchLogsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedMatchTypes.splice(state.selectedMatchTypes.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedMatchTypes.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedListMatchState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedListMatchLogs.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogs.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListMatchLogs = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogs.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedListMatchLogs = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedListMatchLogsWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria
} = managedListMatchLogsSlice.actions;
export default managedListMatchLogsSlice.reducer;
