/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Image, Pagination} from 'react-bootstrap';

import CompanyCard from "./CompanyCard";
import ContactCard from "./ContactCard";
import {useSelector} from "react-redux";

import './ExploreCards.scss';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import config from "../../../../config";
import brainLightning from "../../../../common/assets/img/tbd_icon_ai-brain-lightning.png";
import Button from "react-bootstrap/Button";
import SpinnerOverlay from "../../../../common/components/SpinnerOverlay";

const CardListContainer = styled.div `
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 10px;
  padding-right: 5px;
  padding-left: 1px;
  // width: 100%
  height: calc(100vh - 255px);
  transition: width 300ms ease-in-out;
  overflow-y: auto;
  overflow-x: auto;
  width: ${props => props.removeWidth ? 'calc(100vw - 355px)' : 'calc(100vw - 40px)'};
 `;

export default function ExploreCards(props) {
    const {
        loading,
        mode,
        handleSearch,
        expanded
    } = props;

    const companyDataState = useSelector(state => state.company);
    const contactDataState = useSelector(state => state.contact);
    const list = mode === 'COMPANY' ?  companyDataState.companies : contactDataState.contacts;
    const pages = Math.ceil(list.length / config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE);

    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (list.length > 0) {
            const indexStart = 0;
            const indexEnd = config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE - 1;
            setSelectedList(list.slice(indexStart, indexEnd + 1));
            setCurrentPage(1);
            return;
        }
        setSelectedList([]);
        setCurrentPage(1);
    }, [list]); // Only re-run the effect if count changes

    const handlePrevPage = () => {
        if (currentPage === 1) return;
        const _prevPage = currentPage - 1
        const indexStart = (_prevPage * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE;
        const indexEnd = (_prevPage * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - 1;
        setSelectedList(list.slice(indexStart, indexEnd + 1));
        setCurrentPage(_prevPage);
    }

    const handleSelectPage = (page) => {
        const indexStart = (page * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE;
        const indexEnd = (page * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - 1;
        setSelectedList(list.slice(indexStart, indexEnd + 1));
        setCurrentPage(page);
    }

    const handleNextPage = () => {
        if (currentPage === pages) return;
        const _nextPage = currentPage + 1
        const indexStart = (_nextPage * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE;
        const indexEnd = (_nextPage * config.constants.EXPLORE_LIST_VIEW_ITEMS_PER_PAGE) - 1;
        setSelectedList(list.slice(indexStart, indexEnd + 1));
        setCurrentPage(_nextPage);
    }

    let selectedListItems = [];
    if (mode === 'COMPANY') {
        selectedListItems = selectedList.map(l => {
            return ({
                companyId: l['CompanyContractorUniverse.company_id'],
                companyIdentity: l['CompanyContractorUniverse.company_identity'],
                companyScore: l['CompanyContractorUniverse.company_score'],
                companyName: l['CompanyContractorUniverse.company_name'],
                companyURL: l['CompanyContractorUniverse.company_url'],
                companyURLStatus: l['CompanyContractorUniverse.company_url_status'],
                companyAddress: `${l['CompanyContractorUniverse.company_address_delivery_line_1']} ${l['CompanyContractorUniverse.company_address_city']}, ${l['CompanyContractorUniverse.company_address_region']} ${l['CompanyContractorUniverse.company_address_postal_code']}`,
                companyPhone: l['CompanyContractorUniverse.company_phone'],
                companyEmail: l['CompanyContractorUniverse.company_email'],
                companyEmailStatus: l['CompanyContractorUniverse.company_email_status'],
                companyClassTrades: l['CompanyContractorUniverse.company_class_trades'],
                companyClassNAICSDescriptions: l['CompanyContractorUniverse.company_class_naics_titles_formatted'],
                companyIndResidential: l['CompanyContractorUniverse.company_ind_residential'],
                companyIndCommercial: l['CompanyContractorUniverse.company_ind_commercial'],
                companyIndIndustrial: l['CompanyContractorUniverse.company_ind_industrial'],
                companyIndExterior: l['CompanyContractorUniverse.company_ind_exterior'],
                companyIndInterior: l['CompanyContractorUniverse.company_ind_interior'],
                companyIndLicensed: l['CompanyContractorUniverse.company_ind_licensed'],
                companyIndBonded: l['CompanyContractorUniverse.company_ind_bonded'],
                companyIndInsured: l['CompanyContractorUniverse.company_ind_insured'],
                companyProfileCnt: l['CompanyContractorUniverse.company_profile_cnt'],
                companyProfileManufacturerCnt: l['CompanyContractorUniverse.company_profile_manufacturer_profile_cnt'],
                companyProfileAssociationCnt: l['CompanyContractorUniverse.company_profile_association_profile_cnt'],
                companyLicenseCnt: l['CompanyContractorUniverse.company_license_cnt'],
                companyPolicyCnt: l['CompanyContractorUniverse.company_policy_cnt'],
                companyPermitCnt: l['CompanyContractorUniverse.company_permit_cnt'],
                companyRegLocalRegistrationStatus: l['CompanyContractorUniverse.company_registration_local_registration_status_mapped'],
                companyStatContactCnt: l['CompanyContractorUniverse.company_contact_cnt'],
                companySourceCnt: l['CompanyContractorUniverse.company_source_cnt'],
                contactId: l['CompanyContractorUniverse.contact_id'],
                contactIdentity: l['CompanyContractorUniverse.contact_identity'],
                contactScore: l['CompanyContractorUniverse.contact_score'],
                contactName: `${l['CompanyContractorUniverse.contact_name_given_name']} ${l['CompanyContractorUniverse.contact_name_surname']}`,
                contactTitle: l['CompanyContractorUniverse.contact_title'],
                contactPhone: l['CompanyContractorUniverse.contact_phone'],
                contactEmail: l['CompanyContractorUniverse.contact_email'],
                contactEmailStatus: l['CompanyContractorUniverse.contact_email_status'],
            })
        });
    } else {
        selectedListItems = selectedList.map(l => {
            return ({
                companyId: l['ContactContractorUniverse.company_id'],
                companyIdentity: l['ContactContractorUniverse.company_identity'],
                companyScore: l['ContactContractorUniverse.company_score'],
                companyName: l['ContactContractorUniverse.company_name'],
                companyURL: l['ContactContractorUniverse.company_url'],
                companyURLStatus: l['ContactContractorUniverse.company_url_status'],
                companyAddress: `${l['ContactContractorUniverse.company_address_delivery_line_1']} ${l['ContactContractorUniverse.company_address_city']}, ${l['ContactContractorUniverse.company_address_region']} ${l['ContactContractorUniverse.company_address_postal_code']}`,
                companyPhone: l['ContactContractorUniverse.company_phone'],
                companyEmail: l['ContactContractorUniverse.company_email'],
                companyEmailStatus: l['ContactContractorUniverse.company_email_status'],
                companyClassTrades: l['ContactContractorUniverse.company_class_trades'],
                companyClassNAICSDescriptions: l['ContactContractorUniverse.company_class_naics_titles_formatted'],
                companyIndResidential: l['ContactContractorUniverse.company_ind_residential'],
                companyIndCommercial: l['ContactContractorUniverse.company_ind_commercial'],
                companyIndIndustrial: l['ContactContractorUniverse.company_ind_industrial'],
                companyIndExterior: l['ContactContractorUniverse.company_ind_exterior'],
                companyIndInterior: l['ContactContractorUniverse.company_ind_interior'],
                companyIndLicensed: l['ContactContractorUniverse.company_ind_licensed'],
                companyIndBonded: l['ContactContractorUniverse.company_ind_bonded'],
                companyIndInsured: l['ContactContractorUniverse.company_ind_insured'],
                companyProfileCnt: l['ContactContractorUniverse.company_profile_cnt'],
                companyProfileManufacturerCnt: l['ContactContractorUniverse.company_profile_manufacturer_profile_cnt'],
                companyProfileAssociationCnt: l['ContactContractorUniverse.company_profile_association_profile_cnt'],
                companyLicenseCnt: l['ContactContractorUniverse.company_license_cnt'],
                companyPolicyCnt: l['ContactContractorUniverse.company_policy_cnt'],
                companyPermitCnt: l['ContactContractorUniverse.company_permit_cnt'],
                companyRegLocalRegistrationStatus: l['ContactContractorUniverse.company_registration_local_registration_status_mapped'],
                companyStatContactCnt: l['ContactContractorUniverse.company_contact_cnt'],
                companySourceCnt: l['ContactContractorUniverse.company_source_cnt'],
                contactId: l['ContactContractorUniverse.contact_id'],
                contactIdentity: l['ContactContractorUniverse.contact_identity'],
                contactScore: l['ContactContractorUniverse.contact_score'],
                contactName: `${l['ContactContractorUniverse.contact_name_given_name']} ${l['ContactContractorUniverse.contact_name_surname']}`,
                contactTitle: l['ContactContractorUniverse.contact_title'],
                contactPhone: l['ContactContractorUniverse.contact_phone'],
                contactEmail: l['ContactContractorUniverse.contact_email'],
                contactEmailStatus: l['ContactContractorUniverse.contact_email_status'],
            });
        });
    }

    return (
        <>
            {list.length > 0 && (
                <Row className="mt-3">
                    <Col xl={12} lg={12} md={12} sm={12}>
                        {(loading || companyDataState.loading || contactDataState.loading) && (
                            <SpinnerOverlay customHeight={'calc(100vh - 255px)'} customWidth={'99%'} />
                        )}
                        <CardListContainer removeWidth={expanded}>
                            {selectedListItems.map((l, i) => {
                                if (mode === 'COMPANY') {
                                    return (
                                        <CompanyCard key={i} mode={mode} item={l} />
                                    )
                                } else {
                                    return (
                                        <ContactCard key={i} mode={mode} item={l} />
                                    )
                                }
                            })}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {renderPagination({
                                    list,
                                    pages,
                                    currentPage,
                                    handlePrevPage,
                                    handleSelectPage,
                                    handleNextPage,
                                })}
                            </div>
                        </CardListContainer>
                    </Col>
                </Row>
            )}

            {list.length === 0 && (
                <Row className="mt-3">
                    <Col xl={12} lg={12} md={12} sm={12} className="text-center text-muted">
                        {(loading || companyDataState.loading || contactDataState.loading) && (
                            <SpinnerOverlay customHeight={'calc(100vh - 255px)'} customWidth={'99%'} />
                        )}
                        <Image
                            src={brainLightning}
                            rounded={true}
                            width={'300px'}
                        /><br/>
                        <p className="display-5 home font-weight-bold">Looks like you haven't selected anything.</p>
                        <p className="display-5 home font-weight-light">Add some filters and hit Apply</p>
                        <p>
                            <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => handleSearch()}
                        >
                            APPLY FILTER
                        </Button></p>
                    </Col>
                </Row>
            )}
        </>
    )
}


const renderPagination = (props) => {
    const {
        pages,
        currentPage,
        handlePrevPage,
        handleSelectPage,
        handleNextPage
    } = props;

    const items = [];
    for (let i = 1; i <= pages; i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => handleSelectPage(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return (
        <Pagination>
            <Pagination.Prev
                onClick={() => handlePrevPage()}
            />
            {items}
            <Pagination.Next
                onClick={() => handleNextPage()}
            />
        </Pagination>
    );
};
