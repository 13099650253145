/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import {
    Form,
    FormGroup,
    FormLabel,
    Row,
    Col,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBuilding, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { APP_COLORS } from "../../../common/Constants";
import RadioGroup from "../../../common/components/RadioGroup";
import {useDispatch, useSelector} from "react-redux";
import {updateListType} from "./uploadSlice";

function ListTypeSelection(props) {
    const dispatch = useDispatch();
    const uploadMetaState = useSelector(state => state.upload);
    const handleSelectListType = function(listTypeId) {
        dispatch(updateListType(listTypeId));
    }

    const listTypes = [
        { value: "IMPORT_COMPANY", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faBuilding}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Companies</span>
                </div>, disabled: uploadMetaState.importRestricted
        },
        { value: "IMPORT_CONTACT", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faUser}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Contacts</span>
                </div>, disabled: uploadMetaState.importRestricted
        },
        { value: "IMPORT_OVERLAY", label:
                <div>
                    <FontAwesomeIcon
                        size="1x"
                        color={APP_COLORS.primary}
                        icon={faMapMarkerAlt}
                    />
                    <span className="medium" style={{color: APP_COLORS.primary}}> Overlays</span>
                </div>
        },
    ];

    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel className="listTypeLabel">
                            What kind of List are you importing
                        </FormLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RadioGroup
                            id={'ListTypeSelection'}
                            name={'ListTypeSelection'}
                            value={uploadMetaState.importRestricted ? 'IMPORT_OVERLAY' : uploadMetaState.listType}
                            items={listTypes}
                            onChange={ selectedValue => handleSelectListType(selectedValue) }
                            showLockIcon
                        />
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    );
}

export default ListTypeSelection;
