import React, {useState, useCallback, useRef } from 'react';

import {
    FormLabel,
} from 'react-bootstrap';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const  ListFilter = (props) => {
    const  {
        id,
        label,
        multiple,
        selected,
        onChange,
        pageSize,
        placeholder,
        renderMenuItemChildren,
        listOptions,
        onSearch,
        disabled,
        paginationText = 'More Searches'
    } = props;

    const [offset, setOffset] = useState(0);
    const [term, setTerm] = useState('');
    const inputEl = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (value) => {
        setTerm(value);
    };

    const handlePaginate =  useCallback((_term, offset) => {
        setLoading(true);
        return onSearch(_term, offset)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    },[]);

    const handleSearch = useCallback((query) => {
        setLoading(true);
        return onSearch(query, 0)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, []);


    return (
        <div>
            <FormLabel className={"formTextLabel"}>
                {label}
            </FormLabel>
            <AsyncTypeahead
                ref={inputEl}
                id={id}
                labelKey={'display'}
                maxResults={pageSize - 1}
                minLength={0}
                disabled={disabled}
                onInputChange={handleInputChange}
                onPaginate={() => {
                    handlePaginate(term, (offset + (pageSize - 1)));
                    setOffset((offset + (pageSize - 1)));
                }}
                onFocus={() => handleSearch('')}
                onSearch={(query) => handleSearch(query)}
                paginate
                placeholder={placeholder}
                renderMenuItemChildren={renderMenuItemChildren}
                useCache={false}
                options={listOptions}
                isLoading={loading}
                onChange={(e) =>  {
                    onChange(e)
                }
                }
                selected={selected}
                ignoreDiacritics
                multiple={multiple}
                query={term}
                paginationText={paginationText}
            >
            </AsyncTypeahead>
        </div>
    );
};

export default ListFilter;
