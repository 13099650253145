/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormLabel,
    FormControl,
} from 'react-bootstrap';
import FormRequiredText from "./form/FormRequiredText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../Constants";
import {faLock} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const DivLockRelative = styled.div `
  position: relative;
`;

const DivLockAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -29px;
`;

const ClearRelative = styled.div `
  position: relative;
`;

const ClearAbsolute = styled.div `
  position: absolute;
  
  right: 10px;
  top: -32px;
  
  cursor: pointer;
  
  &:hover {
    color: ${APP_COLORS['red']} !important;
  }
`;

const getRegExp = (type) => {
    let regx = null;
    switch (type) {
      case 'EMAIL':
        regx =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        break;
      case 'URL':
        regx =             
         /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
         break;
      case 'PHONE':
        regx =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        break;
      case 'TITLE':
          regx =         
         /[A-Za-z]/ig;
        break;
      default:
        break;
    }
    return regx;
  };
  const validationHandler = (e, props) => {    
    if (!props.onValidateFunc) return;  
    const { value, name } = e.target;
    let msg = null;
  
    if (!value && props.isReq) {              
      msg = `Please enter ${props.title}.`;
    } else if (value && props.reqType && !getRegExp(props.reqType).test(value)) {
      msg = `Please enter valid ${props.title}.`;
    } else if (props.min && value.length < props.min){
      msg = `${props.title} must be at least ${props.min} characters long.`;
    }
    else if (props.max && value.length > props.max) {
      msg = `${props.title} is more than ${props.max} characters long.`;
    }
  
    props.onValidateFunc(msg, name);
  };
  
const FormTextInput = (props) => {
    const {
        clearable,
        clearableHandle
    } = props;

    return (
        <FormGroup
            style={props.style}
            controlId={props.controlId}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
        >
            {props.label &&
                <FormLabel className={"formTextLabel"}>
                    {props.label}
                    {props.required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            }

            <FormControl
                id={props.id}
                name={props.name}                
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                disabled={props.disabled}
                placeholder={props.placeholder}
                type={"text"}
                isInvalid={props.isInvalid}
                onBlur={(e) => validationHandler(e, props)}
                reqType={props.reqType}                
            />
            {props.validation.state && (
                <FormControl.Feedback type='invalid'>
                    {props.validation.message || 'Invalid'}
                </FormControl.Feedback>
            )}
            {props.disabled && props.lockIcon && (
                <DivLockRelative>
                    <DivLockAbsolute>
                        <FontAwesomeIcon
                            color={APP_COLORS['yellow']}
                            icon={faLock}
                            style={{marginRight: '10px', fontSize: 17}}
                        />
                    </DivLockAbsolute>
                </DivLockRelative>
            )}
            {clearable && clearableHandle && (!props.disabled) && (
                <ClearRelative>
                    <ClearAbsolute onClick={() => clearableHandle()}>
                        <FontAwesomeIcon
                            color={APP_COLORS['gray-500']}
                            icon={faTimes}
                            style={{fontSize: 25}}
                        />
                    </ClearAbsolute>
                </ClearRelative>
            )}
            {props.errorMsg && (              
                <span className="text-danger">
                    {props.errorMsg === true
                        ? `Please enter ${props.title}.`
                        : props.errorMsg}
                </span>
             )}
        </FormGroup>
    );
};


FormTextInput.propTypes = {
    id: PropTypes.string,
    name:PropTypes.string,
    title:PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'lg']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    isInvalid: PropTypes.bool,
    validation: PropTypes.shape({
        state: PropTypes.oneOf(['error', 'warning']),
        message: PropTypes.string,
    }),
    lockIcon: PropTypes.bool,
    clearable: PropTypes.bool,
    clearableHandle: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    onValidateFunc: PropTypes.func,
    reqType:PropTypes.string,
    errorMsg: PropTypes.any,
};

FormTextInput.defaultProps = {
    style: {},
    title: '',
    name:'',
    className: '',
    labelStyle: {},
    onChange: e => e,
    onBlur: e => e,
    onKeyDown: e => e,
    isInvalid: false,
    validation: {},
    value: '',
    size: 'sm',
    lockIcon: false,
    clearable: false,
    clearableHandle: e => e,
    onValidateFunc: () => {},
    reqType:'',
};

export default FormTextInput;
