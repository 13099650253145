import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import Modal from '../../../common/components/Modal';
import {
    Container,
    Form,
    Row,
    Col,
    Spinner,
    Button,
} from 'react-bootstrap';

import FormContainer from "../../../common/components/layout/FormContainer";
import FormTextInput from "../../../common/components/FormTextInput";
import {activateTeamMember} from "./teamSlice";

function ActivateTeamMemberDialog(props) {
    const {
        teamMember,
        handleClose,
    } = props;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleActivateTeamMember = async () => {
        dispatch(activateTeamMember({memberId: teamMember.id}));
        handleClose();
    };

    let isOwner = false;
    const role = teamMember.roles && teamMember.roles.length === 1 ? teamMember.roles[0] : '';
    if (role) {
        if (role === 'OWNER') isOwner = true;
    }

    return (
        <Modal
            dialogClassName={'dialog'}
            showClose={true}
            show={true}
            handleClose={handleClose}
            title={'Activate Team Member'}
            footer={
                (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>

                        <Button
                            variant='info'
                            disabled={loading || isOwner}
                            onClick={() => handleActivateTeamMember()}
                        >
                            {!loading && <>ACTIVATE</>}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            variant='outline-secondary'
                            onClick={() => handleClose()}
                            style={{marginLeft: '10px'}}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            {loading?
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        style={{marginTop: '30px'}}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
                :
                <Container>
                    <FormContainer>
                        <Form noValidate>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Given Name'}
                                        value={teamMember.profile.givenName}
                                        size={'sm'}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Surname'}
                                        value={teamMember.profile.surName}
                                        size={'sm'}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Email'}
                                        value={teamMember.email}
                                        size={'sm'}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12} style={{
                                    marginBottom: '10px'
                                }}>
                                    <span style={{
                                        fontStyle: 'italic',
                                        fontSize: '14px'
                                    }}>Once Activated the team member will need to request a password reset on the login page, in order to login.</span>
                                </Col>
                            </Row>
                        </Form>
                    </FormContainer>
                </Container>
            }
        </Modal>
    );
}
export default ActivateTeamMemberDialog;
