import React, { useEffect, useState} from 'react';
import { Button } from "react-bootstrap";
import BreadCrumb from "../../common/components/BreadCrumb";
import DataCollectionPanelHeader from "../../common/components/DataCollectionPanelHeader";
import DropdownAction from "../../common/components/DropdownAction";
import PanelTable from "../../common/components/layout/PanelTable";
import SearchCompanyInput from "../components/form/FormInputTextSearch";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import './ManagedCompanies.scss'
import ManagedCompaniesTable from './ManagedCompaniesTable';
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import {
    clearSelected,
    setSearchCriteria,
    setTcuFilter,
    getManagedCompaniesCount,
    getManagedCompanies,
    clearSelectedCompanies
} from "./managedCompaniesSlice";

const ManagedComapnies = (props) => {
    return (
        <ManagedCompaniesComponent />
    );
}

const ManagedCompaniesComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Companies',
        href: '',
        isActive: true
    }];

    const managedCompaniesState = useSelector(state => state.managedCompanies);
    const dispatch = useDispatch();
    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [searchSubTextFilter,SetsearchSubTextFilter] = useState('No Additional Filters Applied');
    const [searchSubText,SetSearchSubText] = useState('Search Companies!');
    // Options state vars
    const [products, setProducts] = useState([]);
    const [fts, setFts] = useState(false);
    const [randomReviewset, setRandomReviewSet] = useState(false);

    // Handle TCU filter select
    useEffect(() => {
        if (products.map(a => a.value).includes('Contractor Universe (TCU)')) {
            dispatch(setTcuFilter(true));
        } else {
            dispatch(setTcuFilter(false));
        }
        
        if(products.length > 0)
        {
            SetsearchSubTextFilter('Selected Filters: '+products.map(a => a.value).join(','));
        }else{
            SetsearchSubTextFilter('No Additional Filters Applied');
        }
        //setLastReload(Date.now());
    }, [dispatch, products]);

    // Clear selected companies on page load
    useEffect(() => {
        dispatch(clearSelectedCompanies());
    }, []);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedCompaniesState.alertMessage) {
            setAlertMessage(managedCompaniesState.alertMessage);
            setShowAlert(true);
        }
    }, [managedCompaniesState.alertMessage]);

    const isReload = managedCompaniesState.reload === true;

    // Get company data and count on page load
    useEffect(() => {
        dispatch(getManagedCompaniesCount());
        dispatch(getManagedCompanies());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }

    const actionItems = [
        {
            label: 'EDIT',
            href: '',
            isActive: true,
            onClickHandler: () => history.push(`/admin/d/companies/${managedCompaniesState.selectedId[0]}/edit`)
        },        
    ];

    return (
        <div className="companies-container">
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <DataCollectionPanelHeader
                header={`Companies (${managedCompaniesState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH</Button>
                        <DropdownAction items={actionItems}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled
                            onClick={() => {}}
                        >CREATE</Button>
                    </div>
                }                
                searchBar={
                    <SearchCompanyInput
                        placeholder={'Search by Id or Identity...'}
                        className={'companiesSearchText'}
                        value={managedCompaniesState.searchCriteria}
                        onChange={(value) => {dispatch(setSearchCriteria(value))}}
                        onKeyDown={handleOnKeyDown}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </SearchCompanyInput>
                }
                searchSubText={searchSubText}
                searchSubTextFilter={searchSubTextFilter}
                products={products}
                setProducts={setProducts}
                fts={fts}
                setFts={setFts}
                randomReviewset={randomReviewset}
                setRandomReviewSet={setRandomReviewSet}
            />
            <PanelTable>
            {managedCompaniesState.loading && (
                    <SpinnerOverlay />
                )}
                <ManagedCompaniesTable
                    lastReload={lastReload}
                />
            </PanelTable>
        </div>
    );
}

export default ManagedComapnies;