/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    sortBy,
} from 'lodash';

import ManagedIntegrationsTable from "./ManagedIntegrationsTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getManagedIntegrationsCount,
    getManagedIntegrations
} from "./managedIntegrationsSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

const ManagedIntegrations = (props) => {
    return (
        <ManagedIntegrationsComponent
        />
    );
};

const ManagedIntegrationsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Integrations',
        href: '',
        isActive: true
    }];
    const managedIntegrationsState = useSelector(state => state.managedIntegrations);
    const dispatch = useDispatch();
    const history = useHistory();
    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedIntegrationsState.alertMessage) {
            setAlertMessage(managedIntegrationsState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedIntegrationsState.alertMessage]);

    const isReload = managedIntegrationsState.reload === true;

    useEffect(() => {
        dispatch(getManagedIntegrationsCount());
        dispatch(getManagedIntegrations());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [];

        actionItems.push(
            {
                label: 'View As JSON'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/integrations/${managedIntegrationsState.selectedId[0]}/view-as-json`),
                isActive: (
                    managedIntegrationsState.selectedIntegrations &&
                    managedIntegrationsState.selectedIntegrations.length === 1
                ),

            }
        );

        return sortBy(actionItems, 'label');
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Integrations (${managedIntegrationsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/integrations/create')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id, organization id, name or description'}
                        value={managedIntegrationsState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {managedIntegrationsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <ManagedIntegrationsTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default ManagedIntegrations;
