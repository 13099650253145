/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import ManagedMatchTypes from "./ManagedMatchTypes"; // eslint-disable-line no-unused-vars

const ManagedMatchTypesContainer = (props) => {
    return(
       <ManagedMatchTypes/>
    )
};

export default ManagedMatchTypesContainer;
