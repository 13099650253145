/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownButton} from "react-bootstrap";
import './DropdownAction.scss';

const DropdownAction = (props) => {
    const {
        items,
    } = props;
    return (
    <DropdownButton id="dropdown-basic-button" variant="outline-secondary" title="ACTIONS" className='dropdownButton'>
            {items.map((item, index) => {
                return (
                    <Dropdown.Item
                        key={`dropdown-action-${index}`}
                        href={`${item.href}`}
                        disabled={!item.isActive}
                        onClick={item.onClickHandler}
                        onSelect={item.onSelectHandler}>
                        {`${item.label}`}
                    </Dropdown.Item >
                );
            })}
        </DropdownButton>
    );
};

DropdownAction.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        onClickHandler: PropTypes.func,
        onSelectHandler: PropTypes.func

    })).isRequired,
};

export default DropdownAction;
