import React, { useState, forwardRef, Component } from 'react';
// import {useDispatch} from 'react-redux';
// import {denormCompanyUpdate} from "./entityVerificationsSlice";

export const ContactRenderer = forwardRef((props, ref) => {
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const attributeValue = props.value;
    const attributeVerified = props.data[attributeDenormPrefix+'Verified'];
    const attributeInvalidated = props.data[attributeDenormPrefix+'Invalidated'];
    const verifiedIcon = attributeVerified ? ' \u2705' : '';

    const contactString = `${props.data[attributeDenormPrefix+'Honorific'] ? props.data[attributeDenormPrefix+'Honorific'] : ''}
                ${props.data[attributeDenormPrefix+'GivenName'] ? props.data[attributeDenormPrefix+'GivenName']: ''}
                ${props.data[attributeDenormPrefix+'MiddleName'] ? props.data[attributeDenormPrefix+'MiddleName']: ''}
                ${props.data[attributeDenormPrefix+'Surname'] ? props.data[attributeDenormPrefix+'Surname']: ''}
                ${props.data[attributeDenormPrefix+'MaturitySuffix'] ? props.data[attributeDenormPrefix+'MaturitySuffix']: ''}
                ${props.data[attributeDenormPrefix+'ProfessionalSuffix'] ? props.data[attributeDenormPrefix+'MaturitySuffix']: ''}`;

    // const
    return (
        <div>
            {attributeInvalidated? <span><s>{verifiedIcon} {contactString}</s></span>: <span>{verifiedIcon} {contactString}</span>}
        </div>
    );

});