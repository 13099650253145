
import _ from 'lodash';
export const STATES =
    {

  AL: {
    id: 'AL',
    display: 'Alabama (AL)',
  },
  AK: {
    id: 'AK',
    display: 'Alaska (AK)',
  },
  AZ: {
    id: 'AZ',
    display: 'Arizona (AZ)',
  },
  AR: {
    id: 'AR',
    display: 'Arkansas (AR)',
  },
  CA: {
    id: 'CA',
    display: 'California (CA)',
  },
  CO: {
    id: 'CO',
    display: 'Colorado (CO)',
  },
  CT: {
    id: 'CT',
    display: 'Connecticut (CT)',
  },
  DE: {
    id: 'DE',
    display: 'Delaware (DE)',
  },
  DC: {
    id: 'DC',
    display: 'District of Columbia (DC)',
  },
  FL: {
    id: 'FL',
    display: 'Florida (FL)',
  },
  GA: {
    id: 'GA',
    display: 'Georgia (GA)',
  },
  HI: {
    id: 'HI',
    display: 'Hawaii (HI)',
  },
  ID: {
    id: 'ID',
    display: 'Idaho (ID)',
  },
  IL: {
    id: 'IL',
    display: 'Illinois (IL)',
  },
  IN: {
    id: 'IN',
    display: 'Indiana (IN)',
  },
  IA: {
    id: 'IA',
    display: 'Iowa (IA)',
  },
  KS: {
    id: 'KS',
    display: 'Kansas (KS)',
  },
  KY: {
    id: 'KY',
    display: 'Kentucky (KY)',
  },
  LA: {
    id: 'LA',
    display: 'Louisiana (LA)',
  },
  ME: {
    id: 'ME',
    display: 'Maine (ME)',
  },
  MD: {
    id: 'MD',
    display: 'Maryland (MD)',
  },
  MA: {
    id: 'MA',
    display: 'Massachusetts (MA)',
  },
  MI: {
    id: 'MI',
    display: 'Michigan (MI)',
  },
  MN: {
    id: 'MN',
    display: 'Minnesota (MN)',
  },
  MS: {
    id: 'MS',
    display: 'Mississippi (MS)',
  },
  MO: {
    id: 'MO',
    display: 'Missouri (MO)',
  },
  MT: {
    id: 'MT',
    display: 'Montana (MT)',
  },
  NE: {
    id: 'NE',
    display: 'Nebraska (NE)',
  },
  NV: {
    id: 'NV',
    display: 'Nevada (NV)',
  },
  NH: {
    id: 'NH',
    display: 'New Hampshire (NH)',
  },
  NJ: {
    id: 'NJ',
    display: 'New Jersey (NJ)',
  },
  NM: {
    id: 'NM',
    display: 'New Mexico (NM)',
  },
  NY: {
    id: 'NY',
    display: 'New York (NY)',
  },
  NC: {
    id: 'NC',
    display: 'North Carolina (NC)',
  },
  ND: {
    id: 'ND',
    display: 'North Dakota (ND)',
  },
  OH: {
    id: 'OH',
    display: 'Ohio (OH)',
  },
  OK: {
    id: 'OK',
    display: 'Oklahoma (OK)',
  },
  OR: {
    id: 'OR',
    display: 'Oregon (OR)',
  },
  PA: {
    id: 'PA',
    display: 'Pennsylvania (PA)',
  },
  RI: {
    id: 'RI',
    display: 'Rhode Island (RI)',
  },
  SC: {
    id: 'SC',
    display: 'South Carolina (SC)',
  },
  SD: {
    id: 'SD',
    display: 'South Dakota (SD)',
  },
  TN: {
    id: 'TN',
    display: 'Tennessee (TN)',
  },
  TX: {
    id: 'TX',
    display: 'Texas (TX)',
  },
  UT: {
    id: 'UT',
    display: 'Utah (UT)',
  },
  VT: {
    id: 'VT',
    display: 'Vermont (VT)',
  },
  VA: {
    id: 'VA',
    display: 'Virginia (VA)',
  },
  WA: {
    id: 'WA',
    display: 'Washington (WA)',
  },
  WV: {
    id: 'WV',
    display: 'West Virginia (WV)',
  },
  WI: {
    id: 'WI',
    display: 'Wisconsin (WI)',
  },
  WY: {
    id: 'WY',
    display: 'Wyoming (WY)',
  },
  GU: {
    id: 'GU',
    display: 'Guam (GU)',
  },
  PR: {
    id: 'PR',
    display: 'Puerto Rico (PR)',
  },
  VI: {
    id: 'VI',
    display: 'US Virgin Islands (VI)',
  },
};
export function getStateById(id) {
  if (id) {
    return _.get(STATES, id);
  }
}
