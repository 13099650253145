import React, { useState, forwardRef, Component } from 'react';
import {useDispatch} from 'react-redux';
import {denormCompanyUpdate} from "./entityVerificationsSlice";
import _ from 'lodash';

export const ContactEditor = forwardRef((props, ref) => {

    const uiAttributeName = props.colDef.headerName;
    const attributeType = props.attributeType;
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const entityIdColumn = props.entityIdColumn;


    const [rowData, setRowData] = useState( props.data);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const [attributeId, setAttributeId] = useState(rowData[attributeDenormPrefix+'Id']);
    const [honorific, setHonorific] = useState(props.data[attributeDenormPrefix+'Honorific']);
    const [givenName, setGivenName] = useState(props.data[attributeDenormPrefix+'GivenName']);
    const [middleName, setMiddleName] = useState(props.data[attributeDenormPrefix+'MiddleName']);
    const [surname, setSurname] = useState(props.data[attributeDenormPrefix+'Surname']);
    const [maturitySuffix, setMaturitySuffix] = useState(props.data[attributeDenormPrefix+'MaturitySuffix']);
    const [professionalSuffix, setProfessionalSuffix] = useState(props.data[attributeDenormPrefix+'ProfessionalSuffix']);

    const [verifiedBy, setVerifiedBy] = useState(setVerifiedByBeginState(rowData[attributeDenormPrefix+'VerifiedBy']));
    const [invalidatedReason, setInvalidatedReason] = useState(setInvalidatedReasonBeginState(rowData[attributeDenormPrefix+'InvalidatedReason']));
    const dispatch = useDispatch();

    function setVerifiedByBeginState(beginValue){

        const validVerifieds = ['---'
            ,'[Entity Verification] - URL Verified - CHANGE'
            ,'[Entity Verification] - URL Verified - VERIFY (NO CHANGE)'
            ,'[Entity Verification] - URL Verified - OTHER'
            ,'[Entity Verification] - Phone Verified'
            ,'[Entity Verification] - Other'];

        if(beginValue === null){
            return '---';
        }
        else if(validVerifieds.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    function setInvalidatedReasonBeginState(beginValue){

        const validReasons = ['---'
            ,'[Entity Verification] - URL Verified - INVALIDATE'
            ,'[Entity Verification] - Phone Verified - INVALIDATE'
            ,'[Entity Verification] - Other'];

        if(beginValue === null){
            return '---';
        }
        else if(validReasons.includes(beginValue)){
            return beginValue;
        }
        else{
            return '[Entity Verification] - Other';
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault(); //prevent refresh on submit
        try {
            dispatch(denormCompanyUpdate({
                entityVerificationId: rowData["id"],
                companyId: rowData["companyId"],
                entityId: rowData[entityIdColumn],
                attributeType: attributeType,
                attributeDenormPrefix: attributeDenormPrefix,

                attributeId: rowData[attributeDenormPrefix+'Id'],
                columns: {  honorific: honorific,
                            givenName: givenName,
                            middleName: middleName,
                            surname: surname,
                            maturitySuffix: maturitySuffix,
                            professionalSuffix: professionalSuffix,
                            verifiedBy: verifiedBy === '---' ? null : verifiedBy,
                            invalidatedReason: invalidatedReason === '---' ? null: invalidatedReason},
                rowIndex: rowIndex
            }));

            // if (url) url.current.focus();
        }
        catch (err){
            console.log(`Had an exception and err = ${JSON.stringify(err)}`);
        }
    };

    const changeVerified = (event) => {
        setVerifiedBy(event.target.value);
        if(event.target.value === '---' && (invalidatedReason === '---')){
            setSubmitEnabled(false);
        }
        else if( event.target.value !== '---' && invalidatedReason !== '---'){ //Can't have both values set.
            setSubmitEnabled(false);
        }
        else {
            setSubmitEnabled(true);
        }
    }

    const changeInvalidated = (event) => {
        setInvalidatedReason(event.target.value);
        if(event.target.value === '---' && verifiedBy === '---' ){
            setSubmitEnabled(false);
        }
        else if(event.target.value !== '---' && verifiedBy !== '---' ){
            setSubmitEnabled(false);
        }
        else{
            setSubmitEnabled(true);
        }
    }


    return (
            // <input onKeyPress={this.onKeyPress} ref={this.textInput} defaultValue={this.props.value}/>
            <form style={{backgroundColor: "white", width:"200px"}} onSubmit={handleSubmit}>
                <div style={{marginLeft: "10px", width:"180px"}}>
                <label>Honorific:<br/><input style={{width:"180px"}} type="text" value={honorific} onChange={(e) => setHonorific(e.target.value)}/></label><br/>
                <label>Given Name:<br/><input style={{width:"180px"}} type="text" value={givenName} onChange={(e) => setGivenName(e.target.value)}/></label><br/>
                <label>Middle Name:<br/><input style={{width:"180px"}} type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)}/></label><br/>
                <label>Surname:<br/><input style={{width:"180px"}} type="text" value={surname} onChange={(e) => setSurname(e.target.value)}/></label><br/>
                <label>Maturity Suffix:<br/><input style={{width:"180px"}} type="text" value={maturitySuffix} onChange={(e) => setMaturitySuffix(e.target.value)}/></label><br/>
                <label>Professional Suffix:<br/><input style={{width:"180px"}} type="text" value={professionalSuffix} onChange={(e) => setProfessionalSuffix(e.target.value)}/></label><br/>
                <label>Verified By:<br/><select style={{width:"180px"}} value={verifiedBy} onChange={changeVerified}>
                    <option value='---'>---</option>
                    <option value='[Entity Verification] - URL Verified - CHANGE'>URL Verified - CHANGE</option>
                    <option value='[Entity Verification] - URL Verified - VERIFY (NO CHANGE)'>URL Verified - VERIFY (NO CHANGE)</option>
                    <option value='[Entity Verification] - URL Verified - OTHER'>URL Verified - OTHER</option>
                    <option value='[Entity Verification] - Phone Verified'>Phone Verified</option>
                    <option value='[Entity Verification] - Other'>Other</option>
                </select></label><br/>
                <label>Invalidated Reason:<br/><select style={{width:"180px"}} value={invalidatedReason} onChange={changeInvalidated}>
                    <option value='---'>---</option>
                    <option value='[Entity Verification] - URL Verified - INVALIDATE'>URL Verified - INVALIDATE</option>
                    <option value='[Entity Verification] - Phone Verified - INVALIDATE'>Phone Verified - INVALIDATE</option>
                    <option value='[Entity Verification] - Other'>Other</option>
                </select></label><br/>
                <button disabled={!submitEnabled}>Submit</button><br/><br/><br/>

                <label>Attribute Id (edits not applied): <br/><input style={{width: "180px"}} value={attributeId}/></label>
                </div>
            </form>
    );
});