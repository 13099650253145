

import {find, map} from 'lodash';

const TYPES = {
    INTERNAL: {
        id: 'INTERNAL',
        display: 'Internal'
    },
    PRIMARY: {
        id: 'PRIMARY',
        display: 'Primary'
    },
    SANDBOX: {
        id: 'SANDBOX',
        display: 'Sandbox'
    }
};

export function getTypeById(id) {
    if (id) {
        const _types = map(Object.keys(TYPES), key => TYPES[key]);
        const res = find(_types, type => type.id === id);
        if (res) {
            return res.display;
        }
        return id;
    }
    return id;
}
