import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedAccount: null
};

export const getAccountById = createAsyncThunk('accounts/getAccountById', async (id) => {
    const GET_MANAGED_ORGANIZATION_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-account/${id}`;
    const account = await Api.get(true, GET_MANAGED_ORGANIZATION_BY_ID_URL);
    return account;
});

export const getAccountWithFilter = createAsyncThunk('accounts/get-all-with-filter', async (filter) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-account`;
    const accounts = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return accounts;
});

export const editAccount = createAsyncThunk('accounts/editAccount', async ({id, data}) => {
    const GET_MANAGED_ORGANIZATION_BY_ID_URL = `${config.services.ATLAS.apiUrl}/account/${id}/edit`;
    const account = await Api.post(true, GET_MANAGED_ORGANIZATION_BY_ID_URL, {data});
    return account;
});

export const createAccount = createAsyncThunk('accounts/createAccount',
    async (data) => {
        const REGISTER_ACCOUNT = `${config.services.ATLAS.apiUrl}/account`;
        return await Api.post(true,
            REGISTER_ACCOUNT,
            { data: data }
        );
    });

export const disableAccount = createAsyncThunk('accounts/disableAccount', async (id) => {
    const DISABLE_ACCOUNT_RUL = `${config.services.ATLAS.apiUrl}/account/${id}/disable`;
    const account = await Api.post(true, DISABLE_ACCOUNT_RUL);
    return account;
});

export const enableAccount = createAsyncThunk('accounts/enableAccount', async (id) => {
    const ENABLE_ACCOUNT_URL = `${config.services.ATLAS.apiUrl}/account/${id}/enable`;
    const account = await Api.post(true, ENABLE_ACCOUNT_URL);
    return account;
});

const managedAccountSlice = createSlice({
    name: 'managedAccountSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedAccount = null;
            return state;
        }
    },
    extraReducers: {
        [getAccountById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getAccountById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedAccount = action.payload;
            }
        },
        [getAccountById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve an account information';
        },

        [editAccount.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [editAccount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedAccount = action.payload;
            }
            state.successMessage = 'Successfully edit account';
        },
        [editAccount.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to edit an account';
        },

        [createAccount.pending]: (state, action) => {
            state.loading = true;
        },
        [createAccount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedAccount = action.payload;
            }
            state.successMessage = `Successfully create a new account [${action.payload.username} - ${action.payload.id}]`;
        },
        [createAccount.rejected]: (state, action) => {
            state.loading = false;
            if (action.error && action.error.message) {
                state.alertMessage = action.error.message;
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
        [disableAccount.pending]: (state, action) => {
            state.loading = true;
        },
        [disableAccount.fulfilled]: (state, action) => {
            state.successMessage = `Successfully disable the account [${action.payload.email} - ${action.payload.id}]`
            state.loading = false;
            if (action.payload) {
                state.managedAccount = action.payload;
            }
        },
        [disableAccount.rejected]: (state, action) => {
            state.loading = false;
        },
        [enableAccount.pending]: (state, action) => {
            state.loading = true;
        },
        [enableAccount.fulfilled]: (state, action) => {
            state.successMessage = `Successfully enable the account [${action.payload.email} - ${action.payload.id}]`
            state.loading = false;
            if (action.payload) {
                state.managedAccount = action.payload;
            }
        },
        [enableAccount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAccountWithFilter().pending]: (state, action) => {
            state.loading = true;
        },
        [getAccountWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.accounts = action.payload;
            }
        },
        [getAccountWithFilter.rejected]: (state, action) => {
            state.loading = false;
        },
    }
});


export const { clearState } = managedAccountSlice.actions;
export default managedAccountSlice.reducer;
