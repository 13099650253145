/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import CardLabel from "../../../../organization/explore/components/cards/CardLabel";
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faPhone,
    faCheck,
    faQuestion,
} from '@fortawesome/free-solid-svg-icons';

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px,max-content) 1fr;
  padding: 0px 10px, 0px, 0px;
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function ContactChannelsBox(props) {
    const {
        item
    } = props;

    if (!(item.contactPhone || item.contactEmail)) {
        return (
            <BoxContainer>
                <CardLabel>Channels</CardLabel>
                <SubText>No channels for this contact.</SubText>
            </BoxContainer>
        )
    }

    return (
        <BoxContainer id='contact-channels-container'>
            <CardLabel>Channels</CardLabel>
            {item.contactPhone && (
                <SubText>
                    <FontAwesomeIcon
                        color={APP_COLORS['orange']}
                        icon={faPhone}
                    />&nbsp;&nbsp;
                    <a id="contact-phone" target='_blank' rel="noreferrer" href={`tel:${item.contactPhone}`}>
                        {item.contactPhone}
                    </a>
                </SubText>
            )}
            {item.contactEmail && (
                <SubText>
                    <FontAwesomeIcon
                        color={APP_COLORS['orange']}
                        icon={faEnvelope}
                    />&nbsp;&nbsp;
                    <a id="contact-email" target='_blank' rel="noreferrer" href={`mailto:${item.contactEmail}`}>
                        <>{item.contactEmail && item.contactEmail.length > 10 ?
                            `${item.contactEmail.substring(0, 10)}...` : item.contactEmail
                        }</>
                    </a>&nbsp;&nbsp;
                    {item.contactEmailStatus && item.contactEmailStatus === "Verified" && (
                        <FontAwesomeIcon
                            color={APP_COLORS['green']}
                            icon={faCheck}
                        />
                    )}
                    {item.contactEmailStatus && item.contactEmailStatus !== "Verified" && (
                        <FontAwesomeIcon
                            color={APP_COLORS['yellow']}
                            icon={faQuestion}
                        />
                    )}
                </SubText>
            )}
        </BoxContainer>
    )
}
