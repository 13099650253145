/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Redirect,
    Route, Switch,
} from 'react-router-dom';
import ManagedCompaniesContainer from './ManagedCompaniesContainer';
import EditCompanyContainer from './EditCompanyContainer';

export default class ManagedCompaniesRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/d/companies" exact component={ManagedCompaniesContainer} />
                <Route path="/admin/d/companies/:id/edit" component={EditCompanyContainer} />
                <Redirect to="/admin/d/companies"/>
            </Switch>
        );
    }
}
