import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    companyPhone: null
};

export const getCompanyPhoneById = createAsyncThunk('companies/getCompanyPhoneById', async (id) => {
    const GET_COMPANY_PHONE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${id}/phone`;
    const companyPhone = await Api.get(true, GET_COMPANY_PHONE_BY_ID_URL);
    return companyPhone;
});

export const changeCompanyPhoneById = createAsyncThunk('companies/changeCompanyPhoneById', async (data) => {
    const CHANGE_COMPANY_PHONE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/phone/change`;
    const response = await Api.post(true, CHANGE_COMPANY_PHONE_BY_ID_URL, data.body);
    return response;
});

export const createCompanyPhoneById = createAsyncThunk('companies/createeCompanyPhoneById', async (data) => {
    const CREATE_COMPANY_PHONE_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/phone`;
    const response = await Api.post(true, CREATE_COMPANY_PHONE_BY_ID_URL, data.body);
    return response;
});

const companyPhoneSlice = createSlice({
    name: 'companyPhoneSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.companyPhone = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyPhoneById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyPhoneById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyPhone = action.payload;
            }
        },
        [getCompanyPhoneById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
        [changeCompanyPhoneById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeCompanyPhoneById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyPhone = action.payload;
            }
        },
        [changeCompanyPhoneById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
        [createCompanyPhoneById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [createCompanyPhoneById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyPhone = action.payload;
            }
        },
        [createCompanyPhoneById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
    }
});

export const { clearState } = companyPhoneSlice.actions;
export default companyPhoneSlice.reducer;
