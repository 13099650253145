/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormLabel,
    FormControl

} from 'react-bootstrap';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../Constants";
import {faLock} from "@fortawesome/pro-solid-svg-icons";

const DivLockRelative = styled.div `
  position: relative;
`;

const DivLockAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -28px;
`;

const FormNumberInput = (props) => {
    const {
        style,
        className,
        id,
        label,
        variant,
        value,
        onChange,
        onBlur,
        disabled,
        lockIcon,
    } = props;
    return (
        <FormGroup
            style={style}
            className={className}
            controlId={id}
        >
            {
                label && (
                    <FormLabel className={"formTextLabel"}>
                        {label}
                    </FormLabel>
                )
            }
            {
                !label && (
                    <div style={{
                        marginTop: '15px'
                    }}>
                    </div>
                )
            }
            <FormControl
                variant={variant}
                type={'number'}
                placeholder={props.placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => onChange(e.target.value)}
                disabled={disabled}
                onBlur={(e) => onBlur(e.target.value)}
            />
            {disabled && lockIcon && (
                <DivLockRelative>
                    <DivLockAbsolute>
                        <FontAwesomeIcon
                            color={APP_COLORS['yellow']}
                            icon={faLock}
                            style={{marginRight: '10px', fontSize: 17}}
                        />
                    </DivLockAbsolute>
                </DivLockRelative>
            )}
        </FormGroup>
    );
};


FormNumberInput.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    lockIcon: PropTypes.bool,
};

FormNumberInput.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    variant: 'primary',
    onChange: e => e,
    onBlur: e => e,
    lockIcon: false,
};

export default FormNumberInput;
