import * as Api from '../Api';
import config from '../../config';
import * as CubeApi from "../CubeApi";
import * as ListService from './ListService';

const apiUrl = `${config.services.ATLAS.apiUrl}/managed-lists`;

export const getById = async (id) => {
    return Api.get(true, `${apiUrl}/${id}`);
};

export const update = async (id, list) => {
    const body = {
        options: null,
        data: list
    };
    return Api.post(true, `${apiUrl}/${id}/edit`, body);
};

export const getListsWithFilter = async(filter) => {
    return Api.get(true, `${apiUrl}`, filter);
};

export const getListImportOverlayRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportOverlayRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportOverlayRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }

    const payload = {
        dimensions: [
            'ListImportOverlayRecord.id',
            'ListImportOverlayRecord.list_id',
            'ListImportOverlayRecord.overlay_name',
            'ListImportOverlayRecord.overlay_website',
            'ListImportOverlayRecord.overlay_address',
            'ListImportOverlayRecord.overlay_address_delivery_line_1',
            'ListImportOverlayRecord.overlay_address_delivery_line_2',
            'ListImportOverlayRecord.overlay_address_city',
            'ListImportOverlayRecord.overlay_address_region',
            'ListImportOverlayRecord.overlay_address_postal_code',
            'ListImportOverlayRecord.overlay_address_postal_code_plus_4',
            'ListImportOverlayRecord.overlay_address_delivery_point_b',
            'ListImportOverlayRecord.overlay_address_latitude',
            'ListImportOverlayRecord.overlay_address_longitude',
            'ListImportOverlayRecord.overlay_address_precision',
            'ListImportOverlayRecord.overlay_address_county_fips',
            'ListImportOverlayRecord.overlay_address_county_name',
            'ListImportOverlayRecord.overlay_address_congressional_d',
            'ListImportOverlayRecord.overlay_address_rdi',
            'ListImportOverlayRecord.overlay_address_utc_offset',
            'ListImportOverlayRecord.overlay_phone',
            'ListImportOverlayRecord.overlay_email',
        //    'ListImportOverlayRecord.created',
        //    'ListImportOverlayRecord.updated',
        //    'ListImportOverlayRecord.backlinks',
        //    'ListImportOverlayRecord.cnt_hvac_plumbers_w_10mi',
        //    'ListImportOverlayRecord.sales_program_cy',
        //    'ListImportOverlayRecord.sales_program_pv',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 5000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};

export const getListImportCompanyRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportCompanyRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportCompanyRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }
    const payload = {
        dimensions: [
            'ListImportCompanyRecord.id',
            'ListImportCompanyRecord.list_id',
            'ListImportCompanyRecord.company_name',
            'ListImportCompanyRecord.company_website',
            'ListImportCompanyRecord.company_address',
            'ListImportCompanyRecord.company_address_delivery_line_1',
            'ListImportCompanyRecord.company_address_delivery_line_2',
            'ListImportCompanyRecord.company_address_city',
            'ListImportCompanyRecord.company_address_region',
            'ListImportCompanyRecord.company_address_postal_code',
            'ListImportCompanyRecord.company_address_postal_code_plus_4',
            'ListImportCompanyRecord.company_address_delivery_point_b',
            'ListImportCompanyRecord.company_address_latitude',
            'ListImportCompanyRecord.company_address_longitude',
            'ListImportCompanyRecord.company_address_precision',
            'ListImportCompanyRecord.company_address_county_fips',
            'ListImportCompanyRecord.company_address_county_name',
            'ListImportCompanyRecord.company_address_congressional_d',
            'ListImportCompanyRecord.company_address_rdi',
            'ListImportCompanyRecord.company_address_utc_offset',
            'ListImportCompanyRecord.company_phone',
            'ListImportCompanyRecord.company_email',
            'ListImportCompanyMatchedRecord.id',
            'ListImportCompanyMatchedRecord.company_id',
            'ListImportCompanyMatchedRecord.list_import_record_id',
            'ListImportCompanyMatchedRecord.matching_score',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 5000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};


export const getListImportContactRecord = async (listId, id) => {
    const _filters = [];
    if (listId) {
        _filters.push({
            dimension: `ListImportContactRecord.list_id`,
            operator: 'equals',
            values: [listId]
        });
    }
    if (id) {
        _filters.push({
            dimension: `ListImportContactRecord.id`,
            operator: 'equals',
            values: [id]
        });
    }
    const payload = {
        dimensions: [
            'ListImportContactRecord.id',
            'ListImportContactRecord.list_id',
            'ListImportContactRecord.company_name',
            'ListImportContactRecord.company_website',
            'ListImportContactRecord.company_address',
            'ListImportContactRecord.company_address_delivery_line_1',
            'ListImportContactRecord.company_address_delivery_line_2',
            'ListImportContactRecord.company_address_city',
            'ListImportContactRecord.company_address_region',
            'ListImportContactRecord.company_address_postal_code',
            'ListImportContactRecord.company_address_postal_code_plus_4',
            'ListImportContactRecord.company_address_delivery_point_b',
            'ListImportContactRecord.company_address_latitude',
            'ListImportContactRecord.company_address_longitude',
            'ListImportContactRecord.company_address_precision',
            'ListImportContactRecord.company_address_county_fips',
            'ListImportContactRecord.company_address_county_name',
            'ListImportContactRecord.company_address_congressional_d',
            'ListImportContactRecord.company_address_rdi',
            'ListImportContactRecord.company_address_utc_offset',
            'ListImportContactRecord.company_phone',
            'ListImportContactRecord.company_email',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 5000
    };

    const res = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    return res;
};

export const removeCubeTableName = (results) => {
    if (!results || results.length === 0) {
        return [];
    }
    const removeTab = (rec) => {
        const res = {};
        Object.keys(rec).map((key) => {
            const keys = key.split('.');
            const newKey = keys.length > 1 ? keys[1] : keys[0]
            res[newKey] = rec[key];
        });
        return res;
    };

    if (results && results.length > 0) {
        return results.map((_rec) => removeTab(_rec));
    }
    return removeTab(results);
};

export const shouldDisplayActionDownload = (list) => {

   // specific case for admin put before common.
    return ListService.shouldDisplayActionDownload(list);
};

export const shouldDisplayActionMatch = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionMatch(list);
};

export const shouldDisplayActionMatchCheck = (list) => {
    return ListService.shouldDisplayActionMatchCheck(list);
};

export const shouldDisplayActionNormalize = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionNormalize(list);
};

export const shouldDisplayActionParse = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionParse(list);
};

export const shouldDisplayActionProcess = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionProcess(list);
};

export const shouldDisplayActionSync = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionSync(list);
};

export const shouldDisplayActionReSync = (list) => {

    // specific case for admin put before common.
    return ListService.shouldDisplayActionReSync(list);
};


