/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Form
} from 'react-bootstrap';
import './Checkbox.scss';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../Constants";
import {faLock} from "@fortawesome/pro-solid-svg-icons";

const DivLockRelative = styled.div `
  position: relative;
`;

const DivLockAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -28px;
`;

const CheckBox = (props) => {
    const {
        id,
        label,
        onChange,
        checked,
        size,
        disabled,
        lockIcon,
        innerState = false
    } = props;

    const [innerCheck, setInnerCheck] = useState(false);

    return (
        <div
            className={innerState ? 'checkBox no-label' : 'checkBox'}
        >
            <Form.Check
                type={'checkbox'}
                id={`checkbox-${id}`}
                label={label}
                onChange={(e) => {
                    if (innerState) {
                        setInnerCheck(!innerCheck);
                    }
                    onChange(e.target.checked);
                }}
                checked={innerState ? innerCheck : checked}
                size={size}
                disabled={disabled}
            />
            {disabled && lockIcon && (
                <DivLockRelative>
                    <DivLockAbsolute>
                        <FontAwesomeIcon
                            color={APP_COLORS['yellow']}
                            icon={faLock}
                            style={{marginRight: '10px', fontSize: 13}}
                        />
                    </DivLockAbsolute>
                </DivLockRelative>
            )}
        </div>
    );
};


CheckBox.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'small', 'lg', 'large']),
    onChange: PropTypes.func,
    lockIcon: PropTypes.bool,
};

CheckBox.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    size: 'sm',
    onChange: e => e,
    checked: false,
    label: '',
    lockIcon: true,
};

export default CheckBox;
