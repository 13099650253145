/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as Api from "../../common/Api";
import config from "../../config";

import {find, isArray, sortBy} from "lodash";

const initialState = {
    organization: [],
    processStatus: [],
    listType: 'IMPORT_COMPANY',
    inputSource: 'FILE_UPLOAD', // [TEXT_INPUT, FILE_UPLOAD]
    fileUrl: '',
    fileSize: 0,
    fileType: 'csv',
    textInput: '',
    rawTextInput: '',
    listName: '',
    listDescription: '',
    fieldMappings: [],
    fileHeaders: [],
    mappingAttributes: [],
    totalRecords: 0,
    loading: false,
    importing: undefined,
    importStatus: undefined
}

export const getFieldMappings = createAsyncThunk('lists/admin-mapping-types',
    async (listType) => {
    const MAPPING_ATTR_URL = `${config.services.ATLAS.apiUrl}/managed-lists/mapping-types`;
    const attr = await Api.get(true, MAPPING_ATTR_URL);

    if (attr && isArray(attr)) {
        const mapping = find(attr, function (o) {
            return o.listType === listType;
        });
        if (mapping && mapping.attributes)
            return sortBy(mapping.attributes, ['ordinalPosition']);
    }

    return [];
});

const adminListImportSlice = createSlice({
    name: 'adminListImportSlice', initialState,
    reducers: {
        resetListImportState: {
            reducer(state, action) {
                state.organization = [];
                state.processStatus = [];
                state.listType = 'IMPORT_COMPANY';
                state.inputSource = 'FILE_UPLOAD'; // [TEXT_INPUT, FILE_UPLOAD]
                state.fileUrl = '';
                state.fileSize = 0;
                state.fileType = 'csv';
                state.textInput = '';
                state.listName = '';
                state.listDescription = '';
                state.fieldMappings = [];
                state.fileHeaders = [];
                state.mappingAttributes = [];
                state.totalRecords = 0;
                state.loading = false;
                state.importing = undefined;
                state.importStatus = undefined;
                state.rawTextInput = undefined;
            }
        },
        updateListImportState: {
            reducer(state, action) {
                state.organization = action.payload.organization;
                state.processStatus = action.payload.processStatus;
                state.listType = action.payload.listType;
                state.inputSource = action.payload.inputSource;
                state.fileUrl = action.payload.fileUrl;
                state.fileSize = action.payload.fileSize;
                state.fileType = action.payload.fileType;
                state.textInput = action.payload.textInput;
                state.listName = action.payload.listName;
                state.listDescription = action.payload.listDescription;
                state.fieldMappings = action.payload.fieldMappings;
                state.fileHeaders = action.payload.fileHeaders;
                state.mappingAttributes = action.payload.mappingAttributes;
                state.totalRecords = action.payload.totalRecords;
                state.loading = action.payload.loading;
                state.importing = action.payload.importing;
                state.importStatus = action.payload.importStatus;
                state.rawTextInput = action.payload.rawTextInput;
            }
        },
    },
    extraReducers: {
        [getFieldMappings.pending]: (state, action) => {
            state.loading = true;
        },
        [getFieldMappings.fulfilled]: (state, action) => {
            state.loading = false;
            state.fieldMappings = action.payload;
        },
        [getFieldMappings.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})

export const {
    resetListImportState,
    updateListImportState,
} = adminListImportSlice.actions;

export default adminListImportSlice.reducer;
