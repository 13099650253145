import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';

import config from '../../config'
import * as CubeApi from '../../common/CubeApi';
import {parseCubeFilter, parseOrgConfigFilter} from './CubeFilterBuilder'
import * as cache from "../../common/Cache";

const initialState = {
    filters: [],
    contacts: [],
    contactsGeo: {
        features: []
    },
    totalCompanies: 0,
    totalContacts: 0,
    currentPage: 1,
    orderBy: {},
    viewMode: 'CONTACT',
    loading: false,
    mapStatus: 'idle',
    productDataType: 'LOC_PLACE',
    accuracyData: null,
    appliedFilters: null,
};

/****
 * The default query run to populate counts when Apply Filter is pressed, WHERE clause must emulate the search query
 * RUN TO POPULATE MAP
 */
export const countCompanyAndContactByCriteria = createAsyncThunk('toolbeltdata/tru-pro-company-contact/count-by-criteria', async ({filters, exploreMode}, {getState}) => {
    const viewMode = exploreMode.contact.toggleMap ? 'map' : 'list';
    console.log(`[count] querying in mode [${exploreMode.mode}] with view mode [${viewMode}], building query`);
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    // Set Accuracy Score Default Filter
    const accuracyScore = getState().contact.accuracyData;
    const configFilters = parseOrgConfigFilter(filters, account.organization.config, 'Contact', accuracyScore);
    const _filters =  configFilters && configFilters.length > 0 ? parseCubeFilter(configFilters, 'Contact') : [];
    // Add Global Default Filters Based on View Mode (Map or List)
    let _filtersWithDefault;
    if (viewMode === 'map') {
        // THIS HOWEVER IS REDUNDANT, THERE IS NO MAP VIEW MODE
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.mapViewDefaultFilters);
    } else {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.listViewDefaultFilters);
    }

    const countPayload = {
        measures: ['ContactContractorUniverse.count', 'ContactContractorUniverse.count_contacts', 'ContactContractorUniverse.count_companies'],
        timeDimensions: [],
        filters: _filtersWithDefault,
    };

    console.log(`[count] query built identified [${countPayload.filters.length}] filters, executing`);
    const countResult = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        countPayload
    );

    const countCompanies = countResult[0]['ContactContractorUniverse.count_companies'] || 0;
    const countContacts = countResult[0]['ContactContractorUniverse.count_contacts'] || 0;

    console.log(`[count] query executed identified [${countCompanies}] companies and [${countContacts}], evaluating`);
    return {
        countCompanies,
        countContacts,
        data: null,
        productDataType: 'RECORD',
        filters: filters,
        cubeQuery: _filtersWithDefault,
    };
});

/****
 * The default query run to populate counts when Apply Filter is pressed, WHERE clause must emulate the search query
 */
export const searchContactByCriteria = createAsyncThunk('toolbeltdata/tru-pro-company-contact/find-one', async ({filters, exploreMode}, {getState}) => {
    const viewMode = exploreMode.contact.toggleMap ? 'map' : 'list';
    console.log(`[search] querying in mode [${exploreMode.mode}] with view mode [${viewMode}], building query`);
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    // Set Accuracy Score Default Filter
    const accuracyScore = getState().contact.accuracyData;
    const configFilters = parseOrgConfigFilter(filters, account.organization.config, 'Contact', accuracyScore);
    const _filters =  configFilters && configFilters.length > 0 ? parseCubeFilter(configFilters, 'Contact') : [];
    // Add Global Default Filters Based on View Mode (Map or List)
    let _filtersWithDefault;
    if (viewMode === 'map') {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.mapViewDefaultFilters);
    } else {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.listViewDefaultFilters);
    }
    const defaultOrder = {'ContactContractorUniverse.company_score': 'desc', 'ContactContractorUniverse.company_class_primary_trade_score': 'desc'};
    const payload = {
        dimensions: [
            'ContactContractorUniverse.company_id',
            'ContactContractorUniverse.company_identity',
            'ContactContractorUniverse.company_score',
            'ContactContractorUniverse.company_name',
            'ContactContractorUniverse.company_url',
            'ContactContractorUniverse.company_url_status',
            'ContactContractorUniverse.company_address_delivery_line_1',
            'ContactContractorUniverse.company_address_city',
            'ContactContractorUniverse.company_address_region',
            'ContactContractorUniverse.company_address_postal_code',
            'ContactContractorUniverse.company_phone',
            'ContactContractorUniverse.company_email',
            'ContactContractorUniverse.company_email_status',
            'ContactContractorUniverse.company_class_primary_trade',
            'ContactContractorUniverse.company_class_primary_trade_score',
            'ContactContractorUniverse.company_class_trades',
            'ContactContractorUniverse.company_class_naics_titles_formatted',
            'ContactContractorUniverse.company_ind_residential',
            'ContactContractorUniverse.company_ind_commercial',
            'ContactContractorUniverse.company_ind_industrial',
            'ContactContractorUniverse.company_ind_exterior',
            'ContactContractorUniverse.company_ind_interior',
            'ContactContractorUniverse.company_ind_licensed',
            'ContactContractorUniverse.company_ind_insured',
            'ContactContractorUniverse.company_ind_bonded',
            'ContactContractorUniverse.company_profile_cnt',
            'ContactContractorUniverse.company_profile_types_mapped',
            'ContactContractorUniverse.company_profile_reviews_ratings_max',
            'ContactContractorUniverse.company_profile_manufacturer_profile_cnt',
            'ContactContractorUniverse.company_profile_association_profile_cnt',
            'ContactContractorUniverse.company_license_cnt',
            'ContactContractorUniverse.company_permit_cnt',
            'ContactContractorUniverse.company_policy_cnt',
            'ContactContractorUniverse.company_registration_local_registration_status_mapped',
            'ContactContractorUniverse.company_contact_cnt',
            'ContactContractorUniverse.company_source_cnt',
            'ContactContractorUniverse.contact_id',
            'ContactContractorUniverse.contact_identity',
            'ContactContractorUniverse.contact_score',
            'ContactContractorUniverse.contact_name_given_name',
            'ContactContractorUniverse.contact_name_surname',
            'ContactContractorUniverse.contact_title',
            'ContactContractorUniverse.contact_phone',
            'ContactContractorUniverse.contact_email',
            'ContactContractorUniverse.contact_email_status',
            'ContactContractorUniverse.contact_roles',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filtersWithDefault,
        order: defaultOrder,
        limit: config.constants.EXPLORE_LIST_VIEW_LIMIT
    };

    console.log(`[search] query built identified [${payload.filters.length}] filters, executing`);
    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    console.log('[search] query executed');
    return resultSet;
});

const contactSlice = createSlice({
    name: 'contactSlice', initialState,
    reducers: {
        filterUpsert: {
            reducer(state, action) {
                const filterOption = action.payload;
                const index = _.findIndex(state.filters, (ft) => ft.id === filterOption.id);
                if (index >= 0) {
                    state.filters.splice(index, 1, filterOption);
                    return;
                }
                state.filters.push(action.payload);
                state.appliedFilters = null;
            },
            prepare(filterOption) {
                return {
                    payload: {
                        id: filterOption.id,
                        display: filterOption.display,
                        operatorId: filterOption.operatorId,
                        operatorDisplay: filterOption.operatorDisplay,
                        field: filterOption.field,
                        value: filterOption.value,
                        section: filterOption.section,
                        subSection: filterOption.subSection,
                        fieldDisplay: filterOption.fieldDisplay,
                        operand: filterOption.operand,
                        rangeField: filterOption.rangeField,
                        component: filterOption.component,
                    }
                }
            }
        },
        clearAllFilter: {
            reducer(state) {
                state.filters = [];
                state.contacts = [];
                state.contactsGeo = {
                    features: []
                };
                state.totalCompanies = 0;
                state.totalContacts = 0;
                state.currentPage = 1;
                state.orderBy = {};
                state.accuracyData = null;
                state.loading = false;
                state.appliedFilters = null;

                return state;
            }
        },
        removeFilter: {
            reducer(state, action) {
                const filterOption = action.payload;
                const index = _.findIndex(state.filters, (ft) => ft.id === filterOption.id);
                if (index >= 0) {
                    state.filters.splice(index, 1);
                }
                state.appliedFilters = null;
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload.currentPage;
                state.orderBy = action.payload.orderBy;
                state.appliedFilters = null;
            }
        },
        replaceFilter: {
            reducer(state, action) {
                state.filters = action.payload;
                state.appliedFilters = null;
            }
        },
        setAccuracyContactData: {
            reducer(state, action) {
                state.accuracyData = action.payload;
                state.appliedFilters = null;
            }
        },
    },
    extraReducers: {

        [searchContactByCriteria.pending]: (state, action) => {
            state.loading = true;
            state.currentPage = 1;
        },
        [searchContactByCriteria.fulfilled]: (state, action) => {
            state.contacts = action.payload;
            state.loading = false;
        },
        [searchContactByCriteria.rejected]: (state, action) => {
            state.loading = false;
        },
        [countCompanyAndContactByCriteria.pending]: (state, action) => {
            state.loading = true;
            state.mapStatus = 'loading';
            state.appliedFilters = null;
        },
        [countCompanyAndContactByCriteria.fulfilled]: (state, action) => {
            state.totalCompanies = action.payload.countCompanies;
            state.totalContacts = action.payload.countContacts;
            state.contactsGeo = action.payload.data;
            state.productDataType = action.payload.productDataType;
            state.loading = false;
            state.mapStatus = 'succeed';
            state.appliedFilters = {
                filters: action.payload.filters,
                totalCompanies: action.payload.countCompanies,
                totalContacts: action.payload.countContacts,
                cubeQuery: action.payload.cubeQuery,
            };
        },
        [countCompanyAndContactByCriteria.rejected]: (state, action) => {
            state.loading = false;
            state.mapStatus = 'error';
            state.appliedFilters = null;
        },
    }
})


export const {replaceFilter, clearAllFilter, filterUpsert, removeFilter, setCurrentPage, setAccuracyContactData} = contactSlice.actions;
export default contactSlice.reducer;
