/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState } from 'react';
import {filter, isEmpty, values} from "lodash";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Row} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";

import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";
import FormSelect from "../../common/components/form/FormSelect";
import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import CheckBox from "../../common/components/CheckBox";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import FormNumericInput from "../../common/components/FormNumericInput";

import {createSource} from "./sourcesSlice";

import {TYPES} from "./SourceTypes";
import {INDICATOR_TYPES} from "../../common/metadata/Indicators";
import {STATES} from "../../common/States";

export default function CreateSourceContainer() {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Sources',
        href: '/admin/sources',
        isActive: false
    }, {label: 'Create Source', href: '', isActive: true}];

    const dispatch = useDispatch();
    const sourcesState = useSelector(state => state.sources);

    const history = useHistory();

    const productOptions = [
        {value: 'b2b', label: 'b2b'},
        {value: 'tcu', label: 'tcu'}
    ];

    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [formValid, setFormValid] = useState(true);
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [qualityScore, setQualityScore] = useState(0.5);
    const [authoritative, setAuthoritative] = useState(false);
    const [isPublic, setIsPublic] = useState(true);
    const [products, setProducts] = useState([productOptions[0]]);
    const [indicators, setIndicators] = useState(null);

    const handleOnCancel = async () => {
        history.push('/admin/sources');
    };

    const isValid = () => {
        if (!type.value) return false;
        if (!name) return false;
        if (!label) return false;
        if (qualityScore) {
            const _qualityScore = parseFloat(qualityScore);
            if (_qualityScore < 0 || _qualityScore > 1) return false;
        }
        return true;
    };

    const reset = () => {
        setType('');
        setType('');
        setType('');
        setName('');
        setLabel('');
        setDescription('');
        setRegion('');
        setCity('');
        setQualityScore('');
        setAuthoritative(false);
        setIsPublic(true);
        setProducts([productOptions[0]]);
        setIndicators(null);
    }

    const handleCreateSource = async () => {
        if (!isValid()) {
            setFormValid(false);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            setFormValid(true);
            const source = {
                type: type.value,
                name: name.trim(),
                label: label ? label.trim() : undefined,
                description: description ? description.trim() : undefined,
                url: url ? url.trim() : undefined,
                region: region.value ? region.value : undefined,
                // county: county,
                city: city ? city.trim() : undefined,
                // postalCode: postalCode,
                qualityScore: !qualityScore ? undefined : parseFloat(qualityScore),
                authoritative: authoritative,
                public: isPublic,
                products: products.map((p) => (p.value)),
                indicators: indicators ? indicators.map((i) => (i.value)) : null
            }
            dispatch(createSource(source));
        }
    };

    useEffect(() => {
        // When open dialog, we clear state.
        if (sourcesState.successMessage) {
            console.log(sourcesState.successMessage);
            setSuccessMessage(sourcesState.successMessage);
            reset();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [sourcesState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        console.log(sourcesState);
        if (sourcesState.alertMessage) {
            setAlertMessage(sourcesState.alertMessage);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [sourcesState.alertMessage]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Create Source'} subHeader={'Create a new source'} />
            <Panel>
                {sourcesState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={!isEmpty(alertMessage)}
                    onClose={() => setAlertMessage('')}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={!isEmpty(successMessage)}
                    onClose={() => setSuccessMessage('')}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createSource">
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Type'}
                                                options={values(TYPES).map((t) => ({
                                                    value: t.id,
                                                    label: t.display
                                                }))}
                                                onChange={(v) => setType(v)}
                                                isInvalid={!formValid && !type}
                                                value={{value: type.value, label: type.label}}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Name'}
                                                value={name}
                                                onChange={value => setName(value)}
                                                isInvalid={!formValid && !name}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Label'}
                                                value={label}
                                                onChange={value => setLabel(value)}
                                                isInvalid={!formValid && !label}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextArea
                                                label={'Description'}
                                                onChange={value => setDescription(value)}
                                                value={description}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'URL'}
                                                value={url}
                                                onChange={value => setUrl(value)}
                                                size={'sm'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            {/*<FormTextInput*/}
                                            {/*    label={'Region'}*/}
                                            {/*    value={region}*/}
                                            {/*    onChange={value => setRegion(value)}*/}
                                            {/*    size={'sm'}*/}
                                            {/*/>*/}
                                            <FormSelect
                                                label={'Region'}
                                                options={values(STATES).map((t) => ({
                                                    value: t.id,
                                                    label: t.display
                                                }))}
                                                onChange={(v) => setRegion(v)}
                                                value={{value: region.value, label: region.label}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'City'}
                                                value={city}
                                                onChange={value => setCity(value)}
                                                size={'sm'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormNumericInput
                                                label={'Quality Score'}
                                                value={qualityScore}
                                                onChange={value => setQualityScore(value)}
                                                size={'sm'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Authoritative'}
                                                checked={authoritative}
                                                onChange={value => setAuthoritative(value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Public'}
                                                checked={isPublic}
                                                onChange={value => setIsPublic(value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Products'}
                                                options={productOptions}
                                                onChange={(v) => setProducts(v)}
                                                value={products}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Indicators'}
                                                options={filter(values(INDICATOR_TYPES), (i) => {
                                                    return !!i.display}).map((t) => ({value: t.id, label: t.display})
                                                )}
                                                onChange={(v) => setIndicators(v)}
                                                value={indicators}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateSource()}
                                    >CREATE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => handleOnCancel()}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    )
}
