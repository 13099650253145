/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import ReactTooltip from "react-tooltip";
import FlyoutChart from "../../../../common/components/FlyoutChart";
import ExploreMapFlyoutDetailSelectedCountCompaniesContactsClustered
    from "./ExploreMapFlyoutDetailSelectedCountCompaniesContactsClustered";
import styled from "styled-components";

const MainContainer = styled.div `
  overflow-y: hidden;
  overflow-x: hidden;
`;

export default function ExploreMapFlyoutDetailSelectedProduct(props) {
    const [dataLabels, setDataLabels] = useState([]);
    const [dataValues, setDataValues] = useState([]);
    const [totalTradeTypes, setTotalTradeTypes] = useState(0);
    const [toolTip, setToolTip] = useState('');
    let noEmptyTradeTypes = [];

    useEffect(() => {
        noEmptyTradeTypes = _.remove(props.tradeTypes, (each) => {
            return each.tradeType && each.tradeType !== '' ;
        });

        let isChartOther = noEmptyTradeTypes.length > 5;
        const tradeTypeOrderByCount = _.orderBy(noEmptyTradeTypes, ['count'], ['desc']);

        let labels = [];
        let labelOther = [];

        let count = [];
        let countOther = 0;

        let numOfAdditionTrades = 0;

        let toolTips = [];

        tradeTypeOrderByCount.forEach((tradeType, i) => {
            if (isChartOther && i > 4) {
                labelOther.push(tradeType.tradeType === '' ? 'No Type Defined' : tradeType.tradeType);
                numOfAdditionTrades += 1;
                countOther += tradeType.count;
            } else {
                labels.push(tradeType.tradeType === '' ? 'No Type Defined' : tradeType.tradeType);
                count.push(tradeType.count)
            }

            toolTips.push(tradeType.tradeType === '' ? 'No Type Defined' : tradeType.tradeType);
        });

        if (isChartOther) {
            labels.push(`${numOfAdditionTrades} Additional Trades`);
            count.push(countOther);
        }

        setDataLabels(labels);
        setDataValues(count);
        setToolTip(toolTips.join(', '))
        setTotalTradeTypes(noEmptyTradeTypes.length);
    }, [props.tradeTypes]);

    const data = {
        labels: dataLabels,
        datasets: [
            {
                label: '# of Votes',
                data: dataValues,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(30, 210, 57, 0.2)',
                    'rgba(54, 66, 226, 0.2)',
                    'rgba(139, 146, 238, 0.2)',
                    'rgba(238, 139, 180, 0.2)',
                    'rgba(231, 134, 85, 0.2)',
                    'rgba(165, 214, 31, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(30, 210, 57, 1)',
                    'rgba(54, 66, 226, 1)',
                    'rgba(139, 146, 238, 1)',
                    'rgba(238, 139, 180, 1)',
                    'rgba(231, 134, 85, 1)',
                    'rgba(165, 214, 31, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <MainContainer>
            <ExploreMapFlyoutDetailSelectedCountCompaniesContactsClustered
                countCompanies={props.countCompanies}
                countContacts={props.countContacts}
            />
            <div>
                <div>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        {totalTradeTypes > 5 ?
                            <span className="lead-text-label">Top Trades</span>
                            :
                            <span className="lead-text-label">Trades</span>
                        }

                        <FlyoutChart
                            chartData={data}
                            legend
                        />
                        <span className="lead-text-label">in {props.bestLocationName}</span>
                    </div>
                </div>
            </div>
            <div className="carousel-total">
                {noEmptyTradeTypes && noEmptyTradeTypes.length === 1 &&
                <span data-tip="React-tooltip"><span
                    style={{fontWeight: 600}}>{`${noEmptyTradeTypes[0].count}`}</span> {` ${noEmptyTradeTypes[0].tradeType}`} </span>
                }
                {noEmptyTradeTypes && noEmptyTradeTypes.length === 2 &&
                <span data-tip="React-tooltip"><span
                    style={{fontWeight: 600}}>{`${noEmptyTradeTypes[0].count}`}</span>{` ${noEmptyTradeTypes[0].tradeType},`}
                    <span
                        style={{fontWeight: 600}}>{`${noEmptyTradeTypes[1].count}`}</span>{` ${noEmptyTradeTypes[1].tradeType}`}</span>
                }
                {noEmptyTradeTypes && noEmptyTradeTypes.length > 2 &&
                <span data-tip="React-tooltip"><span
                    style={{fontWeight: 600}}>{`${noEmptyTradeTypes[0].count}`}</span>{` ${noEmptyTradeTypes[0].tradeType === '' ? 'No Type Defined' : noEmptyTradeTypes[0].tradeType},`}
                    <span
                        style={{fontWeight: 600}}>{`${noEmptyTradeTypes[1].count}`}</span>{` ${noEmptyTradeTypes[1].tradeType} and `}<span
                        style={{fontWeight: 600}}>{`${noEmptyTradeTypes.length - 2}`}</span> {` more`}</span>
                }

                <ReactTooltip place="bottom" type="dark" effect="solid">
                    <div style={{maxWidth: 150, padding: 10}}>
                        {toolTip}
                    </div>
                </ReactTooltip>
            </div>
        </MainContainer>
    );
}
