/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";

import {useDispatch, useSelector} from "react-redux";
import {clearSelected, setSearchCriteria} from "./listSlice";

import PanelTable from "../../common/components/layout/PanelTable";

import ListTable from "./ListTable";
import FormTextInput from "../../common/components/FormTextInput";
import EditListDialog from './EditListDialog';
import DownloadListDialog from './DownloadListDialog';
import UploadListDialog from "../explore/upload/UploadListDialog";
import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import PanelHeader from "../../common/components/layout/PanelHeader";

import './List.scss';
import {shouldDisplayActionDownload} from "../../common/services/ListService";
import DownloadListMatchReportDialog from "./DownloadListMatchReportDialog";

export default class Lists extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showImportDialog: false,
            lastReload: Date.now()
        };
    }

    render() {
        return (
            <ListsComponent/>
        )
    }
}

function ListsComponent(props) {
    const breadcrumbItems = [{label: 'Home', href: '/org/home', isActive: false}, {
        label: 'Lists',
        href: '',
        isActive: true
    }];
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [showEditRecord, setShowEditRecord] = useState(false);
    const [showDownloadRecord, setShowDownloadRecord] = useState(false);
    const [showCreateListMatchReport, setShowCreateListMatchReport] = useState(false);
    const [lastReload, setLastReload] = useState(Date.now());
    const listDataState = useSelector(state => state.list);
    const dispatch = useDispatch();

    const getActionItems = () => {
        const actionItems = [
            {
                label: 'DOWNLOAD',
                href: '',
                isActive: listDataState?.selectedId?.length === 1 &&
                    shouldDisplayActionDownload(listDataState.selectedList[0], true)
                ,
                onClickHandler: () => setShowDownloadRecord(true)
            },
            {
                label: 'EDIT',
                href: '',
                isActive: listDataState?.selectedId?.length === 1,
                onClickHandler: () => setShowEditRecord(true)
            },
            // DISABLING HAS NOT BEEN TESTED
            // {
            //     label: 'Create Match Report'.toUpperCase(),
            //     href: '',
            //     onClickHandler: () => setShowCreateListMatchReport(true),
            //     isActive: (
            //         listDataState?.selectedId &&
            //         listDataState?.selectedId?.length === 1 && (listDataState.selectedList[0].type === 'MATCH_COMPANY' || listDataState.selectedList[0].type === 'MATCH_CONTACT')
            //     ),
            // }
        ];

        return actionItems;
    };

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [dispatch, lastReload]);


    return (
        <div className="lists-container">
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            {showImportDialog && (
                <div>
                    <UploadListDialog
                        show={showImportDialog}
                        handleClose={() => {
                            setShowImportDialog(false);
                            setLastReload(Date.now());
                        }}
                    />
                </div>
            )}
            {showEditRecord && (
                <EditListDialog
                    show={showEditRecord}
                    handleClose={() => {
                        setShowEditRecord(false);
                    }}
                    id={listDataState.selectedId[0]}
                />
            )}
            {showDownloadRecord && (
                <DownloadListDialog
                    show={showDownloadRecord}
                    handleClose={() => {
                        setShowDownloadRecord(false);
                    }}
                    id={listDataState.selectedId[0]}
                />
            )}
            {showCreateListMatchReport && (
                <DownloadListMatchReportDialog
                    show={showCreateListMatchReport}
                    handleClose={() => {
                        setShowCreateListMatchReport(false);
                    }}
                    id={listDataState.selectedId[0]}
                />
            )}
            <PanelHeader
                header={`Lists (${listDataState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => setShowImportDialog(true)}
                        >
                            IMPORT
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search name or description ... '}
                        className={'listSearchText'}
                        value={listDataState.searchCriteria}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        onKeyDown={handleOnKeyDown}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {listDataState.loading && (
                    <SpinnerOverlay/>
                )}
                <ListTable lastReload={lastReload}/>
            </PanelTable>
        </div>
    );
}
