/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";
import {APP_COLORS} from "../../../../common/Constants";

const CardLabel = styled.div `
  font-size: 13px;
  font-weight: bold;
  color: ${APP_COLORS['gray-card']};
`;

export default CardLabel;
