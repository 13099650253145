import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    organizations: [],
    managedOrganization: null,
};

export const createOrganization = createAsyncThunk('organizations/create-organization',
    async (input,  { rejectWithValue }) => {
        const CREATE_ORGANIZATION = `${config.services.ATLAS.apiUrl}/managed-organizations/onboard`;
        try {
            const org = await Api.post(true,
                CREATE_ORGANIZATION,
                {
                    options: null,
                    data: input
                }
            );
            return org;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.body);
        }
    });

export const getOrganizations = createAsyncThunk('organizations/get-all', async (arg, {getState, requestId}) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-organizations`;
    const organizations = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL,
        buildQuery(
            getState().organizations.currentPage,
            getState().organizations.pageSize,
            getState().organizations.order.by,
            getState().organizations.order.direction,
            getState().organizations.searchCriteria
        ));
    return organizations;
});

export const getOrganizationsWithFilter = createAsyncThunk('organizations/get-all-with-filter', async (filter) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-organizations`;
    const organizations = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return organizations;
});

export const getOrganizationByIdWithFilter = createAsyncThunk('organizations/get-by-id-with-filter', async ({id, filter}) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.apiUrl}/managed-organizations/${id}`;
    const organizations = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return organizations;
});

export const getOrganizationsCount = createAsyncThunk('organizations/getCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        // const where = {
        //     where: {
        //         or: [
        //             {
        //                 id: {
        //                     ilike: `%${getState().organizations.searchCriteria ? getState().organizations.searchCriteria : ''}%`
        //                 }
        //             },
        //             {
        //                 name: {
        //                     ilike: `%${getState().organizations.searchCriteria ? getState().organizations.searchCriteria : ''}%`
        //                 }
        //             }
        //         ]
        //
        //     }
        // }
        const SOURCE_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-organizations/count`;
        // const organizationsCount = await Api.get(true, SOURCE_COUNT_URL, where);
        const organizationsCount = await Api.get(true, SOURCE_COUNT_URL,
            buildQuery(
                getState().organizations.currentPage,
                getState().organizations.pageSize,
                getState().organizations.order.by,
                getState().organizations.order.direction,
                getState().organizations.searchCriteria
            )
        );

        return organizationsCount?.count;
    } catch (err) {
        console.error(err);
    }
});


export const getOrganizationById = createAsyncThunk('organizations/getOrganizationById', async (id) => {
    const GET_MANAGED_ORGANIZATION_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-organizations/${id}`;
    const organization = await Api.get(true, GET_MANAGED_ORGANIZATION_BY_ID_URL);
    return organization;
});


const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isGuid = regexExp.test(searchCriteria);

    const query = isGuid ? {
        filter: {
            // fields: ['id', 'typeId', 'name', 'abbrv', 'statusId', 'created'],
            where: {
                or: [{
                    id: {
                        eq: `${searchCriteria ? searchCriteria : ''}`
                    }
                }]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            // fields: ['id', 'typeId', 'name', 'abbrv', 'statusId', 'owner', 'created'],
            where: {
                and: [
                    {
                        or: [{
                            name: {
                                ilike: `%${searchCriteria ? searchCriteria : ''}%`
                            }
                        }]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};


const organizationsSlice = createSlice({
    name: 'organizationsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getOrganizations.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrganizations.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.organizations = action.payload;
            }
        },
        [getOrganizations.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOrganizationsCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrganizationsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getOrganizationsCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOrganizationsWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrganizationsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.organizations = action.payload;
            }
        },
        [getOrganizationsWithFilter.rejected]: (state, action) => {
            state.loading = false;
        },
        [getOrganizationByIdWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrganizationByIdWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedOrganization = action.payload;
            }
        },
        [getOrganizationByIdWithFilter.rejected]: (state, action) => {
            state.loading = false;
        },
        [createOrganization.pending]: (state, action) => {
            state.loading = true;
        },
        [createOrganization.fulfilled]: (state, action) => {
            state.loading = false;
            state.successMessage = `Successfully onboarded a new organization [${action.payload.name} - ${action.payload.id}]`
            state.reset = true;
        },
        [createOrganization.rejected]: (state, action) => {
            state.loading = false;
            if (action?.payload) {
                let errObj = undefined;
                try {
                    errObj = action?.payload;
                } catch (e) {
                    // Don't need to do anything.
                }
                if (errObj) {
                    state.alertMessage = `${errObj.message}.`;
                } else {
                    state.alertMessage = action.error.message;
                }
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },


        [getOrganizationById.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrganizationById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedOrganization = action.payload;
            }
        },
        [getOrganizationById.rejected]: (state, action) => {
            state.loading = false;
        },


    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria,
    resetCreateOrganizationState
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
