/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";
import {APP_COLORS} from "../../../../common/Constants";
import {
    faBuilding, faCalculator,
    faCertificate, faEnvelope, faFile,
    faHouse, faIdBadge,
    faPersonShelter, faPhone,
    faSatelliteDish, faUser,
    faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import {faChimney, faFileCertificate} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getTagImagesCompanyById} from "../../../../common/components/TagImagesCompany";
import {CloudImage} from "../../../../common/components/TagImagesCloudView";

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 20px minmax(20px,max-content);
  padding: 0;
  margin-top: 10px;
`;

const CardLabel = styled.div `
  font-size: 12px;
  font-weight: bold;
  color: ${APP_COLORS['gray-card']};
`;

const LeadText = styled.div `
  font-size: 12px;
  font-weight: bold;
  color: ${APP_COLORS.blue};
`;

const SubText = styled.div `
  font-size: 12px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

const PillRow = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
`;

const PillContainer = styled.div `
  border-radius: 5px;
  border: 1px solid ${APP_COLORS['gray-card']};
  margin: 3px;
  background-color: ${APP_COLORS['gray-100']};
  
  padding: 0 5px;
  
  display: flex;

  max-width: 270px;
`;

const PillIcon = styled.div `
  padding: 1px 0 2px 5px;
  background-color: ${props => props.bgColor ? props.bgColor : "#f8f9fa"};
  
  img {
    height: 13px !important;
  }
`;

const PillText = styled.span `
  font-size: 12px;
  padding: 5px;
  color: ${APP_COLORS['tech-blue']};
  font-weight: 600;

  text-overflow: ellipsis;
  overflow: hidden;
`;

const ContactBox = styled.div `
  display: flex;
  grid-gap: 5px;
`;

export default function ExploreMapFlyoutDetailUnCluster(props) {
    const {
        item
    } = props;

    let classifications = [];
    if (item.company_class_trades) {
        classifications = item.company_class_trades.split(', ');
    }

    const pillIcons = [
        {attr: 'companyIndResidential', value: false, icon: faHouse, text: 'Residential'},
        {attr: 'companyIndCommercial', value: false, icon: faBuilding, text: 'Commercial'},
        {attr: 'companyIndIndustrial', value: false, icon: faWarehouse, text: 'Industrial'},
        {attr: 'companyIndExterior', value: false, icon: faChimney, text: 'Exterior'},
        {attr: 'companyIndInterior', value: false, icon: faPersonShelter, text: 'Interior'},
        {attr: 'companyIndLicensed', value: false, icon: faFileCertificate, text: 'Licensed'},
        {attr: 'companyIndBonded', value: false, icon: faFileCertificate, text: 'Bonded'},
        {attr: 'companyIndInsured', value: false, icon: faFileCertificate, text: 'Insured'},
        {attr: 'companyProfileCnt', value: true, icon: faSatelliteDish, text: 'Profiles'},
        {attr: 'companyManufacturerCnt', value: true, icon: faWarehouse, text: 'Manufacturers'},
        {attr: 'companyAssociationCnt', value: true, icon: faCertificate, text: 'Associations'},
        {attr: 'companyLicenseCnt', value: true, icon: faIdBadge, text: 'Licenses'},
        {attr: 'companyPermitCnt', value: true, icon: faCalculator, text: 'Permits'},
        {attr: 'companyPolicyCnt', value: true, icon: faFileCertificate, text: 'Policies'},
        {attr: 'companyStatContactCnt', value: true, icon: faUser, text: 'Contacts'},
        {attr: 'companySourceCnt', value: true, icon: faFile, text: 'Sources'},
    ];

    const pills = [];
    pillIcons.forEach((p, i) => {
        if (item[p.attr]) {
            if (p.value && item[p.attr]) {
                pills.push(
                    <PillContainer key={i}>
                        <PillText>
                            <FontAwesomeIcon
                                icon={p.icon}
                            />&nbsp;{item[p.attr]} {p.text}
                        </PillText>
                    </PillContainer>
                )
            } else {
                pills.push(
                    <PillContainer key={i}>
                        <PillText>
                            <FontAwesomeIcon
                                icon={p.icon}
                            />&nbsp;{p.text}
                        </PillText>
                    </PillContainer>
                )
            }
        }
    })

    // Interpreted Values
    if (item.companyRegLocalRegistrationStatus === 'Active') {
        pills.push(
            // we should'nt be hardcoding the key ....
            <PillContainer key={99}>
                <PillText>
                    <FontAwesomeIcon
                        icon={faFileCertificate}
                    />&nbsp;Registered
                </PillText>
            </PillContainer>
        )
    }

    let onlineProfilesImages;

    if (item.company_profile_online_profiles && item.company_profile_online_profiles !== '') {
        let result = item.company_profile_online_profiles.replace(/[\[\]"]/g, "");
        onlineProfilesImages = result.split(',');
    }

    let manufacturers;

    if (item.company_profile_manufacturer_profiles && item.company_profile_manufacturer_profiles !== '') {
        let result = item.company_profile_manufacturer_profiles.replace(/[\[\]"]/g, "");
        manufacturers = result.split(',');
    }

    let associations;

    if (item.company_profile_association_profiles && item.company_profile_association_profiles !== '') {
        let result = item.company_profile_association_profiles.replace(/[\[\]"]/g, "");
        associations = result.split(',');
    }

    return (
        <>
            {item.contact_id && (
                <BoxContainer>
                    <CardLabel>Primary Contact</CardLabel>
                    <ContactBox>
                        <LeadText>
                            <>{item.contact_name_given_name} {item.contact_name_surname}</>
                        </LeadText>
                        {item.contactPhone && (
                            <SubText>
                                <a target='_blank' rel="noreferrer" href={`tel:${item.contactPhone}`}>
                                    <FontAwesomeIcon
                                        color={APP_COLORS['orange']}
                                        icon={faPhone}
                                    />
                                </a>
                            </SubText>
                        )}
                        {item.contactEmail && (
                            <SubText>
                                <a target='_blank' rel="noreferrer" href={`mailto:${item.contactEmail}`}>
                                    <FontAwesomeIcon
                                        color={APP_COLORS['orange']}
                                        icon={faEnvelope}
                                    />
                                </a>&nbsp;&nbsp;
                            </SubText>
                        )}
                    </ContactBox>
                    <SubText>
                        {item.contact_title && (
                            <>{item.contact_title}</>
                        )}
                    </SubText>
                </BoxContainer>
            )}
            {classifications && classifications.length > 0 && (
                <BoxContainer>
                    <CardLabel>Classifications</CardLabel>
                    <PillRow>
                        {classifications && classifications.length > 0 && (
                            <>
                                {classifications.map((t, i) => (
                                    <PillContainer key={i}>
                                        <PillText>{t}</PillText>
                                    </PillContainer>
                                ))}
                            </>
                        )}
                    </PillRow>
                </BoxContainer>
            )}
            {(item.company_profile_online_profiles && item.company_profile_online_profiles.length > 0) && (
                <BoxContainer>
                    <CardLabel>Online Profiles</CardLabel>
                    <PillRow>
                        {onlineProfilesImages && onlineProfilesImages.length > 0 && (
                            <>
                                {onlineProfilesImages.map((t, i) => (
                                    <PillContainer key={i}>
                                        {getTagImagesCompanyById(t) ? (
                                            <>
                                                {!getTagImagesCompanyById(t).notShowIcon && (
                                                    <PillIcon bgColor={getTagImagesCompanyById(t).backgroundColor}>
                                                        <CloudImage image={getTagImagesCompanyById(t).imageUrl} height={20} />
                                                    </PillIcon>
                                                )}
                                                <PillText>
                                                    {getTagImagesCompanyById(t).caption && (
                                                        <>{t}</>
                                                    )}
                                                </PillText>
                                            </>
                                        ) : (
                                            <PillText>{t}</PillText>
                                        )}
                                    </PillContainer>
                                ))}
                            </>
                        )}
                    </PillRow>
                </BoxContainer>
            )}
            {(item.company_profile_manufacturer_profiles && item.company_profile_manufacturer_profiles.length > 0) && (
                <BoxContainer>
                    <CardLabel>Manufacturers</CardLabel>
                    <PillRow>
                        {manufacturers && manufacturers.length > 0 && (
                            <>
                                {manufacturers.map((t, i) => (
                                    <PillContainer key={i}>
                                        {getTagImagesCompanyById(t) ? (
                                            <>
                                                {!getTagImagesCompanyById(t).notShowIcon && (
                                                    <PillIcon bgColor={getTagImagesCompanyById(t).backgroundColor}>
                                                        <CloudImage image={getTagImagesCompanyById(t).imageUrl} height={20} />
                                                    </PillIcon>
                                                )}
                                                <PillText>
                                                    {getTagImagesCompanyById(t).caption && (
                                                        <>{t}</>
                                                    )}
                                                </PillText>
                                            </>
                                        ) : (
                                            <PillText>{t}</PillText>
                                        )}
                                    </PillContainer>
                                ))}
                            </>
                        )}
                    </PillRow>
                </BoxContainer>
            )}
            {(item.company_profile_association_profiles && item.company_profile_association_profiles.length > 0) && (
                <BoxContainer>
                    <CardLabel>Affiliations</CardLabel>
                    <PillRow>
                        {associations && associations.length > 0 && (
                            <>
                                {associations.map((t, i) => (
                                    <PillContainer key={i}>
                                        {getTagImagesCompanyById(t) ? (
                                            <>
                                                {!getTagImagesCompanyById(t).notShowIcon && (
                                                    <PillIcon bgColor={getTagImagesCompanyById(t).backgroundColor}>
                                                        <CloudImage image={getTagImagesCompanyById(t).imageUrl} height={20} />
                                                    </PillIcon>
                                                )}
                                                <PillText>
                                                    {getTagImagesCompanyById(t).caption && (
                                                        <>{t}</>
                                                    )}
                                                </PillText>
                                            </>
                                        ) : (
                                            <PillText>{t}</PillText>
                                        )}
                                    </PillContainer>
                                ))}
                            </>
                        )}
                    </PillRow>
                </BoxContainer>
            )}
            <BoxContainer>
                <CardLabel>Attributes</CardLabel>
                <PillRow>
                    {pills.length > 0 && (
                        pills.slice(0, 6) // Limit display to 7 items
                    )}
                </PillRow>
            </BoxContainer>
        </>
    )
}
