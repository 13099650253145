/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars
import ManagedContacts from './ManagedContacts';

const ManagedContactsContainer = (props) => {

    return(
        <ManagedContacts />
    );
};

export default ManagedContactsContainer;
