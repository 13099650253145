/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {createAccount} from "../accounts/managedAccountSlice";
import {getOrganizationById} from "./managedOrganizationSlice";

import CreateManageAccount from "../components/accounts/CreateManageAccount";

export default function OrganizationAddTeamMemberContainer(props) {
    const { match } = props;

    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Organization',
        href: '/admin/organizations',
        isActive: false
    }, {label: 'Add Organization Team Member', href: '', isActive: true}];

    const dispatch = useDispatch();

    const managedOrganizationState = useSelector(state => state.managedOrganization);
    const managedAccountState = useSelector(state => state.managedAccount);

    const history = useHistory();

    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [account, setAccount] = useState({
        organization: [],
        givenName: '',
        surName: '',
        email: '',
        role: { value: 'USER', label: 'User' },
        sendEmail: false
    });

    const reset = () => {
        resetAccount();
        setAlertMessage('');
        setSuccessMessage('');
    }

    const resetAccount = () => {
        setAccount({
            ...account,
            givenName: '',
            surName: '',
            email: '',
            role: { value: 'USER', label: 'User' },
            sendEmail: false
        });
    }

    useEffect( () => {
        if (match.params.id){
            dispatch(getOrganizationById(match.params.id));
        }
    }, [match.params.id]);

    useEffect( () => {
        setAccount({
            ...account,
            organization: managedOrganizationState.managedOrganization
        });
    }, [managedOrganizationState.managedOrganization]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedAccountState.successMessage) {
            setSuccessMessage(managedAccountState.successMessage);
            resetAccount();
        }
    }, [managedAccountState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedAccountState.alertMessage) {
            setAlertMessage(managedAccountState.alertMessage);
        }
    }, [managedAccountState.alertMessage]);

    const handleOnCreate = async (data) => {
        dispatch(createAccount(data));
    };

    const handleOnCancel = async () => {
        reset();
        history.push('/admin/organizations')
    };

    return (
        <CreateManageAccount
            breadcrumbItems={breadcrumbItems}
            account={account}
            handleOnCreate={handleOnCreate}
            handleOnCancel={handleOnCancel}
            loading={managedAccountState.loading || managedOrganizationState.loading}
            alertMessage={alertMessage}
            successMessage={successMessage}
        />
    )
}
