/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import InputsTable from "./InputsTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getInputsCount, getInputs
} from "./inputsSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

export default class Inputs extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showImportDialog: false,
            lastReload: Date.now()
        };
    }

    render() {
        return (
            <InputsComponent/>
        )
    }
}

function InputsComponent(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Inputs',
        href: '',
        isActive: true
    }];
    const inputsState = useSelector(state => state.inputs);
    const dispatch = useDispatch();
    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (inputsState.alertMessage) {
            setAlertMessage(inputsState.alertMessage);
            setShowAlert(true);
        }
    }, [inputsState.alertMessage]);

    useEffect(() => {
        dispatch(getInputsCount());
        dispatch(getInputs());
    }, [inputsState.reload === true]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }
    const getActionItems = () => {
        const actionItems = [
            {
                label: 'EDIT',
                href: '',
                isActive: inputsState?.selectedId.length === 1,
             onClickHandler: () => history.push(`/admin/inputs/${inputsState.selectedId[0]}/edit-input`)
            }
        ];   
        return actionItems;
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Inputs (${inputsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/inputs/create-input')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput placeholder={'Search by name, label or description...'}
                                   value={inputsState.searchCriteria}
                                   onKeyDown={handleOnKeyDown}
                                   onChange={(value) => {
                                       dispatch(setSearchCriteria(value));
                                   }}
                                   clearable
                                   clearableHandle={() => {
                                       dispatch(setSearchCriteria(''));
                                       setLastReload(Date.now());
                                   }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {inputsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <InputsTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
}
