import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedSource: null
};

export const getSourceById = createAsyncThunk('sources/getSourceById', async (id) => {
    const GET_MANAGED_SOURCE_BY_ID_URL =`${config.services.ATLAS.apiUrl}/managed-sources/${id}`;
    const source = await Api.get(true, GET_MANAGED_SOURCE_BY_ID_URL);
    return source;
});

export const editSource = createAsyncThunk('sources/editSource', async ({id, data}) => {
    const GET_MANAGED_SOURCE_BY_ID_URL = `${config.services.ATLAS.apiUrl}/managed-sources/${id}/edit`;
    console.log(data);
    const source = await Api.post(true, GET_MANAGED_SOURCE_BY_ID_URL, {data});
    return source;
});

const sourceSlice = createSlice({
    name: 'sourceSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedSource = null;
            return state;
        }
    },
    extraReducers: {
        [getSourceById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getSourceById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedSource = action.payload;
            }
        },
        [getSourceById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve the source';
        },
        [editSource.pending]: (state, action) => {
            state.loading = true;
        },
        [editSource.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedSource = action.payload;
            }
            state.successMessage = 'Successfully edit source';
        },
        [editSource.rejected]: (state, action) => {
            state.loading = false;
           if (action.error && action.error.message) {
             state.alertMessage = action.error.message;
            } else {
                state.alertMessage = 'Unable to edit an source';
            }

        }
    }
});


export const { clearState } = sourceSlice.actions;
export default sourceSlice.reducer;
