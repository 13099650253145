/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import Select from "react-select";

import FormLabel from "./FormLabel";
import styled from "styled-components";
import FormRequiredText from "./FormRequiredText";

export const SelectStyled = styled(Select) `
  div {
    border-radius: 0;
  }
`;

const FormSelect = (props) => {
    const {
        label,
        options,
        onChange,
        value,
        disabled,
        isClearable,
        placeholder,
        required,
        isInvalid,
        isMulti
    } = props;

    const customStyles = {
        control: (styles) => ({ ...styles, borderColor: '#c13727 !important' }),
    }

    return (
        <>
            {label && (
                <FormLabel>
                    {label}
                    {required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            )}
            <SelectStyled
                options={options}
                onChange={(v) => onChange(v)}
                value={value}
                isDisabled={disabled}
                isClearable={isClearable}
                placeholder={placeholder}
                styles={isInvalid ? customStyles : undefined}
                isMulti={isMulti}
                menuPortalTarget={document.body}
            />
        </>
    );
}

export default FormSelect;
