/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import superagent from 'superagent';

import config from '../config';
import {supportsLocalStorage} from './Utils';
import * as cache from './Cache';

// eslint-disable-next-line
export async function post(authenticated, endpoint, requestBody, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
            if (_token) {
                try {
                    const res = await superagent
                        .post(`${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null)
                        .set('x-api-jwt', _jwt);

                    if (res.body && res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        ForceLogout();
                    }
                    // console.log(err.response.body);
                    throw err;
                }
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .post(`${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json');

                if (res.body && res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    ForceLogout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

// eslint-disable-next-line
export async function get(authenticated, endpoint, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
            if (_token) {
                try {
                    const res = await superagent
                        .get(`${endpoint}`)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null)
                        .set('x-api-jwt', _jwt);

                    if (res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        ForceLogout();
                    }
                    // console.error(err);
                    throw err;
                }
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .get(`${endpoint}`)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json');

                if (res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    ForceLogout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

export async function patch(authenticated, endpoint, requestBody, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
            if (_token) {
                try {
                    const res = await superagent
                        .patch(`${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null)
                        .set('x-api-jwt', _jwt);

                    if (res.body && res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        ForceLogout();
                    }
                    // console.log(err.response.body);
                    throw err;
                }
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .patch(`${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json');

                if (res.body && res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    ForceLogout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

export async function upload(authenticated, endpoint, file, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        if (authenticated) {
            // TODO: add some utils for encoding / decoding tokens
            const _token = cache.get(config.constants.CACHE_KEY_TOKEN);
            const _apiKey = cache.get(config.constants.CACHE_KEY_API);
            const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
            if (_token) {
                try {
                    const res = await superagent
                        .post(`${endpoint}`)
                        .attach('file', file)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Authorization', _token.id)
                        .set('x-api-key', _apiKey ? _apiKey.key : null)
                        .set('x-api-jwt', _jwt);

                    if (res.body && res.body.error) {
                        // console.error(res.body.error);
                        throw new Error(res.body.error);
                    }

                    return res.body;
                } catch (err) {
                    if (err && err.status === 401 && logoutOn401) {
                        // TODO: is there a need to defer?
                        // AccountActions.logout();
                        ForceLogout();
                    }
                    // console.log(err.response.body);
                    throw err;
                }
            } else {
                throw new Error('Unable to make request, Authentication Required');
            }
        } else {
            try {
                const res = await superagent
                    .post(`${endpoint}`)
                    .attach(file.name, file)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'multipart/form-data');

                if (res.body && res.body.error) {
                    // console.error(res.body.error);
                    throw new Error(res.body.error);
                }

                return res.body;
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    // AccountActions.logout();
                    ForceLogout();
                } else {
                    // console.error(err);
                    throw err;
                }
            }
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

export function generateQueryString(query) {
    const queryKeys = Object.keys(query);
    const queryString = queryKeys.map((key, idx) => {
        const value = query[key];
        if (value === undefined) {
            return '';
        }
        if (idx === 0) {
            return `${encodeURIComponent(key)}=${typeof value === 'object' ? encodeURIComponent(JSON.stringify(value)) : encodeURIComponent(value)}`;
        }
        return `&${encodeURIComponent(key)}=${typeof value === 'object' ? encodeURIComponent(JSON.stringify(value)) : encodeURIComponent(value)}`;
    }).join('');
    return queryString;
}

export const ForceLogout = () => {
    cache.del(config.constants.CACHE_KEY_TOKEN);
    cache.del(config.constants.CACHE_KEY_ACCOUNT);
    cache.del(config.constants.CACHE_KEY_API);
    cache.del(config.constants.CACHE_KEY_JWT);

    window.location.replace('/');
}
