import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector } from "react-redux";
import ColumnCollapse from "../../ColumnCollapse";
import CollapsePanel from "../../../../../common/components/CollapsePanel";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { GridButton, DataGridContainer } from '../../StyledComponents';
import { getContactNameById, changeContactNameById } from './slices/contactNameSlice';
import { getContactTitleById, changeContactTitleById } from './slices/contactTitleSlice';
import { getContactPhoneById, changeContactPhoneById } from './slices/contactPhoneSlice';
import { getContactEmailById, changeContactEmailById, createContactEmailById } from './slices/contactEmailSlice';
import { Form, Row, Col } from 'react-bootstrap';
import FormTextInput from "../../../../../common/components/FormTextInput";
import FormContainer from "../../../../../common/components/layout/FormContainer";

const ContactNameComponent = (props) => {
    const { 
        contactId, 
        getAttributeRows, 
        attributeColumns,
        isCallFromCompany =false,
    } = props;

    const contactNameState = useSelector(state => state.contactName);

    const dispatch = useDispatch();

    const [contactName, setContactName] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [honorificInput, setHonorificInput] = useState();
    const [givenNameInput, setGivenNameInput] = useState();
    const [middleNameInput, setMiddleNameInput] = useState();
    const [surnameInput, setSurnameInput] = useState();
    const [maturitySuffixInput, setMaturitySuffixInput] = useState();
    const [nameInput, setNameInput]=useState(nameInput);
    const [isUpdateContactName, setUpdateContactName]=useState(false);
    const [isSaveName, setSaveName]=useState(false); 
    const [isCancelName, setCancelName]=useState(false)
    const [isShowGrid, setShowGrid]=useState(false); 
    const [isShowActionButton, setShowActionButton]=useState(false);
    const [isFormValid, setFormValid] =useState(true);    
    
    useEffect(() => {
        if(contactId && contactId!=='')
        {
            dispatch(getContactNameById(contactId));
        }
    }, [contactId]);    

    useEffect(() => {
        if(!contactNameState)
        {
         setContactName('');
        }
        if (contactNameState.contactName) {
            setContactName(contactNameState.contactName);
            if (contactName) {
               setHonorificInput(contactName.honorific);
               setGivenNameInput(contactName.givenName);
               setMiddleNameInput(contactName.middleName);
               setSurnameInput(contactName.surname);
               setMaturitySuffixInput(contactName.maturitySuffix);
            }
               setShowGrid(true);
               setShowActionButton(true);
        }
    }, [contactNameState.contactName]);
    useEffect(() => {
        if (contactName) {
            const nonstandardAttributes = [
                {property: 'id', value: contactName.id},
                {property: 'contactId', value: contactName.contactId},
                {property: 'honorific', value: contactName.honorific},
                {property: 'givenName', value: contactName.givenName},
                {property: 'middlename', value: contactName.middleName},
                {property: 'surname', value: contactName.surname},
                {property: 'maturitySuffix', value: contactName.maturitySuffix},
                {property: 'professionalSuffix', value: contactName.professionalSuffix},
                {property: '_name', value: contactName._name},
                {property: '_honorific', value: contactName._honorific},
                {property: '_givenName', value: contactName._givenName},
                {property: '_middleName', value: contactName._middleName},
                {property: '_surname', value: contactName._surname},
                {property: '_maturitySuffix', value: contactName._maturitySuffix},
                {property: '_professionalSuffix', value: contactName._professionalSuffix},
                {property: 'parseStrategies', value: contactName.parseStrategies},
                {property: 'parseStrategy', value: contactName.parseStrategy}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, contactName));
        }
    }, [contactName, givenNameInput, middleNameInput, surnameInput, maturitySuffixInput]);

    
    const onChange = (e) => {               
        setUpdateContactName(true);
        setSaveName(true);
        setCancelName(true);
        setShowActionButton(false);
        setShowGrid(false);
        e.preventDefault();        
        if (contactName) {
           setHonorificInput(contactName.honorific);
           setGivenNameInput(contactName.givenName);
           setMiddleNameInput(contactName.middleName);
           setSurnameInput(contactName.surname);
           setMaturitySuffixInput(contactName.maturitySuffix);
        }  
        Object.keys(error).forEach((x) => {
            const errObj = error[x];            
            if (errObj.errorMsg) {              
              onInputValidate(false, x);
            } 
          });      
    }

    const validateForm = () => {
        let isInvalid = false;        
        Object.keys(error).forEach((x) => {
          const errObj = error[x];          
          if (errObj.errorMsg) {           
            isInvalid = true;
            onInputValidate(true, x);
          } 
        });
        return !isInvalid;
      };
    const saveNewNameOnClick = (e) => {        
        e.preventDefault();      
        const isValid = validateForm();
        if (!isValid) {
            console.error('Invalid Form!');
            setFormValid(false);            
            return;
          }
        dispatch(changeContactNameById({id: contactId, body: {data: {honorific: honorificInput,givenName: givenNameInput,middleName: middleNameInput,surname: surnameInput,maturitySuffix: surnameInput}}}));
        setShowActionButton(true);             
        setSaveName(false);
        setUpdateContactName(false);
        setShowGrid(true);
        setCancelName(false);
    }
        const cancelNewNameOnClick = (e) => {
        e.preventDefault(); 
        setShowActionButton(true);          
        setCancelName(false);
        setUpdateContactName(false);
        setShowGrid(true); 
        setSaveName(false);       
        
    } 
    const onInputValidate = (value, name) => {        
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };
     
      const [error, setError] = useState({        
        honorific: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
          },
        givenName: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
          },
        middleName: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
          },
        surName: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
          },
      }); 
    
    return (<>
        <ColumnCollapse header={'CONTACT NAME'} isOpen={isCallFromCompany}>
        <CollapsePanel header={'ATTRIBUTE'} isShowActionButtons={isShowActionButton}
         isSaveValueButton={isSaveName} isCancelValueButton={isCancelName} onSaveValue={saveNewNameOnClick}  onCancelValue={cancelNewNameOnClick} 
         onChange={onChange} isOpen={true}>       
         {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
                :<> </>}  
                {isUpdateContactName && (<> 
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput">  
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput 
                                            style={{ marginTop: '20px'}}  
                                            title={'honorific'}                                                               
                                            label={'Honorific'}  
                                            name={'honorific'}                                          
                                            value={honorificInput}                                            
                                            onChange={value => setHonorificInput(value)}
                                            isInvalid={!isFormValid && !honorificInput}
                                            size={'sm'}
                                            required
                                            reqType={'TITLE'}
                                            {...error.honorific}                                          
                                        />
                                    </Col>
                                </Row>                                                             
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput 
                                            style={{ marginTop: '2px'}}  
                                            title={'Given Name'}                      
                                            label={'Given Name'}
                                            name={'givenName'}
                                            value={givenNameInput}
                                            onChange={value => setGivenNameInput(value)}
                                            isInvalid={!isFormValid && !givenNameInput}
                                            size={'sm'}  
                                            required          
                                            reqType={'TITLE'}                                 
                                            {...error.givenName}
                                        />
                                    </Col>
                                </Row>                                                     
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput 
                                            style={{ marginTop: '2px'}}  
                                            title={'MiddleName'}                      
                                            label={'Middle Name'}
                                            name={'middleName'}
                                            value={middleNameInput}
                                            onChange={value => setMiddleNameInput(value)}
                                            isInvalid={!isFormValid && !middleNameInput}
                                            size={'sm'}   
                                            required          
                                            reqType={'TITLE'}                                
                                            {...error.middleName}
                                        />
                                    </Col>
                                </Row>                                         
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput
                                            style={{ marginTop: '2px'}}
                                            title={'Surname'}                       
                                            label={'Surname'}
                                            name={'surName'}
                                            value={surnameInput}
                                            onChange={value => setSurnameInput(value)}
                                            isInvalid={!isFormValid && !surnameInput}
                                            size={'sm'} 
                                            required
                                            reqType={'TITLE'}
                                            {...error.surName}
                                        />
                                    </Col>
                                </Row>               
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput 
                                            style={{ marginTop: '2px'}}                         
                                            label={'Maturity Suffix'}
                                            value={surnameInput}
                                            onChange={value => setMaturitySuffixInput(value)}
                                            size={'sm'}
                                            required                  
                                        />
                                    </Col>
                                </Row> 
                            </Form.Group>
                        </Form>
                    </FormContainer>
                </> )}                
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    </>);
}

const ContactTitleComponent = (props) => {
    const { 
        contactId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const contactTitleState = useSelector(state => state.contactTitle);

    const dispatch = useDispatch();

    const [contactTitle, setContactTitle] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [titleInput, setTitleInput] = useState(titleInput);
    const [isAddNewTitle, setAddNewTitle]=useState(false);
    const [isSaveTitle, setSaveTitle]=useState(false); 
    const [isCancelTitle, setCancelTitle]=useState(false);
    const [isShowGrid, setShowGrid]=useState(false); 
    const [isShowActionButton, setShowActionButton]=useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isTitleValid,setTitleValid]= useState(false);

    const changeOnClick = () => {
        if (titleInput !== contactTitle.title && (contactId && contactId!=='')) {
            dispatch(changeContactTitleById({id: contactId, body: {data: {title: titleInput}}}));
        }
    }
    const isInvalid = () => {       
        return isTitleValid;
    };   
   
    useEffect(() => {
        if(contactId && contactId!=='')
        {
            dispatch(getContactTitleById(contactId));
        }        
    }, [contactId]);

    useEffect(() => {
        if(!contactTitleState)
        {
         setContactTitle('');
        }
        if (contactTitleState.contactTitle) {
            setContactTitle(contactTitleState.contactTitle);
            setShowGrid(true);
            setShowActionButton(true);
        }
    }, [contactTitleState.contactTitle]);

    useEffect(() => {
        if (contactTitle) {
            setTitleInput(contactTitle.title);
        }
    }, [contactTitle]);

    useEffect(() => {
        if (contactTitle) {
            const nonstandardAttributes = [
                {property: 'id', value: contactTitle.id},
                {property: 'contactId', value: contactTitle.contactId},
                {property: 'title', value: contactTitle.title},
                {property: 'titleFunction', value: contactTitle.titleFunction},
                {property: 'titleLevel', value: contactTitle.titleLevel},
                {property: '_title', value: contactTitle._title},
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, contactTitle));
        }
    }, [contactTitle, titleInput]);
    const onChange = (e) => {
        e.preventDefault();       
        setAddNewTitle(true);
        setCancelTitle(true);
        setShowGrid(false);
        setSaveTitle(true);
        setShowActionButton(false);
        return false;
    }
    const saveNewTitleOnClick = (e) => {
        e.preventDefault(); 
        if (isInvalid()) {
            setFormValid(false);             
        } 
        else{
                if(contactTitle)
                {
                    if (titleInput !== contactTitle.title) {
                        dispatch(changeContactTitleById({id: contactId, body: {data: {title: titleInput}}}));            
                    }                         
                } 
                setSaveTitle(false);
                setAddNewTitle(false);
                setCancelTitle(false);
                setShowGrid(true);
                setShowActionButton(true); 
            }      
        
         }
    const cancelNewTitleOnClick = (e) => {
        e.preventDefault(); 
        setShowActionButton(true);          
        setCancelTitle(false);
        setAddNewTitle(false);
        setShowGrid(true); 
        setSaveTitle(false); 
    } 
    const onInputValidate = (value, name) => {    
        if(value)     
        {
            setTitleValid(true);
        }else{
            setTitleValid(false);
        }
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };
     
      const [error, setError] = useState({        
        contactTitle: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      }); 
    return (<>
        <ColumnCollapse header={'CONTACT TITLE'}>
            <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={isShowActionButton} 
             isSaveValueButton={isSaveTitle} onSaveValue={saveNewTitleOnClick} isCancelValueButton={isCancelTitle}
             onCancelValue={cancelNewTitleOnClick} 
              onChange={onChange}>
              {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
             :<> </> }
                {isAddNewTitle && (<> 
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput" className={'coreattribute-mt20'}>                           
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput 
                                         label={'Title'}
                                         title={'title'}
                                         name={'contactTitle'}
                                         placeholder="Enter title"
                                         value={titleInput}
                                         onChange={value => setTitleInput(value)}
                                         isInvalid={!formValid}                                         
                                         size={'sm'} 
                                         reqType={'TITLE'}
                                         required
                                         {...error.contactTitle}               
                                       />
                                    </Col>
                                </Row>                                
                            </Form.Group>
                        </Form>
                    </FormContainer>
                </> )}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    </>);
}

const ContactPhoneComponent = (props) => {
    const { 
        contactId, 
        getAttributeRows, 
        attributeColumns 
    } = props;

    const contactPhoneState = useSelector(state => state.contactPhone);

    const dispatch = useDispatch();

    const [contactPhone, setContactPhone] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [phoneInput, setPhoneInput] = useState(phoneInput);
    const [isAddNewPhone,setAddNewPhone] =useState(false);
    const [isShowGrid,setShowGrid] = useState(false);
    const [isSavePhone,setSavePhone] = useState(false);
    const [isShowActionButton,setShowActionButton] = useState(false);
    const [isEnableAddValueButton,setEnableAddValueButton] = useState(false);
    const [formValid,setFormValid] = useState(true);
    const [isPhoneValid, setPhoneValid]= useState(false);

    const changeOnClick = () => {
        if (phoneInput !== contactPhone.phone && (contactId && contactId!=='')) {
            dispatch(changeContactPhoneById({id: contactId, body: {data: {phone: phoneInput}}}));
        }
    }
    
    const isInvalid = () => {       
        return isPhoneValid;
    }; 

    useEffect(() => {
        if(contactId && contactId!=='')
        {
            dispatch(getContactPhoneById(contactId));
        } 
       
    }, [contactId]);
        if(!contactPhoneState)
        {
            setEnableAddValueButton(true);
            setContactPhone('');
        }

    useEffect(() => {
        if (contactPhoneState.contactPhone) {
            setContactPhone(contactPhoneState.contactPhone);
            setEnableAddValueButton(false);
            setShowGrid(true);
            setShowActionButton(true);
        }
        else{
            setEnableAddValueButton(true);
        }
    }, [contactPhoneState.contactPhone]);

    useEffect(() => {
        if (contactPhone) {
            setPhoneInput(contactPhone.phone);
       }
    }, [contactPhone]);

    useEffect(() => {
        if (contactPhone) {
            const nonstandardAttributes = [
                {property: 'id', value: contactPhone.id},
                {property: 'contactId', value: contactPhone.contactId},
                {property: 'phone', value: contactPhone.phone},
                {property: 'e164Format', value: contactPhone.e164Form},
                {property: 'internationalFormat', value: contactPhone.internationalFormat},
                {property: 'nationalFormat', value: contactPhone.nationalFormat},
                {property: 'countryCode', value: contactPhone.countryCode},
                {property: 'countryPrefix', value: contactPhone.countryPrefix},
                {property: 'countryName', value: contactPhone.countryName},
                {property: 'carrier', value: contactPhone.carrier},
                {property: 'lineType', value: contactPhone.lineType},
                {property: 'public', value: contactPhone.public ? 'true' : 'false'},
                {property: '_phone', value: contactPhone._phone}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, contactPhone));
        }
    }, [contactPhone, phoneInput]);

    const onChange = (e) => {
        e.preventDefault();
        setAddNewPhone(true);
        setShowGrid(false);
        setSavePhone(true);
        setShowActionButton(false);        
        return false;
    }
    const saveNewPhoneOnClick = (e) => {
        e.preventDefault();
        
        if (isInvalid()) {
            setFormValid(false);
        }
        else{
            if(contactPhone)
            {
              if (phoneInput !== contactPhone.phone) {
                  dispatch(changeContactPhoneById({id: contactId, body: {data: {phone: phoneInput}}}));
              }
              setShowActionButton(true);
            }
              setSavePhone(false);
              setAddNewPhone(false);
              setShowGrid(true);
        }

    }
    const onInputValidate = (value, name) => {
        if(value)     
        {
            setPhoneValid(true);
        }else{
            setPhoneValid(false);
        }
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };

      const [error, setError] = useState({
          contactPhone: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      });

    return (<>
        <ColumnCollapse header={'CONTACT PHONE'}>
        <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={isShowActionButton} isShowAddValueButton={isEnableAddValueButton}
            isSaveValueButton={isSavePhone} onSaveValue={saveNewPhoneOnClick}
            onChange={onChange}>
                {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
                :<></>}
                {isAddNewPhone && (<>
                    <FormContainer>
                        <Form noValidate>
                            <Form.Group controlId="createInput">
                                <Row>
                                    <Col md={6}>
                                        <FormTextInput
                                            style={{marginTop:'30px'}}
                                            title={'phone'}
                                            label={'Phone'}
                                            name={'contactPhone'}
                                            placeholder="Enter Phone"
                                            value={phoneInput}
                                            onChange={value => setPhoneInput(value)}
                                            isInvalid={!formValid && !phoneInput}
                                            size={'sm'}
                                            reqType={'PHONE'}
                                            required

                                            {...error.contactPhone}
                                        />
                                    </Col>
                                </Row>
                                </Form.Group>
                    </Form>
                </FormContainer>
                </> )}
            </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    </>);
}

const ContactEmailComponent = (props) => {
    const { 
        contactId, 
        getAttributeRows, 
        attributeColumns 
    } = props;
    
    const contactEmailState = useSelector(state => state.contactEmail);
    
    const dispatch = useDispatch();
    
    const [contactEmail, setContactEmail] = useState();
    const [attributeRows, setAttributeRows] = useState([]);
    const [emailInput, setEmailInput] = useState(emailInput);
    const [isAddNewEmail, setAddNewEmail]=useState(false);
    const [isSaveEmail, setSaveEmail]=useState(false);
    const [isCancelEmail, setCancelEmail]=useState(false);
    const [isShowGrid, setShowGrid]=useState(false);
    const [isShowActionButton, setShowActionButton]=useState(false)
    const [formValid, setFormValid] = useState(true);
    const [isEnableAddValueButton, setEnableAddValueButton]= useState(false);    

    const changeOnClick = () => {
        if (emailInput !== contactEmail.email && (contactId && contactId!=='')) {
            dispatch(changeContactEmailById({id: contactId, body: {data: {email: emailInput}}}));
        }
    }
    
    useEffect(() => {
        if(contactId && contactId!=='')
        {
            dispatch(getContactEmailById(contactId));
        }        
    }, [contactId]);
    
    useEffect(() => {
        if(!contactEmailState)
        {
            setEnableAddValueButton(true);
             setContactEmail('');
        }
        if (contactEmailState.contactEmail) {
            setContactEmail(contactEmailState.contactEmail);
            setEnableAddValueButton(false);
            setShowGrid(true);
            setShowActionButton(true);
        }
        else{
            setEnableAddValueButton(true);
        }
    }, [contactEmailState.contactEmail]);

    useEffect(() => {
        if (contactEmail) {
            setEmailInput(contactEmail.email);
        }
    }, [contactEmail]);
    
    useEffect(() => {
        if (contactEmail) {
            const nonstandardAttributes = [
                {property: 'id', value: contactEmail.id},
                {property: 'contactId', value: contactEmail.contactId},
                {property: 'email', value: contactEmail.email},
                {property: 'localPart', value: contactEmail.localPart},
                {property: 'domain', value: contactEmail.domain},
                {property: 'pattern', value: contactEmail.pattern},
                {property: 'formatValid', value: contactEmail.formatValid ? 'true' : 'false'},
                {property: 'mxFound', value: contactEmail.mxFound ? 'true' : 'false'},
                {property: 'smtpFound', value: contactEmail.smtpFound ? 'true' : 'false'},
                {property: 'catchAll', value: contactEmail.catchAll ? 'true' : 'false'},
                {property: 'disposable', value: contactEmail.disposable ? 'true' : 'false'},
                {property: 'free', value: contactEmail.free ? 'true' : 'false'},
                {property: 'role', value: contactEmail.role ? 'true' : 'false'},
                {property: 'public', value: contactEmail.public ? 'true' : 'false'},
                {property: '_email', value: contactEmail._email}
            ];
            setAttributeRows(getAttributeRows(nonstandardAttributes, contactEmail));
        }
    }, [contactEmail, emailInput]);
    const addValueOnClick = (e) => {
        e.preventDefault();
        setAddNewEmail(true);
        setSaveEmail(true);
        setCancelEmail(true);
        setShowGrid(false);
        setEnableAddValueButton(false);
        setShowActionButton(false);               
        return false;
    }
    const onChange = (e) => {
        e.preventDefault();
        setAddNewEmail(true);
        setCancelEmail(true);
        setShowGrid(false);
        setSaveEmail(true);
        setShowActionButton(false);            
        if(contactEmail){
            setEmailInput(contactEmail.email)
        }   
        Object.keys(error).forEach((x) => {
            const errObj = error[x];            
            if (errObj.errorMsg) {              
              onInputValidate(false, x);
            } 
          }); 
        return false;
    }
        
    const validateForm = () => {
        let isInvalid = false;        
        Object.keys(error).forEach((x) => {
          const errObj = error[x];          
          if (errObj.errorMsg) {           
            isInvalid = true;
            onInputValidate(true, x);
          } 
        });
        return !isInvalid;
      };
    const saveNewEmailOnClick = (e) => { 
        e.preventDefault();           
        const isValid = validateForm();
        if (!isValid) {
            console.error('Invalid Form!');
            setFormValid(false);            
            return;
          }
             
        if(contactEmail)
        {            
        if (emailInput !== contactEmail.email) {
                dispatch(changeContactEmailById({id: contactId, body: {data: {email: emailInput}}}));
            }
        }       
        else if(emailInput) {
                dispatch(createContactEmailById({id: contactId, body: {data: {email: emailInput}}}));                              
            }        
            setAddNewEmail(false);
            setSaveEmail(false);
            setShowGrid(true);
            setShowActionButton(true); 
            setCancelEmail(false);
        }
        const cancelNewEmailOnClick = (e) => {
            e.preventDefault(); 
            setShowActionButton(true);          
            setCancelEmail(false);
            setAddNewEmail(false);
            setShowGrid(true); 
            setSaveEmail(false); 
        }    
     
    const onInputValidate = (value, name) => {     
        setError((prev) => ({
          ...prev,
          [name]: { ...prev[name], errorMsg: value },
        }));
      };
     
      const [error, setError] = useState({        
          contactEmail: {
          isReq: true,
          errorMsg: '',
          onValidateFunc: onInputValidate,
        },
      });  
    
    return (<>
        <ColumnCollapse header={'CONTACT EMAIL'}>
        <CollapsePanel header={'ATTRIBUTE'} isOpen={true} isShowActionButtons={isShowActionButton}  isShowAddValueButton={isEnableAddValueButton}
            onAddValue={addValueOnClick}  isSaveValueButton={isSaveEmail} onSaveValue={saveNewEmailOnClick}
            isCancelValueButton={isCancelEmail} onCancelValue={cancelNewEmailOnClick}
            onChange={onChange}>
            {isShowGrid ?
                <DataGridContainer>
                    <DataGrid autoHeight columns={attributeColumns} rows={attributeRows} />
                </DataGridContainer>
                :<> </> }
                    {isAddNewEmail && (<>
                        <FormContainer>
                            <Form noValidate>
                                <Form.Group controlId="createInput">
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput 
                                             style={{ marginTop:'20px'}}  
                                             title={'Email'}                     
                                             label={'Email'}
                                             name={'contactEmail'}
                                             placeholder="Enter email"
                                             value={emailInput}
                                             onChange={value => setEmailInput(value)}
                                             isInvalid={!formValid && !emailInput}
                                             size={'sm'}
                                             reqType={"EMAIL"}
                                             required
                                             {...error.contactEmail}
                                           />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </FormContainer>
                    </> )}
        </CollapsePanel>
            <CollapsePanel header={'CANDIDATES'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'HISTORY'} isOpen={true} btnVisible={false}></CollapsePanel>
            <CollapsePanel header={'RECORDS'} isOpen={true} btnVisible={false}></CollapsePanel>
        </ColumnCollapse>
    </>);
}

const ContactCoreAttributes = (props) => {
    const {             
        isCallFromCompany =false,
    } = props;
    const [show, setShow] = useState(true);

    useEffect(() => {
        setShow(false);
    }, [props.contactId]);

    useEffect(() => {
        if (!show) {
            setShow(true);
        }
    }, [show]);

    return (<>
        {show && <>        
            <ContactNameComponent isCallFromCompany={isCallFromCompany} {...props} />
            <ContactTitleComponent {...props} />
            <ContactPhoneComponent {...props} /> 
            <ContactEmailComponent {...props} />
        </>}
    </>);
}

export default ContactCoreAttributes;