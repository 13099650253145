/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from "react";
import styled from "styled-components";

import Dropdown from "react-bootstrap/Dropdown";

import {APP_COLORS} from "../../common/Constants";
import './ExploreAccuracyCofig.scss';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/pro-solid-svg-icons";
import * as cache from "../../common/Cache";
import config from "../../config";

const ExploreAccuracyConfigContainer = styled.div `
  justify-self: end;
  margin-right: 5px;
  margin-top: 2px;
  display: flex;
  align-items: center;
`;

const ExploreAccuracyConfig = (props) => {
    const {
        accuracyData,
        onChange,
    } = props;

    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    const allowUnfiltered = account.organization.config.scores.allowUnfiltered;
    const orgScores = account.organization.config.scores;

    const accuracyGrades = [
        {key: 'aPlus', label: 'A+', display: 'A+ (.9 or better)', color: APP_COLORS.green, minScore: 0.9, maxScore: 1},
        {key: 'bPlus', label: 'B+', display: 'B+ (.8 or better)', color: APP_COLORS.green, minScore: 0.8, maxScore: 1},
        {key: 'cPlus', label: 'C+', display: 'C+ (.7 or better)', color: APP_COLORS.yellow, minScore: 0.7, maxScore: 1},
        {key: 'dPlus', label: 'D+', display: 'D+ (.6 or better)', color: APP_COLORS.red, minScore: 0.6, maxScore: 1},
        {key: 'all', label: 'All', display: 'All (unfiltered)', color: APP_COLORS.red, minScore: 0, maxScore: 1}
    ]
    const [accuracyGrade, setAccuracyGrade] = useState(accuracyData ? accuracyData : accuracyGrades[1]);

    const onClickAccuracyGrade = (e, idx) => {
        // console.log(accuracyGrades[idx]);
        setAccuracyGrade(accuracyGrades[idx])
        onChange(accuracyGrades[idx]);
    };

    useEffect( () => {
        if (!accuracyData) {
            const currentAG = accuracyGrades.find(ag => ag.minScore <= orgScores.companyScore);
            setAccuracyGrade(currentAG);
        } else {
            setAccuracyGrade(accuracyData ? accuracyData : accuracyGrades[1]);
        }
    }, [accuracyData])

    return (
        <ExploreAccuracyConfigContainer>
            <span style={{
                color: APP_COLORS['power-blue'],
                fontSize: '1rem',
                fontWeight: 'bold',
            }}>SCORE</span>
            <Dropdown>
                <Dropdown.Toggle
                    variant="link"
                    id="explore-accuracy-score-dropdown-toggle"
                    style={{
                        color: accuracyGrade.color,
                        // fontStyle: 'italic',
                    }}
                >
                    {accuracyGrade.label}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    alignRight
                >
                    {accuracyGrades.map((ag, idx) => (
                        ag.key === 'all' && !allowUnfiltered ?
                            <Dropdown.Item
                                id={`explore-accuracy-score-dropdown-item-${ag.key}`}
                                key={`explore-accuracy-score-dropdown-item-${idx}`}
                                as="button"
                                disabled
                                onClick={(e) => onClickAccuracyGrade(e, idx)}
                            >{ag.display}&nbsp;
                                <FontAwesomeIcon
                                    color={APP_COLORS['gray-500']}
                                    icon={faLock}
                                />
                            </Dropdown.Item>
                            :
                            <Dropdown.Item
                                id={`explore-accuracy-score-dropdown-item-${ag.key}`}
                                key={`explore-accuracy-score-dropdown-item-${idx}`}
                                as="button"
                                onClick={(e) => onClickAccuracyGrade(e, idx)}
                            >{ag.display}
                            </Dropdown.Item>
                    )) }
                </Dropdown.Menu>
            </Dropdown>
        </ExploreAccuracyConfigContainer>
    );
}

export default ExploreAccuracyConfig;
