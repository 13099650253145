/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import React from "react";
import styled from "styled-components";
import CardLabel from "../../../../organization/explore/components/cards/CardLabel";
import {APP_COLORS} from "../../../../common/Constants";
import {getTagImagesCompanyById} from "../../../../common/components/TagImagesCompany";
import {CloudImage} from "../../../../common/components/TagImagesCloudView";

const PillIcon = styled.span `
  padding-left: 5px;
`;

const BoxContainer = styled.div `
  display: grid;
  grid-template-rows: 25px minmax(25px, max-content);
  padding: 0 10px 0 0;
`;

const PillRow = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
`;

const PillContainer = styled.div `
  border-radius: 5px;
  border: 1px solid ${APP_COLORS['gray-card']};
  margin: 3px;
  background-color: ${APP_COLORS['gray-100']};
`;

const PillText = styled.span `
  font-size: 14px;
  padding: 5px;
  // padding-left: 5px;
  // padding-right: 5px;
  color: ${APP_COLORS['tech-blue']};
  font-weight: 600;
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

export default function CompanyManufacturersBox(props) {
    const {
        item
    } = props;

    let manufacturers = item.companyProfileManufacturers;

    return (
        <BoxContainer>
            <CardLabel>Manufacturers</CardLabel>
            <PillRow>
                {(manufacturers && manufacturers.length > 0) ? (
                    <>
                        {manufacturers.map((t, i) => (
                            <PillContainer key={i}>
                                {getTagImagesCompanyById(t) && (
                                    <PillIcon>
                                        <CloudImage image={getTagImagesCompanyById(t).imageUrl} height={20} />
                                    </PillIcon>
                                )}
                                <PillText>{t}</PillText>
                            </PillContainer>
                        ))}
                    </>
                ) : <SubText>No manufacturers for this company.</SubText>}
            </PillRow>
        </BoxContainer>
    )
}
