/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react'; // eslint-disable-line no-unused-vars
import _, {find} from 'lodash';

import Explore from './Explore';
import {useDispatch, useSelector} from "react-redux";
import {setExploreMode} from "./exploreSlice";

import {
    clearAllFilter as clearAllCompanyFilter,
    searchCompanyByCriteria,
    countCompanyAndContactByCriteria,
    replaceFilter as replaceCompanyFilter, setAccuracyData,
} from "./companySlice";

import {
    clearAllFilter as clearAllContactFilter,
    searchContactByCriteria,
    countCompanyAndContactByCriteria as countContactAndCompanyByCriteria,
    replaceFilter as replaceContactFilter, setAccuracyContactData,
} from "./contactSlice";
import ScreenLockOverlay from "../../common/components/ScreenLockOverlay";

export default function ExploreContainer() {
    const dispatch = useDispatch();

    const accountState = useSelector(state => state.account);
    const exploreDataState = useSelector(state => state.explore);
    const companyDataState = useSelector(state => state.company);
    const contactDataState = useSelector(state => state.contact);
    const searchCriteriaState = useSelector(state => state.searchData);

    const [noServiceSearch, setNoServiceSearch] = useState(false);
    const [canExport, setCanExport] = useState(false);

    const handleClearAllFilter = () => {
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            return dispatch(clearAllCompanyFilter());
        }
        return dispatch(clearAllContactFilter());
    };
    const handleSearch = async () => {
        // Company
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            // Execute Queries to Support Map View
            dispatch(countCompanyAndContactByCriteria({filters: companyDataState.filters, exploreMode: exploreDataState.exploreMode}));
            // Execute Queries to Support List View
            dispatch(searchCompanyByCriteria({filters: companyDataState.filters, exploreMode: exploreDataState.exploreMode}));
            return;
        }
        // Contact
        // Execute Queries to Support Map View
        dispatch(countContactAndCompanyByCriteria({filters: contactDataState.filters, exploreMode: exploreDataState.exploreMode}));
        // Execute Queries to Support List View
        dispatch(searchContactByCriteria({filters: contactDataState.filters, exploreMode: exploreDataState.exploreMode}));
    };


    const handleToggleFilter = (exploreMode) => {
        dispatch(setExploreMode(exploreMode));
    };
    const hasFilter = exploreDataState.exploreMode.mode === 'COMPANY' ?
        companyDataState.filters.length > 0 :
        contactDataState.filters.length > 0;

    const getContactCount = () => {
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            return companyDataState.totalContacts;
        }
        return contactDataState.totalContacts;
    };
    const getCompaniesCount = () => {
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            return companyDataState.totalCompanies;
        }
        return contactDataState.totalCompanies;
    };

    const getCurrentFilters = () => {
        return exploreDataState.exploreMode.mode === 'COMPANY' ? companyDataState.filters : contactDataState.filters;
    };

    const getAppliedFilters = () => {
        return exploreDataState.exploreMode.mode === 'COMPANY' ? companyDataState.appliedFilters : contactDataState.appliedFilters;
    };

    const handleOnSelectSearchCriteria = (search) => {
        const selectedSearch = find(searchCriteriaState.searchCriteria, _search => _search.id === search.id);
        const filters = selectedSearch?.query?.filters?.value;
        const type = selectedSearch?.query?.type;
        if (type === 'SEARCH_COMPANY') {
            dispatch(replaceCompanyFilter(filters));
            dispatch(searchCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(countCompanyAndContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(setExploreMode({
                ...exploreDataState.exploreMode,
                mode: 'COMPANY',
                company: {
                    ...exploreDataState.exploreMode.company,
                    sections: {
                        ...exploreDataState.exploreMode.company.sections,
                    }
                }
            }));
            return;
        }
        if (type === 'SEARCH_CONTACT') {
            dispatch(replaceContactFilter(filters));
            dispatch(searchContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(countContactAndCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(setExploreMode({
                ...exploreDataState.exploreMode,
                mode: 'CONTACT',
                contact: {
                    ...exploreDataState.exploreMode.contact,
                    sections: {
                        ...exploreDataState.exploreMode.contact.sections,
                    }
                }
            }));
        }
    };
    const settingAccuracy = (v) => {
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            dispatch(setAccuracyData(v));
        } else {
            dispatch(setAccuracyContactData(v));
        }
    };
    const loading = companyDataState.loading ||
        contactDataState.loading ||
        searchCriteriaState.status.status.loading ||
        companyDataState.loading ||
        contactDataState.loading;

    useEffect(() => {
        const organization = accountState.account.organization;
        const restrictedFeatures = _.get(organization, 'config.features', undefined);
        if (restrictedFeatures) {
            if (restrictedFeatures.find(feature => feature === 'search')) setNoServiceSearch(false);
            if (restrictedFeatures.find(feature => feature === 'export')) setCanExport(true);
        }
        if (!restrictedFeatures || restrictedFeatures.length === 0) {
            setNoServiceSearch(true);
        }
    }, []);

    return (
        <>
            {noServiceSearch && (
                <ScreenLockOverlay />
            )}
            <Explore
                currentFilters={getCurrentFilters()}
                handleToggleFilter={handleToggleFilter}
                exploreMode={exploreDataState.exploreMode}
                hasFilter={hasFilter}
                totalCompanies={getCompaniesCount()}
                totalContacts={getContactCount()}
                loading={loading}
                handleSearch={() => handleSearch()}
                handleClearAllFilter={() => handleClearAllFilter()}
                searchCriteria={searchCriteriaState.searchCriteria}
                handleOnSelectSearchCriteria={(value) => handleOnSelectSearchCriteria(value)}
                handleSettingAccuracy={(value) => settingAccuracy(value)}
                accuracyData={exploreDataState.exploreMode.mode === 'COMPANY' ? companyDataState.accuracyData : contactDataState.accuracyData}
                canExport={canExport}
                appliedFilters={getAppliedFilters()}
            />
        </>
    );
}
