/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    getOrganizationById,
    editOrganization,
    clearState,
} from "./managedOrganizationSlice";

import {Row, Col, Button, Form, Table} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import BreadCrumb from '../../common/components/BreadCrumb';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import PanelHeader from "../../common/components/layout/PanelHeader";
import FormTextInput from "../../common/components/FormTextInput";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";
import FormJsonInput from "../../common/components/form/FormJsonInput";
import OrganizationConfigForm from './OrganizationConfigForm';
import styled from "styled-components";

const EditOrganizationContainer = (props) => {
    const managedOrganizationState = useSelector(state => state.managedOrganization);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    // Form
    const [managedOrganization, setManagedOrganization] = useState({
        name: '',
    });
    const [name, setName] = useState('');
    const [config, setConfig] = useState(managedOrganizationState.managedOrganization?.config);

    useEffect( () => {
            if (props.match.params.id){
                dispatch(getOrganizationById(props.match.params.id));
            }

        }, [props.match.params.id]);

    useEffect( () => {
        if (managedOrganizationState.managedOrganization) {
            setManagedOrganization(managedOrganizationState.managedOrganization);
            setName(managedOrganizationState.managedOrganization?.name);
            setConfig(managedOrganizationState.managedOrganization?.config);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Organizations',
                        href: '/admin/organizations',
                        isActive: false
                    },
                    {
                        label: `${managedOrganizationState?.managedOrganization.id}`,
                        href: '',
                        isActive: true
                    },
                    {label: 'Edit Organization' , href: '', isActive: true}]
            )
        }
        }
        , [managedOrganizationState.managedOrganization]);

    useEffect(() => {
        setAlertMessage(managedOrganizationState.alertMessage);
        if (managedOrganizationState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedOrganizationState.alertMessage]);

    useEffect(() => {

        setSuccessMessage(managedOrganizationState.successMessage);
        if (managedOrganizationState.successMessage) {
            setShowSuccess(true);
        }else {
            setShowSuccess(false);
        }
    }, [managedOrganizationState.successMessage]);

    const handleEditOrganization = async (data) => {
        dispatch(editOrganization({id:props.match.params.id, data:data}));
    };

    const handleCancel = async () => {
        dispatch(clearState());
        history.push('/admin/organizations')
    };

    return (
        <div>
            {managedOrganizationState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <EditOrganization
                id={managedOrganization?.id}
                handleEditOrganization={(data) => handleEditOrganization(data)}
                handleCancel={() => handleCancel()}
                name={name}
                config={config}
                breadcrumbItems={breadcrumbItems}
            />
        </div>
    );
};


function EditOrganization(props) {
    const {
        breadcrumbItems,
        name,
        handleCancel,
        id,
        config
    } = props;

    const [formValid, setFormValid] = useState(true);
    const [nameState, setNameState] = useState('');
    const [configState, setConfigState] = useState('');

    const reset = () => {
        setNameState('');
        setConfigState(null);
    };

    const isValid = () => {
        return !!nameState && nameState.length > 1;
    };

    const handleEditOrganization = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            const data = {
                name: nameState,
                config: configState
            };
            props.handleEditOrganization(data);
        }
    };

    useEffect(() => {
        setNameState(name);
        setConfigState(config);
    }, [name]);

    const PreLikeText = styled.div `
        margin-top: 5px;
        display: block;
        white-space: pre-wrap;
        unicode-bidi: embed;
        font-family: monospace;
        color: #212529;
        font-size: 87.5%;
        `;

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={'Edit Organization'}
                subHeader={'Change organization properties'}
            />
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="editOrganization">
                            <Row>
                            <Col md={7}>
                            <Row>
                                <Col md={9}>
                                    <FormLabel>Organization ID</FormLabel>
                                    {id &&
                                        <TextHoverDisplay
                                            fullText={id}
                                            truncatedText={id}
                                            displayTruncated={false}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <FormTextInput
                                        label={'Name'}
                                        placeholder="Enter name"
                                        value={nameState}
                                        onChange={value => setNameState(value)}
                                        isInvalid={!formValid && !nameState}
                                        size={'sm'}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {config && <OrganizationConfigForm
                                        setConfig={setConfigState}
                                        defaultConfig={{"config": config}}
                                        formValid={formValid}
                                    />}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEditOrganization()}
                                    >EDIT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            handleCancel();

                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}>
                                <div style={{position: 'sticky', top: 0, padding: '15px'}}>
                                    <h6 style={{fontWeight: "600"}}>Help</h6>
                                    <PreLikeText>
                                        <Table size="sm">
                                            <thead>
                                            <tr>
                                                <th>Property</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td style={{padding: '5px'}}>name</td>
                                                <td style={{padding: '5px'}}>The organization name</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>companyScore</td>
                                                <td style={{padding: '5px'}}>Numeric value in range 0.6 - 0.9. Default value is 0.8</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>contactScore</td>
                                                <td style={{padding: '5px'}}>Numeric value in range 0.6 - 0.9. Default value is 0.8</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>allowUnfiltered</td>
                                                <td style={{padding: '5px'}}>Allows unfiltered scores. Default value is false</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>features</td>
                                                <td style={{padding: '5px'}}>Options are Search, Enrich, and Export</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>segments</td>
                                                <td style={{padding: '5px'}}>Currently disabled</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>classifications</td>
                                                <td style={{padding: '5px'}}>Uncheck unrestricted to select from available classifications</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>services</td>
                                                <td style={{padding: '5px'}}>Currently disabled</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>unrestricted</td>
                                                <td style={{padding: '5px'}}>Default is selected. Deselect to edit Taxonomies fields</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>cities</td>
                                                <td style={{padding: '5px'}}>Uncheck unrestricted to enter cities (separated by commas)</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>msa</td>
                                                <td style={{padding: '5px'}}>Uncheck unrestricted to select from available msa's</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>regions</td>
                                                <td style={{padding: '5px'}}>Uncheck unrestricted to select from available regions</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>unrestricted</td>
                                                <td style={{padding: '5px'}}>Default is selected. Deselect to edit Geographies fields</td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '5px'}}>suppliers</td>
                                                <td style={{padding: '5px'}}>Put something here</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </PreLikeText>
                                </div>
                            </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default EditOrganizationContainer;

