/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {getManagedCompanies, getManagedCompaniesCount, setCurrentPage, setOrder, setSelectedId} from "./managedCompaniesSlice";

import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import TableWithPaging from '../../common/components/TableWithPaging';
import CheckBox from "../../common/components/CheckBox";
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/pro-solid-svg-icons';
import LinkButton from '../../common/components/LinkButton';
import {useHistory} from "react-router-dom";
import { wrap } from 'module';
import TextWrap from "../../common/components/TextWrap";

const ManagedCompaniesTable = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const managedCompaniesState = useSelector((state) => state.managedCompanies);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getManagedCompaniesCount());
        dispatch(getManagedCompanies());
    }, [props.lastReload, dispatch]);

    // Generate table column headers
    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'companyId',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    return <div>
                        <CheckBox
                            id={`check-${row.companyId}`}
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.companyId,
                                    name: row.name,
                                    type: row.type,
                                    status: row.status,
                                    isChecked: value
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'companyId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyId) {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row'}}>                              
                            <LinkButton style={{fontSize: '100%', fontWeight: 400,'padding':'0px'}} onClick={() => history.push(`/admin/d/companies/${row.companyId}/edit`)}>
                            <TextHoverDisplay canShowCopyToClipboard={false}
                                    truncatedText={row.companyId.substring(0, 13)}
                                    fullText={row.companyId} minWidth={116}
                                />
                            </LinkButton>
                            <TextHoverDisplay canShowText={false}
                                    truncatedText={row.companyId.substring(0, 13)}
                                    fullText={row.companyId} minWidth={15}
                                /></div>
                        );
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Identity',
                property: 'companyIdentity',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyIdentity) {
                        return <TextHoverDisplay style={{'text-wrap': 'nowrap'}}
                            truncatedText={row.companyIdentity.substring(0, 13)}
                            fullText={row.companyIdentity}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Score',
                property: 'companyScore',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyScore) {
                        return <TextWrap propertyvalue={row.companyScore}></TextWrap>
                    }
                    return <span></span>
                }
            },

            {
                id: 5,
                name: 'Name',
                property: 'companyName',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyName) {
                          
                        return  <TextWrap propertyvalue={row.companyName}></TextWrap>                    
                    }
                    return <span></span>
                }
            },
            {
                id: 6,
                name: 'URL',
                property: 'companyUrl',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.url) {
                        return (
                            <a target='_blank'
                               href={`${row.url}`}
                               rel='noopener noreferrer'
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={faExternalLink}
                                    style={{marginRight: '5px', fontSize: '12px','text-wrap': 'nowrap'}}
                                />
                                {`${row.url.substring(0, 20)}...`}                               
                            </a>
                        )
                    }                   
                }
            },
            {
                id: 7,
                name: 'Delivery Line 1',
                property: 'companyAddressDeliveryLine1',
                sortable: false,
                formatted: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressDeliveryLine1) {                        
                        return  <TextWrap propertyvalue={row.companyAddressDeliveryLine1}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 8,
                name: 'Delivery Line 2',
                property: 'companyAddressDeliveryLine2',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressDeliveryLine2) {                        
                        return  <TextWrap propertyvalue={row.companyAddressDeliveryLine2}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 9,
                name: 'City',
                property: 'companyAddressCity',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyAddressCity) {
                        return  <TextWrap propertyvalue={row.companyAddressCity}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 10,
                name: 'Region',
                property: 'companyAddressRegion',
                sortable: false,
                formatted: false,
            },
            {
                id: 11,
                name: 'Postal Code',
                property: 'companyAddressPostalCode',
                sortable: false,
                formatted: false,
            },
            {
                id: 12,
                name: 'Phone',
                property: 'companyPhone',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyPhone) {                        
                        return  <TextWrap propertyvalue={row.companyPhone}></TextWrap>
                    }
                    return <span></span>
                }                
            },
            {
                id: 13,
                name: 'Email',
                property: 'companyEmail',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyEmail) {
                        return  <TextWrap propertyvalue={row.companyEmail}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 14,
                name: 'Primary Trade',
                property: 'companyClassPrimaryTrade',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyClassPrimaryTrade) {
                        return  <TextWrap propertyvalue={row.companyClassPrimaryTrade}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 15,
                name: 'Trades',
                property: 'companyClassTrades',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.companyClassTrades) {
                        return  <TextWrap propertyvalue={row.companyClassTrades}></TextWrap> 
                    }
                    return <span></span>
                }
            },
            {
                id: 16,
                name: 'Contacts',
                property: 'companyStatContactCnt',
                sortable: false,
                formatted: false,
            },
            {
                id: 17,
                name: 'First Touch',
                property: 'companySourceFirstTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.companySourceFirstTouch) {
                        return <TextWrap propertyvalue={DateTime.fromISO(row.companySourceFirstTouch).toLocaleString(DateTime.DATETIME_MED)}></TextWrap>
                        
                    }
                    return <span></span>
                }
            },
            {
                id: 18,
                name: 'Last Touch',
                property: 'companySourceLastTouch',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.companySourceLastTouch) {
                        return <TextWrap propertyvalue={DateTime.fromISO(row.companySourceLastTouch).toLocaleString(DateTime.DATETIME_MED)}></TextWrap>                        
                    }
                    return <span></span>
                }
                
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getManagedCompanies());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div id='company-table-container'>
            <TableWithPaging
                id={'companies-table-with-paging'}
                headers={headers}
                rows={managedCompaniesState.companies}
                countOfResults={managedCompaniesState.totalRecords}
                resultsPerPage={managedCompaniesState.pageSize}
                currentPage={managedCompaniesState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
};

ManagedCompaniesTable.propTypes = {
    managedCompanies: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

ManagedCompaniesTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    managedCompanies: []
};

export default ManagedCompaniesTable;
