import {
    map,
    isArray,
    filter,
    split,
    uniqBy,
} from 'lodash';
import {Point_At_Distance_And_Bearing2} from "../../common/utils/LatLongUtils";

export const parseCubeFilter = (filters, table = 'Company') => {
    const filterToDeconstruct = filters.find((f) => f.id === 'COMPANY_ADDRESS_CITY_REGION_FORMATTED');
    if (filterToDeconstruct) {
        const filterToDeconstructIndex = filters.findIndex((f) => f.id === 'COMPANY_ADDRESS_CITY_REGION_FORMATTED');
        filters.splice(filterToDeconstructIndex, 1);
        filters.push({
            id: 'SUBSTITUTE_CITY_FILTER',
            display: 'Company City',
            field: [
                'CompanyContractorUniverse.company_address_city'
            ],
            operatorDisplay: 'in',
            operatorId: 'equals',
            component: 'combobox',
            fieldDisplay: '[company_address_city]',
            hide: true,
            value: uniqBy(filterToDeconstruct.value.map((v) => {
                return {id: v.id.split(',')[0].trim()}
            }), 'id')
        });
        filters.push({
            id: 'SUBSTITUTE_REGION_FILTER',
            display: 'Company Region',
            field: [
                'CompanyContractorUniverse.company_address_region'
            ],
            operatorDisplay: 'in',
            operatorId: 'equals',
            component: 'combobox',
            fieldDisplay: '[company_address_region]',
            hide: true,
            value: uniqBy(filterToDeconstruct.value.map((v) => {
                return {id: v.id.split(',')[1].trim()}
            }), 'id')
        });
    }

    // Thinking about we should duplicate filter meta data of contact filter.
    // Or just not specify table name on filed 'Company.company_name' -> 'company_name'
    // then auto generate table name.
    const replaceTableName = (field) => {
        if (isArray(field)) {
            return map(field, f => f.replace('Company', table));
        }
        return field.replace('Company', table);
    };
    const _filters = map(filters, (ft) => ({
        ...ft,
        field: replaceTableName(ft.field)
    }));
    return map(_filters, (ft) => {
        if (isArray(ft.field) && ft.field.length > 0) {
            if (ft.operatorId.toLowerCase() === 'multiple_boolean') {
                return _parseFilter(ft);
            }
            // {or: []}
            return (
                {
                    or: map(ft.field, _field => _parseFilter({
                        ...ft,
                        field: _field
                    }))
                }
            );
        }
        return _parseFilter(ft);
    });

};
const _parseFilter = (ft) => {

    if (ft.operatorId.toLowerCase() === 'between') {
        return ({
            and: [
                {
                    member: ft.field,
                    operator: 'gte',
                    values: [`${ft.value.min}`]
                },
                {
                    member: ft.field,
                    operator: 'lte',
                    values: [`${ft.value.max}`]
                }
            ]
        });
    }
    if (ft.operatorId.toLowerCase() === 'equals') {
        if (ft.rangeField) {
            const rangeValues = filter(ft.value, v => (v.min || v.max));
            const rangeQuery = map(rangeValues, v => {
                if (v.min && v.max) {
                    return ({
                        and: [
                            {
                                member: ft.rangeField,
                                operator: 'gte',
                                values: [`${v.min}`]
                            },
                            {
                                member: ft.rangeField,
                                operator: 'lte',
                                values: [`${v.max}`]
                            }
                        ]
                    });
                }
                if (v.min) {
                    return (
                        {
                            member: ft.rangeField,
                            operator: 'gte',
                            values: [`${v.min}`]
                        }
                    );
                }

                if (v.max) {
                    return (
                        {
                            member: ft.rangeField,
                            operator: 'lte',
                            values: [`${v.max}`]
                        }
                    );
                }

            });
            rangeQuery.push(
                {
                    member: ft.field,
                    operator: ft.operatorId,
                    values: ft.value && isArray(ft.value) ? map(ft.value, v => `${v.id}`) : [`${ft.value}`]
                });
            return (
                {
                    or: rangeQuery
                }
            )
        }
        return (
            {
                member: ft.field,
                operator: ft.operatorId,
                values: ft.value && isArray(ft.value) ? map(ft.value, v => `${v.id}`) : [`${ft.value}`]
            })
    }

    if (ft.operatorId.toLowerCase() === 'notequals') {
        return (
            {
                member: ft.field,
                operator: ft.operatorId,
                values: ft.value && isArray(ft.value) ? map(ft.value, v => `${v.id}`) : [`${ft.value}`]
            })
    }

    if (ft.operatorId.toLowerCase() === 'set') {
        return (
            {
                member: ft.field,
                operator: ft.operatorId
            })
    }

    if (ft.operatorId.toLowerCase() === 'multiple_boolean') {
        const isUnknownSet = filter(ft.value, v => v.unknown === true);
        const selectedValues = map(ft.value, v => v.id);
        // fields are true
        const fieldsSet = filter(ft.field, (_field) => {
            const tableAndColumn = _field.split('.');
            const column = tableAndColumn && tableAndColumn.length === 2 ? tableAndColumn[1] : null;
            return selectedValues.includes(column);
        });
        const querySet = map(fieldsSet, field => {
            return (
                {
                    member: field,
                    operator: 'set'
                }
            );
        });
        if (isUnknownSet && isUnknownSet.length > 0) {
            const queryNotSet = map(ft.field, field => {
                return (
                    {
                        member: field,
                        operator: 'notSet'
                    }
                );
            });
            querySet.push(({
                and: queryNotSet
            }))
        }

        return ({
            or: querySet
        });
    }

    if (ft.operatorId.toLowerCase() === 'lat_long_radius') {
        const latLongField = split(ft.field, ',');
        const {
            latitude,
            longitude,
            radius
        } = ft.value;
        const latLongEast = Point_At_Distance_And_Bearing2(latitude, longitude, `${radius} mi`, '90');
        const latLongSouth = Point_At_Distance_And_Bearing2(latitude, longitude, `${radius} mi`, '180');
        const latLongWest = Point_At_Distance_And_Bearing2(latitude, longitude, `${radius} mi`, '270');
        const latLongNorth = Point_At_Distance_And_Bearing2(latitude, longitude, `${radius} mi`, '360');
        const minLat = split(latLongSouth, ',')[0];
        const maxLat = split(latLongNorth, ',')[0];
        const minLong = split(latLongWest, ',')[1];
        const maxLong = split(latLongEast, ',')[1];
        return (
            {
                and: [{
                    member: latLongField[0],
                    operator: 'gte',
                    values: [minLat]
                }, {
                    member: latLongField[0],
                    operator: 'lte',
                    values: [maxLat]
                }, {
                    member: latLongField[1],
                    operator: 'gte',
                    values: [minLong]
                }, {
                    member: latLongField[1],
                    operator: 'lte',
                    values: [maxLong]
                }
                ]
            })
    }
    if (ft.operatorId === 'contains' && Array.isArray(ft.value) && ft.value.length > 0 && ft.value[0].id) {
        const operands = map(ft.value, v => v.id);
        return (
            {
                member: ft.field,
                operator: ft.operatorId,
                values: operands
            }
        )

    }
    if (ft.operatorId === 'notContains' && Array.isArray(ft.value) && ft.value.length > 0 && ft.value[0].id) {
        const operands = map(ft.value, v => v.id);
        return (
            {
                member: ft.field,
                operator: ft.operatorId,
                values: operands
            }
        )

    }
    if (ft.operand) {
        return (
            {
                member: ft.field,
                operator: ft.operatorId,
                values: [ft.operand]
            }
        )
    }

    return (
        {
            member: ft.field,
            operator: ft.operatorId,
            values: ft.value && isArray(ft.value) ? map(ft.value, v => `${v}`) : [ft.value]
        }
    )
};

export const parseDimension = (requestType, columns) => {
    if (requestType === 'SEARCH_COMPANY' || requestType === 'EXPORT_COMPANY') {
        return [
            'CompanyContractorUniverse.company_identity',
            'CompanyContractorUniverse.company_name',
            'CompanyContractorUniverse.company_class_primary_trade',
            'CompanyContractorUniverse.company_class_primary_trade_score',
            'CompanyContractorUniverse.company_url',
            'CompanyContractorUniverse.company_address_delivery_line_1',
            'CompanyContractorUniverse.company_phone',
            'CompanyContractorUniverse.company_email',
        ];
    }
    return [
        'ContactContractorUniverse.contact_identity',
        'ContactContractorUniverse.contact_name_given_name',
        'ContactContractorUniverse.contact_name_surname',
        'ContactContractorUniverse.contact_title',
        'ContactContractorUniverse.contact_phone',
        'ContactContractorUniverse.contact_email',
        'ContactContractorUniverse.company_name',
        'ContactContractorUniverse.company_class_primary_trade',
        'ContactContractorUniverse.company_class_primary_trade_score',
        'ContactContractorUniverse.company_url',
        'ContactContractorUniverse.company_address_delivery_line_1',
        'ContactContractorUniverse.company_phone',
        'ContactContractorUniverse.company_email',
    ];
}

export const parseOrgConfigFilter = (filters, config, table = 'Company', accuracyScore = null) => {
    const _filters = [];
    filters.forEach(f => {
        _filters.push(f);
    });

    // TODO: THIS SHOULDNT BE POSSIBLE
    if (!config) {
        return filters;
    }

    // Scores
    if (config.scores || accuracyScore) {
        if (table === 'Company') {
            const score = accuracyScore ? accuracyScore.minScore : config.scores.companyScore;
            _filters.push({
                id: 'LOCKED_COMPANY_SCORE',
                display: 'Company Score',
                field: 'CompanyContractorUniverse.company_score',
                operatorDisplay: 'between',
                operatorId: 'between',
                component: 'rangeslider',
                value: {
                    min: score,
                    max: 1
                },
                step: 0.01,
                fieldDisplay: 'company_score'
            });
        } else {
            const score = accuracyScore ? accuracyScore.minScore : config.scores.contactScore;
            _filters.push({
                id: 'LOCKED_CONTACT_SCORE',
                display: 'Contact Score',
                field: 'ContactContractorUniverse.contact_score',
                operatorDisplay: 'between',
                operatorId: 'between',
                component: 'rangeslider',
                value: {
                    min: score,
                    max: 1
                },
                step: 0.01,
                fieldDisplay: 'company_score'
            });
        }
    }
    return _filters;
};
