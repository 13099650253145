import React, {useState} from 'react';
import isEmail from "validator/es/lib/isEmail";
import {isEmpty} from "lodash";
import {useDispatch} from "react-redux";
import Modal from '../../../common/components/Modal';
import {
    Container,
    Form,
    Row,
    Col,
    Spinner,
    Button,
} from 'react-bootstrap';

import FormContainer from "../../../common/components/layout/FormContainer";
import FormTextInput from "../../../common/components/FormTextInput";
import CheckBox from "../../../common/components/CheckBox";
import {addTeamMember} from "./teamSlice";

function AddTeamMemberDialog(props) {
    const {
        handleClose,
    } = props;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [formGivenName, setFormGivenName] = useState('');
    const [formGivenNameValid, setFormGivenNameValid] = useState(true);
    const [formSurname, setFormSurname] = useState('');
    const [formSurnameValid, setFormSurnameValid] = useState(true);
    const [formEmail, setFormEmail] = useState('');
    const [formEmailValid, setFormEmailValid] = useState(true);

    const isGivenNameValid = () => {
        if (isEmpty(formGivenName) || formGivenName.length < 2) {
            setFormGivenNameValid(false);
            return false;
        }
        // reset if needed
        if (!formGivenNameValid) setFormGivenNameValid(true);
        return true;
    };

    const isSurnameValid = () => {
        if (isEmpty(formSurname) || formSurname.length < 2) {
            setFormSurnameValid(false);
            return false;
        }
        // reset if needed
        if (!formSurnameValid) setFormSurnameValid(true);
        return true;
    };

    const isEmailValid = () => {
        if (isEmpty(formEmail) || !isEmail(formEmail)) {
            setFormEmailValid(false);
            return false;
        }
        // reset if needed
        if (!formEmailValid) setFormEmailValid(true);
        return true;
    };

    const handleAddTeamMember = async () => {
        if (
            // TODO: This isn't particularly intelligent
            isGivenNameValid() && isSurnameValid() && isEmailValid()
        ) {
            dispatch(addTeamMember({givenName: formGivenName, surname: formSurname, email: formEmail}));
            handleClose();
        }
    };

    return (
        <Modal
            dialogClassName={'dialog'}
            showClose={true}
            show={true}
            handleClose={handleClose}
            title={'Add Team Member'}
            footer={
                (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>

                        <Button
                            variant='info'
                            disabled={loading}
                            onClick={() => handleAddTeamMember()}
                        >
                            {!loading && <>ADD</>}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            variant='outline-secondary'
                            onClick={() => handleClose()}
                            style={{marginLeft: '10px'}}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            {loading?
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        style={{marginTop: '30px'}}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
                :
                <Container>
                    <FormContainer>
                        <Form noValidate>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Given Name'}
                                        value={formGivenName}
                                        onChange={value => setFormGivenName(value)}
                                        isInvalid={!formGivenNameValid}
                                        size={'sm'}
                                        placeholder="Please enter the team members given name"
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Surname'}
                                        value={formSurname}
                                        onChange={value => setFormSurname(value)}
                                        isInvalid={!formSurnameValid}
                                        size={'sm'}
                                        placeholder="Please enter the team members surname"
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Email'}
                                        placeholder="Please enter the team members email"
                                        value={formEmail}
                                        onChange={value => setFormEmail(value)}
                                        isInvalid={!formEmailValid}
                                        size={'sm'}
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12} style={{
                                    marginBottom: '10px'
                                }}>
                                    <CheckBox
                                        label="Send Verification Email"
                                        checked={true}
                                    />
                                    <span style={{
                                        fontStyle: 'italic',
                                        fontSize: '14px'
                                    }}>A verification email will be sent to new team members. They must verify their email by clicking the link in the email. Once verified they can request a password reset on the login page.</span>
                                </Col>
                            </Row>
                        </Form>
                    </FormContainer>
                </Container>
            }
        </Modal>
    );
}
export default AddTeamMemberDialog;
