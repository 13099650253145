import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { wrap } from 'module';
import styled from "styled-components";

const TextWrap = (props) => {
    const{
        propertyvalue,
    }=props;
    return(
        <span style={{'text-wrap': 'nowrap'}}>{`${propertyvalue}`}</span>  
    )
}

export default TextWrap;