

import {find, map} from 'lodash';

const TYPES = {
    CREATED: {
        id: 'CREATED',
        display: 'Created'
    },
    REGISTERED: {
        id: 'REGISTERED',
        display: 'Registered'
    },
    ACTIVE: {
        id: 'ACTIVE',
        display: 'Active'
    },
    DISABLED: {
        id: 'DISABLED',
        display: 'Disabled'
    },
};

export function getTypeById(id) {
    if (id) {
        const _types = map(Object.keys(TYPES), key => TYPES[key]);
        const res = find(_types, type => type.id === id);
        if (res) {
            return res.display;
        }
        return id;
    }
    return id;
}
