import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    tcuFilter: false,
    selectedId: [],
    selectedCompanies: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'companyScore', direction: 'DESC'},
    orderSecondary:{by: 'companyId',direction: 'ASC'},   
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    companies: [],
};

export const getManagedCompanies = createAsyncThunk('companies/get-all', async (arg, {getState, requestId}) => {
    const GET_COMPANIES_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-companies`;
    const query = buildQuery(getState().managedCompanies.currentPage, getState().managedCompanies.pageSize,
        getState().managedCompanies.order.by, getState().managedCompanies.order.direction,      
        getState().managedCompanies.orderSecondary.by, getState().managedCompanies.orderSecondary.direction,
        getState().managedCompanies.searchCriteria,getState().managedCompanies.tcuFilter);
    const companies = await Api.get(true, GET_COMPANIES_URL, query);
    
    return companies;
});

export const getManagedCompaniesWithFilter = createAsyncThunk('companies/get-all-with-filter', async (filter) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-companies`;
    const companies = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return companies;
});

export const getManagedCompaniesCount = createAsyncThunk('companies/getCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const searchCriteria = (getState().managedCompanies ? getState().managedCompanies.searchCriteria : '');
        const tcuFilter = (getState().managedCompanies ? getState().managedCompanies.tcuFilter : false);
        const where = {
            where: {
                and: [
                    {
                        or: [{
                            companyId: {
                                eq: `${searchCriteria}`
                            }
                        }
                        ]
                    },
                    (tcuFilter ?
                        {
                            companyProductTcu: {
                                eq: true
                            }
                        }
                        : {}
                    )
                ]
            }
        }
        const SOURCE_COUNT_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-companies/count`;
        const companiesCount = await Api.get(true, SOURCE_COUNT_URL, searchCriteria ? where:'');
        return companiesCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection,orderByOption,orderDirectionOptional, searchCriteria, tcuFilter) => {
    const query = searchCriteria ? {
        filter: {
            where: {
                and: [
                    {
                        or: [{
                            companyId: {
                            eq: `${searchCriteria}`
                            }
                        }
                        ]
                    },
                    (tcuFilter ?
                        {
                            companyProductTcu: {
                                eq: true
                            }
                        }
                        : {}
                    )
                ]
            },
            
            order: (`${orderBy} ${orderDirection},${orderByOption} ${orderDirectionOptional}`),       
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    }: {
        filter: {
            order: (`${orderBy} ${orderDirection},${orderByOption} ${orderDirectionOptional}`),       
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };

    return query;
};

const managedCompaniesSlice = createSlice({
    name: 'managedCompaniesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        clearSelectedCompanies: {
            reducer(state, action) {
                state.selectedCompanies = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedCompanies.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedCompanies.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        setTcuFilter: {
            reducer(state, action) {
                state.tcuFilter = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedCompanies.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedCompanies.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companies = action.payload;
            }
        },
        [getManagedCompanies.rejected]: (state, action) => {
            state.loading = false;
        },
        [getManagedCompaniesCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedCompaniesCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getManagedCompaniesCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getManagedCompaniesWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedCompaniesWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companies = action.payload;
            }
        },
        [getManagedCompaniesWithFilter.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});


export const {setCurrentPage, setOrder, setSelectedId, clearSelected, clearSelectedCompanies, setSearchCriteria, setTcuFilter, resetCreateOrganizationState} = managedCompaniesSlice.actions;
export default managedCompaniesSlice.reducer;
