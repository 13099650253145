import _ from "lodash";

export const TAG_IMAGES_COMPANY = [
    {
        profile: 'Air Conditioning Contractors of America (ACCA)',
        imageUrl: 'v1640014035/assets/profiles/air_conditioning_contactors_of_america_logo_imygcc.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Alliant Energy',
        imageUrl: 'v1640014366/assets/profiles/aliiant_energy_logo_caajyt.svg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Amana',
        imageUrl: 'v1640014479/assets/profiles/amana_logo_k0u7fh.png',
        categorizations: 'HVAC / Mechanical Pro, Appliance Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'American Academy of Healthcare Interior Designers (AAHID)',
        imageUrl: 'v1640014555/assets/profiles/american_academy_of_healthcare_interior_designers_logo_pvy5i3.png',
        categorizations: 'Interior Designer',
        indicators: 'commrecial, healthcare',
        caption: false
    },
    {
        profile: 'American Fence Association (AFA)',
        imageUrl: 'v1640014677/assets/profiles/american_fence_association_bhehug.png',
        categorizations: 'Fence Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'American Institute of Architects - Austin (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - Charlotte (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - Colorado (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - Connecticut (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - New Jersey (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - North Carolina (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - Philadelphia (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects - Seattle (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Architects (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'American Institute of Building Design (AIBD)',
        imageUrl: 'v1640014934/assets/profiles/american_institute_of_building_design_logo_r75xn3.jpg',
        categorizations: 'Architect',
        indicators: '',
        caption: false
    },
    {
        profile: 'American Subcontractors Association (ASA)',
        imageUrl: 'v1640015120/assets/profiles/american_subcontractors_association_logo_jnnmoz.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Anderson',
        imageUrl: 'v1640015171/assets/profiles/anderson_logo_gc8b3b.svg',
        categorizations: 'Window & Door Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Angies List',
        imageUrl: 'v1640015218/assets/profiles/angies_list_logo_kzokrm.svg',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Architizer',
        imageUrl: 'v1640015322/assets/profiles/architizer_logo_fcr5jt.png',
        categorizations: 'Architect',
        indicators: '',
        caption: false
    },
    {
        profile: 'Associated Landscape Contractors of Colorado (ALCC)',
        imageUrl: 'v1640015442/assets/profiles/associated_landscape_contractors_of_colorado_logo_scu8ug.jpg',
        categorizations: 'Landscaper',
        indicators: '',
        caption: true
    },
    {
        profile: 'Association of Licensed Architects (ALA)',
        imageUrl: 'v1640015502/assets/profiles/association_of_licensed_architects_logo_utkhv9.png',
        categorizations: 'Architect',
        indicators: '',
        caption: false
    },
    {
        profile: 'Association of Outdoor Lighting Professionals (AOLP)',
        imageUrl: 'v1640015565/assets/profiles/association_of_outdoor_lighting_professionals_logo_xowde5.png',
        categorizations: 'Landscaper, Lighting Pro',
        indicators: '',
        caption: true
    },
    {
        profile: 'Association of Professional Landscape Designers (APLD)',
        imageUrl: 'v1640015770/assets/profiles/associaton_professional_landscape_designers_logo_t8duig.jpg',
        categorizations: 'Landscape Designer',
        indicators: '',
        caption: true
    },
    {
        profile: 'Atlas Roofing',
        imageUrl: 'v1640015856/assets/profiles/atlas_roofing_logo_odqokm.png',
        categorizations: 'Roofer',
        indicators: '',
        caption: true
    },
    {
        profile: 'Better Business Bureau',
        imageUrl: 'v1640019998/assets/profiles/better_business_bureau_logo_jx1pgv.jpg',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Bosch',
        imageUrl: 'v1640020090/assets/profiles/bosch_logo_aucmus.png',
        categorizations: 'Plumber, Appliance Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Bosch',
        imageUrl: 'v1640020090/assets/profiles/bosch_logo_aucmus.png',
        categorizations: 'Plumber, Appliance Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Boston Society for Architecture (AIA)',
        imageUrl: 'v1640014729/assets/profiles/american_institute_of_architects_logo_v7pmbx.svg',
        categorizations: 'Architect',
        indicators: '',
        caption: true
    },
    {
        profile: 'Bradford White',
        imageUrl: 'v1640020155/assets/profiles/bradford_white_logo_nwsjbu.svg',
        categorizations: 'Plumber',
        indicators: '',
        caption: false
    },
    {
        profile: 'Bryant',
        imageUrl: 'v1640020250/assets/profiles/bryant_logo_seddj3.svg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'BuildZoom',
        imageUrl: 'v1640020313/assets/profiles/buildzoom_logo_mmhzs4.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Cabinet Makers Association (CMA)',
        imageUrl: 'v1640020360/assets/profiles/cabinet_makes_association_logo_i9zrub.png',
        categorizations: 'Cabinet Maker',
        indicators: '',
        caption: false
    },
    {
        profile: 'California Landscape Contractors Association (CLCA)',
        imageUrl: 'v1640020417/assets/profiles/california_landscape_contractors_association_logo_w5n1rq.png',
        categorizations: 'Landscaper',
        indicators: '',
        caption: true
    },
    {
        profile: 'Carrier',
        imageUrl: 'v1640020502/assets/profiles/carrier_logo_p5hn4d.jpg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'CertainTeed',
        imageUrl: 'v1640020709/assets/profiles/certainteed_logo_e6jdfu.jpg',
        categorizations: 'Roofer, Siding Pro, Fence Pro, Decking Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Chicago Roofing Contractors Association (CRCA)',
        imageUrl: 'v1640020817/assets/profiles/chicago_roofing_contractors_association_kcbalz.png',
        categorizations: 'Roofer',
        indicators: '',
        caption: true
    },
    {
        profile: 'ConEd',
        imageUrl: 'v1640020872/assets/profiles/conedison_logo_clzmyp.jpg',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'D&B',
        imageUrl: 'v1640020939/assets/profiles/d_and_b_logo_xjxtqk.jpg',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Daikin Comfort',
        imageUrl: 'v1640020987/assets/profiles/daikin_comfort_logo_xjnmd0.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Deckorators',
        imageUrl: 'v1640021033/assets/profiles/deckorators_logo_qvmysr.png',
        categorizations: 'Decking Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'dnb.com',
        imageUrl: 'v1640020939/assets/profiles/d_and_b_logo_xjxtqk.jpg',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Dunkirk',
        imageUrl: 'v1640021086/assets/profiles/dunkirk_logo_pobreh.png',
        categorizations: 'Plumbing',
        indicators: '',
        caption: false
    },
    {
        profile: 'Ecological Landscape Alliance',
        imageUrl: 'v1640021131/assets/profiles/ecological_landscape_alliance_logo_blkywi.png',
        categorizations: 'Landscaper',
        indicators: '',
        caption: true
    },
    {
        profile: 'Energy Trust of Oregon',
        imageUrl: 'v1640021181/assets/profiles/energy_trust_of_oregon_logo_yoorke.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Entergy Arkansas',
        imageUrl: 'v1640021241/assets/profiles/entergy_arkansas_logo_jfrtww.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Entergy Mississippi',
        imageUrl: 'v1640021241/assets/profiles/entergy_mississippi_logo_ppicg3.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Federated Electrical Contractors (FEC)',
        imageUrl: 'v1640021289/assets/profiles/federated_electrical_contractors_logo_bo5oxh.png',
        categorizations: 'Electrician',
        indicators: '',
        caption: false
    },
    {
        profile: 'Fiberon Decking',
        imageUrl: 'v1640021326/assets/profiles/fiberon_logo_luj51n.png',
        categorizations: 'Decking Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Flooring Contractors Association (FCICA)',
        imageUrl: 'v1640021387/assets/profiles/flooring_contractors_of_america_logo_jouwjt.png',
        categorizations: 'Flooring Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Florida Irrigation Society',
        imageUrl: 'v1640021895/assets/profiles/florida_irrigation_society_logo_l6ilcc.png',
        categorizations: 'Irrigation Specialist',
        indicators: '',
        caption: false
    },
    {
        profile: 'Florida Power & Light',
        imageUrl: 'v1640021935/assets/profiles/florida_power_and_light_logo_pfsmcz.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Florida Roofing and Sheet Metal Contractors Association (FRSMCA)',
        imageUrl: 'v1640022038/assets/profiles/florida_roofing_and_sheet_metal_contractors_association_logo_pkm3bw.png',
        categorizations: 'Roofer',
        indicators: '',
        caption: true
    },
    {
        profile: 'GAF',
        imageUrl: 'v1640022098/assets/profiles/gaf_logo_f5eosm.png',
        categorizations: 'Roofer',
        indicators: '',
        caption: true
    },
    {
        profile: 'Georgia Arborists Association (GAA)',
        imageUrl: 'v1640022144/assets/profiles/georgia_arborists_association_logo_qhny3h.png',
        categorizations: 'Arborist',
        indicators: '',
        caption: true
    },
    {
        profile: 'Goodman',
        imageUrl: 'v1640022183/assets/profiles/goodman_logo_vm40ma.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'Google',
        imageUrl: 'v1640022223/assets/profiles/google_logo_fpdlzz.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Havenly',
        imageUrl: 'v1640022269/assets/profiles/havenly_logo_tjiqer.jpg',
        categorizations: 'Interior Designer',
        indicators: '',
        caption: true
    },
    {
        profile: 'Heil',
        imageUrl: 'v1640022342/assets/profiles/heil_logo_bvqtdt.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: '',
        caption: false
    },
    {
        profile: 'HomeAdvisor',
        imageUrl: 'v1640022431/assets/profiles/homeadvisor_logo_wofmd9.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Houzz',
        imageUrl: 'v1640022431/assets/profiles/houzz_logo_flomjd.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Idaho Power',
        imageUrl: 'v1640025673/assets/profiles/idaho_power_logo_mckt76.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'IKO',
        imageUrl: 'v1640025704/assets/profiles/iko_logo_edjxin.jpg',
        categorizations: 'Roofer',
        indicators: '',
        caption: false
    },
    {
        profile: 'Illinois Landscape Contractors Association (ILCA)',
        imageUrl: 'v1640025849/assets/profiles/illinois_landscape_contractors_association_logo_up2qvs.png',
        categorizations: 'Landscaper',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors - Central Texas (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors - Dallas (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors - Gulf Coast Texas (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors - Lubbock (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors - Texas Panhandle (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Independent Electrical Contractors (IEC)',
        imageUrl: 'v1640025850/assets/profiles/independent_electrical_contractors_association_logo_uqwqvi.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Indoor Air Quality Association (IAQA)',
        imageUrl: 'v1640026098/assets/profiles/indoor_air_quality_association_logo_izjhgh.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Institute of Inspection Cleaning and Restoration Certification (IICRC)',
        imageUrl: 'v1640026164/assets/profiles/institute_of_inspection_cleaning_and_restoration_certification_logo_dxrnql.jpg',
        categorizations: 'Restoration Specialist',
        indicators: 'Interior, Exterior',
        caption: false
    },
    {
        profile: 'InterNACHI',
        imageUrl: 'v1640026189/assets/profiles/internachi_logo_nw0fcn.png',
        categorizations: 'Home Inspector',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'International Association of Lighting Designers (IALD)',
        imageUrl: 'v1640026253/assets/profiles/international_association_of_lighting_designers_logo_zesapz.svg',
        categorizations: 'Lighting Pro',
        indicators: '',
        backgroundColor: '#b8b8b8',
        caption: false
    },
    {
        profile: 'International Pool and Spa Service Association (IPSSA)',
        imageUrl: 'v1640026301/assets/profiles/independent_pool_and_spa_service_association_logo_fjtfoc.png',
        categorizations: 'Swimming Pool Pro',
        indicators: 'Exterior',
        caption: true
    },
    {
        profile: 'Irrigation Association',
        imageUrl: 'v1640026377/assets/profiles/irrigation_association_logo_yxejpg.jpg',
        categorizations: 'Irrigation Specialist',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'James Hardie',
        imageUrl: 'v1640026617/assets/profiles/james_hardie_logo_qeiclk.png',
        categorizations: 'Siding Pro, Repiar & Remodel Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Kohler Pro Installers',
        imageUrl: 'v1640026677/assets/profiles/kohler_logo_yvbycc.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Lennox',
        imageUrl: 'v1640027053/assets/profiles/lennox_logo_mr2ktp.svg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'LP',
        imageUrl: 'v1640027103/assets/profiles/lp_logo_aj69fn.png',
        categorizations: 'Siding Pro, Repiar & Remodel Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Marvin - Find a Pro',
        imageUrl: 'v1640027362/assets/profiles/marvin_logo_bent40.png',
        categorizations: 'Window & Door Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Mechanical & Service Contractors Association of Eastern Pennsylvania (MCAEPA)',
        imageUrl: 'v1640047171/assets/profiles/mechanical_and_service_contractors_association_of_easterna_pa_logopng_ss7toe.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'Michigan Nursery and Landscape Association (MNLA)',
        imageUrl: 'v1640047515/assets/profiles/michigan_nursery_and_landscape_association_logo_q2xdq3.png',
        categorizations: 'Landscaper',
        indicators: 'Exterior',
        caption: true
    },
    {
        profile: 'Midwest Roofing Contractors Association (MRCA)',
        imageUrl: 'v1640047656/assets/profiles/midwset_roofing_contractors_association_logo_yjxr6a.png',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Mitsubishi Comfort',
        imageUrl: 'v1640047812/assets/profiles/mitsubishi_electric_logo_uc55za.svg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Moen',
        imageUrl: 'v1640047874/assets/profiles/moen_logo_osmby1.svg',
        categorizations: 'Plumber',
        indicators: 'Interior',
        backgroundColor: '#b8b8b8',
        caption: false
    },
    {
        profile: 'MoistureShield',
        imageUrl: 'v1640048129/assets/profiles/moisture_shield_logo_dlwn4s.jpg',
        categorizations: 'Decking Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'National Air Duct Cleaners Association (NADCA)',
        imageUrl: 'v1640048976/assets/profiles/national_air_duct_cleaning_assocation_logo_bsapl9.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'National Association of Electrical Contractors (NECA)',
        imageUrl: 'v1640050830/assets/profiles/national_association_of_electrical_contractors_logo_hmfddk.png',
        categorizations: 'Electrician',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'National Association of Home Builders (NAHB) - Professional Remodelers',
        imageUrl: 'v1640050878/assets/profiles/national_association_of_home_builders_logo_bjku5n.png',
        categorizations: 'Builder, Repair & Remodel Pro',
        indicators: 'Residential',
        caption: false
    },
    {
        profile: 'National Association of Landscape Professionals (NALP)',
        imageUrl: 'v1640050955/assets/profiles/national_assocation_of_landscape_professionals_logo_e4wzdo.png',
        categorizations: 'Landscaper',
        indicators: '',
        caption: false
    },
    {
        profile: 'National Kitchen and Bath Association (NKBA)',
        imageUrl: 'v1640051003/assets/profiles/national_kitchen_and_bath_assocation_logo_wkqire.png',
        categorizations: '',
        indicators: 'Residential, Interior',
        caption: false
    },
    {
        profile: 'National Roofing Contractors Association (NRCA)',
        imageUrl: 'v1640051052/assets/profiles/national_roofing_contractors_assocation_logo_au49ud.png',
        categorizations: 'Roofer',
        indicators: '',
        caption: false
    },
    {
        profile: 'National Tile Contractors Association (NTCA)',
        imageUrl: 'v1640051094/assets/profiles/national_tile_contractors_assocation_logo_nkaqkf.png',
        categorizations: 'Stone & Tile Pro',
        indicators: 'Residential, Interior',
        caption: true
    },
    {
        profile: 'National Wood Flooring Association (NWFA)',
        imageUrl: 'v1640051144/assets/profiles/national_wood_flooting_association_logo_t5q5t8.png',
        categorizations: 'Flooring Pro',
        indicators: 'Residential, Interior',
        caption: true
    },
    {
        profile: 'Navien',
        imageUrl: 'v1640051190/assets/profiles/navien_logo_p8ta0g.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'North American Deck and Railing Association (NADRA)',
        imageUrl: 'v1640051426/assets/profiles/north_american_deck_and_railing_assoc_logo_sakaf5.png',
        categorizations: 'Decking Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'North American Technical Excellence (NATE)',
        imageUrl: 'v1640051493/assets/profiles/north_american_technical_excellence_logo_r3rtgm.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Residential',
        caption: true
    },
    {
        profile: 'North East Roofing Contractors Association (NERCA)',
        imageUrl: 'v1640051548/assets/profiles/north_east_roofing_contractors_assocation_logo_w7cfcj.png',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Ohio Edison',
        imageUrl: 'v1640051584/assets/profiles/ohio_edison_logo_vdbckh.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Oregon Landscape Contractors Association (OLCA)',
        imageUrl: 'v1640051626/assets/profiles/oregon_landscape_contractors_association_logo_jchyob.jpg',
        categorizations: 'Landscaper',
        indicators: '',
        caption: false
    },
    {
        profile: 'Owens Corning',
        imageUrl: 'v1640051685/assets/profiles/owens_corning_logo_bakvrr.jpg',
        categorizations: 'Roofer',
        indicators: '',
        caption: true
    },
    {
        profile: 'Painting Contractors Association (PCA)',
        imageUrl: 'v1640051728/assets/profiles/painting_contractors_association_logo_amagvc.png',
        categorizations: 'Painter',
        indicators: '',
        caption: true
    },
    {
        profile: 'Peerless',
        imageUrl: 'v1640051785/assets/profiles/peerless_logo_hvpqv6.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'Pella - Find a Pro',
        imageUrl: 'v1640051828/assets/profiles/pella_logo_tkmieq.svg',
        categorizations: 'Window & Door Pro',
        indicators: 'Interior, Exterior',
        caption: true
    },
    {
        profile: 'Penn Power',
        imageUrl: 'v1640051872/assets/profiles/penn_power_logo_qu0hkb.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'PlyGem',
        imageUrl: 'v1640051927/assets/profiles/plygem_logo_rly6y0.png',
        categorizations: 'Siding Pro, Window & Door Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Porch',
        imageUrl: 'v1640051982/assets/profiles/porch_logo_y31yv1.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Portland General Electric',
        imageUrl: 'v1640052039/assets/profiles/portland_general_electric_logo_ogb2ct.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Potomac Edison',
        imageUrl: 'v1640052077/assets/profiles/potomac_edison_logo_krrrew.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Public Service Company of OK',
        imageUrl: 'v1640052112/assets/profiles/public_service_company_of_ok_logo_oo2lyq.svg',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Qualified Water Efficient Landscaper (QWEL)',
        imageUrl: 'v1640052147/assets/profiles/qwel_logo_fwyd97.png',
        categorizations: 'Landscaper',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Rainbird',
        imageUrl: 'v1640052192/assets/profiles/rainbird_logo_cnn0os.png',
        categorizations: 'Irrigation Specialist',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Restoration Industry Association (RIA)',
        imageUrl: 'v1640052237/assets/profiles/restoration_industry_assocation_logo_iwevz7.png',
        categorizations: 'Restoration Specialist',
        indicators: '',
        caption: true
    },
    {
        profile: 'Rheem',
        imageUrl: 'v1640052303/assets/profiles/rheem_logo_jfav5e.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'Rinnai',
        imageUrl: 'v1640052339/assets/profiles/rinnai_logo_rrrfmu.svg',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Roofers and Siding Contractors Alliance (RSCA)',
        imageUrl: 'v1640052399/assets/profiles/roofing_and_siding_contractors_alliance_logo_jww2v6.png',
        categorizations: 'Roofer, Siding Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Roofing Contractors Association of Texas (RCAT)',
        imageUrl: 'v1640052455/assets/profiles/roofing_contractors_association_of_texas_logo_k11y3l.jpg',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Roofing Contractors Association of Washington (RCAW)',
        imageUrl: 'v1640052538/assets/profiles/roofing_contractors_association_of_washington_logo_oxfy7o.jpg',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: true
    },
    {
        profile: 'RoofingInsights',
        imageUrl: 'v1640052589/assets/profiles/roofinginsights_logo_yvzoyz.jpg',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Royal Building Products',
        imageUrl: 'v1640052673/assets/profiles/royal_building_products_logo_xw3ipo.jpg',
        categorizations: 'Siding Pro, Decking Pro, Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Ruud',
        imageUrl: 'v1640052754/assets/profiles/ruud_logo_i09djh.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Solar Power World 2020 Top Solar Contractors',
        imageUrl: 'v1640052851/assets/profiles/solar_power_world_logo_ofvjc2.png',
        categorizations: 'Solar Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Southwestern Electric Power Company',
        imageUrl: 'v1640052903/assets/profiles/southwestern_electric_power_company_logo_k2ppsw.svg',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'Texas Air Conditioning Contractors Association (TACCA)',
        imageUrl: 'v1640052944/assets/profiles/texas_air_conditioning_contractors_association_logo_uedhvg.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Texas Nursery and Landscape Association (TNLA)',
        imageUrl: 'v1640052986/assets/profiles/texas_nursery_and_landscape_association_logo_x3qnvm.png',
        categorizations: 'Landscaper',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'The Hospitality Industry Network (NEWH)',
        imageUrl: 'v1640053062/assets/profiles/hospitality_industry_network_logo_rjihjb.png',
        categorizations: '',
        indicators: '',
        caption: false
    },
    {
        profile: 'thebluebook.com',
        imageUrl: 'v1640053189/assets/profiles/thebluebook_logo_belglx.png',
        categorizations: 'Commercial',
        indicators: '',
        backgroundColor: '#b8b8b8',
        caption: true,
        notShowIcon: true
    },
    {
        profile: 'TimberTech',
        imageUrl: 'v1640053229/assets/profiles/timbertech_logo_ysr0mv.svg',
        categorizations: 'Decking Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Trane',
        imageUrl: 'v1640053301/assets/profiles/trane_logo_ufk6yk.jpg',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Tree Care Industry Association (TCIA)',
        imageUrl: 'v1640053357/assets/profiles/tree_care_industry_association_logo_k1yevn.jpg',
        categorizations: 'Arborist',
        indicators: 'Exterior',
        caption: true
    },
    {
        profile: 'Trex',
        imageUrl: 'v1640046812/assets/profiles/trex_logo_zibhck.jpg',
        categorizations: 'Decking Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Triangle Tube',
        imageUrl: 'v1640053411/assets/profiles/triangle_tube_logo_llhpzh.png',
        categorizations: 'Plumber Boilers',
        indicators: '',
        backgroundColor: '#b8b8b8',
        caption: true,
        notShowIcon: true
    },
    {
        profile: 'TruPro Profile - Higher Ed',
        imageUrl: 'v1640053477/assets/profiles/ToolBelt_Icon_qjt3pj.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'TruPro Profile - HVAC / Mechanical Pro',
        imageUrl: 'v1640053477/assets/profiles/ToolBelt_Icon_qjt3pj.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'TruPro Profile - Interior Designer',
        imageUrl: 'v1640053477/assets/profiles/ToolBelt_Icon_qjt3pj.png',
        categorizations: 'Interior Designer',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'Tucson Electric Power',
        imageUrl: 'v1640053539/assets/profiles/tucson_electric_power_logo_aqlih6.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'Urban Ag Council Georgia',
        imageUrl: 'v1640053593/assets/profiles/urban_ag_council_georgia_abrv6c.svg',
        categorizations: 'Landscaper',
        indicators: 'Exterior',
        backgroundColor: '#b8b8b8',
        caption: false
    },
    {
        profile: 'US Boiler',
        imageUrl: 'v1640053631/assets/profiles/usboiler_logo_ye9ncq.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'usaplumbing.info',
        imageUrl: 'v1640053774/assets/profiles/usa_plumbing_logo_zumtuq.png',
        categorizations: 'Plumber, HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: true
    },
    {
        profile: 'Utica',
        imageUrl: 'v1640053843/assets/profiles/utica_logo_x14fis.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        backgroundColor: '#b8b8b8',
        caption: false
    },
    {
        profile: 'Velux',
        imageUrl: 'v1640046702/assets/profiles/velux_logo_tdofmq.svg',
        categorizations: 'Window & Door Pro',
        indicators: 'Exterior, Windows, Skylights',
        caption: false
    },
    {
        profile: 'Vinyl Siding Institute (VSI)',
        imageUrl: 'v1640053891/assets/profiles/vinyl_siding_institute_logo_lhwezs.svg',
        categorizations: 'Siding Pro',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Weil-McLain',
        imageUrl: 'v1640053933/assets/profiles/well_mclain_logo_kqy56d.png',
        categorizations: 'Plumber',
        indicators: 'Interior',
        caption: false
    },
    {
        profile: 'Western States Roofing Contractors Association (WSRCA)',
        imageUrl: 'v1640053981/assets/profiles/western_states_roofing_contractors_association_logo_g5dse3.png',
        categorizations: 'Roofer',
        indicators: 'Exterior',
        caption: false
    },
    {
        profile: 'Wolfe Home Products',
        imageUrl: 'v1640054040/assets/profiles/wolf_home_products_logo_wi3e3y.png',
        categorizations: 'Siding Pro, Decking Pro, Cabinet Maker',
        indicators: '',
        caption: true
    },
    {
        profile: 'Xcel',
        imageUrl: 'v1640054122/assets/profiles/xcel_energy_logo_g1re5n.svg',
        categorizations: '',
        indicators: '',
        backgroundColor: '#b8b8b8',
        caption: false
    },
    {
        profile: 'Yellow Pages',
        imageUrl: 'v1640054174/assets/profiles/yellow_pages_logo_ccwk9p.svg',
        categorizations: '',
        indicators: '',
        backgroundColor: '#b8b8b8',
        caption: true
    },
    {
        profile: 'Yelp',
        imageUrl: 'v1640054282/assets/profiles/yelp_logo_slcf5z.png',
        categorizations: '',
        indicators: '',
        caption: true
    },
    {
        profile: 'York',
        imageUrl: 'v1640054320/assets/profiles/york_logo_t5e31t.png',
        categorizations: 'HVAC / Mechanical Pro',
        indicators: 'Interior',
        caption: false
    }
];

export function getTagImagesCompanyById(id) {
    if (id) {
        return _.find(TAG_IMAGES_COMPANY, o => o.profile === id.trim());
    }
}
