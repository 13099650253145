

import React, {  useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useOutsiderClick } from'./useOutsiderClick';
import Styles from './SvgSpritesIconPicker.scss';


const SvgSpritesIconPicker = (props) => {

    const {
        url,
        value,
        onSelectIcon,
        color,
    } = props;

    const [displayFaPicker, setDisplayFaPicker] = useState(false);
    const FaRef = useRef(null);
    useOutsiderClick(FaRef, () => setDisplayFaPicker(false));
    const _value = value || 'map-marker-alt';
    return (
        <div>
            <FaIcon
                id={'selected-sprite'}
                name={_value}
                onClick={(name) => {
                    onSelectIcon(name);
                    setDisplayFaPicker(true);
                }}
                color={color}
                url={url}
            />
            {displayFaPicker &&
                <div  ref={FaRef} className={Styles.SvgSpritesIconPicker}>
                    <div className={'popover'}>
                        <div className='SvgSpritesIconPicker'>
                            {icons.map((option, index) =>
                                (
                                    <div key={`${index}`}>
                                        <FaIcon
                                            id={`sprite-${option.icon}-${index}`}
                                            name={option.icon}
                                            onClick={(name) => {
                                                onSelectIcon(name);
                                                setDisplayFaPicker(false);
                                            }}
                                            color={color}
                                            url={url}
                                        />
                                    </div>
                                )

                            )
                            }

                        </div>
                    </div>
                </div>
            }
        </div>

    );
};

const FaIcon = (props) => {
    const {
        id,
        onClick,
        name,
        color,
        url
    } = props;

    return (
        <div
            className={'svgIconItem'}
            onClick={() => onClick(name)}
            key={id}
        >
            <svg
                width={20}
                height={20}
                style={{fill: `${color}`}}
            >
                <use
                    href={url+`#${name}`}
                />
            </svg>
        </div>
    )
};

SvgSpritesIconPicker.defaultProps = {
    options: [],
    value: 'ad',
    onSelectIcon: e => e,
};

SvgSpritesIconPicker.propTypes = {
    url: PropTypes.string.isRequired,
    options: PropTypes.array,
    value: PropTypes.string,
    onSelectIcon: PropTypes.func.isRequired,
};

export default SvgSpritesIconPicker;

const icons = [
    {icon: 'address-book'},
    {icon: 'ambulance'},
    {icon: 'location-arrow'},
    {icon: 'thumbtack'},
    {icon: 'map-marker'},
    {icon: 'map-marker-alt'},
    {icon: 'map-pin'},
    {icon: 'exclamation'},
    {icon: 'hammer'},
    {icon: 'hard-hat'},
    {icon: 'paint-roller'},
    {icon: 'toolbox'},
];

FaIcon.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    name: PropTypes.string,
    color: PropTypes.string,
    url: PropTypes.string,
};
