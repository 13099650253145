/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import queryString from 'query-string';

import FormValidator from '../FormValidator';
import {confirmAccountAndSetPassword} from '../account/AccountService';
import logo from '../assets/img/ToolBelt_login_logo.png';

export default class AccountConfirm extends Component {
    static propTypes = {
        location: PropTypes.object,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false,
            token: null,
            formReset: {
                validated: false,
                password1: '',
                password2: ''
            },
            showAlert: false,
            alertVariant: null,
            alertMessage: null,
            resetSuccess: false,
        };
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed && !parsed.access_token) {
            this.setState({redirect: true});
        } else {
            this.setState({token: parsed.access_token});
        }
    }

    /**
     * Validate input using onChange event
     * @param  {String} event
     * @return {Function} a function used for the event
     */

    validateOnChange = (event) => {

        const input = event.target;
        const form = input.form; // eslint-disable-line prefer-destructuring
        const value = input.type === 'checkbox' ? input.checked : input.value;
        const result = null;

        // TODO USE THIS WHEN YOU WANT VALUES TO BE VALIDATED AS THE USER TYPES
        // const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT'].includes(i.nodeName));
        const {errors, hasError} = FormValidator.bulkValidate(inputs);
        const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9+-_!@#$%^&*()]{8,})$/;

        if (hasError) {
            this.setState({
                [form.name]: {
                    ...this.state[form.name],
                    errors
                },
                showAlert: false,
            });
        } else if (!passwordPattern.test(this.state.formReset.password1)) {
            this.setState({
                showAlert: true,
                alertVariant: 'danger',
                alertMessage: `Password must contain the following: A lowercase letter , A capital (uppercase) letter, A number and Allow special characters: +-_!@#$%^&*()`
            });
        } else {
            this.setState({
                loading: true,
                [form.name]: {
                    validated: true,
                    ...this.state[form.name],
                    errors
                }
            }, () => {
                confirmAccountAndSetPassword(this.state.formReset.password1, this.state.token)
                    .then((resp) => {
                        this.setState({
                            loading: false,
                            formReset: {
                                password1: '',
                                password2: ''
                            },
                            showAlert: true,
                            alertVariant: 'success',
                            alertMessage: `Your password has been successfully reset. Please login to continue.`,
                            resetSuccess: true
                        });
                    })
                    .catch(error => { // eslint-disable-line
                        this.setState({loading: false}, () => {
                            if (error.message === 'Unauthorized') {
                                this.setState({
                                    showAlert: true,
                                    alertVariant: 'danger',
                                    alertMessage: 'Reset failed. Password reset is only available for a limited time. Please submit your request again.',
                                    resetSuccess: false,
                                });
                            } else if (error.message === 'Bad Request') {
                                this.setState({
                                    showAlert: true,
                                    alertVariant: 'danger',
                                    alertMessage: 'Reset failed. Please check the password format and try again.',
                                    resetSuccess: false,
                                });
                            } else {
                                const err = JSON.parse(error.message);
                                this.setState({
                                    showAlert: true,
                                    alertVariant: 'danger',
                                    alertMessage: `Reset failed. There was a problem - ${err.error.message}`,
                                    resetSuccess: false,
                                });
                            }
                        });
                    });
            });
        }
    };

    /* Simplify error check */
    hasError = (formName, inputName, method) => this.state[formName]
        && this.state[formName].errors
        && this.state[formName].errors[inputName]
        && this.state[formName].errors[inputName][method];

    render() {
        if (this.state.redirect) {
            return (<Redirect to="/login"/>);
        }


        if (this.state.resetSuccess) {
            return (
                <Container fluid style={{overflow: 'hidden'}}>
                    <Row className="min-vh-100">
                        <Col className="bg-primary" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Image
                                src={logo}
                                width="350"
                                alt="ToolBeltData Logo"
                                fluid
                            />
                        </Col>
                        <Col style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Col xl={8} lg={8} md={12} sm={12}>
                                <Form noValidate validated={this.state.formReset.validated} name="formReset" onSubmit={this.onSubmit}>
                                    <div className="text-center">
                                        <h2>Setup your password</h2>
                                    </div>

                                    <Alert
                                        variant={this.state.alertVariant}
                                        show={this.state.showAlert}
                                        onClose={() => this.setState({showAlert: false})}
                                        dismissible
                                        transition={false}
                                    >
                                        {this.state.alertMessage}
                                    </Alert>
                                    <Form.Text className="text-center mt-2">
                                        <Link to="login" className="text-muted">Back to Login</Link>
                                    </Form.Text>
                                </Form>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            )
        };

        return (
            <Container fluid style={{overflow: 'hidden'}}>
                <Row className="min-vh-100">
                    <Col className="bg-primary" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Image
                            src={logo}
                            width="350"
                            alt="ToolBeltData Logo"
                            fluid
                        />
                    </Col>
                    <Col style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Col xl={8} lg={8} md={12} sm={12}>
                            <Form noValidate validated={this.state.formReset.validated} name="formReset" onSubmit={this.onSubmit}>
                                <div className="text-center">
                                    <h2>Setup your password</h2>
                                </div>

                                <Form.Group controlId="formResetPassword1">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password1"
                                        placeholder="8+ Characters Required"
                                        isInvalid={
                                            this.hasError('formReset', 'password1', 'required')
                                            || this.hasError('formReset', 'password1', 'minlen')
                                        }
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "minlen"]'
                                        data-param="8"
                                        value={this.state.formReset.password1}
                                    />
                                    {this.hasError('formReset', 'password1', 'required') && (
                                        <Form.Control.Feedback type="invalid">
                                            Password is required.
                                        </Form.Control.Feedback>
                                    )}
                                    {!this.hasError('formReset', 'password1', 'required')
                                    && this.hasError('formReset', 'password1', 'minlen')
                                    && (
                                        <Form.Control.Feedback type="invalid">
                                            Password must be at least 8 characters in length.
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group controlId="formResetPassword2">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        size="md"
                                        type="password"
                                        name="password2"
                                        placeholder="Confirm the password"
                                        isInvalid={
                                            this.hasError('formReset', 'password2', 'required')
                                            || this.hasError('formReset', 'password2', 'equalto')
                                        }
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "equalto"]'
                                        data-param="formResetPassword1"
                                        value={this.state.formReset.password2}
                                    />
                                    {this.hasError('formReset', 'password2', 'required') && (
                                        <Form.Control.Feedback type="invalid">
                                            Password is required.
                                        </Form.Control.Feedback>
                                    )}
                                    {
                                        !this.hasError('formReset', 'password2', 'required')
                                        && this.hasError('formReset', 'password2', 'equalto')
                                        && (
                                            <Form.Control.Feedback type="invalid">
                                                Passwords must match.
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                                <Alert
                                    variant={this.state.alertVariant}
                                    show={this.state.showAlert}
                                    onClose={() => this.setState({showAlert: false})}
                                    dismissible
                                    transition={false}
                                >
                                    {this.state.alertMessage}
                                </Alert>
                                <Button
                                    variant="info"
                                    type="submit"
                                    size="md"
                                    block
                                    className="pt-3 pb-3"
                                    disabled={this.state.loading}
                                >
                                    {!this.state.loading && <>SAVE</>}
                                    {this.state.loading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Button>
                                <Form.Text className="text-center mt-2">
                                    <Link to="login" className="text-muted">Back to Login</Link>
                                </Form.Text>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
        )
    }
}
