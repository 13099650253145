/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import PropTypes from 'prop-types';
import React, {useState} from 'react';

import './FormColorPalette.scss';
import FormLabel from "./FormLabel";

export function FormColorPalette(props) {
    const {
        label,
        labelStyle,
        disabled,
        value,
        handleChange,
        editing
    } = props;

    const [active, setActive] = useState(false);

    if (editing) {
        return (
            <>
                {label && (
                    <FormLabel>
                        {label}
                    </FormLabel>
                )}
                <div className="color-palette">
                    <label className="color-selector-editing">
                        <span className="circle" style={{ background: value }} />
                        <span>{value}</span>
                        <input
                            type="color"
                            value={value}
                            onChange={(e) => {
                                handleChange(e.target.value);
                                setActive(!active);
                            }}
                            className="hidden"
                            disabled={disabled}
                        />
                    </label>
                </div>
            </>
        );
    }

    return (
        <>
            {label && (
                <FormLabel>
                    {label}
                </FormLabel>
            )}
            <div className="color-palette">
                <label className="color-selector">
                    <span className="circle" style={{ background: value }} />
                    <span>{value}</span>
                </label>
            </div>
        </>
    );
}

FormColorPalette.propTypes = {
    editing: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    handleChange: PropTypes.func,
};
