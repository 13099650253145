/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';
import TableWithPaging from '../../common/components/TableWithPaging';
import {useDispatch, useSelector} from "react-redux";
import {getOrganizations, getOrganizationsCount, setCurrentPage, setOrder, setSelectedId} from "./organizationsSlice";
import {FormCheck, InputGroup} from "react-bootstrap";
import {getTypeById} from './OrganizationTypeses';
import './Organizations.scss';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import CheckBox from "../../common/components/CheckBox";

const OrganizationsTable = (props) => {

    const dispatch = useDispatch();
    const organizationsState = useSelector((state) => state.organizations);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getOrganizationsCount());
        dispatch(getOrganizations());
    }, [props.lastReload, dispatch]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // let image = tbdBrainLightningIcon;
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.id,
                                    isChecked: value
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Name',
                property: 'name',
                sortable: true,
                formatted: false
            },
            {
                id: 4,
                name: 'Abbrv',
                property: 'abbrv',
                sortable: true,
                formatted: false
            },
            {
                id: 5,
                name: 'Type',
                property: 'typeId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    return (<span>{getTypeById(row.typeId)}</span>);
                }
            },
            {
                id: 6,
                name: 'Owner',
                property: 'owner', // organization.owner.email
                sortable: false, // TODO sort this column
                formatted: true,
                formatBy: (row, header) => {
                    if (row.owner) {
                        if (row.owner.statusId === 'DISABLED') {
                            return (<div className="organizations-owner-inactive-email" > {row.owner.email}</div>)
                        } else if (row.owner.statusId === 'ACTIVE') {
                            return (<div className="organizations-owner-active-email"> {row.owner.email}</div>)
                        }
                        return (<div> {row.owner.email}</div>)
                    }
                    return (<span></span>);
                }
            },
            {
                id: 7,
                name: 'Members',
                property: '',// organization.members.length
                sortable: false, // TODO sort this column
                formatted: true,
                formatBy: (row, header) => {
                    if (row.members && row.members.length > 0) {
                        return (<div> {row.members.length} </div>);
                    }
                    return (<div>0</div>);
                }
            },
            {
                id: 8,
                name: 'Created',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getOrganizations());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'source-table-with-paging'}
                headers={headers}
                rows={organizationsState.organizations}
                countOfResults={organizationsState.totalRecords}
                resultsPerPage={organizationsState.pageSize}
                currentPage={organizationsState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
};


OrganizationsTable.propTypes = {
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

OrganizationsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    organizations: []
};
export default OrganizationsTable;
