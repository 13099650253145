/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import styled from "styled-components";
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH} from "@fortawesome/pro-solid-svg-icons";

const CardActionContainer = styled.div `
  justify-self: end;
  align-self: center;
  padding-right: 20px;
`;

const CardActionDropdown = styled(DropdownButton) `
  button {
    border: none !important;
    background-color: transparent !important;

    &:hover {
      border: none;
      background-color: transparent;
    }

    &:active {
      border: none !important;
      background-color: transparent !important;
    }
    
    &:after {
      display: none;
    }
  }
`;

export default function CardActionBox(props) {
    const {
        disabled,
        onChange
    } = props;

    const actionItems = [
        {
            label: 'EXPORT',
            href: '',
            onClickHandler: () => console.log('TEST')
        },
    ];

    return (
        <CardActionContainer>
            <CardActionDropdown
                id="dropdown-basic-button"
                variant="outline-secondary"
                disabled={disabled}
                title={
                    <div className="pull-left">
                        <FontAwesomeIcon
                            color={APP_COLORS['blue']}
                            icon={faEllipsisH}
                            style={{fontSize: '18px'}}
                        />
                    </div>
                }
                className='dropdownButton'
            >
                {actionItems.map((item, index) => {
                    return (
                        <Dropdown.Item
                            key={`dropdown-action-${index}`}
                            href={`${item.href}`}
                            disabled={!item.isActive}
                            onClick={item.onClickHandler}
                            onSelect={item.onSelectHandler}>
                            {`${item.label}`}
                        </Dropdown.Item >
                    );
                })}
            </CardActionDropdown>
        </CardActionContainer>
    )
}
