/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {FormCheck, InputGroup} from "react-bootstrap";
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {findIndex, assign, truncate} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {getAdminLists, getAdminListsCount, setCurrentPage, setOrder, setSelectedId} from "./adminListsSlice";

import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import TableWithPaging from '../../common/components/TableWithPaging';
import {getListStatusById, LIST_STATUSES} from "../../common/metadata/ListStatuses";
import { getTypeById } from "../../common/metadata/ListTypes";

import {APP_COLORS} from "../../common/Constants";
import styled from "styled-components";
import CheckBox from "../../common/components/CheckBox";

const RecordColumn = styled.div `
  width: 200px;
`;

const RecordCount = styled.span `
  color: ${APP_COLORS['orange']};
`;

const RecordText = styled.span `
  width: ${APP_COLORS['tech-blue']};
`;

const AdminListsTable = (props) => {
    const dispatch = useDispatch();
    const adminListsState = useSelector((state) => state.adminLists);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getAdminListsCount());
        dispatch(getAdminLists());
    }, [props.lastReload, dispatch]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // let image = tbdBrainLightningIcon;
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.id,
                                    name: row.name,
                                    type: row.type,
                                    status: row.status,
                                    isChecked: value
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Org Id',
                property: 'organizationId',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row.organizationId) {
                        return <TextHoverDisplay
                            truncatedText={row.organizationId.substring(0, 13)}
                            fullText={row.organizationId}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Type',
                property: 'type',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    const listTypeDef =  getTypeById(row.type);
                    if (listTypeDef) {
                        return (<span>{listTypeDef.display}</span>);
                    }
                    return (<span>{row.type}</span>);
                }
            },
            {
                id: 5,
                name: 'Name',
                property: 'name',
                sortable: true,
                formatted: false
            },
            {
                id: 6,
                name: 'Description',
                property: 'description',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    if (row.description) {
                       return <span> {`${truncate(row.description, {length: 100, omission: '...'})}`} </span>
                    }
                    return <span></span>
                }
            },
            {
                id: 7,
                name: 'Status',
                property: 'status',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    const listStatusDef =  getListStatusById(row.status);
                    if (listStatusDef.id === LIST_STATUSES.READY.id || listStatusDef.id === LIST_STATUSES.EXPORTED.id) {
                        return (<span style={{color: '#6da06f'}}>{listStatusDef.display}</span>)
                    } else  if (listStatusDef.id === LIST_STATUSES.CREATED.id || listStatusDef.id === LIST_STATUSES.CREATED.id) {
                        return (<span>{listStatusDef.display}</span>);
                    } else if (listStatusDef.id === LIST_STATUSES.ERROR.id) {
                        return (<span style={{color: '#c13727'}}>{listStatusDef.display}</span>);
                    } else {
                        return (<span style={{color: '#fdba43'}}>{listStatusDef.display}</span>);
                    }
                }
            },
            {
                id: 8,
                name: 'Records',
                property: 'dataCntRecords',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row.dataCntRecords) {
                        return (
                            <RecordColumn>
                                <RecordCount>{row.dataCntRecords.toLocaleString('en-US')}</RecordCount> <RecordText>Records</RecordText>
                            </RecordColumn>
                        )
                    } else {
                        return (<RecordColumn><RecordCount>N/A</RecordCount></RecordColumn>)
                    }
                }
            },
            {
                id: 9,
                name: 'Created',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getAdminLists());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'source-table-with-paging'}
                headers={headers}
                rows={adminListsState.adminLists}
                countOfResults={adminListsState.totalRecords}
                resultsPerPage={adminListsState.pageSize}
                currentPage={adminListsState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
};


AdminListsTable.propTypes = {
    adminLists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

AdminListsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    adminLists: []
};

export default AdminListsTable;
