/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';

import './ExploreMapFlyoutDetail.scss';

import ExploreMapFlyoutDetailNoSelection from './ExploreMapFlyoutDetailNoSelection';
import ExploreMapFlyoutDetailSelectedProductClustered from './ExploreMapFlyoutDetailSelectedProductClustered';
import ExploreMapFlyoutDetailSelectedProductRecord from './ExploreMapFlyoutDetailSelectedProductRecord';
import ExploreMapFlyoutDetailSelectedImportListLocation from './ExploreMapFlyoutDetailSelectedImportListLocation';
import ExploreMapFlyoutDetailSelectedImportListContact from './ExploreMapFlyoutDetailSelectedImportListContact';
import ExploreMapFlyoutDetailSelectedImportListCompany from './ExploreMapFlyoutDetailSelectedImportListCompany';
import ExploreMapFlyoutDetailSelectedSupplier from './ExploreMapFlyoutDetailSelectedSupplier';

export default function ExploreMapFlyoutDetail(props) {
    const selectedPoint = props.selectedPoint;
    // Is this should be a default one?.
    if (props.exploreMapFlyoutDetailMode === 'NONE') {
        return <ExploreMapFlyoutDetailNoSelection/>
    }

    // List Import Location
    if (
        props.exploreMapFlyoutDetailMode === 'OVERLAY'
        && selectedPoint
        && selectedPoint.type === 'OVERLAY'
    ) {
        return <ExploreMapFlyoutDetailSelectedImportListLocation {...props} />
    }

    // List Import Company
    if (
        props.exploreMapMode === 'ADDRESS'
        && props.exploreMapFlyoutDetailMode === 'UNCLUSTERED'
        && selectedPoint
        && selectedPoint.type === 'OVERLAY'
        && selectedPoint.properties
        && selectedPoint.properties.listType === 'IMPORT_COMPANY'
    ) {
        return <ExploreMapFlyoutDetailSelectedImportListCompany {...props} />
    }

    // List Import Contact
    if (
        props.exploreMapMode === 'ADDRESS'
        && props.exploreMapFlyoutDetailMode === 'UNCLUSTERED'
        && selectedPoint
        && selectedPoint.type === 'OVERLAY'
        && selectedPoint.properties
        && selectedPoint.properties.listType === 'IMPORT_CONTACT'
    ) {
        return <ExploreMapFlyoutDetailSelectedImportListContact {...props} />
    }

    if (
        props.exploreMapFlyoutDetailMode === 'PRODUCT_DATA_CLUSTERED'
        && selectedPoint
        && selectedPoint.type === 'PRODUCT'
    ) {
        return <ExploreMapFlyoutDetailSelectedProductClustered {...props} />
    }

    if (
        props.exploreMapFlyoutDetailMode === 'PRODUCT_DATA_RECORD_POINT'
        && selectedPoint && selectedPoint.type === 'PRODUCT'
    ) {
        return <ExploreMapFlyoutDetailSelectedProductRecord {...props} />
    }

    // SUPPLIER
    if (
        props.exploreMapMode === 'ADDRESS'
        && props.exploreMapFlyoutDetailMode === 'UNCLUSTERED'
        && selectedPoint
        && selectedPoint.type === 'OVERLAY'
        && selectedPoint.properties
        && selectedPoint.properties.listType === 'SUPPLIER'
    ) {
        return <ExploreMapFlyoutDetailSelectedSupplier {...props} />
    }

    return null;
}
