/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const SpinnerContainer = styled.div `
  position: absolute;
  width: ${props => props.customWidth ? props.customWidth : '100%'};
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.15); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SpinnerOverlay = (props) => {
    const {
        customHeight,
        customWidth,
    } = props;

    const body = document.body;
    const html = document.documentElement;

    const height = Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight );

    return (
        <SpinnerContainer style={{height: customHeight ? customHeight : height}} customWidth={customWidth}>
            <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                variant="info"
            />
        </SpinnerContainer>
    );
}

export default SpinnerOverlay;
