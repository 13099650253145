import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    suppliers: []
};

export const getSuppliers = createAsyncThunk('sources/get-all', async (arg, {getState, requestId}) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/suppliers`;
    const suppliers = await Api.get(true, GET_SOURCE_URL);
    return suppliers;
});

export const getSuppliersWithFilter = createAsyncThunk('suppliers', async (filter) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/suppliers`;
    const suppliers = await Api.get(true, GET_SOURCE_URL, filter);
    return suppliers;
});

const suppliersSlice = createSlice({
    name: 'suppliersSlice', initialState,
    reducers: {
    },
    extraReducers: {
        [getSuppliers.pending]: (state, action) => {
            state.loading = true;
        },
        [getSuppliers.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.suppliers = action.payload;
            }
        },
        [getSuppliers.rejected]: (state, action) => {
            state.loading = false;
        },
        [getSuppliersWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getSuppliersWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getSuppliersWithFilter.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})

export const {} = suppliersSlice.actions;
export default suppliersSlice.reducer;
