/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormLabel,
    FormControl

} from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './FormDatePicker.scss';
import moment from "moment/moment";
import {APP_COLORS} from "../Constants";
import {faLock} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";

const DivCalRelative = styled.div `
  position: relative;
`;

const DivCalAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -30px;
`;

const DivLockRelative = styled.div `
  position: relative;
`;

const DivLockAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -30px;
`;

const FormDatePicker = (props) => {
    const selectedDate = props.value ? moment(props.value, props.format).toDate() : undefined;
    const CustomInputDate = React.forwardRef(({ value, onClick }, ref) => {
        const format = props.format || 'YYYY-MM-DD';
        // value is a string date with format MM-DD-YYYY
        const formatValue = value ? moment(value, 'MM-DD-YYYY').format(format) : '';
        return(
            <FormGroup
                style={props.style}
                controlId={props.controlId}
                bssize={props.bsSize}
                onBlur={props.onBlur}
            >
                {props.label &&
                <FormLabel className={"formTextLabel"}>{props.label}</FormLabel>
                }
                <div className="date-input-layout">
                    <FormControl
                        value={formatValue}
                        onChange={(e) => props.onChange(e.target.value)}
                        disabled={props.disabled}
                        placeholder={props.format}
                        size={props.size}
                        type={"text"}
                        isInvalid={props.isInvalid}
                        onClick={onClick}
                    />
                    {(!props.disabled || !props.lockIcon) && (
                        <DivCalRelative>
                            <DivCalAbsolute>
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    color={APP_COLORS['tech-blue']}
                                    style={{marginRight: '10px', fontSize: 17}} />
                            </DivCalAbsolute>
                        </DivCalRelative>
                    )}
                    {props.disabled && props.lockIcon && (
                        <DivLockRelative>
                            <DivLockAbsolute>
                                <FontAwesomeIcon
                                    color={APP_COLORS['yellow']}
                                    icon={faLock}
                                    style={{marginRight: '10px', fontSize: 17}}
                                />
                            </DivLockAbsolute>
                        </DivLockRelative>
                    )}
                </div>
                {props.validation.state && (
                    <FormControl.Feedback type='invalid'>
                        {props.validation.message || 'Invalid'}
                    </FormControl.Feedback>
                )}
            </FormGroup>
        );
    });
    return (
        <div className="datePicker">
            <DatePicker
                selected={selectedDate}
                onChange={date => {
                    props.onChange(date);
                }}
                customInput={<CustomInputDate
                />}
            />
        </div>
    );
};

FormDatePicker.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'small', 'lg', 'large']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isInvalid: PropTypes.bool,
    validation: PropTypes.shape({
        state: PropTypes.oneOf(['error', 'warning']),
        message: PropTypes.string,
    }),
    lockIcon: PropTypes.bool,
};

FormDatePicker.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    onChange: e => e,
    onBlur: e => e,
    isInvalid: false,
    validation: {},
    size: 'sm',
    lockIcon: false,
    format: 'YYYY-MM-DD'
};

export default FormDatePicker;
