/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';

import ContractorDirectoryContainer from "./ContractorDirectoryContainer";

export default class ContractorDirectoryRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/tools/contractor-directory" component={ContractorDirectoryContainer} />
            </Switch>
        );
    }
}
