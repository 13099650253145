/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import './RangeSlider.scss'

function RangeSlider(props) {
    const [value, setValue] = useState({
        min: props.value ?  props.value.min : 0,
        max: props.value ? props.value.max: 0,
    });

    useEffect(() => {
        setValue({
            min: props.value ?  props.value.min : 0,
            max: props.value ? props.value.max: 0,
        });
    }, [props.value]);

    return (
        <div style={props.styles} className="rangeSliderContainer">
            {props.name && (
                <div className="rangeSliderLabel">
                    <div className="rangeSliderNameLabel">{props.name}</div>
                    <div className="rangeSliderMinMaxLabel">{value.min} - {value.max}</div>
                </div>
            )}
            <div>
                <InputRange
                    draggableTrack
                    maxValue={props.max}
                    minValue={props.min}
                    onChange={ value => {
                        if (Number.isInteger(value.min) && Number.isInteger(value.max)) {
                            setValue({
                                min: value.min,
                                max: value.max
                            });
                            return;
                        }
                        setValue({
                            min: Number(value.min.toFixed(2)),
                            max: Number(value.max.toFixed(2))
                        });

                    } }
                    onChangeComplete={value => props.onChangeComplete(value)}
                    value={value}
                    disabled={props.disabled}
                    step={props.step || 1}
                />
            </div>
        </div>
    );
}

RangeSlider.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.shape({
            min: PropTypes.number.isRequired,
            max: PropTypes.number.isRequired,
    }),
    onChangeComplete: PropTypes.func.isRequired,
};

export default RangeSlider;
