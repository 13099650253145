import React, {useState, useEffect, useMemo} from "react";
import {editSource, getSourceById, clearState} from "./sourceSlice";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import FormLabel from "../../common/components/form/FormLabel";
import {Row, Col, Button, Form} from 'react-bootstrap';
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import FormTextInput from "../../common/components/FormTextInput";
import FormTextArea from "../../common/components/form/FormTextArea";
import {TYPES} from "./SourceTypes";

import FormSelect from "../../common/components/form/FormSelect";
import FormNumericInput from "../../common/components/FormNumericInput";
import CheckBox from "../../common/components/CheckBox";
import {INDICATOR_TYPES} from "../../common/metadata/Indicators";
import {values, filter} from "lodash";
import {Alert} from "react-bootstrap";
import {isEmpty} from "lodash";
import {STATES} from "../../common/States";

const EditSourceContainer = (props) => {

    const managedSourceState = useSelector(state => state.source);
    const dispatch = useDispatch();
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (props.match.params.id) {
            dispatch(getSourceById(props.match.params.id));
        }
    }, [dispatch, props.match.params.id]);

    useEffect(() => {
        if (managedSourceState.managedSource) {
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Sources',
                        href: '/admin/sources',
                        isActive: false
                    },
                    {
                        label: `${managedSourceState?.managedSource.id}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: `Edit Source`,
                        href: '',
                        isActive: true
                    }
                ]
            )
        }
    }, [managedSourceState.managedSource]);

    useEffect(() => {
        setAlertMessage(managedSourceState.alertMessage);
        if (managedSourceState.alertMessage) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedSourceState.alertMessage]);

    useEffect(() => {
        setSuccessMessage(managedSourceState.successMessage);
        if (managedSourceState.successMessage) {
            setShowSuccess(true);
        } else {
            setShowSuccess(false);
        }
    }, [managedSourceState.successMessage]);


    const handleEditSource = async (data) => {
        dispatch(editSource({id: props.match.params.id, data: data}));
    };
    const handleCancel = async () => {
        dispatch(clearState());
        history.push('/admin/sources')
    };

    return (
        <div>
            {managedSourceState.loading && (
                <SpinnerOverlay/>
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            {
                managedSourceState.managedSource && (
                    <EditSource
                        id={managedSourceState.managedSource?.id}
                        handleEditSource={(data) => handleEditSource(data)}
                        handleCancel={() => handleCancel()}
                        breadcrumbItems={breadcrumbItems}
                        source={managedSourceState.managedSource}
                    />
                )
            }
        </div>
    );
};

function EditSource(props) {

    const {
        breadcrumbItems,
        handleEditSource,
        handleCancel,
        source
    } = props;

    const [formValid, setFormValid] = useState(true);
    const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState(null);
    const [sourceName, setSourceName] = useState(source.name);
    const [sourceLabel, setSourceLabel] = useState(source.label);
    const [sourceDescription, setSourceDescription] = useState(source.description || '');
    const [sourceUrl, setSourceUrl] = useState(source.url || '');
    const [sourceRegion, setSourceRegion] = useState(null);
    const [sourceCity, setSourceCity] = useState(source.city || '');
    const [sourceQualityScore, setSourceQualityScore] = useState(source.qualityScore);
    const [sourceAuthoritative, setSourceAuthoritative] = useState(source.authoritative);
    const [sourceIsPublic, setSourceIsPublic] = useState(source.public)
    const [selectedSourceProductOptions, setSelectedSourceProductOptions] = useState([]);
    const [selectedSourceIndicatorOptions, setSelectedSourceIndicatorOptions] = useState([]);

    const typeOptions = values(TYPES).map((typ) => {
        return {value: typ.id, label: typ.display};
    });

    const regionOptions = values(STATES).map((state) => {
        return {value: state.id, label: state.display};
    });

    useEffect(() => {
        setSelectedSourceTypeOption(typeOptions.find(a => a.value === source.type));
        setSourceRegion(regionOptions.find(a => a.value === source.region))
    }, [source.type]);

    const productOptions = [
        {value: 'b2b', label: 'b2b'},
        {value: 'tcu', label: 'tcu'}
    ];

    useEffect(() => {
        if (source.products && source.products.length > 0) {
            const _selectedSourceProductOptions = [];
            source.products.forEach(element => {
                _selectedSourceProductOptions.push(productOptions.find(a => a.value === element));
            });
            setSelectedSourceProductOptions(_selectedSourceProductOptions);
        }
    }, [source.products]);

    useEffect(() => {
        if (source.indicators && source.indicators.length > 0) {
            const _selectedSourceIndicatorOptions = [];
            source.indicators.forEach(element => {
                const ind = values(INDICATOR_TYPES).find(a => a.id === element);
                _selectedSourceIndicatorOptions.push({
                        value: ind.id,
                        label: ind.display
                    }
                )
            })
            setSelectedSourceIndicatorOptions(_selectedSourceIndicatorOptions);
        }
    }, [source.indicators]);

    const isValid = () => {
        if (isEmpty(sourceName)) return false;
        if (isEmpty(sourceLabel)) return false;
        if (isEmpty(selectedSourceProductOptions)) return false;
        return true;
    };


    const handleEditSourceClick = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            setFormValid(true);
            const data = {
                type: selectedSourceTypeOption.value,
                name: sourceName,
                label: sourceLabel,
                description: isEmpty(sourceDescription) ? null : sourceDescription,
                url: isEmpty(sourceUrl) ? null : sourceUrl,
                region: isEmpty(sourceRegion) ? null : sourceRegion.value,
                city: isEmpty(sourceCity) ? null : sourceCity,
                qualityScore: parseFloat(sourceQualityScore),
                authoritative: sourceAuthoritative,
                public: sourceIsPublic,
                products: selectedSourceProductOptions.map((i) => i.value),
                indicators: selectedSourceIndicatorOptions.length > 0 ? selectedSourceIndicatorOptions.map((i) => i.value) : null,
            };
            handleEditSource(data);
        }
    };

    const onInputValidate = (value, name) => {
        setError((prev) => ({
            ...prev,
            [name]: {...prev[name], errorMsg: value},
        }));
    };

    const [error, setError] = useState({
        sourceName: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
        sourceLabel: {
            isReq: true,
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
        sourceUrl: {
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
        sourceRegion: {
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
        sourceCity: {
            errorMsg: '',
            onValidateFunc: onInputValidate,
        },
    });

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Edit Source'} subHeader={'Edit (change) the source'}/>
            <Panel>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="editSource">
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={9}>
                                            <FormLabel>Source ID</FormLabel>
                                            {source.id &&
                                                <TextHoverDisplay
                                                    fullText={source.id}
                                                    truncatedText={source.id}
                                                    displayTruncated={false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Type'}
                                                options={values(TYPES).map((t) => ({value: t.id, label: t.display}))}
                                                onChange={(v) => setSelectedSourceTypeOption(v)}
                                                value={selectedSourceTypeOption}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'Name'}
                                                label={'Name'}
                                                name={'sourceName'}
                                                value={sourceName}
                                                onChange={value => setSourceName(value)}
                                                isInvalid={!formValid && !sourceName}
                                                size={'sm'}
                                                min={4}
                                                required
                                                {...error.sourceName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'Label'}
                                                label={'Label'}
                                                name={'sourceLabel'}
                                                value={sourceLabel}
                                                onChange={value => setSourceLabel(value)}
                                                isInvalid={!formValid && !sourceLabel}
                                                min={4}
                                                size={'sm'}
                                                required
                                                {...error.sourceLabel}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextArea
                                                label={'Description'}
                                                onChange={value => setSourceDescription(value)}
                                                value={sourceDescription}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'URL'}
                                                label={'URL'}
                                                name={'sourceUrl'}
                                                value={sourceUrl}
                                                onChange={value => setSourceUrl(value)}
                                                reqType={'URL'}
                                                size={'sm'}
                                                {...error.sourceUrl}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            {/*<FormTextInput*/}
                                            {/*    title={'Region'}*/}
                                            {/*    label={'Region'}*/}
                                            {/*    name={'sourceRegion'}*/}
                                            {/*    value={sourceRegion}*/}
                                            {/*    onChange={value => setSourceRegion(value)}*/}
                                            {/*    size={'sm'}*/}
                                            {/*    {...error.sourceRegion}*/}
                                            {/*/>*/}
                                            <FormSelect
                                                label={'Region'}
                                                name={'sourceRegion'}
                                                options={values(STATES).map((t) => ({
                                                    value: t.id,
                                                    label: t.display
                                                }))}
                                                onChange={(v) => setSourceRegion(v)}
                                                value={sourceRegion}
                                                {...error.sourceRegion}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                title={'City'}
                                                label={'City'}
                                                name={'sourceCity'}
                                                value={sourceCity}
                                                onChange={value => setSourceCity(value)}
                                                size={'sm'}
                                                {...error.sourceCity}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormNumericInput
                                                label={'Quality Score'}
                                                value={sourceQualityScore}
                                                onChange={value => setSourceQualityScore(value)}
                                                step={props.step || 0.05}
                                                min={0.01}
                                                max={0.99}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Authoritative'}
                                                checked={sourceAuthoritative}
                                                onChange={value => setSourceAuthoritative(value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <CheckBox
                                                label={'Public'}
                                                checked={sourceIsPublic}
                                                onChange={value => setSourceIsPublic(value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Products'}
                                                options={productOptions}
                                                onChange={value => setSelectedSourceProductOptions(value)}
                                                value={selectedSourceProductOptions}
                                                isMulti
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormSelect
                                                label={'Indicators'}
                                                options={filter(values(INDICATOR_TYPES), (i) => {
                                                    return !!i.display
                                                }).map((t) => ({value: t.id, label: t.display})
                                                )}
                                                onChange={value => setSelectedSourceIndicatorOptions(value)}
                                                value={selectedSourceIndicatorOptions}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleEditSourceClick()}
                                    >EDIT
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );

}

export default EditSourceContainer;
