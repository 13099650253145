
import _ from 'lodash';
export const TYPES =
    {
        /*PROFILE*/ // eslint-disable-line
        ATLAS: {
            id: 'ATLAS',
            display: 'ToolBeltData Profile',
            description: 'A profile source type for all internal profiling activities',
        },
        ASSOCIATION: {
            id: 'ASSOCIATION',
            display: 'Association',
            description: 'A profile source type for company associations or affiliations',
        },
        MANUFACTURER: {
            id: 'MANUFACTURER',
            display: 'Manufacturer',
            description: 'A profile source type for manufacturer profiles (eg find-a-dealer)',
        },
        ONLINE: {
            id: 'ONLINE',
            display: 'Online',
            description: 'A profile source type for online profiles (eg google) not otherwise typed',
        },
        PLACE: {
            id: 'PLACE',
            display: 'Place',
            description: 'A profile source type for places',
        },
        SUPPLIER: {
            id: 'SUPPLIER',
            display: 'Supplier',
            description: 'A profile source type for supplier',
        },
        THIRD_PARTY: {
            id: 'THIRD_PARTY',
            display: 'Third Party',
            description: 'A profile source type for third party profiles',
        },
        TRADE_PUBLICATION: {
            id: 'TRADE_PUBLICATION',
            display: 'Trade Publication',
            description: 'A profile source type for trade publications',
        },
        UTILITY: {
            id: 'UTILITY',
            display: 'Utility',
            description: 'A profile source type for utility profiles (eg trade partners, contractor locators)',
        },
        /*END PROFILE*/ // eslint-disable-line
        LICENSOR: {
            id: 'LICENSOR',
            display: 'Licensor',
            description: 'A source type for licensors',
        },
        PERMITTING_AUTHORITY: {
            id: 'PERMITTING_AUTHORITY',
            display: 'Permitting Authority',
            description: 'A source type for permitting authorities',
        },
        POLICY_PROVIDER: {
            id: 'POLICY_PROVIDER',
            display: 'Policy Provider',
            description: 'A source type for policy providers',
        },
        REGISTRAR: {
            id: 'REGISTRAR',
            display: 'Registrar',
            description: 'A source type for registrars',
        },
        LEGACY: {
            id: 'LEGACY',
            display: 'Legacy',
            description: 'A source type for legacy sources not otherwise typed',
        },
    }

    export function getTypeById(id) {
        if (id) {
            return _.get(TYPES, id);
        }
    }
