/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars

import ExploreMap from './ExploreMap';

/**
 * ExploreMapContainer
 */
export default function ExploreMapContainer(props) {
    return (
        <ExploreMap {...props} />
    );
}
