/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from "react";

import {Button, Col, Form, Row} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import isEmail from "validator/es/lib/isEmail";
import BreadCrumb from "../../../common/components/BreadCrumb";

import FormSearchOrganizationsTypeahead from "../organizations/FormSearchOrganizationsTypeahead";
import FormTextInput from "../../../common/components/FormTextInput";
import PanelHeader from "../../../common/components/layout/PanelHeader";

import {isArray, isEmpty} from "lodash";

import Container from "../../../common/components/layout/Container";
import Panel from "../../../common/components/layout/Panel";
import FormContainer from "../../../common/components/layout/FormContainer";
import SpinnerOverlay from "../../../common/components/SpinnerOverlay";
import FormSelect from "../../../common/components/form/FormSelect";
import CheckBox from "../../../common/components/CheckBox";

export default function CreateManageAccount(props) {
    const {
        breadcrumbItems,
        account,
        handleOnCreate,
        handleOnCancel,
        searchOrganization = false,
        alertMessage,
        successMessage,
        loading = false
    } = props;

    const [createSuccessMessage, setCreateSuccessMessage] = useState('');
    const [createAlertMessage, setCreateAlertMessage] = useState('');

    // Form
    const [formValid, setFormValid] = useState(true);
    const [organization, setOrganization] = useState(account.organization);
    const [givenName, setGivenName] = useState(account.givenName);
    const [surName, setSurName] = useState(account.surName);
    const [email, setEmail] = useState(account.email);
    const [role, setRole] = useState(account.role);
    const [sendEmail, setSendEmail] = useState(account.sendEmail);

    const isValid = () => {
        if (organization.length === 0) return false;
        if (isEmpty(givenName)) return false;
        if (isEmpty(surName)) return false;
        if (isEmpty(email) || !isEmail(email)) return false;

        return true;
    };

    useEffect(() => {
        setOrganization(account.organization);
        setGivenName(account.givenName);
        setSurName(account.surName);
        setEmail(account.email);
        setRole(account.role);
        setSendEmail(account.sendEmail);
    }, [account]);

    useEffect(() => {
        if (successMessage) {
            setCreateSuccessMessage(successMessage);
        }
    }, [successMessage]);

    useEffect(() => {
        if (alertMessage) {
            setCreateAlertMessage(alertMessage);
        }
    }, [alertMessage]);

    const handleCreateAccount = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            const data = {
                    givenName: givenName,
                    surName: surName,
                    email: email,
                    sendEmail: sendEmail,
                    username: email,
                    roles: isArray(role.value) ? role.value : [role.value],
                    organizationId: isArray(organization) ? organization[0].id : organization.id,

            };
            handleOnCreate(data);
        }
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader header={'Create Account'} subHeader={'Create a new account'} />
            <Panel>
                {loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={!isEmpty(createAlertMessage)}
                    onClose={() => setCreateAlertMessage('')}
                    dismissible
                    transition={false}
                >
                    {createAlertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={!isEmpty(createSuccessMessage)}
                    onClose={() => setCreateSuccessMessage('')}
                    dismissible
                    transition={false}
                >
                    {createSuccessMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createOrganization">
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            <FormSearchOrganizationsTypeahead
                                                label={'Organization'}
                                                onChange={setOrganization}
                                                organization={organization}
                                                isInvalid={!formValid && organization.length === 0}
                                                editing={searchOrganization}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Given Name'}
                                                value={givenName}
                                                onChange={value => setGivenName(value)}
                                                isInvalid={!formValid && !givenName}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormTextInput
                                                label={'Surname'}
                                                value={surName}
                                                onChange={value => setSurName(value)}
                                                isInvalid={!formValid && !surName}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormTextInput
                                                label={'Email'}
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={value => setEmail(value)}
                                                isInvalid={!formValid && (!email || !isEmail(email))}
                                                size={'sm'}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormSelect
                                                label={'Role'}
                                                options={[
                                                    {value: 'ADMINISTRATOR', label: 'Administrator'},
                                                    {value: 'DEVELOPER', label: 'Developer'},
                                                    {value: 'USER', label: 'User'},
                                                ]}
                                                onChange={(v) => setRole(v)}
                                                value={role}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <CheckBox
                                                label={'Send Welcome Email'}
                                                checked={sendEmail}
                                                onChange={value => setSendEmail(value)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateAccount()}
                                    >CREATE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => handleOnCancel()}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}
