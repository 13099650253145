/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

export const URLS = {
    // TODO: If i want /org or another prefix everywhere this will need to be conditionally built
    admin: {
        data: {
            base: '/admin/data',
            companies: {
                createCompany: '/admin/data/companies/create-company',
            }
        }
    },
    common: {
        products: {
            base: '/products',
            feelingLucky: '/products/feeling-lucky',
        }
    },
    organizations: {
        assets: {
            base: '/org/assets',
            createAsset: '/org/assets/create',
        },
        applications: {
            base: '/org/manage/applications',
            createApplication: '/org/manage/applications/create',
        },
        team: {
            base: '/org/manage/team',
            createTeamMember: '/org/manage/team/create-team-member',
        }
    }
};

// TODO: Update these with the bootstrap colors
const APP_COLORS = {
    'white': '#fff',
    'gray-100': '#f8f9fa',
    'gray-200': '#e9ecef',
    'gray-300': '#dee2e6',
    'gray-400': '#ced4da',
    'gray-500': '#adb5bd',
    'gray-600': '#6c757d',
    'gray-700': '#495057',
    'gray-800': '#343a40',
    'gray-900': '#212529',
    'gray-card': '#A6A6A6',
    'black': '#000',
    'blue': '#2d5d7e',
    'power-blue': '#2c354f',
    'indigo': '#6610f2',
    'purple': '#6f42c1',
    'pink': '#e83e8c',
    'red': '#c13727',
    'orange': '#ee5a38',
    'yellow': '#fdba43',
    'green': '#6da06f',
    'teal': '#20c997',
    'cyan': '#17a2b8',
};

APP_COLORS.primary = APP_COLORS.blue;
APP_COLORS.secondary = APP_COLORS['tech-blue'];
APP_COLORS.success = APP_COLORS.green;
APP_COLORS.info = APP_COLORS.orange;
APP_COLORS.warning = APP_COLORS.yellow;
APP_COLORS.danger = APP_COLORS.red;
APP_COLORS.light = APP_COLORS['grey-100'];
APP_COLORS.dark = APP_COLORS['gray-800'];
APP_COLORS['tech-blue'] = APP_COLORS.blue;

export {APP_COLORS};
