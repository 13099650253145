/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    shouldDisplayActionDownload,
    shouldDisplayActionMatch,
    shouldDisplayActionMatchCheck,
    shouldDisplayActionNormalize,
    shouldDisplayActionParse,
    shouldDisplayActionProcess,
    shouldDisplayActionSync,
    shouldDisplayActionReSync
} from '../../common/services/AdminListService';

import {
    sortBy,
} from 'lodash';

import AdminListsTable from "./AdminListsTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getAdminListsCount, getAdminLists
} from "./adminListsSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";
import {clearState} from "./adminListSlice";

const AdminLists = (props) => {
    return (
        <AdminListsComponent
        />
    );
};

const AdminListsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Lists',
        href: '',
        isActive: true
    }];
    const adminListsState = useSelector(state => state.adminLists);
    const adminListState = useSelector(state => state.adminList);
    const dispatch = useDispatch();

    const history = useHistory();

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (adminListsState.alertMessage) {
            setAlertMessage(adminListsState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [adminListsState.alertMessage]);

    const isReload = adminListsState.reload === true;

    useEffect(() => {
        dispatch(getAdminListsCount());
        dispatch(getAdminLists());
    }, [isReload, dispatch]);

    useEffect(() => {
        setSuccessMessage(adminListState.successMessage);
        console.log(adminListState);
        if (adminListState.successMessage) {
            setShowSuccess(true);
            setTimeout(
                function() {
                    dispatch(clearState());
                    setShowSuccess(false);
                },
                2000
            );
        }else {
            setShowSuccess(false);
        }
    }, [adminListState.successMessage]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [];

        actionItems.push(
            {
                label: 'DOWNLOAD',
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/download`),
                isActive: adminListsState.selectedLists &&
                adminListsState.selectedLists.length === 1 &&
                shouldDisplayActionDownload(adminListsState.selectedLists[0])
            }
        );

        actionItems.push(
            {
                label: 'EDIT',
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/edit`),
                isActive: adminListsState.selectedLists && adminListsState.selectedLists.length === 1
            }
        );

        actionItems.push(
            {
                label: 'Match'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/match`),
                isActive: adminListsState.selectedLists &&
                adminListsState.selectedLists.length === 1 &&
                shouldDisplayActionMatch(adminListsState.selectedLists[0]),

            }
        );

        actionItems.push(
            {
                label: '- Check'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/match-check`),
                isActive: adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1 &&
                    shouldDisplayActionMatchCheck(adminListsState.selectedLists[0]),
            }
        );

        actionItems.push(
            {
                label: 'Normalize'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/normalize`),
                isActive:
                    adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1 &&
                    shouldDisplayActionNormalize(adminListsState.selectedLists[0]),


            }
        );

        actionItems.push(
            {
                label: 'Parse'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/parse`),
                isActive: (
                    adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1 &&
                    shouldDisplayActionParse(adminListsState.selectedLists[0])
                ),

            }
        );

        actionItems.push(
            {
                label: 'Process'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/process`),
                isActive: (
                    adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1 &&
                    shouldDisplayActionProcess(adminListsState.selectedLists[0])
                ),
            }
        );

        // Deprecated in favor of keeping import * record lists out of Athena
        // actionItems.push(
        //     {
        //         label: 'Sync'.toUpperCase(),
        //         href: '',
        //         onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/sync`),
        //         isActive: (
        //             adminListsState.selectedLists &&
        //             adminListsState.selectedLists.length === 1 &&
        //             shouldDisplayActionSync(adminListsState.selectedLists[0])
        //         ),
        //     }
        // );

        actionItems.push(
            {
                label: 'Re-Sync'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/re-sync`),
                isActive: (
                    adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1 &&
                    shouldDisplayActionReSync(adminListsState.selectedLists[0])
                ),
            }
        );

        actionItems.push(
            {
                label: 'View As JSON'.toUpperCase(),
                href: '',
                onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/view-as-json`),
                isActive: (
                    adminListsState.selectedLists &&
                    adminListsState.selectedLists.length === 1
                    // adminListsState.selectedLists[0].status === 'NORMALIZED'
                ),

            }
        );

        // DISABLING HAS NOT BEEN TESTED
        // actionItems.push(
        //     {
        //         label: 'Create Match Report'.toUpperCase(),
        //         href: '',
        //         onClickHandler: () => history.push(`/admin/lists/${adminListsState.selectedId[0]}/create-list-match-report`),
        //         isActive: (
        //             adminListsState.selectedLists &&
        //             adminListsState.selectedLists.length === 1 &&
        //             adminListsState.selectedLists[0].status === 'READY' &&
        //             (adminListsState.selectedLists[0].type === 'MATCH_COMPANY' ||  adminListsState.selectedLists[0].type === 'MATCH_CONTACT')
        //         ),
        //     }
        // );

        return actionItems
    };

    return (
        <Container>
            <Alert
                variant={"success"}
                show={showSuccess}
                onClose={() => setShowSuccess(!showSuccess)}
                dismissible
                transition={false}
            >
                {successMessage}
            </Alert>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Lists (${adminListsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/lists/import')}
                        >
                            IMPORT
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id, organization id, name, description, type or status'}
                        value={adminListsState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {adminListsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <AdminListsTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default AdminLists;
