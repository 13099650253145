

import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    FormGroup,
    FormLabel,

} from 'react-bootstrap';
import './FormDropdown.scss';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_COLORS} from "../Constants";
import {faLock} from "@fortawesome/pro-solid-svg-icons";

const DivLockRelative = styled.div `
  position: relative;
`;

const DivLockAbsolute = styled.div `
  position: absolute;
  
  right: 0;
  top: -25px;
`;

function ComboBox(props) {
    const {
        id,
        style,
        size,
        className,
        label,
        placeholder,
        disabled,
        onChange,
        options,
        labelKey,
        multiple,
        selected,
        clearButton,
        isLoading
    } = props;
    return (
        <div id={id} className={className} style={style}>
            <FormGroup
            >
                <FormLabel className={"formTextLabel"}>
                    {label}
                </FormLabel>
                <Typeahead
                    id={`combo-box-${id}`}
                    clearButton={clearButton}
                    labelKey={labelKey}
                    size={size}
                    options={options}
                    placeholder={placeholder}
                    disabled={disabled}
                    multiple={multiple}
                    ignoreDiacritics
                    selected={selected}
                    positionFixed={true}
                    isLoading={isLoading}
                    onChange={(model) => {
                        return onChange(model);}
                    }

                />
                {props.disabled && props.lockIcon && (
                    <DivLockRelative>
                        <DivLockAbsolute>
                            <FontAwesomeIcon
                                color={APP_COLORS['yellow']}
                                icon={faLock}
                                style={{marginRight: '10px', fontSize: 17}}
                            />
                        </DivLockAbsolute>
                    </DivLockRelative>
                )}
            </FormGroup>
        </div>
    )
}

ComboBox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    toggleStyle: PropTypes.object,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func.isRequired,
    labelKey: PropTypes.func,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    selected: PropTypes.arrayOf(
    PropTypes.shape({
            id: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
        })
    ),
    clearButton: PropTypes.bool,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'small', 'md', 'lg', 'large']),
    lockIcon: PropTypes.bool,
};

ComboBox.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    validation: {},
    variant: 'link',
    disabled: false,
    options: [],
    value: {},
    size: 'sm',
    toggleStyle: {textDecoration: 'none'},
    onChange: e => e,
    labelKey: (option) => option.display,
    multiple: false,
    clearButton: false,
    label: '',
    lockIcon: false,
};
export default ComboBox;
