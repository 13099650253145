import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    companyEmail: null
};

export const getCompanyEmailById = createAsyncThunk('companies/getCompanyEmailById', async (id) => {
    const GET_COMPANY_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${id}/email`;
    const companyEmail = await Api.get(true, GET_COMPANY_EMAIL_BY_ID_URL);
    return companyEmail;
});

export const changeCompanyEmailById = createAsyncThunk('companies/changeCompanyEmailById', async (data) => {
    const CHANGE_COMPANY_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/email/change`;
    const response = await Api.post(true, CHANGE_COMPANY_EMAIL_BY_ID_URL, data.body);
    return response;
});

export const createCompanyEmailById = createAsyncThunk('companies/createeCompanyEmailById', async (data) => {
    const CREATE_COMPANY_EMAIL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/email`;
    const response = await Api.post(true, CREATE_COMPANY_EMAIL_BY_ID_URL, data.body);
    return response;
});

const companyEmailSlice = createSlice({
    name: 'companyEmailSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.companyEmail = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyEmail = action.payload;
            }
        },
        [getCompanyEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
        [changeCompanyEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeCompanyEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyEmail = action.payload;
            }
        },
        [changeCompanyEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },[createCompanyEmailById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [createCompanyEmailById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyEmail = action.payload;
            }
        },
        [createCompanyEmailById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
    }
});

export const { clearState } = companyEmailSlice.actions;
export default companyEmailSlice.reducer;
