/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import {
    filter,
    map,
    slice
} from 'lodash';
import './TagImagesCloudView.scss';
import {getTagImagesCompanyById} from "./TagImagesCompany";
import { Image, Transformation } from 'cloudinary-react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function TagImagesCloudView(props) {
    const {
        images
    } = props;
    const getImagesSize = (numImages) => {
        if (numImages === 1) {
            return 120;
        }
        if (numImages === 2) {
            return 80;
        }
        if (numImages === 3) {
            return 60;
        }
        if (numImages === 4) {
            return 50;
        }
        return 38;
    };

    const allImageCompanies = map(images, i => getTagImagesCompanyById(i));
    const foundImages = filter(allImageCompanies, i => !!i);
    let showImages = foundImages;

    if (foundImages.length > 3) {
        showImages = slice(foundImages, 0, 3);
    }

    const profileLabels = map(foundImages, i => i.profile);

    return (
        <>
            {showImages && showImages.length > 0 && (
                <div className={`tag-cloud-view-container tag-${showImages.length}-image`}>
                    {showImages.map((imageCompany, index) => {
                        return (
                            <div key={`tag-cloud-image-${index}`} className={`image-cloud image-${(index+1)}`}
                                 style={imageCompany.backgroundColor ?
                                     {backgroundColor: imageCompany.backgroundColor}
                                     :
                                     {backgroundColor: "transparent"}
                                }
                            >
                                <CloudImage image={imageCompany.imageUrl} height={getImagesSize(showImages.length)} />
                            </div>
                        )
                    })}
                </div>
            )}
            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id={`tooltip-bottom`} style={{fontSize: '10px'}}>
                            {profileLabels.join(', ')}
                        </Tooltip>
                    }
                    transition={false}
                >
                    {({ref, ...triggerHandler}) => (
                        <span {...triggerHandler} ref={ref}  className="lead-sub-text">{foundImages.length} Total</span>
                    )
                    }
                </OverlayTrigger>
            </div>
        </>
    )
}

export function CloudImage({image, height}) {
    const publicId = image || 'assets/icons/image_not_found';
    // https://res.cloudinary.com/toolbeltdata/image/upload/

    return (
        <Image cloudName='toolbeltdata' publicId={publicId} secure>
            <Transformation
                // width={height}
                height={height}
                crop='fit'
                dpr='auto'
            />
            <Transformation quality="auto" fetchFormat="auto" />
        </Image>
    )
}
