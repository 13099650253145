/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';

const BasePage = props => (
    <div>
        { props.children }
    </div>
);

BasePage.propTypes = {
    children: PropTypes.element.isRequired
};

export default BasePage;
