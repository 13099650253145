/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react'; // eslint-disable-line no-unused-vars
import AccountConfirm from "./AccountConfirm";

/**
 * ResetContainer
 */
export default class AccountConfirmContainer extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() { // eslint-disable-line
        return (
            <AccountConfirm {...this.props} />
        );
    }
}
