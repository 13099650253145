import styled from 'styled-components';
import {APP_COLORS} from "../../../common/Constants";

const LeadText = styled.div `
  font-size: 15px;
  font-weight: bold;
  color: ${APP_COLORS.blue};
`;

const SubText = styled.div `
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

const CheckBoxContainer = styled.div`
    margin-left: 5px;
    margin-top: 10px;
`;

const GridButton = styled.button`
    background-color: transparent;
    text-decoration: underline;
    font-weight: bold;
    border: 0;
    color: ${APP_COLORS.orange};
`;

const DataGridContainer = styled.div`
    padding: 5px;
    height: 100%;
    width: 100%;
`;

export {LeadText, SubText, CheckBoxContainer, GridButton, DataGridContainer};