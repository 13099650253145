/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

function LinkButton(props) {
    const {
        style,
        disabled,
        onClick,
        children,
        size,
    } = props;

    return (
            <Button
                variant={'link'}
                style={style}
                onClick={onClick}
                disabled={disabled}
                size={size}
            >
                {children}
            </Button>
    )
}


LinkButton.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,

};

LinkButton.defaultProps = {
    style: {textDecoration: 'none', boxShadow: 'none'},
    className: '',
    disabled: false,
    size: 'sm',
    onClick: (e) => e,
};
export default LinkButton;

