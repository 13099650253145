
import _ from 'lodash';
export const TYPES =
    {
        PROFILE: {
            id: 'PROFILE',
            display: 'Profile',
            description: 'A profile input record type',
        },
        LICENSE: {
            id: 'LICENSE',
            display: 'License',
            description: 'A license input record type',
        },
        PERMIT: {
            id: 'PERMIT',
            display: 'Permit',
            description: 'A permit input record type',
        },
        POLICY: {
            id: 'POLICY',
            display: 'Policy',
            description: 'A policy input record type',
        },
        REGISTRATION: {
            id: 'REGISTRATION',
            display: 'Registration',
            description: 'A registration input record type',
        },
        MIGRATED: {
            id: 'MIGRATED',
            display: 'Migrated',
            description: 'A legacy migrated input type'
        },
    }

    export function getTypeById(id) {
        if (id) {
            return _.get(TYPES, id);
        }
    }
