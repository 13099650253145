/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import {FormCheck, InputGroup} from "react-bootstrap";
import PropTypes from 'prop-types';
import {findIndex, assign} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {getManagedListErrors, getManagedListErrorsCount, setCurrentPage, setOrder, setSelectedId} from "./managedListErrorsSlice";

import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import TableWithPaging from '../../common/components/TableWithPaging';
import {DateTime} from "luxon";
import CheckBox from "../../common/components/CheckBox";

const ManagedListErrorsTable = (props) => {
    const dispatch = useDispatch();
    const managedListErrorsState = useSelector((state) => state.managedListErrors);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getManagedListErrorsCount());
        dispatch(getManagedListErrors());
    }, [props.lastReload, dispatch]);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    // let image = tbdBrainLightningIcon;
                    return <div>
                        <CheckBox
                            innerState
                            onChange={value =>
                                dispatch(setSelectedId({
                                    id: row.id,
                                    name: row.name,
                                    type: row.type,
                                    status: row.status,
                                    isChecked: value
                                }))
                            }
                        />
                    </div>
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.id.substring(0, 13)}
                            fullText={row.id}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'List Id',
                property: 'listId',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row.id) {
                        return <TextHoverDisplay
                            truncatedText={row.listId.substring(0, 13)}
                            fullText={row.listId}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Action',
                property: 'action',
                sortable: true,
                formatted: false
            },
            {
                id: 5,
                name: 'Error Message',
                property: 'errorMsg',
                sortable: false,
                formatted: false,
            },
            {
                id: 6,
                name: 'Created',
                property: 'created',
                sortable: true,
                formatted: true,
                formatBy: (row, header) => {
                    return DateTime.fromISO(row.created).toLocaleString(DateTime.DATETIME_MED);
                }
            },
        ];
    };

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;
        let _sortOrder;
        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';
        setHeader(headersUtil(headers, header, _sortOrder));
        dispatch(setOrder({by: header.property, direction: _sortOrder}));
        onSelectPage(
            1,
            {
                [header.property]: _sortOrder.toLowerCase()
            }
        );
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(getManagedListErrors());
    };

    const [headers, setHeader] = useState(createHeader().map(hc =>
        assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        })));

    return (
        <div>
            <TableWithPaging
                id={'source-table-with-paging'}
                headers={headers}
                rows={managedListErrorsState.managedListErrors}
                countOfResults={managedListErrorsState.totalRecords}
                resultsPerPage={managedListErrorsState.pageSize}
                currentPage={managedListErrorsState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
};


ManagedListErrorsTable.propTypes = {
    managedListErrors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string
        })
    ),
    handleSearch: PropTypes.func,
};

ManagedListErrorsTable.defaultProps = {
    style: {},
    className: '',
    labelStyle: {},
    managedListErrors: []
};

export default ManagedListErrorsTable;
