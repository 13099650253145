/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Redirect,
    Route, Switch,
} from 'react-router-dom';

import CreateOrganizationContainer from "./CreateOrganizationContainer";
import EditOrganizationContainer from "./EditOrganizationContainer";
import OrganizationsContainer from './OrganizationsContainer';
import OrganizationAddTeamMemberContainer from "./OrganizationAddTeamMemberContainer";

export default class OrganizationsRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Switch>
                <Route path="/admin/organizations/create-organization" exact component={CreateOrganizationContainer}/>
                <Route path="/admin/organizations/:id/managed-organizations" exact component={EditOrganizationContainer}/>
                <Route path="/admin/organizations/:id/organization-add-team-member" exact component={OrganizationAddTeamMemberContainer}/>
                <Route path="/admin/organizations" component={OrganizationsContainer} />
            </Switch>
        );
    }
}
