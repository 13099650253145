/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import pjson from '../package.json';

const NAME = `${pjson.name}`;
const VERSION = `${pjson.version}.${pjson.build}`;
const STORAGE_NAMESPACE_PREFIX = `tbd_${NAME.toLowerCase()}`;
const CONSTANTS = {
    CACHE_KEY_TOKEN: '$',
    CACHE_KEY_ACCOUNT: 'account',
    CACHE_KEY_API: 'api',
    CACHE_KEY_JWT: 'jwt',
    // Defines what value to switch on between loc mode and record mode
    EXPLORE_MAP_VIEW_CLUSTER_MODE_RECORD_LIMIT: 10000,
    EXPLORE_MAP_VIEW_CLUSTER_MODE_QUERY_LIMIT: 50000,
    EXPLORE_LIST_VIEW_LIMIT: 70,
    EXPLORE_LIST_VIEW_ITEMS_PER_PAGE: 10,
    LIMIT_FILE_UPLOAD: 512, // MegaByte
};

// Default filters applied to every query
const DEFAULT_FILTERS = {
    mapViewDefaultFilters: [
        // Only display records with a lat/lon otherwise data ends up on null island
        // This is enforced in company/contactSlice
        // {
        //     member: `Company.company_address_loc_city_latitude`,
        //     operator: 'set',
        // },
        // {
        //     member: `Company.company_address_loc_city_longitude`,
        //     operator: 'set',
        // }
    ],
    listViewDefaultFilters: [],
};

// TODO: The environment level stuff (urls) should be moved to .env files in order to prevent them
//  from being included in the build files (but the default .env behaviour wont work for us)

const config = {
    development: {
        env: 'development',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.dev`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                baseUrl: 'http://localhost:3000/api',
                adminBaseUrl: 'http://localhost:4000/api/admin',
                dataBaseUrl: 'http://localhost:3005/api/data',
                cubeBaseUrl: 'http://localhost:5000/cubejs-api/v1',
            }
        },
        explore: {
            defaultFilters: DEFAULT_FILTERS
        }
    },
    test: {
        env: 'test',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.test`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                // apiUrl: 'http://localhost:3000/api/v2',
                apiUrl: 'https://test.toolbeltdata.io/api/v2',
                // baseUrl: 'http://localhost:3000/api/v2',
                baseUrl: 'https://test.toolbeltdata.io/api',
                adminBaseUrl: 'https://test.toolbeltdata.io/api/admin',
                dataBaseUrl: 'http://localhost:3000/api/v2',
                // dataBaseUrl: 'https://test.toolbeltdata.io/api/data',
                cubeBaseUrl: 'https://test.toolbeltdata.io/cubejs-api/v1',
                // cubeBaseUrl: 'http://localhost:5000/cubejs-api/v1',
            }
        },
        explore: {
            defaultFilters: DEFAULT_FILTERS
        }
    },
    production: {
        env: 'production',
        name: NAME,
        version: VERSION,
        storageNamespace: `${STORAGE_NAMESPACE_PREFIX}.prod`,
        constants: CONSTANTS,
        services: {
            ATLAS: {
                // apiUrl: 'http://localhost:3000/api/v2',
                apiUrl: 'https://prod.toolbeltdata.io/api/v2',
                baseUrl: 'https://prod.toolbeltdata.io/api',
                adminBaseUrl: 'https://prod.toolbeltdata.io/api/admin',
                dataBaseUrl: 'https://prod.toolbeltdata.io/api/data',
                cubeBaseUrl: 'https://prod.toolbeltdata.io/cubejs-api/v1',
            }
        },
        explore: {
            defaultFilters: DEFAULT_FILTERS
        }
    }
};

// This is probably good enough for now
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
export default config[process.env.REACT_APP_ENV];
