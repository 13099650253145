import React from 'react';
import {APP_COLORS} from "../../../../common/Constants";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const ExploreMapFlyoutSelectedBadges = (props) => {

    const {
        badgesIcons
    } = props;

    const badgesIconsJsx = [];
    badgesIcons.forEach((i) => {
        if (parseInt(i.value, 10) > 0) {
            badgesIconsJsx.push(
                <div key={badgesIconsJsx.length + 1} className="selected-point-detail-record-badges-icons-item"
                     data-for='budgesIcon' data-tip={`${i.value} ${i.suffix}`}>
                        <span>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            /><span style={{fontSize: '14px', fontWeight: '600'}}> {i.value} {i.suffix}</span>
                        </span>
                </div>
            )
        }
    });
    return (
        <div style={{paddingTop: '40px', paddingBottom: '10px'}}>
            <div>
                {badgesIconsJsx.length > 0 && (
                    <div className="selected-point-detail-record-badges-icons-container">
                        {badgesIconsJsx}
                    </div>
                )}
                {/*<ReactTooltip id='budgesIcon' getContent={(dataTip) => `${dataTip}`}/>*/}
            </div>
        </div>
    );
};

export default ExploreMapFlyoutSelectedBadges;
