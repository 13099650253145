/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import AdminLists from "./AdminLists"; // eslint-disable-line no-unused-vars

const AdminListsContainer = (props) => {
    return(
       <AdminLists/>
    )
};

export default AdminListsContainer;
