import React, { useState, forwardRef, Component } from 'react';
// import {useDispatch} from 'react-redux';
// import {denormCompanyUpdate} from "./entityVerificationsSlice";

export const AttributeRenderer = forwardRef((props, ref) => {
    const attributeDenormPrefix = props.attributeDenormPrefix;
    const attributeValue = props.value;
    const attributeVerified = props.data[attributeDenormPrefix+'Verified'];
    const attributeInvalidated = props.data[attributeDenormPrefix+'Invalidated'];
    const verifiedIcon = attributeVerified ? ' \u2705' : '';

    // const
    return (
        <div>
        {attributeInvalidated? <span><s>{verifiedIcon} {attributeValue}</s></span>: <span>{verifiedIcon} {attributeValue}</span>}
        </div>
    );

});