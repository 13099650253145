import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import config from '../../../../../../config'
import * as Api from "../../../../../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    companyURL: null
};

export const getCompanyURLById = createAsyncThunk('companies/getCompanyURLById', async (id) => {
    const GET_COMPANY_URL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${id}/url`;
    const companyURL = await Api.get(true, GET_COMPANY_URL_BY_ID_URL);
    return companyURL;
});

export const changeCompanyURLById = createAsyncThunk('companies/changeCompanyURLById', async (data) => {
    const CHANGE_COMPANY_URL_BY_ID_URL = `${config.services.ATLAS.dataBaseUrl}/companies/${data.id}/url/change`;
    const response = await Api.post(true, CHANGE_COMPANY_URL_BY_ID_URL, data.body);
    return response;
});

const companyURLSlice = createSlice({
    name: 'companyURLSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.companyURL = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyURLById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyURLById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyURL = action.payload;
            }
        },
        [getCompanyURLById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve company information';
        },
        [changeCompanyURLById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [changeCompanyURLById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.companyURL = action.payload;
            }
        },
        [changeCompanyURLById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to set company information';
        },
    }
});

export const { clearState } = companyURLSlice.actions;
export default companyURLSlice.reducer;
