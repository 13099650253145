/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";
import {Form} from "react-bootstrap";

const FormLabel = styled(Form.Label) `
  font-weight: 600;
  color: #2d5d7e;
  align-items: center;
`;

export default FormLabel;
