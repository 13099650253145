/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import React, {useState} from 'react';
import {
    Form,
    FormGroup,
    FormLabel,
    Row,
    Col,
} from 'react-bootstrap';

import RadioGroup from "../../../common/components/RadioGroup";

import {CSVReader} from "react-papaparse";
import {useDispatch, useSelector} from "react-redux";

import {
    resetFileHeaders,
    updateFileHeaders,
    updateFileUrl,
    updateListName,
    updateFileSize,
    updateInputSource, updateTextInput,
} from "./uploadSlice";

import BrowserWindowImage from '../../../common/assets/img/ToolbeltIcons_Artboard_17.png'
import FileImage from '../../../common/assets/img/ToolbeltIcons_Artboard_17.png'
import Image from "react-bootstrap/Image";
import Select from "react-select";
import config from "../../../config";
import Alert from "react-bootstrap/Alert";

const uploadRef = React.createRef();

function InputListSelection(props) {
    const {
        handleUploadFile
    } = props;
    const listSource = [
        {
            value: "FILE_UPLOAD", label: <>Upload</>
        },
        {
            value: "TEXT_INPUT", label: <> Text Input </>
        },
    ];

    const fileTypes = [
        {value: 'csv', label: 'Comma Separated Value (CSV)'}
    ];

    const uploadMetaState = useSelector(state => state.upload);
    const dispatch = useDispatch();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleOnDrop = (data, meta) => {
        const input = uploadRef.current;
        const sizeLimit = config.constants.sizeLimit;

        if (meta && meta.size > (sizeLimit * 1000000)) {
            input.state.files = null;
            setShowAlert(true);
            setAlertMessage('File size is not support.');
            // setTimeout(() => setShowAlert(false), 5000);

            return;
        }

        if (input && input.state && input.state.files) {
            handleUploadFile(input.state.files);
        }

        if (data && data.length > 0) {
            dispatch(updateFileHeaders(data[0]));
            dispatch(updateInputSource('FILE_UPLOAD'));
        }

        if (meta) {
            dispatch(updateFileUrl(meta.name));
            dispatch(updateFileSize(meta.size));
            // dispatch(updateFileType(meta.type));
        }

        if (input) {
            const fileName = input.state.files[0] && input.state.files[0].name;
            dispatch (updateListName(fileName.substring(0, fileName.indexOf('.'))));
        }
    }


    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        if (data && data.length > 0) {
            dispatch(resetFileHeaders());
        }
    }

    const handleOnTextInputUpdate = (data) => {
        dispatch(updateTextInput(data));
        dispatch(updateInputSource('TEXT_INPUT'));
    }

    return (
        <>
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>
            <Form className="inputListSelectionContent">
                <FormGroup>
                    <Row>
                        <Col md="auto" className="label-text">
                            <FormLabel>Select your list source</FormLabel>
                        </Col>
                        <Col md="auto">
                            <RadioGroup
                                id={'ListSourceSelection'}
                                name={'ListSourceSelection'}
                                value={uploadMetaState.inputSource}
                                items={listSource}
                                onChange={selectedValue => dispatch(updateInputSource(selectedValue))}
                                radioButton
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {uploadMetaState.inputSource === 'FILE_UPLOAD'
                                && uploadMetaState.fileUrl.length === 0 && (
                                    <CSVReader
                                        ref={uploadRef}
                                        onDrop={handleOnDrop}
                                        onError={handleOnError}
                                        addRemoveButton
                                        onRemoveFile={handleOnRemoveFile}
                                        config={{preview: 10}}
                                        style={{
                                            dropArea: {
                                                border: 'dashed'
                                            },
                                            dropAreaActive: {
                                                borderColor: 'red',
                                            },
                                            dropFile: {
                                                width: 100,
                                                height: 120,
                                                background: '#fff',
                                            },
                                            fileSizeInfo: {
                                                color: '#fff',
                                                backgroundColor: '#000',
                                                borderRadius: 3,
                                                lineHeight: 1,
                                                marginBottom: '0.5em',
                                                padding: '0 0.4em',
                                            },
                                            fileNameInfo: {
                                                color: '#fff',
                                                backgroundColor: '#eee',
                                                borderRadius: 3,
                                                fontSize: 14,
                                                lineHeight: 1,
                                                padding: '0 0.4em',
                                            },
                                            removeButton: {
                                                color: 'blue',
                                            },
                                            progressBar: {
                                                backgroundColor: 'pink',
                                            },
                                        }}
                                    >
                                        <div className="source-image">
                                            <Image
                                                src={BrowserWindowImage}
                                                width="110"
                                                alt="Browser Window Image"
                                                fluid
                                            />
                                            <FormLabel>
                                                <b>Drag n' drop your file here or click to select your file</b>
                                            </FormLabel>
                                        </div>
                                    </CSVReader>
                                )}
                            {uploadMetaState.inputSource === 'FILE_UPLOAD'
                                && uploadMetaState.fileUrl.length !== 0 && (
                                    <div className="source-file">
                                        <Image
                                            src={FileImage}
                                            width="110"
                                            alt="File Image"
                                            fluid
                                        />
                                        <FormLabel>
                                            <span className="file-name">{uploadMetaState.fileUrl}</span> selected for upload
                                        </FormLabel>
                                        <Row className="source-file-type">
                                            <Col md={4} className="col-form-label">
                                                <FormLabel>
                                                    Data Type:
                                                </FormLabel>
                                            </Col>
                                            <Col md={8}>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={fileTypes[0]}
                                                    name="color"
                                                    options={fileTypes}
                                                    menuPortalTarget={document.body}
                                                    menuPosition={'absolute'}
                                                    styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            {uploadMetaState.inputSource === 'TEXT_INPUT' && (
                                <div className="source-text-input">
                                    <Row>
                                        <Col>
                                            <FormLabel>
                                                Type or Copy / Paste your list below.
                                            </FormLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control as="textarea" rows={5} value={uploadMetaState.rawTextInput}
                                                          onChange={
                                                              (e) => handleOnTextInputUpdate(e.target.value)}/>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
        </>
    );
}


export default InputListSelection;
