import {createSlice} from "@reduxjs/toolkit";
const initialState = {
    viewMode: 'CONTACT',
    exploreMode: {
        mode: 'COMPANY',
        company: {
            sections: {
                // COMPANY: true
            },
            toggleMap: true,
        },
        contact: {
            sections: {},
        }
    },
    map: null,
    overlaySelect: null
};

const exploreSlice = createSlice({
    name: 'explore', initialState,
    reducers: {
        setExploreMode: {
            reducer(state, action) {
                state.exploreMode = action.payload;
            }
        },
        setExploreMap: {
            reducer(state, action) {
                state.map = action.payload;
            }
        },
        setCompanyMode: {
            reducer(state) {
                state.exploreMode.mode = 'COMPANY';
                return state;
            }
        },
        setOverlaySelect: {
            reducer(state, action) {
                state.overlaySelect = action.payload;
                return state;
            }
        },
    },
})


export const {setExploreMode, setExploreMap, setCompanyMode, setOverlaySelect} = exploreSlice.actions;
export default exploreSlice.reducer;
