/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

import HomeRouter from './home/HomeRouter.jsx';
import ExploreRouter from './explore/ExploreRouter.jsx';
import ListsRouter from './lists/ListsRouter.jsx';
import IntegrationsRouter from './manage/integrations/IntegrationsRouter';
import TeamRouter from './manage/team/TeamRouter';
import {getMetaData} from '../common/metadata/metaDataSlice';


export default function OrganizationRouter() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMetaData());
    }, [dispatch]);

    return (
            <Switch>
                <Route path="/org/home" exact component={HomeRouter}/>
                <Route path="/org/explore" exact component={ExploreRouter}/>
                <Route path="/org/lists" exact component={ListsRouter}/>
                {/*<Route path="/org/manage/integrations" exact component={IntegrationsRouter}/>*/}
                <Route path="/org/manage/team" exact component={TeamRouter}/>
                <Redirect to="/org/home"/>
            </Switch>
        );
}
