/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import React, { useState} from "react";
import styled from "styled-components";

import {APP_COLORS} from "../Constants";
import {Accordion, AccordionCollapse, Button, Col, Form, Row} from "react-bootstrap";
import FormSelect from "./form/FormSelect";
import CheckBox from "./CheckBox";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";


const HeaderPanel = styled.div `
  background-color: ${APP_COLORS['gray-100']};
  padding: 0.5rem;

  border-width: 0.0125rem;
  border-style: solid;
  border-color: #e7eaf3;
`;

const SearchBarContainer = styled.div `
  margin: 8px 0 8px 15px;
`;

const HeaderDivider = styled.div `
    height: 1px;
    border-top: 1px solid #2d5d7e;
    margin-left: 10px;
    margin-right: 10px;
`;

const HeaderText = styled.div `
  margin: 8px 0 8px 15px;
  font-size: 30px;
`;

const HeaderSubHeaderText = styled.div `
  margin: 0 0 8px 15px;
  font-size: 13px;
`;

const SearchSubText = styled.div `
  display: flex;
  //margin-left: 1rem;
  font-size: 13px;
  font-style: italic;
  padding: 5px 15px;
`;
const SearchSubTextFilter = styled.div `
  font-weight: 600;
  font-size: 14px;
`;
const SearchExpandCollapseToggleContainer = styled.div `
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: end;
`;

const OptionsContainer = styled.div `
  margin: 15px 15px 15px 15px;
`;

const SearchBarRow = styled.div `
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 10px;
`;

const ToolActionContainer = styled.div `
  margin: 8px 15px 8px 15px;
  
  > div {
    display: grid;
    grid-template-columns: 125px 125px 125px;
    grid-gap: 10px;
  }
`;

const ToolActionRow = styled.div `
  display: grid;
  grid-template-columns: ${props => props.customTemplateColumns};
  grid-gap: 10px;
`;

const DisabledDiv = styled.div `
  pointer-events: none;
`;

const OptionsPanel = (props) => {
    const {
        products,
        setProducts,
        productsList,
        fts,
        setFts,
        randomReviewset,
        setRandomReviewSet
    } = props;
    const HeaderDividerFilter = styled.div `
    height: 1px;
    border-top: 1px solid #2d5d7e;
    margin-right: 10px;
`;
    return (
       <div>
            <Row>
                <Col>
                     <h5>Search Options</h5>
                     <HeaderDividerFilter />
                </Col>
            </Row>
                <Row>
                <Col>
                    <FormSelect
                        label={'Filter by Product'}
                        placeholder="Select product(s)"
                        value={products}
                        onChange={value => setProducts(value)}
                        options={productsList}
                        isMulti={true}
                        size={'sm'}
                        required
                    />
                </Col>
                <Col>
                    <DisabledDiv>
                        <CheckBox
                            label={'Search Using Full Text Search (FTS)'}
                            checked={fts}
                            onChange={value => setFts(value)}
                            size={'sm'}
                        />
                        <CheckBox
                            label={'Generate Random Reviewset'}
                            checked={randomReviewset}
                            onChange={value => setRandomReviewSet(value)}
                            size={'sm'}
                        />                
                    </DisabledDiv>
                </Col>
            </Row>
        </div>

    );
}

const DataCollectionPanelHeader = (props) => {
    const {
        header,
        headerSubHeader,
        toolAction,
        searchBar,        
        searchSubText,
        searchSubTextFilter,
        customTemplateColumns = '1fr 425px',
        products, setProducts,
        fts, setFts,
        randomReviewset, setRandomReviewSet
    } = props;

    const [expanded, setExpanded] = useState(false);

    const productsList = [
        {label: 'Contractor Universe (TCU)', value: 'Contractor Universe (TCU)'}       
    ];

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionToggle(eventKey, () => setExpanded(!expanded));
        return (
            <Button
                variant="link"
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        );
    }

    return (
        <HeaderPanel>
            {toolAction ?
                <ToolActionRow customTemplateColumns={customTemplateColumns}>
                    <div>
                        <HeaderText>{header}</HeaderText>
                        <HeaderSubHeaderText>{headerSubHeader}</HeaderSubHeaderText>
                    </div>
                    <ToolActionContainer>{toolAction}</ToolActionContainer>
                </ToolActionRow>
                :
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <HeaderText>{header}</HeaderText>
                    </Col>
                </Row>
            }
            <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <HeaderDivider />
                </Col>
            </Row>
            <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
            <SearchBarContainer>{searchBar}
                <Accordion>
                    <SearchBarRow>
                        
                        <Col xl={12} lg={12} md={12} sm={12}>
                           
                        </Col>
                        
                    </SearchBarRow>
                    <Row>
                    <Col xl={10} lg={10} md={10} sm={10}>
                            <SearchSubText>{searchSubText}&nbsp;<SearchSubTextFilter>{searchSubTextFilter}</SearchSubTextFilter></SearchSubText>
                        </Col>
                    <Col xl={2} lg={2} md={2} sm={2}>
                            <SearchExpandCollapseToggleContainer>
                                <CustomToggle eventKey="0">
                                    { expanded ? (
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    ) : (
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    )}
                                </CustomToggle>
                            </SearchExpandCollapseToggleContainer>
                        </Col>
                    </Row>
                    <AccordionCollapse eventKey="0">
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <OptionsContainer>
                                    <OptionsPanel 
                                        products={products}
                                        setProducts={setProducts}
                                        productsList={productsList}
                                        fts={fts}
                                        setFts={setFts}
                                        randomReviewset={randomReviewset}
                                        setRandomReviewSet={setRandomReviewSet}
                                    />
                                </OptionsContainer>
                            </Col>
                        </Row>
                    </AccordionCollapse>
                </Accordion>
                </SearchBarContainer>
                </Col>
            </Row>
        </HeaderPanel>
    );
}

export default DataCollectionPanelHeader;
