import {Row, Col} from 'react-bootstrap';
import FormTextInput from "../../common/components/FormTextInput";
import React, {Component, useEffect, useState} from 'react';
import defaultConfig from './organization-default-config.json';
import OrganizationConfigForm from "./OrganizationConfigForm";

const isEmail = require('validator/es/lib/isEmail');

export default function OnboardOrganizationForm(props) {
    // Load props
    const orgConfig = props.props.orgConfigProp;
    const setOrgConfig = props.props.setOrgConfigProp;
    const formValid = props.props.formValidProp;
    // const asJSON = props.props.asJSONProp;

    // Set state variables
    const [orgName, setOrgName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [ownerSurname, setOwnerSurname] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [config, setConfig] = useState(defaultConfig);

    useEffect(() => {
        // When form fields are updated, update the JSON representation
        const data = {
            name: orgName,
            config: config,
            owner: {
                givenName: ownerName,
                surName: ownerSurname,
                credentials: {
                    email: ownerEmail
                }
            }
        }
        setOrgConfig(data);
    }, [config, orgName, ownerEmail, ownerName, ownerSurname, setOrgConfig]);

    // if (asJSON) {
    //     // The <p> tag is used only for testing, keep it hidden
    //     return (<div>
    //             <Col md={9}>
    //                 <p hidden id='jsonInputTxt'>{JSON.stringify(orgConfig)}</p>
    //                 <FormJsonInput
    //                     id={'create-config-organization'}
    //                     placeholder={orgConfig}
    //                     onChange={(v) => setOrgConfig(v)}
    //                     height={'100%'}
    //                     width={'100%'}
    //                     viewOnly={true}
    //                     required
    //                 />
    //             </Col>
    //         </div>);
    // }

    return (<div>
        <p hidden id='jsonInputTxt'>{JSON.stringify(orgConfig)}</p>
            <Row>
                <Col md={9}>
                    <div id="organization-name-div">
                        <FormTextInput
                            label={'Organization Name'}
                            placeholder="Enter organization name"
                            value={orgName}
                            onChange={value => setOrgName(value)}
                            isInvalid={!formValid && !orgName}
                            size={'sm'}
                            required
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <div id="owner-name-div">
                        <FormTextInput
                            label={'Owner Given Name'}
                            placeholder="Enter owner given Name"
                            value={ownerName}
                            onChange={value => setOwnerName(value)}
                            isInvalid={!formValid && !ownerName}
                            size={'sm'}
                            required
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <div id="owner-surname-div">
                        <FormTextInput
                            label={'Owner Surname'}
                            placeholder="Enter owner surname"
                            value={ownerSurname}
                            onChange={value => setOwnerSurname(value)}
                            isInvalid={!formValid && !ownerSurname}
                            size={'sm'}
                            required
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    <div id="owner-email-div">
                        <FormTextInput
                            label={'Owner Email'}
                            placeholder="Enter email"
                            value={ownerEmail}
                            onChange={value => setOwnerEmail(value)}
                            isInvalid={!formValid && (!ownerEmail || !isEmail(ownerEmail))}
                            size={'sm'}
                            required
                        />
                    </div>
                </Col>
            </Row>

            <OrganizationConfigForm 
                setConfig={setConfig}
                defaultConfig={defaultConfig}
                formValid={formValid}
            />
    </div>);
}