/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Pagination} from 'react-bootstrap';

import {useDispatch, useSelector} from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {postContractorsFtsSearch} from "./contractorDirectorySlice";

import ContractorDirectorySearchResult from "./ContractorDirectorySearchResult";
import ContractorDirectorySearchResultsMap from "./ContractorDirectorySearchResultsMap";

const SearchResultsContainer = styled.div `
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 10px;
  padding-right: 5px;
  padding-left: 1px;
  height: calc(100vh - 255px);
  transition: width 300ms ease-in-out;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  // width: ${props => props.removeWidth ? 'calc(100vw - 355px)' : 'calc(100vw - 40px)'};
 `;

const RESULTS_PER_PAGE = 3;

export default function ContractorDirectorySearchResults(props) {
    const {
        mode,
        lastReload,
        handleOnContact
    } = props;

    const dispatch = useDispatch();
    const contractorDirectoryState = useSelector(state => state.contractorDirectory);
    const searchResults = contractorDirectoryState.searchResults;
    const pages = Math.ceil(searchResults.length / RESULTS_PER_PAGE);

    const [paginatedSearchResults, setPaginatedSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    // User Searches
    useEffect(() => {
        if (lastReload) {
            // Do something b/c last reload exists (otherwise it doesnt and we avoid running multiple times)
            dispatch(postContractorsFtsSearch());
        }
    }, [lastReload, dispatch]);

    useEffect(() => {
        if (searchResults.length > 0) {
            const indexStart = 0;
            const indexEnd = RESULTS_PER_PAGE - 1;
            setPaginatedSearchResults(searchResults.slice(indexStart, indexEnd + 1));
            setCurrentPage(1);
            return;
        }
        setPaginatedSearchResults([]);
        setCurrentPage(1);
    }, [searchResults]); // Only re-run the effect if count changes

    const handlePrevPage = () => {
        if (currentPage === 1) return;
        const _prevPage = currentPage - 1
        const indexStart = (_prevPage * RESULTS_PER_PAGE) - RESULTS_PER_PAGE;
        const indexEnd = (_prevPage * RESULTS_PER_PAGE) - 1;
        setPaginatedSearchResults(searchResults.slice(indexStart, indexEnd + 1));
        setCurrentPage(_prevPage);
    }

    const handleSelectPage = (page) => {
        const indexStart = (page * RESULTS_PER_PAGE) - RESULTS_PER_PAGE;
        const indexEnd = (page * RESULTS_PER_PAGE) - 1;
        setPaginatedSearchResults(searchResults.slice(indexStart, indexEnd + 1));
        setCurrentPage(page);
    }

    const handleNextPage = () => {
        if (currentPage === pages) return;
        const _nextPage = currentPage + 1
        const indexStart = (_nextPage * RESULTS_PER_PAGE) - RESULTS_PER_PAGE;
        const indexEnd = (_nextPage * RESULTS_PER_PAGE) - 1;
        setPaginatedSearchResults(searchResults.slice(indexStart, indexEnd + 1));
        setCurrentPage(_nextPage);
    }

    return (
        <>
            {searchResults.length > 0 && (
                <Row className="mt-3">
                    <Col xl={9} lg={9} md={9} sm={9}>
                        <SearchResultsContainer>
                            {paginatedSearchResults.map((l, i) => (
                                <ContractorDirectorySearchResult key={i} mode={mode} item={l} handleOnContact={handleOnContact}/>
                            ))}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {renderPagination({
                                    searchResults,
                                    pages,
                                    currentPage,
                                    handlePrevPage,
                                    handleSelectPage,
                                    handleNextPage,
                                })}
                            </div>
                        </SearchResultsContainer>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={3}>
                        <ContractorDirectorySearchResultsMap></ContractorDirectorySearchResultsMap>
                    </Col>
                </Row>
            )}
        </>
    )
}


const renderPagination = (props) => {
    const {
        pages,
        currentPage,
        handlePrevPage,
        handleSelectPage,
        handleNextPage
    } = props;

    const items = [];
    for (let i = 1; i <= pages; i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => handleSelectPage(i)}
            >
                {i}
            </Pagination.Item>
        );
    }

    return (
        <Pagination>
            <Pagination.Prev
                onClick={() => handlePrevPage()}
            />
            {items}
            <Pagination.Next
                onClick={() => handleNextPage()}
            />
        </Pagination>
    );
};
