import React, { Component } from 'react';

export class TestForm extends Component {
    render () {
        return (
            <button onClick={() => window.alert('Button Clicked!')}>Click Me!</button>
        );
    }

    afterGuiAttached() {
        if (this.textInput) this.textInput.current.focus();
    };
}