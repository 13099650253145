/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import ManagedMatchTemplates from "./ManagedMatchTemplates"; // eslint-disable-line no-unused-vars

const ManagedMatchTemplatesContainer = (props) => {
    return(
       <ManagedMatchTemplates/>
    )
};

export default ManagedMatchTemplatesContainer;
