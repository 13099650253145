/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import AsyncSelect from "react-select/async";

import FormLabel from "./FormLabel";
import styled from "styled-components";
import FormRequiredText from "./FormRequiredText";


const AsyncSelectStyled = styled(AsyncSelect) `
  div {
    border-radius: 0;
  }
`;

const FormAsyncSelect = (props) => {
    const {
        label,
        loadOptions,
        isClearable,
        cacheOptions,
        defaultOptions,
        onChange,
        value,
        getOptionLabel,
        getOptionValue,
        onInputChange,
        isInvalid,
        placeholder,
        required
    } = props;

    const customStyles = {
        control: (styles) => ({ ...styles, borderColor: '#c13727 !important' }),
    }

    return (
        <>
            {label && (
                <FormLabel>
                    {label}
                    {required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            )}
            <AsyncSelectStyled
                isClearable={isClearable}
                cacheOptions={cacheOptions}
                defaultOptions={defaultOptions}
                loadOptions={loadOptions}
                getOptionLabel={e => getOptionLabel(e)}
                getOptionValue={e => getOptionValue(e)}
                onChange={onChange}
                onInputChange={onInputChange}
                styles={isInvalid ? customStyles : undefined}
                value={value}
                placeholder={placeholder}
            />
        </>
    );
}

export default FormAsyncSelect;
