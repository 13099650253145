/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";

import {InputGroup} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-solid-svg-icons";

import {useDispatch, useSelector} from "react-redux";

import {getOrganizationsWithFilter} from "../../organizations/organizationsSlice";
import TextHoverDisplay from "../../../common/components/TextHoverDisplay";

import {isArray} from "lodash";
import FormLabel from "../../../common/components/form/FormLabel";
import FormRequiredText from "../../../common/components/form/FormRequiredText";

const FormSearchOrganizationsTypeahead = (props) => {
    const {
        label,
        organization,
        onChange,
        isInvalid,
        editing,
        required
    } = props;

    const dispatch = useDispatch();
    const organizationState = useSelector(state => state.organizations);

    const searchOrganization = (search) => {
        const query = {
            filter: {
                fields: ['id', 'typeId', 'name', 'abbrv', 'statusId', 'created'],
                where: {
                    or: [{
                        name: {
                            ilike: `%${search ? search : ''}%`
                        }
                    }]
                }
            },
        };

        dispatch(getOrganizationsWithFilter(query));
    };

    return (
        <div id="search-organization-typeahead-div">
            {label && (
                <FormLabel>
                    {label}
                    {required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            )}
            { editing ? (
                <div id="organizationAsyncTypeahead">
                    <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSearch} style={{fontSize: '16px'}} />
                    </InputGroup.Text>
                        <AsyncTypeahead
                            id="search-organization-typeahead"
                            isLoading={organizationState.loading}
                            labelKey="name"
                            minLength={1}
                            onSearch={searchOrganization}
                            options={organizationState.organizations}
                            placeholder="Search organization..."
                            renderMenuItemChildren={(option) => (
                                <div key={option.id}>
                                    <span>{option.name}</span>
                                </div>
                            )}
                            onChange={onChange}
                            selected={organization}
                            clearButton
                            isInvalid={isInvalid}
                        />
                </InputGroup>
                </div>
            ) : (
                <TextHoverDisplay
                    fullText={isArray(organization) ? organization[0]?.id : organization?.id }
                    truncatedText={isArray(organization) ? organization[0]?.id : organization?.id}
                    displayTruncated={false}
                />
            )}
        </div>
    )
}

export default FormSearchOrganizationsTypeahead;
