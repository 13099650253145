/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars

import ManagedCompanies from './ManagedCompanies';

const ManagedCompaniesContainer = (props) => {

    return(
        <ManagedCompanies />
    );
};

export default ManagedCompaniesContainer;
