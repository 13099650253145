

import React from 'react';

import './ExploreMapFlyoutDetailLocation.scss';
import {filter} from "lodash";

export function ExploreMapFlyoutSelectedDetailSection(props) {
    const {
        detailIconJsx,
        densityIconsJsx,
        name,
        address1,
        address2,
    } = props;

    return (
        <div className={"flyout-detail-section"}>
            <div className="icon-container">
                {detailIconJsx}
                <div>
                    <div className="lead-text-container">
                        <div className="lead-text-items">
                            <span className="lead-text-label">{name}</span>
                            <span className="lead-text-small">{address1}</span>
                            <span className="lead-text-small">{address2}</span>
                        </div>
                    </div>
                    <div>
                        {densityIconsJsx.length > 0 && (
                            <div className="density-icons-container">
                                {densityIconsJsx}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
