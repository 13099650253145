/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";

const DescriptionColumn = styled.div `
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default DescriptionColumn;
