/**
 * Copyright ToolBelt Data Inc., 2022 All Rights Reserved
 */

// NOTE: KEEP THIS IN ORDER OF ELEMENTS IN THE UI; IT HELPS QA

/*

Filter Sections + Sub Sections

[search]

CONTACT
    - NAME
    - TITLE
    - PHONE
    - EMAIL
COMPANY
    - NAME
    - URL
    - LOCATION
    - PHONE
    - EMAIL
FACTS
STATISTICS
TAXONOMY
    - SEGMENTS
    - CLASSIFICATIONS
    - SERVICES
INSIGHTS
    - INDICATORS
    - SCORES
PROFILES
LICENSES
PERMITS
POLICIES
REGISTRATIONS
SOURCES
LISTS -- Not defined here; defined in component

 */

/***

OBJECT STRUCTURE / CONVENTION

 SECTION
    SUB SECTION
        ATTRIBUTE - if there is one or just the 'primary' filter type for a given attribute it should be named (uppercase) to match the data element (in cube)
        ATTRIBUTE_FILTER_TYPE - if there is more than one filter for a given attribute add the filter type / operand display
    STICKY_FILTER - when the given section does not have any subsection dependents (its sticky)

 EXAMPLE:

 COMPANY
    NAME
        CONTACT_NAME - matches field in database
        CONTACT_NAME_HAS_ANY_VALUE - is field in db + operand

 */
export const FilterOptions = {
    CONTACT: {
        NAME: {
            CONTACT_NAME_GIVEN_NAME: {
                id: 'CONTACT_NAME_GIVEN_NAME',
                display: 'Given Name',
                field: 'ContactContractorUniverse.contact_name_given_name',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[contact_name_given_name]'
            },
            CONTACT_NAME_SURNAME: {
                id: 'CONTACT_NAME_SURNAME',
                display: 'Surname',
                field: 'ContactContractorUniverse.contact_name_surname',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[contact_name_surname]'
            },
        },
        TITLE: {
            CONTACT_TITLE: {
                id: 'CONTACT_TITLE',
                display: 'Contains',
                field: 'ContactContractorUniverse.contact_title',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[contact_title]'
            },
            CONTACT_TITLE_HAS_ANY_VALUE: {
                id: 'CONTACT_TITLE_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'ContactContractorUniverse.contact_title',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[contact_title]'
            },
        },
        PHONE: {
            CONTACT_PHONE: {
                id: 'CONTACT_PHONE',
                display: 'Contains',
                field: 'ContactContractorUniverse.contact_phone',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[contact_phone]'
            },
            CONTACT_PHONE_HAS_ANY_VALUE: {
                id: 'CONTACT_PHONE_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'ContactContractorUniverse.contact_phone',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[contact_phone]'
            },
        },
        EMAIL: {
            CONTACT_EMAIL_CONTAINS: {
                id: 'CONTACT_EMAIL_CONTAINS',
                display: 'Contains',
                field: 'ContactContractorUniverse.contact_email',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[contact_email]'
            },
            CONTACT_EMAIL_HAS_ANY_VALUE: {
                id: 'CONTACT_EMAIL_HAS_ANY_VALUE',
                display: 'Has any Value',
                field: 'ContactContractorUniverse.contact_email',
                operatorDisplay: 'Has any Value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[contact_email]'
            },
            CONTACT_EMAIL_STATUS: {
                id: 'CONTACT_EMAIL_STATUS',
                display: 'Status',
                field: 'ContactContractorUniverse.contact_email_status',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                metaDataId: 'CONTACT_EMAIL_STATUS',
                fieldDisplay: '[contact_email_statusn]'
            },
        },
    },
    COMPANY: {
        NAME: {
            COMPANY_NAME: {
                id: 'COMPANY_NAME',
                display: 'Contains',
                field: 'CompanyContractorUniverse.company_name',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[company_name]'
            },
        },
        URL: {
            COMPANY_URL: {
                id: 'COMPANY_URL',
                display: 'Contains',
                field: 'CompanyContractorUniverse.company_url',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[company_url]'
            },
            COMPANY_URL_NOT_NULL: {
                id: 'COMPANY_URL_NOT_NULL',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_url',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_url]'
            },
        },
        LOCATION: {
            COMPANY_ADDRESS_CITY: {
                id: 'COMPANY_ADDRESS_CITY',
                display: 'City',
                field: 'CompanyContractorUniverse.company_address_city',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                metaDataId: 'COMPANY_ADDRESS_CITY',
                fieldDisplay: '[company_address_city]'
            },
            COMPANY_ADDRESS_REGION: {
                id: 'COMPANY_ADDRESS_REGION',
                display: 'Region',
                field: 'CompanyContractorUniverse.company_address_region',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                metaDataId: 'COMPANY_ADDRESS_REGION',
                fieldDisplay: '[company_address_region]'
            },
            COMPANY_ADDRESS_POSTAL_CODE: {
                id: 'COMPANY_POSTAL_CODE',
                display: 'Postal Code',
                field: 'CompanyContractorUniverse.company_address_postal_code',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                metaDataId: 'COMPANY_ADDRESS_POSTAL_CODE',
                fieldDisplay: '[company_address_postal_code]'
            },
            COMPANY_ADDRESS_LOC_MSA: {
                id: 'COMPANY_ADDRESS_LOC_MSA',
                display: 'MSA',
                field: 'CompanyContractorUniverse.company_address_loc_msa',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_address_loc_msa]'
            },
            COMPANY_ADDRESS_RESIDENTIAL_DELIVERY_INDICATOR: {
                id: 'COMPANY_ADDRESS_RESIDENTIAL_DELIVERY_INDICATOR',
                display: 'Residential Delivery Indicator',
                field: 'CompanyContractorUniverse.company_address_rdi',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                hide: true,
                fieldDisplay: '[company_address_rdi]'
            },
            COMPANY_ADDRESS_LOC_CSA: {
                id: 'COMPANY_ADDRESS_LOC_CSA',
                display: 'CSA',
                field: 'CompanyContractorUniverse.company_address_loc_csa',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                hide: true,
                fieldDisplay: '[company_address_loc_csa]'
            }
        },
        PHONE: {
            COMPANY_PHONE: {
                id: 'COMPANY_PHONE',
                display: 'CONTAINS',
                field: 'CompanyContractorUniverse.company_phone',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[company_phone]'
            },
            COMPANY_PHONE_NOT_NULL: {
                id: 'COMPANY_PHONE_NOT_NULL',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_phone',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_phone]'
            },
        },
        EMAIL: {
            COMPANY_EMAIL_CONTAINS: {
                id: 'COMPANY_EMAIL_CONTAINS',
                display: 'Contains',
                field: 'CompanyContractorUniverse.company_email',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[company_email]'
            },
            COMPANY_EMAIL_HAS_ANY_VALUE: {
                id: 'COMPANY_EMAIL_HAS_ANY_VALUE',
                display: 'Has any Value',
                field: 'CompanyContractorUniverse.company_email',
                operatorDisplay: 'Has any Value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_email]'
            },
            COMPANY_EMAIL_STATUS: {
                id: 'COMPANY_EMAIL_STATUS',
                display: 'Status',
                field: 'CompanyContractorUniverse.company_email_status',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                metaDataId: 'COMPANY_EMAIL_STATUS',
                fieldDisplay: '[company_email_statusn]'
            },
        },
        HIDDEN_FILTERS: {
            COMPANY_ID: {
                id: 'COMPANY_ID',
                display: 'Company Id',
                field: [
                    'CompanyContractorUniverse.company_id'
                ],
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[company_id]',
                hide: true,
            },
            COMPANY_IDENTITY: {
                id: 'company_identity',
                display: 'Company Identity',
                field: [
                    'CompanyContractorUniverse.company_identity'
                ],
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[company_identity]',
                hide: true,
            },
            COMPANY_ADDRESS_CITY_REGION_FORMATTED: {
                id: 'COMPANY_ADDRESS_CITY_REGION_FORMATTED',
                display: 'Company Address City and Region',
                field: [
                    'CompanyContractorUniverse.company_address_city_region_formatted'
                ],
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[company_address_city_region_formatted]',
                hide: true,
            },
            COMPANY_ADDRESS_GEO_RADIUS: {
                id: 'COMPANY_ADDRESS_GEO_RADIUS',
                display: 'Latitude, Long, Distance',
                field: 'CompanyContractorUniverse.company_address_latitude,CompanyContractorUniverse.company_address_longitude',
                operatorDisplay: 'within',
                operatorId: 'lat_long_radius',
                component: 'none',
                fieldDisplay: '[Radius]'
            },
            CONTACT_ID: {
                id: 'CONTACT_ID',
                display: 'Contact Id',
                field: [
                    'CompanyContractorUniverse.contact_id'
                ],
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[contact_id]',
                hide: true,
            },
            CONTACT_IDENTITY: {
                id: 'CONTACT_IDENTITY',
                display: 'Contact Identity',
                field: [
                    'CompanyContractorUniverse.contact_identity'
                ],
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                fieldDisplay: '[contact_identity]',
                hide: true,
            },
        }
    },
    FACTS: {
        STICKY_FILTER: {
            COMPANY_FACT_FOUNDED: {
                id: 'COMPANY_FACT_FOUNDED',
                display: 'Founded (yyyy)',
                field: 'CompanyContractorUniverse.company_fact_founded',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'textbox',
                fieldDisplay: '[company_fact_founded]',
            },
            COMPANY_FACT_FOUNDED_HAS_ANY_VALUE: {
                id: 'COMPANY_FACT_FOUNDED_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_fact_founded',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_fact_founded]'
            },
            COMPANY_FACT_BUSINESS_STRUCTURE: {
                id: 'COMPANY_FACT_BUSINESS_STRUCTURE',
                display: 'Business Structure',
                field: 'CompanyContractorUniverse.company_fact_business_structure',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_fact_business_structure]',
                metaDataId: 'COMPANY_FACT_BUSINESS_STRUCTURE',
            },
            COMPANY_FACT_PUBLIC_PRIVATE: {
                id: 'COMPANY_FACT_PUBLIC_PRIVATE',
                display: 'Public or Private',
                field: 'CompanyContractorUniverse.company_fact_public_private',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_fact_public_private]',
                metaDataId: 'COMPANY_FACT_PUBLIC_PRIVATE',
            },
            COMPANY_FACT_OWNERSHIP_TYPE: {
                id: 'COMPANY_FACT_OWNERSHIP_TYPE',
                display: 'Ownership Type',
                field: 'CompanyContractorUniverse.company_fact_ownership_types',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                hide: true,
                fieldDisplay: '[company_fact_ownership_types]',
                metaDataId: 'COMPANY_FACT_OWNERSHIP_TYPE',
            },
        },
    },
    STATISTICS: {
        STICKY_FILTER: {
            COMPANY_STAT_EMPLOYEES_RANGE: {
                id: 'COMPANY_STAT_EMPLOYEES_RANGE',
                display: 'Employees',
                field: 'CompanyContractorUniverse.company_stat_employees_range',
                operatorDisplay: 'in',
                operatorId: 'equals',
                multiple: true,
                component: 'combobox',
                metaDataId: 'COMPANY_STAT_EMPLOYEES_RANGE',
                fieldDisplay: '[company_stat_employees_range]'
            },
            COMPANY_STAT_EMPLOYEES_RANGE_HAS_ANY_VALUE: {
                id: 'COMPANY_STAT_EMPLOYEES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_stat_employees_range',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_stat_employees_range]'
            },
            COMPANY_STAT_SALES_RANGE: {
                id: 'COMPANY_STAT_SALES_RANGE',
                display: 'Sales',
                field: 'CompanyContractorUniverse.company_stat_sales_range',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                metaDataId: 'COMPANY_STAT_SALES_RANGE',
                fieldDisplay: '[company_stat_sales_range]'
            },
            COMPANY_STAT_SALES_RANGE_HAS_ANY_VALUE: {
                id: 'COMPANY_STAT_SALES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_stat_sales_range',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_stat_sales_range]'
            },
        },
    },
    TAXONOMY: {
        SEGMENTS: {
            COMPANY_PROFILE_KEYWORD_CATEGORIES: {
                id: 'COMPANY_PROFILE_KEYWORD_CATEGORIES',
                display: 'Part of',
                field: 'CompanyContractorUniverse.company_profile_keyword_categories',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'COMPANY_SEGMENTS', // metadataId deviates from naming convention; stub in metaDataServices is correct
                multiple: true,
                disabled: true, // TODO: Re-Enable Once We've Implemented NextGen Taxonomy
                fieldDisplay: '[company_profile_keyword_categories]' // could mask?
            },
            // TODO: Re-Enable Once We've Implemented NextGen Taxonomy
            // COMPANY_PROFILE_KEYWORD_CATEGORIES_HAS_ANY_VALUE: {
            //     id: 'COMPANY_PROFILE_KEYWORD_CATEGORIES_HAS_ANY_VALUE',
            //     display: 'has any value',
            //     field: 'CompanyContractorUniverse.company_profile_keyword_categories',
            //     operatorDisplay: 'has any value',
            //     operatorId: 'set',
            //     component: 'checkbox',
            //     fieldDisplay: '[company_profile_keyword_categories]'
            // },
        },
        CLASSIFICATION: {
            COMPANY_CLASS_PRIMARY_TRADE: {
                id: 'COMPANY_CLASS_PRIMARY_TRADE',
                display: 'Trade (Primary)',
                field: 'CompanyContractorUniverse.company_class_primary_trade',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                metaDataId: 'COMPANY_CLASS_PRIMARY_TRADE',
                multiple: true,
                fieldDisplay: '[company_class_primary_trade]'
            },
            COMPANY_CLASS_PRIMARY_TRADE_HAS_ANY_VALUE: {
                id: 'COMPANY_CLASS_PRIMARY_TRADE_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_class_primary_trade',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_class_primary_trade]'
            },
            COMPANY_CLASS_TRADES: {
                id: 'COMPANY_CLASS_TRADES',
                display: 'All Trades',
                field: 'CompanyContractorUniverse.company_class_trades',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'COMPANY_CLASS_TRADES',
                multiple: true,
                fieldDisplay: '[company_class_trades]'
            },
            COMPANY_CLASS_TRADES_HAS_ANY_VALUE: {
                id: 'COMPANY_CLASS_TRADES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_class_trades',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_class_trades]'
            },
            COMPANY_CLASS_NAICS_DESCRIPTIONS: {
                id: 'COMPANY_CLASS_NAICS_DESCRIPTIONS',
                display: 'NAICS',
                field: 'CompanyContractorUniverse.company_class_naics_titles_formatted',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'COMPANY_CLASS_NAICS_DESCRIPTIONS',
                multiple: true,
                hide: true,
                fieldDisplay: '[company_class_naics_titles_formatted]'
            },
            COMPANY_CLASS_NAICS_DESCRIPTIONS_HAS_ANY_VALUE: {
                id: 'COMPANY_CLASS_NAICS_DESCRIPTIONS_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_class_naics_titles_formatted',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                hide: true,
                fieldDisplay: '[company_class_naics_titles_formatted]'
            },
            COMPANY_CLASS_SIC_DESCRIPTIONS: {
                id: 'COMPANY_CLASS_SIC_DESCRIPTIONS',
                display: 'SIC',
                field: 'CompanyContractorUniverse.company_class_sic_titles_formatted',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'COMPANY_CLASS_SIC_DESCRIPTIONS',
                multiple: true,
                hide: true,
                fieldDisplay: '[company_class_sic_titles_formatted]'
            },
            COMPANY_CLASS_SIC_DESCRIPTIONS_HAS_ANY_VALUE: {
                id: 'COMPANY_CLASS_SIC_DESCRIPTIONS_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_class_sic_titles_formatted',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                hide: true,
                fieldDisplay: '[company_class_sic_titles_formatted]'
            },
        },
        SERVICES: {
            COMPANY_PROFILE_SERVICES: {
                id: 'COMPANY_PROFILE_SERVICES',
                display: 'offers',
                field: 'CompanyContractorUniverse.company_services',
                operatorDisplay: 'in',
                operatorId: 'contains', // must be contains to support JSON datatype w/n cubejs
                component: 'combobox',
                metaDataId: 'COMPANY_SERVICES', // metadataId deviates from naming convention; stub in metaDataServices is correct
                multiple: true,
                fieldDisplay: '[company_services]'
            },
            COMPANY_PROFILE_SERVICES_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILE_SERVICES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_services',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_services]'
            }
        },
    },
    INSIGHTS: {
        AFFINITIES_AFFILIATIONS: {
            COMPANY_AFFINITIES: {
                id: 'COMPANY_AFFINITIES',
                display: 'Affinities',
                field: 'CompanyContractorUniverse.company_affinities',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_affinities]',
                metaDataId: 'COMPANY_AFFINITIES',
            },
            COMPANY_AFFILIATIONS: {
                id: 'COMPANY_AFFILIATIONS',
                display: 'Affiliations',
                field: 'CompanyContractorUniverse.company_affiliations',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_affiliations]',
                metaDataId: 'COMPANY_AFFILIATIONS',
            },
        },
        INDICATORS: {
            COMPANY_INDICATOR_WORK_TYPES: {
                id: 'COMPANY_INDICATOR_WORK_TYPES',
                display: 'Work Types',
                field: 'CompanyContractorUniverse.company_ind_work_types_formatted',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_ind_work_types_formatted]',
                metaDataId: 'COMPANY_INDICATOR_WORK_TYPES',
            },
            COMPANY_INDICATOR_WORK_LOCATIONS: {
                id: 'COMPANY_INDICATOR_WORK_LOCATIONS',
                display: 'Work Locations',
                field: 'CompanyContractorUniverse.company_ind_work_locations_formatted',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_ind_work_locations_formatted]',
                metaDataId: 'COMPANY_INDICATOR_WORK_LOCATIONS',
            },
            COMPANY_INDICATOR_WORK_CREDENTIALS: {
                id: 'COMPANY_INDICATOR_WORK_CREDENTIALS',
                display: 'Work Credentials',
                field: 'CompanyContractorUniverse.company_ind_work_credentials_formatted',
                operatorDisplay: 'contains',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_ind_work_credentials_formatted]',
                metaDataId: 'COMPANY_INDICATOR_WORK_CREDENTIALS',
            },
        },
        SCORES: {
            COMPANY_SCORE_AT_LEAST: {
                id: 'COMPANY_SCORE_AT_LEAST',
                display: 'Company Score',
                field: 'CompanyContractorUniverse.company_score',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_score]',
            },
            COMPANY_SCORE_AT_MOST: {
                id: 'COMPANY_SCORE_AT_MOST',
                display: '',
                field: 'CompanyContractorUniverse.company_score',
                operatorDisplay: 'at most', // lte
                operatorId: 'lte', // lte
                component: 'numberInput',
                placeholder: 'at most',
                fieldDisplay: '[company_score]',
            },
            CONTACT_SCORE_AT_LEAST: {
                id: 'CONTACT_SCORE_AT_LEAST',
                display: 'Contact Score',
                field: 'CompanyContractorUniverse.company_score',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[contact_score]',
            },
            CONTACT_SCORE_AT_MOST: {
                id: 'CONTACT_SCORE_AT_MOST',
                display: '',
                field: 'CompanyContractorUniverse.company_score',
                operatorDisplay: 'at most', // lte
                operatorId: 'lte', // lte
                component: 'numberInput',
                placeholder: 'at most',
                fieldDisplay: '[contact_score]',
            },
        }
    },
    PROFILES: {
        STICKY_FILTER: {
            COMPANY_PROFILE_TYPES: {
                id: 'COMPANY_PROFILE_TYPES',
                display: 'types',
                field: 'CompanyContractorUniverse.company_profile_types_mapped',
                operatorDisplay: 'in',
                operatorId: 'contains',
                multiple: true,
                component: 'combobox',
                metaDataId: 'COMPANY_PROFILE_TYPES',
                fieldDisplay: '[company_profile_types_mapped]'
            },
            COMPANY_PROFILE_TYPES_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILE_TYPES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_profile_types_mapped',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_profile_types_mapped]'
            },
            COMPANY_PROFILE_SCORE_NORMALIZED_AVG: {
                id: 'COMPANY_PROFILE_SCORE_NORMALIZED_AVG',
                display: 'Normalized Score (Avg)',
                field: 'CompanyContractorUniverse.company_profile_normalized_score_avg',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_profile_normalized_score_avg]',
            },
            COMPANY_PROFILE_SCORE_NORMALIZED_AVG_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILE_SCORE_NORMALIZED_AVG_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_profile_normalized_score_avg',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_profile_normalized_score_avg]'
            },
            COMPANY_PROFILE_REVIEWS_RATINGS_AVG: {
                id: 'COMPANY_PROFILE_REVIEWS_RATINGS_AVG',
                display: 'Reviews & Ratings (Avg)',
                field: 'CompanyContractorUniverse.company_profile_reviews_ratings_avg',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_profile_reviews_ratings_avg]',
            },
            COMPANY_PROFILE_CNT: {
                id: 'COMPANY_PROFILE_CNT',
                display: 'number of',
                field: 'CompanyContractorUniverse.company_profile_cnt',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_profile_cnt]'
            },
        },
        ASSOCIATIONS: {
            COMPANY_PROFILE_ASSOCIATIONS: {
                id: 'COMPANY_PROFILE_ASSOCIATIONS',
                display: 'Types',
                field: 'CompanyContractorUniverse.company_profile_association_profiles',
                operatorDisplay: 'in',
                operatorId: 'contains',
                component: 'combobox',
                metaDataId: 'COMPANY_PROFILE_ASSOCIATIONS',
                multiple: true,
                fieldDisplay: '[company_profile_association_profiles]'
            },
            COMPANY_PROFILE_ASSOCIATIONS_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILE_ASSOCIATIONS_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_profile_association_profiles',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_profile_association_profiles]'
            },
            COMPANY_PROFILE_ASSOCIATION_CNT: {
                id: 'COMPANY_PROFILE_ASSOCIATION_CNT',
                display: 'Number Of',
                field: 'CompanyContractorUniverse.company_profile_association_profile_cnt',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_profile_association_profile_cnt]'
            }
        },
        MANUFACTURERS: {
            COMPANY_PROFILE_MANUFACTURERS: {
                id: 'COMPANY_PROFILE_MANUFACTURERS',
                display: 'Types',
                field: 'CompanyContractorUniverse.company_profile_manufacturer_profiles',
                operatorDisplay: 'in',
                operatorId: 'contains',
                component: 'combobox',
                metaDataId: 'COMPANY_PROFILE_MANUFACTURERS',
                multiple: true,
                fieldDisplay: '[company_profile_manufacturer_profiles]'
            },
            COMPANY_PROFILE_MANUFACTURERS_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILE_MANUFACTURERS_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_profile_manufacturer_profiles',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_profile_manufacturer_profiles]'
            },
            COMPANY_PROFILE_MANUFACTURER_CNT: {
                id: 'COMPANY_PROFILE_MANUFACTURER_CNT',
                display: 'Number Of',
                field: 'CompanyContractorUniverse.company_profile_manufacturer_profile_cnt',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_profile_manufacturer_profile_cnt]'
            }
        },
        ONLINE: {
            COMPANY_PROFILE_ONLINE_PROFILES: {
                id: 'COMPANY_PROFILE_ONLINE_PROFILES',
                display: 'Types',
                field: 'CompanyContractorUniverse.company_profile_online_profiles',
                operatorDisplay: 'in',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                metaDataId: 'COMPANY_PROFILE_ONLINE_PROFILES',
                fieldDisplay: '[company_profile_online_profiles]'
            },
            COMPANY_PROFILES_ONLINE_HAS_ANY_VALUE: {
                id: 'COMPANY_PROFILES_ONLINE_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_profile_online_profiles',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_profile_online_profiles]'
            },
            COMPANY_PROFILE_ONLINE_PROFILE_CNT: {
                id: 'COMPANY_PROFILE_ONLINE_PROFILE_CNT',
                display: 'Number Of',
                field: 'CompanyContractorUniverse.company_profile_online_profile_cnt',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                placeholder: 'at least',
                fieldDisplay: '[company_profile_online_profile_cnt]'
            },
            // TODO: COMPANY_PROFILE_ONLINE_CLAIMED
            // TODO: COMPANY_PROFILE_ONLINE_CLAIMED_CNT
            // TODO: COMPANY_PROFILE_ONLINE_SCORE_TYPES
            // TODO: COMPANY_PROFILE_ONLINE_SCORES
            // TODO: COMPANY_PROFILE_ONLINE_SCORE_CNT
        },
    },
    LICENSES: {
        STICKY_FILTER: {
            COMPANY_LICENSE_TYPES: {
                id: 'COMPANY_LICENSE_TYPES',
                display: 'Types',
                field: 'CompanyContractorUniverse.company_license_types_mapped',
                operatorDisplay: 'in',
                operatorId: 'contains',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_license_types]',
                metaDataId: 'COMPANY_LICENSE_TYPES',
            },
            COMPANY_LICENSE_TYPES_HAS_ANY_VALUE: {
                id: 'COMPANY_LICENSE_TYPES_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_ind_licensed',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_ind_licensed]'
            },
            COMPANY_LICENSE_ACTIVE_CNT_HAS_ANY_VALUE: {
                id: 'COMPANY_LICENSE_TYPES_HAS_ACTIVE_LICENSE',
                display: 'has any active value',
                field: 'CompanyContractorUniverse.company_license_active_license_cnt',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_license_active_license_cnt]'
            },
            COMPANY_LICENSE_CNT: {
                id: 'COMPANY_LICENSE_CNT',
                display: 'number of',
                field: 'CompanyContractorUniverse.company_license_cnt',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_license_cnt]',
                placeholder: 'at least',
                hide: true
            },
            COMPANY_LICENSE_ACTIVE_CNT: {
                id: 'COMPANY_LICENSE_ACTIVE_CNT',
                display: 'number of active',
                field: 'CompanyContractorUniverse.company_license_active_license_cnt',
                operatorDisplay: 'at least', // gte
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_license_active_license_cnt]',
                placeholder: 'at least',
                hide: true
            },
        },
    },
    PERMITS: {
        STICKY_FILTER: {
            // TODO: PERMIT CNT GETS MOVED TO FREQUENCY ONCE WE IMPLEMENT TYPES
            COMPANY_PERMIT_CNT: {
                id: 'COMPANY_PERMIT_CNT',
                display: 'Number Of',
                field: 'CompanyContractorUniverse.company_permit_cnt',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_cnt]'
            },
            COMPANY_PERMIT_CNT_HAS_ANY_VALUE: { // TODO: would prefer to use a non-numeric field but data model is null instead of zero so this works
                id: 'COMPANY_PERMIT_CNT_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_permit_cnt',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_permit_cnt]'
            },
            COMPANY_PERMIT_LAST_ISSUED: {
                id: 'COMPANY_PERMIT_LAST_ISSUED',
                display: 'last issued (on or after)',
                field: 'CompanyContractorUniverse.company_permit_last_issued',
                operatorDisplay: 'on or after',
                placeholder: 'on or after',
                operatorId: 'afterDate',
                component: 'datepicker',
                fieldDisplay: '[company_permit_last_issued]'
            },
        },
        FREQUENCY: {
            COMPANY_PERMIT_CNT_LAST_3_MONTHS: {
                id: 'COMPANY_PERMIT_CNT_LAST_3_MONTHS',
                display: 'Number of (last 3 months)',
                field: 'CompanyContractorUniverse.company_permit_cnt_last_3_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_cnt_last_3_months]',
            },
            COMPANY_PERMIT_CNT_LAST_6_MONTHS: {
                id: 'COMPANY_PERMIT_CNT_LAST_6_MONTHS',
                display: 'Number of (last 6 months)',
                field: 'CompanyContractorUniverse.company_permit_cnt_last_6_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_cnt_last_6_months]'
            },
            COMPANY_PERMIT_CNT_LAST_12_MONTHS: {
                id: 'COMPANY_PERMIT_CNT_LAST_12_MONTHS',
                display: 'Number of (last 12 months)',
                field: 'CompanyContractorUniverse.company_permit_cnt_last_12_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_cnt_last_12_months]'
            },
            COMPANY_PERMIT_CNT_LAST_24_MONTHS: {
                id: 'COMPANY_PERMIT_CNT_LAST_24_MONTHS',
                display: 'Number of (last 24 months)',
                field: 'CompanyContractorUniverse.company_permit_cnt_last_24_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_permit_cnt_last_24_months]'
            },
            COMPANY_PERMIT_CNT_LAST_36_MONTHS: {
                id: 'COMPANY_PERMIT_CNT_LAST_36_MONTHS',
                display: 'Number of (Last 36 months)',
                field: 'CompanyContractorUniverse.company_permit_cnt_last_36_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_permit_cnt_last_36_months]'
            },
        },
        VALUATION: {
            COMPANY_PERMIT_AVG_VALUATION: {
                id: 'COMPANY_PERMIT_AVG_VALUATION',
                display: 'Valuation (AVG)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_permitted_valuation_avg]',
            },
            COMPANY_PERMIT_AVG_VALUATION_LAST_3_MONTHS: {
                id: 'COMPANY_PERMIT_AVG_VALUATION_LAST_3_MONTHS',
                display: 'Valuation (last 3 months)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg_last_3_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_permitted_valuation_avg_last_3_months]'
            },
            COMPANY_PERMIT_AVG_VALUATION_LAST_6_MONTHS: {
                id: 'COMPANY_PERMIT_AVG_VALUATION_LAST_6_MONTHS',
                display: 'Valuation (last 6 months)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg_last_6_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_permit_permitted_valuation_avg_last_6_months]'
            },
            COMPANY_PERMIT_AVG_VALUATION_LAST_12_MONTHS: {
                id: 'COMPANY_PERMIT_AVG_VALUATION_LAST_12_MONTHS',
                display: 'Valuation (last 12 months)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg_last_12_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_permit_permitted_valuation_avg_last_12_months]'
            },
            COMPANY_PERMIT_AVG_VALUATION_LAST_24_MONTHS: {
                id: 'COMPANY_PERMIT_AVG_VALUATION_LAST_24_MONTHS',
                display: 'Valuation (last 24 months)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg_last_24_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_permit_permitted_valuation_avg_last_24_months]'
            },
            COMPANY_PERMIT_AVG_VALUATION_LAST_36_MONTHS: {
                id: 'COMPANY_PERMIT_AVG_VALUATION_LAST_36_MONTHS',
                display: 'Valuation (last 36 months)',
                field: 'CompanyContractorUniverse.company_permit_permitted_valuation_avg_last_36_months',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                hide: true,
                fieldDisplay: '[company_permit_permitted_valuation_avg_last_36_months]'
            },
        }
    },
    POLICIES: {
        STICKY_FILTER: {
            // TODO: POLICY CNT GETS MOVED ONCE WE IMPLEMENT TYPES
            COMPANY_POLICY_CNT: {
                id: 'COMPANY_POLICY_CNT',
                display: 'number of',
                field: 'CompanyContractorUniverse.company_policy_cnt',
                operatorDisplay: 'at least', // gte
                placeholder: 'at least',
                operatorId: 'gte', // gte
                component: 'numberInput',
                fieldDisplay: '[company_policy_cnt]'
            },
            COMPANY_POLICY_CNT_HAS_ANY_VALUE: { // TODO: would prefer to use a non-numeric field but data model is null instead of zero so this works
                id: 'COMPANY_POLICY_CNT_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_policy_cnt',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_policy_cnt]'
            }
        },
    },
    REGISTRATIONS: {
        STICKY_FILTER: {
            COMPANY_REG_LOCAL_REGISTRATION_STATUS: {
                id: 'COMPANY_REG_LOCAL_REGISTRATION_STATUS',
                display: 'Status',
                field: 'CompanyContractorUniverse.company_registration_local_registration_status',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_registration_local_registration_status]',
                metaDataId: 'COMPANY_REG_LOCAL_REGISTRATION_STATUS',
            },
            COMPANY_REG_LOCAL_REGISTRATION_STATUS_HAS_ANY_VALUE: {
                id: 'COMPANY_REG_LOCAL_REGISTRATION_STATUS_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_registration_local_registration_status',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_registration_local_registration_status]'
            },
            COMPANY_REG_LOCAL_REGISTRAR: {
                id: 'COMPANY_REG_LOCAL_REGISTRAR',
                display: 'registrar',
                field: 'CompanyContractorUniverse.company_registration_local_registration_registrar',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_registration_local_registration_registrar]',
                metaDataId: 'COMPANY_REG_LOCAL_REGISTRAR',
            },
            COMPANY_REG_LOCAL_REGISTRAR_REGION: {
                id: 'COMPANY_REG_LOCAL_REGISTRAR_REGION',
                display: 'region',
                field: 'CompanyContractorUniverse.company_registration_local_registration_registrar_region',
                operatorDisplay: 'in',
                operatorId: 'equals',
                component: 'combobox',
                multiple: true,
                fieldDisplay: '[company_registration_local_registration_registrar_region]',
                metaDataId: 'COMPANY_REG_LOCAL_REGISTRAR_REGION',
            },
        },
    },
    SOURCES: {
        STICKY_FILTER: {
            COMPANY_SOURCE_CNT: {
                id: 'COMPANY_SOURCE_CNT',
                display: 'number of',
                field: 'CompanyContractorUniverse.company_source_cnt',
                operatorDisplay: 'at least',
                placeholder: 'at least',
                operatorId: 'gte',
                component: 'numberInput',
                fieldDisplay: '[company_source_cnt]'
            },
            COMPANY_AUTHORITATIVE_SOURCE_CNT: {
                id: 'COMPANY_AUTHORITATIVE_SOURCE_CNT',
                display: 'number of authoritative',
                field: 'CompanyContractorUniverse.company_authoritative_source_cnt',
                operatorDisplay: 'at least',
                placeholder: 'at least',
                operatorId: 'gte',
                component: 'numberInput',
                fieldDisplay: '[company_authoritative_source_cnt]'
            },
            COMPANY_AUTHORITATIVE_SOURCE_CNT_HAS_ANY_VALUE: {
                id: 'COMPANY_AUTHORITATIVE_SOURCE_CNT_HAS_ANY_VALUE',
                display: 'has any value',
                field: 'CompanyContractorUniverse.company_authoritative_source_cnt',
                operatorDisplay: 'has any value',
                operatorId: 'set',
                component: 'checkbox',
                fieldDisplay: '[company_authoritative_source_cnt]'
            },
            COMPANY_SOURCE_LAST_TOUCH: {
                id: 'COMPANY_SOURCE_LAST_TOUCH',
                display: 'Last Touch (on or after)',
                field: 'CompanyContractorUniverse.company_source_last_touch',
                operatorDisplay: 'on or after',
                placeholder: 'on or after',
                operatorId: 'afterDate',
                component: 'datepicker',
                fieldDisplay: '[company_source_last_touch]'
            }
        }
    },
};

