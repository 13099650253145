import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    tcuFilter: false,
    selectedId: [],
    selectedContacts: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'companyScore', direction: 'DESC'}, 
    orderSecondary: {by: 'contactId', direction: 'ASC'}, 
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    contacts: [],
};

export const getManagedContacts = createAsyncThunk('contacts/get-all', async (arg, {getState, requestId}) => {
    const GET_Contacts_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-contacts`;
    const query = buildQuery(getState().managedContacts.currentPage, getState().managedContacts.pageSize,
        getState().managedContacts.order.by, getState().managedContacts.order.direction,      
        getState().managedContacts.orderSecondary.by, getState().managedContacts.orderSecondary.direction, 
        getState().managedContacts.searchCriteria, getState().managedContacts.tcuFilter);
    const contacts = await Api.get(true, GET_Contacts_URL, query);
    return contacts;
});

export const getManagedContactsWithFilter = createAsyncThunk('contacts/get-all-with-filter', async (filter) => {
    const GET_MANAGED_ORGANIZATIONS_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-contacts`;
    const contacts = await Api.get(true, GET_MANAGED_ORGANIZATIONS_URL, filter);
    return contacts;
});

export const getManagedContactsCount = createAsyncThunk('contacts/getCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const searchCriteria = (getState().managedContacts ? getState().managedContacts.searchCriteria : '');
        const tcuFilter = (getState().managedContacts ? getState().managedContacts.tcuFilter : false);
        const where = {
            where: {
                and: [
                    {
                        or: [{                        
                                contactId: {
                                    eq: `${searchCriteria}`
                                }
                            },{
                                companyId: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    },
                    (tcuFilter ?
                        {
                            companyProductTcu: {
                                eq: true
                            }
                        }
                        : {}
                    )
                ]
            }
        }
        const SOURCE_COUNT_URL = `${config.services.ATLAS.dataBaseUrl}/denormalized-contacts/count`;
        const contactsCount = await Api.get(true, SOURCE_COUNT_URL, searchCriteria ? where:'');
        return contactsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

//
const buildQuery = (currentPage, pageSize, orderBy,orderDirection,orderByOption,orderDirectionOptional, searchCriteria, tcuFilter) => {
  
    const query = searchCriteria ? {
        filter: {
            where: {
                and: [
                    {
                        or: [{                        
                                contactId: {
                                    eq: `${searchCriteria}`
                                }
                            }
                        ]
                    },
                    (tcuFilter ?
                        {
                            companyProductTcu: {
                                eq: true
                            }
                        }
                        : {}
                    )
                ]
            },
            order: (`${orderBy} ${orderDirection},${orderByOption} ${orderDirectionOptional}`),

            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    }: {
        filter: {
            order: (`${orderBy} ${orderDirection},${orderByOption} ${orderDirectionOptional}`),       
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
    },
};

    return query;
};

const managedContactsSlice = createSlice({
    name: 'managedContactsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        clearSelectedContacts: {
            reducer(state, action) {
                state.selectedContacts = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {                
                state.currentPage = action.payload;
            }
        },
        setPageSize: {
            reducer(state, action) {                
                state.pageSize = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedContacts.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedContacts.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        setTcuFilter: {
            reducer(state, action) {
                state.tcuFilter = action.payload;
                state.currentPage = 1;
            }
        },
        resetContactState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedContacts.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedContacts.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contacts = action.payload;
            }
        },
        [getManagedContacts.rejected]: (state, action) => {
            state.loading = false;
        },
        [getManagedContactsCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedContactsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getManagedContactsCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getManagedContactsWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getManagedContactsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.contacts = action.payload;
            }
        },
        [getManagedContactsWithFilter.rejected]: (state, action) => {
            state.loading = false;
        }
    }
});


export const {setCurrentPage, setPageSize, setOrder, setSelectedId, clearSelected, clearSelectedContacts, setSearchCriteria, setTcuFilter, resetContactState} = managedContactsSlice.actions;
export default managedContactsSlice.reducer;
