import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import { isGuid } from "../../common/utils/Utils";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedMatchTemplates: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedMatchTemplates: [],
    managedMatchTemplate: null,
};

export const getManagedMatchTemplates = createAsyncThunk('managedMatchTemplates/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-match-templates`;
    const managedMatchTemplates = await Api.get(true, GET_URL, buildQuery(getState().managedMatchTemplates.currentPage, getState().managedMatchTemplates.pageSize,
        getState().managedMatchTemplates.order.by, getState().managedMatchTemplates.order.direction, getState().managedMatchTemplates.searchCriteria));
    return managedMatchTemplates;
});

export const getManagedMatchTemplatesWithFilter = createAsyncThunk('managedMatchTemplates/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-match-templates`;
    const managedMatchTemplates = await Api.get(true, GET_URL, filter);
    if (managedMatchTemplates && managedMatchTemplates.length > 0) {
        const uniqueMatchTypes = _.uniq(_.map(managedMatchTemplates, matchTemplate => matchTemplate.matchTypeId));
        const matchTypes = await Api.get(true, `${config.services.ATLAS.apiUrl}/managed-match-types`, {filter: {where: {id: {inq: uniqueMatchTypes}}}});
        return _.map(managedMatchTemplates, matchTemplate => {
            const matchType = matchTypes.find(type => type.id === matchTemplate.matchTypeId);
            return ({
                    ...matchTemplate,
                    matchType: {
                        ...matchType
                    }

                }
            )
        });
    }
    return managedMatchTemplates;
});

export const getManagedMatchTemplatesCount = createAsyncThunk('managedMatchTemplates/getCount', async (arg, {getState, requestId}) => {
    try {
        const searchCriteria = getState().managedMatchTemplates.searchCriteria ? getState().managedMatchTemplates.searchCriteria : '';
        const where = isGuid(searchCriteria) ?
            {
            where: {
                or: [
                    {
                        id: {
                            eq: searchCriteria
                        }
                    },
                    {
                        matchTypeId: {
                            eq: searchCriteria
                        }
                    },
                    {
                        name: {
                            ilike: `%${searchCriteria}%`
                        }
                    },
                    {
                        description: {
                            ilike: `%${searchCriteria}%`
                        }
                    }
                ]
            }
        } : {
                where: {
                    or: [
                        {
                            name: {
                                ilike: `%${searchCriteria}%`
                            }
                        },
                        {
                            description: {
                                ilike: `%${searchCriteria}%`
                            }
                        }
                    ]
                }
            };
        const COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-match-templates/count`;
        // const managedMatchTemplatesCount = await Api.get(true, COUNT_URL, where);
        const managedMatchTemplatesCount = await Api.get(true, COUNT_URL, {filter: where});
        return managedMatchTemplatesCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = isGuid(searchCriteria) ?
        {
        filter: {
            where: {
                and: [
                        {
                            or: [
                                    {
                                        id: {
                                            eq: searchCriteria
                                            }
                                    },
                                    {
                                        matchTypeId: {
                                            eq: searchCriteria
                                        }
                                    },
                                    {
                                        name: {
                                            ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                        }
                                    },
                                    {
                                        description: {
                                            ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                        }
                                    }
                                ]
                        }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
            filter: {
                where: {
                    and: [
                        {
                            or: [
                                {
                                    name: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                },
                                {
                                    description: {
                                        ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                    }
                                }
                            ]
                        }
                    ]
                },
                order: [`${orderBy} ${orderDirection}`],
                limit: pageSize,
                skip: (currentPage - 1) * pageSize
            },
        };
    return query;
};

export const createManagedMatchTemplate = createAsyncThunk('matchTemplates/createManagedMatchTemplate',
    async (input,  { rejectWithValue }) => {
        const URL = `${config.services.ATLAS.apiUrl}/managed-match-templates`;
        console.log(input);
        try {
            const matchTemplate = await Api.post(true,
                URL,
                {
                    options: null,
                    data: input.matchTemplate
                }
            );
            return matchTemplate;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.body);
        }
    });

const managedMatchTemplatesSlice = createSlice({
    name: 'managedMatchTemplatesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTemplates = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedMatchTemplates.splice(state.selectedMatchTemplates.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedMatchTemplates.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedMatchTemplateState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedMatchTemplates.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplates.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTemplates = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplates.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTemplates = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedMatchTemplatesWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [createManagedMatchTemplate.pending]: (state, action) => {
            state.loading = true;
        },
        [createManagedMatchTemplate.fulfilled]: (state, action) => {
            state.loading = false;
            state.successMessage = `Successfully created a new match template [${action.payload.id}]`
            state.reset = true;
        },
        [createManagedMatchTemplate.rejected]: (state, action) => {
            state.loading = false;
            if (action?.payload) {
                let errObj = undefined;
                try {
                    errObj = action?.payload;
                } catch (e) {
                    // Don't need to do anything.
                }
                if (errObj) {
                    state.alertMessage = `${errObj.error.message}.`;
                } else {
                    state.alertMessage = action.error.message;
                }
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria,
    resetCreateManagedMatchTemplateState
} = managedMatchTemplatesSlice.actions;
export default managedMatchTemplatesSlice.reducer;
