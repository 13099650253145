import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    selectedIntegrations: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedIntegrations: [],
    managedIntegration: null,
};

export const getManagedIntegrations = createAsyncThunk('managedIntegrations/get-all', async (arg, {getState, requestId}) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-integrations`;
    const managedIntegrations = await Api.get(true, GET_URL, buildQuery(getState().managedIntegrations.currentPage, getState().managedIntegrations.pageSize,
        getState().managedIntegrations.order.by, getState().managedIntegrations.order.direction, getState().managedIntegrations.searchCriteria));
    return managedIntegrations;
});

export const getManagedIntegrationsWithFilter = createAsyncThunk('managedIntegrations/get-all-with-filter', async (filter) => {
    const GET_URL = `${config.services.ATLAS.apiUrl}/managed-integrations`;
    const managedIntegrations = await Api.get(true, GET_URL, filter);
    return managedIntegrations;
});

export const getManagedIntegrationsCount = createAsyncThunk('managedIntegrations/getCount', async (arg, {getState, requestId}) => {
    try {
        const where = buildQuery(getState().managedIntegrations.currentPage, getState().managedIntegrations.pageSize,
            getState().managedIntegrations.order.by, getState().managedIntegrations.order.direction, getState().managedIntegrations.searchCriteria);
        const COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-integrations/count`;
        const managedIntegrationsCount = await Api.get(true, COUNT_URL, where);
        return managedIntegrationsCount?.count;
    } catch (err) {
        console.error(err);
    }
});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isGuid = regexExp.test(searchCriteria);
    const query = isGuid ? {
        filter: {
            where: {
                and: [
                    {
                        or: [{
                            id: {
                                eq: `${searchCriteria ? searchCriteria : ''}`
                            }
                        },
                            {
                                organizationId: {
                                    eq: `${searchCriteria ? searchCriteria : ''}`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
        } : {
        filter: {
            where: {
                    or: [
                        {
                            name: {
                                ilike: `%${searchCriteria ? searchCriteria : ''}%`
                            }
                        },
                        {
                            description: {
                                ilike: `%${searchCriteria ? searchCriteria : ''}%`
                            }
                        }
                    ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
};

export const createManagedIntegration = createAsyncThunk('integrations/createManagedIntegration',
    async (data,  { rejectWithValue }) => {
        const URL = `${config.services.ATLAS.apiUrl}/managed-integrations`;
        try {
            const integration = await Api.post(true,
                URL,
                {
                    options: null,
                    data: data
                }
            );
            return integration;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.body);
        }
    });

const managedIntegrationsSlice = createSlice({
    name: 'managedIntegrationsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedIntegrations = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedIntegrations.splice(state.selectedIntegrations.indexOf((list) => list.id === action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedIntegrations.push(action.payload);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetCreateManagedIntegrationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedIntegrations.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrations.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedIntegrations = action.payload;
                state.download = undefined;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrations.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsCount.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsCount.rejected]: (state, action) => {
            state.loading = false;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsWithFilter.pending]: (state, action) => {
            state.loading = true;

            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedIntegrations = action.payload;
                state.download = undefined;
            }
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [getManagedIntegrationsWithFilter.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
        },
        [createManagedIntegration.pending]: (state, action) => {
            state.loading = true;
        },
        [createManagedIntegration.fulfilled]: (state, action) => {
            state.loading = false;
            state.successMessage = `Successfully created a new integration [${action.payload.id}]`
            state.reset = true;
        },
        [createManagedIntegration.rejected]: (state, action) => {
            state.loading = false;
            if (action?.payload) {
                let errObj = undefined;
                try {
                    errObj = action?.payload;
                } catch (e) {
                    // Don't need to do anything.
                }
                if (errObj) {
                    state.alertMessage = `${errObj.error.message}.`;
                } else {
                    state.alertMessage = action.error.message;
                }
            } else {
                console.error(`There was an unhandled rejection`, action);
            }
        },
    }
});


export const {
    setCurrentPage,
    setOrder,
    setSelectedId,
    clearSelected,
    setSearchCriteria,
    resetCreateManagedIntegrationState,
} = managedIntegrationsSlice.actions;
export default managedIntegrationsSlice.reducer;
