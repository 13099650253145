import * as Api from "../Api";
import config from "../../config";

export const getSupplierRecordBySourceId = async (sourceId) => {

    const filter = {
       where: {
           sourceId: sourceId
       },
        limit: 5000,
    };
    return Api.get(true, `${config.services.ATLAS.apiUrl}/supplier-records`, {filter});
};

export const getSupplierRecordByCompanyRecordId = async (sourceId, id) => {

    const filter = {
        where: {
            and: [
                {sourceId: sourceId},
                {companyRecordId: id}
            ]
        },
        limit: 1
    };
    return Api.get(true, `${config.services.ATLAS.apiUrl}/supplier-records`, {filter});
};

export const getSuppliers = async (filter) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/suppliers`;
    const suppliers = await Api.get(true, GET_SOURCE_URL, filter);
    return suppliers;
};
