/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {
    createOrganization, resetCreateOrganizationState
} from "./organizationsSlice";

import _ from 'lodash';
import {Row, Col, Button, Form, Table} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import BreadCrumb from '../../common/components/BreadCrumb';
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";
import Container from "../../common/components/layout/Container";
import defaultConfig from './organization-default-config.json';
import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import OnboardOrganizationForm from './OnboardOrganizationForm';
import LinkButton from '../../common/components/LinkButton';
import {StickyContainer, Sticky} from 'react-sticky';

export default class CreateOrganizationContainer extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <CreateOrganization/>
        )
    }
}

function CreateOrganization(props) {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Organizations',
        href: '/admin/organizations',
        isActive: false
    }, {label: 'Onboard Organization', href: '', isActive: true}];
    const organizationState = useSelector(state => state.organizations);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [formValid, setFormValid] = useState(true);
    const [orgConfig, setOrgConfig] = useState(defaultConfig);
    // const [editAsJSON, setEditAsJSON] = useState(false);
    // const [editBtnTxt, setEditBtnTxt] = useState('View JSON');

    const reset = () => {
        setOrgConfig(defaultConfig);
        setAlertMessage(undefined);
        setShowAlert(false);
        setShowSuccess(false);
        setSuccessMessage(undefined);

        dispatch(resetCreateOrganizationState());
    }

    const isValid = () => {
        if (_.isEmpty(orgConfig)) return false;
        return true;
    };

    useEffect(() => {
        // When open dialog, we clear state.
        if (organizationState.reset) {
            reset()
        }
    }, [organizationState.reset]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (organizationState.successMessage) {
            setSuccessMessage(organizationState.successMessage);
            setShowSuccess(true);
        }
    }, [organizationState.successMessage]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (organizationState.alertMessage) {
            setAlertMessage(organizationState.alertMessage);
            setShowAlert(true);
        }
    }, [organizationState.alertMessage]);

    const handleCreateOrganization = async () => {
        if (!isValid()) {
            setFormValid(false);
        } else {
            dispatch(createOrganization(orgConfig));
        }
    };

    const PreLikeText = styled.div `
        margin-top: 5px;
        display: block;
        white-space: pre-wrap;
        unicode-bidi: embed;
        font-family: monospace;
        color: #212529;
        font-size: 87.5%;
        `;

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={'Onboard Organization'}
                subHeader={'Create (onboard) a new client organization'}
            />
            <Panel>
                {organizationState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="createOrganization">
                            <Row>
                                <Col md={6}>
                                    <OnboardOrganizationForm props={{
                                        orgConfigProp: orgConfig,
                                        setOrgConfigProp: setOrgConfig,
                                        formValidProp: formValid
                                    }} />
                                </Col>
                                {/* <Col md={2} style={{display: 'flex', flexFlow: 'column', height:'100vh'}}>
                                    <div id='editAsJSONBtn-container' style={{position: 'sticky', top: 0, padding: '15px'}}>
                                    <LinkButton id="editAsJSONBtn" onClick={() => {
                                        if (!editAsJSON) {
                                            setEditBtnTxt('Edit Form');
                                        } else {
                                            setEditBtnTxt('View JSON');
                                        }
                                        setEditAsJSON(!editAsJSON);
                                        }}>{editBtnTxt}</LinkButton>
                                    </div>
                                </Col> */}
                                <Col md={2}></Col>
                                <Col md={4} style={{display: 'flex', flexFlow: 'column', height: '150vh'}}>
                                        <div style={{position: 'sticky', top: 0, padding: '15px'}}>
                                            <h6 style={{fontWeight: "600"}}>Help</h6>
                                            <PreLikeText>
                                                <Table size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>Property</th>
                                                        <th>Description</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>name</td>
                                                        <td style={{padding: '5px'}}>The organization name</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>givenName</td>
                                                        <td style={{padding: '2px'}}>The organization owner's given name</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>surName</td>
                                                        <td style={{padding: '2px'}}>The organization owner's surname name</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '2px'}}>email</td>
                                                        <td style={{padding: '2px'}}>The organization owner's email.  Must be a valid email address</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>companyScore</td>
                                                        <td style={{padding: '5px'}}>Numeric value in range 0.6 - 0.9. Default value is 0.8</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>contactScore</td>
                                                        <td style={{padding: '5px'}}>Numeric value in range 0.6 - 0.9. Default value is 0.8</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>allowUnfiltered</td>
                                                        <td style={{padding: '5px'}}>Allows unfiltered scores. Default value is false</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>features</td>
                                                        <td style={{padding: '5px'}}>Options are Search, Enrich, and Export</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>segments</td>
                                                        <td style={{padding: '5px'}}>Currently disabled</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>classifications</td>
                                                        <td style={{padding: '5px'}}>Uncheck unrestricted to select from available classifications</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>services</td>
                                                        <td style={{padding: '5px'}}>Currently disabled</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>unrestricted</td>
                                                        <td style={{padding: '5px'}}>Default is selected. Deselect to edit Taxonomies fields</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>cities</td>
                                                        <td style={{padding: '5px'}}>Uncheck unrestricted to enter cities (separated by commas)</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>msa</td>
                                                        <td style={{padding: '5px'}}>Uncheck unrestricted to select from available msa's</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>regions</td>
                                                        <td style={{padding: '5px'}}>Uncheck unrestricted to select from available regions</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>unrestricted</td>
                                                        <td style={{padding: '5px'}}>Default is selected. Deselect to edit Geographies fields</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding: '5px'}}>suppliers</td>
                                                        <td style={{padding: '5px'}}>Put something here</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </PreLikeText>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        id='onboard-btn'
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => handleCreateOrganization()}
                                    >ONBOARD
                                    </Button>
                                    <Button
                                        id='cancel-btn'
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            reset();
                                            history.push('/admin/organizations')
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}
