/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useState} from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkButton from "./LinkButton";
import {APP_COLORS} from "../Constants";

function TogglePanel(props) {
    const [expand, setExpand] = useState(props.isExpand ? props.isExpand : false);
    const [more, setMore] = useState(false);

    const handleOnClick = (value) => {
        setExpand(value);
        props.handleOnClick(value);
    };
    const visibleChildren = props.children;
    const hideChildren = props.hideChildren;
    return (
        <div className="accordionWrapper">
            <div
                className={classNames({
                    "accordionTitle": true,
                    "open": expand,
                })}
            >
                <div
                    className="accordionHeaderContent"
                    onClick={() => handleOnClick(!expand)}
                >
                    <div className="title">{props.title}</div>
                </div>
                <div
                    className="expandIcon"
                    onClick={() => handleOnClick(!expand)}
                >
                    { expand ?
                        <FontAwesomeIcon icon={faMinusCircle} />
                        :
                        <FontAwesomeIcon icon={faPlusCircle} />
                    }
                </div>
            </div>
            <div
                className={classNames({
                    "accordionItem": true,
                    "collapsed": !expand,
                })}
            >
                <div
                    className="accordionContent"
                    style={{
                        maxHeight: props.contentHeight ? props.contentHeight : 'auto',
                    }}
                >
                    {visibleChildren}
                    {more &&
                        <div>
                            {hideChildren}
                        </div>
                    }
                    {hideChildren &&
                        <div className="accordionShowMoreContent">
                            <LinkButton
                                onClick={() => setMore(!more)}
                            >
                                {more ?
                                    <span style={{color: APP_COLORS.info}}>LESS</span>
                                    :
                                    <span style={{color: APP_COLORS.info}}>MORE</span>
                                }
                            </LinkButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

TogglePanel.propTypes = {
    contentHeight: PropTypes.number,
    isExpand: PropTypes.bool,
    handleOnClick: PropTypes.func,
    hideChildren: PropTypes.object
};

TogglePanel.defaultProps = {
    handleOnClick: (e) => e,
};

export default TogglePanel;
