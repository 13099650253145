/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";

const CardContainer = styled.div `
  display: grid;
  grid-template-columns: 100px minmax(100px,400px) minmax(20px,300px) minmax(20px,300px) minmax(20px,250px) minmax(20px,3000px) minmax(160px,400px) 1fr;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  min-height: 100px;
  width: 100%;
  min-width: 1540px;
`;

export default CardContainer;
