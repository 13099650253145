import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import {parseCubeFilter, parseDimension} from "../explore/CubeFilterBuilder";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    lists: [],
    selectedList: [],
    status: {
        status: 'idle',
        error: undefined,
    }
};

export const getLists = createAsyncThunk('lists/get-all', async (arg, {getState, requestId}) => {
    const GET_LIST_URL = `${config.services.ATLAS.apiUrl}/lists`;
    const lists = await Api.get(true, GET_LIST_URL, buildQuery(getState().list.currentPage, getState().list.pageSize,
        getState().list.order.by, getState().list.order.direction, getState().list.searchCriteria));
    return lists;
});

export const getListsWithFilter = createAsyncThunk('lists/get-all-with-filter', async (filter) => {
    const GET_LIST_URL = `${config.services.ATLAS.apiUrl}/lists`;
    const lists = await Api.get(true, GET_LIST_URL, filter);
    return lists;
});

export const getListsCount = createAsyncThunk('lists/getListCount', async (arg, {getState, requestId}) => {
    const query = {
        where: {
            and: [
                {
                    or: [{
                        name: {
                            ilike: `%${getState().list.searchCriteria ? getState().list.searchCriteria : ''}%`
                        }
                    },
                        {
                            description: {
                                ilike: `%${getState().list.searchCriteria ? getState().list.searchCriteria : ''}%`
                            }
                        }
                    ]
                }
            ]
        },
    };

    const LIST_COUNT_URL = `${config.services.ATLAS.apiUrl}/lists/count`;
    const listsCount = await Api.get(true, LIST_COUNT_URL, {filter: query});
    return listsCount?.count;
});

export const exportListCriteria = createAsyncThunk('list/export-list-criteria',
    async ({name, type, description, filters, cubeQuery, notifyByEmailOnSuccessOrFailure}) => {
        if (!type || !name) {
            throw new Error('required field.');
        }
        const _dimensions = parseDimension(type);
        const requestUrl = `${config.services.ATLAS.apiUrl}/lists/export`;
        const data = {
            data: {
                name: name,
                description: description || '',
                type: type,
                query: {
                    type: type,
                    requestUrl: requestUrl,
                    requestQuery: {
                        dimensions: _dimensions,
                        filters: cubeQuery,
                        ungrouped: true,
                    },
                    filters: {
                        value: filters,
                        version: 0.1,
                    }
                },
                config: {
                    notifyByEmailOnSuccessOrFailure: notifyByEmailOnSuccessOrFailure,
                }
            }
        };
        try {
            const resultSet = await Api.post(true,
                requestUrl,
                data
            );
            return resultSet;
        } catch(err) {
            throw new Error(`Unable to export list, ${err.message}`,);
        }

});

const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const query = {
        filter: {
            fields: ['id', 'name', 'type', 'created', 'status', 'description', 'dataCntRecords', 'dataCntCompanies', 'dataCntContacts', 'config'],
            where: {
                and: [
                    {
                        or: [{
                            name: {
                                ilike: `%${searchCriteria ? searchCriteria : ''}%`
                            }
                        },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }, {
                        tableRef: null
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    // const query = {
    //     filter: {
    //         fields: ['id', 'name', 'type', 'created', 'status', 'description', 'dataCntRecords', 'dataCntCompanies', 'dataCntContacts'],
    //         where: {
    //             name: {
    //                 ilike: `%${searchCriteria ? searchCriteria : ''}%`
    //             }
    //         },
    //         order: `${orderBy} ${orderDirection}`,
    //         limit: pageSize,
    //         skip: (currentPage - 1) * pageSize
    //     },
    // };

    return query;
}


const listSlice = createSlice({
    name: 'listSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                        state.selectedList.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                        state.selectedList.push(action.payload.list);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        resetExport: {
            reducer(state, action) {
                state.status.status = 'idle';
                state.status.error = '';
            }
        }
    },
    extraReducers: {
        [getLists.pending]: (state, action) => {
            state.loading = true;
        },
        [getLists.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.lists = action.payload;
            }
        },
        [getLists.rejected]: (state, action) => {
            state.loading = false;
        },
        [getListsCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getListsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getListsCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getListsWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getListsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.lists = action.payload;
            }
        },
        [getListsWithFilter.rejected]: (state, action) => {
            state.loading = false;
        },


        [exportListCriteria.pending]: (state, action) => {
            state.loading = true;
            state.status.status = 'loading';
        },
        [exportListCriteria.fulfilled]: (state, action) => {
            state.loading = false;
            state.status.status = 'succeeded';
        },
        [exportListCriteria.rejected]: (state, action) => {
            state.loading = false;
            state.status.status = 'error';
            state.status.error = action?.error?.message
        },
    }
})


export const {setCurrentPage, setOrder, setSelectedId, clearSelected, setSearchCriteria, resetExport} = listSlice.actions;
export default listSlice.reducer;
