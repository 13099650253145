import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import config from '../../config'
import * as Api from '../../common/Api';
import {LIST_STATUSES} from "../../common/metadata/ListStatuses";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    adminList: null,
    listMatchReport: null,
};


export const getMatchListById = createAsyncThunk('matchListReport/get-by-id', async (id) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-lists/${id}`;
    const adminList = await Api.get(true, URL);
    return adminList;
});

export const createMatchReport = createAsyncThunk('matchListReport/createMatchReport',
    async ({id}) => {
        const GET_URL = `${config.services.ATLAS.apiUrl}/managed-list-matches`;
        const DOWNLOAD_LIST = `${GET_URL}/${id}/create-match-report`;
        return await Api.get(true,
            DOWNLOAD_LIST,
            {
                options: {
                },
                data: {
                }
            }
        );
    });

const adminListMatchReportSlice = createSlice({
    name: 'adminListMatchReportSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.adminList = null;
            return state;
        },
    },
    extraReducers: {
        [getMatchListById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
            state.download = undefined;
        },
        [getMatchListById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.adminList = action.payload;
            }
        },
        [getMatchListById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve managed list';
        },

        [createMatchReport.pending]: (state, action) => {
            state.loading = true;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.listMatchReport = undefined;
        },
        [createMatchReport.fulfilled]: (state, action) => {
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.loading = false;
            if (action.payload) {
                state.listMatchReport = action.payload;
            }
        },
        [createMatchReport.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to create match report for this list';
            state.successMessage = undefined;
            state.listMatchReport = undefined;
        },

    }
});

export const { clearState } = adminListMatchReportSlice.actions;
export default adminListMatchReportSlice.reducer;
