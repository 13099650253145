/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";
import {APP_COLORS} from "../../Constants";

const FormRequiredText = styled.span`
  color: ${APP_COLORS['red']};
`;

export default FormRequiredText;
