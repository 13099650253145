/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import { Image } from 'react-bootstrap';

import './ExploreMapFlyoutDetailLocation.scss';
import mapMarkerIcon from '../../../../common/assets/img/ToolbeltIcons-15.png';

export default function ExploreMapFlyoutDetailLocation(props) {
    return (
        <div className="detail-location-container">
            <Image
                className="icon"
                src={mapMarkerIcon}
                alt="ToolBeltData Company Icon"
                fluid
            />
            <div className="detail-container">
                <div className="detail-label">{props.bestLocation.company_address_city_region_formatted}</div>
                {props.isClustered ?
                    <div className="detail-location-more">
                        {
                            props.companyLocations.length > 1 ? `and ${props.companyLocations.length -1} more places`
                            :
                            `${props.bestLocation.long}, ${props.bestLocation.lat}`
                        }
                    </div>
                    :
                    <div className="detail-location">{`${props.bestLocation.long}, ${props.bestLocation.lat}`}</div>
                }
            </div>
        </div>
    );
}
