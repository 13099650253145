/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    sortBy,
} from 'lodash';

import ManagedListErrorsTable from "./ManagedListErrorsTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getManagedListErrorsCount,
    getManagedListErrors
} from "./managedListErrorsSlice.js";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

const ManagedListErrors = (props) => {
    return (
        <ManagedListErrorsComponent
        />
    );
};

const ManagedListErrorsComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'List Errors',
        href: '',
        isActive: true
    }];
    const managedListErrorsState = useSelector(state => state.managedListErrors);
    const dispatch = useDispatch();
    const history = useHistory();
    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedListErrorsState.alertMessage) {
            setAlertMessage(managedListErrorsState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedListErrorsState.alertMessage]);

    const isReload = managedListErrorsState.reload === true;

    useEffect(() => {
        dispatch(getManagedListErrorsCount());
        dispatch(getManagedListErrors());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [];

        actionItems.push(
            {
                label: 'EDIT',
                disabled: true,
                href: '',
                onClickHandler: () => history.push(`/admin/list-errors/${managedListErrorsState.selectedId[0]}/edit`),
                isActive: managedListErrorsState.selectedListErrors && managedListErrorsState.selectedListErrors.length === 1
            }
        );

        return sortBy(actionItems, 'label');
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`List Errors (${managedListErrorsState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled
                            onClick={() => history.push('/admin/list-matches/create')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id, organization id, list id'}
                        value={managedListErrorsState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {managedListErrorsState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <ManagedListErrorsTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default ManagedListErrors;
