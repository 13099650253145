/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import Select from "react-select";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import FormLabel from "./FormLabel";
import styled from "styled-components";
import FormRequiredText from "./FormRequiredText";

const FormJsonInput = (props) => {
    const {
        id,
        label,
        placeholder,
        required,
        onChange,
        height,
        width,
        viewOnly
    } = props;

    return (
        <>
            {label && (
                <FormLabel>
                    {label}
                    {required && (
                        <FormRequiredText> *</FormRequiredText>
                    )}
                </FormLabel>
            )}
            <JSONInput
                id={id || 'json-id'}
                placeholder={placeholder}
                locale={locale}
                height={height}
                width={width}
                viewOnly={viewOnly}
                theme='light_mitsuketa_tribute'
                style={{
                    container: {border: '1px solid #ced4da'}
                }}
                onChange={(v) => onChange(v.jsObject)}
            />
        </>
    );
}

export default FormJsonInput;
