/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faEnvelope,
    faPhone,
    faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import mapMarkerIcon from '../../../../common/assets/img/ToolbeltIcons-15.png';
import {APP_COLORS} from '../../../../common/Constants';
import ExploreMapFlyoutDetailLoadingSpinner from './ExploreMapFlyoutDetailLoadingSpinner';
import {FilterOptions} from "../../FilterOptions";
import {
    countCompanyAndContactByCriteria, replaceFilter as replaceCompanyFilter,
    searchCompanyByCriteria
} from "../../companySlice";
import {
    countCompanyAndContactByCriteria as countContactAndCompanyByCriteria,
    replaceFilter as replaceContactFilter,
    searchContactByCriteria
} from "../../contactSlice";
import {setExploreMode} from "../../exploreSlice";
import {cloneDeep, concat} from "lodash";
import {ExploreMapFlyoutSelectedDetailSection} from './ExploreMapFlyoutSelectedDetailSection';
import {getSupplierRecordByCompanyRecordId} from '../../../../common/services/SupplierService'
import {SelectStyled} from "../../../../common/components/form/FormSelect";

const options = [
    { value: 5, label: '5 Miles' },
    { value: 10, label: '10 Miles' },
    { value: 20, label: '20 Miles' },
    { value: 50, label: '50 Miles' },
    { value: 100, label: '100 Miles' },
    { value: 200, label: '200 Miles' },
]

export default function ExploreMapFlyoutDetailSelectedSupplier (props) {
    const dispatch = useDispatch();

    const exploreDataState = useSelector(state => state.explore);
    const [loading, setLoading] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedDistanceFrom, setSelectedDistanceFrom] = useState(null);
    const {
        currentFilters,
    } = props;
    const _filters = cloneDeep(currentFilters);

    useEffect(() => {
        console.log('ExploreMapFlyoutDetailSelectedSupplier - Supplier');
        setLoading(true);
        _getRecord(props.selectedPoint)
            .then((response) => {
                setLoading(false);
                setSelectedRecord(response);
            });
    }, [props.selectedPoint]);

    if (loading) return (<ExploreMapFlyoutDetailLoadingSpinner/>);

    const handleSearch = async (filters_) => {
        let filters = concat(_filters, filters_);
        if (exploreDataState.exploreMode.mode === 'COMPANY') {
            dispatch(replaceCompanyFilter(filters));
            dispatch(searchCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(countCompanyAndContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
            dispatch(setExploreMode({
                ...exploreDataState.exploreMode,
                mode: 'COMPANY',
                company: {
                    ...exploreDataState.exploreMode.company,
                    sections: {
                        ...exploreDataState.exploreMode.company.sections,
                    }
                }
            }));
            return;
        }
        dispatch(replaceContactFilter(filters));
        dispatch(searchContactByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
        dispatch(countContactAndCompanyByCriteria({filters: filters, exploreMode: exploreDataState.exploreMode}));
        dispatch(setExploreMode({
            ...exploreDataState.exploreMode,
            mode: 'CONTACT',
            contact: {
                ...exploreDataState.exploreMode.contact,
                sections: {
                    ...exploreDataState.exploreMode.contact.sections,
                }
            }
        }));
    };

    if (selectedRecord) {
        const {
            companyName,
            companyAddressDeliveryLine1,
            companyAddressLastLine,
            companyAddressCity,
            companyAddressRegion,
            companyAddressPostalCode,
            companyAddressLatitude,
            companyAddressLongitude,
            companyUrl
        } = selectedRecord;
        const locationAddressLastLine = `${companyAddressCity}, ${companyAddressRegion} ${companyAddressPostalCode}`

        const densityIcons = [
            {attr: 'companyUrl', icon: faGlobe},
            {attr: 'companyEmail', icon: faEnvelope},
            {attr: 'companyPhone', icon: faPhone},
            {attr: 'companyAddressDeliveryLine1', icon: faMapMarkerAlt},
        ];
        const densityIconsJsx = [];
        densityIcons.forEach((i, idx) => {
            if (selectedRecord[i.attr]) {
                let marginLeft = '10px';
                if (densityIconsJsx.length === 0) marginLeft = '0px';
                // TODO: Garbage data ....
                if (i.attr === 'overlay_website' && selectedRecord[i.attr] !== 'null') {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <a
                                href={`${companyUrl}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['orange']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </a>
                        </div>
                    )
                } else {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            />
                        </div>
                    )
                }
            }
        });

        return (
            <Row>
                <Col>
                    <div className="explore-map-flyout-detail">
                        <ExploreMapFlyoutSelectedDetailSection
                            name={companyName}
                            address1={companyAddressDeliveryLine1}
                            address2={companyAddressLastLine}
                            densityIconsJsx={densityIconsJsx}
                            detailIconJsx={
                                <Image
                                    className="icon"
                                    src={mapMarkerIcon}
                                    alt="ToolBeltData Map Marker Icon"
                                    fluid
                                />}
                        />
                        <div className="flyout-map-tools-section">
                            <div className="label-text">SEARCH WITHIN</div>
                            <SelectStyled
                                id="selected-location-point-geospatial-search-typeahead"
                                options={options}
                                onChange={(e) => {
                                    setSelectedDistanceFrom(e);
                                    if (e) {
                                        return handleSearch([{
                                            ...FilterOptions.COMPANY.HIDDEN_FILTERS.COMPANY_ADDRESS_GEO_RADIUS,
                                            value: {
                                                latitude: companyAddressLatitude,
                                                longitude: companyAddressLongitude,
                                                radius: e.value,
                                            }
                                        }]);
                                    }
                                }}
                                value={selectedDistanceFrom}
                                placeholder={'Select a distance ...'}
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
}

ExploreMapFlyoutDetailSelectedSupplier.propTypes = {
    selectedPoint: PropTypes.object,
};

async function _getRecord(selectedPoint) {
    const {
        sourceId,
        companyRecordId,
    } = selectedPoint.properties;

    const record = await getSupplierRecordByCompanyRecordId(sourceId, companyRecordId);
    return record[0];
}
