/**
 * Copyright ToolBelt Data Inc., 2021 All Rights Reserved
 */

import styled from "styled-components";

const Container = styled.div `
  padding: 15px;
`;

export default Container;
