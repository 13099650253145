/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";

import FormLabel from "./FormLabel";
import RadioGroup from "../RadioGroup";

const FormRadioGroup = (props) => {
    const {
        label,
        id,
        name,
        items,
        onChange,
        value,
        radioButton
    } = props;

    return (
        <RadioGroupContainer>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                id={id}
                name={name}
                value={value}
                items={items}
                onChange={selectedValue => onChange(selectedValue)}
                radioButton={radioButton}
            />
        </RadioGroupContainer>
    )
}

const RadioGroupContainer = styled.div `
  div {
    margin-left: -23px;
  }
`;

export default FormRadioGroup;
