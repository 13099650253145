

import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Collapse,
    Button,
} from 'react-bootstrap'
import './CollapsePanel.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import {APP_COLORS} from "../Constants";
import { GridButton } from '../../admin/companies/components/StyledComponents';

function CollapsePanel(props) {
    const { 
        isOpen=false,
        isShowActionButtons=false,
        onChange,
        onVerify,
        onValidate,
        isShowAddValueButton=false,
        onAddValue,
        isSaveValueButton=false,
        onSaveValue,
        isCancelValueButton=false,
        onCancelValue,
        showVerifyForCompany=false,
    } = props;
    const [open, setOpen] = useState(isOpen);


    return(
        <div className={'collapsePanel core-pl0'}>
            <div className={'header'}>
                <div className='action-mt5'>{props.header}</div>
                {isShowActionButtons && ( <> 
                    <div className="collapse-container">
                    <GridButton onClick={onChange}>CHANGE</GridButton>
                    {showVerifyForCompany && (<GridButton onClick={onVerify}>VERIFY</GridButton> )}
                    <GridButton onClick={onValidate}>INVALIDATE</GridButton>
                    </div>                                                                                 
                    </>)
                }  
                {isShowAddValueButton && (<> 
                    <div className="collapse-container">
                    <GridButton onClick={onAddValue}>Add Value</GridButton>                   
                   </div>
                    </>)}
                 {isCancelValueButton && (<>
                    <div className="collapse-pl300">
                    <GridButton onClick={onCancelValue}>Cancel</GridButton>
                    </div>
                    </>)}
                 {isSaveValueButton && (<> 
                    <div className="collapse-container">
                    <GridButton onClick={onSaveValue}>Save</GridButton>                   
                    </div>                                                                                 
                    </>)}

            </div>
            <Collapse in={open}>
                <div>
                    {props.children}
                </div>

            </Collapse>

        </div>
    )
}


export default CollapsePanel;
