/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import _ from 'lodash';

import contact_title from './dataFiles/contact_title.json';
import company_address_region from './dataFiles/company_address_region.json';
import company_address_loc_msa from './dataFiles/company_address_loc_msa.json';
import company_address_loc_csa from './dataFiles/company_address_loc_csa.json';
import company_class_trades from './dataFiles/company_class_trades.json';
import company_class_naics_descriptions from './dataFiles/company_class_naics_descriptions.json';
import company_class_sic_descriptions from './dataFiles/company_class_sic_descriptions.json';
// TODO: STUBING FROM COMPANY_PROFILE_KEYWORDS FOR NOW
import company_segments from './dataFiles/company_profile_keyword_categories.json';
// TODO: END STUBING FROM COMPANY_PROFILE_KEYWORDS FOR NOW
import company_services from './dataFiles/company_services.json';
import company_fact_business_structure from './dataFiles/company_fact_business_structure.json';
import company_stat_employees_range from './dataFiles/company_stat_employees_range.json';
import company_stat_sales_range from './dataFiles/company_stat_sales_range.json';
import company_affinities from './dataFiles/company_affinities.json';
import company_affiliations from './dataFiles/company_affiliations.json';
import company_profile_types from './dataFiles/company_profile_types.json';
import company_profile_associations from './dataFiles/company_profile_associations.json';
import company_profile_manufacturers from './dataFiles/company_profile_manufacturers.json';
import company_profile_online_profiles from './dataFiles/company_profile_online_profiles.json';
import company_profile_utilities from './dataFiles/company_profile_utilities.json';
import company_reg_local_registration_status from './dataFiles/company_reg_local_registration_status.json';
import company_reg_local_registrar from './dataFiles/company_reg_local_registrar.json';

import * as cache from "../Cache";
import config from "../../config";

const EMAIL_STATUSES = [
    {id: 'Catch All', display: 'Catch All'},
    {id: 'Disposable Email', display: 'Disposable Email'},
    {id: 'Not Available', display: 'Not Available'},
    {id: 'Role', display: 'Role'},
    {id: 'Verified', display: 'Verified'},
];

/**
 *
 * @return {Promise<[]>}
 */
export const getContactTitle = async() => {
    try {
        return contact_title.data;
    } catch (err) {
        return [];
    }
};

function getAccount() {
    return cache.get(config.constants.CACHE_KEY_ACCOUNT);
}

/**
 *
 * @return {Promise<[]>}
 */
export const getContactEmailStatus = async() => {
    return EMAIL_STATUSES
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAddressRegion = async() => {
    try {
        const regions = _.get(getAccount(), 'organization.config.subscription.geographies.countries.us.regions');
        if (regions) {
            return _.filter(company_address_region.data, (o) => _.includes(regions, o.id));
        }
        return company_address_region.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAddressRdi = async() => {
    return [
        {id: 'Commercial', display: 'Commercial'},
        {id: 'Residential', display: 'Residential'},
    ]
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAddressLocMsa = async() => {
    try {
        const msas = _.get(getAccount(), 'organization.config.subscription.geographies.countries.us.msas');
        if (msas) {
            return _.filter(company_address_loc_msa.data, (o) => _.includes(msas, o.id));
        }
        // Filter by regions if subscription present
        const regions = _.get(getAccount(), 'organization.config.subscription.geographies.countries.us.regions');
        if (regions) {
            return _.filter(company_address_loc_msa.data, (o) => {
                return o.regions.some(r => _.includes(regions, r));
            });
        }
        return company_address_loc_msa.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAddressLocCsa = async() => {
    try {
        const csas = _.get(getAccount(), 'organization.config.subscription.geographies.countries.us.csas');
        if (csas) {
            return _.filter(company_address_loc_csa.data, (o) => _.includes(csas, o.id));
        }
        // Filter by regions if subscription present
        const regions = _.get(getAccount(), 'organization.config.subscription.geographies.countries.us.regions');
        if (regions) {
            return _.filter(company_address_loc_csa.data, (o) => {
                return o.regions.some(r => _.includes(regions, r));
            });
        }
        return company_address_loc_csa.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyEmailStatus = async() => {
    return EMAIL_STATUSES
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanySegments = async() => {
    try {
        return company_segments.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyClassPrimaryTrade = async() => {
    try {
        if (_.get(getAccount(), 'organization.config.subscription.products.taxonomy.classifications.filterOn') === "trades") {
            const trades = _.get(getAccount(), 'organization.config.subscription.products.taxonomy.classifications.allowed');
            if (trades) {
                return _.filter(company_class_trades.data, (o) => _.includes(trades, o.id));
            }
        }
        // TODO: Add NAICS Restriction
        return company_class_trades.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyClassTrades = async() => {
    return getCompanyClassPrimaryTrade();
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyClassNAICSDescriptions = async() => {
    try {
        return company_class_naics_descriptions.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyClassSICDescriptions = async() => {
    try {
        return company_class_sic_descriptions.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyServices = async() => {
    try {
        return company_services.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyFactBusinessStructure = async () => {
    try {
        return company_fact_business_structure.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyFactPublicPrivate = async () => {
    return [
        {
            "id": "Public",
            "display": "Public"
        },
        {
            "id": "Private",
            "display": "Private"
        },
    ];
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyFactOwnershipType = async () => {
    return [
        {
            "id": "Minority Owned",
            "display": "Minority Owned"
        },
        {
            "id": "Veteran Owned",
            "display": "Veteran Owned"
        },
        {
            "id": "Woman Owned",
            "display": "Woman Owned"
        },
    ];
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyStatEmployeesRange = async() => {
    try {
        return company_stat_employees_range.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyStatSalesRange = async() => {
    try {
        return company_stat_sales_range.data;
    } catch (err) {
        return [];
    }
}

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAffinities = async() => {
    try {
        return company_affinities.data;
    } catch (err) {
        return [];
    }
}

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyAffiliations = async() => {
    try {
        return company_affiliations.data;
    } catch (err) {
        return [];
    }
}

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyIndicatorWorkTypes = async() => {
    try {
        return [
            {
                display: "Residential",
                id: "Residential"
            },
            {
                display: "Industrial",
                id: "Industrial"
            },
            {
                display: "Commercial",
                id: "Commercial"
            },
            {
                display: "Infrastructure",
                id: "Infrastructure"
            }
        ];
    } catch (err) {
        return [];
    }
}

export const getCompanyIndicatorWorkLocations = async() => {
    try {
        return [
            {
                display: "Interior",
                id: "Interior"
            },
            {
                display: "Exterior",
                id: "Exterior"
            },
        ];
    } catch (err) {
        return [];
    }
}

export const getCompanyIndicatorWorkCredentials = async() => {
    try {
        return [
            {
                display: "Licensed",
                id: "Licensed"
            },
            {
                display: "Bonded",
                id: "Bonded"
            },
            {
                display: "Insured",
                id: "Insured"
            }
        ];
    } catch (err) {
        return [];
    }
}

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyProfileTypes = async() => {
    try {
        return company_profile_types.data;
    } catch (err) {
        return [];
    }
}

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyProfileAssociations = async() => {
    try {
        return company_profile_associations.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyProfileManufacturers = async() => {
    try {
        return company_profile_manufacturers.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyProfileOnlineProfiles = async() => {
    try {
        return company_profile_online_profiles.data;
    } catch (err) {
        return [];
    }
};

/**
 *
 * @return {Promise<[]>}
 */
export const getCompanyProfileUtilities = async() => {
    try {
        return company_profile_utilities.data;
    } catch (err) {
        return [];
    }
};

export const getCompanyLicenseTypes = async () => {
    return getCompanyClassPrimaryTrade();
};

export const getCompanyRegLocalRegistrationStatus = async () => {
    try {
        return company_reg_local_registration_status.data;
    } catch (err) {
        return [];
    }
};

export const getCompanyRegLocalRegistrar = async () => {
    try {
        return company_reg_local_registrar.data;
    } catch (err) {
        return [];
    }
};

export const getCompanyRegLocalRegistrarRegion = async () => {
    return getCompanyAddressRegion();
};
