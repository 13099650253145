

import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {Modal as BsModal,
    Button
} from 'react-bootstrap';

import './Modal.scss'

function Modal(props) {
    const {
        size,
        title,
        header,
        footer,
        showClose,
        handleClose,
        handleSave,
        children,
        showHeaderLine,
        dialogClassName
    } = props;

    return (
        <BsModal
            show={true}
            onHide={handleClose}
            size={size}
            className={'modal'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            dialogClassName={dialogClassName}
            animation={false} // This also temp fixed issue findDomNode warning in console.
        >
            <BsModal.Header
                closeButton={showClose}
            >
                {header ?
                    header
                    :
                    <div style={{width: '100%'}}>
                        <BsModal.Title>{title}</BsModal.Title>
                        {showHeaderLine &&
                            <hr/>
                        }
                    </div>
                }
            </BsModal.Header>

            <BsModal.Body>
                {children}
            </BsModal.Body>

            <BsModal.Footer>
                {footer ?
                    footer
                    :
                    <div className={'footer'}>
                        <Button variant='secondary' onClick={handleClose}>Close</Button>
                        <Button variant='primary' onClick={handleSave}>Save</Button>
                    </div>
                }
            </BsModal.Footer>
        </BsModal>
    );
}

Modal.protoTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    showClose: PropTypes.bool,
    handleSave: PropTypes.func,
    handleClose: PropTypes.func,
    header: PropTypes.element,
    size: PropTypes.oneOf(['sm', 'small', 'lg', 'large']),
    footer: PropTypes.element,
    dialogClassName: PropTypes.string
};

Modal.defaprotoTypes = {
    disabled: false,
    showClose: true,
    size: 'sm',
    handleSave: (e) => e,
    handleClose: (e) => e,
};

export default Modal;
