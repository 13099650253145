/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
    Route,
} from 'react-router-dom';
import ExploreContainer from './ExploreContainer';

export default class ExploreRouter extends Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    render() { // eslint-disable-line
        return (
            <Route component={ExploreContainer} />
        );
    }
}
