/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";

import React from "react";
import Image from "react-bootstrap/Image";

import {APP_COLORS} from "../../common/Constants";
import companyIcon from "../../common/assets/img/ToolbeltIcons_Artboard_19.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck} from "@fortawesome/pro-solid-svg-icons";

const IconBoxContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BTVerifiedContainer = styled.div `
  padding: 10px;
`;

const IconContainer = styled.div `
  height: 65px;
  width: 65px;
  padding: 6px;
  border: 1px solid ${APP_COLORS['gray-400']};
`;

const Icon = styled(Image) `
  height: 50px;
`;

export default function ContractorDirectorySearchResultIconBox(props) {
    const {
        btVerified
    } = props;

    return (
        <IconBoxContainer>
            {/*{*/}
            {/*    btVerified && (*/}
            {/*        <BTVerifiedContainer>*/}
            {/*            <FontAwesomeIcon*/}
            {/*                color="#00d8d8"*/}
            {/*                icon={faBadgeCheck}*/}
            {/*                style={{fontSize: '18px'}}*/}
            {/*            />*/}
            {/*        </BTVerifiedContainer>*/}
            {/*    )*/}
            {/*}*/}
            <IconContainer>
                <Icon
                    src={companyIcon}
                    alt="ToolBeltData Company Icon"
                    fluid
                />
            </IconContainer>
        </IconBoxContainer>
    )
}

