import * as React from 'react';

import {Row, Col} from 'react-bootstrap';

import FormTextInput from "../../common/components/FormTextInput";
import FormJsonInput from "../../common/components/form/FormJsonInput";
import FormSelect from '../../common/components/form/FormSelect';
import FormSearchOrganizationsTypeahead from "../components/organizations/FormSearchOrganizationsTypeahead";
import FormSearchAccountsTypeahead from "../components/organizations/FormSearchAccountsTypeahead";


const CreateManagedIntegrationForm = (props) => {
    const {
        editAsJSON,
        integrationConfig,
        setIntegrationConfig,
        organizationId,
        setOrganizationId,
        type,
        setType,
        name,
        setName,
        description,
        setDescription,
        accountId,
        setAccountId,
        accountDisabled,
        formValid
    } = props;

    if (editAsJSON) {
        // The <p> tag is used only for testing, keep it hidden
        return (<>
            <p hidden id='jsonInputTxt'>{JSON.stringify(integrationConfig)}</p>
            <FormJsonInput
                id={'create-config-organization'}
                htmlFor={'createManagedIntegration'}
                label={'JSON Preview'}
                placeholder={integrationConfig}
                onChange={(v) => setIntegrationConfig(v)}
                height={'100%'}
                width={'100%'}
                viewOnly={true}
            />
        </>);
    }

    return (<div>
        <Row>
            <Col md={9}>
                <FormSearchOrganizationsTypeahead
                    label={'Organization'}
                    onChange={setOrganizationId}
                    organization={organizationId}
                    isInvalid={!formValid && organizationId.length === 0}
                    editing={true}
                    required
                />
            </Col>
        </Row>
        <Row>
            <Col md={9}>
                <div id='organization-type-div'>
                    <FormSelect
                        id={'organizationType'}
                        for={'createManagedIntegrationForm'}
                        label={'Type'}
                        placeholder="Select organization type"
                        value={type}
                        options={[{ value: 'API', label: 'API' }]}
                        onChange={value => setType(value)}
                        isInvalid={!formValid && !type}
                        size={'sm'}
                        required
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={9}>
                <div id='organization-name-div'>
                    <FormTextInput
                        data-testid={'organizationName'}
                        for={'createManagedIntegrationForm'}
                        label={'Name'}
                        placeholder="Enter integration name"
                        value={name}
                        onChange={value => setName(value)}
                        isInvalid={!formValid && !name}
                        size={'sm'}
                        required
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={9}>
                <div id='organization-description-div'>
                    <FormTextInput
                        data-testid={'description'}
                        for={'createManagedIntegrationForm'}
                        label={'Description'}
                        placeholder="Enter description"
                        value={description}
                        onChange={value => setDescription(value)}
                        isInvalid={!formValid && !description}
                        size={'sm'}
                        required
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={9}>
                <div id='account-search-div'>
                    <FormSearchAccountsTypeahead
                        id={'accountSearch'}
                        data-testid={'accountSearch'}
                        for={'createManagedIntegrationForm'}
                        label={'Account'}
                        onChange={setAccountId}
                        organization={(organizationId[0] ?? (organizationId ?? {id: ''})).id}
                        account={accountId}
                        isInvalid={!formValid && accountId.length === 0}
                        editing={true}
                        required
                        disabled={accountDisabled}
                    />
                </div>
            </Col>
        </Row>
    </div>);
}

export default CreateManagedIntegrationForm;