

import React, {  useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {useDropzone} from 'react-dropzone'

import Spinner from 'react-bootstrap/Spinner';

const UploadIcon = (props) => {

    const {
        onUpload,
    } = props;

    const [loading, setLoading] = useState(false);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        upload(acceptedFiles[0]);
    }, []);

    const {getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop});

    const baseStyle = {
        style: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        }
    };

    const activeStyle = {
        style: {
            borderColor: '#2196f3'
        }
    };

    const acceptStyle = {
        style: {
            borderColor: '#00e676'
        }
    };

    const rejectStyle = {
        style: {
            borderColor: '#ff1744'
        }
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const upload = async (photo) => {
        setLoading(true);
        try {
            await onUpload(photo);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            return undefined;
        }

    };

    return (
        <div>
            {!loading &&
                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: '2px',
                    borderRadius: '2px',
                    borderStyle: 'dashed',
                    backgroundColor: '#fafafa',
                    color: '#bdbdbd',
                    outline: 'none',
                    transition: 'border .24s ease-in-out'
                }

                }>
                    <div {...getRootProps(style)}
                    >
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </div>
            }
            {loading &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        style={{marginTop: '30px'}}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>

            }
        </div>
    )
};

UploadIcon.defaultProps = {
    options: [],
    value: 'ad',
    onSelectIcon: e => e,
};

UploadIcon.propTypes = {
    url: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    onSelectIcon: PropTypes.func.isRequired,
};

export default UploadIcon;
