/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import logo from "../assets/img/ToolBelt_Icon.png";
import Image from "react-bootstrap/Image";
import {APP_COLORS} from "../Constants";

const SpinnerContainer = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.70); /* Black background with opacity */
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SubscriptionBox = styled.div `
  width: 700px;
  height: 350px;
  background-color: #FFF;
  z-index: 999;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  display: grid;
  grid-template-rows: 74px 40px 1fr;
  padding: 40px 0;
`;

const Text1 = styled.span `
  font-weight: bold;
  color: ${APP_COLORS['tech-blue']};
  font-size: 60px;
`;

const Text2 = styled.span `
  color: ${APP_COLORS['tech-blue']};
  font-size: 60px;
`;

const Text3Box = styled.span `
  position: relative;
`;

const Text3 = styled.span `
  color: ${APP_COLORS['gray-500']};
  font-size: 20px;
  
  position: absolute;
  text-transform: uppercase;

  left: 95px;
  top: -25px;
  width: 340px;
`;

const HeaderBox = styled.div `
  display: grid;
  grid-template-columns: 90px 125px 109px 1fr;
  align-items: center;
`;

const SubContent = styled.div `
  align-self: flex-start;
  
  text-align: center;
  padding-top: 15px;
  font-size: 17px;
`;

const ScreenLockOverlay = (props) => {
    const body = document.body;
    const html = document.documentElement;

    const height = Math.max( body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight ) - 60;

    return (
        <SpinnerContainer style={{height: height}}>
            <SubscriptionBox>
                <HeaderBox>
                    <Image
                        src={logo}
                        width="90"
                        alt="ToolBeltData Logo"
                    />
                    <Text1>Tool</Text1>
                    <Text2>Belt</Text2>
                    <Text1>Data</Text1>
                </HeaderBox>
                <Text3Box>
                    <Text3>POWER TOOLS FOR SALES GROWTH</Text3>
                </Text3Box>
                <SubContent>
                    Data Exploration has been disabled for your organization<br />
                    Contact your account manager to update your subscription
                </SubContent>
            </SubscriptionBox>
        </SpinnerContainer>
    );
}

export default ScreenLockOverlay;
