import React, {useEffect, useState} from 'react';
import Modal from '../../common/components/Modal';
import {
    Container,
    Form,
    Row,
    Col,
    Spinner,
    Button,
} from 'react-bootstrap';

import {getById, download} from '../../common/services/ListService';
import Styles from'./DownloadListDialog.scss';
import FormTextInput from "../../common/components/FormTextInput";
import FormContainer from "../../common/components/layout/FormContainer";
import {APP_COLORS} from "../../common/Constants";

function DownloadListDialog(props) {
    const {
        handleClose,
        id,
    } = props;
    const [selectedList, setSelectedList] = useState(null);
    const [loading, setLoading] = useState(true);

    const [downloadResult, setDownloadResult] = useState(null);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const list = await getById(id);
            setSelectedList(list);
            setLoading(false);
        };
        init();

    }, [id]);

    const downloadList = async () => {
        setLoading(true);
        try {
            const data = {
                listId: selectedList.id,
                dataType: 'csv',
                fileName: selectedList.name
            };
            const result = await download(id, data);
            setLoading(false);
            setDownloadResult(result);
        } catch(error) {
            setLoading(false);
        }
    };
    return (
        <Modal
            showClose={true}
            show={true}
            handleClose={handleClose}
            dialogClassName={'dialog'}
            title={`Download List`}
            footer={
                (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>

                        <Button
                            variant='info'
                            disabled={loading || !!downloadResult}
                            onClick={() => downloadList()}
                        >
                            {!loading && <>Download</>}
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            variant='outline-secondary'
                            onClick={() => handleClose()}
                            style={{marginLeft: '10px'}}
                        >
                            Cancel
                        </Button>
                    </div>
                )
            }
        >
            {loading && !selectedList?
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Spinner
                        style={{marginTop: '30px'}}
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
                :
                <Container className={Styles.container}>
                    <FormContainer>
                        <Form noValidate>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Data Type'}
                                        value={'csv'}
                                        size={'sm'}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: '10px',
                                paddingBottom: '0px'
                            }}>
                                <Col md={12}>
                                    <FormTextInput
                                        label={'Name'}
                                        value={selectedList?.name}
                                        size={'sm'}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Form>
                        {!downloadResult &&
                            <Row>
                                <Col>
                                    <span style={{
                                        fontStyle: 'italic',
                                        fontSize: '14px'
                                    }}>Select download to generate a link for your data.</span>
                                </Col>
                            </Row>
                        }
                        {downloadResult &&
                            <Row>
                                <Col>
                                    <span style={{
                                        fontStyle: 'italic',
                                        fontSize: '14px'
                                    }}>Your download is ready. Click the link below to download.</span>
                                    <br/>
                                    <br/>
                                    <div>
                                        <a href={`${downloadResult.dataUrl}`}
                                           style={{
                                               // fontStyle: 'italic',
                                               // textDecoration: 'underline',
                                               color: APP_COLORS.orange
                                           }}
                                        >
                                            {`${selectedList.name}.csv`}
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </FormContainer>
                </Container>
            }
        </Modal>
    );
}
export default DownloadListDialog;
