import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import config from '../../config'
import * as Api from '../../common/Api';

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    managedMatchTemplate: null,
};

export const getManagedMatchTemplateById = createAsyncThunk('matchTemplates/getMatchTemplateById', async (id) => {
    const URL = `${config.services.ATLAS.apiUrl}/managed-match-templates/${id}`;
    const matchTemplate = await Api.get(true, URL);
    return matchTemplate;
});

export const editManagedMatchTemplate = createAsyncThunk('matchTemplates/editMatchTemplate', async ({id, data}) => {
    const payload = {
        options: null,
        data: {
            name: data.matchTemplate.name,
            description: data.matchTemplate.description,
            config: data.matchTemplate.config
        }
    }
    const URL = `${config.services.ATLAS.apiUrl}/managed-match-templates/${id}/edit`;
    const matchTemplate = await Api.post(true, URL, payload);
    return matchTemplate;
});

const managedMatchTemplateSlice = createSlice({
    name: 'managedMatchTemplateSlice', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedMatchTemplate = null;
            return state;
        },
        resetCreateOrganizationState: {
            reducer(state, action) {
                state.loading = false;
                state.reset = true;
                state.alertMessage = undefined;
                state.successMessage = undefined;
            }
        },
    },
    extraReducers: {
        [getManagedMatchTemplateById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getManagedMatchTemplateById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTemplate = action.payload;
            }
        },
        [getManagedMatchTemplateById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve match template information';
        },
        [editManagedMatchTemplate.pending]: (state, action) => {
            state.loading = true;
        },
        [editManagedMatchTemplate.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.managedMatchTemplate = action.payload;
            }
            state.successMessage = 'Successfully edited match template';
        },
        [editManagedMatchTemplate.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
            state.alertMessage = `There was an error while trying to edit the match template. Failed with message - [${action.error.message}]`;
        },
    }
});

export const { clearState } = managedMatchTemplateSlice.actions;
export default managedMatchTemplateSlice.reducer;
