/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import {Button} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    sortBy,
} from 'lodash';

import ManagedMatchTemplatesTable from "./ManagedMatchTemplatesTable";
import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getManagedMatchTemplatesCount,
    getManagedMatchTemplates
} from "./managedMatchTemplatesSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import PanelTable from "../../common/components/layout/PanelTable";
import Container from "../../common/components/layout/Container";

const ManagedMatchTemplates = (props) => {
    return (
        <ManagedMatchTemplatesComponent
        />
    );
};

const ManagedMatchTemplatesComponent = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Match Templates',
        href: '',
        isActive: true
    }];
    const managedMatchTemplatesState = useSelector(state => state.managedMatchTemplates);
    const dispatch = useDispatch();
    const history = useHistory();
    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (managedMatchTemplatesState.alertMessage) {
            setAlertMessage(managedMatchTemplatesState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [managedMatchTemplatesState.alertMessage]);

    const isReload = managedMatchTemplatesState.reload === true;

    useEffect(() => {
        dispatch(getManagedMatchTemplatesCount());
        dispatch(getManagedMatchTemplates());
    }, [isReload, dispatch]);

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now())
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [];

        actionItems.push(
            {
                label: 'EDIT',
                href: '',
                onClickHandler: () => history.push(`/admin/match-templates/${managedMatchTemplatesState.selectedId[0]}/edit`),
                isActive: managedMatchTemplatesState.selectedMatchTemplates && managedMatchTemplatesState.selectedMatchTemplates.length === 1
            }
        );

        return sortBy(actionItems, 'label');
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Match Templates (${managedMatchTemplatesState.totalRecords.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            onClick={() => history.push('/admin/match-templates/create')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id, matchTypeId, name or description'}
                        value={managedMatchTemplatesState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <PanelTable>
                {managedMatchTemplatesState.loading && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <ManagedMatchTemplatesTable lastReload={lastReload}/>
            </PanelTable>
        </Container>
    );
};

export default ManagedMatchTemplates;
