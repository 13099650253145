import _ from "lodash";

export const LIST_STATUSES = {
    // IMPORT ONLY USE CASE
    // CREATED -> PARSING -> PARSED -> NORMALIZING -> NORMALIZED
    // OR * -> ERROR
    CREATED: {
        id: 'CREATED',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: true,
        display: 'Created',
        description: null
    },
    // UPLOADED: { // TODO MIGHT NOT BE NEEDED
    //   id: 'UPLOADED',
    //   domain: 'IMPORT',
    //   display: 'Uploaded',
    //   description: null
    // },
    PARSING: {
        id: 'PARSING',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Parsing',
        description: null
    },
    PARSED: {
        id: 'PARSED',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: true,
        display: 'Parsed',
        description: null
    },
    NORMALIZING: {
        id: 'NORMALIZING',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Normalizing',
        description: null
    },
    NORMALIZED: {
        id: 'NORMALIZED',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: true,
        display: 'Normalized',
        description: null
    },
    READY: {
        id: 'READY',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: true,
        display: 'Ready',
        description: null
    },
    ERROR: {
        id: 'ERROR',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Error',
        description: null
    },
    // OVERLAY USE CASE
    // CREATED -> PARSING -> PARSED -> NORMALIZING -> NORMALIZED -> SYNCING -> SYNCED -> READY
    // OR * -> ERROR
    SYNCING: {
        id: 'SYNCING',
        listTypes: ['OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Syncing',
        description: null
    },
    SYNCED: {
        id: 'SYNCED',
        listTypes: ['OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Synced',
        description: null
    },
    RE_SYNCING: {
        id: 'RE_SYNCING',
        listTypes: ['IMPORT', 'OVERLAY', 'MATCH', 'EXPORT'],
        processTo: false,
        display: 'Re-Syncing',
        description: null
    },
    // MATCH USE CASE
    // CREATED -> MATCHING -> MATCHED -> SYNCING -> SYNCED -> READY
    // OR * -> ERROR
    MATCHING: {
        id: 'MATCHING',
        listTypes: ['MATCH'],
        processTo: false,
        display: 'Matching',
        description: null
    },
    MATCHED: {
        id: 'MATCHED',
        listTypes: ['MATCH'],
        processTo: true,
        display: 'Matched',
        description: null
    },
    // EXPORT USE CASE
    // CREATED -> EXPORTING -> EXPORTED -> SYNCING -> SYNCED -> READY
    // OR * -> ERROR
    EXPORTING: {
        id: 'EXPORTING',
        listTypes: ['EXPORT'],
        processTo: false,
        display: 'Exporting',
        description: null
    },
    EXPORTED: {
        id: 'EXPORTED',
        listTypes: ['EXPORT'],
        processTo: true,
        display: 'Exported',
        description: null
    },
};
export function getListStatusById(id) {
    if (id) {
        return _.get(LIST_STATUSES, id);
    }
}
