/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";

import React from "react";

import {APP_COLORS} from "../../common/Constants";
import {
    faBuilding, faCalculator,
    faCertificate, faFile,
    faHouse, faIdBadge,
    faPersonShelter,
    faSatelliteDish, faUser,
    faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import {faChimney, faFileCertificate} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BoxContainer = styled.div`
    padding: 0 10px 0 0;
`;

const CardLabel = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: ${APP_COLORS['gray-card']};
`;

const PillRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;
`;

const PillContainer = styled.div`
    border-radius: 5px;
    border: 1px solid ${APP_COLORS['gray-card']};
    margin: 3px;
    background-color: ${APP_COLORS['gray-100']};
`;

const PillText = styled.span`
    font-size: 14px;
    padding: 5px;
    color: ${APP_COLORS['tech-blue']};
    font-weight: 600;
`;

const CredentialItem = styled.div`
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
`;

export default function ContractorDirectorySearchResultCredentialsBox(props) {
    const {
        item
    } = props;

    const pillIcons = [
        {attr: 'companyIndResidential', value: false, icon: faHouse, text: 'Residential'},
        {attr: 'companyIndCommercial', value: false, icon: faBuilding, text: 'Commercial'},
        {attr: 'companyIndIndustrial', value: false, icon: faWarehouse, text: 'Industrial'},
        {attr: 'companyIndExterior', value: false, icon: faChimney, text: 'Exterior'},
        {attr: 'companyIndInterior', value: false, icon: faPersonShelter, text: 'Interior'},
        {attr: 'companyIndLicensed', value: false, icon: faFileCertificate, text: 'Licensed'},
        {attr: 'companyIndBonded', value: false, icon: faFileCertificate, text: 'Bonded'},
        {attr: 'companyIndInsured', value: false, icon: faFileCertificate, text: 'Insured'},
        {attr: 'companyProfileCnt', value: true, icon: faSatelliteDish, text: 'Profiles'},
        {attr: 'companyManufacturerCnt', value: true, icon: faWarehouse, text: 'Manufacturers'},
        {attr: 'companyAssociationCnt', value: true, icon: faCertificate, text: 'Associations'},
        {attr: 'companyLicenseCnt', value: true, icon: faIdBadge, text: 'Licenses'},
        {attr: 'companyPermitCnt', value: true, icon: faCalculator, text: 'Permits'},
        {attr: 'companyPolicyCnt', value: true, icon: faFileCertificate, text: 'Policies'},
        {attr: 'companyContactCnt', value: true, icon: faUser, text: 'Contacts'},
        {attr: 'companySourceCnt', value: true, icon: faFile, text: 'Sources'},
    ];

    const pills = [];
    pillIcons.forEach((p, i) => {
        if (true) {
            pills.push(
                <PillContainer key={i}>
                    <PillText>
                        <FontAwesomeIcon
                            icon={faFileCertificate}
                        />&nbsp;{p.text}
                    </PillText>
                </PillContainer>)

        }
    })

    // Interpreted Values
    if (item.companyRegLocalRegistrationStatus === 'Active') {
        pills.push(
            // we should'nt be hardcoding the key ....
            <PillContainer key={99}>
                <PillText>
                    <FontAwesomeIcon
                        icon={faFileCertificate}
                    />&nbsp;Registered
                </PillText>
            </PillContainer>
        )
    }

    console.log(item.companyLicenseActiveLicenseTypesMappedWSource);

    return (
        // <BoxContainer>
        //     <CardLabel>Credentials</CardLabel>
        //     <PillRow>
        //         {item.companyLicenseActiveLicenseTypesMappedWSource && item.companyLicenseActiveLicenseTypesMappedWSource.length > 0 && (
        //             <div style={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //             }}>
        //                 {item.companyLicenseActiveLicenseTypesMappedWSource.map((l, i) => (
        //                     <CredentialItem key={i}>
        //                         <span><b>Licensed {l.split(':')[0]}</b> - {l.split(':')[1].substring(0, 30)}...</span>
        //                     </CredentialItem>
        //                 ))}
        //             </div>
        //         )}
        //     </PillRow>
        //     <CardLabel>Associations</CardLabel>
        //     <PillRow>
        //         {item.companyAffinitiesAndAffiliations && item.companyAffinitiesAndAffiliations.length > 0 && (
        //             <div style={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //             }}>
        //                 {item.companyAffinitiesAndAffiliations.map((l, i) => (
        //                     <CredentialItem key={i}>
        //                         <span>{l}</span>
        //                     </CredentialItem>
        //                 ))}
        //             </div>
        //         )}
        //     </PillRow>
        // </BoxContainer>

    <BoxContainer>
        <CardLabel>Credentials</CardLabel>
        <PillRow>
            {item.companyLicenseActiveLicenseTypesMappedWSource && item.companyLicenseActiveLicenseTypesMappedWSource.length > 0 && (
                <>
                    {item.companyLicenseActiveLicenseTypesMappedWSource.map((t, i) => (
                        <PillContainer key={i}>
                            <PillText>
                                <FontAwesomeIcon
                                    icon={faFileCertificate}
                                />&nbsp;<b>{t.split(':')[0]}</b> - {t.split(':')[1].substring(0, 20)}...
                            </PillText>
                        </PillContainer>
                    ))}
                </>
            )}
        </PillRow>
        <CardLabel>Affinities</CardLabel>
        <PillRow>
            {item.companyAffinitiesAndAffiliations && item.companyAffinitiesAndAffiliations.length > 0 && (
                <>
                    {item.companyAffinitiesAndAffiliations.map((t, i) => (
                        <PillContainer key={i}>
                            <PillText>
                                <FontAwesomeIcon
                                    icon={faWarehouse}
                                />&nbsp;{t}
                            </PillText>
                        </PillContainer>
                    ))}
                </>
            )}
        </PillRow>
    </BoxContainer>
    )
}

