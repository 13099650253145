/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars
import { useLocation } from 'react-router-dom';
import EditCompany from './EditCompany';

const EditCompanyContainer = (props) => {
    const search = useLocation().search;
    const selectedContactId = new URLSearchParams(search).get('selectedContactId');

    return(
        <EditCompany id={props.match.params.id} selectedContactId={selectedContactId} />
    );
};

export default EditCompanyContainer;
